import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ADD_COMMENT } from "../../../Graghql/mutations";
import { useMutation } from "@apollo/client";
import { TextField, Button } from "@material-ui/core";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { userDeatils } from "../../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
function AddComment(props) {

  const { handleSubmit, register } = useForm({});
  const user = useReactiveVar(userDeatils);
  const [addCmnts] = useMutation(ADD_COMMENT);
  const [comment, setComment] = useState("");
  function handleChange(event) {
    setComment(event.target.value);
  }
  function onSubmit(params) {
    props?.approvalPropertyId===true?params.PropertyId = props.data.PropertyId:params.PropertyId = props.propertyId;
    params.UserName = user.data.FirstName + " " + user.data.LastName;
    //console.log("checkinggg-->",params.UserName)

    params.UserId = user.data._id;
    params.CreatedBy = user.data._id;
    params.UpdatedBy = user.data._id;


    try {
      addCmnts({ variables: params })
        .then((res) => {
          props&&props.handlePagination();
          toggleSnackbar({
            status: true,
            message: "Comment successfully Submitted",
            variant: "success",
          });
          props.setOpenAgentPrint(false);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  
  function getFeaturedImage(images) {
    let position = images&&images?.findIndex((element) => element.Featured === true);
    if (position !== -1) return images[position]?.FileUrl;
    else return "../assets/images/no-image.jpg";
  }
  //console.log("image",props.image)
  let pid = props.data.PID;
  let adTitle = props.data.AdTitle;
  let propertydata = props.data;
  //console.log("p data",propertydata)
  //console.log("p data",adTitle)
  return (
    <div className="property-comment-box">
    
      
        <div className="printPropertyDetail align-items-center mt-0"> 
          <div className="printPropertyDetail-Img">
              <img src={getFeaturedImage(propertydata?.PendingImages?propertydata?.PendingImages:propertydata?.Images)}
              // "../assets/images/contact-address-bg.jpg" 
              className="imgfluid" alt="Icon"/>
            </div>
            <div>
              <h2 className="mb-0"><b>PID#&nbsp;{pid}</b>&nbsp;{adTitle}</h2>
              </div>
            
          </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      
        <TextField
          multiline="bool"
          className="w-100 property-boundary-box"
          rows={7}
          variant="outlined"
          {...register("Comment", 
          { required: true }
          )}
          onChange={handleChange}
          value={comment}
          placeholder="Add your comment here"
          

        />
        <div className="add-comment-btn d-flex justify-content-between">
          <Button variant="contained" color="secondary" type="submit" onClick={()=>{props.setOpenAgentPrint(false)}}>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}

export default AddComment;
