import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import {
  IconButton,
  Button,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useQuery } from "@apollo/client";
import { LIST_AREAS } from "../../Graghql/queries";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../Common/Loader";

export default function Locations(props) {
  // const schema = yup.object().shape({
  //   subject: yup
  //     .string()
  //     .required("Subject is required"),
  // })
  //   const {register, handleSubmit,formState} = useForm({
  //     resolver: yupResolver(schema),
  //   });
  //   const { errors } = formState;
  // eslint-disable-next-line no-unused-vars
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkCities, setCheckCities] = useState([]);
  const {selected, setSelected, isCheck, setIsCheck} = props;
  
  useEffect(()=>{
    let obj = props.defaultValue
    console.log("pathname",props.pathname);
    const inputString = props.pathname;
    const end = inputString?.indexOf("/", 1); // Start searching from index 1
    const extractedString = inputString?.slice(0, end);
    console.log(extractedString,"extractedString");
    if(props.pathname && props.pathname !== "" && extractedString !=="/searchlistings")
      obj = {area : extractedString?.replace("/", "").split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')};
      
    let arr = [];
    let loc = [];
    if(obj && obj.area && !props.loaded){
      if(props.setLoaded && props.loaded === false)
        props.setLoaded(true);
      group1&&group1.listAreas.forEach(element => {
        if((typeof obj.area === "object" && obj.area.includes(element.City)) || element.City.replace(" ","")===obj.area){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      group2&&group2.listAreas.forEach(element => {
        if((typeof obj.area === "object" && obj.area.includes(element.City)) ||element.City.replace(" ","")===obj.area){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      group3&&group3.listAreas.forEach(element => {
        if((typeof obj.area === "object" && obj.area.includes(element.City)) ||element.City.replace(" ","")===obj.area){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      setSelected(loc)
      setIsCheck(arr);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "0px 0px 6px rgba(105, 105, 105, 0.25)",
    // eslint-disable-next-line no-dupe-keys
    boxShadow: 24,
    p: 4,
  };
  const { data: group1, loading } = useQuery(LIST_AREAS, {
    variables: { group: 1 },
  });
  const { data: group2 } = useQuery(LIST_AREAS, {
    variables: { group: 2 },
  });
  const { data: group3 } = useQuery(LIST_AREAS, {
    variables: { group: 3 },
  });

  const handleSelectAll = (data,value,index) => {

    // setIsCheckAll(!index&&!isCheckAll);
    // setIsCheck(item?.Area?.map((City,index1) =>{  console.log("index1",index1);return(City)}));
    // if (isCheckAll) {
    //   setIsCheck([]);
    // }
    let array = [...isCheck];
    let subArray = []
    data.listAreas[index].Area.forEach(element => {
      subArray.push(element)
      if(array.includes(element)){
        let pos = array.findIndex(e => element===e);
        array.splice(pos, 1);
      }
    });
    let mainCityValues=data.listAreas[index].City
    let mainCity=[...checkCities]
     if(value) {
     
     mainCity.push(data.listAreas[index].City)
    setCheckCities(mainCity)
     }
    else if(checkCities.includes(mainCityValues)){
      
      let position=checkCities.findIndex(e=> mainCity===e )
      checkCities.splice(position,1)
    }
//     let mainCityValues=data.listAreas[index].City
//     let cityValue=[...mainCityValues]
//     let cityArray = []
//     data.listAreas?.forEach(element => {
//       cityArray.push(element)
//       if(cityValue.includes(element)){
//         let pos = array.findIndex(e => element===e);
//         cityValue.splice(pos, 1);
//       }
//     });
// console.log("///////",cityArray)

    if(value){
      setIsCheck([...array,...subArray]);
      handleCities(data.listAreas[index].Area,[...array,...subArray],data.listAreas[index].City)
    }
    else{
      setIsCheck(array);
      handleCities(data.listAreas[index].Area,array,data.listAreas[index].City)
    }
  };
 
  console.log("=======>",isCheckAll)
 //console.log("checkCities",checkCities)
  // const handleChecked=(item, value)=>{
  //   console.log("itemmmm",item)
  //   let array = [...locationIndex];
  //   if (value === true) {
  //     array.push(item);
  //   }
  //   setLocationIndex(array)
  // }
  const handleChange=(item, value, data)=>{
    // const { id, checked } = value;
    // setIsCheck([...isCheck, item]);
    // if (!checked) {
    //   setIsCheck(isCheck.filter(item1 => item1 !== item));
    // }
    let array = [...isCheck];
    if(!value){
      let pos = array.findIndex(e => item===e);
      array.splice(pos, 1);
    }
    else{
      array.push(item);
    }
    let cFlag = false;
    data.Area.forEach(element => {
      if(!array.includes(element))
        cFlag = true;
    });
    if(!cFlag)
      handleCities(data.Area,array,data.City)
    setIsCheck(array);
  }
  // function checkGrpSelected (data,index) {
  //   let flag = false;
  //   data.listAreas[index].Area.forEach(element => {
  //     if(!isCheck.includes(element)){
  //       flag = true;
  //     }
  //   });
  //   return !flag;
  // }
  function handleCities(data,array,city) {
    let flag = false;
    data.forEach(element => {
      if(array.includes(element)){
        flag = true;
      }
    });
    if(flag){
      if(!selected.includes(city)){
        let arr = [...selected];
        arr.push(city);
        setSelected(arr);
      }
    }
    else{
      let pos = selected.findIndex((element) => element === city);
      if(pos > -1){
        let arr = [...selected];
        arr.splice(pos,1);
        setSelected(arr);
      }
    }
  }
  return (
    <div>
      <Modal
        onClose={() => props.setOpenLocations(false)}
        open={props.openLocations}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="printModalWrap location-filter-modal"
      >
        <Box sx={style} className="location-filter-wrap">
          <div className="location-filter-head">
          <Loader open={loading} />
            <h3>Select Location</h3>
            <IconButton>
              <CloseIcon onClick={() =>{ props.setSubArea(isCheck);props.setOpenLocations(false)}}/>
            </IconButton>
          </div>
          <div className="location-filter">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="location-filter-list">
                    {group1?.listAreas?.map((item, index) => {
                      //console.log("index11111",index)
                      return(
                      
                      <div>
                       <h4><Checkbox color="primary"  
                       checked={selected.includes(item.City)}
                       onChange={(e) =>{handleSelectAll(group1,e.target.checked,index);} }
                       />
                        {item?.City}</h4>

                        <ul className="location-filter-list-inner">
                          {item?.Area?.map((City, index1) => (
                            <li key={index1}>
                              <Checkbox color="primary"
                               checked={isCheck.includes(City)}
                               onChange={(e) =>handleChange(City, e.target.checked,group1?.listAreas[index])}
                              />
                              {City}
                            </li>
                          ))}
                        </ul>
                      </div>)}
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="location-filter-list">
                    {group2?.listAreas?.map((item, index) => (
                      <div>
                        <h4><Checkbox color="primary" 
                       checked={selected.includes(item.City)}
                       onChange={(e) =>handleSelectAll(group2,e.target.checked,index)} 
                       />
                        {item?.City}</h4>

                        <ul className="location-filter-list-inner">
                          {item?.Area?.map((City, index1) => (
                            <li key={index1}>
                              <Checkbox color="primary" 
                               checked={isCheck.includes(City)}
                               onChange={(e) =>handleChange(City, e.target.checked,group2?.listAreas[index])}
                              />
                              {City}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="location-filter-list">
                    {group3?.listAreas?.map((item, index) => (
                      <div>
                        <h4><Checkbox color="primary" 
                       checked={selected.includes(item.City)}
                       onChange={(e) =>handleSelectAll(group3,e.target.checked,index)} 
                       />
                        {item?.City}</h4>

                        <ul className="location-filter-list-inner">
                          {item?.Area?.map((City, index1) => (
                            <li key={index1}>
                              <Checkbox color="primary" 
                               checked={isCheck.includes(City)}
                               onChange={(e) =>handleChange(City, e.target.checked,group3?.listAreas[index])}
                              />
                              {City}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!loading && <div className="location-filter-footer">
              <Button variant="outlined" color="primary" onClick={()=>{setIsCheck([]);props.setSelected([]);props.setSubArea([])}}>Clear all</Button>
              <Button variant="contained" color="primary" onClick={()=> {props.setSubArea(isCheck);props.setOpenLocations(false)}}>Save & Proceed</Button>
          </div>}

          {/* <ul className="location-filter-list">
            {data?.listAreas?.map((item, index) => (
              <li>
                <Checkbox color="primary" />
                {item?.City}

                <ul className="location-filter-list-inner">
                  {item?.Area?.map((City, index) => (
                    <li key={index}>
                      <Checkbox color="primary" />
                      {City}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>*/}
        </Box>
      </Modal>
    </div>
  );
}
