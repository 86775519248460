import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  // uri: "https://d3nukl1ekjeyzl.cloudfront.net/graphql",
  // uri: "https://d1ylemp9zd6rpk.cloudfront.net/graphql",
  // uri: "http://hr-staging.us-east-1.elasticbeanstalk.com/graphql",
   uri: process.env.REACT_APP_API_URL + "/graphql",
  // uri: "https://staging-api.hamptonsrentals.com/graphql",
  // uri: "https://prod-api.hamptonsrentals.com/graphql",
  //uri: "https://dev-api.hamptonsrentals.com/graphql",
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("@authToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});
// GraphQL Init
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
export default client;
