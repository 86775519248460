import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Autocomplete, {
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  LEADS_LIST,
  LIST_ALL_USERS,
} from "../../Graghql/queries";
import { userDeatils} from "../../RactiveVariables/index";
import SideBar from "../Common/sidebar";
import LoggedInHeader from "../Common/loggedinHeader";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

const LeadsList = (props) => {
  const history = useHistory();


  const [searchKeyword, setSearchKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [, setIsPaginating] = useState(false);
  const [agent, setAgent] = useState("All");
  const [type, setType] = useState("All");
  const [renderdetails, setRenderdetails] = useState("");
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const user = useReactiveVar(userDeatils);
  
  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setAgent(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const leadsListVariables = {
    search: searchKeyword,
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    agent: renderdetails?._id,
    userType: type === "All" ? "" : type,
  };
  const { data: agentData } = useQuery(LIST_ALL_USERS, {
    variables: { role: "agent", skip: 0, limit: 40, search: agent },
  });
  //console.log("ad======>", agentData);
  const agentType = [
    { label: "Owner", value: "owner" },
    { label: "Renter", value: "renter" },
  ];
  let agentName = agentData && [...agentData.listAllUsers];
  //  && agentData.listAllUsers.data;
  // console.log("a data", agentName);

  const {
    data,
    loading: leadsLoading,
    fetchMore,
  } = useQuery(LEADS_LIST, {
    fetchPolicy: "network-only",
    variables: leadsListVariables,
  });
  const handlePagination = () => {
    fetchMore({
      variables: leadsListVariables,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  React.useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, agent || type, searchKeyword]);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleCloseDelete = () => {
  //   setAnchorElDelete(null);
  // };

  let leadsListCount = data && data.leadsList && data.leadsList.count;
  let listLeads = data && data.leadsList && data.leadsList.data;
  // console.log("leaddslist", listLeads);
  // console.log("leaddslistCount",leadsListCount);
  // const changedStatus =
  //   defaultValue?.Status == "active" ? "inactive" : "active";

  function sortLabels(a, b) {
    var nameA = a.FirstName.toUpperCase();
    var nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  return (
    <div className="custom-layout">
      <LoggedInHeader label="Leads" />
      <div className="custom-layout-content">
        <div className="search-band search-band-properties ps-0">
          {/* <div className="search-band"> */}
          <form>
            <FormControl className="search-town ps-2">
              <TextField
                style={{ border: 0 }}
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Name, Type, Lead"
                type="search"
                onChange={(event) => setSearchKeyword(event.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>
            {/* </div> */}
            {/* <hr style={{ margin: "0px" }} /> */}

            {user?.data?.Role!=="agent"&&<FormControl className="autocompleteSearch">
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Agent
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={agent}
                onChange={handleChange}
                label="Agent"
                defaultValue="All"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="All">All</MenuItem>
                {agentName?.sort(sortLabels).map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item?.FirstName.charAt(0).toUpperCase() + item?.FirstName.slice(1).toLowerCase() + " " + item?.LastName.charAt(0).toUpperCase() + item?.LastName.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </Select> */}
              <InputLabel>Agent</InputLabel>
              <Autocomplete
                id="demo-simple-select-outlined"
                options={agentName?.sort(sortLabels) ?? []}
                inputValue={agent}
                label="Agent"
                //freeSolo
                getOptionLabel={(option) =>
                  option?.FirstName.charAt(0).toUpperCase() +
                  option?.FirstName.slice(1).toLowerCase() +
                  " " +
                  option?.LastName.charAt(0).toUpperCase() +
                  option?.LastName.slice(1).toLowerCase()
                }
                className="w-100 fullWidthSearch"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="All"/>
                )}
                //value={renderdetails?.Email}
                //value={error.suggest}
                onChange={(e, value) => {
                  setRenderdetails(value);
                  // setEmailError()
                }}
                onInputChange={(e, value) => {
                  setAgent(value);
                }}
                // filterOptions={(options, params) => {
                //  // const filtered = filter(options, params);
                //   setSearchKeyword(params.inputValue)
                //   // Suggest the creation of a new value
                //   // if (params.inputValue !== "") {
                //   //   filtered.push(params.inputValue);
                //   // }
                //   // return filtered;
                // }}
              />
            </FormControl>}

            <FormControl
            // variant="outlined" className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={type}
                onChange={handleTypeChange}
                label="Type"
                defaultValue="All"
              >
                <MenuItem value="All">All</MenuItem>
                {agentType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </div>

        <div style={{ marginTop: "0px" }} className="content-wrap">
          <SideBar selected="leads" />
          <div className="row">
            <div className="col-md-12">
              <div>
                <Table
                  className="common-table lead-table"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Property</TableCell>
                      <TableCell>Last Update</TableCell>
                      <TableCell className="text-left">Agent</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {leadsLoading ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Leads...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : listLeads && listLeads.length ? (
                      listLeads.map((item, index) => (
                        <TableRow
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push("/lead-detail/" + item?._id)
                          }
                          selected={props.selected === "lead-detail"}
                        >
                          <TableCell component="th" scope="row">
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="me-1"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  fontSize: "14px",
                                }}
                                src={item?.UserDtls?.ProfileImage}
                              >
                                {item?.UserDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase()}
                              </Avatar>
                              <label>
                                {" "}
                                {item?.UserDtls?.FirstName &&
                                  item?.UserDtls?.FirstName.charAt(
                                    0
                                  ).toUpperCase() +
                                    item?.UserDtls?.FirstName.slice(
                                      1
                                    ).toLowerCase() +
                                    " "}
                                {item?.UserDtls?.LastName &&
                                  item?.UserDtls?.LastName.charAt(
                                    0
                                  ).toUpperCase() +
                                    item?.UserDtls?.LastName.slice(
                                      1
                                    ).toLowerCase()}
                              </label>

                              {/* {item?.UserDtls && item?.UserDtls?.FirstName && <label> {item?.UserDtls && item?.UserDtls?.FirstName.charAt(0).toUpperCase() + item?.UserDtls?.FirstName.slice(1).toLowerCase() +
                                " " +
                                item?.UserDtls?.LastName.charAt(0).toUpperCase() + item?.UserDtls?.LastName.slice(1).toLowerCase()}
                              </label>} */}
                            </div>
                          </TableCell>

                          <TableCell>
                            {item?.UserType &&
                              item?.UserType.charAt(0).toUpperCase() +
                                item?.UserType.slice(1)}
                          </TableCell>
                          <TableCell>
                            {item?.LeadSrc && item?.LeadSrc}
                          </TableCell>
                          <TableCell>
                            {item?.OwnedPptyCount ? item?.OwnedPptyCount : "0"}
                          </TableCell>
                          <TableCell>
                            {item.UpdatedOn === null
                              ? "-"
                              : moment(
                                  new Date(parseInt(item.UpdatedOn))
                                ).format("lll")}
                          </TableCell>

                          <TableCell className="text-left">
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="me-1"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  fontSize: "14px",
                                }}
                                src={item?.AgentDtls?.ProfileImage}
                              >
                                {item?.AgentDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase()}
                              </Avatar>
                              {item?.AgentDtls?.FirstName && (
                                <label>
                                  {" "}
                                  {item?.AgentDtls?.FirstName.charAt(
                                    0
                                  ).toUpperCase() +
                                    item?.AgentDtls?.FirstName.slice(
                                      1
                                    ).toLowerCase() +
                                    " " +
                                    item?.AgentDtls?.LastName.charAt(
                                      0
                                    ).toUpperCase() +
                                    item?.AgentDtls?.LastName.slice(
                                      1
                                    ).toLowerCase()}
                                </label>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <IconButton className="lead-status-btn">
                              OPPORTUNITY
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : agent || type || searchKeyword ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Leads Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No leads Added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!leadsLoading && leadsListCount > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={leadsListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsList;
