import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  ListItemText,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import moment from "moment";
import {
  rentalPeriodOptions,
  bedRoomsOptions,
  bathroomOptions,
  mainAmenityOptions,
  tennisOptions,
  poolOptions,
  petsOptions,
  waterfrontOptions,
} from "../Properties/addProperty/options";
import Popover1 from "@material-ui/core/Popover";
import Popover2 from "@material-ui/core/Popover";
import PAndSHistory from "../Properties/propertyActions/PAndSHistory";
import AgentPrint from "../Properties/propertyActions/AgentPrint";
import CustomerPrint from "../Properties/propertyActions/CustomerPrint";
import EmailListing from "../Properties/propertyActions/EmailListing";
import Sold from "../Properties/propertyActions/Sold";
import Offthemarket from "../Properties/propertyActions/Off the Market";
import Featured from "../Properties/propertyActions/Featured";
import Suggest from "../Properties/propertyActions/Suggest";
import Delete from "../Properties/propertyActions/Delete";
import { useQuery, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Slider from "@material-ui/core/Slider";
import {
  PENDING_PROPERTY_LIST,
  PENDING_PROPERTIES_COUNT,
  PROPERTY_SEARCH,
} from "../../Graghql/queries";
import {
  APPROVE_DISAPPROVE_PPTY,
  LEAD_ACTIVITY_ADD,
} from "../../Graghql/mutations";
import LoggedInHeader from "../Common/loggedinHeader";
import Loader from "../Common/Loader";
import SideBar from "../Common/sidebar";
import CloseIcon from "@material-ui/icons/Close";
import DialogBox from "../Common/DialogBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import Locations from "../Properties/Locations";
// const useStyles = makeStyles((theme) => ({
//   menuPaper: {
//     maxHeight: 200,
//   },
// }));
import AddComment from "./../Properties/propertyActions/AddComment";
import CustomizedModal from "../Common/customizedModal";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: 450,
  },
  margin: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 100) return text.slice(0, 100) + " ...";
      else return text.slice(0, 100);
    } else return text;
  }
  if (!props.text) return null;
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 100 && (
        <label style={{cursor:'pointer'}} onClick={() => setVisible(!visible)}>
          {visible ? " Less" : " More"}
        </label>
      )}
    </div>
  );
}
const PendingList = (props) => {
  const user = useReactiveVar(userDeatils);
  const options = [
    { label: "Edit Listing", id: "EDIT" },
    { label: "Agent Print", id: "AgentPrint" },
    { label: "Customer Print", id: "CustomerPrint" },
    { label: "P & S History", id: "P&S" },
    { label: "Add Comment", id: "ADDCOMMENT" },
    { label: "Email Listing", id: "EmailListing" },
    { label: "View Listing", id: "ViewListing" },
    { label: "Sold", id: "Sold" },
    { label: " OTM/Inactive", id: "Off the Market" },
    { label: "Suggest", id: "Suggest" },
    { label: "Delete Listing", id: "Delete" },
    //   { label: "Feature", id: "Feature" },
    //  { label: "View Agreement", id: "ViewAgreement" },
  ];
  const optionsAgent = [
    { label: "Edit Listing", id: "EDIT" },
    { label: "Add Comment", id: "ADDCOMMENT" },
  ];
  const classes = useStyles();
  const history = useHistory();
  const rowsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPrice, setAnchorElPrice] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [propertyId, setPropertyId] = useState("");
  const [openPAndS, setOpenPAndS] = useState(false);
  const [itemDetails, setitemDetails] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPopUpDisapprove, setOpenPopUpDisapprove] = useState(false);
  const [openPopUpCheck, setOpenPopUpCheck] = useState(false);
  const [moreComment, setMoreComment] = useState(false);
  const [areaFilter, setAreaFilter] = useState("");
  const [rentalPeriodFilter, setRentalPeriodFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [bathFilter, setBathFilter] = useState("");
  const [keyWordSearch, setKeyWordSearch] = useState("");
  const [isCommercial, setIsCommercial] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [location, setLocation] = useState([]);
  const [amenityName, setAmenityName] = useState([]);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [waterfront, setWaterfront] = useState([]);
  const [amount, setAmount] = useState(["0", "1000000"]);
  const [tennis, setTennis] = useState("");
  const [pools, setPools] = useState("");
  const [pets, setPets] = useState("");
  const [style, setStyle] = useState([])
  const [openLocations, setOpenLocations] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variable1, setVariable1] = useState([]);
  const [variable2, setVariable2] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [propertyType, setPropertyType] = useState({
    residential: false,
    commercial: false,
    isPrivateProperty: false,
  });
  const [openClicked, setOpenClicked] = useState(true);
  const { register, handleSubmit, control, reset } = useForm();
  const [openAddComments, setOpenAddComments] = useState(false);
  const [approvalPropertyId, setApprovalPropertyId] = useState(false);
  const [pendingDeleteCheck, setPendingDeleteCheck] = useState("");
  const [openAgentPrint, setOpenAgentPrint] = useState(false);
  const [openCustomerPrint, setOpenCustomerPrint] = useState(false);
  const [, setAgentPrint] = useState([]);
  const [, setCustomerPrint] = useState([]);
  const [openEmailListing, setOpenEmailListing] = useState(false);
  const [openSold, setOpenSold] = useState(false);
  const [openOffthemarket, setOpenoffthemarket] = useState(false);
  const [openFeatured, setOpenFeatured] = useState(false);
  const [openSuggest, setOpenSuggest] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [status, setStatus] = useState("");

  const {
    data: propertyList,
    // fetchMore,
  } = useQuery(PROPERTY_SEARCH, {
    variables: { keywordSearch: keyWordSearch },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      // handleAvailableIDs();
    },
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  function formatePrice(value) {
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function formatePriceFilter(value) {
    if (value)
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // return value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function convertTimeStampWithTime(date) {
    // return moment(new Date(Number(date))).format("MMM DD,hh:mm");
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function formateTaxMap(value) {
    if (
      value?.Value1 &&
      value?.Value2 &&
      value?.Value3 &&
      value?.Value4 &&
      value?.Value5 &&
      value?.Value6 &&
      value?.Value7
    )
      return `${value?.Value1}/${value?.Value2}/${value?.Value3}/${value?.Value4}/${value?.Value5}/${value?.Value6}/${value?.Value7}`;
  }
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  function chechForPrice(priceArray) {
    console.log("price array", priceArray);
    let position = priceArray.findIndex(
      (element) =>
        element.AvblStatus.toLowerCase() === "yes" ||
        element.AvblStatus.toLowerCase() === "rented" ||
        element.AvblStatus.toLowerCase() === "no"
    );
    if (position === -1) return false;
    else return true;
  }
  //   const expirydate =
  //     item.RRExp && moment(item.RRExp).format("DD/MM/YYYY");
  const [viwedProperty] = useMutation(LEAD_ACTIVITY_ADD);
  const [approveDisapprove] =
    useMutation(APPROVE_DISAPPROVE_PPTY, {
      refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
      onCompleted: (res) => {
        refetch();
      },
    });

  const pendingListVariables = {
    skip: (page-1) * rowsPerPage,
    limit: rowsPerPage,
    //selectedArea: areaFilter,
    //selectedPeriod: rentalPeriodFilter,
    selectedPrice: priceFilter,
    selectedBed: parseFloat(bedFilter),
    selectedBath: parseFloat(bathFilter),
    keywordSearch: keyWordSearch,
    rentalPeriods: rentalPeriod,
    locations: variable1,
    isPrivate: isPrivate,
    amenities: amenityName,
    //isCommercial: isCommercial,
    //isResidential: isResidential,
    listWater: waterfront,
    listTennis: tennis,
    listPool: pools,
    listPet: pets,
    listStyle:style,
    subArea: variable2,
    status: status,
  };
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    fetchMore({
      variables: pendingListVariables,
    });
  };
  const {
    data: pendingList,
    loading,
    fetchMore,
    refetch,
  } = useQuery(PENDING_PROPERTY_LIST, {
    fetchPolicy: "network-only",
    variables: pendingListVariables,
  });
  const pendingPropertyLists =
    pendingList &&
    pendingList.pendingPropertiesList &&
    pendingList.pendingPropertiesList.data;

  const pendingPropertyCount =
    pendingList?.pendingPropertiesList &&
    pendingList?.pendingPropertiesList?.count;
  //console.log("pendinggg", pendingPropertyLists, pendingPropertyCount);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleSearch(params) {
    //console.log(",,,,,,,,,,", params);
    setVariable2(isCheck);
    setVariable1(selected);
    if (openClicked === false) setOpenClicked(!openClicked);
    if (params.search) {
      setKeyWordSearch(params.search);
    } else {
      setKeyWordSearch("");
    }
    if (params.area) {
      if (params.area === "Any Area") setAreaFilter("");
      else setAreaFilter(params.area);
    }
    if (params.period) {
      if (params.period === "Any Periods") setRentalPeriodFilter("");
      else setRentalPeriodFilter(params.period);
    }
    setPriceFilter(amount);
    // if (params.price) {
    //   if (params.price === "Any Price") setPriceFilter("");
    //   else setPriceFilter(params.price);
    // }
    if (params.bed) {
      if (params.bed === "Any Beds") setBedFilter("");
      else setBedFilter(params.bed);
    }
    if (params.bath) {
      if (params.bath === "Any Baths") setBathFilter("");
      else setBathFilter(params.bath);
    }

    if (params.tennis) {
      if (params.tennis === "Any Tennis") setTennis("");
      else setTennis(params.tennis);
    }
    if (params.pools) {
      if (params.pools === "Any Pools") setPools("");
      else setPools(params.pools);
    }
    if (params.pets) {
      if (params.pets === "Any Pets") setPets("");
      else setPets(params.pets);
    }
    if (params.status) {
      if (params.status === "Any Status") setStatus("");
      else setStatus(params.status);
    }
    if (params.location) {
      setLocation(params.location);
    }
    if (params.rentalPeriod) {
      setRentalPeriod(params.rentalPeriod);
    }
    if (params.amenity) {
      setAmenityName(params.amenity);
    }
    if (params.waterfront) {
      setWaterfront(params.waterfront);
    }
    if (params.style) {
      setStyle(params.style);
    }
    setIsPrivate(propertyType.isPrivateProperty);
    setIsCommercial(propertyType.commercial);
    setIsResidential(propertyType.residential);
  }

  // eslint-disable-next-line no-unused-vars
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  const handleApprove = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: propertyId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapprove = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: propertyId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  const handleApproveCheck = (itemDetails) => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemDetails?.PropertyId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addPendingProperty/${itemDetails?.PropertyId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemDetails?.PropertyId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addPendingProperty/${itemDetails?.PropertyId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleViewListing = () => {
    try {
      viwedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property View",
          LeadType: "View",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [itemDetails?._id],
        },
      })
        .then((res) => {
          // toggleSnackbar({
          //   status: true,
          //   message: "You have been successfully saved property",
          //   variant: "success",
          // });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleMenuChange = (type, item, target) => {
    console.log("item.....", item?._id);

    item && item._id && setitemDetails(item._id);
    item && setitemDetails(item);
    if (item) {
      setitemDetails(item);
      setAgentPrint([item]);
      setCustomerPrint([item]);
    }
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "MENU":
        setAnchorEl(target);
        break;
      case "AgentPrint":
        setOpenAgentPrint(true);
        setAnchorEl(false);
        break;
      case "CustomerPrint":
        setOpenCustomerPrint(true);
        setAnchorEl(false);
        break;
      case "EDIT":
        //setPropertyId(itemDetails?.PropertyId)
        user?.data?.Role === "admin" || user?.data?.Role === "agentAdmin"
          ? setOpenPopUpCheck(true)
          : history.push(`/addPendingProperty/${itemDetails?.PropertyId}`);
        setAnchorEl(false);
        break;
      case "P&S":
        setOpenPAndS(true);
        setAnchorEl(false);
        break;
      case "ADDCOMMENT":
        setApprovalPropertyId(true);
        setOpenAddComments(true);

        setAnchorEl(false);
        break;
      case "ViewListing":
        // eslint-disable-next-line eqeqeq
        user?.data?.Role == "admin" || user?.data?.Role == "agentAdmin"
          ? window.open(itemDetails?.PropertySlug, "_blank")
          : history.push(itemDetails?.PropertySlug);
        handleViewListing();

        // document.getElementById("view-Listing").click();
        //history.push("/property-detail/" + itemDetails?._id);
        setAnchorEl(false);
        break;
      case "EmailListing":
        setOpenEmailListing(true);
        setAnchorEl(false);
        break;
      case "Sold":
        setOpenSold(true);
        setAnchorEl(false);
        break;
      case "Off the Market":
        setOpenoffthemarket(true);
        setAnchorEl(false);
        break;
      case "Feature":
        setOpenFeatured(true);
        setAnchorEl(false);
        break;
      case "Suggest":
        setOpenSuggest(true);
        setAnchorEl(false);
        break;
      case "ViewAgreement":
        // user?.data?.Role == "admin"
        // ? window.open(itemDetails?._id, "_blank")
        // :
        history.push("/agreement/" + itemDetails?._id);

        //setOpenAgreement(true);
        setAnchorEl(false);
        break;
      case "Delete":
        setOpenDelete(true);
        setAnchorEl(false);
        break;
    }
  };
  const handleClick = (event) => {
    setAnchorElPrice(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    // console.log("nv", newValue)
    let strArray = newValue;
    let strArr = strArray.map(function (e) {
      return e.toString();
    });
    setAmount(strArr);
  };
  const handleClosePrice = () => {
    setAnchorElPrice(null);
  };
  const openPrice = Boolean(anchorElPrice);
  const id = openPrice ? "simple-popover" : undefined;
  function valuetext(value) {
    return value;
  }
  const followersMarks = [
    {
      value: 1000,
      scaledValue: 1000,
      label: "1k",
    },
    {
      value: 5000,
      scaledValue: 5000,
      label: "5k",
    },
    {
      value: 10000,
      scaledValue: 10000,
      label: "10k",
    },
    {
      value: 25000,
      scaledValue: 25000,
      label: "25k",
    },
    {
      value: 50000,
      scaledValue: 50000,
      label: "50k",
    },
    {
      value: 100000,
      scaledValue: 100000,
      label: "100k+",
    },
    // {
    //   value: 250000,
    //   scaledValue: 250000,
    //   label: "250k"
    // },
    // {
    //   value: 500000,
    //   scaledValue: 500000,
    //   label: "500k"
    // },
    // {
    //   value: 1000000,
    //   scaledValue: 1000000,
    //   label: "1M+"
    // }
  ];
  // eslint-disable-next-line no-unused-vars
  const scale = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  // function numFormatter(num) {
  //   if (num > 999 && num < 1000000) {
  //     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  //   } else if (num >= 1000000) {
  //     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  //   }else if (num < 900) {
  //     return num; // if value < 1000, nothing to do
  //   }
  // }
  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 100000) {
      return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  //console.log("itemDetails------>", itemDetails);
  const handleClearAll = () => {
    setAreaFilter("");
    setBedFilter("");
    setBathFilter("");
    setRentalPeriodFilter("");
    setPriceFilter("");
    setKeyWordSearch("");
    setVariable1([]);
    setVariable2([]);
    setSelected([]);
    setSubArea([]);
    setIsCheck([]);
    setRentalPeriod([]);
    setIsPrivate(false);
    setAmenityName([]);
    setIsCommercial(false);
    setIsResidential(false);
    setWaterfront([]);
    setTennis("");
    setPools("");
    setPets("");
    setStatus("");
    setStyle([]);
    setAmount(["0", "1000000"]);
    setPropertyType({
      residential: false,
      commercial: false,
      isPrivateProperty: false,
    });
  };
  //  function formatePrices(value) {
  //   return value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // }
  const NumberChecking = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === "" || re.test(e)) {
      return e;
    } else {
      let newVal = e.replace(/\D/g, "");
      return newVal;
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleEmailIDCheck = (id) => {
    let pos = propertyList?.propertySearch?.data.findIndex(
      (element) => element?._id === id
    );
    console.log("pos------->", pos);
    if (pos > -1) {
      return [propertyList?.propertySearch?.data[pos]];
    }
  };
const handlePriceCheck=(value)=>{
 let chkData= value?.map((items)=>items?.PrePrice===0)
 console.log(",,,,,",chkData)
  if(chkData===true)
 return true
  else return false
}
  return (
    <div className="custom-layout">
      {/* // ----Code added for comment pop up By Anu on Oct 12 */}
      {openAddComments && (
        <CustomizedModal
          hideHeader={true}
          component={AddComment}
          open={openAddComments}
          setOpenAgentPrint={setOpenAddComments}
          propertyId={itemDetails._id}
          //propertyIdPendingApproval={itemDetails.PropertyId}
          approvalPropertyId={approvalPropertyId}
          data={itemDetails}
          handlePagination={handlePagination}
        />
      )}
      {/* // ---- Ends here */}
      {openLocations && (
        <Locations
          openLocations={openLocations}
          setOpenLocations={setOpenLocations}
          setLocation={setLocation}
          setSubArea={setSubArea}
          setSelected={setSelected}
          selected={selected}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
          // data= {data?.leadsDetails?.UserDtls?.Email}
          // recipientId={data?.leadsDetails?.UserId}
        />
      )}
      <Loader open={loading} />
      <LoggedInHeader
        //handleAddBtnClick={handleAddBtnClick}
        label="Pending Approval"
        //addBtnLabel="Add Property"
      />
      <div className="custom-layout-content">
        <div className="search-band search-band-properties ps-0">
          <form onSubmit={handleSubmit(handleSearch)}>
            <FormControl className="search-town ps-2">
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Ad's Title, Address, PID"
                {...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>
            {/* <FormControl className="search-town ps-2" onClick={()=>setOpenLocations(true)}>
              <TextField
                disabled={true}
                id="standard-full-width"
                className="fullWidthSearch"
                label="LOCATION"
                disableUnderline={false}
                placeholder="City/Town"
                {...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl> */}
            <FormControl
              className="search-town ps-2"
              onClick={() => setOpenLocations(true)}
              style={{cursor:'pointer'}}
            >
              {/* <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel> */}
              {/* <Controller
                control={control}
                name="location"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      // variant="outlined"
                      value={value && value?.length > 0 ? value : [""]}
                      onChange={(e) =>
                        onChange(
                          e.target.value[0] === ""
                            ? [e.target.value[1]]
                            : e.target.value
                        )
                      }
                      // input={<Input />}
                      renderValue={(selected) =>
                        selected[0] === ""
                          ? "Locations"
                          : ` Locations (${selected.length} selected)`
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} disabled value={""}>
                        Locations
                      </MenuItem>
                      {areaOptions?.sort(sortLabels).map((item) => (
                        <MenuItem
                          className="advance-filter-listItem"
                          key={item.value}
                          value={item.value}
                        >
                          <Checkbox
                            color="primary"
                            checked={value?.indexOf(item.value) > -1}
                          />
                          <ListItemText primary={item.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              /> */}
              <TextField
                // disabled={true}
                id="standard-full-width"
                className="fullWidthSearch"
                label="LOCATION"
                disableUnderline={false}
                onClick={() => setOpenLocations(true)}
                placeholder={
                  selected.length > 0 || subArea.length > 0
                    ? selected.length + subArea.length + " Selected"
                    : "City/Town"
                }
                // {...register("search")}
                fullWidth
                style={{pointerEvents:'none'}}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
              {/* <InputLabel>TOWN</InputLabel>
              <Select
                defaultValue="Any Area"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("area")}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value="Any Area">{"Which Area?"}</MenuItem>
                {areaOptions?.sort(sortLabels).map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
            <FormControl>
              <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
              <Controller
                control={control}
                name="rentalPeriod"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      // variant="outlined"
                      value={value && value?.length > 0 ? value : [""]}
                      onChange={(e) =>
                        onChange(
                          e.target.value[0] === ""
                            ? [e.target.value[1]]
                            : e.target.value
                        )
                      }
                      // input={<Input />}
                      renderValue={(selected) =>
                        selected[0] === ""
                          ? "Periods"
                          : ` Periods (${selected.length} selected)`
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} disabled value={""}>
                        Periods
                      </MenuItem>
                      {rentalPeriodOptions.map((item) => (
                        <MenuItem
                          className="advance-filter-listItem"
                          key={item.RentalPeriod}
                          value={item.RentalPeriod}
                        >
                          <Checkbox
                            color="primary"
                            checked={value?.indexOf(item.RentalPeriod) > -1}
                          />
                          <ListItemText primary={item.RentalPeriod} />
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <div
                className="price-filter-box-outer"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <b style={{ cursor: "pointer" }} className="price-filter-label">
                  PRICE
                </b>
                {/* <div style={{ cursor: "pointer" }} className="price-filter-box">
                  {amount[0] === "0" && amount[1] === "1000000"
                    ? "Any Price "
                    : "$" + formatePrices(amount[0]) + " - " + "$" + formatePrices(amount[1])}
                  <ArrowDropDownIcon />
                </div> */}
                <div style={{ cursor: "pointer" }} className="price-filter-box">
                  {amount[0] === "0" && amount[1] === "1000000"
                    ? "Any Price "
                    : amount[0] === ""
                    ? `$ - $ ${amount[1]}` &&
                      formatePriceFilter(amount[1])
                    : amount[1] === ""
                    ? "$" + amount[0] &&
                      `${formatePriceFilter(amount[0])} - $`
                    : amount[0] === "" && amount[1] === ""
                    ? `$ - $`
                    : "$" +
                      formatePriceFilter(amount[0]) +
                      " - " +
                      "$" +
                      formatePriceFilter(amount[1])}
                  <ArrowDropDownIcon />
                </div>
              </div>
              <Popover2
                className="price-filter"
                id={id}
                open={openPrice}
                anchorEl={anchorElPrice}
                onClose={handleClosePrice}
                anchorPosition={{ top: 2000, left: 400 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography className={classes.typography}>
                  <div className={classes.root}>
                    <Typography id="range-slider" gutterBottom>
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginLeft: "460px",
                          marginTop: "5px",
                        }}
                        onClick={() => handleClosePrice()}
                      />
                      Price range
                    </Typography>
                    <Slider
                      value={amount}
                      step={1}
                      valueLabelFormat={numFormatter}
                      marks={followersMarks}
                      // scale={scale}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      max={100000}
                      min={0}
                    />
                  </div>
                </Typography>
                <div style={{ display: "flex" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    {/* <InputLabel >Min</InputLabel> */}
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={formatePriceFilter(amount[0])}
                      placeholder="Min"
                      onChange={(e) => {
                        setAmount([NumberChecking(e.target.value), amount[1]]);
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      labelWidth={30}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    {/* <InputLabel >Max</InputLabel> */}
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      placeholder="Max"
                      value={formatePriceFilter(amount[1])}
                      onChange={(e) => {
                        setAmount([amount[0], NumberChecking(e.target.value)]);
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      labelWidth={30}
                    />
                  </FormControl>
                </div>
              </Popover2>
              {/* <InputLabel>PRICE</InputLabel>
              <Select
                defaultValue="Any Price"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("price")}
              >
                <MenuItem value="Any Price">{"Any Price"}</MenuItem>
                {priceOptions.map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
            <FormControl>
              <InputLabel>BED</InputLabel>
              <Controller
                control={control}
                name="bed"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      value={value ? value : "Any Beds"}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      renderValue={(selected) =>
                        selected === "" ? "Any Beds" : selected
                      }
                      MenuProps={MenuProps}
                      // defaultValue="Any Beds"
                      // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      // {...register("bed")}
                    >
                      <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                      {bedRoomsOptions.map((item) => (
                        <MenuItem value={item.label}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>

            <FormControl>
              <InputLabel>BATH</InputLabel>
              <Controller
                control={control}
                name="bath"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      value={value ? value : "Any Baths"}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      renderValue={(selected) =>
                        selected === "" ? "Any Baths" : selected
                      }
                      MenuProps={MenuProps}
                      // defaultValue="Any Baths"
                      // MenuProps={{ classes: { paper: classes.menuPaper } }}
                      // {...register("bath")}
                    >
                      <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                      {bathroomOptions.map((item) => (
                        <MenuItem value={item.label}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
            <FormControl className="search-btn-outer">
              <Button
                className="search-btn"
                variant="contained"
                type="submit"
                // onClick={()=>{setOpenClicked(!openClicked)}}
              >
                Search
              </Button>
              {/* <MoreHorizIcon  onClick={()=>{setOpenClicked(!openClicked)}}/> */}
              <label
                onClick={() => {
                  setOpenClicked(!openClicked);
                }}
                className="advanced-filter-btn"
              >
                {openClicked === true ? <>More</> : <>Less</>}
                {openClicked === true ? (
                  <img
                    src="../assets/images/more-open.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                ) : (
                  <img
                    src="../assets/images/more-close.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                )}
              </label>
              <Button
                type="submit"
                onClick={() => {
                  reset();
                  handleClearAll();
                }}
                className="clear-filter p-2"
                style={{ minWidth: "auto" }}
                data-toggle="tooltip"
                title="Clear Filter"
              >
                <img
                  src="../assets/images/icon-reset.svg"
                  className="img-fluid"
                  alt="Clear Filter"
                />
              </Button>
            </FormControl>
          </form>
        </div>
        {!openClicked && (
          <div>
            <div className="advance-filter advance-filter-admin">
              <form onSubmit={handleSubmit(handleSearch)}>
                <div className="row">
                  <h6>Advanced Filter</h6>
                  {/* <div className="col-md-12 ms-2">
                  <h6>Property Type</h6>
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.residential}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="residential"
                        color="primary"
                      // {...register("residential")}
                      />
                    }
                    label="Residential"
                  />
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.commercial}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="commercial"
                        color="primary"
                      // {...register("commercial")}
                      />
                    }
                    label="Commercial"
                  />
                </div> */}
                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-checkbox-label">AMENITIES</InputLabel> */}
                      <Controller
                        control={control}
                        name="amenity"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value.length)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Amenities?"
                                  : ` Amenities (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Amenities
                              </MenuItem>
                              {mainAmenityOptions.map((name) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={name.value}
                                  value={name.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(name.value) > -1}
                                  />
                                  <ListItemText primary={name.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />

                      {/* <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            variant="oulined"
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={value ? value : []}
                            onChange={(e) => onChange(e.target.value)}
                            // onChange={(e)=>{setAmenityName(e.target.value)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} className={classes.chip} />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {amenities.map((amenity) => (
                              <MenuItem key={amenity.value} value={amenity.value} >
                                {amenity.label}
                              </MenuItem>
                            ))}
                          </Select>)
                      }}
                    /> */}
                    </FormControl>
                  </div>
                  {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
                    <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Periods" : ` Periods (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Periods</MenuItem>
                            {rentalPeriodOptions.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                                <Checkbox color="primary" checked={value?.indexOf(item.RentalPeriod) > -1} />
                                <ListItemText primary={item.RentalPeriod} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}
                  {/* <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rentalPeriodOptions.map((item) => (
                            <MenuItem key={item.RentalPeriod} value={item.RentalPeriod}>
                              {item.RentalPeriod}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                  {/* </FormControl>
                </div> */}
                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-checkbox-label">WATERFRONT</InputLabel> */}
                      <Controller
                        control={control}
                        name="waterfront"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Waterfronts?"
                                  : ` Waterfronts (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Waterfronts
                              </MenuItem>
                              {waterfrontOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={item.value}
                                  value={item.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(item.value) > -1}
                                  />
                                  <ListItemText primary={item.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                      {/* <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {waterfrontOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                    </FormControl>
                  </div>

                  {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                    <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Locations" : ` Locations (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Locations</MenuItem>
                            {areaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}

                  {/* <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setLocation(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {areaOptions?.sort(sortLabels).map((item) => (
                            <MenuItem value={item.label}>{item.value}</MenuItem>
                          ))}

                        </Select>)}
                    /> */}
                  {/* </FormControl>
                </div> */}

                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-checkbox-label">TENNIS</InputLabel> */}
                      <Controller
                        control={control}
                        name="tennis"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              // input={<Input/>}
                              variant="outlined"
                              value={
                                value ? value : tennis ? tennis : "Any Tennis"
                              }
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              renderValue={(selected) =>
                                selected === "" ? "Any Tennis" : selected
                              }
                              MenuProps={MenuProps}
                              // defaultValue={tennis === "" ? "Any Tennis" : tennis}
                              // MenuProps={MenuProps}
                              // {...register("tennis")}
                            >
                              <MenuItem
                                style={{ color: "#6e7073" }}
                                value="Any Tennis"
                              >
                                {"Tennis?"}
                              </MenuItem>
                              {tennisOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  value={item.value}
                                >
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </div>

                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-checkbox-label">POOL</InputLabel> */}
                      <Controller
                        control={control}
                        name="pools"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              // input={<Input/>}
                              variant="outlined"
                              value={
                                value ? value : pools ? pools : "Any Pools"
                              }
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              renderValue={(selected) =>
                                selected === "" ? "Any Pools" : selected
                              }
                              MenuProps={MenuProps}
                              // defaultValue={pools === "" ? "Any Pools" : pools}
                              // MenuProps={MenuProps}
                              // {...register("pools")}
                            >
                              <MenuItem
                                style={{ color: "#6e7073" }}
                                value="Any Pools"
                              >
                                {"Pool?"}
                              </MenuItem>
                              {poolOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  value={item.value}
                                >
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                      <Controller
                        control={control}
                        name="pets"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              variant="outlined"
                              value={value ? value : pets ? pets : "Any Pets"}
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              renderValue={(selected) =>
                                selected === "" ? "Any Pets" : selected
                              }
                              MenuProps={MenuProps}
                              // input={<Input/>}
                              // defaultValue={pets === "" ? "Any Pets" : pets}
                              // MenuProps={MenuProps}
                              // {...register("pets")}
                            >
                              <MenuItem
                                style={{ color: "#6e7073" }}
                                value="Any Pets"
                              >
                                {"Pets?"}
                              </MenuItem>
                              {petsOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  value={item.value}
                                >
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </div>

                  <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              variant="outlined"
                              value={
                                value === "requested"
                                  ? "Pending Approval"
                                  : value === "Pending Off the Market"
                                  ? "Pending OTM/Inactive"
                                  : value
                                  ? value
                                  : status
                                  ? status
                                  : "Any Status"
                              }
                              onChange={(e) => {
                                onChange(e.target.value);
                              }}
                              renderValue={(selected) =>
                                selected === "" ? "Any Status" : selected
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                style={{ color: "#6e7073" }}
                                value="Any Status"
                              >
                                {"Status?"}
                              </MenuItem>
                              <MenuItem
                                className="advance-filter-listItem"
                                value="requested"
                              >
                                Pending Approval
                              </MenuItem>
                              <MenuItem
                                className="advance-filter-listItem"
                                value="Pending Delete"
                              >
                                Pending Delete
                              </MenuItem>
                              <MenuItem
                                className="advance-filter-listItem"
                                value="Pending Sold"
                              >
                                Pending Sold
                              </MenuItem>
                              <MenuItem
                                className="advance-filter-listItem"
                                value="Pending Off the Market"
                              >
                                Pending OTM/Inactive
                              </MenuItem>
                              <MenuItem
                                className="advance-filter-listItem"
                                value="Pending On The Market"
                              >
                                Pending On The Market
                              </MenuItem>
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                control={control}
                name="style"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      variant="outlined"
                      // value={ value ? value  :style ? style:"Any Style"}
                      // onChange={(e) => {onChange(e.target.value )}}
                      // renderValue={(selected) => selected=== "" ? "Any Style" : selected}
                      MenuProps={MenuProps}
                       value={value && value?.length > 0 ? value : [""]}
                       onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                       renderValue={(selected) => selected[0] === "" ? "Style?" : selected}
                      // input={<Input/>}
                      // defaultValue={status === "" ? "Any Status" : status}
                      // MenuProps={MenuProps}
                      // {...register("status")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} key={0} value={[]}>{"Style?"}</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="Condo/Co-op">Condo/Co-op</MenuItem>
                    </Select>
  )
}}
/>
                  </FormControl>
                </div>
                  <div className="col-md-12 mb-2 ms-2">
                    <h6 className="mt-3">Property List as</h6>
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={propertyType.isPrivateProperty}
                          onChange={(e) =>
                            setPropertyType({
                              ...propertyType,
                              [e.target.name]: e.target.checked,
                            })
                          }
                          name="isPrivateProperty"
                          color="primary"
                          // {...register("isPrivateProperty")}
                        />
                      }
                      label="Private"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="content-wrap">
          {pendingPropertyCount > 0 && (
            <label className="admin-property-count">
              {pendingPropertyCount + " results"}
            </label>
          )}
          <SideBar selected="pendingApproval" count={pendingPropertyCount} />
          <div className="adminListing">
            {pendingPropertyLists && pendingPropertyLists.length
              ? pendingPropertyLists?.map((item, index) => {
                  //   var arr1=item?.EditedKeys
                  //   var obj1=item?.PropertyEditedFields
                  //  let editedOnlyValue=(arr1,obj1)=> arr1?.reduce((r,e)=>Object.assign(r, obj1[e] ? {[e]: obj1[e]} : null), {})

                  return (
                    <div className="adminListingItem">
                      <div className="al-itemImg-initialDetails">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="al-itemImg">
                              <img
                                src={getFeaturedImage(
                                  item?.propertyDtls?.Images
                                )}
                                className="img-fluid"
                                alt=""
                              />
                              {console.log("item===========>>", item)}
                              {item?.propertyDtls?.IsPrivate && (
                                <label className="exclusive-label">
                                  {item?.propertyDtls?.IsPrivate === true
                                    ? "Exclusive"
                                    : null}
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="al-initialDetails">
                              <div className="al-head">
                                <div>
                                  {/* {property?.IsFeatured===true&&<div className="adminFeaturedTag">Featured</div>} */}
                                  <address>
                                    PID#{item?.propertyDtls?.PID}{" "}
                                    {item?.propertyDtls?.StreetAddress}{", "}{item?.propertyDtls?.Area}
                                  </address>
                                  <h3 style={{ color: "#A64C48" }}>
                                    {item?.propertyDtls?.AdTitle}
                                    {/* {item?.AdTitle?.charAt(0).toUpperCase() +
                                    item?.AdTitle?.slice(1).toLowerCase()} */}
                                  </h3>
                                  {item?.propertyDtls?.ApprovedPriceData
                                    ?.SalesPrice &&
                                  item?.propertyDtls?.ApprovedPriceData
                                    ?.SalesPrice !== 0 ? (
                                    <h6>
                                      SalePrice:{" "}
                                      <font>
                                        $
                                        {formatePrice(
                                          item?.propertyDtls?.ApprovedPriceData
                                            ?.SalesPrice
                                        )}
                                      </font>
                                    </h6>
                                  ) : null}
                                </div>
                                <div className="al-head-btn">
                                  {item?.Status && (
                                    <Button
                                      type="submit"
                                      color="sucess"
                                      variant="contained"
                                      className="green-btn"
                                    >
                                      {item?.Status === "requested"
                                        ? "Pending Approval"
                                        : item?.Status ===
                                          "Pending Off the Market"
                                        ? "Pending OTM/Inactive"
                                        : item?.Status?.charAt(
                                            0
                                          ).toUpperCase() +
                                          item?.Status?.slice(1).toLowerCase()}
                                    </Button>
                                  )}
                                  <Button
                                    style={{ backgroundColor: "#000" }}
                                    onClick={(e) =>
                                      handleMenuChange(
                                        "MENU",
                                        item,
                                        e.currentTarget
                                      )
                                    }
                                  >
                                    <img
                                      src="../assets/images/dot-group.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                  </Button>
                                </div>
                              </div>
                              {item?.propertyDtls?.AdDescription && (
                                <TexttView
                                  text={
                                    item?.propertyDtls?.AdDescription?.charAt(
                                      0
                                    ).toUpperCase() +
                                    item?.propertyDtls?.AdDescription?.slice(
                                      1
                                    ).toLowerCase()
                                  }
                                />
                                // <p>
                                //   {item?.AdDescription.charAt(0).toUpperCase() +
                                //     item?.AdDescription.slice(1).toLowerCase()}
                                // </p>
                              )}
                              {item?.propertyDtls?.ListMainAmenity && (
                                <ul className="al-style propertyStyleTag">
                                  {item?.propertyDtls?.ListMainAmenity &&
                                    item?.propertyDtls?.ListMainAmenity.map(
                                      (items) =>
                                        items?.Flag === true && (
                                          <li
                                            style={{
                                              backgroundColor: "#A64C48",
                                              color: "#fff",
                                            }}
                                          >
                                            {items?.Title}
                                          </li>
                                        )
                                      // items?.Flag === true ? (
                                      //   <li
                                      //     style={{
                                      //       backgroundColor: "#A64C48",
                                      //       color: "#fff",
                                      //     }}
                                      //   >
                                      //     {items?.Title}
                                      //   </li>
                                      // ) : items.Flag === false ? (
                                      //   <li
                                      //     style={{
                                      //       backgroundColor: "#A64C48",
                                      //       color: "#fff",
                                      //     }}
                                      //   >
                                      //     <del>{items?.Title}</del>
                                      //   </li>
                                      // ) : (
                                      //   ""
                                      // )
                                    )}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="al-aminities-price">
                        <ul>
                          {item?.propertyDtls?.ListBedRoom && (
                            <li>
                              <label>Bed:</label>
                              <span>{item?.propertyDtls?.ListBedRoom}</span>
                            </li>
                          )}
                          {/* {(item?.propertyDtls?.ListBedRoom ||
                          item?.PropertyEditedFields?.ListBedRoom) && (
                          <li>
                            <label>Bed:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListBedRoom}
                              </del>
                              <span className="updated-value">
                                {item.ListBedRoom}
                              </span>
                            </div>
                          </li>
                        )} */}
                          {/* {item?.PropertyEditedFields?.ListBedRoom && (
                        <li>
                        <label>Bed:</label> 
                          {item?.PropertyEditedFields?.ListBedRoom}
                        </li>
                      )} */}
                          {item?.propertyDtls?.ListBathRoom && (
                            <li>
                              <label>Bath:</label>

                              <span>{item?.propertyDtls?.ListBathRoom}</span>
                            </li>
                          )}
                          {item.propertyDtls?.ListStyle && (
                            <li>
                              <label>Style:</label>
                              <span>{item.propertyDtls?.ListStyle}</span>
                            </li>
                          )}
                          {item.propertyDtls?.LivingArea && (
                            <li>
                              <label>Living Area:</label>
                              <span>{item.propertyDtls?.LivingArea}</span>
                            </li>
                          )}
                          {item.propertyDtls?.YearBuilt && (
                            <li>
                              <label>Year Build:</label>
                              {item.propertyDtls?.YearBuilt && (
                                <span>{item.propertyDtls?.YearBuilt}</span>
                              )}
                            </li>
                          )}
                          {item.propertyDtls?.ListHeat && (
                            <li>
                              <label>Heat:</label>
                              <span>{item.propertyDtls?.ListHeat}</span>
                            </li>
                          )}
                          {item.propertyDtls?.ListGarage && (
                            <li>
                              <label>Garage:</label>
                              <span>{item.propertyDtls?.ListGarage}</span>
                            </li>
                          )}
                          {item.propertyDtls?.ListBasement && (
                            <li>
                              <label>Basement:</label>
                              <span>{item.propertyDtls?.ListBasement}</span>
                            </li>
                          )}
                          {item.propertyDtls?.ListAirCondition && (
                            <li>
                              <label>A/C:</label>
                              <span>{item.propertyDtls?.ListAirCondition}</span>
                            </li>
                          )}
                          {item.propertyDtls?.LotSize && (
                            <li>
                              <label>Lot Size:</label>
                              <span>{item.propertyDtls?.LotSize}</span>
                            </li>
                          )}
                          {item.propertyDtls?.BedBathConfiguration && (
                            <li>
                              <label>Bed Bath Configuration:</label>
                              <span>
                                {item.propertyDtls?.BedBathConfiguration}
                              </span>
                            </li>
                          )}
                            {item.propertyDtls?.ListPool && (
                            <li>
                              <label>Pool:</label>
                              <span>
                                {item.propertyDtls?.ListPool}
                              </span>
                            </li>
                          )}
                          {item.propertyDtls?.ListStory && (
                            <li>
                              <label>Stories:</label>
                              <span>{item.propertyDtls?.ListStory}</span>
                            </li>
                          )}
                          {/* {formateTaxMap(item.propertyDtls?.SuffCoTaxMap)  && (
                          <li>
                            <label>TaxMap:</label>
                              <span >
                                {formateTaxMap(item.propertyDtls?.SuffCoTaxMap)}
                              </span>
                          </li>
                        )} */}
                          {item.propertyDtls?.RentalRegisteryId && (
                            <li>
                              <label>RRN ID:</label>
                              <span>
                                {item.propertyDtls?.RentalRegisteryId}
                              </span>
                            </li>
                          )}

                          {/* 
    {(item.RRExp || item?.PropertyEditedFields?.RRExp) && (
                          <li>
                            <label>RR Expires on:</label>
                            <div className="updated-figure">
                              {item?.PropertyEditedFields?.RRExp && (
                                <del>
                                  {typeof item?.PropertyEditedFields?.RRExp=="object"?moment(
                                    item?.PropertyEditedFields?.RRExp
                                  ).format("MM/DD/YYYY"):moment(new Date(parseInt( item?.PropertyEditedFields?.RRExp))).format(
                                    "MM/DD/YYYY"
                                  )}
                                </del>
                              )}
                              <span className="updated-value">
                                {typeof item.RRExp=="object"?moment(item.RRExp).format("MM/DD/YYYY"):moment(new Date(parseInt(item.RRExp))).format(
                                    "MM/DD/YYYY"
                                  )}
                              </span>
                            </div>
                          </li>
                        )} */}

                          {/* {(item.RRExp || item?.PropertyEditedFields?.RRExp) && (
                          <li>
                            <label>RR Expires on:</label>
                            <div className="updated-figure">
                              {item?.PropertyEditedFields?.RRExp && (
                                <del>
                                  {moment(
                                    item?.PropertyEditedFields?.RRExp
                                  ).format("MM/DD/YYYY")}
                                </del>
                              )}
                              <span className="updated-value">
                                {moment(item.RRExp).format("MM/DD/YYYY")}
                              </span>
                            </div>
                          </li>
                        )} */}
                          {item.propertyDtls?.RRExp && (
                            <li>
                              <label>RR Expires on:</label>
                              {/* {moment(item.RRExp).format("DD/MM/YYYY")} */}

                              <span>
                                {item.propertyDtls?.RRExp &&
                                item.propertyDtls?.RRExp.length > 13
                                  ? moment(item.propertyDtls?.RRExp).format(
                                      "MM/DD/YYYY"
                                    )
                                  : moment
                                      .unix(item.propertyDtls?.RRExp / 1000)
                                      .format("MM/DD/YYYY")}
                                {/* {moment(item.RRExp).format("MM/DD/YYYY")} */}
                              </span>
                            </li>
                          )}
                          {item.propertyDtls?.ListAgentJustification && (
                            <li>
                              <label>Agent Justification:</label>
                              <span>
                                {item.propertyDtls?.ListAgentJustification}
                              </span>
                            </li>
                          )}
                          {item.propertyDtls?.ListKey && (
                            <li>
                              <label>Key:</label>
                              <span>{item.propertyDtls?.ListKey}</span>
                            </li>
                          )}
                          {item.propertyDtls?.KeyLocation && (
                            <li>
                              <label>Key Location:</label>
                              <span>{item.propertyDtls?.KeyLocation}</span>
                            </li>
                          )}
                        </ul>

                        {/* <ul>
                      {item?.PropertyEditedFields?.ListBedRoom && (
                        <li>
                          <label>Bed:</label>
                          {item?.PropertyEditedFields?.ListBedRoom}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListBathRoom && (
                        <li>
                          <label>Bath:</label>
                          {item?.PropertyEditedFields?.ListBathRoom}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListStyle && (
                        <li>
                          <label>Style:</label>
                          {item?.PropertyEditedFields?.ListStyle}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.LivingArea && (
                        <li>
                          <label>Living Area:</label>
                          {item?.PropertyEditedFields?.LivingArea}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.YearBuilt && (
                        <li>
                          <label>Year Build:</label>
                          {item?.PropertyEditedFields?.YearBuilt}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListHeat && (
                        <li>
                          <label>Heat:</label>
                          {item?.PropertyEditedFields?.ListHeat}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListGarage && (
                        <li>
                          <label>Garage:</label>
                          {item?.PropertyEditedFields?.ListGarage}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListBasement && (
                        <li>
                          <label>Basement:</label>
                          {item?.PropertyEditedFields?.ListBasement}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListAirCondition && (
                        <li>
                          <label>A/C:</label>
                          {item?.PropertyEditedFields?.ListAirCondition}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.LotSize && (
                        <li>
                          <label>Lot Size:</label>
                          {item?.PropertyEditedFields?.LotSize}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.BedBathConfiguration && (
                        <li>
                          <label>Bed Bath Configuration:</label>
                          {item?.PropertyEditedFields?.BedBathConfiguration}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListStory && (
                        <li>
                          <label>Stories:</label>
                          {item?.PropertyEditedFields?.ListStory}
                        </li>
                      )}
                      {formateTaxMap(
                        item?.PropertyEditedFields?.SuffCoTaxMap
                      ) && (
                        <li>
                          <label>TaxMap:</label>
                          {formateTaxMap(
                            item?.PropertyEditedFields?.SuffCoTaxMap
                          )}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.RentalRegisteryId && (
                        <li>
                          <label>RRN ID:</label>
                          {item?.PropertyEditedFields?.RentalRegisteryId}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.RRExp && (
                        <li>
                          <label>RR Expires on:</label>
                          {moment(item?.PropertyEditedFields?.RRExp).format(
                            "DD/MM/YYYY"
                          )}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListAgentJustification && (
                        <li>
                          <label>Agent Justification:</label>
                          {item?.PropertyEditedFields?.ListAgentJustification}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListKey && (
                        <li>
                          <label>Key:</label>
                          {item?.PropertyEditedFields?.ListKey}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.KeyLocation && (
                        <li>
                          <label>Key Location:</label>
                          {item?.PropertyEditedFields?.KeyLocation}
                        </li>
                      )}
                    </ul> */}

                        {item?.propertyDtls?.ApprovedPriceData?.PriceDetails &&
                          chechForPrice(
                            item?.propertyDtls?.ApprovedPriceData?.PriceDetails
                          ) && (
                            <ul>
                              {item?.propertyDtls?.ApprovedPriceData
                                ?.PriceDetails &&
                                item?.propertyDtls?.ApprovedPriceData?.PriceDetails.map(
                                  (items) => {
                                    if (
                                      items.AvblStatus?.toLowerCase() ===
                                        "yes" ||
                                      items.AvblStatus?.toLowerCase() ===
                                        "rented" ||
                                      items.AvblStatus?.toLowerCase() === "no"
                                    ){
                                      return (
                                        <li>
                                          <label>
                                            <img
                                              src={
                                                items.AvblStatus?.toLowerCase() ===
                                                "yes"
                                                  ? "../assets/images/green-check.svg"
                                                  : items.AvblStatus?.toLowerCase() ===
                                                    "rented"
                                                  ? "../assets/images/red-check.svg"
                                                  : "../assets/images/red-cross.svg"
                                              }
                                              className="img-fluid"
                                              alt="Tick"
                                            />{" "}
                                            {items.RentalPeriod}
                                          </label>
                                          {/* ${formatePrice(items.Price)} */}
                                          {/* <div className="updated-figure">
                                        {items.PrePrice != 0 && (
                                          <del>
                                            ${formatePrice(items.PrePrice)}
                                          </del>
                                        )} */}
                                          <span>
                                            ${formatePrice(items.Price)}
                                          </span>
                                          {/* </div> */}
                                        </li>
                                      );}
                                      return null
                                  }
                                )}
                            </ul>
                          )}

                        {/* {item?.PriceData?.PriceDetails &&
                      chechForPrice(item?.PriceData?.PriceDetails) && (
                        <ul>
                          {item?.PriceData?.PriceDetails &&
                            item?.PriceData?.PriceDetails.map((items) => {
                              if (
                                items.AvblStatus.toLowerCase() === "yes" ||
                                items.AvblStatus.toLowerCase() === "rented"
                              )
                                return (
                                  <li>
                                    <label>
                                      <img
                                        src={
                                          items.AvblStatus.toLowerCase() ===
                                          "yes"
                                            ? "../assets/images/green-check.svg"
                                            : "../assets/images/red-check.svg"
                                        }
                                        className="img-fluid"
                                        alt="Tick"
                                      />{" "}
                                      {items.RentalPeriod}
                                    </label>
                                    ${formatePrice(items.PrePrice)}
                                  </li>
                                );
                            })}
                        </ul>
                      )} */}
                      </div>
                      {}
                      {item?.propertyDtls?.ApprovedPriceData
                        ?.ShortTermPriceDetails &&
                        item?.propertyDtls?.ApprovedPriceData
                          ?.ShortTermPriceDetails.length > 0 && (
                          <div>
                            <br />
                            <h5>Short Term Values (Priced Weekly)</h5>
                            <div style={{ paddingBottom: "20px" }}>
                              {item?.propertyDtls?.ApprovedPriceData?.ShortTermPriceDetails.map(
                                (items) => {
                                  return (
                                    <Chip
                                      className="me-2"
                                      label={`${convertTimeStamp(
                                        items.DateFrom
                                      )} - ${convertTimeStamp(items.DateTo)} $${
                                        items.Amount
                                      }`}
                                    />
                                  );
                                }
                              )}
                            </div>
                            <Divider />
                          </div>
                        )}
                      <div className="al-owner-agent-details">
                        <div className="row">
                          <div className="col-md-3">
                            {item?.OwnerDtls?.FirstName && (
                              <h6>
                                {`${item?.OwnerDtls?.FirstName} ${item?.OwnerDtls?.LastName}`}
                                <span>(OWNER)</span>
                              </h6>
                            )}
                            <ul>
                              {item?.OwnerDtls?.HomePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  H-{item?.OwnerDtls?.HomePhone}
                                </li>
                              )}
                              {item?.OwnerDtls?.OfficePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  O-{item?.OwnerDtls?.OfficePhone}
                                </li>
                              )}
                              {item?.OwnerDtls?.CellPhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  C-{item?.OwnerDtls?.CellPhone}
                                </li>
                              )}
                              {item?.OwnerDtls?.LocalPhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  L-{item?.OwnerDtls?.LocalPhone}
                                </li>
                              )}

                              {item?.OwnerDtls?.Email && (
                                <li>
                                  <img
                                    src="../assets/images/email.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  {item?.OwnerDtls?.Email}
                                </li>
                              )}
                            </ul>
                          </div>
                          {item?.AgentDtls && (
                            <div className="col-md-3">
                              {item?.AgentDtls?.FirstName && (
                                <h6>
                                  {`${item?.AgentDtls?.FirstName} ${item?.AgentDtls?.LastName}`}
                                  <span>(PROPERTY AGENT)</span>
                                </h6>
                              )}
                              <ul>
                                {item?.AgentDtls?.HomePhone && (
                                  <li>
                                    <img
                                      src="../assets/images/phone.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    H-{item?.AgentDtls?.HomePhone}
                                  </li>
                                )}
                                {item?.AgentDtls?.OfficePhone && (
                                  <li>
                                    <img
                                      src="../assets/images/phone.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    O-{item?.AgentDtls?.OfficePhone}
                                  </li>
                                )}
                                {item?.AgentDtls?.Phone && (
                                  <li>
                                    <img
                                      src="../assets/images/phone.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    C-{item?.AgentDtls?.Phone}
                                  </li>
                                )}
                                {item?.AgentDtls?.LocalPhone && (
                                  <li>
                                    <img
                                      src="../assets/images/phone.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    L-{item?.AgentDtls?.LocalPhone}
                                  </li>
                                )}

                                {item?.AgentDtls?.Email && (
                                  <li>
                                    <img
                                      src="../assets/images/email.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    {item?.AgentDtls?.Email}
                                  </li>
                                )}
                                <li>(Agent Input Listing)</li>
                              </ul>
                            </div>
                          )}
                          <div className="col-md-3">
                            <ul className="al-extra">
                              {item.propertyDtls?.SpecialInfo && (
                                <li>
                                  <span>Key Special Info:</span>
                                  <label>
                                    {item.propertyDtls?.SpecialInfo}
                                  </label>
                                </li>
                              )}
                              {item.propertyDtls?.ShowingIns && (
                                <li>
                                  <span>SI:</span>
                                  <label>{item.propertyDtls?.ShowingIns}</label>
                                </li>
                              )}
                              {item.propertyDtls?.AlaramCode && (
                                <li>
                                  <span>Alarm:</span>
                                  <label>{item.propertyDtls?.AlaramCode}</label>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <hr />

                      {(item?.PropertyEditedFields===null)&&(item?.AgentInfoEditedFields===null)&&handlePriceCheck(item?.PriceData?.PriceDetails)?"":
                      <div className="updated-changes">
                        <h6>
                          Updated Changes by{" "}
                          {item?.UpdatedByDtls?.FirstName.charAt(
                            0
                          ).toUpperCase() +
                            item?.UpdatedByDtls?.FirstName.slice(
                              1
                            ).toLowerCase()}{" "}
                          {item?.UpdatedByDtls?.LastName.charAt(
                            0
                          ).toUpperCase() +
                            item?.UpdatedByDtls?.LastName.slice(
                              1
                            ).toLowerCase()}{" "}
                          on {convertTimeStamp(item.UpdatedOn)}
                        </h6>

                        {item?.AdTitle && item?.PropertyEditedFields?.AdTitle && (
                          <div className="updated-item">
                            <p>
                              <b>Title:&nbsp;</b>
                              {item?.AdTitle}
                            </p>
                          </div>
                        )}
                        {item?.AdDescription &&
                          item?.PropertyEditedFields?.AdDescription && (
                            <div className="updated-item">
                              <p>
                                <b>Description:&nbsp;</b>
                                {item?.AdDescription}
                              </p>
                            </div>
                          )}

                        {item?.PropertyEditedFields?.ListMainAmenity && (
                          <div className="updated-item updated-features">
                            {item?.ListMainAmenity &&
                              item?.ListMainAmenity?.map(
                                (items) =>
                                  items?.Flag === true && (
                                    <label className="updated-to">
                                      {items?.Title}
                                    </label>
                                  )
                              )}
                            {item?.PropertyEditedFields?.ListMainAmenity &&
                              item?.PropertyEditedFields?.ListMainAmenity?.map(
                                (items) =>
                                  items?.Flag === true && (
                                    <label className="updated-from">
                                      {items?.Title}
                                    </label>
                                  )
                              )}
                          </div>
                        )}
                        <div className="updated-item al-aminities-price">
                          <ul>
                            {(item?.ListBedRoom ||
                              item?.PropertyEditedFields?.ListBedRoom) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListBedRoom" && (
                                    <li>
                                      <label>Bed:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListBedRoom
                                          }
                                        </del>
                                        <span>{item.ListBedRoom}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListBathRoom ||
                              item?.PropertyEditedFields?.ListBathRoom) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListBathRoom" && (
                                    <li>
                                      <label>Bath:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListBathRoom
                                          }
                                        </del>
                                        <span>{item?.ListBathRoom}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListStyle ||
                              item?.PropertyEditedFields?.ListStyle) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListStyle" && (
                                    <li>
                                      <label>Style:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListStyle
                                          }
                                        </del>
                                        <span>{item?.ListStyle}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.LivingArea ||
                              item?.PropertyEditedFields?.LivingArea) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "LivingArea" && (
                                    <li>
                                      <label>Living Area:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.LivingArea
                                          }
                                        </del>
                                        <span>{item?.LivingArea}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.YearBuilt ||
                              item?.PropertyEditedFields?.YearBuilt) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "YearBuilt" && (
                                    <li>
                                      <label>Year Build:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.YearBuilt
                                          }
                                        </del>
                                        <span>{item?.YearBuilt}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListHeat ||
                              item?.PropertyEditedFields?.ListHeat) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListHeat" && (
                                    <li>
                                      <label>Heat:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.ListHeat}
                                        </del>
                                        <span>{item?.ListHeat}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListGarage ||
                              item?.PropertyEditedFields?.ListGarage) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListGarage" && (
                                    <li>
                                      <label>Garage:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListGarage
                                          }
                                        </del>
                                        <span>{item?.ListGarage}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListBasement ||
                              item?.PropertyEditedFields?.ListBasement) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListBasement" && (
                                    <li>
                                      <label>Basement:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListBasement
                                          }
                                        </del>
                                        <span>{item?.ListBasement}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListAirCondition ||
                              item?.PropertyEditedFields?.ListAirCondition) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListAirCondition" && (
                                    <li>
                                      <label>A/C:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListAirCondition
                                          }
                                        </del>
                                        <span>{item?.ListAirCondition}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.LotSize ||
                              item?.PropertyEditedFields?.LotSize) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "LotSize" && (
                                    <li>
                                      <label>Lot Size:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.LotSize}
                                        </del>
                                        <span>{item?.LotSize}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.BedBathConfiguration ||
                              item?.PropertyEditedFields
                                ?.BedBathConfiguration) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "BedBathConfiguration" && (
                                    <li>
                                      <label>Bed Bath Configuration:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.BedBathConfiguration
                                          }
                                        </del>
                                        <span>
                                          {item?.BedBathConfiguration}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListStory ||
                              item?.PropertyEditedFields?.ListStory) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListStory" && (
                                    <li>
                                      <label>Stories:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListStory
                                          }
                                        </del>
                                        <span>{item?.ListStory}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(formateTaxMap(item?.SuffCoTaxMap) ||
                              formateTaxMap(
                                item?.AgentInfoEditedFields?.SuffCoTaxMap
                              )) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "SuffCoTaxMap" && (
                                    <li>
                                      <label>TaxMap:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {formateTaxMap(
                                            item?.AgentInfoEditedFields
                                              ?.SuffCoTaxMap
                                          )}
                                        </del>
                                        <span>
                                          {formateTaxMap(item?.SuffCoTaxMap)}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.RentalRegisteryId ||
                              item?.PropertyEditedFields?.RentalRegisteryId) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "RentalRegisteryId" && (
                                    <li>
                                      <label>RRN ID:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.RentalRegisteryId
                                          }
                                        </del>
                                        <span>{item?.RentalRegisteryId}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.RRExp ||
                              item?.PropertyEditedFields?.RRExp) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "RRExp" && (
                                    <li>
                                      <label>RR Expires on:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.RRExp &&
                                          item?.PropertyEditedFields?.RRExp
                                            .length > 13
                                            ? item?.PropertyEditedFields?.RRExp
                                              ? moment(
                                                  item?.PropertyEditedFields
                                                    ?.RRExp
                                                ).format("MM/DD/YYYY")
                                              : "Not Set"
                                            : item?.PropertyEditedFields?.RRExp
                                            ? moment
                                                .unix(
                                                  item?.PropertyEditedFields
                                                    ?.RRExp / 1000
                                                )
                                                .format("MM/DD/YYYY")
                                            : item?.PropertyEditedFields
                                                ?.RRExp !== null && "Not Set"}
                                        </del>
                                        <span>
                                          {item?.RRExp &&
                                          item?.RRExp.length > 13
                                            ? moment(item?.RRExp).format(
                                                "MM/DD/YYYY"
                                              )
                                            : moment
                                                .unix(item?.RRExp / 1000)
                                                .format("MM/DD/YYYY")}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListAgentJustification ||
                              item?.AgentInfoEditedFields
                                ?.ListAgentJustification) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "ListAgentJustification" && (
                                    <li>
                                      <label>Agent Justification:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.ListAgentJustification
                                          }
                                        </del>
                                        <span>
                                          {item?.ListAgentJustification}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListKey ||
                              item?.AgentInfoEditedFields?.ListKey) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "ListKey" && (
                                    <li>
                                      <label>Key:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.AgentInfoEditedFields?.ListKey}
                                        </del>
                                        <span>{item?.ListKey}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.KeyLocation ||
                              item?.AgentInfoEditedFields?.KeyLocation) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "KeyLocation" && (
                                    <li>
                                      <label>Key Location:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.KeyLocation
                                          }
                                        </del>
                                        <span>{item?.KeyLocation}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.StreetAddress ||
                              item?.PropertyEditedFields?.StreetAddress) &&
                              item?.PropertyEditedFields?.StreetAddress && (
                                <li>
                                  <label>Street Address:</label>
                                  <div className="updated-figure">
                                    <del>
                                      {
                                        item?.PropertyEditedFields
                                          ?.StreetAddress
                                      }
                                    </del>
                                    <span>{item?.StreetAddress}</span>
                                  </div>
                                </li>
                              )}
                            {(item?.City || item?.PropertyEditedFields?.City) &&
                              item?.PropertyEditedFields?.City && (
                                <li>
                                  <label>City:</label>
                                  <div className="updated-figure">
                                    <del>
                                      {item?.PropertyEditedFields?.City}
                                    </del>
                                    <span>{item?.City}</span>
                                  </div>
                                </li>
                              )}
                            {(item?.State ||
                              item?.PropertyEditedFields?.State) &&
                              item?.PropertyEditedFields?.State && (
                                <li>
                                  <label>State:</label>
                                  <div className="updated-figure">
                                    <del>
                                      {item?.PropertyEditedFields?.State}
                                    </del>
                                    <span>{item?.State}</span>
                                  </div>
                                </li>
                              )}
                            {(item?.Zip || item?.PropertyEditedFields?.Zip) &&
                              item?.PropertyEditedFields?.Zip && (
                                <li>
                                  <label>Zip:</label>
                                  <div className="updated-figure">
                                    <del>{item?.PropertyEditedFields?.Zip}</del>
                                    <span>{item?.Zip}</span>
                                  </div>
                                </li>
                              )}
                            {(item?.Area || item?.PropertyEditedFields?.Area) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "Area" && (
                                    <li>
                                      <label>Area:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.Area}
                                        </del>
                                        <span>{item?.Area}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListFireplace ||
                              item?.PropertyEditedFields?.ListFireplace) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListFireplace" && (
                                    <li>
                                      <label>Fireplaces:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListFireplace
                                          }
                                        </del>
                                        <span>{item?.ListFireplace}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListPet ||
                              item?.PropertyEditedFields?.ListPet) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListPet" && (
                                    <li>
                                      <label>Pets:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.ListPet}
                                        </del>
                                        <span>{item?.ListPet}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListPool ||
                              item?.PropertyEditedFields?.ListPool) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListPool" && (
                                    <li>
                                      <label>Pools:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {item?.PropertyEditedFields?.ListPool}
                                        </del>
                                        <span>{item?.ListPool}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListTennis ||
                              item?.PropertyEditedFields?.ListTennis) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListTennis" && (
                                    <li>
                                      <label>Tennis:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListTennis
                                          }
                                        </del>
                                        <span>{item?.ListTennis}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ListWater ||
                              item?.PropertyEditedFields?.ListWater) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "ListWater" && (
                                    <li>
                                      <label>Waterfront:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.ListWater
                                          }
                                        </del>
                                        <span>{item?.ListWater}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.PropertyType ||
                              item?.PropertyEditedFields?.PropertyType) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "PropertyType" && (
                                    <li>
                                      <label>Property Type:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.PropertyType
                                          }
                                        </del>
                                        <span>{item?.PropertyType}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.IsFurnished ||
                              item?.PropertyEditedFields?.IsFurnished) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "IsFurnished" && (
                                    <li>
                                      <label>Furnished:</label>
                                      <div className="updated-figure">
                                        {item?.PropertyEditedFields
                                          ?.IsFurnished !==
                                          item?.IsFurnished && (
                                          <del>
                                            {item?.PropertyEditedFields
                                              ?.IsFurnished === true
                                              ? "Yes"
                                              : item?.PropertyEditedFields
                                                  ?.IsFurnished === false
                                              ? "No"
                                              : ""}
                                          </del>
                                        )}
                                        <span>
                                          {item?.IsFurnished === true
                                            ? "Yes"
                                            : item?.IsFurnished === false
                                            ? "No"
                                            : ""}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.IsPrivate ||
                              item?.PropertyEditedFields?.IsPrivate) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "IsPrivate" && (
                                    <li>
                                      <label>Is Private:</label>

                                      <div className="updated-figure">
                                        {item?.PropertyEditedFields
                                          ?.IsPrivate !== item?.IsPrivate && (
                                          <del>
                                            {item?.PropertyEditedFields
                                              ?.IsPrivate === true
                                              ? "Yes"
                                              : item?.PropertyEditedFields
                                                  ?.IsPrivate === false
                                              ? "No"
                                              : ""}
                                          </del>
                                        )}
                                        <span>
                                          {item?.IsPrivate === true
                                            ? "Yes"
                                            : item?.IsPrivate === false
                                            ? "No"
                                            : ""}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.AlaramCode ||
                              item?.AgentInfoEditedFields?.AlaramCode) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "AlaramCode" && (
                                    <li>
                                      <label>Alarm Code:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.AlaramCode
                                          }
                                        </del>
                                        <span>{item?.AlaramCode}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.ShowingIns ||
                              item?.AgentInfoEditedFields?.ShowingIns) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "ShowingIns" && (
                                    <li>
                                      <label>Showing Instructions:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.ShowingIns
                                          }
                                        </del>
                                        <span>{item?.ShowingIns}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.SpecialInfo ||
                              item?.AgentInfoEditedFields?.SpecialInfo) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "SpecialInfo" && (
                                    <li>
                                      <label>Special Info:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.SpecialInfo
                                          }
                                        </del>
                                        <span>{item?.SpecialInfo}</span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.IsDownstairsMaster === true ||
                              item?.IsDownstairsMaster === false ||
                              item?.PropertyEditedFields?.IsDownstairsMaster ===
                                true ||
                              item?.PropertyEditedFields?.IsDownstairsMaster ===
                                false) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "IsDownstairsMaster" && (
                                    <li>
                                      <label>
                                        Master Bedroom on Ground Floor:
                                      </label>
                                      <div className="updated-figure">
                                        {item?.PropertyEditedFields
                                          ?.IsDownstairsMaster !==
                                          item?.IsDownstairsMaster && (
                                          <del>
                                            {item?.PropertyEditedFields
                                              ?.IsDownstairsMaster === true
                                              ? "Yes"
                                              : item?.PropertyEditedFields
                                                  ?.IsDownstairsMaster === false
                                              ? "No"
                                              : ""}
                                          </del>
                                        )}
                                        <span>
                                          {item?.IsDownstairsMaster === true
                                            ? "Yes"
                                            : item?.IsDownstairsMaster === false
                                            ? "No"
                                            : ""}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.OwnerDtls?.FirstName ||
                              item?.OwnerDtls?.LastName ||
                              item?.PropertyEditedFields?.OwnerName) &&
                              item?.EditedKeys?.map(
                                (items) =>
                                  items === "OwnerId" && (
                                    <li>
                                      <label>Owner Name:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.PropertyEditedFields
                                              ?.OwnerName
                                          }
                                        </del>
                                        <span>
                                          {item?.OwnerDtls?.FirstName +
                                            " " +
                                            item?.OwnerDtls?.LastName}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                            {(item?.AgentDtls?.FirstName ||
                              item?.AgentDtls?.LastName ||
                              item?.AgentInfoEditedFields?.PropertyAgentName) &&
                              item?.AgentInfoEditedKeys?.map(
                                (items) =>
                                  items === "PropertyAgentId" && (
                                    <li>
                                      <label>Property Agent:</label>
                                      <div className="updated-figure">
                                        <del>
                                          {
                                            item?.AgentInfoEditedFields
                                              ?.PropertyAgentName
                                          }
                                        </del>
                                        <span>
                                          {item?.AgentDtls?.FirstName +
                                            " " +
                                            item?.AgentDtls?.LastName}
                                        </span>
                                      </div>
                                    </li>
                                  )
                              )}
                          </ul>
                        </div>
                        {item?.PriceData&&<div className="updated-item al-aminities-price border-0">
                          {item?.PriceData?.PriceDetails &&
                            chechForPrice(item?.PriceData?.PriceDetails) && (
                              <ul>
                                {item?.PriceData?.PriceDetails &&
                                  item?.PriceData?.PriceDetails.map((items) => {
                                    if (
                                      (items.AvblStatus?.toLowerCase() ===
                                        "yes" ||
                                        items.AvblStatus?.toLowerCase() ===
                                          "rented" ||
                                        items.AvblStatus?.toLowerCase() ===
                                          "no") &&
                                      items?.IsEdited === true
                                    ){
                                      return (
                                        <li>
                                          <label>
                                            <img
                                              src={
                                                items.AvblStatus?.toLowerCase() ===
                                                "yes"
                                                  ? "../assets/images/green-check.svg"
                                                  : items.AvblStatus?.toLowerCase() ===
                                                    "no"
                                                  ? "../assets/images/red-cross.svg"
                                                  : "../assets/images/red-check.svg"
                                              }
                                              className="img-fluid"
                                              alt="Tick"
                                            />{" "}
                                            {items.RentalPeriod}
                                          </label>
                                          <div className="updated-figure">
                                            {items.PrePrice !== 0 && (
                                              <del>
                                                ${formatePrice(items.PrePrice)}
                                              </del>
                                            )}
                                            <span>
                                              ${formatePrice(items.Price)}
                                            </span>
                                          </div>
                                        </li>
                                      );}
                                      return null
                                  })}
                                  
                              </ul>
                            )} 
                            {((item?.PriceData?.EditedSalesPrice!==0)&&item?.PriceData?.SalesPrice!==0)&&(
                              <div className="d-flex">
                             <label className="me-2">Sale Price:</label>
                             <div className="updated-figure">
                               {(item?.PriceData?.EditedSalesPrice!==0&&item?.PriceData?.EditedSalesPrice!==null)&&(
                              <del>
                                
                                  ${formatePrice(item?.PriceData?.EditedSalesPrice)}
                                
                              </del>)}
                             {item?.PriceData?.SalesPrice!==0&& <span>${formatePrice(item?.PriceData?.SalesPrice)}</span>}
                             </div>
                            </div>
                            )}
                            {/* {item?.PriceData?.EditedSalesPrice!==item?.PriceData?.SalesPrice?
                            "Sale Price: "+item?.PriceData?.SalesPrice:"Sale Price: "+item?.PriceData?.EditedSalesPrice!==0&&item?.PriceData?.EditedSalesPrice} */}
                        </div>}
                      </div>
                       } 

                      {item?.PendingComments && (
                        <div className="al-comments">
                          <ul>
                            {item?.PendingComments &&
                              [...item?.PendingComments]
                                .map((items, index) => {
                                  if (!moreComment && index > 1) return null;
                                  return (
                                    <li>
                                      {items.Comment},{" "}
                                      <b>
                                        - {items.UserName}{" "}
                                        {convertTimeStampWithTime(
                                          items.CreatedOn
                                        )}
                                      </b>
                                    </li>
                                  );
                                })}
                          </ul>
                          {item?.PendingComments &&
                            item?.PendingComments.length > 2 && (
                              <label
                                style={{cursor:'pointer'}}
                                className="view-comment"
                                onClick={() => setMoreComment(!moreComment)}
                              >
                                {moreComment
                                  ? "View less Comments"
                                  : "View more Comments"}
                              </label>
                            )}
                        </div>
                      )}
                      <div className="al-dates">
                        <ul>
                          {item.CreatedOn && (
                            <li>
                              Listing Date :&nbsp;
                              <b>{convertTimeStamp(item.CreatedOn)}</b>
                            </li>
                          )}
                          {item.UpdatedOn && (
                            <li>
                              Last Update :&nbsp;
                              <b>{convertTimeStamp(item.UpdatedOn)}</b>
                            </li>
                          )}
                          {item?.UpdatedByDtls?.FirstName && (
                            <li>
                              Last Update by :&nbsp;
                              <b>
                                {item?.UpdatedByDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.UpdatedByDtls?.FirstName.slice(
                                    1
                                  ).toLowerCase()}{" "}
                                {item?.UpdatedByDtls?.LastName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.UpdatedByDtls?.LastName.slice(
                                    1
                                  ).toLowerCase()}
                              </b>
                            </li>
                          )}
                          <li>
                            Status :&nbsp;
                            <b>
                              {item?.Status === "requested"
                                ? "Pending Approval"
                                : item?.Status === "Pending Off the Market"
                                ? "Pending OTM/Inactive"
                                : item?.Status?.charAt(0).toUpperCase() +
                                  item?.Status?.slice(1).toLowerCase()}
                            </b>
                          </li>
                          {/* <li>
                           Listing Type :&nbsp;<b>Need Listing agreement</b>
                         </li> */}
                        </ul>
                      </div>
                      {user?.data?.Role !== "agent" && (
                        <div className="d-flex pt-3 pb-4 justify-content-between">
                          <Button
                            //disabled={approveDisapproveLoading}
                            color="sucess"
                            variant="contained"
                            className="green-btn"
                            onClick={() => {
                              setOpenPopUp(true);
                              setPropertyId(item?.PropertyId);
                              setPendingDeleteCheck(item?.Status);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Approve
                          </Button>

                          <Button
                            //disabled={approveDisapproveLoading}
                            color="primary"
                            variant="contained"
                            className=""
                            style={{
                              textTransform: "capitalize",
                              fontFamily: "Helvetica-Light",
                            }}
                            onClick={() => {
                              setOpenPopUpDisapprove(true);
                              setPropertyId(item?.PropertyId);
                            }}
                          >
                            Decline
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })
              : areaFilter ||
                rentalPeriodFilter ||
                priceFilter ||
                bedFilter ||
                bathFilter ||
                keyWordSearch ||
                rentalPeriod ||
                location ||
                isPrivate ||
                amenityName ||
                isCommercial ||
                isResidential ||
                waterfront ||
                tennis ||
                pools ||
                pets ||
                status
              ? "No pending property to show"
              : "There is no property for Approval"}
          </div>
          <DialogBox
            open={openPopUp}
            setOpen={setOpenPopUp}
            handlePositiveBtn={() => handleApprove()}
            title={
              pendingDeleteCheck === "Pending Delete"
                ? "Delete ?"
                : "Approve ?"
            }
            text={
              pendingDeleteCheck === "Pending Delete"
                ? "Are you sure you want to Delete this Property ?"
                : "Are you sure you want to Approve this Property ?"
            }
          />
          <DialogBox
            open={openPopUpDisapprove}
            setOpen={setOpenPopUpDisapprove}
            handlePositiveBtn={() => handleDisapprove()}
            title={"Decline ?"}
            text={"Are you sure you want to Decline this Property ?"}
          />
          <DialogBox
            negativeBtn={true}
            open={openPopUpCheck}
            setOpen={setOpenPopUpCheck}
            handlePositiveBtn={() => handleApproveCheck(itemDetails)}
            handleNegativeBtn={() => handleDisapproveCheck(itemDetails)}
            negativeText={"Decline"}
            positiveText={"Approve"}
            title={"Approve/Decline ?"}
            text={
              "Before Editing, you need to either Approve/Decline this Property with the changes made ?"
            }
          />

          {/* <Dialog open={openPopUpCheck} onClose={() => setOpenPopUpCheck(false)}>
      <DialogTitle className="pb-0">{"Approve/Disapprove" + "?"}</DialogTitle>
      <DialogContent  className="">
        <DialogContentText>{"Before editing, Are you sure you want to Approve/Disapprove this Property with the changes made ?"}</DialogContentText>
        <DialogActions className="d-flex justify-content-between pe-0 ps-0" style={{marginBottom:'14px'}}>
          <Button onClick={() => setOpenPopUpCheck(false)} className="outline-btn">
            { "Disapprove"}
          </Button>
          <Button
            className="highlight-btn"
            onClick={() => {
              props.handlePositiveBtn();
              props.setOpen(false);
             // props.setDeleteVar&&props.setDeleteVar(null)
            }}
            color="primary"
            autoFocus
          >
            { "Approve"}
          </Button>
      </DialogActions>
      </DialogContent>
      
    </Dialog> */}
          {/* <DialogBox
            open={openPopUpDisapproveCheck}
            setOpen={setOpenPopUpDisapproveCheck}
            handlePositiveBtn={() => handleDisapproveCheck()}
            title={"Decline" + "?"}
            text={"Before editing, Are you sure you want to Decline this Property with the changes made ?"}
          /> */}
          <Popover1
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "5px" }}
          >
            <div
              style={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.43)",
                borderRadius: "4px",
              }}
            >
              {(user?.data?.Role === "agentAdmin" ||
                user?.data?.Role === "admin") && (
                <div className="al-action-popup">
                  {options.map((option, index) => {
                    return (
                      <>
                        {/* {itemDetails.Status == "Pending Delete" ||
                  itemDetails.Status == "Pending Sold" ? (
                    <Button
                      disabled={
                        itemDetails.Status == "Pending Delete" ||
                        itemDetails.Status == "Pending Sold"
                      }
                      style={
                        option.label === "None" && option.id === "None"
                          ? { opacity: "0", cursor: "auto" }
                          : null
                      }
                      onClick={() => handleMenuChange(option.id)}
                      variant="outlined"
                      color="primary"
                      // style={{
                      //   border: "1px solid #A88585",
                      //   boxSizing: "border-box",
                      //   borderRadius: "4px",
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      //   margin: "10px",
                      //   padding: "10px",
                      //   color: "#A88585",
                      // }}
                    >
                      {option.label == " OTM/Inactive" &&
                      itemDetails.Status == "Pending Off the Market"
                        ? (option.label = " OTM/Active")
                        : option.label === "Feature" &&
                          itemDetails.IsFeatured === true
                        ? (option.label = "UnFeature")
                        : option.label}
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        (!option.disabled &&
                          itemDetails.Status != "approved") ||
                        ((itemDetails.AgreementPdfUrl === null ||
                          itemDetails.AgreementPdfUrl === "") &&
                          option.label === "View Agreement")
                      }
                      style={
                        option.label === "None" && option.id === "None"
                          ? { opacity: "0", cursor: "auto" }
                          : null
                      }
                      onClick={() => handleMenuChange(option.id)}
                      variant="outlined"
                      color="primary"
                    >
                      {option.label == " OTM/Inactive" &&
                      itemDetails.Status == "Pending Off the Market"
                        ? (option.label = " OTM/Active")
                        : option.label === "Feature" &&
                          itemDetails.IsFeatured === true
                        ? (option.label = "UnFeature")
                        : option.label}
                    </Button>
                  )} */}
                        <Button
                          // disabled={
                          //   !option.disabled && itemDetails.Status != "approved"
                          // }
                          style={
                            option.label === "None" && option.id === "None"
                              ? { opacity: "0", cursor: "auto" }
                              : null
                          }
                          onClick={() => handleMenuChange(option.id)}
                          variant="outlined"
                          color="primary"
                        >
                          {/* {option.label == " OTM/Inactive" &&
                      itemDetails.Status == "Pending Off the Market"
                        ? (option.label = " OTM/Active")
                        : option.label} */}
                          {option.label === " OTM/Inactive" &&
                          itemDetails.Status === "Pending Off the Market"
                            ? (option.label = " OTM/Active")
                            : option.label}
                        </Button>
                      </>
                    );
                  })}
                </div>
              )}
              {user?.data?.Role === "agent" && (
                <div className="al-action-popup">
                  {optionsAgent.map((option, index) => {
                    return (
                      <>
                        <Button
                          // disabled={
                          //   !option.disabled && itemDetails.Status != "approved"
                          // }
                          style={
                            option.label === "None" && option.id === "None"
                              ? { opacity: "0", cursor: "auto" }
                              : null
                          }
                          onClick={() => handleMenuChange(option.id)}
                          variant="outlined"
                          color="primary"
                        >
                          {/* {option.label == " OTM/Inactive" &&
                      itemDetails.Status == "Pending Off the Market"
                        ? (option.label = " OTM/Active")
                        : option.label} */}
                          {option.label}
                        </Button>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </Popover1>
          {AgentPrint && (
            <CustomizedModal
              component={AgentPrint}
              label="Agent Print"
              open={openAgentPrint}
              data={[itemDetails]}
              setOpenAgentPrint={setOpenAgentPrint}
            />
          )}

          {CustomerPrint && (
            <CustomizedModal
              component={CustomerPrint}
              label="Customer Print"
              open={openCustomerPrint}
              data={[itemDetails]}
              setOpenAgentPrint={setOpenCustomerPrint}
            />
          )}
          {EmailListing && (
            <CustomizedModal
              hideHeader={true}
              component={EmailListing}
              label="Email Listing"
              open={openEmailListing}
              setOpenAgentPrint={setOpenEmailListing}
              //emailOwner={props.openModalEmailOwner ? true : null}
              // propertyDetails={handleEmailIDCheck(itemDetails.PropertyId)}
              propertyDetails={[itemDetails?.propertyDtls]}
            />
          )}
          <Sold
            open={openSold}
            setOpen={setOpenSold}
            propertyId={itemDetails.PropertyId}
            pid={itemDetails.PID}
            handlePagination={handlePagination}
          />
          <Offthemarket
            open={openOffthemarket}
            setOpen={setOpenoffthemarket}
            propertyId={itemDetails.PropertyId}
            pid={itemDetails.PID}
            propertyStatus={itemDetails.Status}
            handlePagination={handlePagination}
          />
          <Featured
            open={openFeatured}
            setOpen={setOpenFeatured}
            propertyId={itemDetails._id}
            pid={itemDetails.PID}
            propertyStatus={itemDetails.IsFeatured}
            handlePagination={handlePagination}
          />
          <Delete
            open={openDelete}
            setOpen={setOpenDelete}
            propertyId={itemDetails.PropertyId}
            pid={itemDetails.PID}
            handlePagination={handlePagination}
          />
          {Suggest && (
            <CustomizedModal
              hideHeader={true}
              component={Suggest}
              open={openSuggest}
              setOpenAgentPrint={setOpenSuggest}
              propertyId={itemDetails?._id}
              data={[itemDetails]}
              suggestApproval={true}
              // data= {suggest}
            />
          )}
          <PAndSHistory
            open={openPAndS}
            setOpen={setOpenPAndS}
            pandsId={itemDetails.PropertyId}
            details={itemDetails}
          />
          {!loading && pendingPropertyCount > 0 && (
            <Pagination
              component="div"
              className="propertyPagination"
              //rowsPerPageOptions={[]}
              count={Math.ceil(pendingPropertyCount / rowsPerPage)}
              //rowsPerPage={rowsPerPage}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PendingList;
