import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton } from "@material-ui/core";
import * as yup from "yup";
import { PROPERTY_DETAILS, VIEW_AGREEMENT } from "../../../Graghql/queries";
import {
  ADD_AGREEMENT,
  EDIT_AGREEMENT,
  SEND_AGREEMENT,
  SIGN_AGREEMENT,
} from "../../../Graghql/mutations";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../../RactiveVariables/index";
import axios from "axios";
import {
  InputLabel,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { agreementDatas } from "./options";
import AuthAgreement from "./authAgreement";
import { Document, Page, pdfjs } from "react-pdf";

const Agreement = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const user = useReactiveVar(userDeatils);
  //console.log("userragree", user);
  const history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [checkPdf, setCheckPdf] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [ip, setIP] = useState("");
  const [defaultValue, setDefaultValue] = React.useState(null);
  const [saveOnlyLoader, setSaveOnlyLoader] = useState(false);
  const [toggle, setToggle] = useState(0);
  const textData = defaultValue?.AgencyDisclosure
    ? defaultValue?.AgencyDisclosure.replace(/<[^>]+>/g, "")
    : agreementDatas.replace(/<[^>]+>/g, "");

  const [longTextData, setLongTextData] = useState(textData);
  const [open, setOpen] = useState(false);
  const [checkAuth, setCheckAuth] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const schema = yup.object().shape({});
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });
  const [addAgreement, { loading }] = useMutation(ADD_AGREEMENT);
  // eslint-disable-next-line no-unused-vars
  const [sendAgreement] = useMutation(SEND_AGREEMENT);
  const [signAgreement] =
    useMutation(SIGN_AGREEMENT);
  // eslint-disable-next-line no-unused-vars
  const [editAgreement, { loading: editLoading }] = useMutation(EDIT_AGREEMENT);
  const { data: propertyDetailsData } = useQuery(PROPERTY_DETAILS, {
    variables: {
      propertyId: props.propertyId,
    },
    onCompleted: (res) => {
      setToggle(toggle + 1);
    },
  });

  const [getAgreementDetails, viewAgreementData] = useLazyQuery(
    VIEW_AGREEMENT,
    {
      fetchPolicy: "network-only",
      variables: {
        PropertyId: props.propertyId,
      },
      onCompleted: (res) => {
        setToggle(toggle + 1);
        // if (res?.viewAgreement) {
        //   setDefaultValue(res?.viewAgreement);
        //   setLongTextData(res?.viewAgreement?.AgencyDisclosure);
        // }
        // else if (propertyDetailsData.propertyDetails)
        //   setDefaultValue(propertyDetailsData.propertyDetails);
        // else setDefaultValue(null);
      },
      onError: (error) => {
        setToggle(toggle + 1);
      },
    }
  );
  useEffect(() => {
    getAgreementDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log("propertyDetailsData------->", propertyDetailsData?.propertyDetails);
  useEffect(() => {
    if (toggle === 2)
      if (viewAgreementData.data) {
        setDefaultValue(viewAgreementData.data.viewAgreement);
        setPdfUrl(viewAgreementData.data.viewAgreement.DocLink);
      } else if (propertyDetailsData.propertyDetails) {
        setDefaultValue(propertyDetailsData.propertyDetails);
      }
    // if (defaultValue === null && propertyDetailsData.propertyDetails)
    //   setDefaultValue(propertyDetailsData.propertyDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  //console.log("viewAgreementData------->", viewAgreementData);
  //console.log("defaultagreementData------->", defaultValue);
  const changeButton = () => {
    //console.log("testtttt",viewAgreementData)
    if (viewAgreementData?.data?.viewAgreement?.Status === "sent")
      return "Resend & Proceed Agreement";
    else return "Send & Proceed Agreement";
  };
  const onSubmit = (params) => {
    console.log("paramsagreement--->", params);

    if (user?.data?.UserType === "USER" && params.ChkLandlordName1 === false) {
      toggleSnackbar({
        status: true,
        message: "please select landloard1",
        variant: "error",
      });
    } else if (user?.data?.UserType === "USER" && checkAuth == null) {
      setOpen(true);
      toggleSnackbar({
        status: true,
        message: "Please login and Sign Agreement",
        variant: "success",
      });
    } else if (user?.data?.UserType === "USER" && checkAuth === true) {
      try {
        params["PropertyId"] = props.propertyId;
        params["SigSigneeName"] = user?.data?.FirstName+ " " + user?.data?.LastName;
        params["SigAddress"] =
          propertyDetailsData?.propertyDetails?.StreetAddress;
        params["SigDateTime"] = moment().format("LLL");
        params["SigIp"] = ip;
        params["StreetAddress"] =
          propertyDetailsData?.propertyDetails?.StreetAddress;
        params["TownStateZip"] = propertyDetailsData?.propertyDetails?.Zip;
        // params["AgencyDisclosure"] = longTextData;
        // params["Party"] = defaultValue?.Party?defaultValue?.Party: "landlord";
        // params["PartyAs"] =  defaultValue?.PartyAs?defaultValue?.PartyAs: "landlord's agent";
        signAgreement({ variables: params })
          .then((res) => {
            //console.log("ressagree", res);
            getAgreementDetails();
            toggleSnackbar({
              status: true,
              message: "successfully signed",
              variant: "success",
            });
            setPdfUrl(res?.data?.signAgreement?.pdfUrl);
            setCheckPdf(true);
            // history.push("/properties");
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      if (!viewAgreementData.data) {
        // if (params.ListingTermChk == true && params.ChkLandlordName1 == true) {
        try {
          // params["ownerId"] = propertyDetailsData?.propertyDetails?.OwnerId;
          params["PropertyId"] = props.propertyId;
          params["StreetAddress"] =
            propertyDetailsData?.propertyDetails?.StreetAddress;
          params["TownStateZip"] = propertyDetailsData?.propertyDetails?.Zip;
          params["ListingTermChk"] = false;
          //onlySave===true?params["saveAndProceed"] = false:params["saveAndProceed"] = true;
          params.saveAndProceed === true
            ? (params.saveAndProceed = false)
            : (params["saveAndProceed"] = true);
          addAgreement({ variables: params })
            .then((res) => {
              getAgreementDetails();
              toggleSnackbar({
                status: true,
                message: "success",
                variant: "success",
              });
              setSaveOnlyLoader(false);
              // history.push("/properties");
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }

        // try {
        //   //params["PropertyId"] = props.propertyId;
        //   sendAgreement({ variables: {PropertyId:props.propertyId} })
        //     .then((res) => {
        //       // toggleSnackbar({
        //       //   status: true,
        //       //   message: "success",
        //       //   variant: "success",
        //       // });
        //       // history.push("/properties");
        //     })
        //     .catch((err) => {
        //       const message = err && err.message;
        //       toggleSnackbar({
        //         status: true,
        //         message: message,
        //         variant: "error",
        //       });
        //     });
        // } catch (err) {
        //   const message = err && err.message;
        //   toggleSnackbar({
        //     status: true,
        //     message: message,
        //     variant: "error",
        //   });
        // }
        // } else {
        //   toggleSnackbar({
        //     status: true,
        //     message: "Please agree to listing terms",
        //     variant: "error",
        //   });
        // }
      } else {
        // if (params.ListingTermChk == true && params.ChkLandlordName1 == true) {
        try {
          params["PropertyId"] = props.propertyId;
          params["StreetAddress"] =
            propertyDetailsData?.propertyDetails?.StreetAddress;
          params["TownStateZip"] = propertyDetailsData?.propertyDetails?.Zip;
          params["ListingTermChk"] = false;
          //onlySave===true?params["saveAndProceed"] = false:params["saveAndProceed"] = true;
          params.saveAndProceed === true
            ? (params.saveAndProceed = false)
            : (params["saveAndProceed"] = true);
          addAgreement({ variables: params })
            .then((res) => {
              getAgreementDetails();
              toggleSnackbar({
                status: true,
                message: "success",
                variant: "success",
              });
              setSaveOnlyLoader(false);
              //setLongTextData("")
              // history.push("/properties");
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }

        // try {
        //   //params["PropertyId"] = props.propertyId;
        //   sendAgreement({ variables: {PropertyId:props.propertyId} })
        //     .then((res) => {
        //       // toggleSnackbar({
        //       //   status: true,
        //       //   message: "success",
        //       //   variant: "success",
        //       // });
        //     })
        //     .catch((err) => {
        //       const message = err && err.message;
        //       toggleSnackbar({
        //         status: true,
        //         message: message,
        //         variant: "error",
        //       });
        //     });
        // } catch (err) {
        //   const message = err && err.message;
        //   toggleSnackbar({
        //     status: true,
        //     message: message,
        //     variant: "error",
        //   });
        // }
        // } else {
        //   toggleSnackbar({
        //     status: true,
        //     message: "Please agree to listing terms",
        //     variant: "error",
        //   });
        // }
      }
    }
  };

  const longText = register("AgencyDisclosure");
  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log("ipressss", res);
    setIP(res.data.IPv4);
  };
  useEffect(() => {
    getIp();
  }, []);
  return (
    <div className="add-property-form-wrap">
      {/* <Loader open={viewAgreementData?.loading}/> */}
      {pdfUrl ? (
        <>
          {viewAgreementData?.loading === true ? (
            <div className="agreement-pdf-loader">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Document
                //file="https://hrfileuploads.s3.amazonaws.com/signedAgreements/agreement-PID-90.pdf"
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page scale={1.4} pageNumber={pageNumber} />
              </Document>
              <div className="text-center pb-3">
                <IconButton
                  color="primary"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className="Pre"
                >
                  <ArrowBackIosIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  <ArrowForwardIosIcon />
                </IconButton>

                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </p>
              </div>
              
          {window.innerWidth < "575.98" && 
            <div
              className="add-property-form-step add-property-btn justify-content-end"
              style={{ borderTop: "1px solid #CFCFCF" }}
            >
              <Button
                color="primary"
                className="highlight-btn"
                variant="contained"
                onClick={() => history.push("/properties")}
              >
                Done
              </Button>
          </div>
          }
            </>
          )}
        </>
      ) : (
        <>
          <AuthAgreement
            open={open}
            setOpen={setOpen}
            setCheckAuth={setCheckAuth}
          />

          {defaultValue && defaultValue !== false && (
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="add-property-form-step">
                <div className="agreement-wrap">
                  <div className="open-lisnting-agreement">
                    <h6>OPEN LISTING AGREEMENT</h6>
                    <address>
                      Dear{" "}
                      {propertyDetailsData?.propertyDetails?.OwnerDtls
                        ?.FirstName +
                        " " +
                        propertyDetailsData?.propertyDetails?.OwnerDtls
                          ?.LastName}
                      ,
                    </address>

                    <p>
                      Please agree to the following terms to market your
                      property for rent on hamptonsrentals.com, which is owned
                      and managed by Rosehip Partners, LLC. By doing so you are
                      listing your property for rent with Rosehip Partners, LLC,
                      a licensed real estate broker located at 15 Railroad Avenue - Suite 3
                      in East Hampton, NY 11937. This listing is not exclusive
                      and we encourage you to list your rental property with
                      other real estate agencies at your discretion. Please
                      enter your rental property's physical address in the open
                      space below to get started.
                    </p>
                    <div className="row agreement-feild">
                      <div className="col-md-6">
                        <InputLabel className="form-label">
                          Property Street Address
                        </InputLabel>
                        <div className="formTextBoxOuter">
                          <TextField
                            variant="outlined"
                            className="formTextBox"
                            {...register("StreetAddress")}
                            disabled
                            defaultValue={
                              defaultValue?.StreetAddress
                                ? defaultValue?.StreetAddress
                                : propertyDetailsData?.propertyDetails
                                  ?.StreetAddress
                            }
                          // error={errors.StreetAddress}
                          />
                          {/* {errors.StreetAddress && (
                        <p className="errorMessage">
                          {errors.StreetAddress.message}
                        </p>
                      )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <InputLabel className="form-label">
                          Town State Zip
                        </InputLabel>
                        <div className="formTextBoxOuter">
                          <TextField
                            variant="outlined"
                            className="formTextBox"
                            {...register("TownStateZip")}
                            disabled
                            defaultValue={
                              defaultValue?.TownStateZip
                                ? defaultValue?.TownStateZip
                                : propertyDetailsData?.propertyDetails?.City +
                                ", " +
                                propertyDetailsData?.propertyDetails?.State +
                                ", " +
                                propertyDetailsData?.propertyDetails?.Zip
                            }
                          />
                        </div>
                      </div>{" "}
                      <p>I Understand and Agree:</p>
                    </div>

                    <ol>
                      <li>
                        I am legally authorized to offer the above property for
                        rent.
                      </li>

                      <li>
                        I can post, access and update my rental listing directly
                        on hamptonsrentals.com.
                      </li>

                      <li>
                        It is up to me to keep my property listing current
                        should availability or price change.
                      </li>
                      <li>
                        My updates will be published to the other real estate
                        agencies I select via an email from hamptonsrentals.com.
                      </li>

                      <li>
                        My name, contact information,any private details about
                        access will NOT be disclosed publicly on
                        hamptonsrentals.com.
                      </li>

                      <li>
                        This listing is non-exclusive and I am free to list my
                        property with other real estate agencies.
                      </li>
                      <li>
                        It is up to me to provide accurate contact information
                        and timely access to my property for showings.
                      </li>

                      <li>
                        Rosehip Partners reserves the right to edit my rental
                        listing.
                      </li>

                      <li>
                        A licensed real estate agent associated with Rosehip
                        Partners, LLC will handle all showings of my property,
                        generated by inquiries from hamptonsrentals.com.
                      </li>

                      <li>
                        Posting and managing my property on hamptonsrentals.com
                        is free, but should Rosehip Partners secure a tenant for
                        my property, I agree to pay to Rosehip a commission
                        equal to 10% of the contracted rental price.
                        <ul>
                          <li>
                            Should the tenant renew the lease or enter into any
                            new lease Landlord acknowledges Rosehip is due a fee
                            and agrees to pay the same for any subsequent
                            renewals by the same tenant. Renewal fees are as
                            follows: 10% each year for years 2-3, 8% each year
                            for years 4-6, 6% for each additional year
                            thereafter.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Should any agency OTHER THAN Rosehip Partners secure a
                        tenant for my property, I will owe Rosehip Partners NO
                        commission.
                      </li>
                      <li>
                        Should the Tenant provided to me by Rosehip Partners
                        purchase my property at any time in the future I will
                        owe Rosehip Partners, LLC a commission equal to 5% of
                        the gross sales price.
                        <ul>
                          <li>
                            If Owner enters into a future exclusive sales
                            listing with any Broker besides Rosehip Partners any
                            tenants provided by Rosehip Partners shall be listed
                            as exemptions to the exclusive sales listing
                            agreement.
                          </li>
                        </ul>
                      </li>
                    </ol>

                    {/* <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          {...register("ListingTermChk")}
                          defaultChecked={defaultValue?.ListingTermChk}
                          // onChange={(e) => setCheckedAgree(e.target.checked)}
                          // value={checkedAgree}
                        />
                      }
                      label="Please check here to agree to listing terms and continue to agency disclosure."
                    />
                  </div> */}

                    <div className="agreement-from">
                      <address>Sincerely,</address>
                      Joseph Kazickas
                      <br />
                      Managing Broker, Rosehip Partners, LLC
                    </div>
                  </div>
                  <div className="agency-disclosure ">
                    <h6>NY AGENCY DISCLOSURE</h6>
                    <p>
                      To complete the listing process you will see here a New
                      York State Disclosure Form for Landlord and Tenant that we
                      are required to present to you by NY real estate law. This
                      is not a contract but a disclosure statement that
                      explicitly defines our agency relationship with you. This
                      document does not bind you in any way. We are agents of
                      the Landlord.
                    </p>

                    <p>
                      Please read through the form and enter your
                      hamptonsrentals.com password at the bottom of the page. By
                      doing so you are “E-signing” the document and
                      acknowledging we have presented it to you. If you have any
                      questions please give us a call at 631-324-0009
                    </p>

                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=""
                      className="formTextBox textarea"
                      //   {...register("AgencyDisclosure")}
                      //   value={textData}
                      // defaultValue={defaultValue?.AgencyDisclosure}
                      value={longTextData}
                      {...longText}
                      onChange={(e) => {
                        longText.onChange(e);
                        setLongTextData(e.target.value);
                      }}
                    />

                    <p>
                      This form was provided to me by Joseph Kazickas, Managing
                      Broker of Rosehip Partners LLC, a licensed real estate
                      broker acting in the interest of the:
                    </p>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="formTextBoxOuter">
                          <InputLabel className="form-label">PARTY</InputLabel>
                          <TextField
                            variant="outlined"
                            className="formTextBox w-100 mb-0"
                            defaultValue="Landlord"
                            // defaultValue={defaultValue?.Party
                            //   ? defaultValue?.Party
                            //   : "Landlord"}
                            {...register("Party")}
                          />
                          {/* <Select
                            variant="outlined"
                            className={"formTextBox"}
                            defaultValue={
                              defaultValue?.Party
                                ? defaultValue?.Party
                                : "landlord"
                            }
                            {...register("Party")}
                            error={errors.Party}
                          >
                            <MenuItem value="landlord">Landlord</MenuItem>
                            <MenuItem value="tenant">Tenant</MenuItem>
                          </Select> */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        {/* <InputLabel className="form-label">
                                Town State Zip
                                </InputLabel> */}
                        <div className="formTextBoxOuter">
                          <InputLabel className="form-label mt-3 mt-md-0">AS A</InputLabel>
                          <TextField
                            variant="outlined"
                            className="formTextBox w-100 mb-0"
                            defaultValue="Landlord's agent"
                            // defaultValue={ defaultValue?.PartyAs
                            //   ? defaultValue?.PartyAs
                            //   : "Landlord's agent"}
                            {...register("PartyAs")}
                          />
                          {/* <Select
                            variant="outlined"
                            className={"formTextBox"}
                            placeholder="As a"
                            defaultValue={
                              defaultValue?.PartyAs
                                ? defaultValue?.PartyAs
                                : "landlord's agent"
                            }
                            {...register("PartyAs")}
                            error={errors.PartyAs}
                          >
                            <MenuItem value="landlord's agent">
                              Landlord's agent
                            </MenuItem>
                            <MenuItem value="tenant's agent">
                              Tenant's agent
                            </MenuItem>
                            <MenuItem value="broker's agent">
                              Broker's agent
                            </MenuItem>
                            <MenuItem value="dual agent">Dual agent</MenuItem>
                            <MenuItem value="dual agent with designated sales agents">
                              Dual agent with designated sales agents
                            </MenuItem>
                          </Select> */}
                        </div>
                      </div>
                    </div>

                    <div className="agreement-confirmation">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="d-flex">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  //value ={checked}
                                  //onChange ={(event)=>{setChecked(event.target.checked);}}
                                  defaultChecked={
                                    defaultValue?.ChkLandlordName1 === "true"
                                      ? true
                                      : false
                                  }
                                  {...register("ChkLandlordName1")}
                                />
                              }
                              label="I/WE"
                            />

                            <div className="formTextBoxOuter w-100">
                              <TextField
                                variant="outlined"
                                className="formTextBox w-100 mb-0"
                                disabled
                                defaultValue={
                                  defaultValue?.LandlordName1
                                    ? defaultValue?.LandlordName1
                                    : propertyDetailsData?.propertyDetails
                                      ?.OwnerDtls?.FirstName +
                                    " " +
                                    propertyDetailsData?.propertyDetails
                                      ?.OwnerDtls?.LastName
                                }
                                {...register("LandlordName1")}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <span>
                            Landlord Name acknowledge electronic presentation
                            via Internet of this disclosure form and email
                            confirmation of the same:Landlord Name
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="d-flex">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  defaultChecked={
                                    defaultValue?.ChkLandlordName2 === "true"
                                      ? true
                                      : false
                                  }
                                  {...register("ChkLandlordName2")}
                                />
                              }
                              label="I/WE"
                            />

                            <div className="formTextBoxOuter w-100">
                              <TextField
                                variant="outlined"
                                className="formTextBox w-100 mb-0"
                                defaultValue={defaultValue?.LandlordName2}
                                {...register("LandlordName2")}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <span>
                            2nd Landlord (if applicable) acknowledge electronic
                            presentation via Internet of this disclosure form
                            and email confirmation of the same:
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="agreement-wrap housing">
                  <h3>New York State Housing and Anti-Discrimination Disclosure Form</h3>
                  <div className="open-lisnting-agreement">
                    <p>Federal, State and local Fair Housing Laws provide comprehensive protections from discrimination in
                      housing. It is unlawful for any property owner, landlord, property manager or other person who sells, rents or
                      leases housing, to discriminate based on certain protected characteristics, which include, but are not limited
                      to <b>race, creed, color, national origin, sexual orientation, gender identity or expression, military status,
                        sex, age, disability, marital status,</b> lawful source of income or familial status. Real estate professionals
                      must also comply with all Fair Housing Laws.</p>
                    <p><b>Real estate brokers and real estate salespersons, and their employees and agents violate the Law if they:</b></p>
                    <ol>
                      <li>Discriminate based on any protected characteristic when negotiating a sale, rental or lease,
                        including representing that a property is not available when it is available.</li>
                      <li>Negotiate discriminatory terms of sale, rental or lease, such as stating a different price because of race,
                        national origin or other protected characteristic.</li>
                      <li>Discriminate based on any protected characteristic because it is the preference of a seller or landlord.</li>
                      <li>Discriminate by “steering” which occurs when a real estate professional guides prospective buyers or
                        renters towards or away from certain neighborhoods, locations or buildings, based on any protected
                        characteristic.</li>
                      <li>Discriminate by “blockbusting” which occurs when a real estate professional represents that a change has
                        occurred or may occur in future in the composition of a block, neighborhood or area, with respect to any
                        protected characteristics, and that the change will lead to undesirable consequences for that area, such
                        as lower property values, increase in crime, or decline in the quality of schools.</li>
                      <li>Discriminate by pressuring a client or employee to violate the Law.</li>
                      <li>Express any discrimination because of any protected characteristic by any statement, publication,
                        advertisement, application, inquiry or any Fair Housing Law record.</li>
                    </ol>
                    <h6>YOU HAVE THE RIGHT TO FILE A COMPLAINT</h6>
                    <p><b>If you believe you have been the victim of housing discrimination</b> you should file a complaint with the
                      New York State Division of Human Rights (DHR). Complaints may be filed by:</p>
                    <ol>
                      <li>Downloading a complaint form from the DHR website: <a href="https://dhr.ny.gov/" target="_blank" rel="noopener noreferrer">www.dhr.ny.gov</a>;</li>
                      <li>Stop by a DHR office in person, or contact one of the Division’s offices, by telephone or by mail, to obtain
                        a complaint form and/or other assistance in filing a complaint. A list of office locations is available online at: <a href="https://dhr.ny.gov/contact-us," target="_blank" rel="noopener noreferrer">https://dhr.ny.gov/contact-us</a>, and the Fair Housing HOTLINE at <a href="tel:(844)-862-8703">(844)-862-8703</a>.</li>
                    </ol>
                    <p>You may also file a complaint with the NYS Department of State, Division of Licensing Services. Complaints may be filed by:</p>
                    <ol>
                      <li>Downloading a complaint form from the Department of State’s website&nbsp;
                        <a href="https://www.dos.ny.gov/licensing/complaint_links.html" target="_blank" rel="noopener noreferrer">https://www.dos.ny.gov/licensing/complaint_links.html</a></li>
                      <li>Stop by a Department’s office in person, or contact one of the Department’s offices, by telephone or by
                        mail, to obtain a complaint form.</li>
                      <li>Call the Department at <a href="tel:(518) 474-4429">(518) 474-4429.</a></li>
                    </ol>
                    <p>There is no fee charged to you for these services. It is unlawful for anyone to retaliate against you for filing a complaint.</p>
                    <p>For more information on Fair Housing Act rights and responsibilities please visit&nbsp;
                      <a href="https://dhr.ny.gov/fairhousing" target="_blank" rel="noopener noreferrer">https://dhr.ny.gov/fairhousing</a> and  <a href="https://www.dos.ny.gov/licensing/fairhousing.html" target="_blank" rel="noopener noreferrer">https://www.dos.ny.gov/licensing/fairhousing.html</a>.</p>
                    <p>This form was provided to me by <b>Joseph M. Kazickas</b> (print name of Real Estate Salesperson/Broker) of <b>Rosehip Partners LLC</b> (print name of Real Estate company, firm or brokerage)</p>

                    <p>I/We <b>{propertyDetailsData?.propertyDetails
                              ?.OwnerDtls?.FirstName +
                            " " +
                            propertyDetailsData?.propertyDetails
                              ?.OwnerDtls?.LastName}</b> landllord acknowledge receipt of a copy of this disclosure form: </p>

                    <div className="row signature-date">
                      <div className="col-md-8">
                        <div className="formTextBoxOuter">
                          <InputLabel className="form-label">Landlord Signature</InputLabel>
                          <TextField
                            variant="outlined"
                            className="formTextBox w-100 mb-0"
                            defaultValue=""
                            placeholder="Signature"
                            disabled

                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formTextBoxOuter">
                          <InputLabel className="form-label">Date</InputLabel>
                          <TextField
                            variant="outlined"
                            className="formTextBox w-100 mb-0"
                            defaultValue={moment().format("L")}
                            disabled

                          />
                        </div>
                      </div>
                    </div>
                    <p>Real Estate broker and real estate salespersons are required by New York State law to provide you with this Disclosure.</p>

                  </div>
                </div>

                <div className="agreeing agreement-wrap">
                  <b>
                    By logging into my account below I certify that I have
                    personally listed my property located at
                  </b>
                  <div className="row">
                    <div className="col-md-6">
                      <InputLabel className="form-label">
                        Property Street Address
                      </InputLabel>
                      <div className="formTextBoxOuter">
                        <TextField
                          variant="outlined"
                          className="formTextBox"
                          {...register("StreetAddress")}
                          disabled
                          defaultValue={
                            propertyDetailsData?.propertyDetails?.StreetAddress
                          }
                        // error={errors.StreetAddress}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <InputLabel className="form-label">
                        Town State Zip
                      </InputLabel>
                      <div className="formTextBoxOuter">
                        <TextField
                          variant="outlined"
                          className="formTextBox"
                          {...register("TownStateZip")}
                          disabled
                          defaultValue={
                            propertyDetailsData?.propertyDetails?.Zip
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <p>
                    with Rosehip Partners LLC via their Web domain,
                    hamptonsrentals.com. I also certify that they have presented
                    me with a New York State Disclosure Form for Landlord and
                    Tenant form that I will sign by logging in below to
                    acknowledge our mutual agency relationship. I also
                    understand and certify that by entering my account ID and
                    password and clicking "Submit Signature" I am in fact
                    submitting an electronic signature endorsing this document.
                  </p>
                  <p>
                    <b>
                      To verify your identity Rosehip Partners LLC will record
                      the below information with your e-signature.
                    </b>
                  </p>
                  <div className="row owner-detail-agreement">
                    <div className="col-md-4">
                      <InputLabel className="form-label">Owner Name</InputLabel>
                      <div className="formTextBoxOuter">
                        <TextField
                          variant="outlined"
                          className="formTextBox"
                          defaultValue={
                            propertyDetailsData?.propertyDetails
                              ?.OwnerDtls?.FirstName +
                            " " +
                            propertyDetailsData?.propertyDetails
                              ?.OwnerDtls?.LastName
                            // user?.data?.FirstName + " " + user?.data?.LastName
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <InputLabel className="form-label">Date</InputLabel>
                      <div className="formTextBoxOuter">
                        <TextField
                          variant="outlined"
                          className="formTextBox"
                          defaultValue={moment().format("L")}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <InputLabel className="form-label">IP Address</InputLabel>
                      <div className="formTextBoxOuter">
                        <TextField
                          variant="outlined"
                          className="formTextBox"
                          value={ip}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row justify-content-center">
                <div className="col-12">
                  <Button className="w-100" variant="outlined" color="primary">
                    Send Link
                  </Button>
                </div>
              </div> */}
                </div>
              </div>
              <div
                className="add-property-form-step add-property-btn"
                style={{ borderTop: "1px solid #CFCFCF" }}
              >
                <Button
                  className="outline-btn"
                  variant="outline"
                  onClick={props.handlePrevious}
                >
                  Back
                </Button>
                <div>
                  {/* {checked===true?( */}

                  {user?.data?.Role === "owner" ||
                    user?.data?.Role === "renter" ? (
                    <Button
                      disabled={loading}
                      type="submit"
                      color="primary"
                      className="highlight-btn"
                      variant="contained"
                    >
                      {loading || editLoading ? (
                        <CircularProgress size="20px" color="inherit" />
                      ) : (
                        "Sign Agreement"
                      )}
                    </Button>
                  ) : (
                    <>
                      <Button
                        id="only-save-agreement"
                        disabled={loading}
                        type="submit"
                        color="primary"
                        className="highlight-btn me-3"
                        variant="contained"
                      >
                        {(loading || editLoading) && saveOnlyLoader !== true ? (
                          <CircularProgress size="20px" color="inherit" />
                        ) : (
                          changeButton()
                        )}
                      </Button>
                      <Button
                        disabled={loading}
                        //type="submit"
                        color="primary"
                        //onClick={()=>handleSubmit((data) => onSubmit(data,flag))}
                        className="highlight-btn"
                        variant="contained"
                        onClick={(e) => {
                          setSaveOnlyLoader(true);
                          setValue("saveAndProceed", true);
                          document
                            .getElementById("only-save-agreement")
                            .click();
                        }}
                      >
                        {(loading || editLoading) && saveOnlyLoader === true ? (
                          <CircularProgress size="20px" color="inherit" />
                        ) : (
                          "Save Agreement"
                        )}
                      </Button>
                    </>
                  )}

                  {/* ):(
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                  toggleSnackbar({
                  status: true,
                  message: "Please select checkbox",
                  variant: "error",
                });
              }}
            >
               {loading || editLoading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : (
                    "Send Agreement"
                  )}
            </Button>
                )} */}
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};
export default Agreement;
