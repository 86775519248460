import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import { useHistory } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_AGENT, LIST_ALL_AGENT } from "../../Graghql/queries";
import { DEACTIVATE_AGENT } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import SideBar from "../Common/sidebar";
import AddAgent from "../Agent/AddAgent";
import LoggedInHeader from "../Common/loggedinHeader";

import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Loader from "../Common/Loader";

const options = [
  { label: "Edit", id: "EDIT" },
  { label: "Deactivate", id: "DEACTIVATE" },
];
const Agent = () => {
  const history= useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setAnchorElDelete] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [, setIsPaginating] = React.useState(false);
  const [openAddAgent, setOpenAddAgent] = React.useState(false);

  const { data: listAllAgent, fetchMore: fetchMoreAllAgents } = useQuery(
    LIST_ALL_AGENT,
    { fetchPolicy: "network-only" }
  );
  const agentListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    search: searchKeyword,
  };

  const {
    data,
    loading: agentLoading,
    fetchMore,
  } = useQuery(LIST_AGENT, {
    fetchPolicy: "network-only",
    variables: agentListVariables,
  });
  const handlePagination = () => {
    fetchMore({
      variables: agentListVariables,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  React.useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchKeyword]);

  const [deactivateAgent] = useMutation(DEACTIVATE_AGENT, {
    refetchQueries: ["listAgent", "listAllAgents"],
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleClick = (event, _itemToDeactivate) => {

  //   setItemToDeactivate(_itemToDeactivate);
  //   setAnchorEl(event.currentTarget);
  // };
  let agentQueue =
    listAllAgent &&
    listAllAgent.listAllAgents &&
    listAllAgent.listAllAgents.data;
  //console.log("agentQueue",agentQueue)
  let agentListCount = data && data.listAgent && data.listAgent.count;
  let agentList = data && data.listAgent && data.listAgent.data;
  //console.log("agentList", agentList, agentListCount)
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  const handleMenuChange = (type, item, target) => {
    item && setSelectedItem(item);
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "DEACTIVATE":
        setAnchorEl(target);
        break;
      case "TOGGLE_DEACTIVATE_ALERT":
        setAnchorEl(null);
        setDeleteAlert(!deleteAlert);
        handleCloseDelete();
        break;
      case "CONFIRM_DEACTIVATE":
        setAnchorEl(null);
        handleDeactivate();
        setDeleteAlert(!deleteAlert);
        break;
      case "Edit":
        setAnchorEl(null);
        setOpenAddAgent(true);
        break;
    }
  };

  const handleDeactivate = () => {
    try {
      deactivateAgent({
        variables: { agentId: selectedItem._id, status: selectedItem.Status },
      })
        .then((res) => {
          const responseData = res.data && res.data.deactivateAgent;
          //responseData && responseData.status=='active'?'inactive':'active'

          if (responseData && responseData.status)
            toggleSnackbar({
              status: true,
              message: responseData.message,
            });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function handleAddBtnClick(params) {
    setOpenAddAgent(true);
    setSelectedItem(null);
  }
  const changedStatus =
    selectedItem?.Status === "active" ? "inactive" : "active";
    
  return (
    <div className="custom-layout">
      <AddAgent
        handlePagination={handlePagination}
        open={openAddAgent}
        setOpen={setOpenAddAgent}
        defaultValue={selectedItem}
        setDefaultValue={setSelectedItem}
        fetchMoreAllAgents={fetchMoreAllAgents}
      />
      <LoggedInHeader
        handleAddBtnClick={handleAddBtnClick}
        label="Agent"
        addBtnLabel="Add Agent"
      />
      <Loader open={agentLoading} />
      <div className="custom-layout-content">
        <div className="search-band">
          <TextField
            id="standard-full-width"
            className="fullWidthSearch"
            label="SEARCH"
            disableUnderline={false}
            placeholder="Name, Address, Email"
            type="search"
            onChange={(event) => setSearchKeyword(event.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
              disableUnderline: false,
            }}
          />
        </div>
        <div className="content-wrap">
          <SideBar selected="agent" />
          <div className="row">
            <div className="col-lg-8">
              <h5>Agent Listing</h5>
              <div>
                <Table
                  className="common-table"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="right">Name</TableCell>
                      <TableCell align="right">Listing</TableCell>
                      <TableCell align="right">In Lead Cycle</TableCell>
                      <TableCell align="right">Agent Status</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {agentLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Agents...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : agentList && agentList.length ? (
                      agentList.map((item, index) => (
                        <TableRow key={index} >
                          <TableCell component="th" scope="row">
                            {/* <FormControlLabel
                              className="table-checkbox"
                              control={
                                <Checkbox
                                  // checked={
                                  //     !!this.state.selectedCaseIds[items._id]
                                  // }
                                  // onChange={(event) =>
                                  //     this.handleChange(
                                  //         items,
                                  //         event.target.checked
                                  //     )
                                  // }
                                  //name="checkedB"
                                  color="primary"
                                />
                              }
                            /> */}
                            <label>
                              {item?.FirstName?.charAt(0).toUpperCase() +
                                item?.FirstName?.slice(1).toLowerCase() +
                                " " +
                                item?.LastName?.charAt(0).toUpperCase() +
                                item?.LastName?.slice(1).toLowerCase()}
                            </label>
                          </TableCell>
                          <TableCell onClick={()=>
                          history.push({
                          pathname: '/properties',
                          state:{agentValue:item?._id,agentName:item?.FirstName+" "+item?.LastName},
                        })} style={{cursor:"pointer"}}>{item?.PropertyCount}</TableCell>
                          <TableCell>
                            {item?.InLeadCycle === true ? "Yes" : "No"}
                          </TableCell>
                          <TableCell>
                            {item?.Status?.charAt(0).toUpperCase() +
                              item?.Status?.slice(1)}
                            {/* {item.InLeadCycle+""} */}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuChange(
                                  "DEACTIVATE",
                                  item,
                                  e.currentTarget
                                )
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        
                      ))
                    ) : searchKeyword ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Agents Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Agents Added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!agentLoading &&agentListCount>0&& (
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={agentListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    //maxHeight: ITEM_HEIGHT * 4.5,
                    //width: '20ch',
                  },
                }}
              >
                {options.map((option, index) => {
                  let statusLabel =
                    option.label === "Deactivate"
                      ? changedStatus === "inactive"
                        ? "Deactivate"
                        : "Activate"
                      : option.label;
                  return (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        handleMenuChange(
                          option.label === "Deactivate" ||
                            option.label === "Activate"
                            ? "TOGGLE_DEACTIVATE_ALERT"
                            : option.label
                        )
                      }
                    >
                      {statusLabel}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Dialog
                open={deleteAlert}
                onClose={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {changedStatus === "inactive" ? "Deactivate ?" : "Activate ?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {changedStatus === "inactive"
                      ? "Are you sure you want to deactivate?"
                      : "Are you sure you want to activate?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                    color="primary"
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => handleMenuChange("CONFIRM_DEACTIVATE")}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="col-lg-4">
              <h5>Agent Queue</h5>
              <List className="agent-queue">
                {agentQueue &&
                  agentQueue.map((item, index) => (
                    <>
                      {console.log(
                        "item?.LastLeadRvd?.LastLeadDate",
                        item?.LastLeadRvd?.LastLeadDate
                      )}
                      <React.Fragment>
                        <ListItem alignItems="flex-start" key={index}>
                          <ListItemAvatar className="mt-0">
                            <Avatar alt="Remy Sharp" src={item.ProfileImage}>
                              {item.FirstName.charAt(0).toUpperCase()+item.LastName.charAt(0).toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <div>
                            <h3>
                              {item.FirstName.charAt(0).toUpperCase() +
                                item.FirstName.slice(1).toLowerCase() +
                                " " +
                                item.LastName.charAt(0).toUpperCase() +
                                item.LastName.slice(1).toLowerCase()}
                            </h3>
                            {/* <time>{"LAST Lead Received Jun 23 10:am"}</time> */}
                            {item?.LastLeadRvd?.LastLeadDate && (
                              <time>LAST Lead Received{" "}
                                {moment(
                                  new Date(
                                    parseInt(item?.LastLeadRvd?.LastLeadDate)
                                  )
                                ).format("MMM DD, h:mm A")}
                              </time>
                            )}
                            {/* <address>Dianne Russell</address> */}
                            <address>
                              {item?.LastLeadRvd?.UserFName?.charAt(
                                0
                              ).toUpperCase() +
                                item?.LastLeadRvd?.UserFName?.slice(
                                  1
                                ).toLowerCase() +
                                " " +
                                item?.LastLeadRvd?.UserLName?.charAt(
                                  0
                                ).toUpperCase() +
                                item?.LastLeadRvd?.UserLName?.slice(
                                  1
                                ).toLowerCase()}
                            </address>
                          </div>
                        </ListItem>
                        <Divider variant="outset" component="li" />
                      </React.Fragment>
                    </>
                  ))}
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent;
