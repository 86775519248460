import React from "react";
import { DialogBox } from "../../Common";
import { useMutation } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import { UPDATE_PROPERTY_STATUS } from "../../../Graghql/mutations";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables";
const Offthemarket = (props) => {
  //console.log(",,,,,,,,,,,", props?.propertyStatus);
  const user = useReactiveVar(userDeatils);
  const [offthemarket] = useMutation(UPDATE_PROPERTY_STATUS);
  const [onthemarket] = useMutation(UPDATE_PROPERTY_STATUS);

  const handleonthemarket = () => {
    try {
      onthemarket({
        variables: {
          PropertyId: props.propertyId,
          ActionType: "On The Market",
        },
      })
        .then((res) => {
          props && props.handlePagination();
          user?.data?.Role === "admin" || user?.data?.Role === "agentAdmin"
            ? toggleSnackbar({
                status: true,
                message: "Property marked as  On the Market ",
                variant: "success",
              })
            : toggleSnackbar({
                status: true,
                message: " On the Market Request has been sent to Admin",
                variant: "success",
              });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleoffthemarket = () => {
    try {
      offthemarket({
        variables: {
          PropertyId: props.propertyId,
          ActionType: "Off the Market",
        },
      })
        .then((res) => {
          props && props.handlePagination();
          (user && user.data && user.data.Role === "admin") ||
          (user && user.data && user.data.Role === "agentAdmin")
            ? toggleSnackbar({
                status: true,
                message: "Property marked as  OTM/Inactive ",
                variant: "success",
              })
            : toggleSnackbar({
                status: true,
                message: " OTM/Inactive Request has been sent to Admin",
                variant: "success",
              });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div>
      {props?.propertyStatus === "Off the Market" ||
      props?.propertyStatus === "Pending Off the Market" ? (
        <DialogBox
          open={props.open}
          setOpen={props.setOpen}
          handlePositiveBtn={handleonthemarket}
          title={`On the Market Property - #PID ${props.pid}`}
          text={"Are you sure want to make property  On the Market?"}
        />
      ) : (
        <DialogBox
          open={props.open}
          setOpen={props.setOpen}
          handlePositiveBtn={handleoffthemarket}
          title={`OTM/Inactive Property - #PID ${props.pid}`}
          text={"Are you sure want to make property  OTM/Inactive?"}
        />
      )}
    </div>
  );
};

export default Offthemarket;
