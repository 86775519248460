import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { CircularProgress,Dialog } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { AUTH_FOR_SIGNIN } from "../../../Graghql/mutations";
import { toggleSnackbar} from "../../../RactiveVariables/index";
import { useHistory,Link } from "react-router-dom";

const AuthAgreement = (props) => {
  // eslint-disable-next-line no-unused-vars
  const history=useHistory()
  const emailRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [authLogin, { loading }] = useMutation(AUTH_FOR_SIGNIN);

  useEffect(() => {
    if (emailRef.current) {
      register(emailRef.current);
      emailRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    //console.log("dataaaa",data)
   
      try {
        authLogin({ variables: data })
          .then((res) => {
            //console.log("resss",res)
            
            let statusLogin= res.data.authForSign.status===true?res.data.authForSign.status:null;
            props.setCheckAuth(statusLogin)
            toggleSnackbar({
                status: true,
                message: "Login sucessfully",
                variant: "success",
              });
            props.setOpen(false)
            
          })
          .catch((err) => {
            console.log("errrrrr", err);
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        console.log("err", err);
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }

  };
const handleClose=()=>{
    props.setOpen(false)
}
  return (
    <Dialog 
    onClose={handleClose} 
    open={props.open}>
    <div className="login-register-wrap" style={{overflowY:'hidden', height:'auto'}}>
      <div className="login-register">
        <img
          src="../assets/images/logo.svg"
          className="login-logo img-fluid d-block m-auto"
          alt="logo"
        />
        <div className="login-register-tab">
          
              <Link 
              to="#"
              //onClick={() => props.setLogin(true)} 
              className="active">
                Log in
              </Link>
             
           
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="form-label">
              Email <sup>*</sup>
            </label>
            <input
              type="email"
              className="form-control"
              ref={emailRef}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="error-msg">Email is required</span>
            )}
          </div>
       
            <div className="form-group">
              <label for="" className="form-label">
                Password <sup>*</sup>
              </label>
              <input
                type="password"
                className="form-control"
                id=""
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span className="error-msg">Password is required</span>
              )}
              {/* <a
                onClick={() => setForgetPass(true)}
                className="forgot-password-link"
              >
                Forgot Password?
              </a> */}
            </div>
        
          <div>
            <Button
              disabled={loading}
              className="login-sumbit-btn"
              type="submit"
              color="primary"
            >
              {loading  ? (
                <CircularProgress color="secondary" />
              ) :  (
                "LOG IN"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
    </Dialog>
  );
};

export default AuthAgreement;
