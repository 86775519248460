import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Link } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete, {
} from "@material-ui/lab/Autocomplete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_OWNER, LIST_ALL_USERS } from "../../Graghql/queries";
import { DELETE_OWNER, DEACTIVATE_OWNER } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import SideBar from "../Common/sidebar";
import AddOwner from "../Owner/AddOwner";
import LoggedInHeader from "../Common/loggedinHeader";
import { DialogBox } from "../Common";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router";
import { userDeatils } from "../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  menuPaper: {
    maxHeight: 200,
  },
}));
const Agent = () => {
  const user = useReactiveVar(userDeatils);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setAnchorElDelete] = useState(null);
  const [deleteVar, setDeleteVar] = useState(null);
  const [email, setEmail] = useState([]);
  const [visible, setVisible] = useState(false);
  const open = Boolean(anchorEl);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [, setIsPaginating] = useState(false);
  const [openAddOwner, setOpenAddOwner] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [renderdetails, setRenderdetails] = useState("");
  console.log("renderdetails", renderdetails);
  const [agent, setAgent] = useState("All");
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setAgent(event.target.value);
  };
  const [deleteOwner] = useMutation(DELETE_OWNER);

  const options = [
    { label: "Edit", id: "EDIT" },
    { label: "Deactivate", id: "DEACTIVATE" },
    { label: "Delete", id: "DELETE" },
  ];

  const ownerListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    search: searchKeyword,
    agentId: renderdetails?._id,
  };
  const { data: agentData } = useQuery(LIST_ALL_USERS, {
    variables: { role: "agent", skip: 0, limit: 20, search: agent },
  });
  let agentName = agentData && [...agentData.listAllUsers];
  const {
    data,
    loading: ownerLoading,
    fetchMore,
  } = useQuery(LIST_OWNER, {
    fetchPolicy: "network-only",
    variables: ownerListVariables,
  });
  const handlePagination = () => {
    fetchMore({
      variables: ownerListVariables,
    });
  };
  const [deactivateOwner] = useMutation(DEACTIVATE_OWNER, {
    refetchQueries: ["listOwner"],
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };
  React.useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, agent, searchKeyword]);

  // const [deactivateAgent] = useMutation(DEACTIVATE_AGENT,{ refetchQueries:['listAgent'] })
  const handleClose = () => {
    setAnchorEl(null);
  };

  let ownerListCount = data && data.listOwner && data.listOwner.count;
  let ownerList = data && data.listOwner && data.listOwner.data;

  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  const handleMenuChange = (type, item, target, element) => {
    item && setDefaultValue(item);
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "EDIT":
        setAnchorEl(target);
        break;
      case "Edit":
        setAnchorEl(null);
        setOpenAddOwner(true);
        break;
      case "TOGGLE_ADD":
        setOpenAddOwner(true);
        setAnchorEl(false);
        break;
      case "DEACTIVATE":
        setAnchorEl(target);
        break;
      case "TOGGLE_DEACTIVATE_ALERT":
        setAnchorEl(null);
        setDeleteAlert(!deleteAlert);
        handleCloseDelete();
        break;
      case "CONFIRM_DEACTIVATE":
        setAnchorEl(null);
        handleDeactivate();
        setDeleteAlert(!deleteAlert);
        break;
      case "DELETE":
        setAnchorEl(target);
        break;
      case "Delete":
        setAnchorEl(null);
        setVisible(true);
        setDeleteVar(element);

        break;
    }
  };
  const handleDeactivate = () => {
    try {
      deactivateOwner({
        variables: { ownerId: defaultValue._id, status: defaultValue.Status },
      })
        .then((res) => {
          const responseData = res.data && res.data.deactivateOwner;
          // eslint-disable-next-line no-unused-vars
          const changedStatus =
            responseData && responseData.status === "active"
              ? "inactive"
              : "active";
          //responseData && responseData.status=='active'?'inactive':'active'

          if (responseData && responseData.status)
            toggleSnackbar({
              status: true,
              message: responseData.message,
            });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function handleDelete() {
    try {
      deleteOwner({
        variables: {
          ownerIds: deleteVar === null ? selectedItem : defaultValue._id,
        },
      })
        .then((res) => {
          res?.data?.deleteOwners?.status === true
            ? toggleSnackbar({
                status: true,
                message: "Owner successfully deleted",
                variant: "success",
              })
            : toggleSnackbar({
                status: true,
                message: res?.data?.deleteOwners?.message,
                variant: "error",
              });
          setSelectedItem([]);
          handlePagination();
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleCheckboxChange(item, value) {
    let arr1 = JSON.stringify(selectedItem);
    let arr = JSON.parse(arr1);
    let emailArr1 = JSON.stringify(email);
    let emailArr = JSON.parse(emailArr1);
    if (value === true) {
      arr.push(item._id);
      emailArr.push(item.Email);
    } else {
      let index = arr.indexOf(item._id);
      arr.splice(index, 1);
      emailArr.splice(index, 1);
    }
    setSelectedItem(arr);
    setEmail(emailArr);
  }
  // eslint-disable-next-line no-unused-vars
  function sendEmail() {
    if (email.length > 0) document.getElementById("send-email-button").click();
  }
  function handleAddBtnClick(params) {
    setOpenAddOwner(true);
    setDefaultValue(null);
  }
  const changedStatus =
    defaultValue?.Status === "active" ? "inactive" : "active";
  function sortLabels(a, b) {
    var nameA = a.FirstName.toUpperCase();
    var nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div className="custom-layout">
      <AddOwner
        handlePagination={handlePagination}
        open={openAddOwner}
        setOpen={setOpenAddOwner}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />

      <LoggedInHeader
        handleAddBtnClick={handleAddBtnClick}
        label="Owner"
        addBtnLabel="Add Owner"
        handleDelete={() => {
          if (selectedItem.length > 0 || deleteVar != null) setVisible(true);
          else {
            toggleSnackbar({
              status: true,
              message: "Please select Owner",
              variant: "error",
            });
          }
        }}
        // sendEmail={sendEmail}
      />
      <div className="custom-layout-content">
        <div className="search-band">
          <DialogBox
            open={visible}
            setOpen={setVisible}
            setDeleteVar={setDeleteVar}
            handlePositiveBtn={handleDelete}
            title={"Delete?"}
            text={
              selectedItem.length === 1 || deleteVar == null
                ? "Do you want to delete these owner ?"
                : "Do you want to delete these owners ?"
            }
            // positiveText={"Delete"}
            // negativeText={"Cancel"}
          />
          <form>
            <FormControl>
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Name, Address, Email"
                type="search"
                onChange={(event) => setSearchKeyword(event.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>
            <FormControl className="autocompleteSearch">
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Agent
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={agent}
                onChange={handleChange}
                label="Agent"
                defaultValue="All"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="All">All</MenuItem>
                {agentName?.sort(sortLabels).map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item?.FirstName.charAt(0).toUpperCase() + item?.FirstName.slice(1).toLowerCase() + " " + item?.LastName.charAt(0).toUpperCase() + item?.LastName.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </Select> */}
              <InputLabel>Agent</InputLabel>
              <Autocomplete
                id="demo-simple-select-outlined"
                options={agentName?.sort(sortLabels) ?? []}
                inputValue={agent}
                //freeSolo
                getOptionLabel={(option) =>
                  option?.FirstName.charAt(0).toUpperCase() +
                  option?.FirstName.slice(1).toLowerCase() +
                  " " +
                  option?.LastName.charAt(0).toUpperCase() +
                  option?.LastName.slice(1).toLowerCase()
                }
                className="w-100 fullWidthSearch"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="All"/>
                )}
                //value={renderdetails?.Email}
                //value={error.suggest}
                onChange={(e, value) => {
                  setRenderdetails(value);
                  // setEmailError()
                }}
                onInputChange={(e, value) => {
                  setAgent(value);
                }}
                // filterOptions={(options, params) => {
                //  // const filtered = filter(options, params);
                //   setSearchKeyword(params.inputValue)
                //   // Suggest the creation of a new value
                //   // if (params.inputValue !== "") {
                //   //   filtered.push(params.inputValue);
                //   // }
                //   // return filtered;
                // }}
              />
            </FormControl>
          </form>
        </div>
        <div className="content-wrap">
          <SideBar selected={"owner"} />
          <div className="row">
            <div className="col-md-12">
              {/* <h5>Owner Listing</h5> */}
              <div>
                <Table
                  className="common-table"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell className="text-left">
                        Agent Assigned
                      </TableCell>
                      <TableCell>#Listings</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {ownerLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Owners...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : ownerList && ownerList.length ? (
                      ownerList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {user?.data?.Role !== "agent" && (
                              <FormControlLabel
                                className="table-checkbox"
                                control={
                                  <Checkbox
                                    checked={selectedItem.includes(item._id)}
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        item,
                                        event.target.checked
                                      )
                                    }
                                    color="primary"
                                  />
                                }
                              />
                            )}
                            <label
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push({
                                  pathname:   "/lead-detail/" + item?.LeadDtls?._id,
                                  state: { title: 'ownerPortfolios' }
                                }
                                
                                )
                              }
                            >
                              {item.FirstName &&
                                item.FirstName.charAt(0).toUpperCase() +
                                  item.FirstName.slice(1).toLowerCase()}{" "}
                              {item.LastName &&
                                item.LastName.charAt(0).toUpperCase() +
                                  item.LastName.slice(1).toLowerCase()}
                            </label>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="me-1"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  fontSize: "14px",
                                }}
                                src={item?.AgentDtls?.ProfileImage}
                              >
                                {item?.AgentDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.AgentDtls?.LastName.charAt(
                                    0
                                  ).toUpperCase()}
                              </Avatar>
                              <label>
                                {" "}
                                {item?.AgentDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.AgentDtls?.FirstName.slice(
                                    1
                                  ).toLowerCase() +
                                  " " +
                                  item?.AgentDtls?.LastName.charAt(
                                    0
                                  ).toUpperCase() +
                                  item?.AgentDtls?.LastName.slice(
                                    1
                                  ).toLowerCase()}
                              </label>
                            </div>
                          </TableCell>
                          <TableCell>
                            {item?.OwnerDtls?.[0]?.propertyCount
                              ? item?.OwnerDtls?.[0]?.propertyCount
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {item?.Status?.charAt(0).toUpperCase() +
                              item?.Status?.slice(1)}
                            {/* {item.InLeadCycle+""} */}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuChange("EDIT", item, e.currentTarget)
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : agent || searchKeyword ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Owners Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Owners Added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!ownerLoading && ownerListCount > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={ownerListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    //maxHeight: ITEM_HEIGHT * 4.5,
                    //width: '20ch',
                  },
                }}
              >
                {options.map((option, index) => {
                  let statusLabel =
                    option.label === "Deactivate"
                      ? changedStatus === "inactive"
                        ? "Deactivate"
                        : "Activate"
                      : option.label;
                  return (
                    <MenuItem
                      key={index}
                      disabled={
                        user?.data?.Role === "agent" &&
                        (option.label === "Delete" ||
                          option.label === "Deactivate")
                      }
                      onClick={() =>
                        handleMenuChange(
                          option.label === "Deactivate" ||
                            option.label === "Activate"
                            ? "TOGGLE_DEACTIVATE_ALERT"
                            : option.label
                        )
                      }
                    >
                      {statusLabel}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Dialog
                open={deleteAlert}
                onClose={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {changedStatus === "inactive" ? "Deactivate ?" : "Activate ?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {changedStatus === "inactive"
                      ? "Are you sure you want to deactivate?"
                      : "Are you sure you want to activate?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                    color="primary"
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => handleMenuChange("CONFIRM_DEACTIVATE")}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <Link
        id="send-email-button"
        to="#"
        onClick={(e) => {
          window.location = "mailto:" + email.toString();
        }}
        style={{ display: "none" }}
      ></Link>
    </div>
  );
};

export default Agent;
