import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));
let key = process.env.REACT_APP_GOOGLE_API_KEY;

export default function AutoComplete({ handlePlaceSelect, defaultValue, register,checkForChanges, ...rest }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue ? defaultValue : null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&locationbias=point:lat,lng`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  async function handleValueSelect(value) {
    setValue(value);
    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${value?.place_id}&key=${key}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        let arr = response?.data?.results?.[0]?.address_components
        let position = arr.findIndex((element) => element.types && element.types[0] === "postal_code");
        if(position!== -1)
          value.zip = response?.data?.results?.[0]?.address_components?.[position]?.["long_name"];
        value.location = response?.data?.results?.[0]?.geometry?.location;
        handlePlaceSelect(value);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>{
        let address= option?.description?.split(",")
        let addresslen=address?.slice(0,address.length-3)

        if(typeof option === "string") return option
         else return addresslen.join()
         //console.log("option--->",address.slice(0,address.length-3))
        //typeof option === "string" ? option : addresslen.join()
      }
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      {...register("StreetAddress")}
      {...rest}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        handleValueSelect(newValue);
        checkForChanges("StreetAddress",newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" fullWidth {...rest} />
      )}
      renderOption={(option) => {
        const matches =
        option?.structured_formatting?.main_text_matched_substrings;
      if (!matches) return false
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match) => [
          match.offset,
          match.offset + match.length,
        ])
      );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

// import React, { useState, useEffect, useRef } from "react";
// import { TextField } from "@material-ui/core";

// let autoComplete;

// const loadScript = (url, callback) => {
//   let script = document.createElement("script");
//   script.type = "text/javascript";

//   if (script.readyState) {
//     script.onreadystatechange = function () {
//       if (script.readyState === "loaded" || script.readyState === "complete") {
//         script.onreadystatechange = null;
//         callback();
//       }
//     };
//   } else {
//     script.onload = () => callback();
//   }

//   script.src = url;
//   document.getElementsByTagName("head")[0].appendChild(script);
// };

// function handleScriptLoad(updateQuery, autoCompleteRef) {
//   autoComplete = new window.google.maps.places.Autocomplete(
//     autoCompleteRef.current,
//     { types: ["(cities)"], componentRestrictions: { country: "us" } }
//   );
//   autoComplete.setFields(["address_components", "formatted_address"]);
//   autoComplete.addListener("place_changed", () =>
//     handlePlaceSelect(updateQuery)
//   );
// }

// async function handlePlaceSelect(updateQuery) {
//   const addressObject = autoComplete.getPlace();
//   const query = addressObject.formatted_address;
//   updateQuery(query);
//   console.log(addressObject);
// }

// function AutoComplete(props) {
//   const [query, setQuery] = useState("");
//   const autoCompleteRef = useRef(null);

//   useEffect(() => {
//     loadScript(
//       `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
//       () => handleScriptLoad(setQuery, autoCompleteRef)
//     );
//   }, []);

//   return (
//     <div className="search-location-input">
//       <input
//         // variant={props.variant}
//         className={props.className}
//         ref={autoCompleteRef}
//         onChange={(event) => setQuery(event.target.value)}
//         placeholder="Enter a City"
//         value={query}
//       />
//     </div>
//   );
// }

// export default AutoComplete;
