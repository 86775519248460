import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Popover, Button } from "@material-ui/core";
import PAndSHistory from "./propertyActions/PAndSHistory";
import AgentPrint from "./propertyActions/AgentPrint";
import CustomizedModal from "../Common/customizedModal";
import CustomerPrint from "./propertyActions/CustomerPrint";
import AddComment from "./propertyActions/AddComment";
import EmailListing from "./propertyActions/EmailListing";
import AdminPropertyListing from "./AdminPropertyListing";
import Sold from "./propertyActions/Sold";
import Offthemarket from "./propertyActions/Off the Market";
import Featured from "./propertyActions/Featured";
import Suggest from "./propertyActions/Suggest";
import Delete from "./propertyActions/Delete";
import {
  LEAD_ACTIVITY_ADD,
  APPROVE_DISAPPROVE_PPTY,
} from "../../Graghql/mutations";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../RactiveVariables/index";
import { toggleSnackbar } from "../../RactiveVariables/index";
import DialogBox from "../Common/DialogBox";

export default function PropertyListing(props) {
  const [itemDetails, setitemDetails] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [agentPrint, setAgentPrint] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [customerPrint, setCustomerPrint] = useState([]);
  const [openAgentPrint, setOpenAgentPrint] = useState(false);
  const [openCustomerPrint, setOpenCustomerPrint] = useState(false);
  const [openEmailListing, setOpenEmailListing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPAndS, setOpenPAndS] = useState(false);
  const [openSold, setOpenSold] = useState(false);
  const [openOffthemarket, setOpenoffthemarket] = useState(false);
  const [openFeatured, setOpenFeatured] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // const [pandsPropertyId, setPandsPropertyId] = useState(null);
  const [openAddComment, setOpenAddComment] = useState(false);
  // const[suggest,setSuggest]=useState([]);
  const [openSuggest, setOpenSuggest] = useState(false);
  const [openPopUpCheck, setOpenPopUpCheck] = useState(false);
  const history = useHistory();
  //const{propertyStatus}=props?.propertyList
  const open = Boolean(anchorEl);
  const options = [
    { label: "Edit Listing", id: "EDIT", disabled: true },
    { label: "Agent Print", id: "AgentPrint" },
    { label: "Customer Print", id: "CustomerPrint" },
    { label: "P & S History", id: "P&S", disabled: true },
    { label: "Add Comment", id: "ADDCOMMENT", disabled: true },
    { label: "Email Listing", id: "EmailListing" },
    { label: "View Listing", id: "ViewListing" },
    { label: "Sold", id: "Sold" },
    { label: " OTM/Inactive", id: "Off the Market", disabled: true },
    { label: "Feature", id: "Feature" },
    { label: "Suggest", id: "Suggest" },
    { label: "Delete Listing", id: "Delete" },
    { label: "View Agreement", id: "ViewAgreement" },
    // { label: "None", id: "None" }, //remove when adding a new menu item
  ];
  const [viwedProperty] = useMutation(LEAD_ACTIVITY_ADD);
  const [approveDisapprove] = useMutation(APPROVE_DISAPPROVE_PPTY, {
    // refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
    // onCompleted: (res) => {
    //   refetch();
    // },
  });
  const user = useReactiveVar(userDeatils);
  console.log("userrrrrrr", user);
  const handleApproveCheck = (itemDetails) => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemDetails?._id,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemDetails?._id}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemDetails?._id,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemDetails?._id}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleViewListing = () => {
    try {
      viwedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property View",
          LeadType: "View",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [itemDetails?._id],
        },
      })
        .then((res) => {
          // toggleSnackbar({
          //   status: true,
          //   message: "You have been successfully saved property",
          //   variant: "success",
          // });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  const handleMenuChange = (type, item, target) => {
    item && item._id && setitemDetails(item._id);
    item && setitemDetails(item);
    // item && setPropertyId(item._id);
    if (item) {
      setitemDetails(item);
      setAgentPrint([item]);
      setCustomerPrint([item]);
      // setSuggest([item]);
    }

    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "MENU":
        setAnchorEl(target);
        break;
      case "AgentPrint":
        setOpenAgentPrint(true);
        setAnchorEl(false);
        break;
      case "CustomerPrint":
        setOpenCustomerPrint(true);
        setAnchorEl(false);
        break;
      case "EmailListing":
        setOpenEmailListing(true);
        setAnchorEl(false);
        break;
      case "ViewListing":
        user?.data?.Role === "admin" || user?.data?.Role === "agentAdmin"
          ? window.open(itemDetails?.PropertySlug, "_blank")
          : history.push(itemDetails?.PropertySlug);
        //handleViewListing();

        // document.getElementById("view-Listing").click();
        //history.push("/property-detail/" + itemDetails?._id);
        setAnchorEl(false);
        break;
      case "EDIT":
        setAnchorEl(false);
        // eslint-disable-next-line eqeqeq
        (user?.data?.Role == "admin" || user?.data?.Role == "agentAdmin") &&
        itemDetails?.PendingFlag === true
          ? setOpenPopUpCheck(true)
          : history.push({
              pathname: `/addProperty/${itemDetails?._id}`,
              state: { pendingFlagCheck: itemDetails?.PendingFlag },
            });
        break;
      case "P&S":
        setOpenPAndS(true);
        setAnchorEl(false);
        break;
      case "ADDCOMMENT":
        setOpenAddComment(true);
        setAnchorEl(false);
        break;
      case "Sold":
        setOpenSold(true);
        setAnchorEl(false);
        break;
      case "Off the Market":
        setOpenoffthemarket(true);
        setAnchorEl(false);
        break;
      case "Feature":
        setOpenFeatured(true);
        setAnchorEl(false);
        break;
      case "Delete":
        setOpenDelete(true);
        setAnchorEl(false);
        break;
      case "Suggest":
        setOpenSuggest(true);
        setAnchorEl(false);
        break;
      case "ViewAgreement":
        history.push("/agreement/" + itemDetails?._id);
        //setOpenAgreement(true);
        setAnchorEl(false);
        break;
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log("itemDetails.Status",((itemDetails.AgreementPdfUrl===null||itemDetails.AgreementPdfUrl==="")))

  return (
    <div className="row">
      {/* <a href="#" id="view-Listing"onClick={()=>history.push("/property-detail/" + itemDetails?._id)} target="_blank"></a> */}
      <DialogBox
        negativeBtn={true}
        open={openPopUpCheck}
        setOpen={setOpenPopUpCheck}
        handlePositiveBtn={() => handleApproveCheck(itemDetails)}
        handleNegativeBtn={() => handleDisapproveCheck(itemDetails)}
        negativeText={"Decline"}
        positiveText={"Approve"}
        title={"Approve/Decline ?"}
        text={
          "Before editing,  you need to either Approve/Decline this Property with the changes made ?"
        }
      />
      {PAndSHistory && (
        <PAndSHistory
          open={openPAndS}
          setOpen={setOpenPAndS}
          // pandsId={pandsPropertyId}
          pandsId={itemDetails?._id}
          details={itemDetails}
        />
      )}
      {AgentPrint && (
        <CustomizedModal
          component={AgentPrint}
          label="Agent Print"
          open={openAgentPrint}
          data={[itemDetails]}
          setOpenAgentPrint={setOpenAgentPrint}
        />
      )}

      {CustomerPrint && (
        <CustomizedModal
          component={CustomerPrint}
          label="Customer Print"
          open={openCustomerPrint}
          data={[itemDetails]}
          setOpenAgentPrint={setOpenCustomerPrint}
        />
      )}

      {AddComment && (
        <CustomizedModal
          hideHeader={true}
          component={AddComment}
          open={openAddComment}
          setOpenAgentPrint={setOpenAddComment}
          propertyId={itemDetails._id}
          data={itemDetails}
          handlePagination={props?.handlePagination}
        />
      )}

      {EmailListing && (
        <CustomizedModal
          hideHeader={true}
          component={EmailListing}
          label="Email Listing"
          open={
            openEmailListing ||
            props.openModalEmailRenter ||
            props.openModalEmailOwner
          }
          setOpenAgentPrint={
            openEmailListing
              ? setOpenEmailListing
              : props.openModalEmailRenter
              ? props.setOpenModalEmailRenter
              : props.openModalEmailOwner
              ? props.setOpenModalEmailOwner
              : null
          }
          emailOwner={props.openModalEmailOwner ? true : null}
          propertyDetails={
            openEmailListing
              ? [itemDetails]
              : props.openModalEmailRenter || props.openModalEmailOwner
              ? props.selectedItem
              : null
          }
        />
      )}
      {Suggest && (
        <CustomizedModal
          hideHeader={true}
          component={Suggest}
          open={openSuggest || props.openModalSuggest}
          setOpenAgentPrint={
            openSuggest ? setOpenSuggest : props.setOpenModalSuggest
          }
          propertyId={itemDetails?._id}
          data={openSuggest ? [itemDetails] : props.selectedItem}
          // data= {suggest}
        />
      )}
      <Sold
        open={openSold}
        setOpen={setOpenSold}
        propertyId={itemDetails._id}
        pid={itemDetails.PID}
        handlePagination={props?.handlePagination}
      />
      <Offthemarket
        open={openOffthemarket}
        setOpen={setOpenoffthemarket}
        propertyId={itemDetails._id}
        pid={itemDetails.PID}
        propertyStatus={itemDetails.Status}
        handlePagination={props?.handlePagination}
      />
      <Featured
        open={openFeatured}
        setOpen={setOpenFeatured}
        propertyId={itemDetails._id}
        pid={itemDetails.PID}
        propertyStatus={itemDetails.IsFeatured}
        handlePagination={props?.handlePagination}
      />
      <Delete
        open={openDelete}
        setOpen={setOpenDelete}
        propertyId={itemDetails._id}
        pid={itemDetails.PID}
        handlePagination={props?.handlePagination}
      />
      {props?.propertyList && props?.propertyList?.length
        ? props?.propertyList?.map((item, index) => {
            return (
              <AdminPropertyListing
                property={item}
                handleSelectAll={props.handleSelectAll}
                handleMenuChange={handleMenuChange}
                handleChecked={props.handleChecked}
                selectedId={props.selectedId}
                selectedItem={props.selectedItem}
              />
            );
          })
        : "Your Search did not match any property"}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: "5px" }}
      >
        <div
          style={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.43)",
            borderRadius: "4px",
          }}
        >
          <div className="al-action-popup">
            {options.map((option, index) => {
              return (
                <>
                  {itemDetails.Status === "Delete" ||
                  itemDetails.Status === "Sold" ? (
                    <Button
                      disabled={
                        itemDetails.Status === "Delete" ||
                        itemDetails.Status === "Sold"
                      }
                      style={
                        option.label === "None" && option.id === "None"
                          ? { opacity: "0", cursor: "auto" }
                          : null
                      }
                      onClick={() => handleMenuChange(option.id)}
                      variant="outlined"
                      color="primary"
                      // style={{
                      //   border: "1px solid #A88585",
                      //   boxSizing: "border-box",
                      //   borderRadius: "4px",
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      //   margin: "10px",
                      //   padding: "10px",
                      //   color: "#A88585",
                      // }}
                    >
                      {option.label === " OTM/Inactive" &&
                      itemDetails.Status === "Off the Market"
                        ? (option.label = " On the Market")
                        : option.label === "Feature" &&
                          itemDetails.IsFeatured === true
                        ? (option.label = "UnFeature")
                        : option.label}
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        (!option.disabled &&
                          itemDetails.Status !== "approved") ||
                        ((itemDetails.AgreementPdfUrl === null ||
                          itemDetails.AgreementPdfUrl === "") &&
                          option.label === "View Agreement")
                      }
                      style={
                        option.label === "None" && option.id === "None"
                          ? { opacity: "0", cursor: "auto" }
                          : null
                      }
                      onClick={() => handleMenuChange(option.id)}
                      variant="outlined"
                      color="primary"
                    >
                      {option.label === " OTM/Inactive" &&
                      itemDetails.Status === "Off the Market"
                        ? (option.label = " On the Market")
                        : option.label === "Feature" &&
                          itemDetails.IsFeatured === true
                        ? (option.label = "UnFeature")
                        : option.label}
                    </Button>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
}
