import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import * as yup from "yup";
import { ADD_AGENT_INFO } from "../../../Graghql/mutations";
import {
  LIST_ALL_AGENT,
  PROPERTY_DETAILS,
  LIST_ACTIVE_AGENTS,PROPERTY_DETAILS_FOR_PENDING
} from "../../../Graghql/queries";
import { useReactiveVar } from "@apollo/client";
import {
  InputLabel,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables/index";

const AgentInfo = (props) => {
  const user = useReactiveVar(userDeatils);
  const locations = useLocation();
  //console.log("userrrrrr-----",user)
  const [scrollLimit, setScrollLimit] = useState(10);
  const [editedField, setEditedField] = useState(null);
  const [editedFieldNames, setEditedfieldNames] = useState([]);
  const [defaultValue, setDefaultValue] = React.useState(null);
  const [taxFormat, setTaxFormat] = React.useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const schema = yup.object().shape({
    PropertyAgentId: yup.string().required("Property Agent is required"),
    SuffCoTaxMap: yup.object().shape({
      Value1: yup.string().matches(/^[0-9]{4}$/, "Must be 4 characters"),
      // .test("len", "Must be exactly 4 characters", (val) => {
      //   if (val.length === 4 || !val) return true;
      // })
      // .test("number", "Must be number", (val) => {
      //   if (!isNaN(val) || !val) return true;
      // }),
      //   Value2: yup
      //     .string()
      //     .test("len", "Must be exactly 3 characters", (val) => {
      //       if (val.length === 3 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
      //   Value3: yup
      //     .string()
      //     .test("len", "Must be exactly 2 characters", (val) => {
      //       if (val.length === 2 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
      //   Value4: yup
      //     .string()
      //     .test("len", "Must be exactly 2 characters", (val) => {
      //       if (val.length === 2 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
      //   Value5: yup
      //     .string()
      //     .test("len", "Must be exactly 2 characters", (val) => {
      //       if (val.length === 2 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
      //   Value6: yup
      //     .string()
      //     .test("len", "Must be exactly 3 characters", (val) => {
      //       if (val.length === 3 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
      //   Value7: yup
      //     .string()
      //     .test("len", "Must be exactly 3 characters", (val) => {
      //       if (val.length === 3 || !val) return true;
      //     })
      //     .test("number", "Must be number", (val) => {
      //       if (!isNaN(val) || !val) return true;
      //     }),
    }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });
  //console.log("........",errors)
  const [addagentInfo, { loading }] = useMutation(ADD_AGENT_INFO);
  const { data: agentList } = useQuery(LIST_ALL_AGENT);
  const { data: activeAgentList } = useQuery(LIST_ACTIVE_AGENTS,{variables:{ skip:0,limit:100}})
  // const { data: activeAgentList,loading: ownerLoading, } = useQuery(LIST_ACTIVE_AGENTS,{variables:{ skip:0,
  //   limit:scrollLimit,
  //   search: searchKeyword,},
  //   onCompleted: (res) => {
  //     let arr = [];
  //     res.listActiveAgents.data.forEach((element) => {
  //       arr.push({
  //         label: element.FirstName + " " + element.LastName,
  //         value: element._id,
  //       });

  //     });
  //     // {renderdetails!=null&&
  //     //   arr.push(renderdetails);
  //     // }

  //     setOwnerList(arr);
  //   },
  // });


  //   const { data: propertyDetailsData} = useQuery(PROPERTY_DETAILS, {
  //      variables: {
  //       propertyId: props.propertyId,
  //     },

  //   });
  const [getPropertyDetails, propertyDetailsData] = useLazyQuery(
    PROPERTY_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: {
        propertyId: props.propertyId,
      },
      onCompleted: (res) => {
        if (res?.propertyDetails) {
          setDefaultValue(res?.propertyDetails);
          let arr = [];
          if (res?.propertyDetails?.SuffCoTaxMap)
            // eslint-disable-next-line array-callback-return
            Object.keys(res?.propertyDetails?.SuffCoTaxMap).map((key) => {
              if(key!=='__typename'){
              arr.push(res?.propertyDetails?.SuffCoTaxMap[key]);
            }
            });

          setTaxFormat(arr);
        } else setDefaultValue({});
      },
    }
  );
  const [getPropertyPendingDetails] = useLazyQuery(
    PROPERTY_DETAILS_FOR_PENDING,
    {
      fetchPolicy: "network-only",
      variables: {
        propertyId: props.propertyId,
      },
      onCompleted: (res) => {
        if (res?.propertyDtlsForPending) {
          setDefaultValue(res?.propertyDtlsForPending);
          let arr = [];
          if (res?.propertyDtlsForPending?.SuffCoTaxMap)
            // eslint-disable-next-line array-callback-return
            Object.keys(res?.propertyDtlsForPending?.SuffCoTaxMap).map((key) => {
              arr.push(res?.propertyDtlsForPending?.SuffCoTaxMap[key]);
            });

          setTaxFormat(arr);
        } else setDefaultValue({});
      },
    }
  );
  useEffect(() => {
    if (props.pathnameCheck === false&&(locations?.state?.pendingFlagCheck===false||locations?.state?.pendingFlagCheck===null||locations?.state?.pendingFlagCheck===undefined))
    getPropertyDetails();
    else
    getPropertyPendingDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log("defaultValueagent", defaultValue);
  let agentQueue =
    agentList && agentList.listAllAgents && agentList.listAllAgents.data;
  let activeAgentLists =
    activeAgentList &&
    activeAgentList.listActiveAgents &&
    activeAgentList.listActiveAgents.data;
  //console.log("errorsss", errors);

  const taxFormatCheck = (i, val) => {
    let length;
    if (i === 0) length = 4;
    if (val.length <= length) {
      let arr = [...taxFormat];
      arr[i] = val;
      setTaxFormat(arr);
    }

    if (i === 1 || i === 5 || i === 6) length = 3;
    if (val.length <= length) {
      let arr = [...taxFormat];
      arr[i] = val;
      setTaxFormat(arr);
    }

    if (i === 2 || i === 3 || i === 4) length = 2;
    if (val.length <= length) {
      let arr = [...taxFormat];
      arr[i] = val;
      setTaxFormat(arr);
    }
  };

  const checkForChanges = (field, value, type, fieldName) => {
    if (Object.keys(defaultValue).length !== 0) {
    let arr=[...editedFieldNames]
    let arr1=[]
    arr.push(...arr1,field)
    var unique = [];
    arr.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
     setEditedfieldNames(unique)
     //console.log("aaaaa",editedFieldNames,unique)
    // if (Object.keys(defaultValue).length !== 0) {
      let obj = { ...editedField };
      if (type === "Object") {
        let subObject = obj[field] ? obj[field] : {};
        if (
          defaultValue[field] &&
          (defaultValue[field][fieldName] ||
            defaultValue[field][fieldName] == null)
        ) {
          if (defaultValue[field][fieldName] !== value) {
            subObject[fieldName] = defaultValue[field][fieldName];
          } else if (subObject[fieldName] || subObject[fieldName] === null) {
            delete subObject[fieldName];
          }
          obj[field] = subObject;
        }
      } else {
        if (defaultValue[field]) {
          if (defaultValue[field] !== value) {
            obj[field] = defaultValue[field];
            if(field ==="PropertyAgentId"){
              let pos= activeAgentLists?.findIndex((element)=>element?._id===defaultValue[field])
              if(pos>-1){
                 obj['PropertyAgentName']= activeAgentLists[pos]?.FirstName + " " + activeAgentLists[pos]?.LastName
              }
            }
              
          } else if (obj[field]) {
            delete obj[field];
          }
        }else if(defaultValue[field] === null){ obj[field] = defaultValue[field];}
      }
      setEditedField(obj);
      //console.log("testing<>>>>>>>>>>>>>>",obj)
    }
  };
  const onSubmit = (params) => {
   // console.log("params--->>>>>>>",params)
    // console.log("paramsadgentinfo", {
    //   ...params,
    //   defaultValue,
    //   ...propertyDetailsData.propertyDetails,
    // });
    // let obj = {};
    // {
    //   Object.keys(params.SuffCoTaxMap).map((key, index) => {
    //     obj[key] = Number(params.SuffCoTaxMap[key]);
    //   });
    // }

    //params["SuffCoTaxMap"] = obj;
    let obj = {};

    // eslint-disable-next-line array-callback-return
    taxFormat.map((item, index) => {
      if (index < 7) obj[`Value${index + 1}`] = Number(item);
    });

    params.SuffCoTaxMap = obj;

    let amenityArray = [];
    if (defaultValue.ListMainAmenity) {
      defaultValue.ListMainAmenity.forEach((element) => {
        amenityArray.push({
          Title: element.Title,
          Flag: element.Flag ? element.Flag : false,
        });
      });

      // propertyDetailsData.propertyDetails.ListMainAmenity = amenityArray;
    }
    if (Object.keys(defaultValue).length === 0) params.ActionType = "Add";
    else {
      params.ActionType = "Edit";
      params.EdtdAgntInfoFields = editedField;
      params.AgentInfoEditedKeys=editedFieldNames;
    }
    if (params.EdtdAgntInfoFields === null) {
      if (props && props.handleNext) {
        props.handleNext(5, 5);
      }
    }
    if( params.EdtdAgntInfoFields!==null){
    try {
      params["PropertyId"] = props.propertyId;
      addagentInfo({
        variables: {
          ...params,
          ...propertyDetailsData.propertyDetails,
          ...{ ListMainAmenity: amenityArray },
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "success",
            variant: "success",
          });

          //reset();
          props&&props.handleNext(5, 5);
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }}
    // }else{
    //     params.ActionType="Edit"
    //     try {
    //         params["PropertyId"] = props.propertyId;
    //         addagentInfo({
    //           variables: {
    //             ...params,
    //             ...propertyDetailsData.propertyDetails,
    //             ...{ ListMainAmenity: amenityArray },
    //           },
    //         })
    //           .then((res) => {
    //             //console.log("resagentInfo", res);

    //             toggleSnackbar({
    //               status: true,
    //               message: "success",
    //               variant: "success",
    //             });

    //             //reset();
    //             props?.handleNext(5);
    //           })
    //           .catch((err) => {
    //             const message = err && err.message;
    //             toggleSnackbar({
    //               status: true,
    //               message: message,
    //               variant: "error",
    //             });
    //           });
    //       } catch (err) {
    //         const message = err && err.message;
    //         toggleSnackbar({
    //           status: true,
    //           message: message,
    //           variant: "error",
    //         });
    //       }
    // }
  };
  // eslint-disable-next-line no-unused-vars
  const handleDropdown =(event)=>{
    setScrollLimit(scrollLimit+10)
      setTimeout(() => {
        setScrollLimit(scrollLimit+10)
  }, 500);
   }
  return (
    <div className="add-property-form-wrap">
      {defaultValue && agentQueue && activeAgentLists && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="add-property-form-step">
            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">
                  Owner's agent <sup>*</sup>
                </InputLabel>
                <div className="formTextBoxOuter">
                  {user?.data?.Role === "agent" ? (
                        <Select
                        variant="outlined"
                        {...register("OwnerAgentId")}
                        //   error={errors.OwnerAgent}
                        className={"formTextBox"}
                        defaultValue={defaultValue?.OwnerDtls?.Agent?defaultValue?.OwnerDtls?.Agent:user?.data?._id}
                        disabled
                      >
                        {activeAgentLists &&
                          activeAgentLists.map((item) => (
                            <MenuItem value={item._id}>
                              {item.FirstName + " " + item.LastName}
                            </MenuItem>
                          ))}
                      </Select>    
                  ) : (
                  
                    <Select
                    variant="outlined"
                    {...register("OwnerAgentId")}
                    //   error={errors.OwnerAgent}
                    className={"formTextBox"}
                    defaultValue={defaultValue?.OwnerDtls?.Agent}
                    //disabled
                  >
                    {activeAgentLists &&
                      activeAgentLists.map((item) => (
                        <MenuItem value={item._id}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      ))}
                  </Select>
                   )}

                   
{/*    code for autocomplete
 <Controller
control={control}
name="OwnerAgentId"
defaultValue={defaultValue?.OwnerDtls?.Agent}
render={({ field: { onChange, value } }) => (
  <div className="formTextBoxOuter">
    <Autocomplete
      id="owner"
      options={ownerList??[]}
      inputValue={searchKeyword}
      getOptionLabel={(ownerList) => ownerList.label}
      onChange={(e, value) => {
        onChange(value?.value);
        //checkForChanges("OwnerId" , value?.value);
      }}
      //defaultValue={defaultValue?.Owner}
          onInputChange={(e,value)=>{ setSearchKeyword(value);}}
          ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.target;
                  if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight)+1 === listboxNode.scrollHeight) {
                                  setTimeout(() => {
                                        handleDropdown(event)
                                  }, listboxNode.scrollTop + 100)
                  }
          }}}
          renderOption={option => (
              <>
              {option && (option.label)}
              </>
          )}
          
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          className="formTextBox form"
          // {...register("OwnerId")}
          error={errors.OwnerAgentId}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
            <>
                   { ownerLoading? <CircularProgress color="inherit" size={10} /> : null}
                    {params.InputProps.endAdornment}
            </>
            ),
          }}
        />
      )}
    />
    {errors.OwnerAgentId && (
      <p className="errorMessage">
        {errors.OwnerAgentId.message}
      </p>
    )}
  </div>
)}
/> */}   
                 
                  {/* {errors && errors?.["OwnerAgent"] && (
                  <p className="errorMessage">{errors?.OwnerAgent?.message}</p>
                )} */}
                  {/* <Autocomplete
                  id="combo-box-demo"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="formTextBox form"
                    />
                  )}
                /> */}
                </div>
              </div>

              <div className="col-md-6">
                <InputLabel className="form-label">
                  Property agent<sup>*</sup>
                </InputLabel>
                <div className="formTextBoxOuter">
                  {user?.data?.Role === "agent" ? (
                    <Select
                      variant="outlined"
                      {...register("PropertyAgentId")}
                      error={errors.PropertyAgentId}
                      className={"formTextBox"}
                      // disabled
                      defaultValue={
                        defaultValue?.PropertyAgentId
                          ? defaultValue?.PropertyAgentId
                          : defaultValue?.OwnerDtls?.Agent
                          ? defaultValue?.OwnerDtls?.Agent
                          : user?.data?._id
                      }
                      onBlur={(e) =>
                        checkForChanges("PropertyAgentId", e.target.value)
                      }
                    >
                      {activeAgentLists &&
                        activeAgentLists.map((item) => (
                          <MenuItem value={item._id}>
                            {item.FirstName + " " + item.LastName}
                          </MenuItem>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      variant="outlined"
                      {...register("PropertyAgentId")}
                      error={errors.PropertyAgentId}
                      className={"formTextBox"}
                      defaultValue={
                        defaultValue?.PropertyAgentId
                          ? defaultValue?.PropertyAgentId
                          : defaultValue?.OwnerDtls?.Agent
                      }
                      onBlur={(e) =>
                        checkForChanges("PropertyAgentId", e.target.value)
                      }
                    >
                      {activeAgentLists &&
                        activeAgentLists.map((item) => (
                          <MenuItem value={item._id}>
                            {item.FirstName + " " + item.LastName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                  {errors && errors?.["PropertyAgentId"] && (
                    <p className="errorMessage">
                      {errors?.PropertyAgentId?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <InputLabel className="form-label">
                  Agent justification
                </InputLabel>
                <div className="formTextBoxOuter">
                  <Select
                    variant="outlined"
                    {...register("ListAgentJustification")}
                    error={errors.ListAgentJustification}
                    className={"formTextBox"}
                    defaultValue={defaultValue?.ListAgentJustification}
                    onBlur={(e) =>
                      checkForChanges("ListAgentJustification", e.target.value)
                    }
                  >
                    <MenuItem value="Agent Input Listing">
                      {" "}
                      Agent Input Listing
                    </MenuItem>
                    <MenuItem value="At owner request">
                      {" "}
                      At owner request
                    </MenuItem>
                    <MenuItem value="No update over 12 months">
                      {" "}
                      No update over 12 months
                    </MenuItem>
                    <MenuItem value="Original agent left company">
                      {" "}
                      Original agent left company
                    </MenuItem>
                  </Select>
                  {/* {errors && errors?.["ListAgentJustification"] && (
                  <p className="errorMessage">
                    {errors?.ListAgentJustification?.message}
                  </p>
                )} */}
                </div>
              </div>

              <div className="col-md-6">
                <InputLabel className="form-label">Keys</InputLabel>
                <div className="formTextBoxOuter">
                  <Select
                    variant="outlined"
                    {...register("ListKey")}
                    error={errors.ListKey}
                    className={"formTextBox"}
                    defaultValue={defaultValue?.ListKey}
                    onBlur={(e) => checkForChanges("ListKey", e.target.value)}
                  >
                    <MenuItem value="At Rosehip"> At Rosehip</MenuItem>
                    <MenuItem value="Call"> Call</MenuItem>
                    <MenuItem value="N/A"> N/A</MenuItem>
                    <MenuItem value="On Site"> On Site</MenuItem>
                  </Select>
                  {/* {errors && errors?.["ListKey"] && (
                  <p className="errorMessage">
                    {errors?.ListKey?.message}
                  </p>
                )} */}
                </div>
              </div>

              <div className="col-md-12">
                <InputLabel className="form-label">Keys Location</InputLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  placeholder=""
                  defaultValue={defaultValue?.KeyLocation}
                  className="formTextBox textarea"
                  {...register("KeyLocation")}
                  error={errors.KeyLocation}
                  onBlur={(e) => checkForChanges("KeyLocation", e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <InputLabel className="form-label">Alarm Code</InputLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  placeholder=""
                  defaultValue={defaultValue?.AlaramCode}
                  className="formTextBox textarea"
                  {...register("AlaramCode")}
                  error={errors.AlaramCode}
                  onBlur={(e) => checkForChanges("AlaramCode", e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <InputLabel className="form-label">
                  Showing Instructions
                </InputLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  placeholder="Best contact number, name, times, alarm control box, etc."
                  className="formTextBox textarea"
                  defaultValue={defaultValue?.ShowingIns}
                  {...register("ShowingIns")}
                  error={errors.ShowingIns}
                  onBlur={(e) => checkForChanges("ShowingIns", e.target.value)}
                />
              </div>

              <div className="col-md-12">
                <InputLabel className="form-label">Special Info</InputLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={2}
                  placeholder="Any particular information that your showing agent should be aware of."
                  className="formTextBox textarea"
                  defaultValue={defaultValue?.SpecialInfo}
                  {...register("SpecialInfo")}
                  error={errors.SpecialInfo}
                  onBlur={(e) => checkForChanges("SpecialInfo", e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <InputLabel className="form-label">
                  Suff. Co. Tax Map #
                </InputLabel>
                {/* {SuffCoTaxMap?.map((item, index) => {
                  console.log("itemmmmm------>", item);
                  return ( */}
                <div className="taxMapCol">
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value1}
                    //{...register("SuffCoTaxMap.Value1")}
                    value={taxFormat[0]}
                    onChange={(e) => {
                      taxFormatCheck(0, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value1"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value1 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value1?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">/</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value2}
                    //{...register("SuffCoTaxMap.Value2")}
                    value={taxFormat[1]}
                    onChange={(e) => {
                      taxFormatCheck(1, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value2"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value2 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value2?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">.</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value3}
                    //{...register("SuffCoTaxMap.Value3")}
                    value={taxFormat[2]}
                    onChange={(e) => {
                      taxFormatCheck(2, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value3"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value3 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value3?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">/</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value4}
                    // {...register("SuffCoTaxMap.Value4")}
                    value={taxFormat[3]}
                    onChange={(e) => {
                      taxFormatCheck(3, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value4"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value4 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value4?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">.</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value5}
                    // {...register("SuffCoTaxMap.Value5")}
                    value={taxFormat[4]}
                    onChange={(e) => {
                      taxFormatCheck(4, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value5"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value5 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value5?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">/</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value6}
                    // {...register("SuffCoTaxMap.Value6")}
                    value={taxFormat[5]}
                    onChange={(e) => {
                      taxFormatCheck(5, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value6"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value6 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value6?.message}
                      </p>
                    )} */}
                  <span className="taxMapColDivide">.</span>
                  <TextField
                    variant="outlined"
                    className="formTextBox"
                    defaultValue={defaultValue?.SuffCoTaxMap?.Value7}
                    //{...register("SuffCoTaxMap.Value7")}
                    value={taxFormat[6]}
                    onChange={(e) => {
                      taxFormatCheck(6, e.target.value);
                      checkForChanges(
                        "SuffCoTaxMap",
                        e.target.value,
                        "Object",
                        "Value7"
                      );
                    }}
                  />
                  {/* {errors && errors?.SuffCoTaxMap?.Value7 && (
                      <p className="errorMessage">
                        {errors?.SuffCoTaxMap?.Value7?.message}
                      </p>
                    )} */}
                </div>
                {/* );
                })} */}
              </div>

              {/* <div className="col-md-12">
              <InputLabel className="form-label">IN #</InputLabel>
              <TextField variant="outlined" className="formTextBox" />
            </div>

            <div className="col-md-12">
              <FormControlLabel
                control={<Checkbox defaultChecked={true} color="primary" />}
                label="HREO Update Required"
              />
            </div> */}
            </div>
          </div>
          <div
            className="add-property-form-step add-property-btn"
            style={{ borderTop: "1px solid #CFCFCF" }}
          >
            <Button
              className="outline-btn"
              variant="outline"
              onClick={props.handlePrevious}
            >
              Back
            </Button>
            <div>
              <Button
                disabled={loading}
                type="submit"
                color="primary"
                className="highlight-btn"
                variant="contained"
                id="property-save-btn"
              >
                {loading ? (
                  <CircularProgress size="20px" color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default AgentInfo;
