import React, { useState, useEffect, useRef } from "react";
import FrontendHeader from "../Common/frontendHeader";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import ListItemText from "@material-ui/core/ListItemText";
import {
  LIST_PROPERTY,
  LEAD_ACTIVITY_LIST,
  LIST_SAVED_PROPERTIES,
  PROPERTY_LIST_IN_MAP,
  PROPERTY_DETAILS,
} from "../../Graghql/queries";
import {
  LEAD_ACTIVITY_ADD,
  UNSAVE_PROPERTY,
  APPROVE_DISAPPROVE_PPTY,
} from "../../Graghql/mutations";
import {
  Button,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useForm, Controller } from "react-hook-form";
import {
  rentalPeriodOptions,
  bedRoomsOptions,
  bathroomOptions,
  mainAmenityOptions,
  tennisOptions,
  poolOptions,
  petsOptions,
  waterfrontOptions,
} from "./addProperty/options";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  authVar,
  userDeatils,
  toggleSnackbar,
} from "../../RactiveVariables/index";
import { LIST_AREAS } from "../../Graghql/queries";
import { useReactiveVar } from "@apollo/client";
import CustomLoader from "../Common/Loader";
import { ReactComponent as IconList } from "./icon-list.svg";
import { ReactComponent as IconMap } from "./icon-map.svg";
import { ReactComponent as IconListMap } from "./icon-list-map.svg";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slider";
import Locations from "./Locations";
import { DialogBox } from "../Common";
import noImage from '../../assets/images/noImage.jpg'
import moreOpen from '../../assets/images/moreOpen.svg'
import moreClose from '../../assets/images/moreClose.svg'
import iconReset from '../../assets/images/iconReset.svg'
import iconLocationOutlined from '../../assets/images/iconLocationOutlined.svg'
import iconSqft from '../../assets/images/iconSqft.svg'
import iconBed from '../../assets/images/iconBed.svg'
import iconBath from '../../assets/images/iconBath.svg'
import iconLocationDark from '../../assets/images/iconLocationDark.svg'
var map = null;
let clusterer = null;
var infoWindow;
const regOnlyArea = [
  "Wainscott North",
  "Wainscott South",
  "East Hampton Northwest",
  "East Hampton Village",
  "East Hampton Village Fringe",
  "East Hampton South",
  "Montauk",
  "Amagansett Dunes",
  "Amagansett Lanes",
  "Amagansett North",
  "Amagansett South",
];
const stylesArr = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
];

function getFeaturedImage(images) {
  let position;
  if (images)
    position = images.findIndex((element) => element.Featured === true);
  if (position !== -1) {
    if (images[position]?.FileUrl) return images[position]?.FileUrl;
    else return noImage;
  } else return noImage;
}

function setMarker(data, auth, setPropertyId, detailData) {
  if (clusterer) clusterer.clearMarkers();
  let markers = [];
  // eslint-disable-next-line array-callback-return
  data.map((item, index) => {
    if (
      !item.Latitude ||
      !item.Longitude ||
      item.Latitude === "" ||
      item.Longitude === ""
    )
      return false;
    let position = {
      lat: parseFloat(item.Latitude),
      lng: parseFloat(item.Longitude),
    };

    const marker = new window.google.maps.Marker({
      position,
    });

    //let privateLabel
    //let contentString;

    //need to style the div @Mathew
    // eslint-disable-next-line no-unused-vars
    const pidLabel =
      "<div class='map_info_wrapper pl-item pl-tag'><div>" +
      "<span>PID #</span>" +
      item?.PID +
      "</div></div>";

    const privateLabel =
      !auth?.auth &&
      regOnlyArea.includes(item?.Area) &&
      !item?.RentalRegisteryId
        ? "<a class='map-view-more'> Due to the recent rental registry law, you must be a registered user to access the details of this property.</a>"
        : item &&
          "<a href='/" +
            item?.PropertySlug +
            "' class='map-view-more'>VIEW MORE</a>";

    const contentString =
      "<div class='map_info_wrapper pl-item'><div class='img_wrapper pl-item-img'>" +
      // "<img src=" +
      // getFeaturedImage(item?.Images) +
      // ">"+
      "</div>" +
      "<div class='property_content_wrap'>" +
      "<div class='property_title pl-item-details'>" +
      "<h3>" +
      item?.AdTitle +
      // item?.AdTitle?.charAt(0).toUpperCase() +
      // item?.AdTitle?.slice(1).toLowerCase() +
      "</h3>" +
      "<div class='property_bed_type lp-detail'>" +
      `<label><img src=${iconLocationDark} class='img-fluid' alt='Icon'/>` +
      item?.Area +
      "</label>" +
      "<div class='lp-rooms d-flex align-items-center'>" +
      `<label><img src=${iconSqft} class='img-fluid' alt='Icon'/>` +
      item?.LivingArea +
      `</label><label><img src=${iconBed} class='img-fluid' alt='Icon'/>` +
      item?.ListBedRoom +
      `</label><label><img src=${iconBath} class='img-fluid' alt='Icon'/>` +
      item?.ListBathRoom +
      "</div>" +
      "</div>" +
      // "<a href='/" +
      // item.PropertySlug +
      privateLabel +
      "</div>" +
      "</div></div>";
    marker.addListener("click", () => {
      // setPropertyId(item?._id)
      if (infoWindow) infoWindow.close(map);
      infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
      });
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });
    // marker.addListener("closeclick", () => {

    //   new window.google.maps.InfoWindow({
    //     //content: contentString,
    //   })
    // });

    //   marker.addListener('mouseover', ()=> {
    //     infoWindow=  new window.google.maps.InfoWindow({
    //       content:pidLabel

    //     })
    //     infoWindow.open({
    //        anchor: marker,
    //        map,
    //       // shouldFocus: false,
    //     });
    // });

    //   marker.addListener('mouseout', ()=> {
    //    new window.google.maps.InfoWindow({
    //      // content:pidLabel
    //     })
    //     infoWindow.close({
    //       //anchor: marker,
    //       //map,
    //       // shouldFocus: false,
    //     });
    // });
    markers.push(marker);
  });
  clusterer = new MarkerClusterer({ markers, map });
  clusterer.addListener("click", () => {
    if (infoWindow) infoWindow.close(map);
  });
}

function PreviousArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-prev">
      <img
        onClick={onClick}
        className="slick-prev-img"
        src="../assets/images/back.svg"
        alt="prev"
      />
    </div>
  );
}
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-next">
      <img
        onClick={onClick}
        className="slick-next-img"
        src="../assets/images/forward.svg"
        alt="next"
      />
    </div>
  );
}

function PropertyShortTile(props) {
  const { item } = props;
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  const [openPopUpCheck, setOpenPopUpCheck] = useState(false);
  const [itemId, setItemId] = useState();
  const [approveDisapprove] = useMutation(APPROVE_DISAPPROVE_PPTY, {
    // refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
    // onCompleted: (res) => {
    //   refetch();
    // },
  });
  const [savedList, { data: listSavedProperty }] = useLazyQuery(
    LIST_SAVED_PROPERTIES,
    {
      fetchPolicy: "network-only",
      variables: { userId: props?.user?.data?._id },
    }
  );
  useEffect(() => {
    if (props?.user?.data?._id) {
      savedList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.user?.data?._id]);
  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;
  const regOnlyArea = [
    "Wainscott North",
    "Wainscott South",
    "East Hampton Northwest",
    // "East Hampton Village",
    "East Hampton Village Fringe",
    "East Hampton South",
    "Montauk",
    "Amagansett Dunes",
    "Amagansett Lanes",
    "Amagansett North",
    "Amagansett South",
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };

  function getAmenityNames(data) {
    if (data) {
      let array = [];
      data.forEach((element) => {
        if (element.Flag === true) array.push(element.Title);
      });
      return array;
    }
  }
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
    onCompleted: (res) => {
      props.refetch();
    },
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
    onCompleted: (res) => {
      props.refetch();
    },
  });

  const [viwedProperty] = useMutation(LEAD_ACTIVITY_ADD);
  // function getFeaturedImage(images) {
  //   let position
  //   if (images)
  //     position = images.findIndex((element) => element.Featured === true);
  //   if ( position !== -1) {
  //     if (images[position]?.FileUrl) return images[position]?.FileUrl;
  //     else return "../assets/images/no-image.jpg";
  //   } else return "../assets/images/no-image.jpg";
  // }
  // function checkSaved(id) {
  //   if (props.savedListId.includes(id)) return true;
  //   else return false;
  // }
  const history = useHistory();
  const handleSaveProperty = () => {
    try {
      savedProperty({
        variables: {
          UserId: props?.user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          Comment: "",
          PropertyIDs: [item?._id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    try {
      unSavedProperty({
        variables: {
          userId: props?.user?.data?._id,
          propertyId: item?._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex(
        (element) => element.PropertyId === item?._id
      );
      if (position !== -1) return true;
      else return false;
    }
  }
  const handleViewListing = () => {
    if (auth?.auth) {
      try {
        viwedProperty({
          variables: {
            UserId: props?.user?.data?._id,
            LeadSrc: "Property View",
            LeadType: "View",
            //RootId:"0",
            Comment: "",
            PropertyIDs: [item?._id],
          },
        })
          .then((res) => {
            // toggleSnackbar({
            //   status: true,
            //   message: "You have been successfully saved property",
            //   variant: "success",
            // });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };
  const handleApproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function getAltTag(item) {
    return `${item?.PID ?? ""}-${item?.ListBathRoom ?? ""}Bath-${
      item?.ListBedRoom ?? ""
    }Bed-${item?.Area ?? ""}`;
  }
  return (
    <div
      className={
        props.mode === "dual"
          ? "col-lg-6 pl-item-space"
          : "col-lg-3 col-md-4 pl-item-space"
      }
    >
      <DialogBox
        negativeBtn={true}
        open={openPopUpCheck}
        setOpen={setOpenPopUpCheck}
        handlePositiveBtn={() => handleApproveCheck()}
        handleNegativeBtn={() => handleDisapproveCheck()}
        negativeText={"Decline"}
        positiveText={"Approve"}
        title={"Approve/Decline ?"}
        text={
          "Before editing,  you need to either Approve/Decline this Property with the changes made ?"
        }
      />
      {/* <a
        href={
          !auth?.auth && regOnlyArea.includes(item?.Area)
            ? null
            : `/property-detail/${item._id};${propertyListVariables.area};${propertyListVariables.period};${propertyListVariables.price};${propertyListVariables.listBed};${propertyListVariables.listBath}`
        }
        target={"_blank"}
      > */}
      <div
        style={{
          cursor:
            !auth?.auth &&
            regOnlyArea.includes(item?.Area) &&
            !item?.RentalRegisteryId
              ? "default"
              : "",
        }}
        className="pl-item"
      >
        {!auth?.auth &&
        regOnlyArea.includes(item?.Area) &&
        !item?.RentalRegisteryId ? (
          <div className="not-registered-user">
            <p>
              Due to the recent rental registry law, you must be a registered
              user to access the details of this property.
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                document.getElementById("frontend-header-login-btn").click()
              }
            >
              Register
            </Button>
          </div>
        ) : (
          <div className="pl-item-img">
            {auth?.auth &&
              (user?.data?.Role === "owner" || user?.data?.Role === "renter") &&
              auth?.auth &&
              props?.savedListId && (
                <IconButton
                  className="pl-fav"
                  color="secondary"
                  onClick={() => {
                    isSaved() ? handleUnSaveProperty() : handleSaveProperty();
                  }}
                >
                  {isSaved() ? (
                    <FavoriteIcon color="primary" />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
              )}
            {(user?.data?.Role === "admin" ||
              user?.data?.Role === "agent" ||
              user?.data?.Role === "agentAdmin") &&
              auth?.auth && (
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    setItemId(item._id);
                    (user?.data?.Role === "admin" ||
                      user?.data?.Role === "agentAdmin") &&
                    item?.PendingFlag === true
                      ? setOpenPopUpCheck(true)
                      : history.push({
                          pathname: `/addProperty/${item._id}`,
                          state: { title: "ppptyFrontUserEdit" },
                        });
                  }}
                  // onClick={(e) => { history.push(`/addProperty/${item?._id}`) }}
                  className="pl-fav"
                >
                  <EditIcon color="grey" />
                </IconButton>
              )}
            {/* {item.Images && item.Images.length > 0 ? ( */}
            <Link
              to={{
                pathname:
                  !auth?.auth &&
                  regOnlyArea.includes(item?.Area) &&
                  !item?.RentalRegisteryId
                    ? null
                    : // : `/property-detail/${item._id};${propertyListVariables.area};${propertyListVariables.period};${propertyListVariables.price};${propertyListVariables.listBed};${propertyListVariables.listBath}`
                      `/${item.PropertySlug}`,
                state: {
                  linkData: "searchList",
                  searchObj: {
                    area: props?.area,
                    subArea: props?.subArea,
                    period: props?.rentalPeriod,
                    bath: props?.bathFilter,
                    bed: props?.bedFilter,
                    keywordSearch: props?.keywordPid,
                    amenities: props?.amenityName,
                    listWater: props?.waterfront,
                    listTennis: props?.tennis,
                    listPool: props?.pools,
                    listPet: props?.pets,
                    listStyle: props?.style,
                    price: props?.priceFilter,
                    count: props?.count,
                    skip: props?.skip,
                    limit: props?.limit,
                  },
                },
                // : user?.data?.Role==="admin"?  window.open(item.PropertySlug, "_blank"):`/${item.PropertySlug}` , state:{linkData: "searchList",subArea:props?.subArea}
              }}
              // target={"_blank"}
            >
              <Slider {...settings}>
                {/* {item.Images.map((imgItem) => {
                    if (!imgItem.FileUrl) return null;
                    return ( */}

                <img
                  src={getFeaturedImage(item.Images)}
                  className="img-fluid"
                  alt={getAltTag(item)}
                  onClick={() =>
                    user?.data?.Role !== "admin" ? handleViewListing() : ""
                  }
                />
                {/* //   );
                  // })} */}
              </Slider>
            </Link>
            {/* // ) : (
            //   <Link
            //     to={
            //       !auth?.auth && regOnlyArea.includes(item?.Area)
            //         ? null
            //         : `/${item.PropertySlug}`
            //     }
            //   // target={"_blank"}
            //   >
            //     <img
            //       src={"../assets/images/no-image.jpg"}
            //       className="img-fluid"
            //       alt="Propety Image"
            //     /></Link>
            // )} */}
            <label className="pd-pid">PID #{item.PID && item.PID}</label>
          </div>
        )}
        <div className="pl-item-details">
          <Link
            to={
              !auth?.auth &&
              regOnlyArea.includes(item?.Area) &&
              !item?.RentalRegisteryId
                ? null
                : `/${item.PropertySlug}`
              //  :user?.data?.Role==="admin"?  window.open(`/${item.PropertySlug}`, "_blank"):`/${item.PropertySlug}`
            }
            // target={"_blank"}
          >
            <h3
              style={{
                cursor:
                  !auth?.auth &&
                  regOnlyArea.includes(item?.Area) &&
                  !item?.RentalRegisteryId
                    ? "default"
                    : "",
              }}
              onClick={() =>
                user?.data?.Role !== "admin" ? handleViewListing() : ""
              }
            >
              {item?.AdTitle && item?.AdTitle}
              {/* {item?.AdTitle &&
                item?.AdTitle.charAt(0).toUpperCase() +
                item?.AdTitle.slice(1).toLowerCase()} */}
            </h3>
          </Link>
          <div class="lp-detail">
            <label>
              <img
                src={iconLocationOutlined}
                class="img-fluid"
                alt="Icon"
              />
              {item?.Area}
            </label>
            <div class="lp-rooms d-flex align-items-center">
              {item?.LivingArea && (
                <label>
                  <img
                    src={iconSqft}
                    class="img-fluid"
                    alt="Icon"
                  />
                  {item?.LivingArea}
                </label>
              )}
              {item?.ListBedRoom && (
                <label>
                  <img
                    src={iconBed}
                    class="img-fluid"
                    alt="Icon"
                  />
                  {item?.ListBedRoom}
                </label>
              )}
              {item?.ListBathRoom && (
                <label>
                  <img
                    src={iconBath}
                    class="img-fluid"
                    alt="Icon"
                  />
                  {item?.ListBathRoom}
                </label>
              )}
            </div>
          </div>
        </div>
        {item.ListMainAmenity &&
          item.ListMainAmenity.find((element) => element.Flag === true) && (
            <div className="pl-item-details pp-availability">
              <ul className="al-style propertyStyleTag">
                {item.ListMainAmenity &&
                  // eslint-disable-next-line array-callback-return
                  getAmenityNames(item.ListMainAmenity).map((name, index) => {
                    if (index === 2)
                      return (
                        <li>{`+${
                          getAmenityNames(item.ListMainAmenity)?.length - 2
                        }`}</li>
                      );
                    else if (index < 2) return <li>{name}</li>;
                  })}
              </ul>
            </div>
          )}
      </div>
      {/* </a> */}
    </div>
  );
}
function Map(props) {
  const key = process.env.REACT_APP_GOOGLE_API_KEY;
  const zoom = 9;
  useEffect(() => {
    const loader = new Loader({
      apiKey: key,
      libraries: ["places"],
    });

    loader.load().then(() => {
      map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: zoom,
        // mapTypeId: 'satellite',
        // mapTypeControl: false,
        // center: { lat: 37.034946, lng: -76.360123 },
        center: { lat: 40.963432, lng: -72.184799 },
      });
      props.setTrigger(!props.trigger);
      // eslint-disable-next-line no-unused-vars
      const styledMapType = new window.google.maps.StyledMapType(stylesArr, {
        name: "Styled Map",
      });
      window.google.maps.event.addListener(map, "click", function () {
        infoWindow.close(map);
      });
      // map.mapTypes.set("styled_map", styledMapType);
      // map.setMapTypeId("styled_map");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);
  return <div id="map" style={{ width: "100%", height: "100vh" }}></div>;
}

function valuetext(value) {
  return value;
}
const followersMarks = [
  {
    value: 1000,
    scaledValue: 1000,
    label: "1k",
  },
  {
    value: 5000,
    scaledValue: 5000,
    label: "5k",
  },
  {
    value: 10000,
    scaledValue: 10000,
    label: "10k",
  },
  {
    value: 25000,
    scaledValue: 25000,
    label: "25k",
  },
  {
    value: 50000,
    scaledValue: 50000,
    label: "50k",
  },
  {
    value: 100000,
    scaledValue: 100000,
    label: "100k+",
  },
  // {
  //   value: 250000,
  //   scaledValue: 250000,
  //   label: "250k"
  // },
  // {
  //   value: 500000,
  //   scaledValue: 500000,
  //   label: "500k"
  // },
  // {
  //   value: 1000000,
  //   scaledValue: 1000000,
  //   label: "1M+"
  // }
];
// eslint-disable-next-line no-unused-vars
const scale = (value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

function numFormatter(num) {
  if (num > 999 && num < 100000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 100000) {
    return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}
export default function PropertyFrontUser(props) {
  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 300,
      marginTop: "50px",
      color: "#6E6E6E",
    },
    typography: {
      padding: theme.spacing(2),
    },

    root: {
      display: "flex",
      flexWrap: "wrap",
      width: 430,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const browserUniqueId = localStorage.getItem("userUniqueId");
  const [areaFilter, setAreaFilter] = useState("");
  const [rentalPeriodFilter, setRentalPeriodFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState(["0", "100000"]);
  const [bedFilter, setBedFilter] = useState("");
  const [bathFilter, setBathFilter] = useState("");
  const [mode, setMode] = useState(
    window.innerWidth < "575.98" ? "list" : "dual"
  );
  //const [mode, setMode] = useState(window.outerWidth > '575.98px' ? "dual": "list");
  const [view, setView] = useState("all"); //variable to manage viewed saved and all
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  const [savedListId, setSavedListId] = useState(null);
  const rowsPerPage = 10;
  const rowsPerPageView = 10;
  const [page, setPage] = useState(0);
  const [isScrolledTop,setIsScrolledTop]= useState(false)
  const [pageView, setPageView] = useState(0);
  const [openClicked, setOpenClicked] = useState(true);
  const [amenityName, setAmenityName] = useState([]);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sqfeet, setSqFeet] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [multiLocation, setMultiLocation] = useState([]);
  const [isCommercial, setIsCommercial] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [waterfront, setWaterfront] = useState([]);
  const [tennis, setTennis] = useState("");
  const [pools, setPools] = useState("");
  const [style, setStyle] = useState([]);
  const [pets, setPets] = useState("");
  const [keywordPid, setKeywordPid] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [amount, setAmount] = useState(["0", "1000000"]);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyId, setPropertyId] = useState("");
  const [subArea, setSubArea] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [openLocations, setOpenLocations] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocation] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variable1, setVariable1] = useState([]);
  const [variable2, setVariable2] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchLoc,setSearchLoc]= useState('')
  useEffect(()=>{
    setSearchLoc(location?.searchObj?.area)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  // eslint-disable-next-line no-unused-vars
  const [propertyType, setPropertyType] = useState({
    residential: false,
    commercial: false,
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };
  const { data: group1 } = useQuery(LIST_AREAS, {
    variables: { group: 1 },
  });
  const { data: group2 } = useQuery(LIST_AREAS, {
    variables: { group: 2 },
  });
  const { data: group3 } = useQuery(LIST_AREAS, {
    variables: { group: 3 },
  });
  useEffect(() => {
    if (location?.searchObj) {
      let obj = location.searchObj;
      console.log("....obj", obj);
      // if (obj.area) { setMultiLocation(obj.area === "Any Area" || obj.area === "" ? "" : typeof (obj.area) === "string" ? [obj.area] : obj.area); setValue("location", typeof (location?.searchObj?.area) === "string" ? [location?.searchObj?.area] : location?.searchObj?.area) }
      if (obj.area) {
        console.log("....obj11", obj);

        // setSelected(obj.area === "Any Area" || obj.area === "" ? "" : typeof (obj.area) === "string" ? [obj.area] : obj.area);
        // setValue("location", typeof (location?.searchObj?.area) === "string" ? [location?.searchObj?.area] : location?.searchObj?.area);
        setVariable1(
          obj.area === "Any Area" || obj.area === ""
            ? ""
            : typeof obj.area === "string"
            ? [obj.area]
            : obj.area
        );
        setValue(
          "location",
          typeof location?.searchObj?.area === "string"
            ? [location?.searchObj?.area]
            : location?.searchObj?.area
        );
      }
      if (obj.period) {
        setRentalPeriod(
          obj.period === "Any Periods" || obj.period === ""
            ? ""
            : typeof obj.period === "string"
            ? [obj.period]
            : obj.period
        );
        setValue(
          "rentalPeriod",
          typeof location?.searchObj?.period === "string"
            ? [location?.searchObj?.period]
            : location?.searchObj?.period
        );
      }
      if (obj.price) setPriceFilter(obj.price);
      if (obj.price) setAmount(obj.price);
      if (obj.bed) setBedFilter(obj.bed);
      setValue("bed", obj.bed);
      if (obj.bath) setBathFilter(obj.bath);
      setValue("bath", obj.bath);
      if (obj.listPet) setPets(obj.listPet);
      if (obj.keywordSearch) setKeywordPid(obj.keywordSearch);
      setValue("keywordPid", obj.keywordSearch);
      if (obj.listPool) setPools(obj.listPool);
      if (obj.listTennis) setTennis(obj.listTennis);
      if (obj.amenities) setAmenityName(obj.amenities);
      if (obj.listStyle) setStyle(obj.listStyle);
      if (obj.ListWater) {
        setWaterfront(
          obj.ListWater === "Any Waterfront" || obj.ListWater === ""
            ? ""
            : obj.ListWater
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let obj = location.searchObj;
    let arr = [];
    let loc = [];
    const inputString = props.location.pathname;
    const end = inputString.indexOf("/", 1); // Start searching from index 1
    const extractedString = inputString.slice(0, end);
    console.log(extractedString, "extractedString");
    if (
      extractedString !== "/searchlistings" &&
      props.location.pathname !== "/"
    ){
      obj = {
        area: extractedString
          .replace("/", "")
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(""),
      };
      
    }
    else{
      obj = { area: location?.searchObj?.area??'' };
    }
      console.log(props.location.pathname, "props.location.pathname");
      
    
    console.log("selected52",obj,location.searchObj);
    if (obj && obj.area && group1 && group2 && group3) {
      group1 &&
        group1.listAreas.forEach((element) => {
          if (
            (typeof obj.area === "object" && obj.area.includes(element.City)) ||
            element.City.replace(" ", "") === obj.area
          ) {
            element &&
              element.Area.forEach((item) => {
                arr.push(item);
              });
            loc.push(element.City);
          }
        });
      group2 &&
        group2.listAreas.forEach((element) => {
          if (
            (typeof obj.area === "object" && obj.area.includes(element.City)) ||
            element.City.replace(" ", "") === obj.area
          ) {
            element &&
              element.Area.forEach((item) => {
                arr.push(item);
              });
            loc.push(element.City);
          }
        });
      group3 &&
        group3.listAreas.forEach((element) => {
          if (
            (typeof obj.area === "object" && obj.area.includes(element.City)) ||
            element.City.replace(" ", "") === obj.area
          ) {
            element &&
              element.Area.forEach((item) => {
                arr.push(item);
              });
            loc.push(element.City);
          }
        });
      setVariable1(loc);
      setVariable2(arr);
      setSelected(loc);
      setSubArea(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group1, group2, group3]);

  console.log("selected",selected);
  const { register, handleSubmit, control, setValue, reset } = useForm();
  const { data: propertyDetailsData } = useQuery(PROPERTY_DETAILS, {
    variables: {
      propertyId: propertyId,
    },
  });
  const propertyListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    area: areaFilter,
    period: rentalPeriodFilter[0],
    price: priceFilter,
    listBed: parseFloat(bedFilter),
    listBath: parseFloat(bathFilter),
    rentalPeriods: rentalPeriod,
    locations: variable1,
    amenities: amenityName,
    isCommercial: isCommercial,
    isResidential: isResidential,
    listWater: waterfront,
    listTennis: tennis,
    listPool: pools,
    listPet: pets,
    listStyle: style,
    keywordSearch: keywordPid,
    browserUniqueId: browserUniqueId,
    subArea: variable2,
  };
  const { data: propertyListData, loading } = useQuery(LIST_PROPERTY, {
    variables: propertyListVariables,
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      let array;
      // if(propertyList?.propertyList?.data && propertyList?.propertyList?.data.length > 0){
      if (page > 0) {
        if(page!==Math.floor(res?.propertyList?.count/10)-1||page!==Math.floor(res?.propertyList?.count/10)){
        array = [
          ...propertyList?.propertyList?.data,
          ...res?.propertyList?.data,
        ];
        setPropertyList({
          propertyList: { data: array.slice(10), count: res?.propertyList?.count },
        });
      }
        if(page===Math.floor(res?.propertyList?.count/10)-1||page===Math.floor(res?.propertyList?.count/10)){
          if(page===Math.floor(res?.propertyList?.count/10)){
            array = [
              ...propertyList?.propertyList?.data,
              ...res?.propertyList?.data,
            ];
          }else{
            array = [
              ...propertyList?.propertyList?.data.slice(10),
              ...res?.propertyList?.data,
            ];
          }
        
        // if(res?.propertyList?.count/10)
        setPropertyList({
          propertyList: { data: array, count: res?.propertyList?.count },
        });
      }
        // (location?.searchObj?.area!==''||location?.searchObj?.period!=='')?history.push(`/${location?.searchObj?.area}/${page}`): history.push(`/searchlistings/${page+1}`)
        if (location?.searchObj?.area||searchLoc) {
          const inputString = location?.pathname;
          const start = inputString.indexOf("/") + 1;
          const end = inputString.indexOf("/", start);
          const extractedString = inputString.slice(start, end);
          history.push(`/${extractedString}/page${page + 1}`);
        } else {
          history.push(`/searchlistings/page${page + 1}`);
        }
      } else {
        setPropertyList(res);
      }
    },
  });
  const [leadActivity, { data: viewedList }] = useLazyQuery(
    LEAD_ACTIVITY_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: pageView * rowsPerPageView,
        limit: rowsPerPageView,
        tabType: "Viewed",
        leadId: user?.data?.LeadList,
      },
      // onCompleted: res => {
      //   let array;
      //   if (pageView > 0) {
      //     array = [...leadActivityList?.leadActivityList?.data, ...res?.leadActivityList?.data];
      //     setLeadActivityList({ leadActivityList: { data: array, count: res?.leadActivityList?.count } });
      //   }
      //   else
      //   setLeadActivityList(res);
      // }
    }
  );

  const { data: propertyMapList, loading: mapLoading } = useQuery(
    PROPERTY_LIST_IN_MAP,
    { variables: propertyListVariables }
  );
  const [leadActivitySave, { data: savedList, refetch }] = useLazyQuery(
    LEAD_ACTIVITY_LIST,
    {
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        let array = [];
        if (res && res.leadActivityList && res.leadActivityList.data) {
          res.leadActivityList.data.forEach((element) => {
            array.push(element.PropertyId);
          });
          setSavedListId(array);
        }
      },
      variables: {
        skip: 0,
        limit: 0,
        tabType: "Saved",
        leadId: user && user.data && user.data.LeadList,
      },
    }
  );
  const listInnerRef = useRef();
  useEffect(() => {
    if (map && propertyMapList?.propertyListInMap?.data) {
      setMarker(
        propertyMapList?.propertyListInMap?.data,
        auth,
        setPropertyId,
        propertyDetailsData?.propertyDetails &&
          propertyDetailsData?.propertyDetails
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, propertyMapList]);
  useEffect(() => {
    if (user?.data?.LeadList) {
      leadActivity();
      leadActivitySave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.LeadList]);

  useEffect(() => {
    // Set the scrollTop value to 2 when the component mounts
    if(listInnerRef.current&&page!==0&&!isScrolledTop){
      listInnerRef.current.scrollTop = 10
    }
    if (listInnerRef.current&&page!==0&&isScrolledTop) {
      listInnerRef.current.scrollTop = 1300;
    }else{
      listInnerRef.current.scrollTop = 10
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyListData]);
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (savedList?.leadActivityList?.count === 0) setView("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedList?.leadActivityList?.count]);

  useEffect(() => {
    if (location?.searchObj?.amenities) {
      setValue("amenity", location?.searchObj?.amenities);
    }
    if (location?.searchObj?.listWater) {
      setValue("waterfront", location?.searchObj?.listWater);
      setWaterfront(location?.searchObj?.listWater);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.searchObj]);
  function handleSearch(params) {
    setVariable2(isCheck);
    setVariable1(selected);
    setPage(0);
    setPageView(0);
    if (openClicked === false) setOpenClicked(!openClicked);
    if (params.area) {
      if (params.area === "Any Area") setAreaFilter("");
      else setAreaFilter(params.area);
    }
    if (params.period) {
      if (params.period === "Any Periods") setRentalPeriodFilter("");
      else setRentalPeriodFilter(params.period);
    }
    setPriceFilter(amount);
    if (params.bed) {
      if (params.bed === "Any Beds") setBedFilter("");
      else setBedFilter(params.bed);
    }
    if (params.bath) {
      if (params.bath === "Any Baths") setBathFilter("");
      else setBathFilter(params.bath);
    }
    if (params.sqFeet) {
      if (params.sqFeet === "Any Feet") setSqFeet("");
      else setSqFeet(params.sqFeet);
    }
    if (params.tennis) {
      if (params.tennis === "Any Tennis") setTennis("");
      else setTennis(params.tennis);
    }
    if (params.pools) {
      if (params.pools === "Any Pools") setPools("");
      else setPools(params.pools);
    }
    if (params.pets) {
      if (params.pets === "Any Pets") setPets("");
      else setPets(params.pets);
    }
    if (params.keywordPid) {
      setKeywordPid(params.keywordPid);
    } else {
      setKeywordPid("");
    }

    if (params.location) {
      setMultiLocation(params.location);
    }
    if (params.subArea) {
      setSubArea(params.subArea);
    }
    if (params.rentalPeriod) {
      setRentalPeriod(params.rentalPeriod);
    }
    if (params.amenity) {
      setAmenityName(params.amenity);
    }
    if (params.waterfront) {
      setWaterfront(params.waterfront);
    }
    if (params.style) {
      setStyle(params.style);
    }
    // setIsPrivate(params.isPrivate)
    setIsCommercial(propertyType.commercial);
    setIsResidential(propertyType.residential);
  }
  function formatLeadData(data) {
    let object = { ...data?.PropertyDtls };
    object["_id"] = data?.PropertyId;
    object["Images"] = data?.ImagesLead;
    return object;
  }
  function ListingIcons(params) {
    return (
      <div
        className={
          mode === "map" ? "map-property-view-type" : "property-view-type"
        }

        //className="property-view-type"
      >
        <IconButton
          onClick={() => {
            if (mode !== "list") setMode("list");
          }}
        >
          {/* <img src="../assets/images/icon-list.svg" className="img-fluid"></img> */}
          <IconList
            fill={mode === "list" ? "#B13F3F" : "#AEAEAE"}
            data-toggle="tooltip"
            title="Gallery"
          />
        </IconButton>
        <IconButton
          onClick={() => {
            if (mode !== "dual") setMode("dual");
          }}
          className="d-none d-md-block"
        >
          {/* <img
            src="../assets/images/icon-list-map.svg"
            className="img-fluid"
          ></img> */}
          <IconListMap
            fill={mode === "dual" ? "#B13F3F" : "#AEAEAE"}
            data-toggle="tooltip"
            title="Hybrid"
          />
        </IconButton>
        <IconButton
          onClick={() => {
            if (mode !== "map") setMode("map");
          }}
        >
          {/* <img src="../assets/images/icon-map.svg" className="img-fluid"></img> */}
          <IconMap
            fill={mode === "map" ? "#B13F3F" : "#AEAEAE"}
            data-toggle="tooltip"
            title="Map"
          />
        </IconButton>
      </div>
    );
  }
  function ListingIconsMaps(params) {
    return (
      <div className={"property-view-type"}>
        <IconButton
          onClick={() => {
            if (mode !== "list") setMode("list");
          }}
        >
          {/* <img src="../assets/images/icon-list.svg" className="img-fluid"></img> */}
          <IconList fill={mode === "list" ? "#B13F3F" : "#AEAEAE"} />
        </IconButton>
        <IconButton
          onClick={() => {
            if (mode !== "dual") setMode("dual");
          }}
          className="d-none d-md-block"
        >
          {/* <img
            src="../assets/images/icon-list-map.svg"
            className="img-fluid"
          ></img> */}
          <IconListMap fill={mode === "dual" ? "#B13F3F" : "#AEAEAE"} />
        </IconButton>
        <IconButton
          onClick={() => {
            if (mode !== "map") setMode("map");
          }}
        >
          {/* <img src="../assets/images/icon-map.svg" className="img-fluid"></img> */}
          <IconMap fill={mode === "map" ? "#B13F3F" : "#AEAEAE"} />
        </IconButton>
      </div>
    );
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    let strArray = newValue;
    let strArr = strArray.map(function (e) {
      return e.toString();
    });
    setAmount(strArr);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight > scrollHeight - 50) {
        if (
          !loading &&
          (page + 1) * rowsPerPage < propertyListData?.propertyList?.count
        )
          setPage((prevPage) => prevPage + 1);
          setIsScrolledTop(false)

        // if (view === "viewed"&&(!viewLoading && (pageView + 1) * rowsPerPageView < viewedList?.leadActivityList?.count))
        // setPageView(pageView + 1);
      } else if (scrollTop === 0 && !loading) {
        if (page > 0) {
          setIsScrolledTop(true)
          setPage((prevPage) => prevPage - 1);
          if (location?.searchObj?.area||searchLoc) {
            const inputString = location?.pathname;
            const start = inputString.indexOf("/") + 1;
            const end = inputString.indexOf("/", start);
            const extractedString = inputString.slice(start, end);
            history.push(`/${extractedString}/page${page}`);
          } else {
            history.push(`/searchlistings/page${page}`);
          }
          // (location?.searchObj?.area!==''||location?.searchObj?.period!=='')?history.push(`/${location?.searchObj?.area}/${page}`): history.push(`/searchlistings/${page}`)
        }
      }
    }
  };

  const handleClearAll = () => {
    setAreaFilter("");
    setRentalPeriodFilter("");
    setPriceFilter("");
    setBedFilter("");
    setBathFilter("");
    setRentalPeriod([]);
    setVariable1([]);
    setVariable2([]);
    setAmenityName([]);
    setIsCommercial(false);
    setIsResidential(false);
    setWaterfront([]);
    setTennis("");
    setPools("");
    setPets("");
    setStyle([]);
    setKeywordPid("");
    setSelected([]);
    setSubArea([]);
    setIsCheck([]);
    setLoaded(true);
    setAmount(["0", "1000000"]);
    history.replace();

    //  setBedFilter("")
    //  setBathFilter("")
    //  setRentalPeriodFilter("")
    //  setPriceFilter("")
    //  setKeyWordSearch("")
    //  setVariable1([])
    //  setVariable2([])
    //  setSelected([])
    //  setSubArea([])
    //  setIsCheck([])
    //  setRentalPeriod([])
    //  setIsPrivate(false)
    //  setAmenityName([])
    //  setIsCommercial(false)
    //  setIsResidential(false)
    //  setWaterfront([])
    //  setTennis("")
    //  setPools("")
    //  setPets("")
    //  setStatus("")
    //  setAgentId("")
    //  setOwnerId("")
    //  setDefaultValue(null)
    //  setDefaultValue2(null)
    //  setAmount(["0", "1000000"])
    //  setPropertyType({
    //    residential: false,
    //    commercial: false,
    //    isPrivateProperty: false,
    //  })
  };
  const NumberChecking = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === "" || re.test(e)) {
      return e;
    } else {
      let newVal = e.replace(/\D/g, "");
      return newVal;
    }
  };
  function formatePrice(value) {
    if (value)
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // return value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <>
      <FrontendHeader
        message
        search
        list
        contact
        location={props.location.pathname}
      />
      {openLocations && (
        <Locations
          openLocations={openLocations}
          setOpenLocations={setOpenLocations}
          setLocation={setLocation}
          setSubArea={setSubArea}
          setSelected={setSelected}
          selected={selected}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
          defaultValue={location?.searchObj}
          pathname={props.location.pathname}
          setLoaded={setLoaded}
          loaded={loaded}
          // data= {data?.leadsDetails?.UserDtls?.Email}
          // recipientId={data?.leadsDetails?.UserId}
        />
      )}
      <CustomLoader
        open={propertyList.length === 0 ? true : false || loading || mapLoading}
      />
      <div className="search-band search-band-properties ps-0">
        <form onSubmit={handleSubmit(handleSearch)}>
          {/* <FormControl className="search-town ps-2" onClick={()=>setOpenLocations(true)}>
              <TextField
                disabled={true}
                id="standard-full-width"
                className="fullWidthSearch"
                label="LOCATION"
                disableUnderline={false}
                placeholder="City/Town"
                {...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl> */}
          <FormControl
            className={classes.formControl}
            onClick={() => setOpenLocations(true)}
          >
            <span className="location-label">Location</span>
            <InputLabel id="demo-mutiple-checkbox-label">
              {selected.length > 0 || subArea.length > 0
                ? selected.length + subArea.length + " Selected"
                : "City/Town"}
              {/* {(selected.length>0||subArea.length>0)&&location?.searchObj? selected.length+subArea.length+ " Selected":selected.length>0||isCheck.length>0?selected.length+isCheck.length+ " Selected":"City/Town"} */}
            </InputLabel>
            {/* <Controller
              control={control}
              name="City/Town"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    // variant="outlined"
                    value={value && value?.length > 0 ? value : [""]}
                    onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                    // input={<Input />}
                    renderValue={(selected) => selected[0] === "" ? "Locations" : ` Locations (${selected.length} selected)`}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} disabled value={""}>City/Town</MenuItem>
                    {areaOptions?.sort(sortLabels).map((item) => (
                      <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                        <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            /> */}
            {/* <TextField
              disabled={true}
              id="standard-full-width"
              className="fullWidthSearch"
              label="LOCATION"
              disableUnderline={false}
              placeholder="City/Town"
              {...register("search")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                disableUnderline: false,
              }}
            /> */}
          </FormControl>

          {/* <FormControl className="search-town">
            <InputLabel>TOWN</InputLabel>
            <Select
              defaultValue={
                location?.searchObj?.area
                  ? location?.searchObj.area
                  : "Any Area"
              }
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              {...register("area")}
              InputProps={{ disableUnderline: true }}
            >
              <MenuItem value="Any Area">{"Any Area"}</MenuItem>
              {areaOptions?.sort(sortLabels).map((item) => (
                <MenuItem value={item.label}><img src="../assets/images/serach-band-location.svg" className="img-fluid me-2 sb-icon" alt="Icon"/>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
            <Controller
              control={control}
              name="rentalPeriod"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    // variant="outlined"
                    value={value && value?.length > 0 ? value : [""]}
                    onChange={(e) =>
                      onChange(
                        e.target.value[0] === ""
                          ? [e.target.value[1]]
                          : e.target.value
                      )
                    }
                    // input={<Input />}
                    renderValue={(selected) =>
                      selected[0] === ""
                        ? "Periods"
                        : ` Periods (${selected.length} selected)`
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} disabled value={""}>
                      Periods
                    </MenuItem>
                    {rentalPeriodOptions.map((item) => (
                      <MenuItem
                        className="advance-filter-listItem"
                        key={item.RentalPeriod}
                        value={item.RentalPeriod}
                      >
                        <Checkbox
                          color="primary"
                          checked={value?.indexOf(item.RentalPeriod) > -1}
                        />
                        <ListItemText primary={item.RentalPeriod} />
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
          {/* <FormControl>
            <InputLabel>PERIOD</InputLabel>
            <Select
              defaultValue={
                location?.searchObj?.period
                  ? location?.searchObj?.period
                  : "Any Periods"
              }
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              {...register("period")}
            >
              <MenuItem value="Any Periods">{"Any Periods"}</MenuItem>
              {rentalPeriodOptions.map((item) => (
                <MenuItem value={item.RentalPeriod}>
                  <img src="../assets/images/serach-band-calendar.svg" style={{height:'14px'}} className="img-fluid me-2 sb-icon" alt="Icon"></img>{item.RentalPeriod}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <FormControl>
            <div
              className="price-filter-box-outer"
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              <b style={{ cursor: "pointer" }} className="price-filter-label">
                PRICE
              </b>
              <div style={{ cursor: "pointer" }} className="price-filter-box">
                {amount[0] === "0" && amount[1] === "1000000"
                  ? "Any Price "
                  : amount[0] === ""
                  ? `$ - $ ${amount[1]}` && formatePrice(amount[1])
                  : amount[1] === ""
                  ? "$" + amount[0] && `${formatePrice(amount[0])} - $`
                  : amount[0] === "" && amount[1] === ""
                  ? `$ - $`
                  : "$" +
                    formatePrice(amount[0]) +
                    " - " +
                    "$" +
                    formatePrice(amount[1])}
                <ArrowDropDownIcon />
              </div>
            </div>
            <Popover
              className="price-filter d-none d-lg-flex"
              style={{ marginTop: "65px" }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorPosition={{ top: 2000, left: 400 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography className={classes.typography}>
                <div className={classes.root}>
                  <div className="mb-4 d-flex align-items-center justify-content-between w-100">
                    Price range
                    <div>
                      <Button
                        variant="outlined"
                        onClick={() => setAmount(["0", "1000000"])}
                      >
                        Clear
                      </Button>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClose()}
                      />
                    </div>
                  </div>
                  <Slide
                    value={amount}
                    step={1}
                    valueLabelFormat={numFormatter}
                    marks={followersMarks}
                    // scale={scale}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    style={{ margin: "0 5px" }}
                    max={100000}
                    min={0}
                  />
                </div>
              </Typography>
              <div style={{ display: "flex" }}>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  {/* <InputLabel >Min</InputLabel> */}
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={formatePrice(amount[0])}
                    placeholder="Min"
                    onChange={(e) => {
                      setAmount([NumberChecking(e.target.value), amount[1]]);
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    labelWidth={30}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  {/* <InputLabel >Max</InputLabel> */}
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    placeholder="Max"
                    value={formatePrice(amount[1])}
                    onChange={(e) => {
                      setAmount([amount[0], NumberChecking(e.target.value)]);
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    labelWidth={30}
                  />
                </FormControl>
              </div>
            </Popover>
          </FormControl>
          <FormControl>
            <InputLabel>BED</InputLabel>
            <Controller
              control={control}
              name="bed"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={
                      value
                        ? value === "Any Beds"
                          ? value
                          : value + "+"
                        : "Any Beds"
                    }
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    renderValue={(selected) =>
                      selected === "" ? "Any Beds" : selected
                    }
                    MenuProps={MenuProps}
                    // defaultValue={
                    //   location?.searchObj?.bed ? location?.searchObj?.bed : "Any Beds"
                    // }
                    // MenuProps={{ classes: { paper: classes.menuPaper } }}
                    // {...register("bed")}
                  >
                    <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                    {bedRoomsOptions.map((item) => (
                      <MenuItem value={item?.label}>
                        <img
                          src={iconBed}
                          style={{ height: "12px" }}
                          className="img-fluid me-2 sb-icon"
                          alt="Icon"
                        ></img>
                        {item?.value}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>

          <FormControl>
            <InputLabel>BATH</InputLabel>
            <Controller
              control={control}
              name="bath"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={
                      value
                        ? value === "Any Baths"
                          ? value
                          : value + "+"
                        : "Any Baths"
                    }
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    renderValue={(selected) =>
                      selected === "" ? "Any Baths" : selected
                    }
                    MenuProps={MenuProps}
                    // defaultValue={
                    //   location?.searchObj?.bath
                    //     ? location?.searchObj?.bath
                    //     : "Any Baths"
                    // }
                    // MenuProps={{ classes: { paper: classes.menuPaper } }}
                    // {...register("bath")}
                  >
                    <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                    {bathroomOptions.map((item) => (
                      <MenuItem value={item.label}>
                        <img
                          src={iconBath}
                          style={{ height: "14px" }}
                          className="img-fluid me-2 sb-icon"
                          alt="Icon"
                        ></img>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
          <FormControl className="search-btn-outer" style={{ flex: 1 }}>
            <Button
              className="search-btn"
              variant="contained"
              type="submit"
              onClick={() => {
                setOpenClicked(!openClicked);
              }}
            >
              Search
            </Button>
            <label
              onClick={() => {
                setOpenClicked(!openClicked);
              }}
              className="advanced-filter-btn"
            >
              {openClicked === true ? <>More</> : <>Less</>}
              {openClicked === true ? (
                <img
                  src={moreOpen}
                  className="img-fluid me-1"
                  alt="Icon"
                />
              ) : (
                <img
                  src={moreClose}
                  className="img-fluid me-1"
                  alt="Icon"
                />
              )}
            </label>
            <Button
              type="submit"
              onClick={() => {
                reset();
                handleClearAll();
              }}
              className="clear-filter p-2"
              style={{ minWidth: "auto" }}
              data-toggle="tooltip"
              title="Clear Filter"
            >
              <img
                src={iconReset}
                className="img-fluid"
                alt="Clear Filter"
              />
            </Button>
          </FormControl>
        </form>
      </div>
      {!openClicked && (
        <div>
          <div className="advance-filter">
            <form onSubmit={handleSubmit(handleSearch)}>
              <div className="row">
                <h6>Advanced Filter</h6>
                {/* <div className="col-md-12 ">
                    <h6>Property Type</h6>
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={propertyType.residential}
                          onChange={(e)=>setPropertyType({...propertyType,[e.target.name]:e.target.checked})}
                          name="residential"
                          color="primary"
                          // {...register("residential")}
                        />
                      }
                      label="Residential"
                    />
                    <FormControlLabel
                    className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={propertyType.commercial}
                          onChange={(e)=>setPropertyType({...propertyType,[e.target.name]:e.target.checked})}
                          name="commercial"
                          color="primary"
                          // {...register("commercial")}
                        />
                      }
                      label="Commercial"
                    />
                </div> */}
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                      control={control}
                      name="keywordPid"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextField
                            variant="outlined"
                            value={value ? value : ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            renderValue={(selected) =>
                              selected === "" ? "" : selected
                            }
                            // defaultValue={keywordPid === "" ? "" : keywordPid}
                            // //MenuProps={MenuProps}
                            // {...register("keywordPid")}
                            // label="PID"
                            placeholder="PID#"
                          ></TextField>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2 d-block d-lg-none">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel> */}
                    <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) =>
                              onChange(
                                e.target.value[0] === ""
                                  ? [e.target.value[1]]
                                  : e.target.value
                              )
                            }
                            // input={<Input />}
                            renderValue={(selected) =>
                              selected[0] === ""
                                ? "Periods"
                                : ` Periods (${selected.length} selected)`
                            }
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>
                              Periods
                            </MenuItem>
                            {rentalPeriodOptions.map((item) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                key={item.RentalPeriod}
                                value={item.RentalPeriod}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={
                                    value?.indexOf(item.RentalPeriod) > -1
                                  }
                                />
                                <ListItemText primary={item.RentalPeriod} />
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>

                <div className="col-md-4 mt-2 d-block d-lg-none">
                  <FormControl>
                    <div
                      className="price-filter-box-outer"
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="price-filter-box"
                      >
                        {amount[0] === "0" && amount[1] === "1000000"
                          ? "Any Price "
                          : `$ ${amount[0]} - $ ${amount[1]}`}
                        <ArrowDropDownIcon />
                      </div>
                    </div>
                    <Popover
                      className="price-filter"
                      style={{ marginTop: "40px" }}
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorPosition={{ top: 2000, left: 400 }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography className={classes.typography}>
                        <div>
                          <div className="mb-4 d-flex align-items-center justify-content-between w-100">
                            Price range
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClose()}
                            />
                          </div>
                          <Slide
                            value={amount}
                            step={1000}
                            valueLabelFormat={numFormatter}
                            marks={followersMarks}
                            // scale={scale}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={valuetext}
                            style={{ margin: "0 5px" }}
                            max={100000}
                            min={0}
                          />
                        </div>
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          {/* <InputLabel >Min</InputLabel> */}
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            value={amount[0]}
                            placeholder="Min"
                            onChange={(e) => {
                              setAmount([e.target.value, amount[1]]);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                        >
                          {/* <InputLabel >Max</InputLabel> */}
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            placeholder="Max"
                            value={amount[1]}
                            onChange={(e) => {
                              setAmount([amount[0], e.target.value]);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>
                      </div>
                    </Popover>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-2 d-block d-lg-none">
                  <FormControl>
                    <Select
                      variant="outlined"
                      defaultValue={
                        location?.searchObj?.bed
                          ? location?.searchObj?.bed
                          : "Any Beds"
                      }
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      {...register("bed")}
                    >
                      <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                      {bedRoomsOptions.map((item) => (
                        <MenuItem value={item?.label}>
                          <img
                            src={iconBed}
                            style={{ height: "12px" }}
                            className="img-fluid me-2 sb-icon"
                            alt="Icon"
                          ></img>
                          {item?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-4 mt-2 d-block d-lg-none">
                  <FormControl>
                    <Select
                      variant="outlined"
                      defaultValue={
                        location?.searchObj?.bath
                          ? location?.searchObj?.bath
                          : "Any Baths"
                      }
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      {...register("bath")}
                    >
                      <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                      {bathroomOptions.map((item) => (
                        <MenuItem value={item.label}>
                          <img
                            src={iconBath}
                            style={{ height: "14px" }}
                            className="img-fluid me-2 sb-icon"
                            alt="Icon"
                          ></img>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                    <Controller
                      control={control}
                      name="subArea"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            // input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Areas?" : ` Areas (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Areas</MenuItem>
                            {subAreaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
                  </FormControl>
                </div> */}

                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">AMENITIES</InputLabel> */}
                    <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) =>
                              onChange(
                                e.target.value[0] === ""
                                  ? [e.target.value[1]]
                                  : e.target.value
                              )
                            }
                            // input={<Input />}
                            renderValue={(selected) =>
                              selected[0] === ""
                                ? "Amenities?"
                                : ` Amenities (${selected.length} selected)`
                            }
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>
                              Amenities
                            </MenuItem>
                            {mainAmenityOptions.sort(sortLabels).map((name) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                key={name.value}
                                value={name.value}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={value?.indexOf(name.value) > -1}
                                />
                                <ListItemText primary={name.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                {/* <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
                  <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => {
                        return(
                          <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          variant="outlined"
                          value={value&&value?.length>0? value : [""]}
                          onChange={(e)=>onChange(e.target.value[0]===""?[e.target.value[1]]:e.target.value)}
                          input={<Input />}
                          renderValue={(selected) =>selected[0]===""?"Periods":` Periods (${selected.length} selected)`}
                          MenuProps={MenuProps}
                      >
                        <MenuItem key={0} disabled value={""}>Periods</MenuItem>
                          {rentalPeriodOptions.map((item) => (
                            <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                              <Checkbox  color="primary" checked={value?.indexOf(item.RentalPeriod) > -1} />
                              <ListItemText primary={item.RentalPeriod} />
                            </MenuItem>
                          ))}
                      </Select>
                        )
                          }}
                          />
                  </FormControl>
                </div> */}
                {/* <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                  <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => {
                        return(
                          <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          variant="outlined"
                          value={value&&value?.length>0? value : [""]}
                          onChange={(e)=>onChange(e.target.value[0]===""?[e.target.value[1]]:e.target.value)}
                          input={<Input />}
                          renderValue={(selected) =>selected[0]===""?"Locations":` Locations (${selected.length} selected)`}
                          MenuProps={MenuProps}
                      >
                        <MenuItem key={0} disabled value={""}>Locations</MenuItem>
                          {areaOptions?.sort(sortLabels).map((item) => (
                            <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                              <Checkbox  color="primary" checked={value?.indexOf(item.value) > -1} />
                              <ListItemText primary={item.value} />
                            </MenuItem>
                          ))}
                      </Select>
                        )
                          }}
                          />
                  </FormControl>
                </div> */}
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">WATERFRONT</InputLabel> */}
                    <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) =>
                              onChange(
                                e.target.value[0] === ""
                                  ? [e.target.value[1]]
                                  : e.target.value
                              )
                            }
                            // input={<Input />}
                            renderValue={(selected) =>
                              selected[0] === ""
                                ? "Waterfronts?"
                                : ` Waterfronts (${selected.length} selected)`
                            }
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>
                              Waterfronts
                            </MenuItem>
                            {waterfrontOptions.map((item) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                key={item.value}
                                value={item.value}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={value?.indexOf(item.value) > -1}
                                />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">TENNIS</InputLabel> */}
                    <Controller
                      control={control}
                      name="tennis"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            // input={<Input/>}
                            variant="outlined"
                            value={
                              value ? value : tennis ? tennis : "Any Tennis"
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            renderValue={(selected) =>
                              selected === "" ? "Any Tennis" : selected
                            }
                            MenuProps={MenuProps}
                            // defaultValue={tennis === "" ? "Any Tennis" : tennis}
                            // MenuProps={MenuProps}
                            // {...register("tennis")}
                          >
                            <MenuItem
                              style={{ color: "#6e7073" }}
                              value="Any Tennis"
                            >
                              {"Tennis?"}
                            </MenuItem>
                            {tennisOptions.map((item) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                value={item.value}
                              >
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">POOL</InputLabel> */}
                    <Controller
                      control={control}
                      name="pools"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            // input={<Input/>}
                            variant="outlined"
                            value={value ? value : pools ? pools : "Any Pools"}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            renderValue={(selected) =>
                              selected === "" ? "Any Pools" : selected
                            }
                            MenuProps={MenuProps}
                            // defaultValue={pools === "" ? "Any Pools" : pools}
                            // MenuProps={MenuProps}
                            // {...register("pools")}
                          >
                            <MenuItem
                              style={{ color: "#6e7073" }}
                              value="Any Pools"
                            >
                              {"Pool?"}
                            </MenuItem>
                            {poolOptions.map((item) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                value={item.value}
                              >
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                      control={control}
                      name="pets"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            variant="outlined"
                            value={value ? value : pets ? pets : "Any Pets"}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            renderValue={(selected) =>
                              selected === "" ? "Any Pets" : selected
                            }
                            MenuProps={MenuProps}
                            // input={<Input/>}
                            // defaultValue={pets === "" ? "Any Pets" : pets}
                            // MenuProps={MenuProps}
                            // {...register("pets")}
                          >
                            <MenuItem
                              style={{ color: "#6e7073" }}
                              value="Any Pets"
                            >
                              {"Pets?"}
                            </MenuItem>
                            {petsOptions.map((item) => (
                              <MenuItem
                                className="advance-filter-listItem"
                                value={item.value}
                              >
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4 mt-2">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                      control={control}
                      name="style"
                      render={({ field: { onChange, value } }) => {
                        // console.log("vl", value)
                        return (
                          <Select
                            variant="outlined"
                            // value={ value ? value  :style ? style:"Any Style"}
                            // onChange={(e) => {onChange(e.target.value )}}
                            // renderValue={(selected) => selected=== "" ? "Any Style" : selected}
                            MenuProps={MenuProps}
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) =>
                              onChange(
                                e.target.value[0] === ""
                                  ? [e.target.value[1]]
                                  : e.target.value
                              )
                            }
                            renderValue={(selected) =>
                              selected[0] === "" ? "Style?" : selected
                            }
                            // input={<Input/>}
                            // defaultValue={status === "" ? "Any Status" : status}
                            // MenuProps={MenuProps}
                            // {...register("status")}
                          >
                            <MenuItem
                              style={{ color: "#6e7073" }}
                              key={0}
                              value={[]}
                            >
                              {"Style?"}
                            </MenuItem>
                            <MenuItem
                              className="advance-filter-listItem"
                              value="Condo/Co-op"
                            >
                              Condo/Co-op
                            </MenuItem>
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                </div>
                {/* <div className="col-md-12 mb-2 ms-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={isPrivate}
                        // onChange={(e)=>setIsPrivate(e.target.checked)}
                        name="isPrivate"
                        color="primary"
                        {...register("isPrivate")}
                      />
                    }
                    label="Private"
                  />
                </div> */}
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="propertyListingOuter">
        <div className="container-fluid p-0">
          <div className="row m-0">
            {(mode === "dual" || mode === "list") && (
              <div
                className={mode === "list" ? "col-md-12 p-0" : "col-md-6 p-0"}
              >
                <div className="propertyListingWrap">
                  <div className="propertyListingBand d-md-flex">
                    <ul>
                      <li
                        className={view === "all" ? "active" : null}
                        onClick={() => setView("all")}
                      >
                        {propertyList?.propertyList?.count >= 0 &&
                          `${propertyList?.propertyList?.count} Rentals`}
                      </li>
                      {auth.auth &&
                        viewedList?.leadActivityList?.count > 0 &&
                        user?.data?.Role !== "admin" &&
                        user?.data?.Role !== "agent" &&
                        user?.data?.Role !== "agentAdmin" && (
                          <li
                            className={view === "viewed" ? "active" : null}
                            onClick={() => setView("viewed")}
                          >{`Viewed(${viewedList?.leadActivityList?.count})`}</li>
                        )}
                      {auth.auth &&
                        savedList?.leadActivityList?.count > 0 &&
                        user?.data?.Role !== "admin" &&
                        user?.data?.Role !== "agentAdmin" && (
                          <li
                            className={view === "saved" ? "active" : null}
                            onClick={() => setView("saved")}
                          >{`Saved(${savedList?.leadActivityList?.count})`}</li>
                        )}
                    </ul>
                    <div className="d-flex align-items-center">
                      <ListingIcons />
                      {/* <TextField
                        select
                        variant="outlined"
                        className="propertySort"
                      ></TextField> */}
                    </div>
                  </div>
                  <div
                    className="propertyListing scrollStyle"
                    ref={listInnerRef}
                    onScroll={onScroll}
                  >
                    {/* <div 
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <CircularProgress />
                </div> */}

                    {view === "all" && (
                      <div className="row" id="property_list">
                        {propertyListData &&
                        propertyListData?.propertyList?.count > 0
                          ? propertyListData?.propertyList?.data && (page === Math.floor(propertyListData?.propertyList?.count / 10))&&propertyListData?.propertyList?.data.length<3?
                          propertyList?.propertyList?.data.map(
                            (item, index) => (
                              <PropertyShortTile
                                item={item}
                                index={index}
                                mode={mode}
                                savedListId={savedListId}
                                user={user}
                                refetch={refetch}
                                propertyList={propertyList}
                                propertyListVariables={propertyListVariables}
                                subArea={subArea}
                                rentalPeriod={rentalPeriod}
                                bedFilter={bedFilter}
                                bathFilter={bathFilter}
                                keywordPid={keywordPid}
                                amenityName={amenityName}
                                waterfront={waterfront}
                                style={style}
                                tennis={tennis}
                                pools={pools}
                                pets={pets}
                                priceFilter={priceFilter}
                                area={selected}
                                count={propertyList?.propertyList?.count}
                                skip={page * rowsPerPage}
                                limit={rowsPerPage}
                              />
                            )
                          )
                          :
                            propertyListData?.propertyList?.data.map(
                              (item, index) => (
                                <PropertyShortTile
                                  item={item}
                                  index={index}
                                  mode={mode}
                                  savedListId={savedListId}
                                  user={user}
                                  refetch={refetch}
                                  propertyList={propertyListData}
                                  propertyListVariables={propertyListVariables}
                                  subArea={subArea}
                                  rentalPeriod={rentalPeriod}
                                  bedFilter={bedFilter}
                                  bathFilter={bathFilter}
                                  keywordPid={keywordPid}
                                  amenityName={amenityName}
                                  waterfront={waterfront}
                                  style={style}
                                  tennis={tennis}
                                  pools={pools}
                                  pets={pets}
                                  priceFilter={priceFilter}
                                  area={selected}
                                  count={propertyListData?.propertyList?.count}
                                  skip={page * rowsPerPage}
                                  limit={rowsPerPage}
                                />
                              )
                            )
                          : (loading || mapLoading||propertyList.length === 0)? "": "Your Search did not match any property"}
                      </div>
                    )}
                    <div className="row">
                      {view === "saved" &&
                        savedList?.leadActivityList?.data &&
                        savedList?.leadActivityList?.data.map((item, index) => (
                          <PropertyShortTile
                            item={formatLeadData(item)}
                            index={index}
                            mode={mode}
                            savedListId={savedListId}
                            user={user}
                            refetch={refetch}
                            propertyList={propertyListData}
                            propertyListVariables={propertyListVariables}
                            subArea={subArea}
                            rentalPeriod={rentalPeriod}
                            bedFilter={bedFilter}
                            bathFilter={bathFilter}
                            keywordPid={keywordPid}
                            amenityName={amenityName}
                            waterfront={waterfront}
                            tennis={tennis}
                            pools={pools}
                            pets={pets}
                            priceFilter={priceFilter}
                            area={selected}
                          />
                        ))}
                      {view === "viewed" &&
                        viewedList?.leadActivityList?.data &&
                        viewedList?.leadActivityList?.data.map(
                          (item, index) => (
                            <PropertyShortTile
                              item={formatLeadData(item)}
                              index={index}
                              mode={mode}
                              savedListId={savedListId}
                              user={user}
                              refetch={refetch}
                              propertyList={propertyListData}
                              propertyListVariables={propertyListVariables}
                              subArea={subArea}
                              rentalPeriod={rentalPeriod}
                              bedFilter={bedFilter}
                              bathFilter={bathFilter}
                              keywordPid={keywordPid}
                              amenityName={amenityName}
                              waterfront={waterfront}
                              tennis={tennis}
                              pools={pools}
                              pets={pets}
                              priceFilter={priceFilter}
                              area={selected}
                            />
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(mode === "dual" || mode === "map") && (
              <div
                className={mode === "dual" ? "col-md-6 p-0" : "col-md-12 p-0"}
              >
                <div className="propertyListingMap d-block">
                  {mode === "map" && window.innerWidth > "575.98" ? (
                    <ListingIcons />
                  ) : (
                    <div className="propertyListingWrap">
                      <div className="propertyListingBand d-flex d-md-none">
                        <ul>
                          <li
                            className={view === "all" ? "active" : null}
                            onClick={() => setView("all")}
                          >
                            {propertyList &&
                              propertyList.propertyList &&
                              propertyList.propertyList.count >= 0 &&
                              `${propertyList.propertyList.count} Rentals`}
                          </li>
                        </ul>
                        <div className="d-flex align-items-center">
                          <ListingIconsMaps />
                        </div>
                      </div>
                    </div>
                  )}
                  <Map mode={mode} setTrigger={setTrigger} trigger={trigger} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
