import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useMutation, useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton } from "@material-ui/core";
import * as yup from "yup";
import {
  InputLabel,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
// import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  LIST_PROPERTY_PRICE_DETAILS,
  PROPERTY_PRICE_FOR_PENDING,
} from "../../../Graghql/queries";
import { ADD_PROPERTY_PRICE_DETAILS } from "../../../Graghql/mutations";
import { EDIT_PROPERTY_PRICE_DETAILS } from "../../../Graghql/mutations";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables/index";

const PromotionAndPrice = (props) => {
  // const inputRef = useRef();

  const locations = useLocation();
  const user = useReactiveVar(userDeatils);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const months = [
    {
      RentalPeriod: "January",
      orderValue: 11,
    },
    {
      RentalPeriod: "February",
      orderValue: 12,
    },
    {
      RentalPeriod: "March",
      orderValue: 13,
    },
    {
      RentalPeriod: "April",
      orderValue: 5,
    },
    {
      RentalPeriod: "May",
      orderValue: 6,
    },
    {
      RentalPeriod: "April/May",
      orderValue: 14,
    },
    {
      RentalPeriod: "MD-LD",
      orderValue: 1,
    },
    {
      RentalPeriod: "June",
      orderValue: 7,
    },
    {
      RentalPeriod: "July",
      orderValue: 8,
    },
    {
      RentalPeriod: "August",
      orderValue: 15,
    },
    {
      RentalPeriod: "August - LD",
      orderValue: 2,
    },
    {
      RentalPeriod: "July - LD",
      orderValue: 16,
    },
    {
      RentalPeriod: "June/July",
      orderValue: 4,
    },
    {
      RentalPeriod: "September",
      orderValue: 9,
    },
    {
      RentalPeriod: "October",
      orderValue: 17,
    },
    {
      RentalPeriod: "September/October",
      orderValue: 18,
    },
    {
      RentalPeriod: "November",
      orderValue: 19,
    },
    {
      RentalPeriod: "December",
      orderValue: 20,
    },
    {
      RentalPeriod: "Extended Season",
      orderValue: 21,
    },
    {
      RentalPeriod: "Year Round",
      orderValue: 10,
    },
    {
      RentalPeriod: "Winter",
      orderValue: 22,
    },
    {
      RentalPeriod: "Weekly",
      orderValue: 23,
    },
    {
      RentalPeriod: "Two Weeks",
      orderValue: 24,
    },
    {
      RentalPeriod: "Short Term",
      orderValue: 25,
    },
    {
      RentalPeriod: "Custom",
      orderValue: 26,
    },
    // {
    //   RentalPeriod: "July - LD",
    //   orderValue: 3,
    // },
  ];
  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    { label: "Rented", value: "rented" },
  ];
  const [currencyValue, setCurrencyValue] = useState("");
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [selectedWeekRate, setSelectedWeekRate] = useState("");
  const [shortTermSubmit, setShortTermSubmit] = useState(false);
  const [shortTermError, setShortTermError] = useState([null, null, null]);
  const [selectedShortTerm, setSelectedShortTerm] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [deleteShortTerm, setDeleteShortTerm] = useState();
  const [editedField, setEditedField] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState(null);
  const schema = yup.object().shape({
    SalesPrice: yup.string(),
    // .test("price-validation", "Must be a number", (value) => {
    //   if (value && isNaN(value)) return false;
    //   else return true;
    // })
    PriceDetails: yup.array().of(
      yup.object().shape(
        {
          Price: yup
            .string()
            // .test("price-validation", "Must be a number", (value) => {
            //   if (value && isNaN(value)) return false;
            //   else return true;
            // })
            .when("AvblStatus", {
              is: (AvblStatus) =>
                AvblStatus === "yes" || AvblStatus === "rented",
              then: yup.string().required("Price is required"),
            }),
          AvblStatus: yup
            .string()
            .nullable()
            .when("Price", {
              is: (Price) => Price,
              then: yup
                .string()
                .required("Available Status is required")
                .nullable(),
            }),
        },
        ["AvblStatus", "Price"]
      )
    ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });



  const [addPropertyPriceDetails, { loading }] = useMutation(
    ADD_PROPERTY_PRICE_DETAILS
  );
  const [editPropertyPriceDetails, { loading: editLoading }] = useMutation(
    EDIT_PROPERTY_PRICE_DETAILS
  );
  const [getPropertyPriceData, propertyPriceDetailsData] = useLazyQuery(
    LIST_PROPERTY_PRICE_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: { propertyId: props.propertyId },
      onCompleted: (res) => {
        if (res?.propertyPriceDetails) {
          setDefaultValue(handlePriceOrder(res?.propertyPriceDetails));
          let shortTerm = [];
          res?.propertyPriceDetails?.ShortTermPriceDetails?.forEach(
              (element) => {
                shortTerm.push({
                  Amount: element.Amount,
                  DateFrom: new Date(parseInt(element.DateFrom)),
                  DateTo: new Date(parseInt(element.DateTo)),
                  RatePeriod: element.RatePeriod,
                });
              }
            );
          setSelectedShortTerm(shortTerm);
          setCurrencyValue(res?.propertyPriceDetails?.SalesPrice);
          //formateDefaultValue(res?.propertyPriceDetails);
        } else setDefaultValue({});
      },
    }
  );

  const [getPropertyPendingPriceData, propertyPendingPriceDetailsData] =
    useLazyQuery(PROPERTY_PRICE_FOR_PENDING, {
      fetchPolicy: "network-only",
      variables: { propertyId: props.propertyId },
      onCompleted: (res) => {
        if (res?.propertyPriceForPending) {
          setDefaultValue(handlePriceOrder(res?.propertyPriceForPending));
          let shortTerm = [];
          res &&
            res.propertyPriceForPending &&
            res.propertyPriceForPending.ShortTermPriceDetails.forEach(
              (element) => {
                shortTerm.push({
                  Amount: element.Amount,
                  DateFrom: new Date(parseInt(element.DateFrom)),
                  DateTo: new Date(parseInt(element.DateTo)),
                  RatePeriod: element.RatePeriod,
                });
              }
            );
          setSelectedShortTerm(shortTerm);
          setCurrencyValue(res?.propertyPriceForPending?.SalesPrice);
          //formateDefaultValue(res?.propertyPriceDetails);
        } else setDefaultValue({});
      },
    });
  const handlePriceOrder = (priceData) => {

    let arr = [];
    months.forEach((element) => {
      let position = priceData.PriceDetails.findIndex(
        (item) =>
          item.RentalPeriod && item.RentalPeriod === element.RentalPeriod
      );

      if (position >= 0) arr.push(priceData.PriceDetails[position]);
    });
    //data.PriceDetails=arr

    return {
      PriceDetails: arr,
      PropertyId: priceData.PropertyId,
      SalesPrice: priceData.SalesPrice,
      ShortTermPriceDetails: priceData.ShortTermPriceDetails,
      Status: priceData.Status,
    };
  };
  // const formateDefaultValue=(datas)=>{

  //   if(datas.ShortTermPriceDetails){
  //     let obj={}
  //     datas.ShortTermPriceDetails.forEach((element) => {
  //       obj[element.DateFrom] = obj[element.DateFrom];
  //       obj[element.DateTo] = obj[element.DateTo];
  //       obj[element.Amount] = obj[element.Amount];
  //     });
  //    // datas.ShortTermPriceDetails = obj;
  //    // setSelectedDateFrom(datas.ShortTermPriceDetails.DateFrom)
  //   }
  //   setDefaultValue(datas)
  // }

  useEffect(() => {
    if (
      props.pathnameCheck === false &&
      user?.data?.Role !== "owner" &&
      (locations?.state?.pendingFlagCheck === false ||
        locations?.state?.pendingFlagCheck === null ||
        locations?.state?.pendingFlagCheck === undefined)
    ) {
      if (propertyPriceDetailsData) getPropertyPriceData();
    } else {
      if (propertyPendingPriceDetailsData) getPropertyPendingPriceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkForChanges = (field, value, type) => {

    if (Object.keys(defaultValue).length !== 0) {
      let obj = { ...editedField };

      if (defaultValue[field]) {
        if (defaultValue[field] !== value) {
          obj[field] = defaultValue[field];
        } else {
          delete obj[field];
        }
        setEditedField(obj);

      } else if (defaultValue[field] === null) {
        obj[field] = defaultValue[field];
      }
    }
  };
  function getNum(val) {
    if (isNaN(val)) {
      return 0;
    }
    return val;
  }
  const onSubmit = (data) => {
    // if (invalid && isTouched) {
    //   inputRef.current.focus();
    // }

    let isEdit = Object.keys(defaultValue).length === 0 ? false : true;
    if (data["SalesPrice"])
      data["SalesPrice"] = Number(NumberChecking(data["SalesPrice"]));
    else data["SalesPrice"] = 0;
    data["EditedSalesPrice"] = defaultValue?.SalesPrice;
    let arr = [];
    let checkFlag = false;
    data &&
      data.PriceDetails &&
      data.PriceDetails.forEach((item) => {

        if (item.Price) {
          checkFlag = true;
          return;
        }
      });
    if (selectedShortTerm.length >= 1 || checkFlag === true) {
      for (let index = 0; index < data?.PriceDetails.length; index++) {
        let obj = {};
        obj["RentalPeriod"] = months[index].RentalPeriod;
        obj["PriceOrder"] = months[index].orderValue;
        obj["PrePrice"] =
          isEdit &&
          defaultValue?.PriceDetails?.[index]?.Price !==
            data?.PriceDetails?.[index]?.["Price"]
            ? defaultValue?.PriceDetails?.[index]?.Price
            : defaultValue?.PriceDetails?.[index]?.PrePrice
            ? defaultValue?.PriceDetails?.[index]?.PrePrice
            : 0;
        obj["IsEdited"] =
          (isEdit &&
            defaultValue?.PriceDetails?.[index]?.Price !==
              getNum(parseInt(data?.PriceDetails?.[index]?.["Price"]))) ||
          defaultValue?.PriceDetails?.[index]?.AvblStatus !==
            (data?.PriceDetails?.[index]?.["AvblStatus"]
              ? data?.PriceDetails?.[index]?.["AvblStatus"]
              : "")
            ? true
            : // :(defaultValue?.PriceDetails?.[index]?.AvblStatus !==
              // (data?.PriceDetails?.[index]?.["AvblStatus"]))&&(data?.PriceDetails?.[index]?.["AvblStatus"])!==undefined
              // ?true
              // : defaultValue?.PriceDetails?.[index]?.IsEdited
              // ? defaultValue?.PriceDetails?.[index]?.IsEdited
              false;

        // data?.PriceDetails?.[index]?.["Price"])
        obj["Price"] = data?.PriceDetails?.[index]?.["Price"]
          ? Number(NumberChecking(data?.PriceDetails?.[index]?.["Price"]))
          : 0;

        // )
        obj["AvblStatus"] = data?.PriceDetails?.[index]?.["AvblStatus"]
          ? data?.PriceDetails?.[index]?.["AvblStatus"]
          : "";
        // if (obj.AvblStatus === null) obj["AvblStatus"] = "";
        // else obj["AvblStatus"]=obj["AvblStatus"]

        // if (obj.Price==="0") obj["Price"] = 0;
        //  else obj["Price"] = NumberChecking(obj["Price"]);
        arr.push(obj);
      }
      data.PriceDetails = arr;

      data.ShortTermPriceDetails = selectedShortTerm;
      if (Object.keys(defaultValue).length === 0) {
        try {
          data["PropertyId"] = props.propertyId;
          addPropertyPriceDetails({ variables: data })
            .then((res) => {


              toggleSnackbar({
                status: true,
                message: "Price has been successfully added",
                variant: "success",
              });

              //reset();
              props && props.handleNext(3, 3);
            })
            .catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        // data?.PriceDetails?.every((item) => item?.IsEdited ===true&&props?.handleNext(3))

        //if(checkData===true) props?.handleNext(3);

        try {
          props.pathnameCheck === true
            ? (data["Type"] = "Pending")
            : (data["Type"] = null);
          data["PropertyId"] = props.propertyId;
          //(data?.SalesPrice===data?.EditedSalesPrice)&&props?.handleNext(3)
          (selectedShortTerm.length !==
            defaultValue?.ShortTermPriceDetails.length ||
            data?.SalesPrice !== data?.EditedSalesPrice) &&
            editPropertyPriceDetails({ variables: data })
              .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Price has been successfully updated",
                  variant: "success",
                });

                //reset();
                props && props.handleNext(3);
              })
              .catch((err) => {
                const message = err && err.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });
          // (data?.SalesPrice!==data?.EditedSalesPrice)&&
          // editPropertyPriceDetails({ variables: data })
          //   .then((res) => {
          //     toggleSnackbar({
          //       status: true,
          //       message: "Price has been successfully updated",
          //       variant: "success",
          //     });

          //     //reset();
          //     props?.handleNext(3);
          //   })
          //   .catch((err) => {
          //     const message = err && err.message;
          //     toggleSnackbar({
          //       status: true,
          //       message: message,
          //       variant: "error",
          //     });
          //   })
          data &&
            data.PriceDetails &&
            data.PriceDetails.map(
              (item) => item?.IsEdited === false && props?.handleNext(3)
            );
          const isEditedData = data?.PriceDetails?.map(
            (item) => item?.IsEdited === true
          );

          isEditedData.includes(true) &&
            editPropertyPriceDetails({ variables: data })
              .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Price has been successfully updated",
                  variant: "success",
                });

                //reset();
                props && props.handleNext(3);
              })
              .catch((err) => {
                const message = err && err.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });

          //           )
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    } else {
      toggleSnackbar({
        status: true,
        message: "Please add at least one rental price",
        variant: "error",
      });
    }
  };

  const handleShortTermError = (type, value) => {
    let arr1 = JSON.stringify(shortTermError);
    let arr = JSON.parse(arr1);
    switch (type) {
      case "all":
        if (!selectedDateFrom) arr[0] = "From Date is required";
        if (!selectedDateTo) arr[1] = "To Date is required";
        if (
          !selectedWeekRate ||
          selectedWeekRate === "" ||
          selectedWeekRate == null
        )
          arr[2] = "Weekly Rate is required";
        break;
      case "fromDate":
        if (value === "") arr[0] = "From Date is required";
        else arr[0] = null;
        break;
      case "toDate":
        if (value === "") arr[1] = "To Date is required";
        else arr[1] = null;
        break;
      case "weeklyRate":
        if (value === "") arr[2] = "Weekly Rate is required";
        else arr[2] = null;
        break;
      default:
        break;
    }

    setShortTermError(arr);
  };
  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date);
    handleShortTermError("fromDate", date);
  };
  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
    handleShortTermError("toDate", date);
  };
  const handleRemoveShortTerm = (item) => {
    // eslint-disable-next-line array-callback-return
    const deleteShortTerm = selectedShortTerm?.map((items, index) => {
      if (items === item) {
        selectedShortTerm.splice(index, 1);
      }
    });
    setDeleteShortTerm(deleteShortTerm);
  };
  const handleShortTerm = () => {
    setShortTermSubmit(true);
    setShortTermError([null, null, null]);
    if (selectedDateFrom && selectedDateTo && selectedWeekRate) {
      setShortTermSubmit(true);
      let addShortTerm = [...selectedShortTerm];

      let obj = {};
      obj.DateFrom = selectedDateFrom;
      obj.DateTo = selectedDateTo;
      obj.Amount = Number(selectedWeekRate);
      // obj.flag = true;
      //obj.AmountFormat = selectedWeekRate;
      addShortTerm.push(obj);
      setSelectedShortTerm(addShortTerm);

      setSelectedDateFrom(null);
      setSelectedDateTo(null);
      setSelectedWeekRate("");
      
    } else {
      handleShortTermError("all");
    }
  };
  // eslint-disable-next-line no-unused-vars
  const validateDate = () => {
    let values = moment(selectedDateTo).format("MM/dd/yyyy");
    let msg = "";
    if (
      moment(selectedDateFrom).format("MM/dd/yyyy") != null &&
      values != null &&
      moment(selectedDateFrom).format("MM/dd/yyyy") > values
    ) {
      msg = "End Date should be greater than Start Date";
    } else {
      msg = "";
    }

    setErrorMsg(msg);
  };

  const NumberChecking = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === "" || re.test(e)) {
      return e;
    } else {
      let newVal = e.replace(/\D/g, "");
      return newVal;
    }
  };
  const currencyFormat = (e) => {
    if (e)
      return parseInt(e)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //    // register(inputRef.current);
  //     inputRef.current.focus();
  //   }
  // }, []);
  const currencyEdit = register("SalesPrice");
  return (
    <div className="add-property-form-wrap">
      {defaultValue && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="add-property-form-step">
            <h5>Availability</h5>
            <span className="discription">
              Please always check your pricing so we have accurate information
              to work with. Also frequent updating ensures your property shows
              up first in search
            </span>

            <div className="availability-wrap">
              {months?.map((item, index) => (
                <div className="availability-per-month" key={index}>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="d-flex align-items-center">
                        <label className="month">{item.RentalPeriod}</label>
                        <div className="availability-price">
                          <span>$</span>
                          <Controller
                            control={control}
                            name={"PriceDetails." + index + ".Price"}
                            defaultValue={
                              defaultValue?.PriceDetails?.[index]?.Price
                            }
                            render={({ field: { onChange, value } }) => {
                              // const formattedValue =
                              //   value === 0 ? null : currencyFormat(value);
                              return (
                                <TextField
                                  variant="outlined"
                                  className="formTextBox"
                                  // defaultValue={
                                  //   defaultValue?.PriceDetails?.[index]?.Price
                                  // }
                                  value={currencyFormat(value)}
                                  ref={
                                    register("PriceDetails." + index + ".Price")
                                      .ref
                                  }
                                  // ref={inputRef}
                                  onChange={(e) => {
                                    onChange(NumberChecking(e.target.value));
                                    //     if(errors.PriceDetails?.[index]?.Price)
                                    //  { setError(`PriceDetails.[${index}].Price`);
                                    // }
                                  }}
                                  // {...register(
                                  //   "PriceDetails." + index + ".Price"
                                  // )}
                                  error={errors.PriceDetails?.[index]?.Price}
                                  // onFocus={() => {
                                  //   if (errors.PriceDetails?.[index]?.Price) {
                                  //     document.querySelector(errors.PriceDetails?.[index]?.Price).focus();
                                  //   }
                                  // }}
                                />
                              );
                            }}
                          />
                          {errors && errors?.PriceDetails?.[index]?.Price && (
                            <p className="errorMessage">
                              {errors?.PriceDetails?.[index]?.Price?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="availability-radio">
                        <>
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={
                              defaultValue?.PriceDetails?.[index]?.AvblStatus===""?'no': defaultValue?.PriceDetails?.[index]?.AvblStatus
                            }
                          >
                            {options?.map((item, i) => (
                              <FormControlLabel
                                {...register(
                                  "PriceDetails." + index + ".AvblStatus"
                                )}
                                key={i}
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                          {errors &&
                            errors?.PriceDetails?.[index]?.AvblStatus && (
                              <p style={{ fontSize: "10px", color: "red" }}>
                                {
                                  errors?.PriceDetails?.[index]?.AvblStatus
                                    ?.message
                                }
                              </p>
                            )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="add-property-form-step grey-bg">
            <h5>Short Term / Event Rental</h5>
            <div className="row">
              <div className="col-md-3">
                <InputLabel className="form-label">DATE RANGE</InputLabel>
                {/* <TextField variant="outlined" className="formTextBox" placeholder="From" /> */}
                <div className="formTextBoxOuter">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //defaultValue={defaultValue?.ShortTermPriceDetails?.[index]?.DateFrom}
                      margin="normal"
                      id="date-picker-dialog"
                      className="formTextBox date-box w-100 mt-0"
                      placeholder="From"
                      format="MM/dd/yyyy"
                      maxDate={selectedDateTo ? selectedDateTo : undefined}
                      value={selectedDateFrom}
                      onChange={handleDateChangeFrom}
                      //   value={selectedDate}
                      //   {...dateRegister}
                      //   onChange={(e) => {
                      //     dateRegister.onChange(e);
                      //     setSelectedDate(e);
                      //   }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {shortTermSubmit && (
                    <span className="errorMessage">{shortTermError[0]}</span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <InputLabel className="form-label">&nbsp;</InputLabel>
                {/* <TextField variant="outlined" className="formTextBox" placeholder="To" /> */}
                <div className="formTextBoxOuter">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //defaultValue={defaultValue?.ShortTermPriceDetails?.[index]?.DateTo}
                      margin="normal"
                      id="date-picker-dialog"
                      className="formTextBox date-box w-100 mt-0"
                      placeholder="To"
                      format="MM/dd/yyyy"
                      minDate={selectedDateFrom ? selectedDateFrom : undefined}
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {shortTermSubmit && (
                    <span className="errorMessage">{shortTermError[1]}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">WEEKLY RATE $</InputLabel>
                <div className="formTextBoxOuter">
                  <TextField
                    //defaultValue={defaultValue?.ShortTermPriceDetails?.[index]?.Amount}
                    variant="outlined"
                    className="formTextBox"
                    placeholder="0"
                    value={
                      currencyFormat(selectedWeekRate)
                        ? currencyFormat(selectedWeekRate)
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedWeekRate(NumberChecking(e.target.value));
                      handleShortTermError("weeklyRate", e.target.value);
                    }}
                  />
                  {shortTermSubmit && (
                    <span className="errorMessage">{shortTermError[2]}</span>
                  )}
                </div>
              </div>
            </div>

            <div>
              <Button
                color="primary"
                className="highlight-btn"
                variant="contained"
                onClick={() => handleShortTerm()}
              >
                Add Short Term
              </Button>
              <div className="short-term-date-shown-wrap">
                {selectedShortTerm?.map((item, index) => {
                  return (
                    <div className="short-term-date-shown" key={index}>
                      {typeof item.DateFrom == "object"
                        ? moment(item.DateFrom).format("MM/DD/YYYY")
                        : moment(new Date(parseInt(item.DateFrom))).format(
                            "MM/DD/YYYY"
                          )}{" "}
                      to{" "}
                      {typeof item.DateTo == "object"
                        ? moment(item.DateTo).format("MM/DD/YYYY")
                        : moment(new Date(parseInt(item.DateTo))).format(
                            "MM/DD/YYYY"
                          )}{" "}
                      -&nbsp; <b> ${currencyFormat(item?.Amount)}</b>
                      <IconButton
                        onClick={() => handleRemoveShortTerm(item)}
                        className="p-1"
                      >
                        <img
                          src="../assets/images/date-close.svg"
                          className="img-fluid date-close"
                          alt="Icon"
                        />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="add-property-form-step"
            style={{ borderBottom: "1px solid #E2E2E2" }}
          >
            <h5>Is your property also for sale?</h5>
            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">SALE PRICE $</InputLabel>
                <TextField
                  defaultValue={defaultValue?.SalesPrice}
                  variant="outlined"
                  className="formTextBox mb-0"
                  placeholder="0"
                  // {...register("SalesPrice")}
                  {...currencyEdit}
                  value={currencyFormat(currencyValue)}
                  onChange={(e) => {
                    currencyEdit.onChange(e);
                    setCurrencyValue(NumberChecking(e.target.value));
                  }}
                  error={errors.SalesPrice}
                  onBlur={(e) =>
                    checkForChanges(
                      "SalesPrice",
                      e.target.value,
                      typeof e.target.value
                    )
                  }
                />
                {errors && errors?.["SalesPrice"] && (
                  <p className="errorMessage">{errors?.SalesPrice?.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className="add-property-form-step add-property-btn">
            <Button
              className="outline-btn"
              variant="outline"
              onClick={props.handlePrevious}
            >
              Back
            </Button>
            <div>
              <Button
                disabled={loading}
                color="primary"
                className="highlight-btn"
                variant="contained"
                type="submit"
                id="property-save-btn"
              >
                {loading || editLoading ? (
                  <CircularProgress size="20px" color="inherit" />
                ) : (
                  " Save"
                )}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default PromotionAndPrice;
