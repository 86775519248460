import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {
  LEAD_ACTIVITY_LIST,
  LEADS_DETAIL,
  LIST_PROPERTY_PREFERENCE,
} from "../../../Graghql/queries";
import LeadProfileEmail from "./LeadProfileEmail"
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom"
import AddRenter from "../../Renter/AddRenter";
import AddOwner from "../../Owner/AddOwner";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: "10px",
  },
}));

const LeadProfile = (props) => {
  const [openAddRenterEdit, setOpenAddRenterEdit] = React.useState(false);
  const [openAddOwnerEdit, setOpenAddOwnerEdit] = React.useState(false);
  const [, setDefaultValue] = React.useState(null);
  const [openEmail, setOpenEmail] = useState(false);
  const { id } = useParams();
  const classes = useStyles();
  const leadActivityListVariables = {
    skip: 0,
    limit: 10,
    tabType: "AgentComment",
    leadId: id,
  };

  const { data: leadActivityNote } = useQuery(LEAD_ACTIVITY_LIST, {
    fetchPolicy: "network-only",
    variables: leadActivityListVariables,
  });

  let notesData =
    leadActivityNote &&
    leadActivityNote.leadActivityList &&
    leadActivityNote.leadActivityList.data;

  const { data} = useQuery(LEADS_DETAIL, {
    fetchPolicy: "network-only",
    variables: { leadId: id },
  });
  let UserId = data?.leadsDetails?.UserId;
  //console.log("dta", data)
  const { data: propertyPreferences } =
    useQuery(LIST_PROPERTY_PREFERENCE, {
      fetchPolicy: "network-only",
      variables: { userId: UserId },
    });
  //console.log("propertyPreferences", propertyPreferences)
  function formatName(firstName, lastName) {
    // eslint-disable-next-line no-sequences
    if ((firstName, lastName))
      return (
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1).toLowerCase() +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1).toLowerCase()
      );
  }
  let name = formatName(
    data?.leadsDetails?.UserDtls?.FirstName,
    data?.leadsDetails?.UserDtls?.LastName
  );
  let date = data?.leadsDetails?.UpdatedOn;
  let uType = data?.leadsDetails?.UserType;
  function phoneNumberFormat(num) {
    if (num) {
      let newNum = num.replace(/[-]+/g, "");
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
      } else {
        x =
          newNum.slice(0, 3) +
          "-" +
          newNum.slice(3, 6) +
          "-" +
          newNum.slice(6, 10);
      }
      return x;
    }
  }

  // console.log("///",data)
  return (
    <div className="col-md-4">
      {openEmail &&
        <LeadProfileEmail
          openEmail={openEmail}
          setOpenEmail={setOpenEmail}
          data={data?.leadsDetails?.UserDtls?.Email}
          recipientId={data?.leadsDetails?.UserId}
        />
      }
      {/* <Loader open={leadsDetailsLoading}/> */}
      <div className="about-lead">
        <IconButton className="editBtn">
          <img
            src="../assets/images/icon-edit.svg"
            className="img-fluid"
            alt="Icon"
            onClick={() => data?.leadsDetails?.UserType === "renter" ? setOpenAddRenterEdit(true) : setOpenAddOwnerEdit(true)}
          />
        </IconButton>
        <AddRenter
          //handlePagination={handlePagination}
          open={openAddRenterEdit}
          setOpen={setOpenAddRenterEdit}
          defaultValue={data?.leadsDetails}
          setDefaultValue={setDefaultValue}
          leadRenter={true}
        />
        <AddOwner
          //handlePagination={handlePagination}
          open={openAddOwnerEdit}
          setOpen={setOpenAddOwnerEdit}
          defaultValue={data?.leadsDetails}
          setDefaultValue={setDefaultValue}
          leadOwner={true}
        />
        <div className="about-lead-details about-lead-blocks">
          <h2>{name}</h2>
          {data?.leadsDetails?.UserDtls?.Email && (
            <div className="about-lead-mail-phone">
              <Link to='#' onClick={() => setOpenEmail(true)}
              //onClick={(e) => { window.location = 'mailto: '+ data?.leadsDetails?.UserDtls?.Email.toString()e.preventDefault();}}
              >
                <img
                  src="../assets/images/icon-email-double.svg"
                  className="img-fluid"
                  alt="Icon"
                //style={{marginRight:'5px'}}
                />
                {data?.leadsDetails?.UserDtls?.Email}
              </Link>
            </div>
          )}
          {data?.leadsDetails?.UserDtls?.Phone && (
            <div className="about-lead-mail-phone">
              <Link to='#' onClick={(e) => {
                window.location = "tel:" + data?.leadsDetails?.UserDtls?.Phone
                e.preventDefault();
              }}>
                <img
                  src="../assets/images/icon-mobile-2.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                {data?.leadsDetails?.UserDtls?.Phone}
              </Link>
            </div>
          )}
          {data?.leadsDetails?.UserDtls?.CellPhone && (
            <div className="about-lead-mail-phone">
              <Link to='#' onClick={(e) => {
                window.location = "tel:" + data?.leadsDetails?.UserDtls?.CellPhone
                e.preventDefault();
              }}>
                <img
                  src="../assets/images/icon-mobile-2.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                {data?.leadsDetails?.UserDtls?.CellPhone}
              </Link>
            </div>
          )}
          {data?.leadsDetails?.UserDtls?.LocalPhone && (
            <div className="about-lead-mail-phone">
              <Link to='#' onClick={(e) => {
                window.location = "tel:" + data?.leadsDetails?.UserDtls?.LocalPhone
                e.preventDefault();
              }}>
                <img
                  src="../assets/images/icon-mobile-2.svg"
                  className="img-fluid"
                  alt="Icon"
                />
                {phoneNumberFormat(data?.leadsDetails?.UserDtls?.LocalPhone)}
              </Link>
            </div>
          )}

          {data?.leadsDetails?.UserDtls?.Email && (
            <Button className="email-lead" variant="contained" color="primary" onClick={() => setOpenEmail(true)}>
              {/* <Link
                style={{ color: "white" }}
                //target="_blank"
                to="#" onClick={(e)=>{window.location= "mailto: "+ data?.leadsDetails?.UserDtls?.Email.toString()
              e.preventDefault() }}
              > */}
              <img
                src="../assets/images/email-white.svg"
                className="img-fluid"
                alt="Icon"
              />{" "}
              Email{" "}
              {formatName(
                data?.leadsDetails?.UserDtls?.FirstName,
                data?.leadsDetails?.UserDtls?.LastName
              )}
              {/* </Link> */}
            </Button>
          )}
        </div>
        <hr />
        <div className="about-lead-blocks lead-first-contact">
          <h3>First Contact</h3>
          <ul>
            <li>
              <label>Date & Time</label>
              {date && (
                <span>
                  {moment(new Date(parseInt(date))).format("L") +
                    " " +
                    moment(new Date(parseInt(date))).format("LT")}
                </span>
              )}
            </li>
          </ul>
        </div>
        <hr />

        {uType === "renter" || uType === "owner" ? (
          <div className="about-lead-blocks">
            <h3>Property Preferences</h3>
            {propertyPreferences?.propertyPreference?._id  ?
              <ul>
                {propertyPreferences?.propertyPreference?.BudgetFrom &&
                  propertyPreferences?.propertyPreference?.BudgetTo && (
                    <li>
                      <label>Price Range:&nbsp;&nbsp;</label>
                      <span>
                        ${propertyPreferences?.propertyPreference?.BudgetFrom} - $
                        {propertyPreferences?.propertyPreference?.BudgetTo}
                      </span>
                    </li>
                  )}
                {propertyPreferences?.propertyPreference?.ListBedRoom && (
                  <li>
                    <label>Min Bedrooms:&nbsp;&nbsp;</label>
                    <span>
                      {propertyPreferences?.propertyPreference?.ListBedRoom}
                    </span>
                  </li>
                )}
                {propertyPreferences?.propertyPreference?.ListPool && (
                  <li>
                    <label>Pool:&nbsp;&nbsp;</label>
                    <span>
                      {propertyPreferences?.propertyPreference?.ListPool}
                    </span>
                  </li>
                )}
                {propertyPreferences?.propertyPreference?.RentalPeriods && (
                  <li>
                    <label>Rental Period Sought:&nbsp;&nbsp;</label>
                    <span>
                      {propertyPreferences?.propertyPreference?.RentalPeriods?.join(
                        ", "
                      )
                      }
                    </span>
                  </li>
                )} 
                {propertyPreferences?.propertyPreference?.Locations && (
                  <li>
                    <label>Location Preferences:&nbsp;&nbsp;</label>
                    <span>
                      {propertyPreferences?.propertyPreference?.Locations?.join(
                        ", "
                      )}
                    </span>
                  </li>
                )}
              </ul> : "No preferences to show"}
          </div>
        ) : null}

        <hr />
        <div className="about-lead-details about-lead-blocks">
          <h3>Associated Agent</h3>
          <div className="d-flex align-items-start">
            <Avatar
              className={classes.small}
              src={data?.leadsDetails?.AgentDtls?.ProfileImage}
            >
              {data?.leadsDetails?.AgentDtls?.FirstName.charAt(0).toUpperCase() + data?.leadsDetails?.AgentDtls?.LastName.charAt(0).toUpperCase()}
            </Avatar>
            <div>
            <label>
              {formatName(
                data?.leadsDetails?.AgentDtls?.FirstName,
                data?.leadsDetails?.AgentDtls?.LastName
              )}
            </label>
            {data?.leadsDetails?.AgentDtls?.Email &&
            <div className="about-lead-mail-phone">
              <a href="mailto:{data?.leadsDetails?.AgentDtls?.Email}"> <img
                src="../assets/images/icon-email-double.svg"
                className="img-fluid"
                alt="Icon"
              />
                {data?.leadsDetails?.AgentDtls?.Email}
              </a>
            </div>}
          {data?.leadsDetails?.AgentDtls?.Phone &&
            <div className="about-lead-mail-phone">
              <a href="tel:{phoneNumberFormat(data?.leadsDetails?.AgentDtls?.Phone)}">
                <img
                  src="../assets/images/icon-mobile-2.svg"
                  className="img-fluid"
                  alt="Icon"
                />{phoneNumberFormat(data?.leadsDetails?.AgentDtls?.Phone)}
              </a>
            </div>
          }
          </div>

          </div>

          

        </div>
        <hr />
        <div className="about-lead-blocks">
          <h3>Additional Notes</h3>
          {notesData && notesData.length === 0 ? "No notes added" :
            notesData &&
            notesData.map(
              (items, index) =>
                index < 3 && (
                  <div className="additional-note-item">
                    <address>
                      {items.CreatedByName.charAt(0).toUpperCase() +
                        items.CreatedByName.slice(1)}{" "}
                    </address>
                    <div className="d-flex align-items-center">
                      <label>
                        {" "}
                        <img
                          src="../assets/images/icon-calendar.svg"
                          className="me-1 img-fluid"
                          alt="Calendar Icon"
                        />
                        {moment(new Date(parseInt(items.CreatedOn))).format(
                          "ll"
                        )}{" "}
                      </label>
                      <label>
                        <img
                          src="../assets/images/icon-clock.svg"
                          className="me-1 img-fluid"
                          alt="Clock Icon"
                        />
                        {moment(new Date(parseInt(items.CreatedOn))).format(
                          "LT"
                        )}
                      </label>
                    </div>
                    <p>{items.Comment}</p>
                    {/* There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered */}
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};
export default LeadProfile;
