import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FrontendHeader from "../Common/frontendHeader";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { FRONTEND_TEAM_DETAIL, LIST_AGENT_PROPERTIES, LIST_SAVED_PROPERTIES } from "../../Graghql/queries";
import Footer from "../Common/footer";
import Slider from "react-slick";
import { DialogBox } from "../Common";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useHistory, Link } from "react-router-dom";
import AuthPopUp from "../Login/AuthPopUp";
import {
  IconButton,
} from "@material-ui/core";
import {
  LEAD_ACTIVITY_ADD,
  UNSAVE_PROPERTY,
} from "../../Graghql/mutations"
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { authVar, userDeatils } from "../../RactiveVariables";

const TeamDetails = () => {
  const { id } = useParams();
  const history = useHistory()
  const { data, } = useQuery(FRONTEND_TEAM_DETAIL, {
    variables: { userId: id },
  });
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[]);
  console.log("data", data)
  const { data: agentProperties } = useQuery(LIST_AGENT_PROPERTIES, {
    variables: { userId: id },
  });
  console.log("agt", agentProperties)
  var homeSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      }
    }],
    // eslint-disable-next-line no-dupe-keys
    responsive: [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      }
    }]

  };

  // eslint-disable-next-line no-unused-vars
  function PreviousArrow(props) {
    const { onClick } = props;
    return (
      <img
      alt="prev"
        onClick={onClick}
        className="slick-prev"
        src="../assets/images/back.svg"
      />
    );
  }
  // eslint-disable-next-line no-unused-vars
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
      alt="next"
        onClick={onClick}
        className="slick-next"
        src="../assets/images/forward.svg"
      />
    );
  }
  let teamDetailList = data && data.agentDetailView;
  console.log("detailData", teamDetailList);
  return (
    <div>
      <FrontendHeader />

      <section className="team-details">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>
                {teamDetailList &&
                  teamDetailList.FirstName.charAt(0).toUpperCase()}
                {teamDetailList && teamDetailList.FirstName.slice(1)}{" "}
                {teamDetailList &&
                  teamDetailList.LastName.charAt(0).toUpperCase()}
                {teamDetailList && teamDetailList.LastName.slice(1)}{" "}
              </h2>
              {teamDetailList?.Designation && (
                <label className="designation">
                  {teamDetailList?.Designation.charAt(0).toUpperCase()}
                  {teamDetailList?.Designation.slice(1)}
                </label>
              )}
              <div className="team-details-text">
                <p>{teamDetailList && teamDetailList.PersonalStatement}</p>
              </div>

              {/* <div className="team-experience">
                <h3>Experience</h3>
                <p style={{color:"red",marginBottom:"20px"}}>*** Demo data</p>
                <ul>
                  <li>
                    <img src="../assets/images/round-tick.svg" className="img-fluid" alt="Icon" />
                    20 Years in Real Estate
                  </li>
                  <li>
                    <img src="../assets/images/round-tick.svg" className="img-fluid" alt="Icon" />
                    4 Years in Retail Sales
                  </li>
                  <li>
                    <img src="../assets/images/round-tick.svg" className="img-fluid" alt="Icon" />
                    20 Years in Real Estate
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-md-4">
              <div className="team-item">
                {teamDetailList?.ProfileImage ? (
                  <Avatar src={teamDetailList?.ProfileImage}></Avatar>
                ) : (
                  <Avatar
                    variant="square"
                    src="../assets/images/no-image.jpg"
                    alt="Name"
                  ></Avatar>
                )}
                <div className="team-primary-data">
                  <ul>
                    <li>
                      <Link to="#" onClick={(e)=>{window.location ="mailto:"+ teamDetailList.Email.toString(); e.preventDefault()}}
                        // href="mailto:Angeelamorisson@gmail.com"
                        className="team-email"
                      >
                        <img
                          src="../assets/images/email-red.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        {teamDetailList && teamDetailList.Email}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={(e)=>{window.location ="tel:"+ teamDetailList.Phone.toString(); e.preventDefault()}}>
                        <img
                          src="../assets/images/phone-red.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        {teamDetailList && teamDetailList.Phone}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={(e)=>{window.location ="tel:"+ teamDetailList.OfficePhone.toString(); e.preventDefault()}}>
                        <img
                          src="../assets/images/mobile-red.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        {teamDetailList && teamDetailList.OfficePhone}{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
                <Divider variant="outset" component="div" />
                <div className="team-mail-btn">
                  <Button
                    className="checkout-listing"
                    color="primary"
                    variant="contained"
                  >
                    <Link style={{color: "#fff"}} to="#" onClick={(e)=>{window.location ="mailto:"+ teamDetailList.Email.toString(); e.preventDefault()}}>
                    Email To {teamDetailList && teamDetailList.FirstName}{" "}
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="homePropetry agentPropertySlider" style={{ backgroundColor: '#F5F5F5' }}>
          <div className="container">
            <div className="agentPropertyTitle d-flex align-items-center justify-content-between">
              {agentProperties?.listAgentProperties?.count > 0 && <label style={{ fontSize: "20px", margin: "15px" }}>Listings of {teamDetailList &&
                teamDetailList?.FirstName.charAt(0).toUpperCase()}
                {teamDetailList && teamDetailList?.FirstName.slice(1)}{" "}
              </label>}

              {agentProperties?.listAgentProperties?.count > 1 && <Button variant="outlined" color="primary" onClick={() => history.push("/agent-details/" + id)}>View All Listings</Button>}
            </div>
            <Slider className="homeSlider" {...homeSlider}>
              {agentProperties?.listAgentProperties?.data &&
                agentProperties?.listAgentProperties?.data.map((items, index) => (
                  <SimilarPropertyTile
                    item={items} index={index}
                  />
                ))
              }
            </Slider>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TeamDetails;

function SimilarPropertyTile(props) {

  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  console.log("user", user)
  const auth = useReactiveVar(authVar);
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
  });
  const { data: listSavedProperty } = useQuery(LIST_SAVED_PROPERTIES, {
    fetchPolicy: "network-only",
    variables: { userId: user?.data?._id },
  });
  const [openUnsaveMsg, setOpenUnsaveMsg] = useState(false);
  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;
  console.log("savedProperties", savedProperties)
  const { id } = useParams();
  console.log("id", id)
  console.log("pr", props)
  const { item } = props;


  // eslint-disable-next-line no-unused-vars
  const history = useHistory()
  const [openAuthPopUp, setOpenAuthPopUp] = React.useState(props?.location === "/login" ? true : false);
  // eslint-disable-next-line no-unused-vars
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };
  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex((element) => element.PropertyId === item._id);
      if (position !== -1) return true;
      else return false;
    }
  }
  const handleSaveProperty = () => {
    // alert("hi");
    // let arr = [];
    // data?.propertyDetails?.forEach((element) => {
    //   let ids = element?._id;
    //   arr.push(ids);
    // });
    // console.log("params.PropertyIDs", arr);
    try {
      savedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [item._id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    // alert("hello")
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: item._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  function PreviousArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="prev"
        onClick={onClick}
        className="slick-prev"
        src="../assets/images/back.svg"
      />
    );
  }
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="next"
        onClick={onClick}
        className="slick-next"
        src="../assets/images/forward.svg"
      />
    );
  }


  return (
    <div className="pl-item" >
      <DialogBox
        open={openUnsaveMsg}
        setOpen={setOpenUnsaveMsg}
        handlePositiveBtn={handleUnSaveProperty}
        title={"Unsave Property?"}
        text={"Are you sure you want to Unsave the property?"}
      />
      <AuthPopUp open={openAuthPopUp} setOpen={setOpenAuthPopUp} />
      {
        
        <div className="pl-item-img">
          {auth.auth && userRole !== "admin" && <IconButton className="pl-fav" color="secondary" onClick={() => { isSaved() ? setOpenUnsaveMsg(true) : handleSaveProperty() }} >
            {isSaved() ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
          </IconButton>}
          <Link
        to={
          `/${item.PropertySlug}`
        }
        target={"_blank"}
      >
          {item?.RelatedPptyImages ? <img
              src={item?.RelatedPptyImages}
              className="img-fluid"
              alt="Propety"
            />:<img
            src={"../assets/images/no-image.jpg"}
            className="img-fluid"
            alt="Propety"
          />}
          </Link>
        </div>
        
      }
      <div className="pl-item-details">
      <Link
          to={
             `/${item.PropertySlug}`
          }
          target={"_blank"}
        >
        <h3>
          {item?.AdTitle}
          {/* {item?.AdTitle.charAt(0).toUpperCase() +
            item?.AdTitle.slice(1).toLowerCase()} */}
        </h3>
        <div class="lp-detail">
          <label>
            <img
              src="../assets/images/icon-location-outlined.svg"
              class="img-fluid"
              alt="Icon"
            />
            {item?.Area}
          </label>
          <div class="lp-rooms d-flex align-items-center">
            {item?.LivingArea && (
              <label>
                <img
                  src="../assets/images/icon-sqft.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.LivingArea}
              </label>
            )}
            {item?.ListBedRoom && (
              <label>
                <img
                  src="../assets/images/icon-bed.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBedRoom}
              </label>
            )}
            {item?.ListBathRoom && (
              <label>
                <img
                  src="../assets/images/icon-bath.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBathRoom}
              </label>
            )}
          </div>
        </div>
        </Link>
      </div>
      <div className="pl-item-details pp-availability">
        <ul className="al-style propertyStyleTag">
          {item?.ListPool && <li>{item?.ListPool}</li>}
          {item?.ListHeat && <li>{item?.ListHeat}</li>}
          {item?.ListWater && <li>{item?.ListWater}</li>}
          {item?.ListAirCondition && <li>{item?.ListAirCondition}</li>}
          {item?.IsSouthOfHighway === true ? <li>South Of Highway</li> : null}
          {item?.IsJacuzziSpa === true ? <li>Jacuzzi Spa</li> : null}
          {item?.IsFurnished === true ? <li>Furnished</li> : null}
        </ul>
      </div>
    </div>
  );
}