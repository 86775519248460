import { makeVar } from '@apollo/client';
export const authVar = makeVar({
    loaded:false,
    authenticated:false,
    userType:null,
    
});
export const toggleSnackbar = makeVar({
    status:false,
    message:"",
    variant:""
});
export const userDeatils = makeVar({
    data:{},
})

export const toggleModal=makeVar({
    status:false,
    component:null
})

// export const routeDetails = makeVar({
//     value:"",
// })