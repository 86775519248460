import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ADD_PROPERTY, EDIT_PROPERTY } from "../../../Graghql/mutations";
import {
  LIST_ALL_USERS,
  PROPERTY_DETAILS,
  PROPERTY_DETAILS_FOR_PENDING,
} from "../../../Graghql/queries";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import DateFnsUtils from "@date-io/date-fns";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../../RactiveVariables/index";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  InputLabel,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Collapse from "@material-ui/core/Collapse";
import { useLocation } from "react-router-dom";
import {
  waterfrontOptions,
  tennisOptions,
  styleOptions,
  storyOptions,
  poolOptions,
  petsOptions,
  heatingOptions,
  garageOptions,
  fireplaceOptions,
  ACOptions,
  basementOptions,
  subAreaOptions,
  mainAmenityOptions,
  propertyTypeOptions,
  propertyTypeCommercialOptions,
} from "./options";
import { v4 as uuidv4 } from "uuid";
import Auto from "../../Common/AutoComplete";

const PropertyInformation = (props) => {
  const locations = useLocation();
  //console.log("propssssssss",props)
  const [propertyCommertialvisible, setPropertyCommertialvisible] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [renderdetails, setRenderdetails] = useState(null); //if renter then renterdetails
  const [ownerList, setOwnerList] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [latLong, setLatLong] = useState(null);
  const [zip, setZip] = useState(null);
  const [scrollLimit, setScrollLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  // console.log("set", searchKeyword);
  const [editedField, setEditedField] = useState(null);
  const [editedFieldNames, setEditedfieldNames] = useState([]);
  // const [pid,setPid] = useState("")
  // const [pid2,setPid2] = useState("")
  const user = useReactiveVar(userDeatils);
  //console.log("userrrrdetails-->",user)
  // eslint-disable-next-line no-unused-vars
  const { data: ownersData, loading: ownerLoading } = useQuery(LIST_ALL_USERS, {
    variables: {
      role: "owner",
      skip: 0,
      limit: scrollLimit,
      search: searchKeyword,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr = [];
      res.listAllUsers.forEach((element) => {
        arr.push({
          label: element.FirstName + " " + element.LastName,
          value: element._id,
        });
      });
      // eslint-disable-next-line no-lone-blocks
      {
        renderdetails != null && arr.push(renderdetails);
      }
      // console.log("arrrraaay-->",arr)
      // console.log("detailllsss-->",renderdetails)

      setOwnerList(arr);
    },
  });
  const schema = yup.object().shape({
    OwnerId: yup.string().required("Owner is required"),
    StreetAddress: yup.string().required("Street address is required"),
    City: yup.string().required("City is required"),
    State: yup.string().required("State is required"),
    Zip: yup.string().required("Zip is required"),
    Area: yup.string().required("Area is required").nullable(),
    // ListBedRoom: yup.string().required("Owner is Required"),
    // ListBathRoom: yup.string().required("Owner is Required"),
    LotSize: yup
      .string()
      .required("Lot size is required")
      .test("lotSize-validation", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
        else return true;
      })
      // .matches(/^[1-9][0-9]*(\.[0-9]*[1-9])?|0\.[0-9]*[1-9]$/, 'Value must be greater than zero'),
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
        "Value should be greater than zero"
      ),
    LivingArea: yup.string().required("Living area is required"),
    YearBuilt: yup
      .string()
      .required("Year built is required")
      .test("year-validation", "Must be a number", (value) => {
        if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
        else return true;
      })
      .matches(/^[0-9]{4}$/, "Must be 4 characters"),
    // .test("year-validation", "Must be 4 characters", (value) => {
    //   if (value && value.length > 4) return false;
    //   else return true;
    // }),
    //RentalRegisteryId: yup.string().matches(/^[0-9]*$/, 'Must be digits'),
    // BedBathConfiguration: yup.string().matches(/^\d+(\/\d+)*$/, 'Must be digits'),
    AdDescription: yup.string().required("Ad description is required"),
    AdTitle: yup.string().required("Ad title is required"),
  });
  const [addProperty, { loading }] = useMutation(ADD_PROPERTY);
  const [editProperty, { loading: editLoading }] = useMutation(EDIT_PROPERTY);
  const { register, handleSubmit, formState, control, setValue } =
    useForm({
      defaultValues: defaultValue,
      resolver: yupResolver(schema),
    });
  const { errors } = formState;
  const [getPropertyDetails, propertyDetailsData] = useLazyQuery(
    PROPERTY_DETAILS,
    {
      fetchPolicy: "network-only",
      variables: {
        propertyId: props.propertyId,
      },
      onCompleted: (res) => {
        formateDefaultValue(res?.propertyDetails);
        // setPid(res?.propertyDetails?.PID)
        setValue("StreetAddress", res?.propertyDetails?.StreetAddress);
        setValue("IsFurnished", res?.propertyDetails?.IsFurnished);
        setValue("IsPrivate", res?.propertyDetails?.IsPrivate);
        //setValue("PendingFlag",res?.propertyDetails?.PendingFlag)
        // setDefaultValue(res?.propertyDetails);
      },
    }
  );
  // console.log("pdd",propertyDetailsData)
  const [getPropertyPendingDetails, { data: propertyPendingDetailsData }] =
    useLazyQuery(PROPERTY_DETAILS_FOR_PENDING, {
      fetchPolicy: "network-only",
      variables: {
        propertyId: props.propertyId,
      },
      onCompleted: (res) => {
        // setPid2(res?.propertyDtlsForPending?.PID)
        formateDefaultValue(res?.propertyDtlsForPending);
        setValue("StreetAddress", res?.propertyDtlsForPending?.StreetAddress);
        setValue("IsFurnished", res?.propertyDetails?.IsFurnished);
        setValue("IsPrivate", res?.propertyDetails?.IsPrivate);

        // setValue("OwnerId","6137122de051d0ce68e29d91")

        // setDefaultValue(res?.propertyDetails);
      },
    });
  function formateDefaultValue(data1) {
    let data2 = JSON.stringify(data1);
    let data = JSON.parse(data2);
    if (data?.IsDownstairsMaster === true) data["IsDownstairsMaster"] = "true";
    else if (data?.IsDownstairsMaster === false)
      data.IsDownstairsMaster = "false";
    if (data?.IsFurnished === true) data.IsFurnished = "true";
    else if (data?.IsFurnished === false) data.IsFurnished = "false";
    if (data?.IsPrivate === true) data.IsPrivate = "true";
    else if (data?.IsPrivate === false) data.IsPrivate = "false";
    if (data?.IsWirelessWeb === true) data.IsWirelessWeb = "true";
    else if (data?.IsWirelessWeb === false) data.IsWirelessWeb = "false";
    if (data?.IsChildSafetyFence === true) data.IsChildSafetyFence = "true";
    else if (data?.IsChildSafetyFence === false)
      data.IsChildSafetyFence = "false";
    if (data?.IsJacuzziSpa === true) data.IsJacuzziSpa = "true";
    else if (data?.IsJacuzziSpa === false) data.IsJacuzziSpa = "false";
    if (data?.IsSouthOfHighway === true) data.IsSouthOfHighway = "true";
    else if (data?.IsSouthOfHighway === false) data.IsSouthOfHighway = "false";
    if (data?.IsPoolHouseOrArtStudio === true)
      data.IsPoolHouseOrArtStudio = "true";
    else if (data?.IsPoolHouseOrArtStudio === false)
      data.IsPoolHouseOrArtStudio = "false";
    if (data?.OwnerDtls) {
      data.Owner = {
        label: data?.OwnerDtls?.FirstName + " " + data?.OwnerDtls?.LastName,
        value: data?.OwnerId,
      };
    }

    if (data?.RRExp) {
      data.RRExp.length > 13
        ? setSelectedDate(data.RRExp)
        : setSelectedDate(new Date(parseInt(data?.RRExp)));
    }
    if (data?.ListMainAmenity) {
      let obj = {};
      data.ListMainAmenity.forEach((element) => {
        obj[element.Title] = element?.Flag;
      });
      data.ListMainAmenity = obj;
    }
    setDefaultValue(data);
  }

  function checkForChanges(field, value, type, fieldName) {
    // console.log("fielddd", field, fieldName);

    if (Object.keys(defaultValue).length !== 0) {
      let arr = [...editedFieldNames];
      let arr1 = [];
      arr.push(...arr1, field);
      // arr.filter((item,
      //   index) => arr.indexOf(item) === index);
      var unique = [];

      arr.forEach((element) => {
        if (!unique.includes(element)) {
          unique.push(element);
        }
      });
      setEditedfieldNames(unique);
      // console.log("arrrrrrr", editedFieldNames, unique);
      // console.log(
      //   "Object.keys(defaultValue).length",
      //   Object.keys(defaultValue).length,
      //   editedField,
      //   defaultValue[field],
      //   field,
      //   value,
      //   type
      // );
      // if (Object.keys(defaultValue).length !== 0) {
      let obj = { ...editedField };

      if (type === "Object") {
        let subObject = obj[field] ? obj[field] : {};
        if (
          defaultValue[field] &&
          (defaultValue[field][fieldName] ||
            defaultValue[field][fieldName] == null)
        ) {
          if (defaultValue[field][fieldName] !== value) {
            subObject[fieldName] = defaultValue[field][fieldName];
          } else if (subObject[fieldName] || subObject[fieldName] === null) {
            delete subObject[fieldName];
          }
          obj[field] = subObject;
        }
      } else {
        if (defaultValue[field]) {
          if (defaultValue[field] !== value) {
            //console.log("objjkkk11111",obj[field])

            obj[field] = defaultValue[field];
            // if(defaultValue[field] === null){ obj[field] = defaultValue[field];}

            if (field === "OwnerId") {
              let pos = ownerList?.findIndex(
                (element) => element?.value === defaultValue[field]
              );
              if (pos > -1) {
                obj["OwnerName"] = ownerList[pos]?.label;
              }
              // obj["OwnerName"]= value
            }
          } else if (obj[field]) {
            delete obj[field];
          }
        } else if (defaultValue[field] === null) {
          obj[field] = defaultValue[field];
        }
      }
      setEditedField(obj);
    }
  }
  // console.log("defaultValue", defaultValue);
  useEffect(() => {
    if (
      props.pathnameCheck === false &&
      user?.data?.Role !== "owner" &&
      (locations?.state?.pendingFlagCheck === false ||
        locations?.state?.pendingFlagCheck === null ||
        locations?.state?.pendingFlagCheck === undefined)
    ) {
      if (props.propertyId) {
        getPropertyDetails();
      } else {
        if (user?.data?.Role === "renter" || user?.data?.Role === "owner") {
          setDefaultValue({
            Owner: {
              label: user?.data?.FirstName + " " + user?.data?.LastName,
              value: user?.data?._id,
            },
            OwnerId: user?.data?._id,
          });
        } else setDefaultValue({});
      }
    } else {
      if (props.propertyId) {
        //alert("hiii")
        getPropertyPendingDetails();
      } else {
        if (user?.data?.Role === "renter" || user?.data?.Role === "owner") {
          setDefaultValue({
            Owner: {
              label: user?.data?.FirstName + " " + user?.data?.LastName,
              value: user?.data?._id,
            },
            OwnerId: user?.data?._id,
          });
        } else setDefaultValue({});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let details= null;
  useEffect(() => {
    if (user?.data?.Role === "renter") {
      setRenderdetails({
        label: user?.data?.FirstName + " " + user?.data?.LastName,
        value: user?.data?._id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdown = (event) => {
    setScrollLimit(scrollLimit + 10);
    setTimeout(() => {
      setScrollLimit(scrollLimit + 10);
    }, 500);
  };
  // const setRRExp = register("RRExp");
  function onSubmit(params) {
    // console.log(".......>>>", params);

    params["PID"] = propertyPendingDetailsData?.propertyDtlsForPending?.PID
      ? propertyPendingDetailsData?.propertyDtlsForPending?.PID
      : propertyDetailsData?.propertyDetails?.PID;
    let amenityFlag = false;
    // eslint-disable-next-line array-callback-return
    Object.keys(params.ListMainAmenity).map((key) => {
      if (params.ListMainAmenity[key] === true) amenityFlag = true;
    });
    if (!amenityFlag) {
      toggleSnackbar({
        status: true,
        message: "Select atleast one Amenity",
        variant: "error",
      });
    } else {
      params.UniqueCode = uuidv4();
      if (latLong) {
        params.Latitude = latLong?.lat.toString();
        params.Longitude = latLong?.lng.toString();
      }
      // -----------------------------------------------------
      if (
        params.IsDownstairsMaster === "true" ||
        params.IsDownstairsMaster === true
      )
        params.IsDownstairsMaster = true;
      else params.IsDownstairsMaster = false;
      if (params.IsFurnished === "true" || params.IsFurnished === true)
        params.IsFurnished = true;
      else params.IsFurnished = false;
      if (params.IsPrivate === "true" || params.IsPrivate === true)
        params.IsPrivate = true;
      else params.IsPrivate = false;
      if (params.IsWirelessWeb === "true") params.IsWirelessWeb = true;
      else params.IsWirelessWeb = false;
      if (params.IsChildSafetyFence === "true")
        params.IsChildSafetyFence = true;
      else params.IsChildSafetyFence = false;
      if (params.IsJacuzziSpa === "true") params.IsJacuzziSpa = true;
      else params.IsJacuzziSpa = false;
      if (params.IsSouthOfHighway === "true") params.IsSouthOfHighway = true;
      else params.IsSouthOfHighway = false;
      if (params.IsPoolHouseOrArtStudio === "true")
        params.IsPoolHouseOrArtStudio = true;
      else params.IsPoolHouseOrArtStudio = false;

      // -----------------------------------------------------
      if (selectedDate) params["RRExp"] = selectedDate;
      // eslint-disable-next-line no-self-assign
      if (params.ListBedRoom) params.ListBedRoom = params.ListBedRoom;
      // eslint-disable-next-line no-self-assign
      if (params.ListBathRoom) params.ListBathRoom = params.ListBathRoom;
      if (params.ListMainAmenity) {
        let amenityArray = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(params.ListMainAmenity).map((key) => {
          amenityArray.push({ Title: key, Flag: params.ListMainAmenity[key] });
        });
        params.ListMainAmenity = amenityArray;
      }
      // if (Object.keys(defaultValue).length === 0) {
      if (!props.propertyId) {
        try {
          addProperty({ variables: params })
            .then((res) => {
              props.setPropertyId(res?.data?.addProperty?.PropertyId);
              toggleSnackbar({
                status: true,
                message: "Saved Succesfully",
                variant: "success",
              });
              props.handleNext(2, 2);
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        // params._id = props.propertyId;
        // console.log("propertyDetailsData?.propertyDetails?.PID",propertyDetailsData?.data?.propertyDetails?.PID)
        params.PID = propertyDetailsData?.data?.propertyDetails?.PID;
        params.PropertyId = props.propertyId;
        params.PropertyEditedFields = formatEditedField(editedField);
        params.EditedKeys = formatEditedField(editedFieldNames);
        props.pathnameCheck === true &&
        (!user?.data?.Role === "admin" || !user?.data?.Role === "agentAdmin")
          ? (params["Type"] = "Pending")
          : (params["Type"] = null);
        // props.pathnameCheck === true? params.PID = pid2 :params.PID = pid

        params.RRExp = params.RRExp
          ? params.RRExp
          : propertyDetailsData?.data?.propertyDetails?.RRExp
          ? propertyDetailsData?.data?.propertyDetails?.RRExp
          : propertyPendingDetailsData?.propertyDtlsForPending?.RRExp;
        params.ListStyle = params.ListStyle
          ? params.ListStyle
          : propertyDetailsData?.data?.propertyDetails?.ListStyle
          ? propertyDetailsData?.data?.propertyDetails?.ListStyle
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListStyle;
        params.ListBathRoom = params.ListBathRoom
          ? params.ListBathRoom
          : propertyDetailsData?.data?.propertyDetails?.ListBathRoom
          ? propertyDetailsData?.data?.propertyDetails?.ListBathRoom
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListBathRoom;
        params.ListBedRoom = params.ListBedRoom
          ? params.ListBedRoom
          : propertyDetailsData?.data?.propertyDetails?.ListBedRoom
          ? propertyDetailsData?.data?.propertyDetails?.ListBedRoom
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListBedRoom;
        params.ListFireplace = params.ListFireplace
          ? params.ListFireplace
          : propertyDetailsData?.data?.propertyDetails?.ListFireplace
          ? propertyDetailsData?.data?.propertyDetails?.ListFireplace
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListFireplace;
        params.ListStory = params.ListStory
          ? params.ListStory
          : propertyDetailsData?.data?.propertyDetails?.ListStory
          ? propertyDetailsData?.data?.propertyDetails?.ListStory
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListStory;
        params.ListHeat = params.ListHeat
          ? params.ListHeat
          : propertyDetailsData?.data?.propertyDetails?.ListHeat
          ? propertyDetailsData?.data?.propertyDetails?.ListHeat
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListHeat;
        params.ListPet = params.ListPet
          ? params.ListPet
          : propertyDetailsData?.data?.propertyDetails?.ListPet
          ? propertyDetailsData?.data?.propertyDetails?.ListPet
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListPet;
        params.ListBasement = params.ListBasement
          ? params.ListBasement
          : propertyDetailsData?.data?.propertyDetails?.ListBasement
          ? propertyDetailsData?.data?.propertyDetails?.ListBasement
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListBasement;
        params.ListGarage = params.ListGarage
          ? params.ListGarage
          : propertyDetailsData?.data?.propertyDetails?.ListGarage
          ? propertyDetailsData?.data?.propertyDetails?.ListGarage
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListGarage;
        params.ListAirCondition = params.ListAirCondition
          ? params.ListAirCondition
          : propertyDetailsData?.data?.propertyDetails?.ListAirCondition
          ? propertyDetailsData?.data?.propertyDetails?.ListAirCondition
          : propertyPendingDetailsData?.propertyDtlsForPending
              ?.ListAirCondition;
        params.ListPool = params.ListPool
          ? params.ListPool
          : propertyDetailsData?.data?.propertyDetails?.ListPool
          ? propertyDetailsData?.data?.propertyDetails?.ListPool
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListPool;
        params.ListTennis = params.ListTennis
          ? params.ListTennis
          : propertyDetailsData?.data?.propertyDetails?.ListTennis
          ? propertyDetailsData?.data?.propertyDetails?.ListTennis
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListTennis;
        params.ListWater = params.ListWater
          ? params.ListWater
          : propertyDetailsData?.data?.propertyDetails?.ListWater
          ? propertyDetailsData?.data?.propertyDetails?.ListWater
          : propertyPendingDetailsData?.propertyDtlsForPending?.ListWater;
        params.PropertyType = params.PropertyType
          ? params.PropertyType
          : propertyDetailsData?.data?.propertyDetails?.PropertyType
          ? propertyDetailsData?.data?.propertyDetails?.PropertyType
          : propertyPendingDetailsData?.propertyDtlsForPending?.PropertyType;
        params.Latitude = propertyDetailsData?.data?.propertyDetails?.Latitude
          ? propertyDetailsData?.data?.propertyDetails?.Latitude
          : propertyPendingDetailsData?.propertyDtlsForPending?.Latitude;
        params.Longitude = propertyDetailsData?.data?.propertyDetails?.Longitude
          ? propertyDetailsData?.data?.propertyDetails?.Longitude
          : propertyPendingDetailsData?.propertyDtlsForPending?.Longitude;
        if (params.PropertyEditedFields === null) props.handleNext(2);
        if (params.PropertyEditedFields !== null) {
          try {
            editProperty({ variables: params })
              .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Saved Succesfully",
                  variant: "success",
                });
                props.handleNext(2);
              })
              .catch((error) => {
                const message = error && error.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });
          } catch (error) {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        }
      }
    }
  }
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  function formatEditedField(value) {
    let array = [];
    if (value?.ListMainAmenity) {
      // eslint-disable-next-line array-callback-return
      Object.keys(value?.ListMainAmenity).map((key) => {
        let obj = {};
        obj["Title"] = key;
        obj["Flag"] =
          value.ListMainAmenity[key] === null
            ? false
            : value.ListMainAmenity[key];
        array.push(obj);
      });
      value.ListMainAmenity = array;
    }
    if (value?.IsPrivate === "true") value.IsPrivate = true;
    else if (value?.IsPrivate === "false") value.IsPrivate = false;
    if (value?.IsDownstairsMaster === "true") value.IsDownstairsMaster = true;
    else if (value?.IsDownstairsMaster === "false")
      value.IsDownstairsMaster = false;
    if (value?.IsFurnished === "true") value.IsFurnished = true;
    else if (value?.IsFurnished === "false") value.IsFurnished = false;
    // else if(value?.StreetAddress) return value.StreetAddress= params?.StreetAddress
    // else if(value?.City)return value.City= params?.City
    // else if(value?.State)return value.State= params?.State
    return value;
  }

  function handlePlaceSelect(params) {
    // console.log(
    //   "paramaaaaaa",
    //   params?.terms?.[params?.terms.length - 3]?.value
    // );
    checkForChanges("City", params?.terms?.[params?.terms.length - 3]?.value);
    //arr.push(checkForChanges("State", params?.terms?.[params?.terms.length-2]?.value))

    //checkForChanges("State", params?.terms?.[params?.terms.length-2]?.value)
    setCity(params?.terms?.[params?.terms.length - 3]?.value);
    setState(params?.terms?.[params?.terms.length - 2]?.value);
    setZip(params?.zip);
    setValue("City", params?.terms?.[params?.terms.length - 3]?.value);
    setValue("State", params?.terms?.[params?.terms.length - 2]?.value);
    setValue("Zip", params?.zip);
    setValue("StreetAddress", params?.terms?.[params?.terms.length - 4]?.value);
    setValue("Area", null);
    //setValue("StreetAddress",params?.description)
    //setValue("StreetAddress",params?.description?.split(",").slice(0,params?.description?.length-3).join())
    setLatLong(params.location);
  }
  const registerCity = register("City");
  const registerState = register("State");
  const registerZip = register("Zip");
  const registerStreet = register("StreetAddress");
  // console.log("editedCheck", editedCheck);
  // console.log("defaultValue------>", defaultValue);
  return (
    <div className="add-property-form-wrap">
      {defaultValue ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="add-property-form-step">
            <div className="ownership-address">
              <h5>Ownership & Address</h5>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    Owner<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  {/* <TextField
                    variant="outlined"
                    className="formTextBox"
                    {...register("PropertyOwnerId")}
                  /> */}
                  <Controller
                    control={control}
                    name="OwnerId"
                    defaultValue={defaultValue.OwnerId}
                    render={({ field: { onChange, value } }) => (
                      <div className="formTextBoxOuter">
                        <Autocomplete
                          id="owner"
                          options={ownerList ?? []}
                          inputValue={searchKeyword}
                          getOptionLabel={(ownerList) => ownerList.label}
                          onChange={(e, value) => {
                            onChange(value?.value);
                            checkForChanges("OwnerId", value?.label);
                          }}
                          defaultValue={defaultValue?.Owner}
                          disabled={
                            user?.data.Role === "owner" ||
                            user?.data.Role === "renter"
                          }
                          onInputChange={(e, value) => {
                            setSearchKeyword(value);
                          }}
                          ListboxProps={{
                            onScroll: (event) => {
                              const listboxNode = event.target;
                              if (
                                Math.floor(
                                  listboxNode.scrollTop +
                                    listboxNode.clientHeight
                                ) +
                                  1 ===
                                listboxNode.scrollHeight
                              ) {
                                setTimeout(() => {
                                  handleDropdown(event);
                                }, listboxNode.scrollTop + 100);
                              }
                            },
                          }}
                          renderOption={(option) => (
                            <>{option && option.label}</>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              className="formTextBox form"
                              // {...register("OwnerId")}
                              error={errors.OwnerId}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {ownerLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={10}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                        {errors.OwnerId && (
                          <p className="errorMessage">
                            {errors.OwnerId.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className={"col-md-6"}>
                  <InputLabel className="form-label">
                    Street Address<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    {/* <TextField
                        variant="outlined"
                        
                        {...register("StreetAddress")}
                        defaultValue={defaultValue?.StreetAddress}
                        error={errors.StreetAddress}
                      /> */}
                    <Auto
                      variant="outlined"
                      className="formTextBox mb-2 mb-md-0"
                      //defaultValue={defaultValue?.StreetAddress?.split(",").slice(0,defaultValue?.StreetAddress?.length-3).join()}
                      defaultValue={defaultValue?.StreetAddress}
                      error={errors.StreetAddress}
                      handlePlaceSelect={handlePlaceSelect}
                      setValue={setValue}
                      register={register}
                      {...registerStreet}
                      checkForChanges={checkForChanges}
                      //setInputValue={(e=>checkForChanges("StreetAddress",e.target.value))}
                      //onInputChange={(e)=>checkForChanges("StreetAddress",e.target.value)}
                    />
                    {errors.StreetAddress && (
                      <p className="errorMessage streetAddress-errorMessage">
                        {errors.StreetAddress.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <InputLabel className="form-label">
                    City<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    <TextField
                      // select
                      defaultValue={defaultValue.City}
                      value={city}
                      {...registerCity}
                      variant="outlined"
                      className="formTextBox w-100"
                      error={errors.City}
                      onChange={(e) => {
                        registerCity.onChange(e);
                        setCity(e.target.value);
                        checkForChanges("City", city);
                      }}
                      onBlur={(e) => checkForChanges("City", e.target.value)}
                    >
                      {/* {city.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))} */}
                    </TextField>
                    {errors.City && (
                      <p className="errorMessage">{errors.City.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <InputLabel className="form-label">
                    State<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    <TextField
                      // select
                      value={state}
                      defaultValue={defaultValue.State}
                      {...registerState}
                      variant="outlined"
                      className="formTextBox w-100"
                      error={errors.State}
                      onChange={(e) => {
                        registerState.onChange(e);
                        setState(e.target.value);
                        //checkForChanges( e.target.value)
                      }}
                      onBlur={(e) => checkForChanges("State", e.target.value)}
                    >
                      {/* {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))} */}
                    </TextField>
                    {errors.State && (
                      <p className="errorMessage">{errors.State.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <InputLabel className="form-label">
                    Area<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    <Controller
                      control={control}
                      name="Area"
                      defaultValue={defaultValue.Area}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          select
                          variant="outlined"
                          value={value}
                          className="formTextBox w-100"
                          error={errors.Area}
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={(e) =>
                            checkForChanges("Area", e.target.value)
                          }
                        >
                          {subAreaOptions.sort(sortLabels).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.Area && (
                      <p className="errorMessage">{errors.Area.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <InputLabel className="form-label">
                    Zip<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    <TextField
                      value={zip}
                      defaultValue={defaultValue.Zip}
                      {...registerZip}
                      variant="outlined"
                      className="formTextBox w-100"
                      error={errors.Zip}
                      onChange={(e) => {
                        registerZip.onChange(e);
                        setZip(e.target.value);
                      }}
                      onBlur={(e) => checkForChanges("Zip", e.target.value)}
                      inputProps={{ maxLength: 5 }}
                    ></TextField>
                    {errors.Zip && (
                      <p className="errorMessage">{errors.Zip.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-6">
                  <InputLabel className="form-label">
                    Rental Registery ID
                  </InputLabel>
                  <div className="formTextBoxOuter">
                    <TextField
                      defaultValue={defaultValue["RentalRegisteryId"]}
                      {...register("RentalRegisteryId")}
                      variant="outlined"
                      className="formTextBox w-100"
                      onBlur={(e) =>
                        checkForChanges("RentalRegisteryId", e.target.value)
                      }
                    ></TextField>
                    {errors.RentalRegisteryId && (
                      <p className="errorMessage">
                        {errors.RentalRegisteryId.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-md-6">
                  <InputLabel className="form-label">RR# Expiry</InputLabel>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="formTextBox date-box w-100"
                      variant="outlined"
                      //  {...setRRExp}
                      format="MM/dd/yyyy"
                      value={selectedDate}
                      onChange={(e) => {
                        // setRRExp.onChange(e);
                        setSelectedDate(e);
                        checkForChanges("RRExp", e);
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {/* <TextField
                    select
                    defaultValue={defaultValue["RRExp"]}
                    {...register("RRExp")}
                    variant="outlined"
                    className="formTextBox w-100"
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> */}
                </div>
              </div>
            </div>
            <div className="features">
              <h5 className="mt-2">Features</h5>
              <div className="feature-item">
                <div className="row">
                  <div className="col-6 col-md-6">
                    <InputLabel className="form-label">
                      Lot Size (Acres)<sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <div className="formTextBoxOuter">
                      <TextField
                        variant="outlined"
                        className="formTextBox"
                        {...register("LotSize")}
                        defaultValue={defaultValue?.LotSize}
                        error={errors.LotSize}
                        onBlur={(e) =>
                          checkForChanges("LotSize", e.target.value)
                        }
                        // inputProps={{type:'number'}}
                      />
                      {errors.LotSize && (
                        <p className="errorMessage">{errors.LotSize.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <InputLabel className="form-label">
                      Year Built<sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <div className="formTextBoxOuter">
                      <TextField
                        variant="outlined"
                        className="formTextBox"
                        {...register("YearBuilt")}
                        defaultValue={defaultValue?.YearBuilt}
                        error={errors.YearBuilt}
                        onBlur={(e) =>
                          checkForChanges("YearBuilt", e.target.value)
                        }
                        inputProps={{ maxLength: 4 }}
                      />
                      {errors.YearBuilt && (
                        <p className="errorMessage">
                          {errors.YearBuilt.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Style</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListStyle"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          defaultValue={defaultValue?.ListStyle}
                          className="feature-item-radio"
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={(e) =>
                            checkForChanges("ListStyle", e.target.value)
                          }
                        >
                          <div className="row m-0">
                            {styleOptions.sort(sortLabels).map((item) => (
                              <div className="col-6 p-0">
                                <FormControlLabel
                                  value={item.value}
                                  control={<Radio color="primary" />}
                                  label={item.label}
                                />
                              </div>
                            ))}
                          </div>
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item  feature-slider">
                <div className="row">
                  <div className="col-3 col-md-4">
                    <label className="feature-item-label">Bedrooms</label>
                  </div>
                  <div className="col-9 col-md-8">
                    <Controller
                      control={control}
                      name="ListBedRoom"
                      render={({ field: { onChange, value } }) => (
                        // <RadioGroup
                        //   defaultValue={defaultValue?.ListBedRoom}
                        //   className="feature-item-radio"
                        //   onChange={(e) => onChange(e.target.value)}
                        // >
                        //   {bedRoomsOptions.map((item) => (
                        //     <FormControlLabel
                        //       value={item.value}
                        //       control={<Radio color="primary" />}
                        //       label={item.label}
                        //     />
                        //   ))}
                        // </RadioGroup>
                        <Slider
                          defaultValue={
                            defaultValue?.ListBedRoom
                              ? defaultValue?.ListBedRoom
                              : 0
                          }
                          // getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider"
                          step={1}
                          marks
                          min={0}
                          max={12}
                          // marks={bedRoomsOptions}
                          onChange={(e, value) => {
                            onChange(value);
                            checkForChanges("ListBedRoom", value);
                          }}
                          valueLabelDisplay="on"
                          valueLabelFormat={(x) => {
                            if (x === 12) return "12+";
                            else return x;
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item feature-slider">
                <div className="row">
                  <div className="col-3 col-md-4">
                    <label className="feature-item-label">Baths</label>
                  </div>
                  <div className="col-9 col-md-8">
                    <Controller
                      control={control}
                      name="ListBathRoom"
                      render={({ field: { onChange, value } }) => (
                        // <RadioGroup
                        //   className="feature-item-radio"
                        //   defaultValue={defaultValue?.ListBathRoom}
                        //   onChange={(e) => onChange(e.target.value)}
                        // >
                        //   {bathroomOptions.map((item) => (
                        //     <FormControlLabel
                        //       value={item.value}
                        //       control={<Radio color="primary" />}
                        //       label={item.label}
                        //     />
                        //   ))}
                        // </RadioGroup>
                        <Slider
                          defaultValue={
                            defaultValue?.ListBathRoom
                              ? defaultValue?.ListBathRoom
                              : 0
                          }
                          // getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider"
                          step={0.5}
                          marks
                          min={0}
                          max={12}
                          // marks={bathroomOptions}
                          onChange={(e, value) => {
                            onChange(value);
                            checkForChanges("ListBathRoom", value);
                          }}
                          valueLabelDisplay="on"
                          valueLabelFormat={(x) => {
                            if (x === 12) return "12+";
                            else return x;
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-6">
                    <InputLabel className="form-label">
                      Bedroom/Bed Configuration
                    </InputLabel>
                    <div className="formTextBoxOuter">
                      <TextField
                        variant="outlined"
                        className="formTextBox"
                        placeholder="MBR/King-Guest 1 Queen..."
                        defaultValue={defaultValue?.BedBathConfiguration}
                        FormControlLabel
                        {...register("BedBathConfiguration")}
                        onBlur={(e) =>
                          checkForChanges(
                            "BedBathConfiguration",
                            e.target.value
                          )
                        }
                      />
                      {errors.BedBathConfiguration && (
                        <p className="errorMessage">
                          {errors.BedBathConfiguration.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <InputLabel className="form-label">
                      Living Area (Approx Sq FT)
                      <sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <div className="formTextBoxOuter">
                      <TextField
                        variant="outlined"
                        className="formTextBox"
                        defaultValue={defaultValue?.LivingArea}
                        FormControlLabel
                        {...register("LivingArea")}
                        error={errors.LivingArea}
                        onBlur={(e) =>
                          checkForChanges("LivingArea", e.target.value)
                        }
                      />
                      {errors.LivingArea && (
                        <p className="errorMessage">
                          {errors.LivingArea.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Fireplaces</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListFireplace"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListFireplace}
                          // {...register("ListFireplace")}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListFireplace", e.target.value);
                          }}
                        >
                          {fireplaceOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Stories</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListStory"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListStory}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListStory", e.target.value);
                          }}
                        >
                          {storyOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Heating</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListHeat"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListHeat}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListHeat", e.target.value);
                          }}
                        >
                          {heatingOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">
                      Master Bedroom on Ground Floor?
                    </label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="IsDownstairsMaster"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.IsDownstairsMaster}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges(
                              "IsDownstairsMaster",
                              e.target.value
                            );
                          }}
                        >
                          <FormControlLabel
                            value={"true"}
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Pets</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListPet"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListPet}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListPet", e.target.value);
                          }}
                        >
                          {petsOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Basement</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListBasement"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListBasement}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListBasement", e.target.value);
                          }}
                        >
                          {basementOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Garage Type</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListGarage"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListGarage}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListGarage", e.target.value);
                          }}
                        >
                          {garageOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">A/C</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListAirCondition"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListAirCondition}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListAirCondition", e.target.value);
                          }}
                        >
                          {ACOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Pool</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListPool"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListPool}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListPool", e.target.value);
                          }}
                        >
                          {poolOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Tennis</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListTennis"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListTennis}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListTennis", e.target.value);
                          }}
                        >
                          {tennisOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Waterfront</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="ListWater"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.ListWater}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("ListWater", e.target.value);
                          }}
                        >
                          {waterfrontOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">Lease Term</label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="ListLeaseTerm"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.ListLeaseTerm}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {leaseTermOptions.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div> */}
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">
                      Main Amenity<sup style={{ color: "red" }}>*</sup>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="row m-0">
                      {mainAmenityOptions.sort(sortLabels).map((item) => (
                        <Controller
                          control={control}
                          name={"ListMainAmenity." + item.label}
                          //error={errors.ListMainAmenity}
                          defaultValue={
                            defaultValue?.ListMainAmenity?.[item.label]
                          }
                          render={({ field: { onChange, value } }) => (
                            <div className="col-md-6 p-0">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked={
                                      defaultValue?.ListMainAmenity?.[
                                        item.label
                                      ]
                                    }
                                    color="primary"
                                    onChange={(e, value) => {
                                      onChange(value);
                                      checkForChanges(
                                        "ListMainAmenity",
                                        value,
                                        "Object",
                                        item.label
                                      );
                                    }}
                                    // checked={jason}
                                  />
                                }
                                label={item.label}
                              />
                            </div>
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">
                        Nature Of Party
                      </label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="ListNatureOfParty"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.ListNatureOfParty}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {natureOfPartyOptions.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div> */}
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Property Type</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="PropertyType"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.PropertyType}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("PropertyType", e.target.value);
                            if (e.target.value === "Commercial")
                              setPropertyCommertialvisible(true);
                            else if (propertyCommertialvisible)
                              setPropertyCommertialvisible(false);
                          }}
                        >
                          {propertyTypeOptions.map((item) => (
                            <FormControlLabel
                              value={item.value}
                              control={<Radio color="primary" />}
                              label={item.label}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={propertyCommertialvisible}>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="feature-item-label">
                        Property Type Commercial
                      </label>
                    </div>
                    <div className="col-md-8">
                      <Controller
                        control={control}
                        name="ListPropertyTypeCommercial"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={
                              defaultValue?.ListPropertyTypeCommercial
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              checkForChanges(
                                "ListPropertyTypeCommercial",
                                e.target.value
                              );
                            }}
                          >
                            {propertyTypeCommercialOptions.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
              {/* <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">Time Frame</label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="ListTimeFrame"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.ListTimeFrame}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {timeFrameOptions.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">Key</label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="ListKey"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.ListKey}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            {listKeyOptions.map((item) => (
                              <FormControlLabel
                                value={item.value}
                                control={<Radio color="primary" />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div> */}
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">Furnished</label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="IsFurnished"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsFurnished}
                            onChange={(e) => {
                              onChange(e.target.value);
                              checkForChanges("IsFurnished", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="feature-item">
                <div className="row">
                  <div className="col-md-4">
                    <label className="feature-item-label">
                      Not Available for Public View, Access to Agents Only
                    </label>
                  </div>
                  <div className="col-md-8">
                    <Controller
                      control={control}
                      name="IsPrivate"
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          className="feature-item-radio"
                          defaultValue={defaultValue?.IsPrivate}
                          onChange={(e) => {
                            onChange(e.target.value);
                            checkForChanges("IsPrivate", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value={"true"}
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">Wireless Web</label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="IsWirelessWeb"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsWirelessWeb}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">
                        Child Safety Fence
                      </label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="IsChildSafetyFence"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsChildSafetyFence}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">
                        Village Location
                      </label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="VillageLocation"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.VillageLocation}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">Jacuzzi Spa</label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="IsJacuzziSpa"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsJacuzziSpa}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">
                        South of Highway
                      </label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="IsSouthOfHighway"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsSouthOfHighway}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="row">
                    <div className="col-4">
                      <label className="feature-item-label">
                        Pool House/ Art Studio{" "}
                      </label>
                    </div>
                    <div className="col-8">
                      <Controller
                        control={control}
                        name="IsPoolHouseOrArtStudio"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            className="feature-item-radio"
                            defaultValue={defaultValue?.IsPoolHouseOrArtStudio}
                            onChange={(e) => onChange(e.target.value)}
                          >
                            <FormControlLabel
                              value={"true"}
                              control={<Radio color="primary" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          <div className="add-property-form-step grey-bg">
            <h5>Property Description</h5>
            <div>
              <InputLabel className="form-label">
                Your Ad's Caption<sup style={{ color: "red" }}>*</sup>
              </InputLabel>
              <div className="formTextBoxOuter">
                <TextField
                  variant="outlined"
                  placeholder="50 Characters Maximum"
                  className="formTextBox"
                  {...register("AdTitle")}
                  error={errors.AdTitle}
                  defaultValue={defaultValue?.AdTitle}
                  onBlur={(e) => checkForChanges("AdTitle", e.target.value)}
                  //inputProps={{maxLength: 50}}
                />
                {errors.AdTitle && (
                  <p className="errorMessage">{errors.AdTitle.message}</p>
                )}
              </div>
            </div>
            <div>
              <InputLabel className="form-label">
                Ad Copy / Description<sup style={{ color: "red" }}>*</sup>
              </InputLabel>
              <div className="formTextBoxOuter">
                <TextField
                  multiline
                  rows="8"
                  variant="outlined"
                  placeholder="Describe your property to the public. 100 words maximum"
                  className="formTextBox w-100"
                  style={{ backgroundColor: "#fff" }}
                  {...register("AdDescription")}
                  error={errors.AdDescription}
                  defaultValue={defaultValue?.AdDescription}
                  onBlur={(e) =>
                    checkForChanges("AdDescription", e.target.value)
                  }
                  //inputProps={{maxLength: 100 }}
                />
                {errors.AdDescription && (
                  <p className="errorMessage">{errors.AdDescription.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="add-property-form-step add-property-btn">
            <Button
              className="outline-btn"
              variant="outline"
              onClick={props.handleCancel}
            >
              Cancel
            </Button>
            <div>
              <Button
                disabled={loading}
                id="property-save-btn"
                type="submit"
                color="primary"
                className="highlight-btn"
                variant="contained"
              >
                {loading || editLoading ? (
                  <CircularProgress size="20px" color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
};
export default PropertyInformation;
