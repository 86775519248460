import React,{useEffect, useState} from "react";
import "./AddAgent.css";
import { useForm } from "react-hook-form";
import InputLabel from '@material-ui/core/InputLabel';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Drawer,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import AddImageIcon from "../../assets/AddImageIcon.svg";
import {  useMutation, useLazyQuery } from "@apollo/client";
import { ADD_AGENT,EDIT_AGENT, DELETE_PROFILE_IMAGE } from "../../Graghql/mutations";
import { CHECK_EMAIL } from "../../Graghql/queries";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { LIST_ALL_AGENT, IMAGE_UPLOAD } from "../../Graghql/queries";
import imageCompression from 'browser-image-compression';
import axios from "axios"; 
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CropImage from "../Common/CropImage";
import CropIcon from "@material-ui/icons/Crop";

export default function AddAgent(props) {
  const [uploadImageLoading, setUploadImageLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [cellPh,setCellPh] = React.useState('');
  const [officePh,setOfficePh] = React.useState('');
  const [draftFlag,setDraftFlag] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  //console.log("propsdefaultvalue-->",props.defaultValue)

  const [addAgent, { loading }] = useMutation(ADD_AGENT,{refetchQueries:[{query:LIST_ALL_AGENT}]});
  const [editAgent, { loading:editLoading }] = useMutation(EDIT_AGENT,{refetchQueries:[{query:LIST_ALL_AGENT}]});
  const [deleteProfileImage] = useMutation(DELETE_PROFILE_IMAGE,{
    onCompleted: (res) => props.handlePagination()});
  const [inLead,setInLead] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [imageFile,setImageFile] = useState(null);
  const [fileName,setFileName] = useState("");
  const [, chechEmailData] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: "network-only",
    variables: {
      Email: email,
    },
  });
  const uplaodImageVariables = {
    originalFilename:fileName,
    path:'/userprofile'
  };
  const [upload, {data:uploadImageData}] = useLazyQuery(IMAGE_UPLOAD, {
    // fetchPolicy: "network-only",
    variables: uplaodImageVariables
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    // designation: yup.string().required("Designation is required"),
    primaryEmail: yup.string().required("Primary Email is required").email(),
    confirmEmail: yup
      .string()
      .required("Confirm Email is required")
      .oneOf([yup.ref("primaryEmail"), null], "Primary email and confirm email do not match"),
    cellPhone:yup.string().required("Cell Phone is required").matches(/^\d{3}-\d{3}-\d{4}$/, 'Must be 10 digits'),
    // .test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
    // officePhone:yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Must be 10 digits'),
    // .test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
    //  address1: yup.string().required("Address1 is required"),
    // address2: yup.string().required("Address2 is required"),
    //  city: yup.string().required("City is required"),
    //  state: yup.string().required("State is required"),
    //  zip: yup
    //  .string().required("This field is required")
    //  .test("zip-validation", "Must be a number", (value) => {
    //    if (value && isNaN(value)) return false;
    //    else return true;
    //  })
    //  .test("zip-validation", "Must be less than 5 numbers", (value) => {
    //    if (value && value.length > 5) return false;
    //    else return true;
    //  }),
  });
  const { register,handleSubmit, formState, reset } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  useEffect(()=>{
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.open])
  useEffect(() => {
    setCellPh(props.defaultValue?.Phone);
    setOfficePh(props.defaultValue?.OfficePhone);
    setInLead(props.defaultValue?.InLeadCycle)
    setSelectedFile(props.defaultValue?.ProfileImage)
  }, [props.defaultValue]);
  const { errors } = formState;
  function onSubmit(params) {
    if(imageFile !== null)
      params["ProfileImage"] = uploadImageData?.imageUpload?.publicUrl;
    else
      params["ProfileImage"] = "";
    params.SaveAsDraft = draftFlag;
    if(params.Status!=="active")
      params.Status="inactive";
    if(params.AccessRights!=="yes")
      params.AccessRights="no";
    if (chechEmailData?.data?.checkEmail?.status === true || chechEmailData?.data===undefined) {
      if (props.defaultValue === null) {
      try {
        addAgent({ variables: params })
          .then((res) => {
            reset();
            toggleSnackbar({
              status: true,
              message: "Agent successfully created",
              variant: "success",
            });
            props.handlePagination();
            //props.fetchMoreAllAgents();
            props.setOpen(false);
            props.setDefaultValue(null);
            setImageFile(null);
            setFileName("");
            setSelectedFile(null);
            setCropImage(null);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
    else{
      try {
        params["id"] = props?.defaultValue?._id;
        editAgent({ variables: params })
          .then((res) => {
            reset();
            toggleSnackbar({
              status: true,
              message: "Agent successfully updated",
              variant: "success",
            });
            props.handlePagination();
            //props.fetchMoreAllAgents();
            props.setOpen(false);
            props.setDefaultValue(null);
            setImageFile(null);
            setFileName("");
            setSelectedFile(null);
            setCropImage(null);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
    }  
  }

  function toggleDrawer() {
    reset();
    props.setOpen(!props.open);
  }
  function phoneNumberFormat(num) {
    if (num) {
      // let newNum = num.replace(/[-]+/g, "");
      let newNum = num.replace(/[^0-9]/g, '');
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
      } else {
        x =
          newNum.slice(0, 3) +
          "-" +
          newNum.slice(3, 6) +
          "-" +
          newNum.slice(6, 10);
      }
      return x;
    }
  }
  function handleClose() {
    props.setDefaultValue(null);
    setImageFile(null);
    setFileName("");
    toggleDrawer();
    setSelectedFile(null);
    setCropImage(null);
  }

  function handelCropDone(result) {
   setCropImage(result) 
  }
  function handleUploadClick (event) {
    var file = event.target.files[0];
    if (!(file?.type=== "image/jpeg"||file?.type==="image/png")){
           toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
  });
      return false;
   }
   if (file.size> 5242880) {
    toggleSnackbar({
            status: true,
            message: "Please upload a file smaller than 5 MB",
            variant: "error",
      });
     return false;
   }
    setFileName(file.name);
		var options = {
		    maxSizeMB: 1,
		    maxWidthOrHeight: 300,
		    useWebWorker: true
		}
    
		imageCompression(file, options)
    .then((compressedFile) => {
      setSelectedFile(URL.createObjectURL(compressedFile))
      setImageFile(compressedFile);
      upload();
    })
    .catch((err) => {
      //console.log("err: ",err)
    })
  };
  async function uploadImage(params) {
    setUploadImageLoading(true);
    if(fileName!=="" && imageFile!==null){
    let url = uploadImageData?.imageUpload?.signedUrl;
    // eslint-disable-next-line no-unused-vars
    const response = await axios.put(
      url,
       cropImage?cropImage.file:imageFile,
      { headers: { 
        'Content-Type': imageFile?.type,
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Headers": "X-Requested-With"
       } });
      }
      setUploadImageLoading(false);
       onSubmit(params);
  }
  function handleDelete(params) {
    setSelectedFile(null);
    setFileName("");
    setImageFile(null);
    setCropImage(null);
    if (props?.defaultValue?._id)
      deleteProfileImage({
        variables: { agentId: props?.defaultValue?._id },
      });
  }
  const setInLeadCycle = register('InLeadCycle');
  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={handleClose}>
        <div role="presentation" className="addAgent">
          <h1 className={"addAgentHead"}>Add Agent</h1>
          <h5 className="add-sub-head">Primary Contact Information</h5>
            <form onSubmit={handleSubmit(uploadImage)} noValidate>
              <div className="active-radio">
                <h6>Active</h6>
                <RadioGroup
                  name="active"
                  className={"addAgentRadio"}
                  defaultValue={props.defaultValue?.Status?props.defaultValue?.Status:"active"}
                  //   value={value}
                  {...register("Status")}
                  // onChange={(e) => setValue("active", e.target.value)}
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </div>
              <div>
                <h5 className="add-sub-head">Photo</h5>
                <div className="add-photo">
                  <div className="add-photo-img">
                  <CropImage
                     open={openCrop}
                     setOpen={setOpenCrop}
                     image={imageFile}
                    handelCropDone={handelCropDone}
                 />
                    <img alt="agent" src={selectedFile ? cropImage? cropImage.fileUrl:selectedFile : AddImageIcon}/>
                    {imageFile && (
                                  <IconButton
                                    color=""
                                    aria-label="drag"
                                    component="span"
                                    className=""
                                    onClick={() => {
                                      setOpenCrop(true);
                                      ;
                                    }}
                                  >
                                    <CropIcon />
                                  </IconButton>
                                )}
                    {selectedFile && <IconButton
                      
                      aria-label="drag"
                      component="span"
                      className="add-photo-delete"
                      
                      onClick={()=>handleDelete()}
                    >
                      <CloseIcon/>
                    </IconButton>}
                  </div>
                  <Button className="outline-btn" component="label" variant="outlined">
                    Add Photo
                    <input
                      type="file"
                      hidden
                      onChange={handleUploadClick}
                      onClick={(event)=> { 
                             event.target.value = null
                        }}
                    />
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">FIRST NAME<sup>*</sup></InputLabel>
                  <TextField
                    //label="First Name"
                    variant="outlined"
                    defaultValue={props.defaultValue?.FirstName}
                    {...register('firstName')}
                    // onChange={(e) => {setValue("firstName", e.target.value);trigger()}}
                    error={errors.firstName}
                    className={"formTextBox"}
                    autoFocus
                  />
                  {errors && errors?.["firstName"] && (
                    <p className="errorMessage">{errors?.firstName?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">LAST NAME<sup>*</sup></InputLabel>
                  <TextField
                    {...register('lastName')}
                    variant="outlined"
                    defaultValue={props.defaultValue?.LastName}
                    error={errors.lastName}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["lastName"] && (
                    <p className="errorMessage">{errors?.lastName?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">DESIGNATION</InputLabel>
                  <TextField
                  {...register('designation')}
                    variant="outlined"
                    defaultValue={props.defaultValue?.Designation}
                    error={errors.designation}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["designation"] && (
                    <p className="errorMessage">
                      {errors?.designation?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">PRIMARY EMAIL<sup>*</sup></InputLabel>
                  <TextField
                  {...register('primaryEmail')}
                    defaultValue={props.defaultValue?.Email}
                    variant="outlined"
                    onBlur={(e) => {
                      setEmail(e.target.value);
                      // chechEmail();
                    }}
                    error={errors.primaryEmail}
                    className={"formTextBox"}
                  />
                  {((errors && errors?.["primaryEmail"])
                   || chechEmailData?.data?.checkEmail===null) && (
                    <p className="errorMessage">
                      {errors?.primaryEmail?.message ? 
                      errors?.primaryEmail?.message : 
                      chechEmailData?.data?.checkEmail===null ? 
                      "Email Already Exist" 
                      : null}
                    </p>
                //   &&(<p className="errorMessage">
                //   {errors?.primaryEmail?.message}
                // </p>)
                   )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">CONFIRM EMAIL<sup>*</sup></InputLabel>
                  <TextField
                     variant="outlined"
                     {...register('confirmEmail')}
                     defaultValue={props.defaultValue?.Email}
                    error={errors.confirmEmail}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["confirmEmail"] && (
                    <p className="errorMessage">
                      {errors?.confirmEmail?.message}
                    </p>
                  )}
                </div>
                <div className="col-12"><span className="instruction-text">Atleast one phone number is required.</span></div>
                <div className="col-md-6">
                  <InputLabel className="form-label">CELL PHONE<sup>*</sup></InputLabel>
                  <TextField
                    {...register('cellPhone')}
                    variant="outlined"
                    value={cellPh}
                    onChange={(e) => setCellPh(phoneNumberFormat(e.target.value))}
                    error={errors.cellPhone}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["cellPhone"] && (
                    <p className="errorMessage">{errors?.cellPhone?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">OFFICE PHONE</InputLabel>
                  <TextField
                  {...register('officePhone')}
                    variant="outlined"
                    value={officePh}
                    onChange={(e) => setOfficePh(phoneNumberFormat(e.target.value))}
                    error={errors.officePhone}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["officePhone"] && (
                    <p className="errorMessage">
                      {errors?.officePhone?.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <h5 className="add-sub-head">Address</h5>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel className="form-label">ADDRESS1<sup>*</sup></InputLabel>
                    <TextField
                      variant="outlined"
                      {...register('address1')}
                      defaultValue={props.defaultValue?.AddressLine1}
                      error={errors.address1}
                      className={"formTextBox"}
                    />
                    {errors && errors?.["address1"] && (
                      <p className="errorMessage">{errors?.address1?.message}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputLabel className="form-label">ADDRESS2</InputLabel>
                    <TextField
                    {...register('address2')}
                      variant="outlined"
                      defaultValue={props.defaultValue?.AddressLine2}
                      error={errors.address2}
                      className={"formTextBox"}
                    />
                    {errors && errors?.["address2"] && (
                      <p className="errorMessage">{errors?.address2?.message}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputLabel className="form-label">CITY<sup>*</sup></InputLabel>
                    <TextField
                      variant="outlined"
                      {...register('city')}
                      defaultValue={props.defaultValue?.City}
                      error={errors.city}
                      className={"formTextBox"}
                    />
                    {errors && errors?.["city"] && (
                      <p className="errorMessage">{errors?.city?.message}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputLabel className="form-label">STATE<sup>*</sup></InputLabel>
                    <TextField
                    {...register('state')}
                      variant="outlined"
                      defaultValue={props.defaultValue?.State}
                      error={errors.state}
                      className={"formTextBox"}
                    />
                    {errors && errors?.["state"] && (
                      <p className="errorMessage">{errors?.state?.message}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                  <InputLabel className="form-label">ZIP<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.Zip}
                    {...register("zip")}
                    error={errors.zip}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["zip"] && (
                    <p className="errorMessage">{errors?.zip?.message}</p>
                  )}
                </div>
                </div>
              </div>

              <div>
                <h5 className="add-sub-head">Bio/Personal Statement</h5>
                <div>
                    <InputLabel className="form-label">COMMENT</InputLabel>
                    <TextField
                      multiline
                      variant="outlined"
                      {...register('PersonalStatement')}
                      defaultValue={props.defaultValue?.PersonalStatement}
                      // onChange={(e) => setValue("PersonalStatement", e.target.value)}
                      error={errors.PersonalStatement}
                      //inputProps={{maxLength : 100}}
                      className={"w-100 pb-4"}
                    />
                    {errors && errors?.["PersonalStatement"] && (
                      <p className="errorMessage">{errors?.PersonalStatement?.message}</p>
                    )} 
                </div>
              </div>
              <div>
                <h5 className="add-sub-head">Settings</h5>
                <div className="active-radio">
                  <h6>Access Rights</h6>
                  <RadioGroup
                    name="AccessRights"
                    className={"addAgentRadio"}
                    // onChange={(e) => setValue("AccessRights", e.target.value)}                    
                    defaultValue={props.defaultValue?.AccessRights}
                    {...register("AccessRights")}
                    >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Super Admin"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="Agent"
                    />
                  </RadioGroup>
                </div>
                <div className="active-radio">
                  <h6>In Lead Cycle</h6>
                  <Checkbox
                    className="p-0"
                    color="primary"
                    checked={inLead}
                    {...setInLeadCycle}
                    onChange={(e)=>{
                      setInLeadCycle.onChange(e);
                      setInLead(e.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="addAgentButton">
                <Button className="outline-btn" onClick={handleClose}>Cancel</Button>
                <div className="d-flex align-items-center">
                  {/* <Button 
                  className="save-draft-btn"
                  type="submit"
                  onClick={()=>{
                    setDraftFlag(true);
                  }}>
                  {draftFlag && loading ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Save as Draft?"
                    )}
                  </Button> */}
                  <Button
                    type="submit"
                    className="highlight-btn"
                    onClick={()=>{
                      setDraftFlag(false);
                    }}>
                    {!draftFlag && (loading || editLoading || uploadImageLoading) ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </div>
            </form>
        </div>
      </Drawer>
    </div>
  );
}
