import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query currentUserDetails {
    currentUserDetails {
      _id
      FirstName
      LastName
      Email
      Phone
      Status
      Role
      ProfileImage
      UserType
      LeadList
      AddressLine1
      AddressLine2
      City
      HomePhone
      CellPhone
      OfficePhone
      LocalPhone
      ContactPreference
      State
      Zip
      Country
      SecondContactName
      SecondContactPhone
      SecondContactEmail
      CreatedOn
      Agent
    }
  }
`;

export const LIST_AGENT = gql`
  query listAgent($search: String, $skip: Int!, $limit: Int!) {
    listAgent(search: $search, skip: $skip, limit: $limit) {
      data {
        _id
        FirstName
        LastName
        Designation
        Email
        UserName
        Status
        Role
        UserType
        AddressLine1
        AddressLine2
        Country
        State
        CityId
        City
        Zip
        LastNotificationReadTime
        ProfileImage
        InLeadCycle
        AccessRights
        OfficePhone
        Phone
        PersonalStatement
        PropertyCount
      }
      count
    }
  }
`;

export const LIST_RENTER = gql`
  query listRenter(
    $agentId: String
    $search: String
    $skip: Int!
    $limit: Int!
  ) {
    listRenter(agentId: $agentId, search: $search, skip: $skip, limit: $limit) {
      data {
        _id
        FirstName
        LastName
        Email
        Role
        UserType
        AddressLine1
        City
        Phone
        ProfileImage
        Status
        Agent
        RenterActivityDate
        AgentDtls {
          FirstName
          LastName
          ProfileImage
        }
        LeadDtls {
          _id
        }
      }
      count
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkEmail($Email: String!) {
    checkEmail(Email: $Email) {
      status
    }
  }
`;

export const LIST_OWNER = gql`
  query listOwner(
    $agentId: String
    $search: String
    $skip: Int!
    $limit: Int!
  ) {
    listOwner(agentId: $agentId, search: $search, skip: $skip, limit: $limit) {
      data {
        _id
        FirstName
        LastName
        Email
        Role
        UserType
        AddressLine1
        City
        Zip
        Status
        ProfileImage
        HomePhone
        CellPhone
        OfficePhone
        LocalPhone
        ContactPreference
        AddressLine2
        State
        Country
        SecondContactName
        SecondContactPhone
        SecondContactEmail
        Agent
        IsInternetUser
        AgentDtls {
          FirstName
          LastName
          ProfileImage
        }
        OwnerDtls {
          propertyCount
        }
        LeadDtls {
          _id
        }
      }
      count
    }
  }
`;
export const LIST_ALL_AGENT = gql`
  query listAllAgents {
    listAllAgents {
      data {
        _id
        FirstName
        userId
        LastName
        ProfileImage
        Designation
        LastNotificationReadTime
        PropertyCount
        ProfileImage
        LastLeadRvd {
          LastLeadDate
          UserFName
          UserLName
        }
      }
      count
    }
  }
`;

export const LIST_ACTIVE_AGENTS = gql`
  query listActiveAgents(
    $search: String
    $skip: Int!
    $limit: Int!) {
    listActiveAgents(
      search: $search
      skip: $skip
      limit: $limit) {
      data {
        _id
        FirstName
        LastName
      }
      count
    }
  }
`;

export const LIST_FRONTEND_TEAMS = gql`
  query listFrontEndTeams {
    listFrontEndTeams {
      data {
        _id
        FirstName
        LastName
        Phone
        Email
        Designation
        OfficePhone
        ProfileImage
        PersonalStatement
      }
      count
    }
  }
`;

export const FRONTEND_TEAM_DETAIL = gql`
  query agentDetailView($userId: String!) {
    agentDetailView(userId: $userId) {
      FirstName
      LastName
      Phone
      Email
      Designation
      OfficePhone
      ProfileImage
      PersonalStatement
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  query imageUpload($originalFilename: String!, $path: String!) {
    imageUpload(originalFilename: $originalFilename, path: $path) {
      filename
      originalFilename
      signedUrl
      publicUrl
    }
  }
`;
// export const LIST_ALL_OWNERS = gql`
// query listAllOwners  {
//   listAllOwners {
//     _id
//     FirstName
//     LastName
//   }
// }
// `;
export const LIST_PROPERTY = gql`
  query propertyList(
    $search: String
    $skip: Int
    $limit: Int
    $area: String
    $period: String
    $price: [String]
    $listBed: Float
    $listBath: Float
    $isResidential: Boolean
    $isCommercial: Boolean
    $amenities: [String]
    $locations: [String]
    $rentalPeriods: [String]
    $listWater: [String]
    $listTennis: String
    $listPool: String
    $listPet: String
    $listStyle :[String]
    $keywordSearch:String
    $browserUniqueId: String
    $subArea:[String]
    $hidePrivatePpty:Boolean
  ) {
    propertyList(
      search: $search
      skip: $skip
      limit: $limit
      area: $area
      period: $period
      price: $price
      listBed: $listBed
      listBath: $listBath
      isResidential: $isResidential
      isCommercial: $isCommercial
      amenities: $amenities
      locations: $locations
      rentalPeriods: $rentalPeriods
      listWater: $listWater
      listTennis: $listTennis
      listPool: $listPool
      listPet: $listPet
      listStyle :$listStyle
      keywordSearch:$keywordSearch
      browserUniqueId: $browserUniqueId
      subArea :$subArea
      hidePrivatePpty:$hidePrivatePpty
    ) {
      data {
        _id
        PropertySlug
        PropertyId
        PendingFlag
        PID
        Area
        ListBedRoom
        ListBathRoom
        AdTitle
        Latitude
        Longitude
        LivingArea
        RentalRegisteryId
        ListMainAmenity {
          Title
          Flag
        }
        Images {
          _id
          FileUrl
          Title
          VideoLink
          Featured
          OrderByVal
        }
      }
      count
    }
  }
`;

export const LEADS_LIST = gql`
  query leadsList(
    $search: String
    $agent: String
    $userType: String
    $skip: Int!
    $limit: Int!
  ) {
    leadsList(
      search: $search
      agent: $agent
      userType: $userType
      skip: $skip
      limit: $limit
    ) {
      data {
        _id
        LeadIdOld
        UserId
        AgentId
        CreatedBy
        CreatedOn
        IsActive
        UpdatedOn
        UpdatedBy
        LeadSrc
        UserType
        UserDtls {
          FirstName
          LastName
          ProfileImage
        }
        AgentDtls {
          FirstName
          LastName
          ProfileImage
        }
        OwnedPptyCount
      }
      count
    }
  }
`;

export const PANDS_HISTORY = gql`
  query propertyHisDetails($propertyId: String!) {
    propertyHisDetails(propertyId: $propertyId) {
      _id
      PropertyIdOld
      PropertyId
      PropertyOwnerId
      PropertySlug
      IsSouthOfHighway
      IsPrivate
      IsSpecial
      IsFeatured
      PropertyType
      ListStyle
      ListBedRoom
      ListBathRoom
      BedBathConfiguration
      TotalAnnualREtaxes
      AvailableSqFt
      AdTitle
      AdDescription
      StreetAddress
      LotSize
      City
      State
      Zip
      RentalRegisteryId
      RRExp
      PropertyStatus
      UpdatedBy
      UpdatedOn
      Area
      Status
      UpdatedByDtls {
        FirstName
        LastName
        Role
      }
    }
  }
`;
export const PROPERTY_PRICE_HIS_DETAILS = gql`
  query propertyPriceHisDtls($propertyId: String!) {
    propertyPriceHisDtls(propertyId: $propertyId) {
      _id
      PropertyId
      UpdatedOn
      ShortTermPriceDetails {
        DateTo
        DateFrom
        Amount
        RatePeriod
      }
      PriceDetails {
        Price
        RentalPeriod
        AvblStatus
        IsEdited
        PrePrice
      }

      UpdatedByDtls {
        FirstName
        LastName
        Role
      }
      SalesPrice
      Status
    }
  }
`;

export const LIST_PROPERTY_PRICE_DETAILS = gql`
  query propertyPriceDetails($propertyId: String!) {
    propertyPriceDetails(propertyId: $propertyId) {
      _id
      PropertyId
      PriceDetails {
        RentalPeriod
        Price
        AvblStatus
        IsEdited
        PrePrice
      }
      ShortTermPriceDetails {
        DateFrom
        DateTo
        Amount
        RatePeriod
      }
      Status
      SalesPrice
    }
  }
`;
export const PROPERTY_DETAILS = gql`
  query propertyDetails($propertyId: String!) {
    propertyDetails(propertyId: $propertyId) {
      _id
      PendingFlag
      PropertyIdOld
      PropertyType
      PropertyOwnerId
      Area
      PropertySlug
      IsSouthOfHighway
      ListStyle
      ListBedRoom
      ListBathRoom
      BedBathConfiguration
      IsFurnished
      IsPrivate
      IsWirelessWeb
      ListAirCondition
      ListFireplace
      ListPool
      IsChildSafetyFence
      ListTennis
      IsDownstairsMaster
      ListWater
      ListStory
      ListHeat
      YearBuilt
      ListBasement
      ListGarage
      ListPet
      IsPoolHouseOrArtStudio
      IsJacuzziSpa
      TotalAnnualREtaxes
      AvailableSqFt
      AdTitle
      AdDescription
      SalesPrice
      IsSalePricePublic
      ListLeaseTerm
      OwnerId
      PropertyAgentId
      ListAgentJustification
      Status
      StreetAddress
      LotSize
      ListKey
      City
      State
      Zip
      RentalRegisteryId
      RRExp
      LivingArea
      VillageLocation
      ListNatureOfParty
      ListPropertyTypeCommercial
      ListTimeFrame
      PID
      OwnerDtls {
        FirstName
        LastName
        Agent
        ProfileImage
      }
      ListMainAmenity {
        Title
        Flag
      }
      KeyLocation
      AlaramCode
      ShowingIns
      SpecialInfo
      SuffCoTaxMap {
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
        Value7
      }
      Images {
        _id
        FileUrl
        Title
        VideoLink
        Featured
        OrderByVal
      }
      Latitude
      Longitude
      PriceData {
        _id
        PropertyId
        PriceDetails {
          RentalPeriod
          Price
          AvblStatus
          IsEdited
          PrePrice
        }
        ShortTermPriceDetails {
          DateFrom
          DateTo
          Amount
          RatePeriod
        }
        Status
        SalesPrice
      }
    }
  }
`;
export const VIEW_AGREEMENT = gql`
  query viewAgreement($PropertyId: String!) {
    viewAgreement(PropertyId: $PropertyId) {
      PropertyId
      StreetAddress
      TownStateZip
      AgencyDisclosure
      Party
      PartyAs
      ChkLandlordName1
      ChkLandlordName2
      LandlordName1
      LandlordName2
      ListingTermChk
      Status
      DocLink
    }
  }
`;
export const PROPERTY_IMAGE_UPLOAD = gql`
  query propertyImageUpload($originalFilename: [fileNames], $path: String!) {
    propertyImageUpload(originalFilename: $originalFilename, path: $path) {
      signedUrl
      filename
      originalFilename
      publicUrl
    }
  }
`;
export const VIEW_PROPERTY_IMAGES = gql`
  query viewPropertyImages($propertyId: String!) {
    viewPropertyImages(propertyId: $propertyId) {
      data {
        _id
        PropertyId
        VideoLink
        FileUrl
        Title
        MimeType
        Featured
        IsPrintable
        OrderByVal
      }
      count
    }
  }
`;
export const PROPERTY_INFO_CHECK = gql`
  query propertyInfoCheck($propertyId: String!) {
    propertyInfoCheck(propertyId: $propertyId) {
      propertyExist
      propertyPrice
      propertyAsset
      propertyAgreement
      propertyAgent
    }
  }
`;

export const PROPERTY_SEARCH = gql`
  query propertySearch(
    $selectedArea: String
    $selectedPeriod: String
    $selectedPrice: [String]
    $selectedBed: Float
    $selectedBath: Float
    $rentalPeriods: [String]
    $locations: [String]
    $isPrivate: Boolean
    $amenities: [String]
    $isCommercial: Boolean
    $isResidential: Boolean
    $listWater: [String]
    $listTennis: String
    $status: String
    $listPool: String
    $listPet: String
    $listStyle: [String]
    $skip: Int
    $limit: Int
    $keywordSearch: String
    $subArea:[String]
    $isFeatured:Boolean
    $agentId:String
    $ownerId:String
  ) {
    propertySearch(
      selectedArea: $selectedArea
      selectedPeriod: $selectedPeriod
      selectedPrice: $selectedPrice
      selectedBed: $selectedBed
      selectedBath: $selectedBath
      rentalPeriods: $rentalPeriods
      locations: $locations
      isPrivate: $isPrivate
      amenities: $amenities
      isCommercial: $isCommercial
      isResidential: $isResidential
      listWater: $listWater
      listTennis: $listTennis
      status: $status
      listPool: $listPool
      listPet: $listPet
      listStyle:$listStyle
      skip: $skip
      limit: $limit
      keywordSearch: $keywordSearch
      subArea: $subArea
      isFeatured:$isFeatured
      ownerId:$ownerId
      agentId:$agentId
    ) {
      data {
        _id
        PropertyId
        PID
        PropertyType
        PropertyOwnerId
        Area
        PropertySlug
        IsSouthOfHighway
        ListStyle
        ListBedRoom
        ListBathRoom
        BedBathConfiguration
        IsFurnished
        IsWirelessWeb
        ListAirCondition
        ListFireplace
        ListPool
        IsChildSafetyFence
        IsFeatured
        ListTennis
        IsDownstairsMaster
        ListWater
        ListStory
        ListHeat
        YearBuilt
        ListBasement
        ListGarage
        IsPrivate
        ListPet
        IsPoolHouseOrArtStudio
        IsJacuzziSpa

        ListMainAmenity {
          Title
          Flag
        }
        TotalAnnualREtaxes
        AvailableSqFt
        AdTitle
        AdDescription
        SalesPrice
        IsSalePricePublic
        ListLeaseTerm
        OwnerId
        PropertyAgentId
        ListAgentJustification
        Status
        StreetAddress
        LotSize
        ListKey
        City
        State
        Zip
        RentalRegisteryId
        RRExp
        LivingArea
        VillageLocation
        ListNatureOfParty
        ListPropertyTypeCommercial
        ListTimeFrame
        message
        OwnerDtls {
          FirstName
          LastName
          ProfileImage
          Agent
          Email
          Phone
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
        }
        AgentDtls {
          FirstName
          LastName
          ProfileImage
          Email
          Phone
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
        }
        UniqueCode
        KeyLocation
        AlaramCode
        ShowingIns
        SpecialInfo
        SuffCoTaxMap {
          Value1
          Value2
          Value3
          Value4
          Value5
          Value6
          Value7
        }
        OwnerFirstName
        OwnerLastName
        OwnerPhone
        OwnerEmail
        OwnerProfileImage
        Images {
          _id
          FileUrl
          Title
          VideoLink
          Featured
          IsPrintable
          OrderByVal
        }
        Comments {
          Comment
          UserName
          CreatedBy
          UpdatedBy
          CreatedOn
          UpdatedOn
        }
        PriceData {
          _id
          PropertyId
          PriceDetails {
            RentalPeriod
            Price
            AvblStatus
            IsEdited
            PrePrice
          }
          ShortTermPriceDetails {
            DateFrom
            DateTo
            Amount
            RatePeriod
          }
          Status
          SalesPrice
        }
        Latitude
        Longitude
        UpdatedOn
        CreatedOn
        UpdatedByDtls {
          FirstName
          LastName
        }
        AgreementPdfUrl
        PendingFlag
        PropertyEditedFields {
          OwnerId
          StreetAddress
          City
          State
          Area
          Zip
          RentalRegisteryId
          RRExp
          LotSize
          YearBuilt
          ListStyle
          ListBedRoom
          ListBathRoom
          BedBathConfiguration
          LivingArea
          ListFireplace
          ListStory
          ListHeat
          IsDownstairsMaster
          ListPet
          ListBasement
          ListGarage
          ListAirCondition
          ListPool
          ListTennis
          ListWater
          ListMainAmenity {
            Title
            Flag
          }
          PropertyType
          IsFurnished
          AdTitle
          AdDescription
          UpdatedOn
          UpdatedBy
         
        }
      }
      count
    }
  }
`;
export const LIST_ALL_USERS = gql`
  query listAllUsers($search: String,$role: String!,$skip: Int!,$limit: Int!) {
    listAllUsers(search:$search,role: $role, skip: $skip, limit: $limit) {
      _id
      FirstName
      LastName
      Email
    }
  }
`;

export const LIST_ALL_SUGGEST_USERS = gql`
  query listUsersForSuggest($search: String,$role: String!,$skip: Int!,$limit: Int!) {
    listUsersForSuggest(search:$search,role: $role, skip: $skip, limit: $limit) {
      data{
        _id
        FirstName
        LastName
        Email
      }
      count

    }
  }
`;

export const LEAD_ACTIVITY_LIST = gql`
  query leadActivityList(
    $leadId: String!
    $skip: Int
    $limit: Int
    $tabType: String
  ) {
    leadActivityList(
      leadId: $leadId
      skip: $skip
      limit: $limit
      tabType: $tabType
    ) {
      data {
        _id
        LeadId
        UserId
        PropertyId
        LeadType
        LeadStatus
        Comment
        IsOpen
        RootId
        DisplayLevel
        Status
        BackendComments
        CreatedByName
        CreatedBy
        LeadSrc
        CreatedOn
        UpdatedBy
        UpdatedOn
        PrtyAgentDtls {
          FirstName
          LastName
          ProfileImage
          Agent
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
          Phone
          Email
        }
        AddedByDtls {
          FirstName
          LastName
          ProfileImage
          Agent
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
          Phone
          Email
        }
        UserDtlsLead {
          FirstName
          LastName
          ProfileImage
          Agent
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
          Phone
          Email
        }
        OwnerDtls {
          OwnerId
          FirstName
          LastName
          ProfileImage
          Agent
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
          Phone
          Email
        }
        PropertyDtls {
          PendingFlag
          PropertyId
          PropertySlug
          Area
          Longitude
          Latitude
          ListBedRoom
          ListBathRoom
          AdTitle
          AdDescription
          LivingArea
          PID
          ListStyle
          ListAirCondition
          ListFireplace
          ListPool
          ListTennis
          ListWater
          ListStory
          ListBasement
          ListGarage
          ListPet
          AvailableSqFt
          StreetAddress
          LotSize
          Status
          City
          State
          Zip
          VillageLocation
          KeyLocation
          YearBuilt
          ListMainAmenity {
            Title
            Flag
          }
        }
        ImagesLead {
          _id
          FileUrl
          Title
          Featured
          OrderByVal
          VideoLink
        }
        PriceDataLead {
          _id
          PropertyId
          PriceDetails {
            RentalPeriod
            Price
            AvblStatus
          }
          ShortTermPriceDetails {
            DateFrom
            DateTo
            Amount
            RatePeriod
          }
          Status
          SalesPrice
        }
        PropertyLeadCmts {
          LeadActivityComment{
       
          Comment
          CreatedBy
          CreatedByName{
            FirstName
         LastName
          }
        
        }
        }
      }
      count
    }
  }
`;

export const LEADS_DETAIL = gql`
  query leadsDetails($leadId: String!) {
    leadsDetails(leadId: $leadId) {
      _id
      UserId
      UserType
      CreatedOn
      UpdatedOn
      CreatedBy
      CreatedOn
      UserType
      AgentId
      IsActive
      AgentDtls {
        _id
        FirstName
        LastName
        ProfileImage
        Email
        Phone
        HomePhone
        OfficePhone
        CellPhone
        LocalPhone
      }
      UserDtls {
        FirstName
        LastName
        ProfileImage
        Email
        Phone
        HomePhone
        OfficePhone
        CellPhone
        LocalPhone
        Status
        ContactPreference
        AddressLine1
        AddressLine2
        City
        State
        Zip
        Country
        SecondContactName
        SecondContactPhone
        SecondContactEmail
      }
    }
  }
`;

export const LIST_SAVED_PROPERTIES = gql`
  query listSavedProperties($userId: String!) {
    listSavedProperties(userId: $userId) {
      data {
        _id
        PropertyId
        PID
        PropertyType
        PropertyOwnerId
        AdTitle
      }
      count
    }
  }
`;

export const LIST_RELATED_PROPERTIES = gql`
  query listRelatedProperties($id: String!, $type: String!) {
    listRelatedProperties(id: $id, type: $type) {
      data {
        _id
        PropertySlug
        PropertyId
        AdTitle
        AdDescription
        Area
        ListBedRoom
        ListBathRoom
        LivingArea
        AvailableSqFt
        RelatedPptyImages
        IsSouthOfHighway
        ListPool
        IsFurnished
        IsWirelessWeb
        PID 
        ListAirCondition
        ListFireplace
        IsChildSafetyFence
        ListTennis
        IsDownstairsMaster
        ListWater
        ListStory
        ListHeat
        IsPoolHouseOrArtStudio
        IsJacuzziSpa
        IsPrivate
        ListMainAmenity {
          Title
          Flag
        }
        Images {
          _id
          FileUrl
          Title
          VideoLink
          Featured
          OrderByVal
        }
      }
      count
    }
  }
`;

export const NEXT_PROPERTY_LIST = gql`
  query listAllProperties(
    $selectedArea: String
    $selectedPeriod: String
    $selectedPrice: String
    $selectedBed: Float
    $selectedBath: Float
    $keywordSearch: String
  ) {
    listAllProperties(
      selectedArea: $selectedArea
      selectedPeriod: $selectedPeriod
      selectedPrice: $selectedPrice
      selectedBed: $selectedBed
      selectedBath: $selectedBath
      keywordSearch: $keywordSearch
    ) {
      data {
        _id
        PropertySlug
      }
    }
  }
`;

export const LIST_SAVED_SEARCH = gql`
  query savedSearchList($type: String!, $skip: Int!, $limit: Int!) {
    savedSearchList(type: $type, skip: $skip, limit: $limit) {
      data {
        _id
        UserId
        BudgetFrom
        BudgetTo
        ListBedRoom
        ListBathRoom
        Period
        Comments
        ListPool
        ListTennis
        Waterview
        ListWater 
        Oceanfront
        ListPet
        SouthOfHighway
        BrowserSessionId
        SavedType
        Town
        Area
        Keyword
        CreatedOn
        UpdatedOn
        UserDtlsNew {
          FirstName
          LastName
          ProfileImage
          Agent
        }
      }
      count
    }
  }
`;

export const LIST_AGENT_PROPERTIES = gql`
  query listAgentProperties($userId: String!, $skip: Int, $limit: Int) {
    listAgentProperties(userId: $userId, skip: $skip, limit: $limit) {
      data {
        _id
        Area
        PropertyId
        IsSouthOfHighway
        ListStyle
        ListBedRoom
        ListBathRoom
        BedBathConfiguration
        IsFurnished
        IsWirelessWeb
        ListAirCondition
        ListFireplace
        ListPool
        PID
        IsChildSafetyFence
        ListTennis
        PropertySlug
        IsDownstairsMaster
        ListWater
        ListStory
        ListHeat
        YearBuilt
        IsPoolHouseOrArtStudio
        IsJacuzziSpa
        ListMainAmenity {
          Title
          Flag
        }
        AvailableSqFt
        AdTitle
        StreetAddress
        LivingArea
        RelatedPptyImages
        Images {
          _id
          FileUrl
          Title
          VideoLink
          Featured
          OrderByVal
        }
      }
      count
    }
  }
`;

export const PENDING_PROPERTY_LIST = gql`
  query pendingPropertiesList(
   
    $selectedPrice: [String]
   
    $selectedBed: Float
    $selectedBath: Float
    $isPrivate: Boolean
    $locations: [String]
    $rentalPeriods: [String]
   
    $amenities: [String]
    $listWater: [String]
    $listTennis: String
    $listPool: String
    $agentId: String
    $listPet: String
    $listStyle: [String]
    $skip: Int
    $limit: Int
    $keywordSearch: String
    
    $status:String
    
  ) {
    pendingPropertiesList(
      
      selectedPrice:$selectedPrice
      
      selectedBed: $selectedBed
      selectedBath: $selectedBath
      isPrivate: $isPrivate
      locations: $locations
      rentalPeriods: $rentalPeriods
      
      amenities: $amenities
      listWater: $listWater
      listTennis: $listTennis
      listPool: $listPool
      listStyle : $listStyle
      agentId: $agentId
      listPet: $listPet
      skip: $skip
      limit: $limit
      keywordSearch: $keywordSearch
      
      status:$status
    ) {
      data {
        _id
        AgentInfoEditedKeys
        EditedKeys
        PropertyId
        PID
        PropertyType
        PropertyOwnerId
        Area
        PropertySlug
        ListStyle
        ListBedRoom
        ListBathRoom
        BedBathConfiguration
        IsFurnished
        ListAirCondition
        ListFireplace
        ListPool
        IsChildSafetyFence
        ListTennis
        IsDownstairsMaster
        ListWater
        ListStory
        ListHeat
        YearBuilt
        ListBasement
        ListGarage
        IsPrivate
        ListPet
        IsPoolHouseOrArtStudio
        IsJacuzziSpa
        ListMainAmenity {
          Title
          Flag
        }
        AvailableSqFt
        AdTitle
        AdDescription
        SalesPrice
        IsSalePricePublic
        ListLeaseTerm
        OwnerId
        PropertyAgentId
        ListAgentJustification
        Status
        StreetAddress
        LotSize
        ListKey
        City
        State
        Zip
        RentalRegisteryId
        RRExp
        LivingArea
        VillageLocation
        ListNatureOfParty
        ListPropertyTypeCommercial
        ListTimeFrame
        message
        OwnerDtls {
          FirstName
          LastName
          ProfileImage
          Agent
          Email
          Phone
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
        }
        AgentDtls {
          FirstName
          LastName
          ProfileImage
          Email
          Phone
          HomePhone
          OfficePhone
          CellPhone
          LocalPhone
        }
        UniqueCode
        KeyLocation
        AlaramCode
        ShowingIns
        SpecialInfo
        SuffCoTaxMap {
          Value1
          Value2
          Value3
          Value4
          Value5
          Value6
          Value7
        }
        OwnerFirstName
        OwnerLastName
        OwnerPhone
        OwnerEmail
        OwnerProfileImage
        Images {
          _id
          FileUrl
          Title
          VideoLink
          Featured
          OrderByVal
        }
        PendingImages{
          _id
          FileUrl
          Title
          VideoLink
          Featured
          OrderByVal
        }
        Comments {
          Comment
          UserName
          CreatedBy
          UpdatedBy
          CreatedOn
          UpdatedOn
        }
        PendingComments{
          _id
          PropertyId
          Comment
          UserId
          UserName
          CreatedBy
          UpdatedBy
          CreatedOn
          UpdatedOn
        }
        PriceData {
          _id
          PropertyId
          
          PriceDetails {
            RentalPeriod
            Price
            AvblStatus
            IsEdited
            PrePrice
          }
          ShortTermPriceDetails {
            DateFrom
            DateTo
            Amount
            RatePeriod
          }
          Status
          SalesPrice
          EditedSalesPrice
        }
        Latitude
        Longitude
        UpdatedOn
        CreatedOn
        UpdatedByDtls {
          FirstName
          LastName
        }
        AgentInfoEditedFields{
          ListAgentJustification
          ListKey
          KeyLocation
          AlaramCode
          ShowingIns
          SpecialInfo
          SuffCoTaxMap{
            Value1
            Value2
            Value3
            Value4
            Value5
            Value6
            Value7
          }
         
        }
        PropertyEditedFields {
          OwnerId
          OwnerName
          StreetAddress
          City
          State
          Area
          Zip
          RentalRegisteryId
          RRExp
          LotSize
          YearBuilt
          ListStyle
          ListBedRoom
          ListBathRoom
          BedBathConfiguration
          LivingArea
          ListFireplace
          ListStory
          ListHeat
          IsDownstairsMaster
          ListPet
          ListBasement
          ListGarage
          ListAirCondition
          ListPool
          ListTennis
          ListWater
          ListMainAmenity {
            Title
            Flag
          }
          PropertyType
          IsFurnished
          AdTitle
          AdDescription
          UpdatedOn
          UpdatedBy
        }
      
        propertyDtls{
          _id
          PropertyId
          PID
          Area
          PropertySlug
          IsSouthOfHighway
          ListStyle
          ListBedRoom
          ListBathRoom
          BedBathConfiguration
          IsFurnished
          
          ListAirCondition
          ListFireplace
          ListPool
          IsChildSafetyFence
          ListTennis
          IsDownstairsMaster
          ListWater
          ListStory
          ListHeat
          YearBuilt
          ListBasement
          ListGarage
          ListPet
          IsPoolHouseOrArtStudio
          IsJacuzziSpa
          ListMainAmenity
          {
            Title
            Flag
          }
          AvailableSqFt
          AdTitle
          AdDescription
          SalesPrice
          OwnerId
          PropertyAgentId
          ListAgentJustification
          Status
          StreetAddress
          LotSize
          ListKey
          City
          State
          Zip
          RentalRegisteryId
          RRExp
          LivingArea
          message
          UniqueCode
          KeyLocation
          AlaramCode
          ShowingIns
          SpecialInfo
          OwnerFirstName
          OwnerLastName
OwnerPhone
OwnerEmail
OwnerProfileImage
AgentOfOwner
Images{
  _id
FileUrl
Title
VideoLink
Featured
OrderByVal
}
ApprovedPriceData{
  _id
PropertyId
PriceDetails{
  RentalPeriod
Price
AvblStatus
IsEdited
PrePrice
}
ShortTermPriceDetails{
  DateFrom
DateTo
Amount
RatePeriod
}
Status
SalesPrice
}
Comments{
  _id
PropertyId
Comment
UserId
UserName
CreatedBy
UpdatedBy
CreatedOn
UpdatedOn
}

Latitude
Longitude
CreatedOn
UpdatedOn
IsPrivate
RelatedPptyImages
AgreementPdfUrl
PropertyPID
IsFeatured
        }
      }
      count
    }
  }
`;
export const LIST_KEYWORDS = gql`
  query listAllKeywords {
    listAllKeywords {
      data {
        BudgetFrom
        BudgetTo
        ListBedRoom
        ListBathRoom
        RentalPeriods
        Keyword
        PriceRange
        Area
        ListPool
        ListTennis
        Waterview
        ListWater
        Oceanfront
        ListPet
        Period
      }
    }
  }
`;
export const PROPERTIES_IN_TOWN = gql`
  query propertiesInTown {
    propertiesInTown {
      town
      count
      townImgUrl
    }
  }
`;
export const CONTACT_LISTING = gql`
query listContact(
  $skip: Int,
  $limit: Int,
  $search: String
){
  listContact(
  skip: $skip,
  limit: $limit,
  search: $search,
  ){
    data{
      FirstName
      LastName
      Email
      Phone
      CreatedOn
      Message{
        Message
        CreatedOn
      }
      contactID
      IsLead
    }
    count
  }
}
`;

export const MESSAGE_COUNT= gql`
query getMsgCount($userId : String!){
  getMsgCount(userId: $userId)
}
`;

export const PENDING_PROPERTY_AGREEMENT_LIST = gql`
query pendingAgreements (
    $skip: Int,
    $limit: Int,
    $selectedArea: String,
    $selectedPeriod: String,
    $selectedPrice: [String],
    $selectedBed: Float,
    $selectedBath: Float,
    $keywordSearch: String,
    $agentId: String,
    $isPrivate: Boolean,
    $locations: [String],
    $rentalPeriods: [String],
    $isResidential: Boolean,
    $isCommercial: Boolean,
    $amenities: [String],
    $listWater: [String],
    $listTennis: String,
    $listPool: String,
    $listPet: String,
    $listType: String,
    $subArea: [String]
  ) {
    pendingAgreements (
    skip: $skip,
    limit: $limit,
    selectedArea: $selectedArea,
    selectedPeriod: $selectedPeriod,
    selectedPrice: $selectedPrice,
    selectedBed: $selectedBed,
    selectedBath: $selectedBath,
    rentalPeriods: $rentalPeriods,
    locations: $locations,
    isPrivate: $isPrivate,
    amenities: $amenities,
    isCommercial: $isCommercial,
    isResidential: $isResidential,
    listWater: $listWater,
    listTennis: $listTennis,
    listPool: $listPool,
    listPet: $listPet,
    keywordSearch: $keywordSearch,
    agentId:$agentId,
    listType:$listType,
    subArea: $subArea
    ){
    data{
      _id
      PropertyId
      PropertyIdNew
      PropertyIdOld
      PID
      PropertyType
      PropertyOwnerId
      Area
      PropertySlug      
      IsSouthOfHighway
      ListStyle
      ListBedRoom
      ListBathRoom
      BedBathConfiguration
      IsFurnished
      IsWirelessWeb
      ListAirCondition
      ListFireplace
      ListPool
      IsChildSafetyFence
      ListTennis
      IsDownstairsMaster
      ListWater
      ListStory
      ListHeat
      YearBuilt
      ListBasement
      ListGarage
      IsPrivate
      ListPet
      IsPoolHouseOrArtStudio
      IsJacuzziSpa
      ListMainAmenity{
        Title
        Flag
      }
      TotalAnnualREtaxes
      AvailableSqFt
      AdTitle
      AdDescription
      SalesPrice
      IsSalePricePublic
      ListLeaseTerm
      OwnerId
      PropertyAgentId
      ListAgentJustification
      Status
      StreetAddress
      LotSize
      ListKey
      City
      State
      Zip
      RentalRegisteryId
      RRExp
      LivingArea
      VillageLocation
      ListNatureOfParty
      ListPropertyTypeCommercial
      ListTimeFrame
      message
      OwnerDtls{
        FirstName
        LastName
        ProfileImage
        Agent
        Email
        Phone
        HomePhone
        OfficePhone
        CellPhone
        LocalPhone
      }
      AgentDtls{
        FirstName
        LastName
        ProfileImage
        Email
        Phone
        HomePhone
        OfficePhone
        CellPhone
        LocalPhone
      }
      UniqueCode
      KeyLocation
      AlaramCode
      ShowingIns
      SpecialInfo
      SuffCoTaxMap{
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
        Value7
      }
      OwnerFirstName
      OwnerLastName
      OwnerPhone
      OwnerEmail
      OwnerProfileImage
      Images{
        _id
        FileUrl
        Title
        VideoLink
        Featured
        OrderByVal
      }
      Comments{
        Comment
        UserName
        CreatedBy
        UpdatedBy
        CreatedOn
        UpdatedOn
      }
      PriceData{
        _id
        PropertyId
        PriceDetails{
          RentalPeriod
          Price
          AvblStatus
          IsEdited
          PrePrice
        }
        ShortTermPriceDetails{
          DateFrom
          DateTo
          Amount
          RatePeriod
        }
        Status
        SalesPrice
      }
      Latitude
      Longitude
      UpdatedOn
      CreatedOn
      UpdatedByDtls{
        FirstName
        LastName
      }
      AgreementPdfUrl
      PropertyEditedFields{
        OwnerId
        StreetAddress
        City
        State
        Area
        Zip
        RentalRegisteryId
        RRExp
        LotSize
        YearBuilt
        ListStyle
        ListBedRoom
        ListBathRoom
        BedBathConfiguration
        LivingArea
        ListFireplace
        ListStory
        ListHeat
        IsDownstairsMaster
        ListPet
        ListBasement
        ListGarage
        ListAirCondition
        ListPool
        ListTennis
        ListWater
        ListMainAmenity{
          Title
          Flag
        }
        PropertyType
        IsFurnished
        AdTitle
        AdDescription
        UpdatedOn
        UpdatedBy
      }
    }
    count
  }
}
`;
export const PENDING_AGREEMENTS_COUNT = gql`
  query pendingAgreementsCount {
    pendingAgreementsCount   
  }
`;
export const PENDING_PROPERTIES_COUNT = gql`
  query pendingPropertiesCount {
    pendingPropertiesCount   
  }
`;
export const LIST_PROPERTY_PREFERENCE = gql`
  query propertyPreference($userId: String) {
    propertyPreference(userId: $userId) {
        _id
        UserId
        BudgetFrom
        BudgetTo
        ListBedRoom
        ListBathRoom
        RentalPeriods
        Locations
        Comments
        ListPool      
        ListTennis
        Waterview
        ListWater
        PriceRange
        Amenities
    }
  }
`;

export const PROPERTY_PRICE_FOR_PENDING = gql`
  query propertyPriceForPending($propertyId: String!) {
    propertyPriceForPending(propertyId: $propertyId) {
      _id
      PropertyId
      PriceDetails {
        RentalPeriod
        Price
        AvblStatus
        IsEdited
        PrePrice
      }
      ShortTermPriceDetails {
        DateFrom
        DateTo
        Amount
        RatePeriod
      }
      Status
      SalesPrice
      UpdatedOn
      UpdatedBy
      UpdatedByDtls{
        FirstName
        LastName
        ProfileImage
        Role
      }
    }
  }
`;
export const PROPERTY_DETAILS_FOR_PENDING = gql`
  query propertyDtlsForPending($propertyId: String!) {
    propertyDtlsForPending(propertyId: $propertyId) {
      _id
      PropertyId
      OwnerId
      PropertyOwnerId
      Area
      Latitude
      Longitude
      LivingArea
      PropertySlug
      IsSouthOfHighway
      IsPrivate
      PID
      IsFurnished
      ListHeat
      ListAirCondition
      IsDownstairsMaster
      ListWater
      ListPool
      ListTennis
      ListBasement
      ListGarage
      ListStory
      ListFireplace
      ListPet
      IsChildSafetyFence
      IsFeatured
      ListMainAmenity{
        Title
        Flag
      }
      PropertyType
      ListStyle
      ListBedRoom
      ListBathRoom
      BedBathConfiguration
      AvailableSqFt
      AdTitle
      OwnerDtls{
        FirstName
        LastName
        Agent
        
      }
      AdDescription
      StreetAddress
      LotSize
      City
      State
      Zip
      RentalRegisteryId
      RRExp
      PropertyStatus
      Status
      UpdatedBy
      UpdatedOn
      YearBuilt
      PropertyAgentId
      PropertyAgent
      ListKey
      AlaramCode
      ListAgentJustification
      KeyLocation
      ShowingIns
      SpecialInfo
      SuffCoTaxMap{
        Value1
        Value2
        Value3
        Value4
        Value5
        Value6
        Value7
      }
      UpdatedByDtls{
        FirstName
        LastName
        ProfileImage
        Role
      }
      
    }
  }
`;
export const PROPERTY_TOGGLE = gql`
  query propertyToggle($args: String!, $argsType: String!) {
    propertyToggle(args: $args, argsType: $argsType) {
      _id
      PropertySlug  
    }
  }
`;
export const PROPERTY_LIST_IN_MAP = gql`
query propertyListInMap(
  $search: String
  $area: String
  $period: String
  $price: [String]
  $listBed: Float
  $listBath: Float
  $isResidential: Boolean
  $isCommercial: Boolean
  $amenities: [String]
  $locations: [String]
  $rentalPeriods: [String]
  $listWater: [String]
  $keywordSearch: String
  $listTennis: String
  $listPool: String
  $listPet: String
  $listStyle :[String]
  $browserUniqueId: String
  $subArea :[String]
) {
  propertyListInMap(
    search: $search
    area: $area
    period: $period
    price: $price
    listBed: $listBed
    listBath: $listBath
    isResidential: $isResidential
    isCommercial: $isCommercial
    keywordSearch: $keywordSearch
    amenities: $amenities
    locations: $locations
    rentalPeriods: $rentalPeriods
    listWater: $listWater
    listTennis: $listTennis
    listPool: $listPool
    listPet: $listPet
    listStyle: $listStyle
    browserUniqueId: $browserUniqueId
    subArea: $subArea
  ) {
    data {
      PropertySlug
      Area
      ListBedRoom
      ListBathRoom
      RentalRegisteryId
      AdTitle
      Latitude
      Longitude
      LivingArea
      PID
      _id
    }
    count
  }
}
`;

export const ALL_ACTIVE_USERS= gql`
query allActiveUsers{
  allActiveUsers
}
`;

export const ALL_ACTIVE_OWNERS = gql`
  query allActiveOwners {
    allActiveOwners   
  }
`;

export const ALL_ACTIVE_PROPERTIES_COUNT = gql`
  query allActivePropertiesCount {
    allActivePropertiesCount   
  }
`;

export const FEATURED_PPTY_LIST = gql`
  query featuredPropertiesList($skip: Int, $limit: Int) {
    featuredPropertiesList(skip: $skip, limit: $limit) {
      _id
      AdTitle
      Area
      ListMainAmenity{
        Title
        Flag
      } 
      ListBedRoom
      ListBathRoom
      SalesPrice
      PID
      RentalRegisteryId
      featuredImage 
      PropertySlug
      PendingFlag
    }
  }
`;

export const LIST_AREAS = gql`
  query listAreas($group:Int) {
    listAreas(group:$group){
      City
      Area
    }   
  }
`;

export const PROPERTIES_IN_TOWN_FOOTER = gql`
  query propertiesInTownFooter($skip: Int, $limit: Int) {
    propertiesInTownFooter(skip: $skip, limit: $limit) {
      town
      count
      townImgUrl
    }
  }
`;