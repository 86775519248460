import { Avatar, Chip } from "@material-ui/core";
import React from "react";
import {  userDeatils } from "../../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import moment from "moment";
const CustomerPrint = React.forwardRef((props, ref) => {
  const customerData = props.data;
  const user = useReactiveVar(userDeatils);
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  return (
    <div className="printModalContent" ref={ref}>
      {customerData.map((item, index) => (
        <div className="pagebreak" key={index}>
          <div className="printHeader">
            <img
              src="../assets/images/logo-hr.jpg"
              className="img-fluid"
              alt="Logo"
            />
            <div className="printHeaderAddress d-flex align-items-center">
              <Avatar src={user?.data?.ProfileImage} className="img-fluid">
                {user?.data?.FirstName?.charAt(0).toUpperCase()+
                user?.data?.LastName?.charAt(0).toUpperCase()}
              </Avatar>
              <div>
                <label>
                  <b style={{ fontFamily: "Helvetica-Normal" }}>
                  {user?.data?.FirstName.charAt(0).toUpperCase() +user?.data?.FirstName.slice(1).toLowerCase()+ " "+ user?.data?.LastName.charAt(0).toUpperCase() +user?.data?.LastName.slice(1).toLowerCase()}
                    {/* {item?.AgentDtls?.FirstName +
                      " " +
                      item?.AgentDtls?.LastName} */}
                  </b>
                </label>
                {user?.data?.Role!=="agent"&&user?.data?.Phone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.Phone}
                  </label>
                )}
                {user?.data?.CellPhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.CellPhone}
                  </label>
                )}
                {user?.data?.Role!=="agent"&&user?.data?.HomePhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.HomePhone}
                  </label>
                )}
                {user?.data?.Role!=="agent"&&user?.data?.LocalPhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.LocalPhone}
                  </label>
                )}
                {user?.data?.OfficePhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.OfficePhone}
                  </label>
                )}
                {user?.data?.Email && (
                  <label className="mb-0">
                    {" "}
                    <img
                      src="../assets/images/email.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {user?.data?.Email}
                  </label>
                )}
                {/* {item?.AgentDtls?.Phone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.Phone}
                  </label>
                )}
                {item?.AgentDtls?.CellPhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.CellPhone}
                  </label>
                )}
                {item?.AgentDtls?.HomePhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.HomePhone}
                  </label>
                )}
                {item?.AgentDtls?.LocalPhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.LocalPhone}
                  </label>
                )}
                {item?.AgentDtls?.OfficePhone && (
                  <label>
                    {" "}
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.OfficePhone}
                  </label>
                )}
                {item?.AgentDtls?.Email && (
                  <label className="mb-0">
                    {" "}
                    <img
                      src="../assets/images/email.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {item?.AgentDtls?.Email}
                  </label>
                )} */}
              </div>
            </div>
          </div>

          <div className="printPropertyImage">
            {item?.Images?.map(
              (items, index) =>
                index < 3 &&
                items?.FileUrl != null && (
                  <div className="printPropertyImageItem" key={index}>
                    <img
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="Property"
                    />
                  </div>
                )
            )}
             {item?.PendingImages?.map(
              (items, index) =>
                index < 3 &&
                items?.FileUrl != null && (
                  <div className="printPropertyImageItem" key={index}>
                    <img
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="Property"
                    />
                  </div>
                )
            )}
          </div>
          <div className="printPropertyDetail customer-printPropertyDetail">
            <h2>
              <b>PID#&nbsp;{item?.PID}</b>&nbsp;
              {item?.AdTitle + ", " + item?.StreetAddress + ", " + item.Area}
            </h2>
         <div style={{fontSize:"14px", marginBottom:"15px",lineHeight:"18px"}}>{item?.AdDescription}</div>
            <ul className="propertyStyleTag">
              {item?.ListMainAmenity?.map(
                (items) => items?.Flag === true && <li>{items.Title}</li>
              )}
            </ul>
          </div>

          <div className="printPriceFeatures">
            <h3>
              <img
                src="../assets/images/icon-home.svg"
                className="imgfluid"
                alt="Icon"
              />
              Property Features
            </h3>
            <ul>
              {item?.ListBedRoom && (
                <li>
                  <label>Bed:</label>
                  <span>{item?.ListBedRoom}</span>
                </li>
              )}
              {item?.ListBathRoom && (
                <li>
                  <label>Bath:</label>
                  <span>{item?.ListBathRoom}</span>
                </li>
              )}
              {item?.ListStyle && (
                <li>
                  <label>Style:</label>
                  <span>{item?.ListStyle}</span>
                </li>
              )}
              {item?.ListFireplace && (
                <li>
                  <label>Fireplace:</label>
                  <span>{item?.ListFireplace}</span>
                </li>
              )}
              {item?.LivingArea && (
                <li>
                  <label>Living Area:</label>
                  <span>{item?.LivingArea}</span>
                </li>
              )}
              {item?.ListGarage && (
                <li>
                  <label>Garage:</label>
                  <span>{item?.ListGarage}</span>
                </li>
              )}
              {item?.YearBuilt && (
                <li>
                  <label>Year Built:</label>
                  <span>{item?.YearBuilt}</span>
                </li>
              )}
              {item?.ListHeat && (
                <li>
                  <label>Heat:</label>
                  <span>{item?.ListHeat}</span>
                </li>
              )}
              {item?.ListBasement && (
                <li>
                  <label>Basement:</label>
                  <span>{item?.ListBasement}</span>
                </li>
              )}
              {item?.ListAirCondition && (
                <li>
                  <label>A/c:</label>
                  <span>{item?.ListAirCondition}</span>
                </li>
              )}
              {item?.LotSize && (
                <li>
                  <label>Lot Size:</label>
                  <span>{item?.LotSize}</span>
                </li>
              )}
              {item?.ListPool && (
                <li>
                  <label>Pool:</label>
                  <span>{item?.ListPool}</span>
                </li>
              )}
              {item?.ListStory && (
                <li>
                  <label>Stories:</label>
                  <span>{item?.ListStory}</span>
                </li>
              )}

              {item?.SuffCoTaxMap?.Value1 != null && (
                <li>
                  <label>TaxMap:</label>{" "}
                  {item?.SuffCoTaxMap && (
                    <span>
                      {item?.SuffCoTaxMap?.Value1 +
                        "/" +
                        item?.SuffCoTaxMap?.Value2 +
                        "/" +
                        item?.SuffCoTaxMap?.Value3 +
                        "/" +
                        item?.SuffCoTaxMap?.Value4 +
                        "/" +
                        item?.SuffCoTaxMap?.Value5 +
                        "/" +
                        item?.SuffCoTaxMap?.Value6 +
                        "/" +
                        item?.SuffCoTaxMap?.Value7}
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>

          {item?.PriceData?.PriceDetails && (
            <div className="printPriceFeatures">
              <h3>
                <img
                  src="../assets/images/icon-price.svg"
                  className="imgfluid"
                  alt="Icon"
                />
                Pricing & Availability
              </h3>
              <ul>
                {item?.PriceData?.PriceDetails?.map((items) => (
                  <>
                    {(items.AvblStatus === "yes" ||
                      items.AvblStatus === "rented") && (
                      <li>
                        <label>
                          {items.AvblStatus === "yes" && (
                            <img
                              src="../assets/images/green-check.svg"
                              className="imgfluid"
                              alt="Icon"
                            />
                          )}{" "}
                          {items.AvblStatus === "rented" && (
                            <img
                              src="../assets/images/red-check.svg"
                              className="imgfluid"
                              alt="Icon"
                            />
                          )}
                          {items.RentalPeriod}
                        </label>
                        <span>${formatePrice(items.Price)}</span>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>
          )}
          {item?.PriceData?.PriceDetails && (
            <div className="printPriceFeatures border-0">
              <h3>
                <img
                  src="../assets/images/icon-price.svg"
                  className="imgfluid"
                  alt="Icon"
                />
                Short term Price (Priced Weekly)
              </h3>
              <ul>
                {item?.PriceData?.ShortTermPriceDetails?.map((items) => (
                      <Chip
                        className="me-2 mt-2"
                        label={`${convertTimeStamp(
                          items.DateFrom
                        )} - ${convertTimeStamp(items.DateTo)} $${items.Amount}`}
                      />
                ))}
              </ul>
            </div>
          )}
          <div className="printFooter">
            <p className="mb-2">
            {/* {item?.StreetAddress + ", " + item.Area} */}
              15 Railroad Avenue - Suite 3 . East Hampton - NY. 11937 . East Hampton
            </p>
            <p>
              All information is provided is deemed reliable but is not
              guranteed and should be independently verified
            </p>
          </div>
        </div>
      ))}
    </div>
  );
});
export default CustomerPrint;
