import React from 'react';


const Header = () => {
   
  
    return (
        <div >
            <header className="fixed-top">
                   <a href="/" className="header-logo "><img src="../assets/images/logo.svg" className="img-fluid" alt="logo" /></a>
                    <div>
                        {/* <Button className="highlight-btn" ><a href="/login" style={{color:"white"}}>Login</a>
                        </Button> */}
                        {/* <Button className="outline-btn list-property-btn" >
                         List Your Property
                        </Button> */}
                    </div>
            </header>
  
        </div>
    );
};



export default Header;
