import React from 'react'
import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core";
import { LEAD_ACTIVITY_ADD } from "../../../Graghql/mutations";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { userDeatils, toggleSnackbar } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router-dom";

export default function AddComment(props) {
  const history = useHistory();
  const user = useReactiveVar(userDeatils);
  const [cmtValue, setCmtValue] = useState();
  const [leadActyAdd, { loading }] = useMutation(LEAD_ACTIVITY_ADD);
  function handleAddComment() {
    if (!cmtValue || cmtValue.trim() === "") {
      toggleSnackbar({
        status: true,
        message: "No Comments Added",
        variant: "error",
      });
      return false;
    }
    let obj = {};
    obj.Comment = cmtValue;
    obj.LeadSrc = "LeadPage";
    obj.LeadType = "PropertyComment";
    obj.RootId = props.id;
    obj.PropertyIDs = [props.open];
    obj.UserId = user.data._id;
    try {
      leadActyAdd({ variables: obj })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Comment successfully Submitted",
            variant: "success",
          });
          handleClose();
          history.push(`/lead-activity/${props?.id}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleClose() {
    if (props && props.setOpen) {
      props.setOpen(null);
    }
  }

  return (
    <Dialog open={props?.open} onClose={handleClose}>
      <div
        style={{
          height: "330px",
          width: "500px",
          padding: "20px 30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2 style={{ textAlign: "center", fontWeight: "500", fontSize:"20px" }}>Add Comment</h2>

        <TextField
          variant="outlined"
          multiline
          value={cmtValue}
          onChange={(e) => setCmtValue(e.target.value)}
          rows={8}
          style={{ width: "100%", marginTop: "20px" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto",
          }}
        >
          <Button onClick={() => handleClose()} className="outline-btn" >
            Cancel
          </Button>
          <Button onClick={() => handleAddComment()} className="highlight-btn">
            {loading ? (
              <CircularProgress size="20px" color="inherit" />
            ) : (
              "Add Comment"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
