import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Checkbox,
  FormControl,
  TextField,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { useReactiveVar } from "@apollo/client";
import moment from "moment";
import Popover1 from "@material-ui/core/Popover";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
    PROPERTY_SEARCH,
} from "../../Graghql/queries";
import { FEATURED_PPTY } from "../../Graghql/mutations";
import LoggedInHeader from "../Common/loggedinHeader";
import Loader from "../Common/Loader";
import SideBar from "../Common/sidebar";
import DialogBox from "../Common/DialogBox";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import Featured from "../Properties/propertyActions/Featured";
import Locations from "../Properties/Locations";
// const useStyles = makeStyles((theme) => ({
//   menuPaper: {
//     maxHeight: 200,
//   },
// }));
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: 450,
  },
  margin: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// eslint-disable-next-line no-unused-vars
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 100) return text.slice(0, 100) + " ...";
      else return text.slice(0, 100);
    } else return text+" ";
  }
  if (!props.text) return null;
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 100 && (
        <label style={{cursor:'pointer'}} onClick={() => setVisible(!visible)}>
          {visible ? " Less" : " More"}
        </label>
      )}
    </div>
  );
}
const FeaturedList = (props) => {
  const options = [
    { label: "Feature", id: "Feature" },
  ];
  const user = useReactiveVar(userDeatils);
  const rowsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPrice, setAnchorElPrice] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [itemDetails, setitemDetails] = useState([]);
  const [areaFilter, setAreaFilter] = useState("");
  const [rentalPeriodFilter, setRentalPeriodFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [bathFilter, setBathFilter] = useState("");
  const [keyWordSearch, setKeyWordSearch] = useState("");
  const [isCommercial, setIsCommercial] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [location, setLocation] = useState([]);
  const [amenityName, setAmenityName] = useState([]);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [waterfront, setWaterfront] = useState([]);
  const [amount, setAmount] = useState(["0", "1000000"]);
  const [tennis, setTennis] = useState("");
  const [pools, setPools] = useState("");
  const [pets, setPets] = useState("");
  const [openFeatured, setOpenFeatured] = useState(false);
  const [openFeaturedAll, setOpenFeaturedAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [privateItem, setPrivateItem] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variable1, setVariable1] = useState([]);
  const [variable2, setVariable2] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [openLocations, setOpenLocations] = useState(false);
  const [propertyType, ] = useState({
    residential: false,
    commercial: false,
    isPrivateProperty: false,
  });
  const [openClicked, setOpenClicked] = useState(true);
  const { register, handleSubmit ,reset} = useForm();
  const [featuredProperty] = useMutation(FEATURED_PPTY, {
    refetchQueries: ["propertySearch"],
    
    onCompleted:(res)=>{
        setSelectedItem([])
        setSelectedId([])
    }
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  function formatePrice(value) {
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  // eslint-disable-next-line no-unused-vars
  function convertTimeStampWithTime(date) {
    return moment(new Date(Number(date))).format("MMM DD,hh:mm");
  }
  // eslint-disable-next-line no-unused-vars
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function formateTaxMap(value) {
    if (
      value?.Value1 &&
      value?.Value2 &&
      value?.Value3 &&
      value?.Value4 &&
      value?.Value5 &&
      value?.Value6 &&
      value?.Value7
    )
      return `${value?.Value1}/${value?.Value2}/${value?.Value3}/${value?.Value4}/${value?.Value5}/${value?.Value6}/${value?.Value7}`;
  }
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  // eslint-disable-next-line no-unused-vars
  function chechForPrice(priceArray) {
    let position = priceArray.findIndex(
      (element) =>
        element.AvblStatus.toLowerCase() === "yes" ||
        element.AvblStatus.toLowerCase() === "rented"
    );
    if (position === -1) return false;
    else return true;
  }
  //   const expirydate =
  //     item.RRExp && moment(item.RRExp).format("DD/MM/YYYY");
 

    const propertyListVariables = {
        skip: (page-1) * rowsPerPage,
        limit: rowsPerPage,
        selectedArea: areaFilter,
        selectedPeriod: rentalPeriodFilter,
        selectedPrice: priceFilter,
        selectedBed: parseFloat(bedFilter),
        selectedBath: parseFloat(bathFilter),
        keywordSearch: keyWordSearch,
        rentalPeriods: rentalPeriod,
        locations: variable1,
        isPrivate: isPrivate,
        amenities: amenityName,
        isCommercial: isCommercial,
        isResidential: isResidential,
        listWater: waterfront,
        listTennis: tennis,
        listPool: pools,
        listPet: pets,
        isFeatured: true,
       // status: status,
        subArea:variable2
      };
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    fetchMore({
      variables: propertyListVariables,
    });
  };
  const {
    data: propertyList,
    loading,
    fetchMore,
  } = useQuery(PROPERTY_SEARCH, { 
    variables: propertyListVariables, 
   // fetchPolicy: "network-only",
    // onCompleted:res=>{
    // }
 });
 const propertyLists =
    propertyList &&
    propertyList.propertySearch &&
    propertyList.propertySearch.data;

  const propertyCount =
    propertyList?.propertySearch && propertyList?.propertySearch?.count;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleSearch(params) {
    setVariable2(isCheck);
    setVariable1(selected);
    if (openClicked === false) setOpenClicked(!openClicked);
    if (params.search) {
      setKeyWordSearch(params.search);
    } else {
      setKeyWordSearch("");
    }
    if (params.area) {
      if (params.area === "Any Area") setAreaFilter("");
      else setAreaFilter(params.area);
    }
    if (params.period) {
      if (params.period === "Any Periods") setRentalPeriodFilter("");
      else setRentalPeriodFilter(params.period);
    }
    setPriceFilter(amount);
    // if (params.price) {
    //   if (params.price === "Any Price") setPriceFilter("");
    //   else setPriceFilter(params.price);
    // }
    if (params.bed) {
      if (params.bed === "Any Beds") setBedFilter("");
      else setBedFilter(params.bed);
    }
    if (params.bath) {
      if (params.bath === "Any Baths") setBathFilter("");
      else setBathFilter(params.bath);
    }

    if (params.tennis) {
      if (params.tennis === "Any Tennis") setTennis("");
      else setTennis(params.tennis);
    }
    if (params.pools) {
      if (params.pools === "Any Pools") setPools("");
      else setPools(params.pools);
    }
    if (params.pets) {
      if (params.pets === "Any Pets") setPets("");
      else setPets(params.pets);
    }
    if (params.location) {
      setLocation(params.location);
    }
    if (params.rentalPeriod) {
      setRentalPeriod(params.rentalPeriod);
    }
    if (params.amenity) {
      setAmenityName(params.amenity);
    }
    if (params.waterfront) {
      setWaterfront(params.waterfront);
    }
    setIsPrivate(propertyType.isPrivateProperty);
    setIsCommercial(propertyType.commercial);
    setIsResidential(propertyType.residential);
  }

  // eslint-disable-next-line no-unused-vars
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  const handleMenuChange = (type, item, target) => {
    console.log("item.....", item?._id);
    item && item._id && setitemDetails(item._id);
    item && setitemDetails(item);
    if (item) {
      setitemDetails(item);
    }
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "MENU":
        setAnchorEl(target);
        break;
        case "Feature":
            setOpenFeatured(true);
            setAnchorEl(false);
            break;
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleClick = (event) => {
    setAnchorElPrice(event.currentTarget);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChange = (event, newValue) => {
    // console.log("nv", newValue)
    let strArray = newValue;
    let strArr = strArray.map(function (e) {
      return e.toString();
    });
    setAmount(strArr);
  };
  // eslint-disable-next-line no-unused-vars
  const handleClosePrice = () => {
    setAnchorElPrice(null);
  };
  const openPrice = Boolean(anchorElPrice);
  // eslint-disable-next-line no-unused-vars
  const id = openPrice ? "simple-popover" : undefined;
  // eslint-disable-next-line no-unused-vars
  function valuetext(value) {
    return value;
  }
  const followersMarks = [
    {
      value: 1000,
      scaledValue: 1000,
      label: "1k",
    },
    {
      value: 5000,
      scaledValue: 5000,
      label: "5k",
    },
    {
      value: 10000,
      scaledValue: 10000,
      label: "10k",
    },
    {
      value: 25000,
      scaledValue: 25000,
      label: "25k",
    },
    {
      value: 50000,
      scaledValue: 50000,
      label: "50k",
    },
    {
      value: 100000,
      scaledValue: 100000,
      label: "100k+",
    },
    // {
    //   value: 250000,
    //   scaledValue: 250000,
    //   label: "250k"
    // },
    // {
    //   value: 500000,
    //   scaledValue: 500000,
    //   label: "500k"
    // },
    // {
    //   value: 1000000,
    //   scaledValue: 1000000,
    //   label: "1M+"
    // }
  ];
  // eslint-disable-next-line no-unused-vars
  const scale = (value) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };

  // function numFormatter(num) {
  //   if (num > 999 && num < 1000000) {
  //     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  //   } else if (num >= 1000000) {
  //     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  //   }else if (num < 900) {
  //     return num; // if value < 1000, nothing to do
  //   }
  // }
  // eslint-disable-next-line no-unused-vars
  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 100000) {
      return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  //console.log("itemDetails------>",itemDetails)
  const handleFeatured=()=>{
    //console.log("selectedId",selectedId)
    try {
      featuredProperty({ variables: {propertyIds:selectedId} })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property successfully updated",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  const handleChecked = (item, value) => {
    let arrId = [...selectedId];
    let array = [...selectedItem];
    let privateArr= [...privateItem]
    if (value === true) {
      array.push(item);
      arrId.push(item._id);
      if(item?.IsPrivate===true)privateArr.push(item?.IsPrivate);
      
    } else {
      let index = arrId.indexOf(item._id);
      arrId.splice(index, 1);
      array.splice(index, 1);
      privateArr.splice(index,1)
    }
    setSelectedId(arrId);
    setSelectedItem(array);
    setPrivateItem(privateArr)
    handleAvailableIDs(arrId);
  };
  const handleAvailableIDs = (idArray)=>{
    let allIds = idArray ? [...idArray] : [...selectedId];
    // eslint-disable-next-line no-unused-vars
    let flag = true;
    propertyLists.forEach(element=>
      {if(!allIds.includes(element._id) &&
        !(
          element.Status === "Delete" ||
          element.Status === "Sold" ||
          element.Status === "Off the Market" ||
          element.Status === "inactive" ||
          element.Status === null
        ))
          flag=false;
    })
    //setSelectAllChecked(flag);
  }
  console.log("selectedItem",selectedItem)
  const handleClearAll=()=>{
    setAreaFilter("")
     setKeyWordSearch("")
     setVariable1([])
     setVariable2([])
     setSelected([])
     setSubArea([])
     setIsCheck([])
    
   }
  return (
    <div className="custom-layout">
         { openFeaturedAll&&  <DialogBox
            open={openFeaturedAll}
            setOpen={setOpenFeaturedAll}
            handlePositiveBtn={handleFeatured}
            title={"UnFeature Property" }
            text={"Are you sure want to make property UnFeature?"}
          />}
          {openLocations &&
        <Locations
          openLocations={openLocations}
          setOpenLocations={setOpenLocations}
          setLocation={setLocation}
          setSubArea={setSubArea}
          setSelected={setSelected}
          selected={selected}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
        // data= {data?.leadsDetails?.UserDtls?.Email}
        // recipientId={data?.leadsDetails?.UserId}
        />
      }
      <Loader open={loading} />
      <LoggedInHeader
        //handleAddBtnClick={handleAddBtnClick}
        label="Featured"
        //addBtnLabel="Add Property"
      />
      <div className="custom-layout-content adminPropertyListing-content">
        <div className="search-band search-band-properties ps-0">
          <form onSubmit={handleSubmit(handleSearch)}>
            <FormControl className="search-town ps-2">
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Ad's Title, Address, PID"
                {...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>
            <FormControl className="search-town ps-2" onClick={() => setOpenLocations(true)}>
              {/* <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel> */}
              {/* <Controller
                control={control}
                name="location"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      // variant="outlined"
                      value={value && value?.length > 0 ? value : [""]}
                      onChange={(e) =>
                        onChange(
                          e.target.value[0] === ""
                            ? [e.target.value[1]]
                            : e.target.value
                        )
                      }
                      // input={<Input />}
                      renderValue={(selected) =>
                        selected[0] === ""
                          ? "Locations"
                          : ` Locations (${selected.length} selected)`
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} disabled value={""}>
                        Locations
                      </MenuItem>
                      {areaOptions?.sort(sortLabels).map((item) => (
                        <MenuItem
                          className="advance-filter-listItem"
                          key={item.value}
                          value={item.value}
                        >
                          <Checkbox
                            color="primary"
                            checked={value?.indexOf(item.value) > -1}
                          />
                          <ListItemText primary={item.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              /> */}
              <TextField
              disabled={true}
              id="standard-full-width"
              className="fullWidthSearch"
              label="LOCATION"
              disableUnderline={false}
              placeholder={selected.length>0||subArea.length>0? selected.length+subArea.length+ " Selected":"City/Town"}
              // {...register("search")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                disableUnderline: false,
              }}
            />
              {/* <InputLabel>TOWN</InputLabel>
              <Select
                defaultValue="Any Area"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("area")}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value="Any Area">{"Which Area?"}</MenuItem>
                {areaOptions?.sort(sortLabels).map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
            {/* <FormControl>
              <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
              <Controller
                control={control}
                name="rentalPeriod"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      // variant="outlined"
                      value={value && value?.length > 0 ? value : [""]}
                      onChange={(e) =>
                        onChange(
                          e.target.value[0] === ""
                            ? [e.target.value[1]]
                            : e.target.value
                        )
                      }
                      // input={<Input />}
                      renderValue={(selected) =>
                        selected[0] === ""
                          ? "Periods"
                          : ` Periods (${selected.length} selected)`
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} disabled value={""}>
                        Periods
                      </MenuItem>
                      {rentalPeriodOptions.map((item) => (
                        <MenuItem
                          className="advance-filter-listItem"
                          key={item.RentalPeriod}
                          value={item.RentalPeriod}
                        >
                          <Checkbox
                            color="primary"
                            checked={value?.indexOf(item.RentalPeriod) > -1}
                          />
                          <ListItemText primary={item.RentalPeriod} />
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl> */}
            {/* <FormControl>
              <div
                className="price-filter-box-outer"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <b style={{ cursor: "pointer" }} className="price-filter-label">
                  PRICE
                </b>
                <div style={{ cursor: "pointer" }} className="price-filter-box">
                  {amount[0] === "0" && amount[1] === "1000000"
                    ? "Any Price "
                    : "$" + amount[0] + " - " + "$" + amount[1]}
                  <ArrowDropDownIcon />
                </div>
              </div>
              <Popover2
                className="price-filter"
                id={id}
                open={openPrice}
                anchorEl={anchorElPrice}
                onClose={handleClosePrice}
                anchorPosition={{ top: 2000, left: 400 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography className={classes.typography}>
                  <div className={classes.root}>
                    <Typography id="range-slider" gutterBottom>
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginLeft: "460px",
                          marginTop: "5px",
                        }}
                        onClick={() => handleClosePrice()}
                      />
                      Price range
                    </Typography>
                    <Slider
                      value={amount}
                      step={1}
                      valueLabelFormat={numFormatter}
                      marks={followersMarks}
                      // scale={scale}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      max={100000}
                      min={0}
                    />
                  </div>
                </Typography>
                <div style={{ display: "flex" }}>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={amount[0]}
                      placeholder="Min"
                      onChange={(e) => {
                        setAmount([e.target.value, amount[1]]);
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      labelWidth={30}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      placeholder="Max"
                      value={amount[1]}
                      onChange={(e) => {
                        setAmount([amount[0], e.target.value]);
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      labelWidth={30}
                    />
                  </FormControl>
                </div>
              </Popover2> */}
              {/* <InputLabel>PRICE</InputLabel>
              <Select
                defaultValue="Any Price"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("price")}
              >
                <MenuItem value="Any Price">{"Any Price"}</MenuItem>
                {priceOptions.map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select> */}
            {/* </FormControl> */}
            {/* <FormControl>
              <InputLabel>BED</InputLabel>
              <Select
                defaultValue="Any Beds"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("bed")}
              >
                <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                {bedRoomsOptions.map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
{/* 
            <FormControl>
              <InputLabel>BATH</InputLabel>
              <Select
                defaultValue="Any Baths"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("bath")}
              >
                <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                {bathroomOptions.map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl className="search-btn-outer">
              <Button
                className="search-btn"
                variant="contained"
                type="submit"
                // onClick={()=>{setOpenClicked(!openClicked)}}
              >
                Search
              </Button>
              <Button  type="submit" onClick={()=>{reset();handleClearAll()}}  className="clear-filter p-2 ms-5" style={{minWidth: "auto"}} data-toggle="tooltip" title="Clear Filter">
                    <img src="../assets/images/icon-reset.svg" className="img-fluid" alt="Clear Filter"/>
                  </Button>
              {/* <MoreHorizIcon  onClick={()=>{setOpenClicked(!openClicked)}}/> */}
              {/* <label
                onClick={() => {
                  setOpenClicked(!openClicked);
                }}
                className="advanced-filter-btn"
              >
                {openClicked === true ? <>More</> : <>Less</>}
                {openClicked === true ? (
                  <img
                    src="../assets/images/more-open.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                ) : (
                  <img
                    src="../assets/images/more-close.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                )}
              </label> */}
            </FormControl>
          </form>
        </div>
        {!openClicked && (
          <div>
            <div className="advance-filter advance-filter-admin">
              <form onSubmit={handleSubmit(handleSearch)}>
                <div className="row">
                  <h6>Advanced Filter</h6>
                  {/* <div className="col-md-12 ms-2">
                  <h6>Property Type</h6>
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.residential}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="residential"
                        color="primary"
                      // {...register("residential")}
                      />
                    }
                    label="Residential"
                  />
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.commercial}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="commercial"
                        color="primary"
                      // {...register("commercial")}
                      />
                    }
                    label="Commercial"
                  />
                </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        name="amenity"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value.length)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Amenities?"
                                  : ` Amenities (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Amenities
                              </MenuItem>
                              {mainAmenityOptions.map((name) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={name.value}
                                  value={name.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(name.value) > -1}
                                  />
                                  <ListItemText primary={name.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}

                      {/* <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            variant="oulined"
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={value ? value : []}
                            onChange={(e) => onChange(e.target.value)}
                            // onChange={(e)=>{setAmenityName(e.target.value)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} className={classes.chip} />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {amenities.map((amenity) => (
                              <MenuItem key={amenity.value} value={amenity.value} >
                                {amenity.label}
                              </MenuItem>
                            ))}
                          </Select>)
                      }}
                    /> */}
                    {/* </FormControl>
                  </div> */}
                  {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
                    <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Periods" : ` Periods (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Periods</MenuItem>
                            {rentalPeriodOptions.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                                <Checkbox color="primary" checked={value?.indexOf(item.RentalPeriod) > -1} />
                                <ListItemText primary={item.RentalPeriod} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}
                  {/* <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rentalPeriodOptions.map((item) => (
                            <MenuItem key={item.RentalPeriod} value={item.RentalPeriod}>
                              {item.RentalPeriod}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                  {/* </FormControl>
                </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        name="waterfront"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Waterfronts?"
                                  : ` Waterfronts (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Waterfronts
                              </MenuItem>
                              {waterfrontOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={item.value}
                                  value={item.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(item.value) > -1}
                                  />
                                  <ListItemText primary={item.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}
                      {/* <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {waterfrontOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                    {/* </FormControl>
                  </div> */}

                  {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                    <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Locations" : ` Locations (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Locations</MenuItem>
                            {areaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}

                  {/* <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setLocation(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {areaOptions?.sort(sortLabels).map((item) => (
                            <MenuItem value={item.label}>{item.value}</MenuItem>
                          ))}

                        </Select>)}
                    /> */}
                  {/* </FormControl>
                </div> */}

                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Select
                        // input={<Input/>}
                        variant="outlined"
                        defaultValue={tennis === "" ? "Any Tennis" : tennis}
                        MenuProps={MenuProps}
                        {...register("tennis")}
                      >
                        <MenuItem
                          style={{ color: "#6e7073" }}
                          value="Any Tennis"
                        >
                          {"Tennis?"}
                        </MenuItem>
                        {tennisOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Select
                        // input={<Input/>}
                        variant="outlined"
                        defaultValue={pools === "" ? "Any Pools" : pools}
                        MenuProps={MenuProps}
                        {...register("pools")}
                      >
                        <MenuItem
                          style={{ color: "#6e7073" }}
                          value="Any Pools"
                        >
                          {"Pool?"}
                        </MenuItem>
                        {poolOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Select
                        variant="outlined"
                        // input={<Input/>}
                        defaultValue={pets === "" ? "Any Pets" : pets}
                        MenuProps={MenuProps}
                        {...register("pets")}
                      >
                        <MenuItem style={{ color: "#6e7073" }} value="Any Pets">
                          {"Pets?"}
                        </MenuItem>
                        {petsOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* <div className="col-md-12 mb-2 ms-2">
                    <h6 className="mt-3">Property List as</h6>
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={propertyType.isPrivateProperty}
                          onChange={(e) =>
                            setPropertyType({
                              ...propertyType,
                              [e.target.name]: e.target.checked,
                            })
                          }
                          name="isPrivateProperty"
                          color="primary"
                          // {...register("isPrivateProperty")}
                        />
                      }
                      label="Private"
                    />
                  </div> */}
                 
                </div>
              </form>
            </div>
          </div>
          
        )} 
        <div className="adminListingSearhButton">
             <Button 
          type="submit"
           color="primary" 
           variant="outlined"
           //disabled= {privateItem.length>0}
           onClick={()=>{
            selectedItem.length > 0 ?
            setOpenFeaturedAll(true)
           :
             toggleSnackbar({
               status: true,
               message: "please select Property",
               variant: "error",
             })
           }}
           >
            Unfeature
          </Button>
        </div>

        <div className="content-wrap featured">
      {propertyCount>0&&<label className="admin-property-count">{propertyCount+" results"}</label>}
          <SideBar selected="featured" count={propertyCount} />
          <div className="adminListing">
            {propertyLists && propertyLists.length
              ? propertyLists?.map((item, index) => (
                  <div className="adminListingItem">
                    <div className="al-itemImg-initialDetails">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="al-itemImg">
                            <img
                              src={getFeaturedImage(item?.Images)}
                              className="img-fluid"
                              alt=""
                            />
                            {item?.IsPrivate && (
                              <label className="exclusive-label">
                                {item?.IsPrivate === true ? "Exclusive" : null}
                              </label>
                            )}
                            <Checkbox
                    disabled={
                      item.Status === "Delete" ||
                      item.Status === "Sold" ||
                      item.Status === "Off the Market"
                      ||
                      item.Status==="inactive"
                      ||
                      item.Status==null
                    }
                    color="secondary"
                    size="small"
                    checked={selectedId?.includes(item._id)}
                    onChange={(e) =>
                     handleChecked(item, e.target.checked)
                    }
                  />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="al-initialDetails">
                            <div className="al-head">
                              <div>
                              {item?.IsFeatured===true&&<div className="adminFeaturedTag">Featured</div>}
                                <address>
                                  PID#{item.PID} {item.StreetAddress}{", "}{item?.Area}
                                </address>
                                <h3 style={{ color: "#A64C48" }}>
                                  {item?.AdTitle}
                                  {/* {item?.AdTitle?.charAt(0).toUpperCase() +
                                    item?.AdTitle?.slice(1).toLowerCase()} */}
                                </h3>
                                {item?.PriceData?.SalesPrice &&
                                item?.PriceData?.SalesPrice !== 0 ? (
                                  <h6>
                                    SalePrice:{" "}
                                    <font>
                                      $
                                      {formatePrice(
                                        item?.PriceData?.SalesPrice
                                      )}
                                    </font>
                                  </h6>
                                ) : null}
                              </div>
                              <div className="al-head-btn">
                                {item?.Status && (
                                  <Button
                                    type="submit"
                                    color="sucess"
                                    variant="contained"
                                    className="green-btn"
                                  >
                                    {item?.Status === "requested"
                                      ? "Pending"
                                      : item?.Status?.charAt(0).toUpperCase() +
                                        item?.Status?.slice(1).toLowerCase()}
                                  </Button>
                                )}
                                <Button
                                  style={{ backgroundColor: "#000" }}
                                  onClick={(e) =>
                                    handleMenuChange(
                                      "MENU",
                                      item,
                                      e.currentTarget
                                    )
                                  }
                                >
                                  <img
                                    src="../assets/images/dot-group.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                </Button>
                              </div>
                            </div>
                            {item?.AdDescription && (
                              <TexttView
                                text={
                                  item?.AdDescription?.charAt(0).toUpperCase() +
                                  item?.AdDescription?.slice(1).toLowerCase()
                                }
                              />
                              // <p>
                              //   {item?.AdDescription.charAt(0).toUpperCase() +
                              //     item?.AdDescription.slice(1).toLowerCase()}
                              // </p>
                            )}
                            {item?.ListMainAmenity && (
                              <ul className="al-style propertyStyleTag">
                                {item?.ListMainAmenity &&
                                  item?.ListMainAmenity.map((items) =>
                                    items?.Flag === true ? (
                                      <li
                                        style={{
                                          backgroundColor: "#A64C48",
                                          color: "#fff",
                                        }}
                                      >
                                        {items?.Title}
                                      </li>
                                    ) : items.Flag === false ? (
                                      <li
                                        style={{
                                          backgroundColor: "#A64C48",
                                          color: "#fff",
                                        }}
                                      >
                                        <del>{items?.Title}</del>
                                      </li>
                                    ) : (
                                      ""
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="al-aminities-price">
                      <ul>
                        {(item.ListBedRoom ||
                          item?.PropertyEditedFields?.ListBedRoom) && (
                          <li>
                            <label>Bed:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListBedRoom}
                              </del>
                              <span className="updated-value">
                                {item.ListBedRoom}
                              </span>
                            </div>
                          </li>
                        )}
                        {/* {item?.PropertyEditedFields?.ListBedRoom && (
                        <li>
                        <label>Bed:</label> 
                          {item?.PropertyEditedFields?.ListBedRoom}
                        </li>
                      )} */}
                        {(item.ListBathRoom ||
                          item?.PropertyEditedFields?.ListBathRoom) && (
                          <li>
                            <label>Bath:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListBathRoom}
                              </del>
                              <span className="updated-value">
                                {item.ListBathRoom}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListStyle ||
                          item?.PropertyEditedFields?.ListStyle) && (
                          <li>
                            <label>Style:</label>
                            <div className="updated-figure">
                              <del>{item?.PropertyEditedFields?.ListStyle}</del>
                              <span className="updated-value">
                                {item.ListStyle}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.LivingArea ||
                          item?.PropertyEditedFields?.LivingArea) && (
                          <li>
                            <label>Living Area:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.LivingArea}
                              </del>
                              <span className="updated-value">
                                {item.LivingArea}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.YearBuilt ||
                          item?.PropertyEditedFields?.YearBuilt) && (
                          <li>
                            <label>Year Build:</label>
                            <div className="updated-figure">
                              {item?.PropertyEditedFields?.YearBuilt && (
                                <del>
                                  {item?.PropertyEditedFields?.YearBuilt}
                                </del>
                              )}
                              {item.YearBuilt && (
                                <span className="updated-value">
                                  {item.YearBuilt}
                                </span>
                              )}
                            </div>
                          </li>
                        )}
                        {(item.ListHeat ||
                          item?.PropertyEditedFields?.ListHeat) && (
                          <li>
                            <label>Heat:</label>
                            <div className="updated-figure">
                              <del>{item?.PropertyEditedFields?.ListHeat}</del>
                              <span className="updated-value">
                                {item.ListHeat}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListGarage ||
                          item?.PropertyEditedFields?.ListGarage) && (
                          <li>
                            <label>Garage:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListGarage}
                              </del>
                              <span className="updated-value">
                                {item.ListGarage}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListBasement ||
                          item?.PropertyEditedFields?.ListBasement) && (
                          <li>
                            <label>Basement:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListBasement}
                              </del>
                              <span className="updated-value">
                                {item.ListBasement}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListAirCondition ||
                          item?.PropertyEditedFields?.ListAirCondition) && (
                          <li>
                            <label>A/C:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.ListAirCondition}
                              </del>
                              <span className="updated-value">
                                {item.ListAirCondition}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.LotSize ||
                          item?.PropertyEditedFields?.LotSize) && (
                          <li>
                            <label>Lot Size:</label>
                            <div className="updated-figure">
                              <del>{item?.PropertyEditedFields?.LotSize}</del>
                              <span className="updated-value">
                                {item.LotSize}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.BedBathConfiguration ||
                          item?.PropertyEditedFields?.BedBathConfiguration) && (
                          <li>
                            <label>Bed Bath Configuration:</label>
                            <div className="updated-figure">
                              <del>
                                {
                                  item?.PropertyEditedFields
                                    ?.BedBathConfiguration
                                }
                              </del>
                              <span className="updated-value">
                                {item.BedBathConfiguration}
                              </span>
                            </div>
                          </li>
                        )}
                            {(item.ListPool ||
                          item?.PropertyEditedFields?.ListPool) && (
                          <li>
                            <label>Pool:</label>
                            <div className="updated-figure">
                              <del>
                                {
                                  item?.PropertyEditedFields
                                    ?.ListPool
                                }
                              </del>
                              <span className="updated-value">
                                {item.ListPool}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListStory ||
                          item?.PropertyEditedFields?.ListStory) && (
                          <li>
                            <label>Stories:</label>
                            <div className="updated-figure">
                              <del>{item?.PropertyEditedFields?.ListStory}</del>
                              <span className="updated-value">
                                {item.ListStory}
                              </span>
                            </div>
                          </li>
                        )}
                        {(formateTaxMap(item.SuffCoTaxMap) ||
                          formateTaxMap(
                            item?.PropertyEditedFields?.SuffCoTaxMap
                          )) && (
                          <li>
                            <label>TaxMap:</label>
                            {/* {formateTaxMap(item.SuffCoTaxMap)} */}
                            <div className="updated-figure">
                              <del>
                                {formateTaxMap(
                                  item?.PropertyEditedFields?.SuffCoTaxMap
                                )}
                              </del>
                              <span className="updated-value">
                                {formateTaxMap(item.SuffCoTaxMap)}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.RentalRegisteryId ||
                          item?.PropertyEditedFields?.RentalRegisteryId) && (
                          <li>
                            <label>RRN ID:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.RentalRegisteryId}
                              </del>
                              <span className="updated-value">
                                {item.RentalRegisteryId}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.RRExp || item?.PropertyEditedFields?.RRExp) && (
                          <li>
                            <label>RR Expires on:</label>
                            {/* {moment(item.RRExp).format("DD/MM/YYYY")} */}
                            <div className="updated-figure">
                              {item?.PropertyEditedFields?.RRExp && (
                                <del>
                                  {item?.PropertyEditedFields?.RRExp && item?.PropertyEditedFields?.RRExp.length>13? moment(item?.PropertyEditedFields?.RRExp).format("MM/DD/YYYY"): moment.unix(item?.PropertyEditedFields?.RRExp/1000).format("MM/DD/YYYY")}
                                  {/* {moment(
                                    item?.PropertyEditedFields?.RRExp
                                  ).format("MM/DD/YYYY")} */}
                                </del>
                              )}
                              <span className="updated-value">
                                {item.RRExp && item.RRExp.length>13? moment(item.RRExp).format("MM/DD/YYYY"): moment.unix(item.RRExp/1000).format("MM/DD/YYYY")}
                                {/* {moment(item.RRExp).format("MM/DD/YYYY")} */}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListAgentJustification ||
                          item?.PropertyEditedFields
                            ?.ListAgentJustification) && (
                          <li>
                            <label>Agent Justification:</label>
                            <div className="updated-figure">
                              <del>
                                {
                                  item?.PropertyEditedFields
                                    ?.ListAgentJustification
                                }
                              </del>
                              <span className="updated-value">
                                {item.ListAgentJustification}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.ListKey ||
                          item?.PropertyEditedFields?.ListKey) && (
                          <li>
                            <label>Key:</label>
                            <div className="updated-figure">
                              <del>{item?.PropertyEditedFields?.ListKey}</del>
                              <span className="updated-value">
                                {item.ListKey}
                              </span>
                            </div>
                          </li>
                        )}
                        {(item.KeyLocation ||
                          item?.PropertyEditedFields?.KeyLocation) && (
                          <li>
                            <label>Key Location:</label>
                            <div className="updated-figure">
                              <del>
                                {item?.PropertyEditedFields?.KeyLocation}
                              </del>
                              <span className="updated-value">
                                {item.KeyLocation}
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>

                      {/* <ul>
                      {item?.PropertyEditedFields?.ListBedRoom && (
                        <li>
                          <label>Bed:</label>
                          {item?.PropertyEditedFields?.ListBedRoom}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListBathRoom && (
                        <li>
                          <label>Bath:</label>
                          {item?.PropertyEditedFields?.ListBathRoom}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListStyle && (
                        <li>
                          <label>Style:</label>
                          {item?.PropertyEditedFields?.ListStyle}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.LivingArea && (
                        <li>
                          <label>Living Area:</label>
                          {item?.PropertyEditedFields?.LivingArea}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.YearBuilt && (
                        <li>
                          <label>Year Build:</label>
                          {item?.PropertyEditedFields?.YearBuilt}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListHeat && (
                        <li>
                          <label>Heat:</label>
                          {item?.PropertyEditedFields?.ListHeat}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListGarage && (
                        <li>
                          <label>Garage:</label>
                          {item?.PropertyEditedFields?.ListGarage}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListBasement && (
                        <li>
                          <label>Basement:</label>
                          {item?.PropertyEditedFields?.ListBasement}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListAirCondition && (
                        <li>
                          <label>A/C:</label>
                          {item?.PropertyEditedFields?.ListAirCondition}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.LotSize && (
                        <li>
                          <label>Lot Size:</label>
                          {item?.PropertyEditedFields?.LotSize}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.BedBathConfiguration && (
                        <li>
                          <label>Bed Bath Configuration:</label>
                          {item?.PropertyEditedFields?.BedBathConfiguration}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListStory && (
                        <li>
                          <label>Stories:</label>
                          {item?.PropertyEditedFields?.ListStory}
                        </li>
                      )}
                      {formateTaxMap(
                        item?.PropertyEditedFields?.SuffCoTaxMap
                      ) && (
                        <li>
                          <label>TaxMap:</label>
                          {formateTaxMap(
                            item?.PropertyEditedFields?.SuffCoTaxMap
                          )}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.RentalRegisteryId && (
                        <li>
                          <label>RRN ID:</label>
                          {item?.PropertyEditedFields?.RentalRegisteryId}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.RRExp && (
                        <li>
                          <label>RR Expires on:</label>
                          {moment(item?.PropertyEditedFields?.RRExp).format(
                            "DD/MM/YYYY"
                          )}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListAgentJustification && (
                        <li>
                          <label>Agent Justification:</label>
                          {item?.PropertyEditedFields?.ListAgentJustification}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.ListKey && (
                        <li>
                          <label>Key:</label>
                          {item?.PropertyEditedFields?.ListKey}
                        </li>
                      )}
                      {item?.PropertyEditedFields?.KeyLocation && (
                        <li>
                          <label>Key Location:</label>
                          {item?.PropertyEditedFields?.KeyLocation}
                        </li>
                      )}
                    </ul> */}

                      {/* {item?.PriceData?.PriceDetails &&
                        chechForPrice(item?.PriceData?.PriceDetails) && (
                          <ul>
                            {item?.PriceData?.PriceDetails &&
                              item?.PriceData?.PriceDetails.map((items) => {
                                if (
                                  items.AvblStatus.toLowerCase() === "yes" ||
                                  items.AvblStatus.toLowerCase() === "rented"
                                )
                                  return (
                                    <li>
                                      <label>
                                        <img
                                          src={
                                            items.AvblStatus.toLowerCase() ===
                                            "yes"
                                              ? "../assets/images/green-check.svg"
                                              : "../assets/images/red-check.svg"
                                          }
                                          className="img-fluid"
                                          alt="Tick"
                                        />{" "}
                                        {items.RentalPeriod}
                                      </label>
                                      <div className="updated-figure">
                                        {items.PrePrice != 0 && (
                                          <del>
                                            ${formatePrice(items.PrePrice)}
                                          </del>
                                        )}
                                        <span className="updated-value">
                                          ${formatePrice(items.Price)}
                                        </span>
                                      </div>
                                    </li>
                                  );
                              })}
                          </ul>
                        )} */}

                      {/* {item?.PriceData?.PriceDetails &&
                      chechForPrice(item?.PriceData?.PriceDetails) && (
                        <ul>
                          {item?.PriceData?.PriceDetails &&
                            item?.PriceData?.PriceDetails.map((items) => {
                              if (
                                items.AvblStatus.toLowerCase() === "yes" ||
                                items.AvblStatus.toLowerCase() === "rented"
                              )
                                return (
                                  <li>
                                    <label>
                                      <img
                                        src={
                                          items.AvblStatus.toLowerCase() ===
                                          "yes"
                                            ? "../assets/images/green-check.svg"
                                            : "../assets/images/red-check.svg"
                                        }
                                        className="img-fluid"
                                        alt="Tick"
                                      />{" "}
                                      {items.RentalPeriod}
                                    </label>
                                    ${formatePrice(items.PrePrice)}
                                  </li>
                                );
                            })}
                        </ul>
                      )} */}
                    </div>
                    {}
                    {/* {item?.PriceData?.ShortTermPriceDetails &&
                      item?.PriceData?.ShortTermPriceDetails.length > 0 && (
                        <div>
                          <br />
                          <h5>Short Term Values (Priced Weekly)</h5>
                          <div style={{ paddingBottom: "20px" }}>
                            {item?.PriceData?.ShortTermPriceDetails.map(
                              (items) => {
                                return (
                                  <Chip
                                    className="me-2"
                                    label={`${convertTimeStamp(
                                      items.DateFrom
                                    )} - ${convertTimeStamp(items.DateTo)} $${
                                      items.Amount
                                    }`}
                                  />
                                );
                              }
                            )}
                          </div>
                          <Divider />
                        </div>
                      )} */}
                    {/* <div className="al-owner-agent-details">
                      <div className="row">
                        <div className="col-md-3">
                          {item?.OwnerDtls?.FirstName && (
                            <h6>
                              {`${item?.OwnerDtls?.FirstName} ${item?.OwnerDtls?.LastName}`}
                              <span>(OWNER)</span>
                            </h6>
                          )}
                          <ul>
                            {item?.OwnerDtls?.HomePhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                H-{item?.OwnerDtls?.HomePhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.OfficePhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                O-{item?.OwnerDtls?.OfficePhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.CellPhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                C-{item?.OwnerDtls?.CellPhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.LocalPhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                L-{item?.OwnerDtls?.LocalPhone}
                              </li>
                            )}

                            {item?.OwnerDtls?.Email && (
                              <li>
                                <img
                                  src="../assets/images/email.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                {item?.OwnerDtls?.Email}
                              </li>
                            )}
                          </ul>
                        </div>
                        {item?.AgentDtls && (
                          <div className="col-md-3">
                            {item?.AgentDtls?.FirstName && (
                              <h6>
                                {`${item?.AgentDtls?.FirstName} ${item?.AgentDtls?.LastName}`}
                                <span>(PROPERTY AGENT)</span>
                              </h6>
                            )}
                            <ul>
                              {item?.AgentDtls?.HomePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  H-{item?.AgentDtls?.HomePhone}
                                </li>
                              )}
                              {item?.AgentDtls?.OfficePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  O-{item?.AgentDtls?.OfficePhone}
                                </li>
                              )}
                              {item?.AgentDtls?.Phone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  C-{item?.AgentDtls?.Phone}
                                </li>
                              )}
                              {item?.AgentDtls?.LocalPhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  L-{item?.AgentDtls?.LocalPhone}
                                </li>
                              )}

                              {item?.AgentDtls?.Email && (
                                <li>
                                  <img
                                    src="../assets/images/email.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  {item?.AgentDtls?.Email}
                                </li>
                              )}
                              <li>(Agent Input Listing)</li>
                            </ul>
                          </div>
                        )}
                        <div className="col-md-3">
                          <ul className="al-extra">
                            {item.SpecialInfo && (
                              <li>
                                <span>Key Special Info:</span>
                                <label>{item.SpecialInfo}</label>
                              </li>
                            )}
                            {item.ShowingIns && (
                              <li>
                                <span>SI:</span>
                                <label>{item.ShowingIns}</label>
                              </li>
                            )}
                            {item.AlaramCode && (
                              <li>
                                <span>Alarm:</span>
                                <label>{item.AlaramCode}</label>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <hr />
                    {/* <div className="al-comments">
                      <ul>
                        {item?.Comments &&
                          [...item?.Comments].reverse().map((items, index) => {
                            if (!moreComment && index > 1) return null;
                            return (
                              <li>
                                {items.Comment},{" "}
                                <b>
                                  - {items.UserName}{" "}
                                  {convertTimeStampWithTime(items.CreatedOn)}
                                </b>
                              </li>
                            );
                          })}
                      </ul>
                      {item?.Comments && item?.Comments.length > 2 && (
                        <a
                          className="view-comment"
                          onClick={() => setMoreComment(!moreComment)}
                        >
                          {moreComment
                            ? "View less Comments"
                            : "View more Comments"}
                        </a>
                      )}
                    </div> */}
                    {/* <div className="al-dates">
                      <ul>
                        {item.CreatedOn && (
                          <li>
                            Listing Date :&nbsp;
                            <b>{convertTimeStamp(item.CreatedOn)}</b>
                          </li>
                        )}
                        {item.UpdatedOn && (
                          <li>
                            Last Update :&nbsp;
                            <b>{convertTimeStamp(item.UpdatedOn)}</b>
                          </li>
                        )}
                        {item?.UpdatedByDtls?.FirstName && (
                          <li>
                            Last Update by :&nbsp;
                            <b>
                              {item?.UpdatedByDtls?.FirstName.charAt(
                                0
                              ).toUpperCase() +
                                item?.UpdatedByDtls?.FirstName.slice(
                                  1
                                ).toLowerCase()}{" "}
                              {item?.UpdatedByDtls?.LastName.charAt(
                                0
                              ).toUpperCase() +
                                item?.UpdatedByDtls?.LastName.slice(
                                  1
                                ).toLowerCase()}
                            </b>
                          </li>
                        )}
                        <li>
                          Status :&nbsp;
                          <b>
                            {item?.Status == "requested"
                              ? "Pending"
                              : item?.Status?.charAt(0).toUpperCase() +
                                item?.Status?.slice(1).toLowerCase()}
                          </b>
                        </li>
                        
                      </ul>
                    </div> */}
                    {user?.data?.Role !== "agent" && (
                      <div className="d-flex pt-3 pb-4 justify-content-between">
                      

                        
                      </div>
                    )}
                  </div>
                ))
              : areaFilter ||
                rentalPeriodFilter ||
                priceFilter ||
                bedFilter ||
                bathFilter ||
                keyWordSearch ||
                rentalPeriod ||
                location ||
                isPrivate ||
                amenityName ||
                isCommercial ||
                isResidential ||
                waterfront ||
                tennis ||
                pools ||
                pets
              ? "No featured property to show"
              : "There is no featured property"}
          </div>
        
          <Popover1
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "5px" }}
          >
            <div
              style={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.43)",
                borderRadius: "4px",
              }}
            >
              <div className="al-action-popup">
                {options.map((option, index) => {
                  return (
                    <>
                      <Button
                        // disabled={
                        //   !option.disabled && itemDetails.Status != "approved"
                        // }
                        style={
                          option.label === "None" && option.id === "None"
                            ? { opacity: "0", cursor: "auto" }
                            : null
                        }
                        onClick={() => handleMenuChange(option.id)}
                        variant="outlined"
                        color="primary"
                      >
                        {option.label === "Feature" &&
                    itemDetails.IsFeatured === true
                      ? (option.label = "UnFeature")
                    :option.label}
                      </Button>
                    </>
                  );
                })}
              </div>
            </div>
          </Popover1>
    { openFeatured&&     <Featured
       open={openFeatured}
       setOpen={setOpenFeatured}
       propertyId={itemDetails._id}
       pid={itemDetails.PID}
       propertyStatus={itemDetails.IsFeatured}
       handlePagination={handlePagination}
      />}
          {!loading && propertyCount > 0 && (
            <Pagination
              component="div"
              className="propertyPagination"
              //rowsPerPageOptions={[]}
              count={Math.ceil(propertyCount / rowsPerPage)}
             // rowsPerPage={rowsPerPage}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default FeaturedList;
