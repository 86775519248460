/////////////Tabs////////////////////
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
////////////////////Drawer///////////////////////////
import Drawer from "@material-ui/core/Drawer";
import { useLazyQuery } from "@apollo/client";
import { PANDS_HISTORY, PROPERTY_PRICE_HIS_DETAILS } from "../../../Graghql/queries";
import moment from "moment";
/////////////////////////Tabs//////////////////////////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


////Tabs/////
const PAndSHistory=(prop)=> {
  console.log("propsssss",prop)
  const [currentTabIndex, setCurrentTabIndex] = useState(0); //Tab index
  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  // const { data } = useQuery(PANDS_HISTORY, {
  //   variables: { propertyId: prop.pandsId },
  //   fetchPolicy: "network-only",
  // });
  const [getPands, { data }] = useLazyQuery(PANDS_HISTORY, {
    fetchPolicy: "network-only",
    variables: {
      propertyId: prop.pandsId,
    }
  });
  let pandsdata = data?.propertyHisDetails;
  //console.log("data", data)
  // const { data: priceData } = useQuery(PROPERTY_PRICE_HIS_DETAILS, {
  //   variables: { propertyId: prop.pandsId },
  //   fetchPolicy: "network-only",
  // });
  const [getPriceHisDetails, { data:priceData }] = useLazyQuery(PROPERTY_PRICE_HIS_DETAILS, {
    fetchPolicy: "network-only",
  variables: {
      propertyId: prop.pandsId,
    }
  });
  let pandsPriceData = priceData?.propertyPriceHisDtls;
  //console.log("pandsPriceData", priceData);
  let commentDetails = prop.details.Comments;
  // console.log("comment",commentDetails)
  //console.log("psdata", pandsdata);
  useEffect(() => {
    if(prop.pandsId){
    getPands()
    getPriceHisDetails()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop.pandsId]);
  
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <div >
      <Drawer className="pands-section"
        anchor={"right"}
        open={prop.open}
        onClose={() => prop.setOpen(false)}>

        <h1 className="pands-heading">P&S History</h1>
        <AppBar
          color="secondary"
          //  style={{backgroundColor: "#FFFFFF", color: " #000000"}}
          position="static"
        >
          <Tabs value={currentTabIndex} onChange={handleChange}>
            <Tab className={currentTabIndex === 0 ? "active" : null} label="Property History" />
            <Tab className={currentTabIndex === 1 ? "active" : null} label="Price History" />
            <Tab className={currentTabIndex === 2 ? "active" : null} label="Agent Comments" />
          </Tabs>
        </AppBar>

        {/* property history */}

        <TabPanel value={currentTabIndex} index={0}>
          {pandsdata?.map((item, index) => (
            <div key={index}>
              <ul>
                  {item?.Status && <li className="status-bar"><label>Status:</label> <span className="status-elements1">{item?.Status && item?.Status.charAt(0).toUpperCase() +
                    item?.Status.slice(1).toLowerCase()}</span></li>}

                  {item?.ListBedRoom && <li className="status-bar"><label>Bed:</label>  <span className="status-elements2">{item?.ListBedRoom}</span></li>}
                  {item?.ListBathRoom && <li className="status-bar"><label>Bath:</label>  <span className="status-elements2">{item?.ListBathRoom}</span></li>}
                  {item?.AvailableSqFt && <li className="status-bar"><label>Living Area:</label>  <span className="status-elements2">{item?.AvailableSqFt}</span></li>}
                  {item?.LotSize && <li className="status-bar"><label>LotSize:</label>  <span className="status-elements2">{item?.LotSize}</span></li>}
                  {item?.ListStyle && <li className="status-bar"><label>Style: </label> <span className="status-elements2">{item?.ListStyle && item?.ListStyle.charAt(0).toUpperCase() +
                    item?.ListStyle.slice(1).toLowerCase()}</span></li>}
                  {item?.RentalRegisteryId && <li className="status-bar"><label>RRN ID: </label> <span className="status-elements2">{item?.RentalRegisteryId}</span></li>}
                  {item?.RRExp && <li className="status-bar"><label>RR Expires on:</label>  <span className="status-elements2">{item?.RRExp}</span></li>}
                  {item?.AdTitle && <li className="status-bar"><label>Your Ad's Caption: </label> <span className="status-elements2">{item?.AdTitle && item.AdTitle 
                    }</span> </li>}
                  {item?.AdDescription && <li className="status-bar"><label>Description:</label>  <span className="status-elements2">{item?.AdDescription.charAt(0).toUpperCase() + item?.AdDescription.slice(1).toLowerCase()}</span>
                  </li>}
                  {item?.Area && <li className="status-bar"><label>Area: </label> <span className="status-elements2">{item?.Area}</span></li>}
                  {item?.City && <li className="status-bar"><label>City: </label> <span className="status-elements2">{item?.City}</span></li>}
                  {item?.State && <li className="status-bar"><label>State:</label>  <span className="status-elements2">{item?.State}</span></li>}
                  {item?.StreetAddress && <li className="status-bar"><label>Street Address:</label>  <span className="status-elements2">{item?.StreetAddress}</span></li>}
                  {item?.Zip && <li className="status-bar"><label>Zip:</label>  <span className="status-elements2">{item?.Zip}</span></li>}

                <li className="updated-name">{item?.UpdatedByDtls?.FirstName&&item?.UpdatedByDtls?.FirstName!==null &&item?.UpdatedByDtls?.FirstName.charAt(0).toUpperCase() + item?.UpdatedByDtls?.FirstName.slice(1).toLowerCase()}  {" " }
                  {item?.UpdatedByDtls?.LastName&&item?.UpdatedByDtls?.LastName!==null && item?.UpdatedByDtls?.LastName.charAt(0).toUpperCase() + item?.UpdatedByDtls?.LastName.slice(1).toLowerCase() + " "}
                  <span className="update-date">
                    updated on  {moment(new Date(parseInt(item?.UpdatedOn))).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                </li>


                {/* <li className="updated-name">{item.UpdatedByDtls.FirstName.charAt(0).toUpperCase()+item.UpdatedByDtls.FirstName.slice(1).toLowerCase() +" "+ 
                 item.UpdatedByDtls.LastName.charAt(0).toUpperCase()+item.UpdatedByDtls.LastName.slice(1).toLowerCase()+ " "} 
          <span className="update-date"> 
            updated on  {moment(new Date(parseInt(item.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )}
          </span>
          </li> */}


                {/* <li className="updated-name">{item.UpdatedByDtls.FirstName.charAt(0).toUpperCase()+item.UpdatedByDtls.FirstName.slice(1).toLowerCase() +" "+ 
                 item.UpdatedByDtls.LastName.charAt(0).toUpperCase()+item.UpdatedByDtls.LastName.slice(1).toLowerCase()+ " "} 
          <span className="update-date"> 
            updated on  {moment(new Date(parseInt(item.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )}
          </span>
          </li> */}

                {/* <li>Area: <span className="status-review">{item.Area}</span> | City: <span className="status-review">{item.City}</span>
           | State: <span className="status-review">{item.State}</span> | Street Address: <span 
           className="status-review">{item.StreetAddress}</span> | Zip: <span className="status-review">{item.Zip}</span></li>
          <li className="updated-name">{item.UpdatedByDtls.FirstName.charAt(0).toUpperCase()+item.UpdatedByDtls.FirstName.slice(1).toLowerCase() +" "+ 
                 item.UpdatedByDtls.LastName.charAt(0).toUpperCase()+item.UpdatedByDtls.LastName.slice(1).toLowerCase()+ " "} 
          <span className="update-date"> 
            updated on  {moment(new Date(parseInt(item.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )}
          </span>
          </li> */}


                {/* <li>In Number: <span className="status-elements2">1234</span> | Key: <span className="status-elements2">
          With Agent</span> | AlarmCode: <span className="status-elements2">With Agent</span> ShowingInstruction: <span 
          className="status-elements1">Call{" "}{item.UpdatedByDtls.FirstName.charAt(0).toUpperCase()+item.UpdatedByDtls.FirstName.slice(1).toLowerCase() +" "+ 
          item.UpdatedByDtls.LastName.charAt(0).toUpperCase()+item.UpdatedByDtls.LastName.slice(1).toLowerCase()+ " "}</span></li>
          <li className="updated-name">{item.UpdatedByDtls.FirstName.charAt(0).toUpperCase()+item.UpdatedByDtls.FirstName.slice(1).toLowerCase() +" "+ 
                 item.UpdatedByDtls.LastName.charAt(0).toUpperCase()+item.UpdatedByDtls.LastName.slice(1).toLowerCase()+ " "}
          <span className="update-date"> 
            updated on  {moment(new Date(parseInt(item.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )}
          </span>
          </li> */}


                {/* <li className="status-bar">Property Added</li>
          <li className="updated-name">{item.UpdatedByDtls.FirstName +" "+ item.UpdatedByDtls.LastName+ " "}
          <span className="update-date"> 
            updated on  {moment(new Date(parseInt(item.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )}
          </span>
          </li> */}
                {/* <li>Role: {item.UpdatedByDtls.Role}</li> */}
              </ul>
            </div>
          ))}

          {/* price history */}

        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          {pandsPriceData?.map((items, index) => (
            <div key={index}>
              <ul>
                {items?.PriceDetails.map((dates) => {
                  if (dates?.AvblStatus.toLowerCase() === "yes") {
                    return (
                      <li className="status-bar">{dates?.RentalPeriod} <span className="status-review">${formatePrice(dates?.Price)}</span></li>
                    )
                  }
                  return null
                })}
                <li className="updated-name">{items?.UpdatedByDtls?.FirstName.charAt(0).toUpperCase() + items?.UpdatedByDtls?.FirstName.slice(1).toLowerCase() + " " +
                  items?.UpdatedByDtls?.LastName.charAt(0).toUpperCase() + items?.UpdatedByDtls?.LastName.slice(1).toLowerCase() + " "}
                  <span className="update-date">
                {items?.UpdatedOn&&  "updated on "+
                    moment(new Date(parseInt(items?.UpdatedOn))).format(
                      "MM/DD/YYYY"
                    )}
                    {/* updated on{" "}
                    {moment(new Date(parseInt(items?.UpdatedOn))).format(
                      "MM/DD/YYYY"
                    )} */}
                  </span>
                </li>

                {/* <li className="status-bar">July: <span className="status-review">Rented</span></li>
                 <li className="updated-name">{items.UpdatedByDtls.FirstName +" "+ items.UpdatedByDtls.LastName+ " "}
                  <span className="update-date"> 
                     updated on 10/27/2021  */}
                {/* {moment(new Date(parseInt(items.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )} */}
                {/* </span>
          </li> */}

                {/* <li className="status-bar">MD-LD: <span className="status-review">$20000</span></li>
                 <li className="updated-name">{items.UpdatedByDtls.FirstName +" "+ items.UpdatedByDtls.LastName+ " "}
                  <span className="update-date"> 
                     updated on 10/27/2021  */}
                {/* {moment(new Date(parseInt(items.UpdatedOn))).format(
                              "MM/DD/YYYY"
                            )} */}
                {/* </span>
          </li> */}


                {/* <li>hello: {items.ShortTermPriceDetails}</li> */}
                {/* <li>SalesPrice: {items.SalesPrice}</li>
              <li>Available Status: {items.AvblStatus}</li>
              <li>Rental Period: {items.RentalPeriod}</li>
              <li>Property Id: {items.propertyId}</li>
              <li>Status: {items.Status}</li>
              <li>Updated By:{items.UpdatedByDtls.FirstName +" "+ items.UpdatedByDtls.LastName}</li> */}
              </ul>
            </div>
          ))}

        </TabPanel>

        {/* agent Comments */}


        <TabPanel value={currentTabIndex} index={2}>

          {commentDetails?.map((items, index) => (
            <div key={index}>
              <p className="status-bar">{items?.Comment}</p>
              <p className="updated-name">{items?.UserName + " "}
                <span className="update-date">Commented on  {moment(new Date(parseInt(items?.UpdatedOn))).format(
                  "MM/DD/YYYY"
                )} </span> </p>
            </div>
          ))}
        </TabPanel>
      </Drawer>
    </div>
  );
}
export default PAndSHistory;
