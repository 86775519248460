import React, { useState, useRef, useEffect } from "react";
import {  useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import {  useMutation } from "@apollo/client";
import { LOGIN_USER, FORGET_PASS } from "../../Graghql/mutations";
import { authVar, userDeatils } from "../../RactiveVariables/index";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg"
//import CustomizedSnackbars from '../Common/customSnackbar';

const Login = (props) => {
  const history=useHistory()
  const emailRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [login, { loading }] = useMutation(LOGIN_USER);
  const [forgetPassword, { loading: forgetLoading }] = useMutation(FORGET_PASS);
  const [forgetPass, setForgetPass] = useState(false);
  const [responseMsg, setResponseMsg] = useState(null);

  useEffect(() => {
    if (emailRef.current) {
      register(emailRef.current);
      emailRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    //console.log("dataaaa",data)
    if (!forgetPass) {
      try {
        login({ variables: data })
          .then((res) => {
            //console.log("resss",res)
            const responseData = res.data && res.data.login;
            const token = responseData.token;
            localStorage.setItem("@authToken", token);
            authVar({
              loaded: true,
              auth: true,
              userType:
                res?.data?.login?.userNew?.UserType === "HR"
                  ? "admin"
                  : res?.data?.login?.userNew?.UserType,
            });
            userDeatils({data:res?.data?.login?.userNew})
            props.setOpen(false);
            if(props?.locState?.url){
              history.push(props?.locState?.url);
            }
            else if(res?.data?.login?.userNew?.UserType === "HR")
              history.push("/leads")
              else history.push(`/lead-activity/${res?.data?.login?.userNew?.LeadList}`)
              if(res?.data?.login?.userNew?.Role==="agent")
              history.push("/properties")
            //props.setUserInfo(responseData.userNew);
            if(res?.data?.login?.userNew?.UserType === "HR" || res?.data?.login?.userNew?.UserType === "Admin")
              history.push("/dashboard")
          })
          .catch((err) => {
            console.log("errrrrr", err);
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
            // props.toggleSnackbar(true, message);
          });
      } catch (err) {
        console.log("err", err);
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
        //props.toggleSnackbar(true, message, 'error');
      }
    } else {
      try {
        forgetPassword({ variables: { email: data.email } })
          .then((res) => {
            const message = res?.data?.forgotPassword?.message;
            setResponseMsg(message);
            setTimeout(() => {
              setResponseMsg(null)
            }, 5000);
            // toggleSnackbar({
            //   status: true,
            //   message: message,
            //   variant: "success",
            // });
          })
          .catch((err) => {
            const message = err && err.message;
            setResponseMsg(message);
            setTimeout(() => {
              setResponseMsg(null)
            }, 5000);
            // toggleSnackbar({
            //   status: true,
            //   message: message,
            //   variant: "error",
            // });
          });
      } catch (err) {
        const message = err && err.message;
        setResponseMsg(message);
        setTimeout(() => {
          setResponseMsg(null)
        }, 5000);
        // toggleSnackbar({
        //   status: true,
        //   message: message,
        //   variant: "error",
        // });
      }
    }
  };

  return (
    <div className="login-register-wrap" style={{overflowY:'hidden', height:'auto'}}>
      <div className="login-register">
        <img
          src={logo}
          className="login-logo img-fluid d-block m-auto"
          alt="logo"
        />
        <div className="login-register-tab">
          {!forgetPass ? (
            <>
              <Link to="#" onClick={() => props.setLogin(true)} className="active">
                Log in
              </Link>
              <Link to="#" onClick={() => props.setLogin(false)}>Register</Link>
            </>
          ) : (
            <Link to="#" className="active">RESET PASSWORD</Link>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="form-label">
              Email <sup>*</sup>
            </label>
            <input
              type="email"
              className="form-control"
              ref={emailRef}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="error-msg">Email is required</span>
            )}
          </div>
          {!forgetPass && (
            <div className="form-group">
              <label for="" className="form-label">
                Password <sup>*</sup>
              </label>
              <input
                type="password"
                className="form-control"
                id=""
                {...register("password", { required: !forgetPass })}
              />
              {errors.password && (
                <span className="error-msg">Password is required</span>
              )}
              <p
              style={{cursor:'pointer'}}
                onClick={() => setForgetPass(true)}
                className="forgot-password-link"
              >
                Forgot Password?
              </p>
            </div>
          )}
          {forgetPass && 
            <div className="text-center mb-3">
              {responseMsg && <p className="mb-2">{responseMsg}</p>}
              <p style={{cursor:'pointer'}} className="text-decoration-underline" onClick={() => {
                  setForgetPass(false);
                  setResponseMsg(null);
                }}>
                Back to login
              </p>
            </div>
          }
          <div>
            <Button
              disabled={loading}
              className="login-sumbit-btn"
              type="submit"
              color="primary"
            >
              {loading || forgetLoading ? (
                <CircularProgress color="secondary" />
              ) : forgetPass ? (
                "REQUEST"
              ) : (
                "LOG IN"
              )}
            </Button>
          {/* {forgetPass&&  <a onClick={() => props.setLogin(true)}>Back to login</a>} */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
