import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
}  from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {  InputLabel, CircularProgress } from "@material-ui/core";
import { LIST_ALL_SUGGEST_USERS } from "../../../Graghql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { LEAD_ACTIVITY_ADD } from "../../../Graghql/mutations";
import { toggleSnackbar } from "../../../RactiveVariables";

const useStyles = makeStyles({
  root: {
    maxWidth: 200,
  },
  media: {
    height: 10,
  },
});
const Suggest = (props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  //console.log("prroooops--->", props);
  // eslint-disable-next-line no-unused-vars
  const filter = createFilterOptions();
  const [message, setMessage] = useState(" ");
  const [renderdetails, setRenderdetails] = useState();
  const [emailError, setEmailError] = useState(null);
  const [options, setOptions] = useState([])
  const [searchKeyword, setSearchKeyword] = useState("");
  const [scrollLimit, setScrollLimit] = useState(10);

  // eslint-disable-next-line no-unused-vars
  const { data, loading: userLoading } = useQuery(LIST_ALL_SUGGEST_USERS, {
    variables: { 
      search:searchKeyword,
      role: "renter",
      skip:0,
    // skip:page * rowsPerPage,
    limit: scrollLimit},
    fetchPolicy: "network-only",
    onCompleted:res=>{
      let arr = [];
      if (res.listUsersForSuggest && res.listUsersForSuggest.data) {
        res.listUsersForSuggest.data.forEach((element) => {
          arr.push({
            label:  element.FirstName + element.LastName + "(" + element.Email + ")",
            value: element._id,
          });
        });
      }
      
       setOptions(arr);
      

      //setOptions([...res.listUsersForSuggest?.data]);
       //setOptions([...options,...res.listUsersForSuggest?.data]);

      // if(page * rowsPerPage<res.listUsersForSuggest?.count)
      //   setPage(page+1);
    }
  });

  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  const [suggested, { loading: suggestLoading }] =
    useMutation(LEAD_ACTIVITY_ADD);
    console.log(" details------>", options);
    //console.log(" renderdetails------>", props?.suggestApproval);
  const handlesuggest = () => {
    if (renderdetails) {
      try {
        let propertyIdArray = [];
        props.data.forEach((element) => {
          let prId = element._id;
          propertyIdArray.push(prId);
        });

        let pendingPropertyIdArray = [];
        props.data.forEach((element) => {
          let prId = element.PropertyId;
          pendingPropertyIdArray.push(prId);
        });
        suggested({
          variables: {
            UserId: renderdetails,
            LeadSrc: "Suggest",
            LeadType: "Suggest",
            //RootId:"0",
            Comment: message,
            PropertyIDs:props?.suggestApproval?pendingPropertyIdArray: propertyIdArray,
          },
        })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Suggested Successfully",
              variant: "success",
            });
            props.setOpenAgentPrint(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      setEmailError("Email is required");
    }
  };
//   window.addEventListener('scroll', function() {
//     console.log("data",window.innerHeight + window.scrollY,  document.body.offsetHeight - 400)
//     if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 600)) {
//       // if(!userLoading && (page+1)*rowsPerPage < data?.listUsersForSuggest?.count)
//           // search("","page")
//     }
//     }
// )
const handleDropdown =(event)=>{
  setScrollLimit(scrollLimit+10)
    setTimeout(() => {
      setScrollLimit(scrollLimit+10)
}, 500);
 }
// const handleDropdown =(event)=>{
//   setRowsPerPage(rowsPerPage+10)
//     setTimeout(() => {
//       setRowsPerPage(rowsPerPage+10)
// }, 500);
//  }
  return (
    <div className="property-comment-box">
      {/* <Loader open={userLoading}/> */}
      <div className="mb-3">
        <InputLabel className="form-label">To</InputLabel>
        <Autocomplete
          id="suggest"
          options={options??[]}
          inputValue={searchKeyword}
          //freeSolo
          getOptionLabel={(option) =>
            option.label
          }
          className="w-100"
          renderInput={(params) => <TextField {...params} variant="outlined" 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
            <>
                   { userLoading? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
            </>
            ),
          }} />}
          //value={renderdetails?.Email}
          //value={error.suggest}
          onChange={(e, value) => {  
            setRenderdetails(value?.value);
            setEmailError() 
             
          }}
          onInputChange={(e,value)=>{ setSearchKeyword(value);}}
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.target;
                    if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight)+1 === listboxNode.scrollHeight) {
                                    setTimeout(() => {
                                          handleDropdown(event)
                                    }, listboxNode.scrollTop + 100)
                    }
            }}}

          //   renderOption={option => (
          //     <>
          //     {option && (option.label)}
          //     </>
          // )}
          // filterOptions={(options, params) => {
          //  // const filtered = filter(options, params);
          //   setSearchKeyword(params.inputValue)
          //   // Suggest the creation of a new value
          //   // if (params.inputValue !== "") {
          //   //   filtered.push(params.inputValue);
          //   // }
          //   // return filtered;
          // }}
        />
        {emailError && <span className="error-msg">{emailError}</span>}
      </div>
      <div>
        <InputLabel className="form-label">Message</InputLabel>
        <TextField
          multiline="bool"
          rows={4}
          className="property-boundary-box pt-0 w-100"
          id="outlined-basic"
          variant="outlined"
          placeholder="Add your message here"
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          //inputProps={{ maxLength: 100 }}
        />
      </div>
      <div className="row">
        <h3>Suggested Properties</h3>

        {props.data?.map((items, index) => {
          return (
            <div className="col-md-4">
              <Card className="suggest-property-item">
                <CardActionArea>
                  <div>
                    <div>
                      <address className="suggest-property-id">
                        PID#{items?.PID}{" "}
                      </address>

                      <img
                        key={index}
                        src={items?.PendingImages?items?.PendingImages&& getFeaturedImage(items?.PendingImages):items?.Images&& getFeaturedImage(items?.Images)}
                        className="img-fluid suggest-img"
                        alt="{item?.data?.AdTitle}"
                      ></img>
                    </div>
                  </div>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      {items?.AdTitle}
                      {/* {items?.AdTitle?.charAt(0).toUpperCase() +
                        items?.AdTitle?.slice(1).toLowerCase()} */}
                    </Typography>
                    <Typography gutterBottom variant="p" component="p">
                      {items?.StreetAddress}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          );
        })}
      </div>
      <div className="add-comment-btn d-flex justify-content-between">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.setOpenAgentPrint(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={suggestLoading}
          variant="contained"
          color="primary"
          onClick={() => {
            handlesuggest();
            // props.setOpenAgentPrint(false);
          }}
        >
          {suggestLoading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : (
            "Suggest"
          )}
        </Button>
      </div>
    </div>
  );
};

export default Suggest;
