import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Menu, Drawer } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { authVar, userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import AuthPopUp from "../Login/AuthPopUp";
import Badge from "@material-ui/core/Badge";
import EditProfile from "./editProfile";
import ChangePassword from "./changePassword";
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom"
import { useMutation, useLazyQuery } from "@apollo/client";
import { CHANGE_READ_STATUS } from "../../Graghql/mutations";
import { MESSAGE_COUNT } from "../../Graghql/queries";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import logo from '../../assets/images/logo.svg'
// const options = [{ label: "Edit Profile", id: "EDIT PROFILE" }];
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   "&:focus": {
  //     backgroundColor: theme.palette.primary.main,
  //     "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //       color: theme.palette.common.white
  //     }
  //   }
  // }
}))(MenuItem);
const FrontendHeader = (props) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const user = useReactiveVar(userDeatils);
  //console.log("aaaa", user);
  const auth = useReactiveVar(authVar);
  const userRole = user?.data?.Role;
  const [logoutAlert, setLogoutAlert] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [openAuthPopUp, setOpenAuthPopUp] = React.useState(
    props?.location === "/login" ? true : false
  );
  const [changeReadStatus] = useMutation(CHANGE_READ_STATUS, {
    refetchQueries: ["getMsgCount"]
  });
  const [getMessageCount, data] = useLazyQuery(MESSAGE_COUNT);
  console.log("count", data)

  const handleMenuItems = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      // case "EDIT PROFILE":
      //   setAnchorEl(target);
      //   break;
      // case "Edit Profile":
      //   setAnchorEl(null);
      //   //setOpenAddSavedSearch(true);
      //   break;
      case "EDIT_PROFILE":
        setOpenEditProfile(true);

        setAnchorEl(false);
        break;
      case "CHANGE_PASSWORD":
        setOpenChangePassword(true);
        //setOpenAuthPopUp(true)
        setAnchorEl(false);
        break;
      case "TOGGLE_LOGOUT_ALERT":
        setLogoutAlert(!logoutAlert);
        setAnchorEl(null);
        break;
      case "CONFIRM_LOGOUT":
        handleLogout();
        setLogoutAlert(!logoutAlert);
        break;
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    authVar({
      loaded: true,
      auth: false,
    });
    userDeatils(null);
    history.push("/");
  };
  // console.log("lcn",props.location, `/lead-activity${user?.data?.LeadList}`)
  const handleClose = (value) => {
    setOpenEditProfile(false);
    setOpenMenu(false);
  };
  const handleClosePassword = (value) => {
    setOpenChangePassword(false);
  };
  const handleMessage = () => {
    changeReadStatus({ variables: { userId: user?.data?._id } })
  }
  useEffect(() => {
    if (user?.data?._id) {
      getMessageCount({ variables: { userId: user?.data?._id } })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?._id])
  return (
    <div>
      <Drawer className="menuDrawer" anchor={"right"} open={openMenu} onClose={handleClose}>
        <IconButton onClick={() => setOpenMenu(false)} className="p-2" style={{ marginLeft: 'auto' }} aria-label="Close">
          <CloseIcon />
        </IconButton>
        <ul>
          {/* {props.rentals && <li className= {props.rentals === "/rentals"? "active": null}>{userRole === "owner" || userRole === "renter" ? <a href="/rentals">Rentals</a>:""}</li>} */}

          {props.search && (
            <li
              className={props?.location?.includes('searchlistings') === true ? "active" : null}
              onClick={() => setOpenMenu(false)}
            >
              <Link to="/searchlistings/page1">Search Rentals</Link>
            </li>
          )}
          {/* {props.message && (
              <li
                className={
                  props.location === `/lead-activity/${user?.data?.LeadList}`
                    ? "active"
                    : null
                }
              >
                {userRole === "owner" || userRole === "renter" ? (
                  <a href={`/lead-activity/${user?.data?.LeadList}`}>
                    Messages
                  </a>
                ) : (
                  ""
                )}
              </li>
            )} */}
          {userRole === "owner" || userRole === "renter" ? props.message && (
            <li
              className={
                props.location === `/lead-activity/${user?.data?.LeadList}`
                  ? "active"
                  : null
              }
            >
              <>
                <Link to={`/lead-activity/${user?.data?.LeadList}`}  style={{ cursor: 'pointer' }} onClick={() => { handleMessage()}}>Messages {data?.getMsgCount !== 0 && <Badge className="p-1 badge-count" badgeContent={data?.getMsgCount}  ></Badge>}</Link>
              </>
            </li>
          ) : ""}
          {props.list && (
            <li
              className={
                props.location === `/properties` ||
                  props.location === `/lead-portfolio/${user?.data?.LeadList}`
                  ? "active"
                  : null
              }
            >
              {(userRole === "admin" || userRole === "agent" || userRole === "agentAdmin") ? (
                <Link to="/dashboard">Admin Panel</Link>
              ) : (userRole === "owner" || userRole === "renter") ? (
                <Link to={`/lead-portfolio/${user?.data?.LeadList}`}>
                  My Properties
                </Link>
              ) : (
                <Link to="#" onClick={() => { setOpenAuthPopUp(true); setOpenMenu(false) }}>
                  List Your Property
                </Link>
              )}
            </li>
          )}
          {/* {props.listyourproperty && <li className= {props.location === `/addProperty/$`||props.location ===`/propertyList`? "active": null}>{
              userRole === "owner" ? <a href={`/addProperty/$`}>List your Property</a> :
              <a href="/propertyList">List Property</a>
              }
            </li>} */}
          {/* {auth.auth && <li><a href = {userRole === "renter" || userRole === "owner" ? `/lead-activity/${user?.data?.LeadList}` : "/properties"}>Control Panel</a></li>} */}

          <li><Link to="/about">About Us</Link></li>

          <li><Link to="/agents" >Agents</Link></li>

          {(user?.data?.Role !== "admin" && user?.data?.Role !== "agentAdmin") && <li><Link to="/contact">Contact Us</Link></li>}

          {/* {userRole === "owner" || userRole === "renter" ? props.contact && (
            <li className={props.location === "/contact" ? "active" : null}>
              <Link to="/contact">Contact</Link>
            </li>
          ) : ""} */}

        </ul>
        <div className="menu-connect">
          <h4>Connect Us</h4>
          <div className="d-flex">
            <Link to={{ pathname: "https://www.facebook.com/hamptonsrental/" }} target="_blank"><img src="../assets/images/icon-fb.svg" className="img-fluid" alt="Icon" /></Link>
            <Link to={{ pathname: "https://www.instagram.com/rosehiprealestate/" }} target="_blank"><img src="../assets/images/icon-instagram.svg" className="img-fluid" alt="Icon" /></Link>
            </div>
        </div>
      </Drawer>
      <AuthPopUp open={openAuthPopUp} setOpen={setOpenAuthPopUp} locState={props?.locState} />
      <header className="fixed-top frontendHeader">
        <Link to="/" className="header-logo ">
          <img
            src={logo}
            className="img-fluid"
            alt="logo"
          />
        </Link>
        <div className="header-right">
          <ul>
            {/* {props.rentals && <li className= {props.rentals === "/rentals"? "active": null}>{userRole === "owner" || userRole === "renter" ? <a href="/rentals">Rentals</a>:""}</li>} */}

            {props.search && (
              <li
                className={props?.location?.includes('searchlistings') === true ? "active" : null}
              >
                <Link to="/searchlistings/page1">Search Rentals</Link>
              </li>
            )}
            {/* {props.message && (
              <li
                className={
                  props.location === `/lead-activity/${user?.data?.LeadList}`
                    ? "active"
                    : null
                }
              >
                {userRole === "owner" || userRole === "renter" ? (
                  <a href={`/lead-activity/${user?.data?.LeadList}`}>
                    Messages
                  </a>
                ) : (
                  ""
                )}
              </li>
            )} */}
            {userRole === "owner" || userRole === "renter" ? props.message && (
              <li
                className={
                  props.location === `/lead-activity/${user?.data?.LeadList}`
                    ? "active"
                    : null
                }
              >
                <>
                  <Link to={`/lead-activity/${user?.data?.LeadList}`}  style={{ cursor: 'pointer' }} onClick={() => { handleMessage() }}>Messages {data?.getMsgCount !== 0 && <Badge className="p-1 badge-count" badgeContent={data?.getMsgCount}  ></Badge>}</Link>
                </>
              </li>
            ) : ""}
            {props.list && (
              <li
                className={
                  props.location === `/properties` ||
                    props.location === `/lead-portfolio/${user?.data?.LeadList}`
                    ? "active"
                    : null
                }
              >
                {(userRole === "admin" || userRole === "agent" || userRole === "agentAdmin") ? (
                  <Link to="/dashboard">Admin Panel</Link>
                ) : (userRole === "owner" || userRole === "renter") ? (
                  <Link to={`/lead-portfolio/${user?.data?.LeadList}`}>
                    My Properties
                  </Link>
                ) : (
                  <Link to="#" onClick={() => setOpenAuthPopUp(true)}>
                    List Your Property
                  </Link>
                )}
              </li>
            )}
            {/* {props.listyourproperty && <li className= {props.location === `/addProperty/$`||props.location ===`/propertyList`? "active": null}>{
              userRole === "owner" ? <a href={`/addProperty/$`}>List your Property</a> :
              <a href="/propertyList">List Property</a>
              }
            </li>} */}
            {/* {auth.auth && <li><a href = {userRole === "renter" || userRole === "owner" ? `/lead-activity/${user?.data?.LeadList}` : "/properties"}>Control Panel</a></li>} */}
            {userRole === "owner" || userRole === "renter" ? props.contact && (
              <li className={props.location === "/contact" ? "active" : null}>
                <Link to="/contact">Contact</Link>
              </li>
            ) : ""}
          </ul>
          {auth.auth ? (
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              // onClick={(event) =>handleMenuItems("TOGGLE_LOGOUT_ALERT",event.currentTarget) }
              color="inherit"
            >
              <Avatar src={user?.data?.ProfileImage}>
                {user?.data?.FirstName?.charAt(0).toUpperCase() + user?.data?.LastName?.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          ) : (
            <Button
              className="highlight-btn"
              id="frontend-header-login-btn"
              color="primary"
              variant="contained"
              onClick={() => setOpenAuthPopUp(true)}
            >
              Login
              {/* <a href="/login" style={{ color: "white" }}>
                Login
              </a> */}
            </Button>
          )}
          {userRole === "owner" || userRole === "renter" ? (
            <label className="user" style={{ cursor: "pointer" }} onClick={(event) => setAnchorEl(event.currentTarget)}>
              {" "}
              {user?.data?.FirstName?.charAt(0).toUpperCase() +
                user?.data?.FirstName?.slice(1).toLowerCase()}
            </label>
          ) : (
            ""
          )}
          {/* <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            getContentAnchorEl={null}
          > */}
          {!props.hideDrawer && <div className="d-block d-md-none"><IconButton><MenuIcon onClick={() => setOpenMenu(true)} /></IconButton></div>}
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >



            {(userRole === "agent" || userRole === "owner" || userRole === "renter" || userRole === "agentAdmin") && <StyledMenuItem
              onClick={() => handleMenuItems("EDIT_PROFILE")}
            >
              Edit Profile
            </StyledMenuItem>}
            {(userRole === "agent" || userRole === "owner" || userRole === "renter" || userRole === "agentAdmin") && <StyledMenuItem
              onClick={() => handleMenuItems("CHANGE_PASSWORD")}
            >
              Change Password
            </StyledMenuItem>}
            <StyledMenuItem onClick={() => handleMenuItems("TOGGLE_LOGOUT_ALERT")}>
              Logout
            </StyledMenuItem>
            {/* {options.map((option, index) => (
            <MenuItem onClick={() => handleMenuItems(option.label)}>
              {option.label}
            </MenuItem>
                ))} */}
          </StyledMenu>
          {/* </Menu> */}
          {/* {EditProfile&&<CustomizedModal
           hideHeader={true}
        component={EditProfile}
        label="Edit Profile"
        open={openEditProfile}
        //data={[itemDetails]}
        setOpenAgentPrint={setOpenEditProfile}
      />} */}
          {/* {ChangePassword&&<CustomizedModal
           hideHeader={true}
        component={ChangePassword}
        label="Edit Profile"
        open={openChangePassword}
        //data={[itemDetails]}
        setOpenAgentPrint={setOpenChangePassword}
      />} */}
          {EditProfile && (
            <Dialog onClose={handleClose} open={openEditProfile}>
              <EditProfile setOpenEditProfile={setOpenEditProfile} />
            </Dialog>
          )}
          {ChangePassword && (
            <Dialog onClose={handleClosePassword} open={openChangePassword}>
              <ChangePassword setOpenChangePassword={setOpenChangePassword} />
            </Dialog>
          )}
          <Dialog
            open={logoutAlert}
            onClose={() => handleMenuItems("TOGGLE_LOGOUT_ALERT")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Logout ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to logout?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleMenuItems("TOGGLE_LOGOUT_ALERT")}
                color="primary"
              >
                No
              </Button>
              <Button
                onClick={() => handleMenuItems("CONFIRM_LOGOUT")}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </header>
    </div>
  );
};

export default FrontendHeader;
