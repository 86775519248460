import React from "react";
import { DialogBox } from "../../Common";
import { useMutation } from "@apollo/client";
import { UPDATE_PROPERTY_STATUS } from "../../../Graghql/mutations";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";

const Delete = (props) => {
  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  console.log("userRole--", userRole);
  const [isDelete] = useMutation(UPDATE_PROPERTY_STATUS);

  const handleDelete = () => {
    try {
      isDelete({
        variables: { PropertyId: props.propertyId, ActionType: "Delete" },
      })
        .then((res) => {
          props && props.handlePagination();

          userRole === "agent"
            ? toggleSnackbar({
                status: true,
                message: "Delete Request has been sent to Admin",
                variant: "success",
              })
            : toggleSnackbar({
                status: true,
                message: "Delete Successfully",
                variant: "success",
              });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div>
      <DialogBox
        open={props.open}
        setOpen={props.setOpen}
        handlePositiveBtn={handleDelete}
        title={`Delete #PID ${props.pid} ?`}
        text={"Do you want to Delete this Property ?"}
      />
    </div>
  );
};

export default Delete;
