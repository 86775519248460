import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Drawer,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ADD_OWNER, EDIT_OWNER } from "../../Graghql/mutations";
import {
  LIST_ALL_AGENT,
  CHECK_EMAIL,
  LIST_ACTIVE_AGENTS,
} from "../../Graghql/queries";
import { makeStyles } from "@material-ui/core/styles";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import PhoneInput from "../Common/PhoneInput";
const THIS_FIELD_IS_REQUIRED = "This field is required";
export default function AddAgent(props) {
console.log("Agent",props.defaultValue);
  const useStyles = makeStyles((theme) => ({
    menuPaper: {
      maxHeight: 200,
    },
  }));

  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
  };
  const user = useReactiveVar(userDeatils);
  console.log("user",user)
  const [addOwner, { loading }] = useMutation(ADD_OWNER);
  const [editOwner, { loading: editLoading }] = useMutation(EDIT_OWNER,{refetchQueries:["leadsDetails","listOwner"] ,});
  const { data: agentList } = useQuery(LIST_ALL_AGENT);
  const { data: activeAgentList } = useQuery(LIST_ACTIVE_AGENTS,{variables:{skip:0,limit:100}});
  const [draftFlag, setDraftFlag] = React.useState(null);
  const [cellPh, setCellPh] = React.useState("");
  // const [homePh, setHomePh] = React.useState("");
  const [officePh, setOfficePh] = React.useState("");
  const [localPh, setLocalPh] = React.useState("");
  const [secondPh, setSecondPh] = React.useState("");
  let agentQueue =
    agentList && agentList.listAllAgents && agentList.listAllAgents.data;
    console.log("AgentQueue",agentQueue);
  let activeAgentLists =
    activeAgentList &&
    activeAgentList.listActiveAgents &&
    activeAgentList.listActiveAgents.data;
    console.log("AgentQueue",activeAgentLists)
  const [email, setEmail] = React.useState("");
  const [homePhone,setHomePhone]= useState("")
  const [, chechEmailData] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: "network-only",
    variables: {
      Email: email,
    },
  });
  console.log("lazzzyyyquery", chechEmailData);
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(THIS_FIELD_IS_REQUIRED),
      // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    lastName: yup
      .string()
      .required(THIS_FIELD_IS_REQUIRED),
      // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    primaryEmail: yup.string().required(THIS_FIELD_IS_REQUIRED).email(),
    confirmEmail: yup
      .string()
      .required(THIS_FIELD_IS_REQUIRED)
      .oneOf([yup.ref("primaryEmail"), null], "Emails don't match"),
    // Zip: yup.number("Must be a number"),
    Zip: yup
      .string().required("Zip is required").nullable()
      .test("zip-validation", "Must be a number", (value) => {
        if (value && isNaN(value)) return false;
        else return true;
      })
      .test("zip-validation", "Must be less than 5 numbers", (value) => {
        if (value && value.length > 5) return false;
        else return true;
      }),
    Agent: yup.string().required(THIS_FIELD_IS_REQUIRED),
    // HomePhone: yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
    // .test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
    CellPhone: yup
    .string()
    .required("Cell Phone is required").nullable()
    .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits")
    
    // .test("cell-validation", "Must be a number", (value) => {
    //   if (value && isNaN(value)) return false;
    //   else return true;
    // })
    // .test("cell-validation", "Must be 10 digits", (value) => {
    //   if (value && value.length > 12) return false;
    //   else return true;
    // })
    ,
    // .test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
    // OfficePhone: yup
    //   .string()
    //   .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
    // .test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
    // LocalPhone: yup.string().test("phone", "Must be a number", (value) => {
    //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //   else return true;
    // }),
     address1:yup.string().required("Address1 is required"),
     city:yup.string().required("City is required"),
     state:yup.string().required("State is required"),
     Country:yup.string().required("Country is required").nullable(),
  });
  const { handleSubmit, formState, setValue, reset, register } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setHomePhone(props?.defaultValue?.HomePhone||props.defaultValue?.UserDtls?.HomePhone)
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    setCellPh(props.defaultValue?.CellPhone);
    // setHomePh(props.defaultValue?.HomePhone);
    setOfficePh(props.defaultValue?.OfficePhone);
    setLocalPh(props.defaultValue?.LocalPhone);
    setSecondPh(props.defaultValue?.SecondContactPhone);
  }, [props.defaultValue]);

  // const setHomeNumber = register("HomePhone");
  const setCellNumber = register("CellPhone");
  const setOfficeNumber = register("OfficePhone");
  const setLocalNumber = register("LocalPhone");
  const setSecondNumber = register("SecondContactPhone");

  const { errors } = formState;
  function onSubmit(params) {
    console.log("ppp",params)
    // if (params?.IsInternetUser === "true") params.IsInternetUser = true;
    // else params.IsInternetUser = false;
    if (params?.Status === "active") params.Status = "active";
    else params.Status = "inactive";
    //if (params["Zip"]) params["Zip"] = Number(params["Zip"]);
    // params["SaveAsDraft"] = draftFlag;
    if (
      chechEmailData?.data?.checkEmail?.status === true ||
      chechEmailData?.data === undefined
    ) {
      if (props.defaultValue === null) {
        try {
          addOwner({ variables: params })
            .then((res) => {
              props.handlePagination();
              props.setOpen(false);
              props.setDefaultValue(null);
              // setHomePh("");
              setCellPh("");
              setOfficePh("");
              setLocalPh("");
              setSecondPh("");
              toggleSnackbar({
                status: true,
                message: "Owner created successfully",
                variant: "success",
              });
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      } else {
        try {
          params["id"] = props?.leadOwner===true?props?.defaultValue?.UserId :props?.defaultValue?._id;
          editOwner({ variables: params })
            .then((res) => {
              //props.handlePagination();
              props.setOpen(false);
              props.setDefaultValue(null);
              // setHomePh("");
              setCellPh("");
              setOfficePh("");
              setLocalPh("");
              setSecondPh("");
              toggleSnackbar({
                status: true,
                message: "Owner updated successfully.",
                variant: "success",
              });
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    }
  }

  function toggleDrawer() {
    // reset();
    props.setOpen(!props.open);
  }

  function phoneNumberFormat(num) {
    if (num) {
      // let newNum = num.replace(/[-]+/g, "");
      let newNum = num.replace(/[^0-9]/g, '');
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
      } else {
        x =
          newNum.slice(0, 3) +
          "-" +
          newNum.slice(3, 6) +
          "-" +
          newNum.slice(6, 10);
      }
      return x;
    }
  }
  function handleClose() {
    props.setDefaultValue(null);
    // setHomePh("");
    setCellPh("");
    setOfficePh("");
    setLocalPh("");
    setSecondPh("");
    toggleDrawer();
  }
  // const agt = {
  //     FirstName: user?.data?.FirstName,
  //     LastName: user?.data?.LastName,
  //     _id : user?.data?._id
  //   }
// console.log("ag",agt)
// function getAgentID(value){
//   if(value){
//     let position = activeAgentLists?.findIndex((element)=>element._id===value);
//     console.log("pstn",position)
//     if(position !== -1){
//       return activeAgentLists[position]
//     }
//   }
//    }
//  console.log("prps",props)
//  console.log("===",getAgentID(props.defaultValue?.Agent))
console.log("props.defaultValue",props.defaultValue)
  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={handleClose}>
        <div role="presentation" className="addAgent">
          <h1 className={"addAgentHead"}>Owner Profile</h1>
          <h5 className="add-sub-head">Primary Contact</h5>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="active-radio">
              <h6>Status</h6>
              <RadioGroup
                name="active"
                className={"addAgentRadio"}
                defaultValue={
                  props.defaultValue?.Status
                    ? props.defaultValue?.Status:props.defaultValue?.UserDtls?.Status?props.defaultValue?.UserDtls?.Status
                    : "active"
                }
                {...register("Status")}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Active"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio color="primary" />}
                  label="Inactive"
                />
              </RadioGroup>
            </div>
            {/* <div className="active-radio">
              <h6>Internet User</h6>
              <RadioGroup
                name="internetUser"
                className={"addAgentRadio"}
                defaultValue={props.defaultValue?.IsInternetUser?.toString()}
                {...register("IsInternetUser")}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </div> */}

            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">
                  FIRST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  autoFocus
                  defaultValue={props.defaultValue?.FirstName||props.defaultValue?.UserDtls?.FirstName}
                  variant="outlined"
                  {...register("firstName")}
                  error={errors.firstName}
                  className={"formTextBox"}
                />
                {errors && errors?.["firstName"] && (
                  <p className="errorMessage">{errors?.firstName?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  LAST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.LastName||props.defaultValue?.UserDtls?.LastName}
                  variant="outlined"
                  {...register("lastName")}
                  // onChange={(e) => setValue("lastName", e.target.value)}
                  error={errors.lastName}
                  className={"formTextBox"}
                />
                {errors && errors?.["lastName"] && (
                  <p className="errorMessage">{errors?.lastName?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  PRIMARY EMAIL<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.Email||props.defaultValue?.UserDtls?.Email}
                  variant="outlined"
                  {...register("primaryEmail")}
                  error={errors.primaryEmail}
                  className={"formTextBox"}
                  onBlur={(e) => {
                    setEmail(e.target.value);
                    // chechEmail();
                  }}
                />
                {((errors && errors?.["primaryEmail"]) ||
                  chechEmailData?.data?.checkEmail === null) && (
                  <p className="errorMessage">
                    {errors?.primaryEmail?.message
                      ? errors?.primaryEmail?.message
                      : chechEmailData?.data?.checkEmail === null
                      ? "Email Already Exist"
                      : null}
                  </p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  CONFIRM EMAIL<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.Email||props.defaultValue?.UserDtls?.Email}
                  variant="outlined"
                  {...register("confirmEmail")}
                  error={errors.confirmEmail}
                  className={"formTextBox"}
                />
                {errors && errors?.["confirmEmail"] && (
                  <p className="errorMessage">
                    {errors?.confirmEmail?.message}
                  </p>
                )}
              </div>
              <div className="col-12">
                <span className="instruction-text">
                  Atleast one phone number is required.
                </span>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">HOME PHONE</InputLabel>
                {/* <TextField
                  defaultValue={props.defaultValue?.HomePhone}
                  variant="outlined"
                  value={homePh}
                  {...setHomeNumber}
                  onChange={(e) => {
                    setHomeNumber.onChange(e);
                    setHomePh(phoneNumberFormat(e.target.value));
                  }}
                  error={errors.HomePhone}
                  className={"formTextBox"}
                /> */}
                <PhoneInput
                  specialLabel={""}
                  value={homePhone}
                  //defaultValue={props.defaultValue?.UserDtls?.HomePhone}
                  // {...register("HomePhone")}
                  country={"us"}
                  onChange={(e) => {
                    setHomePhone(e)
                    setValue("HomePhone", e);
                  }}
                />
                {errors && errors?.["HomePhone"] && (
                  <p className="errorMessage">{errors?.HomePhone?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">CELL PHONE<sup>*</sup></InputLabel>
                <TextField
                  variant="outlined"
                  defaultValue={props.defaultValue?.UserDtls?.CellPhone}
                  value={cellPh}
                  {...setCellNumber}
                  onChange={(e) => {
                    setCellNumber.onChange(e);
                    setCellPh(phoneNumberFormat(e.target.value));
                  }}
                  error={errors.CellPhone}
                  className={"formTextBox"}
                />
                {errors && errors?.["CellPhone"] && (
                  <p className="errorMessage">{errors?.CellPhone?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">OFFICE PHONE</InputLabel>
                <TextField
                  variant="outlined"
                  defaultValue={props.defaultValue?.UserDtls?.OfficePhone}
                  value={officePh}
                  {...setOfficeNumber}
                  onChange={(e) => {
                    setOfficeNumber.onChange(e);
                    setOfficePh(phoneNumberFormat(e.target.value));
                  }}
                  error={errors.OfficePhone}
                  className={"formTextBox"}
                />
                {errors && errors?.["OfficePhone"] && (
                  <p className="errorMessage">{errors?.OfficePhone?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">LOCAL PHONE</InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.LocalPhone||props.defaultValue?.UserDtls?.LocalPhone}
                  variant="outlined"
                  value={localPh}
                  {...setLocalNumber}
                  onChange={(e) => {
                    setLocalNumber.onChange(e);
                    setLocalPh(phoneNumberFormat(e.target.value));
                  }}
                  error={errors.localPhone}
                  className={"formTextBox"}
                />
                {/* <PhoneInput
                  specialLabel={""}
                  // {...register("LocalPhone")}
                  country={"us"}
                  onChange={(e) => {
                    setValue("LocalPhone", e);
                  }}
                /> */}
                {errors && errors?.["LocalPhone"] && (
                  <p className="errorMessage">{errors?.LocalPhone?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  CONTACT PREFERENCES
                </InputLabel>
                {/* <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.ContactPreference}
                    {...register("ContactPreference")}
                    error={errors.contactPreferences}
                    className={"formTextBox"}
                  /> */}
                <Select
                  variant="outlined"
                  {...register("ContactPreference")}
                  error={errors.contactPreferences}
                  className={"formTextBox"}
                  defaultValue={props.defaultValue?.ContactPreference||props.defaultValue?.UserDtls?.ContactPreference}
                >
                  <MenuItem value="select"> Select</MenuItem>
                  <MenuItem value="cell"> Cell</MenuItem>
                  <MenuItem value="cell then office">
                    {" "}
                    Cell then Office
                  </MenuItem>
                  <MenuItem value="email1"> Email 1</MenuItem>
                  <MenuItem value="home"> Home</MenuItem>
                  <MenuItem value="home then cell"> Home then Cell</MenuItem>
                  <MenuItem value="local"> Local</MenuItem>
                  <MenuItem value="office"> Office</MenuItem>
                  <MenuItem value="second contact phone">
                    {" "}
                    Second contact phone
                  </MenuItem>
                </Select>
                {errors && errors?.["contactPreferences"] && (
                  <p className="errorMessage">
                    {errors?.contactPreferences?.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <h5 className="add-sub-head">Your Mailing Address</h5>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">ADDRESS1<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.AddressLine1||props.defaultValue?.UserDtls?.AddressLine1}
                    {...register("address1")}
                    error={errors.address1}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["address1"] && (
                    <p className="errorMessage">{errors?.address1?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">ADDRESS2</InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.AddressLine2||props.defaultValue?.UserDtls?.AddressLine2}
                    {...register("address2")}
                    error={errors.address2}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["address2"] && (
                    <p className="errorMessage">{errors?.address2?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">CITY<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.City||props.defaultValue?.UserDtls?.City}
                    {...register("city")}
                    error={errors.city}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["city"] && (
                    <p className="errorMessage">{errors?.city?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">STATE<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.State||props.defaultValue?.UserDtls?.State}
                    {...register("state")}
                    error={errors.state}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["state"] && (
                    <p className="errorMessage">{errors?.state?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">ZIP<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.Zip||props.defaultValue?.UserDtls?.Zip}
                    {...register("Zip")}
                    error={errors.Zip}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["Zip"] && (
                    <p className="errorMessage">{errors?.Zip?.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">COUNTRY<sup>*</sup></InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={props.defaultValue?.Country||props.defaultValue?.UserDtls?.Country}
                    {...register("Country")}
                    error={errors.Country}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["Country"] && (
                    <p className="errorMessage">{errors?.Country?.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <h5 className="add-sub-head">Secondary Contact</h5>
              <div className="col-12">
                <span className="instruction-text">
                  This could be a spouse, a property manager or a friend who can
                  give access.
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    SECOND CONTACT NAME
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("SecondContactName")}
                    defaultValue={props.defaultValue?.SecondContactName||props.defaultValue?.UserDtls?.SecondContactName}
                    error={errors.secondContactName}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["secondContactName"] && (
                    <p className="errorMessage">
                      {errors?.secondContactName?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    SECOND CONTACT PHONE
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    value={secondPh}
                    {...setSecondNumber}
                    onChange={(e) => {
                      setSecondNumber.onChange(e);
                      setSecondPh(phoneNumberFormat(e.target.value));
                    }}
                    defaultValue={props.defaultValue?.SecondContactPhone||props.defaultValue?.UserDtls?.SecondContactPhone}
                    error={errors.secondContactPhone}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["secondContactPhone"] && (
                    <p className="errorMessage">
                      {errors?.secondContactPhone?.message}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <InputLabel className="form-label">
                    SECOND CONTACT EMAIL
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    {...register("SecondContactEmail")}
                    defaultValue={props.defaultValue?.SecondContactEmail||props.defaultValue?.UserDtls?.SecondContactEmail}
                    error={errors.secondContactEmail}
                    className={"formTextBox"}
                  />
                  {errors && errors?.["secondContactEmail"] && (
                    <p className="errorMessage">
                      {errors?.secondContactEmail?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5 className="add-sub-head">Associated Agent</h5>
              <div className="col-md-6">
                <InputLabel className="form-label">AGENT<sup>*</sup></InputLabel>
                
                {/* {user?.data?.Role == "agent" ? (
                  <Controller 
                  control={control}
                  name="AgentDetails"
                  render={({ field: { onChange, value } }) => {
                    return(
                  <Autocomplete
                  id="combo-box-demo"
                  value={value}
                  onChange={(e,value) =>{ onChange(value);setValue("Agent",value?._id)}}
                  options={activeAgentLists }
                  getOptionLabel={options=>options.FirstName + " " + options.LastName}
                    // activeAgentLists.map((item) => (item.FirstName + " " + item.LastName))}
                  renderInput={(params) => <TextField {...params} variant="outlined"/>}
                  
                  error={errors.Agent}
                  defaultValue={props?.defaultValue?.Agent?getAgentID(props?.defaultValue?.Agent): 
                    {
                      FirstName: user?.data?.FirstName,
                      LastName: user?.data?.LastName,
                     _id : user?.data?._id
                    }
                  }
                  className={"formTextBox"}
                      
                  />)
                }} 
                />
                ):(<Controller 
                  control={control}
                  name="AgentDetails"
                  render={({ field: { onChange, value } }) => {
                    return(
                  <Autocomplete
                  id="combo-box-demo"
                  value={value}
                  onChange={(e,value) =>{ onChange(value);setValue("Agent",value?._id)}}
                  options={activeAgentLists}
                  getOptionLabel={options=>options.FirstName + " " + options.LastName}
                    // activeAgentLists.map((item) => (item.FirstName + " " + item.LastName))}
                  renderInput={(params) => <TextField {...params} variant="outlined"/>}
                  
                  error={errors.Agent}
                  defaultValue={getAgentID(props?.defaultValue?.Agent)}
                  className={"formTextBox"}
                />)
                  }}
                />
                )} */}
                {user?.data?.Role === "agent" ? (
                  <Select
                    variant="outlined"
                    {...register("Agent")}
                    error={errors.Agent}
                    className={"formTextBox"}
                    defaultValue={props.defaultValue?.Agent?props.defaultValue?.Agent:props.defaultValue?.AgentDtls?._id?props.defaultValue?.AgentDtls?._id:user?.data?._id}
                    disabled
                    MenuProps={MenuProps}
                  >
                    {props.defaultValue||user?.data?._id?
                    activeAgentLists &&
                      activeAgentLists.map((item) => (
                        <MenuItem value={item._id}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      )):agentQueue &&
                      agentQueue.map((item) => (
                        <MenuItem value={item.userId}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Select
                    variant="outlined"
                    {...register("Agent")}
                    error={errors.Agent}
                    className={"formTextBox"}
                    defaultValue={props.defaultValue?.Agent||props.defaultValue?.AgentDtls?._id}
                    MenuProps={MenuProps}
                  >
                    {props.defaultValue?activeAgentLists &&
                      activeAgentLists.map((item) => (
                        <MenuItem value={item._id}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      )):agentQueue &&
                      agentQueue.map((item) => (
                        <MenuItem value={item.userId}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                {errors && errors?.["Agent"] && (
                  <p className="errorMessage">{errors?.Agent?.message}</p>
                )}
              </div>
            </div>

            <div className="addAgentButton">
              <Button className="outline-btn" onClick={handleClose}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                {/* <Button
                  className="save-draft-btn"
                  type="submit"
                  onClick={() => {
                    setDraftFlag(true);
                  }}
                >
                  {draftFlag && loading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : (
                    "Save as Draft?"
                  )}
                </Button> */}
                <Button
                  type="submit"
                  className="highlight-btn"
                  onClick={() => {
                    setDraftFlag(false);
                  }}
                >
                  {!draftFlag && (loading || editLoading) ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
