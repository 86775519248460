import React from "react";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../../RactiveVariables";

export default function MiniSideBar(props) {
const user=useReactiveVar(userDeatils)
  return (
    
      <div className="property-step">
        <ul className="d-none d-md-block">
          {props.menuItems.map((item, index) =>{ 
            if(user?.data?.UserType==="USER"){
            if (item.label==="Agent Info") return null
            }
          return(
            <li
              className={(index + 1)===props.step ? "active" : null}
              onClick={() => {if((index + 1)<=props.stepState) props.setStep(index + 1)}}
            >
                <img 
                  src={
                    // index < props.step
                    props.completed[index]
                      ? "../assets/images/round-tick-red.svg"
                      : "../assets/images/round-tick.svg"
                  }
                  className="img-fluid"
                  alt="Icon"
                />
                {item.label}
            </li>
          )})}
        </ul>
        <ul className="property-step-band d-flex d-md-none">
        {props.menuItems.map((item, index) =>{ 
            if(user?.data?.UserType==="USER"){
            if (item.label==="Agent Info") return null
            }
          return(
            <li className={(index + 1)===props.step ? "active" : null}
               onClick={() => {if((index + 1)<=props.stepState) props.setStep(index + 1)}}>                 
                  <img
                  src={
                    // index < props.step
                    props.completed[index]
                      ? "../assets/images/round-tick-red.svg"
                      : "../assets/images/round-tick.svg"
                  }
                  className="img-fluid"
                  alt="Icon"
                />
                  <label>{item.mLabel}</label>
              </li>
          )})}
        </ul>
      </div>
    
  );
}
