import React, { useState, useEffect } from "react";
import SideBar from "../Common/sidebar";
import LoggedInHeader from "../Common/loggedinHeader";
import { useHistory } from "react-router";
import { useReactiveVar } from "@apollo/client";
import PropertyListing from "./PropertyListing";
import { useQuery, useMutation } from "@apollo/client";
import { PROPERTY_SEARCH, LIST_ALL_USERS, LIST_AGENT } from "../../Graghql/queries";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LIST_AREAS } from "../../Graghql/queries";
import {
  Button,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomerPrint from "./propertyActions/CustomerPrint";
import CustomizedModal from "../Common/customizedModal";
import AgentPrint from "./propertyActions/AgentPrint";
import { toggleSnackbar } from "../../RactiveVariables";
import {
  bedRoomsOptions,
  bathroomOptions,
  mainAmenityOptions,
  tennisOptions,
  poolOptions,
  petsOptions,
  waterfrontOptions,
} from "./addProperty/options";
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { Controller, useForm } from "react-hook-form";
import Loader from "../Common/Loader";
import { useLocation } from "react-router-dom";
import { FEATURED_PPTY } from "../../Graghql/mutations";
import Locations from "./Locations";
import { userDeatils } from "../../RactiveVariables/index";
const rentalPeriodOptions = [
  {
    RentalPeriod: "MD-LD",
  },
  {
    RentalPeriod: "May",
  },
  {
    RentalPeriod: "June",
  },
  {
    RentalPeriod: "June/July",
  },
  {
    RentalPeriod: "July",
  },
  {
    RentalPeriod: "July - LD",
  },
  {
    RentalPeriod: "August",
  },
  {
    RentalPeriod: "August - LD",
  },
  {
    RentalPeriod: "September",
  },
  {
    RentalPeriod: "October",
  },
  {
    RentalPeriod: "November",
  },
  {
    RentalPeriod: "December",
  },
  {
    RentalPeriod: "January",
  },
  {
    RentalPeriod: "February",
  },
  {
    RentalPeriod: "March",
  },
  {
    RentalPeriod: "April",
  },
  {
    RentalPeriod: "Year Round",
  },
  {
    RentalPeriod: "Winter",
  },
  {
    RentalPeriod: "Short Term",
  },

];
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 450,
  },
  margin: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '50px',
    color: '#6E6E6E',
  },

}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
};
function valuetext(value) {
  return value;
}

const followersMarks = [
  {
    value: 1000,
    scaledValue: 1000,
    label: "1k"
  },
  {
    value: 5000,
    scaledValue: 5000,
    label: "5k"
  },
  {
    value: 10000,
    scaledValue: 10000,
    label: "10k"
  },
  {
    value: 25000,
    scaledValue: 25000,
    label: "25k"
  },
  {
    value: 50000,
    scaledValue: 50000,
    label: "50k"
  },
  {
    value: 100000,
    scaledValue: 100000,
    label: "100k+"
  },
  // {
  //   value: 250000,
  //   scaledValue: 250000,
  //   label: "250k"
  // },
  // {
  //   value: 500000,
  //   scaledValue: 500000,
  //   label: "500k"
  // },
  // {
  //   value: 1000000,
  //   scaledValue: 1000000,
  //   label: "1M+"
  // }
];
// eslint-disable-next-line no-unused-vars
const scale = value => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

// function numFormatter(num) {
//   if (num > 999 && num < 1000000) {
//     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
//   } else if (num >= 1000000) {
//     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
//   }else if (num < 900) {
//     return num; // if value < 1000, nothing to do
//   }
// }
function numFormatter(num) {
  if (num > 999 && num < 100000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 100000) {
    return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}
const Properties = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const user = useReactiveVar(userDeatils);
  const locations = useLocation();
  //const keywordValue = locations.state?.keywordValue;
  console.log("proppppp",locations.state?.agentName)

  
  const [keywordValue, setKeywordValue] = useState(locations.state?.keywordValue);
  const [agentValue, setAgentValue] = useState(locations.state?.agentValue);
  const [agentName, setAgentName] = useState(locations.state?.agentName);
  let agentValues={ label:agentName,
    value: agentValue}
  //console.log("agentValues",agentValues,locations,locations.state?.agentValue,agentValue)
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [privateItem, setPrivateItem] = useState([]);
  const [openModalAgent, setOpenModalAgent] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [areaFilter, setAreaFilter] = useState("");
  const [rentalPeriodFilter, setRentalPeriodFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [bathFilter, setBathFilter] = useState("");
  const [keyWordSearch, setKeyWordSearch] = useState("");
  const [openModalSuggest, setOpenModalSuggest] = useState(false);
  const [openModalEmailRenter, setOpenModalEmailRenter] = useState(false);
  const [openModalEmailOwner, setOpenModalEmailOwner] = useState(false);
  const [ownerId, setOwnerId] = useState("");
  const { handleSubmit, control, setValue,reset,} = useForm();
  const [openClicked, setOpenClicked] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  const [amenityName, setAmenityName] = useState([]);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sqfeet, setSqFeet] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState([]);
  const [isCommercial, setIsCommercial] = useState(false)
  const [isResidential, setIsResidential] = useState(false)
  const [isPrivate, setIsPrivate] = useState(false)
  const [waterfront, setWaterfront] = useState([])
  const [tennis, setTennis] = useState("")
  const [pools, setPools] = useState("")
  const [pets, setPets] = useState("")
  const [status, setStatus] = useState("")
  const [style, setStyle] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [amount, setAmount] = useState(["0", "1000000"]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [minValues, setMaxValues] = useState('');
  const [subArea, setSubArea] = useState([]);
  const [agentId, setAgentId] = useState("");
  //const [locationDetails, setLocationDetails] = useState([]);
  const [searchKeyword1, setSearchKeyword1] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [scrollLimit, setScrollLimit] = useState(10);
  const [defaultValue,setDefaultValue]=  useState(null)
  const [defaultValue2,setDefaultValue2]=  useState(null)
  const [ownerList, setOwnerList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [openLocations, setOpenLocations] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variable1, setVariable1] = useState([]);
  const [variable2, setVariable2] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const locationVar = useLocation();
  const [featuredProperty] = useMutation(FEATURED_PPTY);
  const [propertyType, setPropertyType] = useState({
    residential: false,
    commercial: false,
    isPrivateProperty: false,
  })
  const { data: group1 } = useQuery(LIST_AREAS, {
    variables: { group: 1 },
  });
  const { data: group2 } = useQuery(LIST_AREAS, {
    variables: { group: 2 },
  });
  const { data: group3 } = useQuery(LIST_AREAS, {
    variables: { group: 3 },
  });
  console.log("default......",defaultValue)
useEffect(()=>{
setValue("agentId",locations.state?.agentValue)
// eslint-disable-next-line react-hooks/exhaustive-deps
},[locations.state?.agentValue])
  const propertyListVariables = {
    //skip: page * rowsPerPage,
    skip:(page - 1) * rowsPerPage,
    limit: rowsPerPage,
    selectedArea: areaFilter,
    selectedPeriod: rentalPeriodFilter,
    selectedPrice: priceFilter,
    selectedBed: parseFloat(bedFilter),
    selectedBath: parseFloat(bathFilter),
    keywordSearch: keyWordSearch?keyWordSearch:keywordValue?keywordValue:"",
    rentalPeriods: rentalPeriod,
    locations: variable1,
    isPrivate: isPrivate,
    amenities: amenityName,
    isCommercial: isCommercial,
    isResidential: isResidential,
    listWater: waterfront,
    listTennis: tennis,
    listPool: pools,
    listPet: pets,
    status: status,
    listStyle:style,
    subArea: variable2,
    agentId: agentId?agentId:agentValue?agentValue:"",
    ownerId: ownerId,
  };
  useEffect(()=>{
    history.replace() 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(()=>{
    let obj = locationVar.searchObj;
    let arr = [];
    let loc = [];
    if(obj && obj.area && group1 && group2 && group3){
      group1&&group1.listAreas.forEach(element => {
        if(obj.area.includes(element.City)){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      group2&&group2.listAreas.forEach(element => {
        if(obj.area.includes(element.City)){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      group3&&group3.listAreas.forEach(element => {
        if(obj.area.includes(element.City)){
          element&&element.Area.forEach(item => {            
            arr.push(item)
          });
          loc.push(element.City)
        }
      });
      setVariable1(loc)
      setVariable2(arr);
      setSelected(loc);
      setSubArea(arr);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[group1,group2,group3])
  // const { data: ownersData, loading: ownerLoading,} = useQuery(LIST_ALL_USERS, {
  //   variables: {
  //     role : "owner",
  //     skip:0,
  //     limit:100,
  //     //search: searchKeyword,
  //   },})
  //   console.log("ownersData-->",ownersData)
  const {
    // eslint-disable-next-line no-unused-vars
    data,
    loading: agentLoading,
    //fetchMore,
  } = useQuery(LIST_AGENT, {
    fetchPolicy: "network-only",
    variables: {
      skip: 0,
      limit: scrollLimit,
      search: searchKeyword1,
    },
    onCompleted: (res) => {
      console.log("resss", res)
      let arr = [];
      res.listAgent.data.forEach((element) => {
        arr.push({
          label: element.FirstName + " " + element.LastName,
          value: element._id,
        });

      });
      // {
      //   renderdetails != null &&
      //     arr.push(renderdetails);
      // }
      // console.log("arrrraaay-->",arr)
      // console.log("detailllsss-->",renderdetails)

      setAgentList(arr);
    },
  });
  // eslint-disable-next-line no-unused-vars
  const { data: ownersData, loading: ownerLoading, } = useQuery(LIST_ALL_USERS, {
    variables: {
      role: "owner",
      skip: 0,
      limit: scrollLimit,
      search: searchKeyword,
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr = [];
      res.listAllUsers.forEach((element) => {
        arr.push({
          label: element.FirstName + " " + element.LastName,
          value: element._id,

        });

      });
     
      // {
      //   renderdetails != null &&
      //     arr.push(renderdetails);
      // }
       //console.log("arrrraaay-->",arr)
      // console.log("detailllsss-->",renderdetails)

      setOwnerList(arr);
    
    },
  });
  // let renderList = {};
  // ownersData?.listAllUsers?.forEach((items) => {
  //   let label=items.FirstName.concat(" ",items.LastName)
  //   //let ownerN=items.LastName
  // //  let obj1={label:`${items.FirstName}${items.LastName}`}
  // //  let obj2={value:items._id}
  //   let obj1={label:label,value:items._id}
  //     renderList[items._id] = {...obj1 };
  //   })
  //   console.log("renderList",renderList)
  // console.log("ownersData",ownersData)

  // let renderList = {};
  // ownersData?.listAllUsers?.forEach((items) => {
  //   let ownerName=items.FirstName.concat(" ",items.LastName)
  //   let obj={value:items._id,label:ownerName}
  //   renderList[items._id] = { ...obj };
  //   })
  //   console.log("renderList",renderList);
  // let renderListAgents = {};
  // data?.listAgent?.data?.forEach((items) => {
  //   let agentName=items.FirstName.concat(" ",items.LastName)
  //   let obj={value:items._id,label:agentName}
  //   renderListAgents[items._id] = { ...obj };
  //   })
  //   console.log("renderList",data?.listAgent?.data);
  const handleDropdown = (event) => {
    setScrollLimit(scrollLimit + 10)
    setTimeout(() => {
      setScrollLimit(scrollLimit + 10)
    }, 500);
  }
  
  // useEffect(() => {
  //   if (user?.data?.Role == "renter") {
  //     setRenderdetails({ label: user?.data?.FirstName + " " + user?.data?.LastName, value: user?.data?._id })

  //   }
  // }, [])
  useEffect(() => {
    if (locations?.searchObj) {
      let obj = locations?.searchObj;
      console.log("objjjjjj---->", obj)
      //if (obj.area) { setVariable1(obj.area === "Any Area" ? [] : typeof obj.area === "object" ? obj.area : [obj.area]); setValue("location", typeof locations?.searchObj?.area === "object" ? locations?.searchObj?.area : [locations?.searchObj?.area]) }
      //if (obj.area) setVariable1(obj.area?obj.area:[] ); setValue("location",locations?.searchObj?.area?locations?.searchObj?.area:[])
      //if (obj.area) { setVariable1(obj.area === "Any Area" ? [] :  [obj.area]); setValue("location",  [locations?.searchObj?.area]) }
       if (obj.area) { setLocation(obj.area === "Any Area" ? [] : typeof obj.area === "object" ? obj.area : [obj.area]); setValue("location", typeof locations?.searchObj?.area === "object" ? locations?.searchObj?.area : [locations?.searchObj?.area]) }
      if (obj.period) { setRentalPeriod(obj.period === "Any Periods" ? "" : obj.period); setValue("rentalPeriod", locations?.searchObj?.period) }
      if (obj.perioddashboard) { setRentalPeriod(obj.perioddashboard === "Any Periods" ? "" : obj.perioddashboard); setValue("rentalPeriod", locations?.searchObj?.perioddashboard) }
      setPriceFilter((obj.price === "" || !obj.price) ? ["0", "1000000"] : obj.price);
      if (obj.price)
        setAmount(obj.price);
      if (obj.bed) setBedFilter(obj.bed) 
      if (obj.bath) setBathFilter(obj.bath);
      if (obj.pool) setPools(obj.pool);
      if (obj.tennis) setTennis(obj.tennis);
      if (obj.pet) setPets(obj.pet);
      if (obj.status) setStatus(obj.status);
      // if(obj.ownerId) setOwnerId(obj.ownerId);
      // if(obj.agentId) setAgentId(obj.agentId);
      if (obj.water && obj.water !== "") { setWaterfront(obj.water === "Any WaterFront" ? [] : obj.water); setValue("waterfront", [locations?.searchObj?.water[0]]) }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
//   useEffect(() => {
//     // simulate async api call with set timeout
    
//     setTimeout(() =>setClearFilter({bed:'',bath:'',search:''}), 1000);;
// }, []);


// useEffect(() => {
  
//     reset(clearFilter);
// }, [clearFilter]);
  const handlePagination = () => {
    fetchMore({
      variables: propertyListVariables,
    });
  };
  function handleAddBtnClick(params) {
    history.push("/addProperty/$");
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    // console.log("nv", newValue)
    let strArray = newValue
    let strArr = strArray.map(function (e) { return e.toString() });
    setAmount(strArr);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const {
    data: propertyList,
    loading,
    fetchMore,
  } = useQuery(PROPERTY_SEARCH, {
    variables: propertyListVariables,
    fetchPolicy: "network-only",
    onCompleted: res => {
      // handleAvailableIDs();
    }
  });
  const propertyLists =
    propertyList &&
    propertyList.propertySearch &&
    propertyList.propertySearch.data;

  const propertyCount =
    propertyList?.propertySearch && propertyList?.propertySearch?.count;

  // function handlePageInputChange(event) {
  //   setRowsPerPage(Number(event.target.value));
  //   setPage(1);
  // }
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  //console.log("selectedItem...", selectedItem);
  const handleChecked = (item, value) => {
    let arrId = [...selectedId];
    let array = [...selectedItem];
    let privateArr = [...privateItem]
    if (value === true) {
      array.push(item);
      arrId.push(item._id);
      if (item?.IsPrivate === true) privateArr.push(item?.IsPrivate);

    } else {
      let index = arrId.indexOf(item._id);
      arrId.splice(index, 1);
      array.splice(index, 1);
      privateArr.splice(index, 1)
    }
    setSelectedId(arrId);
    setSelectedItem(array);
    setPrivateItem(privateArr)
    handleAvailableIDs(arrId);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangePrice = (prop) => (event) => {
    setMaxValues(event.target.value);
  };
  function handleSearch(params) {
    // console.log("eee----->",e)
   // e.preventDefault()
    setVariable2(isCheck);
    setVariable1(selected);
    console.log("paramsss", params);
    setPage(1);
    if (openClicked === false) setOpenClicked(!openClicked)
    console.log("ser", params)
    if (params.search) {
      setKeyWordSearch(params.search);
    }
    else { setKeyWordSearch("") }
    if(keywordValue){
      setKeywordValue(keywordValue)
    }else{ setKeywordValue("")}
    // if (params.area) {
    //   if (params.area === "Any Area") setAreaFilter("");
    //   else setAreaFilter(params.area);
    // }
    if (params.period) {
      if (params.period === "Any Periods") setRentalPeriodFilter("");
      else setRentalPeriodFilter(params.period);
    }
    setPriceFilter(amount);

    if (params.bed) {
      if (params.bed === "Any Beds") setBedFilter("");
      else setBedFilter(params.bed);
    }
    if (params.bath) {
      if (params.bath === "Any Baths") setBathFilter("");
      else setBathFilter(params.bath);
    }
    if (params.sqFeet) {
      if (params.sqFeet === "Any Feet") setSqFeet("");
      else setSqFeet(params.sqFeet);
    }
    if (params.tennis) {
      if (params.tennis === "Any Tennis") setTennis("")
      else setTennis(params.tennis)
    }
    if (params.pools) {
      if (params.pools === "Any Pools") setPools("")
      else setPools(params.pools)
    }
    if (params.pets) {
      if (params.pets === "Any Pets") setPets("")
      else setPets(params.pets)
    }
    if (params.status) {
      if (params.status === "Any Status") setStatus("")
      else setStatus(params.status)
    }
    // if (params.style) {
    //   if (params.style === "Any Style") setStyle("")
    //   else setStyle(params.style)
    // }
    if (params.style) {
      setStyle(params.style)
    }
    if (params.location) {
      setLocation(params.location)
    }
    if (params.subArea) {
      setSubArea(params.subArea)
    }
    if (params.agentId) {
      if (params.agentId === "") setAgentId("")
      else 
      setAgentId(params.agentId)
    }
    if(agentValue){
     if(agentValue==="")setAgentValue("") 
    else setAgentValue(agentValue)}

    if(!defaultValue) setAgentId("")
    //if(!locations?.state?.agentName) setAgentValue("")
    if (params.ownerId) {
  
      setOwnerId(params.ownerId)}
      else {setOwnerId("")}
      if(!defaultValue2) setOwnerId("")
     
    
    if (params.rentalPeriod) {
      setRentalPeriod(params.rentalPeriod)
    }
    if (params.amenity) {
      setAmenityName(params.amenity)
    }
    if (params.waterfront) {
      setWaterfront(params.waterfront)
    }
    setIsPrivate(propertyType.isPrivateProperty)
    setIsCommercial(propertyType.commercial)
    setIsResidential(propertyType.residential)

  }

  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  const handleAvailableIDs = (idArray) => {
    let allIds = idArray ? [...idArray] : [...selectedId];
    let flag = true;
    propertyLists.forEach(element => {
      if (!allIds.includes(element._id) &&
        !(
          element.Status === "Delete" ||
          element.Status === "Sold" ||
          element.Status === "Off the Market" ||
          element.Status === "inactive" ||
          element.Status == null
        ))
        flag = false;
    })
    setSelectAllChecked(flag);
  }
  const handleSelectAll = (value) => {
    let arrId = [...selectedId];
    let array = [...selectedItem];
    console.log("value====>", arrId);

    if (value === true) {
      propertyLists.forEach((item) => {
        if (
          array.includes(item) === false &&
          !(
            item.Status === "Delete" ||
            item.Status === "Sold" ||
            item.Status === "Off the Market" ||
            item.Status === "inactive" ||
            item.Status == null
            // item.IsPrivate== true
          )
        )
          array.push(item);
        if (arrId.includes(item._id) === false &&
          !(
            item.Status === "Delete" ||
            item.Status === "Sold" ||
            item.Status === "Off the Market" ||
            item.Status === "inactive" ||
            item.Status == null
            // item.IsPrivate== true
          )
        ) arrId.push(item._id);
      });
      console.log("value==3==>", array, arrId);
    } else if (value === false) {
      arrId = [];
      array = [];
    }
    setSelectedId(arrId);
    setSelectedItem(array);
    handleAvailableIDs(arrId);
  };
  const handleFeatured = () => {
    //console.log("selectedId",selectedId)
    try {
      featuredProperty({ variables: { propertyIds: selectedId } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property successfully updated",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
 

  // console.log("clearText",tennis)
  const handleClearAll=()=>{
   // console.log("clearText",clearText)
     //setClearText("")
    // setClearTextLoc("")
    // setClearTextBed("Any Beds")
    // setValue("bed","")
    // reset({
    //   bed: "Any Beds"
    // },{keepValues: false} )
    setKeywordValue("")
    setAgentValue("")
    setAgentName("")
    setBedFilter("")
    setBathFilter("")
    setRentalPeriodFilter("")
    setPriceFilter("")
    setKeyWordSearch("")
    setVariable1([])
    setVariable2([])
    setSelected([])
    setSubArea([])
    setIsCheck([])
    setRentalPeriod([])
    setIsPrivate(false)
    setAmenityName([])
    setIsCommercial(false)
    setIsResidential(false)
    setWaterfront([])
    setTennis("")
    setPools("")
    setPets("")
    setStatus("")
    setStyle([])
    setAgentId("")
    setOwnerId("")
    setDefaultValue(null)
    setDefaultValue2(null)
    setAmount(["0", "1000000"])
    setPropertyType({
      residential: false,
      commercial: false,
      isPrivateProperty: false,
    })
    setLoaded(true);
  }
  const NumberChecking = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === "" || re.test(e)) {
      return e;
    } else {
      let newVal = e.replace(/\D/g, "");
      return newVal;
    }
  };
   function formatePrice(value) {
     if(value)
     return parseInt(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // return value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
console.log("selected--->",selected)
  return (
    <div className="custom-layout">
      {openLocations &&
        <Locations
          openLocations={openLocations}
          setOpenLocations={setOpenLocations}
          setLocation={setLocation}
          setSubArea={setSubArea}
          setSelected={setSelected}
          selected={selected}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
          checkedData={locations?.searchObj?.area}
          defaultValue={locationVar?.searchObj}
          setLoaded={setLoaded}
          loaded={loaded}
        />
      }
      <Loader open={loading} />
      <LoggedInHeader
        handleAddBtnClick={handleAddBtnClick}
        label="Properties"
        addBtnLabel="Add Property"
      />
      <div className="custom-layout-content adminPropertyListing-content">
        {/* <div className="custom-layout-content "> */}

        <div className="search-band search-band-properties ps-0">
          <form onSubmit={handleSubmit(handleSearch)}>
            <FormControl className="search-town ps-2">
            <Controller
                control={control}
                name="search"
                render={({ field: { onChange, value } }) => {
                   console.log("vlllll", value)
                  return (
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                value={value?value:keywordValue?keywordValue:""}
                disableUnderline={false}
                placeholder="Ad's Title, Address, PID"
               //{...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
                // value={clearFilter&&clearText}
                 //onChange={(e)=>setKeywordValue("")}
                 onChange={(e) => {onChange(e.target.value?e.target.value:setKeywordValue("") )}}
                
              />   )
            }}
          />
            </FormControl>

            <FormControl className="ps-2" onClick={() => setOpenLocations(true)} style={{cursor:'pointer'}}>
              <TextField
                // disabled={true}
                id="standard-full-width"
                className="fullWidthSearch"
                label="LOCATION"
                disableUnderline={false}
                onClick={() => setOpenLocations(true)}
                readonly
                placeholder={(selected.length>0||subArea.length>0)? selected.length+subArea.length+ " Selected":"City/Town"}
                // {...register("search")}
                fullWidth
                style={{pointerEvents:'none'}}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
               // defaultValue={locations?.searchObj?.area?locations?.searchObj?.area+ " Selected":"City/Town"}
                // value={clearFilter&& clearTextLoc}
                // onChange={(e)=>setClearTextLoc(e.target.value)}
              />
            </FormControl>


            {/* <FormControl className="search-town">
            <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
              <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => {
                        // console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            // variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            // input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Locations" : ` Locations (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Locations</MenuItem>
                            {areaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />

            </FormControl> */}

            {/* <InputLabel>TOWN</InputLabel>
              <Select
                defaultValue={
                  locations?.searchObj?.area
                    ? locations?.searchObj.area
                    : "Any Area"
                }
                //defaultValue="Any Area"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("area")}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value="Any Area">{"Which Area?"}</MenuItem>
                {areaOptions?.sort(sortLabels).map((item) => (
                  <MenuItem value={item.label}><img src="../assets/images/serach-band-location.svg" className="img-fluid me-2 sb-icon" alt="Icon" />{item.value}</MenuItem>
                ))}
              </Select> */}
            <FormControl>
              <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
              <Controller
                control={control}
                name="rentalPeriod"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      // variant="outlined"
                      value={ value&&(value?.length > 0) ? value  :[""]}
                      onChange={(e) => {onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}}
                      // input={<Input />}
                      renderValue={(selected) => selected[0] === "" ? "Periods" : ` Periods (${selected.length} selected)`}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} disabled value={""}>Periods</MenuItem>
                      
                      {rentalPeriodOptions.map((item) => (
                        <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                          <Checkbox color="primary" checked={value?.indexOf(item.RentalPeriod) > -1}/>
                          <ListItemText primary={item.RentalPeriod} />
                        </MenuItem>
                      ))}
                    </Select>
                  )
                }}
              />
              {/* <InputLabel>PERIOD</InputLabel>
              <Select
                defaultValue={
                  locations?.searchObj?.period
                    ? locations?.searchObj?.period
                    : "Any Periods"
                }
                //defaultValue="Any Periods"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("period")}
              >
                <MenuItem value="Any Periods">{"Any Periods"}</MenuItem>
                {rentalPeriodOptions.map((item) => (
                  <MenuItem value={item.RentalPeriod}><img src="../assets/images/serach-band-calendar.svg" style={{ height: '14px' }} className="img-fluid me-2 sb-icon" alt="Icon" />
                    {item.RentalPeriod}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
            <FormControl>
              <div className="price-filter-box-outer" aria-describedby={id} variant="contained" onClick={handleClick}>
                <b style={{ cursor: "pointer" }} className="price-filter-label">PRICE</b>
                <div style={{ cursor: "pointer" }} className="price-filter-box">{amount[0] === "0" && amount[1] === "1000000" ? "Any Price " :amount[0] === ""?`$-$ ${amount[1]}`&&formatePrice(amount[1]):amount[1] === ""?"$"+amount[0]&&`${formatePrice(amount[0])}-$`:amount[0] === "" && amount[1] === "" ?`$ - $`:`$ ${formatePrice(amount[0])} - $ ${formatePrice(amount[1])}`}<ArrowDropDownIcon /></div>

              </div>
              <Popover
                className="price-filter"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorPosition={{ top: 2000, left: 400 }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={classes.typography}>
                  <div className={classes.root}>
                    <Typography id="range-slider" gutterBottom>
                      <CloseIcon style={{ cursor: "pointer", marginLeft: "460px", marginTop: "5px" }} onClick={() => (handleClose())} />
                      Price range

                    </Typography>
                    <Slider
                      value={amount}
                      step={1}
                      valueLabelFormat={numFormatter}
                      marks={followersMarks}
                      // scale={scale}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      max={100000}
                      min={0}
                    />

                  </div>
                </Typography>
                <div style={{ display: "flex" }}>

                  <FormControl fullWidth className={classes.margin} variant="outlined">
                    {/* <InputLabel >Min</InputLabel> */}
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={formatePrice(amount[0])}
                      placeholder="Min"
                      onChange={(e) => { setAmount([NumberChecking(e.target.value), amount[1]]) }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      labelWidth={30}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.margin} variant="outlined">
                    {/* <InputLabel >Max</InputLabel> */}
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      placeholder="Max"
                      value={formatePrice(amount[1])}
                      onChange={(e) => { setAmount([amount[0], NumberChecking(e.target.value)]) }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      labelWidth={30}
                    />
                  </FormControl>
                </div>
              </Popover>

              {/* <InputLabel>PRICE</InputLabel>
              <Select
                defaultValue={
                  locations?.searchObj?.price
                    ? locations?.searchObj?.price
                    : "Any Price"
                }
                // defaultValue="Any Price"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("price")}
              >
                <MenuItem value="Any Price">{"Any Price"}</MenuItem>
                {priceOptions.map((item) => (
                  <MenuItem value={item.label}>{item.value}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
            <FormControl>
              <InputLabel>BED</InputLabel>
              <Controller
                control={control}
                name="bed"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
              <Select
                // defaultValue={locations?.searchObj?.bed ? locations?.searchObj?.bed: "Any Beds"
                 
                // }
                value={ value ? value==="Any Beds"?value:value+"+":locations?.searchObj?.bed ? locations?.searchObj?.bed:"Any Beds"}
                onChange={(e) => {onChange(e.target.value )}}
                renderValue={(selected) => selected=== "" ? "Any Beds" : selected}
                MenuProps={MenuProps}
                // defaultValue={bedFilter===""?"Any Beds":bedFilter}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                //  {...register("bed")}
              >
                <MenuItem  value={"Any Beds"}>{"Any Beds"}  </MenuItem>
                {bedRoomsOptions.map((item) => (
                  <MenuItem value={item.label} ><img src="../assets/images/icon-bed.svg" style={{ height: '12px' }} className="img-fluid me-2 sb-icon" alt="Icon" />{item.value}</MenuItem>
                ))}
              </Select>
                    )
                  }}
                />
            </FormControl>
            <FormControl>
              <InputLabel>BATH</InputLabel>
              <Controller
                control={control}
                name="bath"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
              <Select
                // defaultValue={
                //   locations?.searchObj?.bath
                //     ? locations?.searchObj?.bath
                //     : "Any Baths"
                // }
                value={ value ? value==="Any Baths"?value:value+"+"   :locations?.searchObj?.bath ? locations?.searchObj?.bath:"Any Baths"}
                onChange={(e) => {onChange(e.target.value )}}
                renderValue={(selected) => selected=== "" ? "Any Baths" : selected}
                MenuProps={MenuProps}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                // {...register("bath")}
              >
                <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                {bathroomOptions.map((item) => (
                  <MenuItem value={item.label}><img src="../assets/images/icon-bath.svg" style={{ height: '14px' }} className="img-fluid me-2 sb-icon" alt="Icon" />{item.value}</MenuItem>
                ))}
              </Select>
                  )
                }}
              />
            </FormControl>
            <FormControl className="search-btn-outer">
              <Button
                className="search-btn"
                variant="contained"
                type="submit"
              // onClick={()=>{setOpenClicked(!openClicked)}}
              >
                Search
              </Button>
              {/* <MoreHorizIcon  onClick={()=>{setOpenClicked(!openClicked)}}/> */}
              <label
                onClick={() => {
                  setOpenClicked(!openClicked);
                }}
                className="advanced-filter-btn"
              >
                {openClicked === true ? <>More</> : <>Less</>}
                {openClicked === true ? <img src="../assets/images/more-open.svg" className="img-fluid me-1" alt="Icon" /> :
                  <img src="../assets/images/more-close.svg" className="img-fluid me-1" alt="Icon" />}
              </label>

                  <Button  type="submit" onClick={()=>{reset();handleClearAll();}}  className="clear-filter p-2" style={{minWidth: "auto"}} data-toggle="tooltip" title="Clear Filter">
                    <img src="../assets/images/icon-reset.svg" className="img-fluid" alt="Clear Filter"/>
                  </Button>
                
            </FormControl>
          </form>
        </div>
        {!openClicked && <div>
          <div className="advance-filter advance-filter-admin">
            <form onSubmit={handleSubmit(handleSearch)}>
              <div className="row">
                <h6 className="col-12 ms-2">Advanced Filter</h6>
                {/* <div className="col-md-12 ms-2">
                  <h6>Property Type</h6>
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.residential}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="residential"
                        color="primary"
                      // {...register("residential")}
                      />
                    }
                    label="Residential"
                  />
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.commercial}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="commercial"
                        color="primary"
                      // {...register("commercial")}
                      />
                    }
                    label="Commercial"
                  />
                </div> */}
                {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      name="subArea"
                      render={({ field: { onChange, value } }) => {
                        // console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            // input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Areas?" : ` Areas (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Areas</MenuItem>
                            {subAreaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
                  </FormControl>
                </div> */}
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">AMENITIES</InputLabel> */}
                    <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        // console.log("vl", value.length)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            // input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Amenities?" : ` Amenities (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Amenities</MenuItem>
                            {mainAmenityOptions.sort(sortLabels).map((name) => (
                              <MenuItem className="advance-filter-listItem" key={name.value} value={name.value}>
                                <Checkbox color="primary" checked={value?.indexOf(name.value) > -1} />
                                <ListItemText primary={name.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />

                    {/* <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            variant="oulined"
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={value ? value : []}
                            onChange={(e) => onChange(e.target.value)}
                            // onChange={(e)=>{setAmenityName(e.target.value)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} className={classes.chip} />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {amenities.map((amenity) => (
                              <MenuItem key={amenity.value} value={amenity.value} >
                                {amenity.label}
                              </MenuItem>
                            ))}
                          </Select>)
                      }}
                    /> */}
                  </FormControl>
                </div>
                {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
                    <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Periods" : ` Periods (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Periods</MenuItem>
                            {rentalPeriodOptions.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                                <Checkbox color="primary" checked={value?.indexOf(item.RentalPeriod) > -1} />
                                <ListItemText primary={item.RentalPeriod} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}
                {/* <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rentalPeriodOptions.map((item) => (
                            <MenuItem key={item.RentalPeriod} value={item.RentalPeriod}>
                              {item.RentalPeriod}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                {/* </FormControl>
                </div> */}
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">WATERFRONT</InputLabel> */}
                    <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => {
                        // console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            // input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Waterfronts?" : ` Waterfronts (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Waterfronts</MenuItem>
                            {waterfrontOptions.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
                    {/* <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {waterfrontOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                  </FormControl>
                </div>

                {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                    <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            variant="outlined"
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<Input />}
                            renderValue={(selected) => selected[0] === "" ? "Locations" : ` Locations (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Locations</MenuItem>
                            {areaOptions?.sort(sortLabels).map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    /> */}


                {/* <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setLocation(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {areaOptions?.sort(sortLabels).map((item) => (
                            <MenuItem value={item.label}>{item.value}</MenuItem>
                          ))}

                        </Select>)}
                    /> */}
                {/* </FormControl>
                </div> */}

                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">TENNIS</InputLabel> */}
                    <Controller
                control={control}
                name="tennis"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      // input={<Input/>}
                      variant="outlined"
                      value={ value ? value  :tennis ? tennis:"Any Tennis"}
                      onChange={(e) => {onChange(e.target.value )}}
                      renderValue={(selected) => selected=== "" ? "Any Tennis" : selected}
                      MenuProps={MenuProps}
                      // defaultValue={tennis === "" ? "Any Tennis" : tennis}
                      // MenuProps={MenuProps}
                      // {...register("tennis")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Any Tennis">{"Tennis?"}</MenuItem>
                      {tennisOptions.map((item) => (
                        <MenuItem className="advance-filter-listItem" value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
     )
    }}
  />
                  </FormControl>
                </div>

                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">POOL</InputLabel> */}
                    <Controller
                control={control}
                name="pools"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      // input={<Input/>}
                      variant="outlined"
                      value={ value ? value  :pools ? pools:"Any Pools"}
                      onChange={(e) => {onChange(e.target.value )}}
                      renderValue={(selected) => selected=== "" ? "Any Pools" : selected}
                      MenuProps={MenuProps}
                      // defaultValue={pools === "" ? "Any Pools" : pools}
                      // MenuProps={MenuProps}
                      // {...register("pools")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Any Pools">{"Pool?"}</MenuItem>
                      {poolOptions.map((item) => (
                        <MenuItem className="advance-filter-listItem" value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
   )
  }}
/>
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                control={control}
                name="pets"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      variant="outlined"
                      value={ value ? value  :pets ? pets:"Any Pets"}
                      onChange={(e) => {onChange(e.target.value )}}
                      renderValue={(selected) => selected=== "" ? "Any Pets" : selected}
                      MenuProps={MenuProps}
                      // input={<Input/>}
                      // defaultValue={pets === "" ? "Any Pets" : pets}
                      // MenuProps={MenuProps}
                      // {...register("pets")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Any Pets">{"Pets?"}</MenuItem>
                      {petsOptions.map((item) => (
                        <MenuItem className="advance-filter-listItem" value={item.value}>{item.value}</MenuItem>
                      ))}
                    </Select>
  )
}}
/>
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      variant="outlined"
                      value={ value ? value  :status ? status:"Any Status"}
                      onChange={(e) => {onChange(e.target.value )}}
                      renderValue={(selected) => selected=== "" ? "Any Status" : selected}
                      MenuProps={MenuProps}
                      // input={<Input/>}
                      // defaultValue={status === "" ? "Any Status" : status}
                      // MenuProps={MenuProps}
                      // {...register("status")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Any Status">{"Status?"}</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="active">Active</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="Delete">Deleted</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="inactive">Inactive</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="Off the Market">OTM/Inactive</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="Sold">Sold</MenuItem>
                    </Select>
  )
}}
/>
                  </FormControl>
                </div>
                {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <Select
                      variant="outlined"
                      // input={<Input/>}
                      defaultValue={ownerId === "" ? "Owners" : ownerId}
                      MenuProps={MenuProps}
                      {...register("ownerId")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Owners">{"Owners?"}</MenuItem>
                      {ownersData?.listAllUsers?.map((item) => (
                        <MenuItem className="advance-filter-listItem" value={item._id}>{item.FirstName}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </div> */}
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      name="ownerId"
                      defaultValue={ownerId}
                      render={({ field: { onChange, value } }) => {
                        return (

                          <div className="formTextBoxOuter">
                            <Autocomplete
                              id="owner"
                              //placeholder="Owners"
                              options={ownerList??[]}
                              defaultValue={defaultValue2}
                              //options={agentList ?? []}
                              // value={value}
                              inputValue={searchKeyword}
                              //getOptionLabel={(agentList) => agentList.label}
                              getOptionLabel={option => option?.label}
                              onChange={(e, value) => {
                                onChange(value?.value);
                                setDefaultValue2(value)
                                //checkForChanges("OwnerId" , value?.value);
                              }}
                              // defaultValue={agentId && agentId}
                              // defaultValue={defaultValue?.Owner}
                              //disabled={user?.data.Role == "owner" || user?.data.Role == "renter"}
                              onInputChange={(e, value) => { setSearchKeyword(value);  }}
                              ListboxProps={{
                                onScroll: (event) => {
                                  const listboxNode = event.target;
                                  if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight) + 1 === listboxNode.scrollHeight) {
                                    setTimeout(() => {
                                      handleDropdown(event)
                                    }, listboxNode.scrollTop + 100)
                                  }
                                }
                              }}
                              // renderOption={option => (
                              //   <>
                              //     {option && (option.label)}
                              //   </>
                              // )}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Owners"
                                  variant="outlined"
                                  className="formTextBox form"
                                  // {...register("OwnerId")}
                                  //error={errors.OwnerId}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {ownerLoading ? <CircularProgress color="inherit" size={10} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {/* {errors.OwnerId && (
                            <p className="errorMessage">
                              {errors.OwnerId.message}
                            </p>
                          )} */}
                          </div>
                        )
                      }}
                    />
                  </FormControl>
                </div>
                
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <Controller
                      control={control}
                      name="agentId"
                      defaultValue={agentId||agentValue}
                      render={({ field: { onChange, value } }) => {
                        return (

                          <div className="formTextBoxOuter">
                            <Autocomplete
                              id="agent"
                              //placeholder="Owners"
                              options={agentList??[]}
                              defaultValue={defaultValue||agentValues}
                              //options={agentList ?? []}
                              // value={value}
                              inputValue={searchKeyword1}
                              //getOptionLabel={(agentList) => agentList.label}
                              getOptionLabel={option => option?.label}
                              onChange={(e, value) => {
                                onChange(value?.value);
                                setDefaultValue(value);
                                setAgentValue("")
                                setAgentName("")
                                //checkForChanges("OwnerId" , value?.value);
                              }}
                              // defaultValue={agentId && agentId}
                              // defaultValue={defaultValue?.Owner}
                              //disabled={user?.data.Role == "owner" || user?.data.Role == "renter"}
                              onInputChange={(e, value) => { setSearchKeyword1(value);  }}
                              ListboxProps={{
                                onScroll: (event) => {
                                  const listboxNode = event.target;
                                  if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight) + 1 === listboxNode.scrollHeight) {
                                    setTimeout(() => {
                                      handleDropdown(event)
                                    }, listboxNode.scrollTop + 100)
                                  }
                                }
                              }}
                              // renderOption={option => (
                              //   <>
                              //     {option && (option.label)}
                              //   </>
                              // )}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Agents"
                                  variant="outlined"
                                  className="formTextBox form"
                                  // {...register("OwnerId")}
                                  //error={errors.OwnerId}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {agentLoading ? <CircularProgress color="inherit" size={10} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {/* {errors.OwnerId && (
                            <p className="errorMessage">
                              {errors.OwnerId.message}
                            </p>
                          )} */}
                          </div>
                        )
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">PETS</InputLabel> */}
                    <Controller
                control={control}
                name="style"
                render={({ field: { onChange, value } }) => {
                  // console.log("vl", value)
                  return (
                    <Select
                      variant="outlined"
                      // value={ value ? value  :style ? style:"Any Style"}
                      // onChange={(e) => {onChange(e.target.value )}}
                      // renderValue={(selected) => selected=== "" ? "Any Style" : selected}
                      MenuProps={MenuProps}
                       value={value && value?.length > 0 ? value : [""]}
                       onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                       renderValue={(selected) => selected[0] === "" ? "Style?" : selected}
                      // input={<Input/>}
                      // defaultValue={status === "" ? "Any Status" : status}
                      // MenuProps={MenuProps}
                      // {...register("status")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} key={0} value={[]}>{"Style?"}</MenuItem>
                      <MenuItem className="advance-filter-listItem" value="Condo/Co-op">Condo/Co-op</MenuItem>
                    </Select>
  )
}}
/>
                  </FormControl>
                </div>
                {/* <div className="col-md-4">
                  <FormControl className={classes.formControl}>
                    <Select
                      variant="outlined"
                      // input={<Input/>}
                      defaultValue={agentId === "" ? "Agents" : agentId}
                      MenuProps={MenuProps}
                      {...register("agentId")}
                    >
                      <MenuItem style={{ color: '#6e7073' }} value="Agents">{"Agents?"}</MenuItem>
                      {ownersData?.listAllUsers?.map((item) => (
                        <MenuItem className="advance-filter-listItem" value={item._id}>{item.FirstName}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </div> */}

                <div className="col-md-12 mb-2 ms-2">
                  <h6 className="mt-3">Property List as</h6>
                  <FormControlLabel
                    className="advance-filter-Property-type"
                    control={
                      <Checkbox
                        checked={propertyType.isPrivateProperty}
                        onChange={(e) => setPropertyType({ ...propertyType, [e.target.name]: e.target.checked })}
                        name="isPrivateProperty"
                        color="primary"
                      // {...register("isPrivateProperty")}
                      />
                    }
                    label="Private"
                  />
                </div>

              </div>
            </form>
          </div>
        </div>}
        <div className="adminListingSearhButton">
          {selectedItem.length > 0 ? (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpenModalCustomer(true);
              }}
            >
              MCP
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                toggleSnackbar({
                  status: true,
                  message: "Please select Property",
                  variant: "error",
                });
              }}
            >
              MCP
            </Button>
          )}
          {selectedItem.length > 0 ? (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpenModalAgent(true);
              }}
            >
              MAP
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                toggleSnackbar({
                  status: true,
                  message: "Please select Property",
                  variant: "error",
                });
              }}
            >
              MAP
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={privateItem.length > 0}
            onClick={() => {
              selectedItem.length > 0 ? handleFeatured() :
                toggleSnackbar({
                  status: true,
                  message: "please select Property",
                  variant: "error",
                })
            }}
          >
            Feature
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => {
              selectedItem.length > 0 ? setOpenModalSuggest(true) : toggleSnackbar(
                {
                  status: true,
                  message: "Please select Property",
                  variant: "error",
                }
              )
            }}
          >
            Suggest
          </Button>
          {selectedItem.length > 0 ? (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpenModalEmailOwner(true);
              }}
            >
              Email Owner
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                toggleSnackbar({
                  status: true,
                  message: "Please select Property",
                  variant: "error",
                });
              }}
            >
              Email Owner
            </Button>
          )}
          {selectedItem.length > 0 ? (
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpenModalEmailRenter(true);
              }}
            >
              Email Renter
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                toggleSnackbar({
                  status: true,
                  message: "Please select Property",
                  variant: "error",
                });
              }}
            >
              Email Renter
            </Button>
          )}
          {openModalCustomer === true && (
            <CustomizedModal
              component={CustomerPrint}
              label="Multiple Customer Print"
              open={openModalCustomer}
              //open={openCustomerPrint}
              data={selectedItem}
              setOpenAgentPrint={setOpenModalCustomer}
            />
          )}
          {openModalAgent === true && (
            <CustomizedModal
              component={AgentPrint}
              label="Multiple Agent Print"
              open={openModalAgent}
              //open={openCustomerPrint}
              data={selectedItem}
              setOpenAgentPrint={setOpenModalAgent}
            />
          )}
          {/* {openModalSuggest === true && (
            <CustomizedModal
              component={Suggest}
              label="Multiple suggest"
              open={openModalSuggest}
              //open={openCustomerPrint}
              data={selectedItem}
              setOpenAgentPrint={setOpenModalSuggest}
            />
          )} */}

          <div className="adminListingSearhButton-all-select">
            <FormControlLabel
              control={<Checkbox color="primary"
              // disabled={selectAllChecked}
              disabled={status==="Delete"||status==="inactive"||status==="Off the Market"||status==="Sold"}
                checked={selectAllChecked}
                onChange={(e) =>
                  handleSelectAll(e.target.checked)
                }
              />}
              label={<span style={{ color: "#A88585" }}>Select All</span>}
            />
          </div>
        </div>
        <div className="content-wrap">
          {propertyCount > 0 && <label className="admin-property-count">{propertyCount + " results"}</label>}
          <SideBar selected="properties" />
          <PropertyListing
            propertyList={propertyList?.propertySearch?.data}
            handleChecked={handleChecked}
            handleSelectAll={handleSelectAll}
            selectedItem={selectedItem}
            selectedId={selectedId}
            openModalEmailRenter={openModalEmailRenter}
            setOpenModalEmailRenter={setOpenModalEmailRenter}
            openModalEmailOwner={openModalEmailOwner}
            setOpenModalEmailOwner={setOpenModalEmailOwner}
            openModalSuggest={openModalSuggest}
            setOpenModalSuggest={setOpenModalSuggest}
            handlePagination={handlePagination}
          />
          {!loading && propertyCount > 0 && (<>
             {/* <Select
             // variant="outlined"
             onChange={handlePageInputChange}
             value={rowsPerPage}
           >
             <MenuItem value={10}>{10}</MenuItem>
             <MenuItem value={25}>{25}</MenuItem>
             <MenuItem value={50}>{50}</MenuItem>
             <MenuItem value={100}>{100}</MenuItem>
           </Select> */}
            <Pagination
              component="div"
              className="propertyPagination"
              //rowsPerPageOptions={[]}
              count={Math.ceil(propertyCount / rowsPerPage)}
              //rowsPerPage={rowsPerPage}
              page={page}
              onChange={handleChangePage}
            />
         </> )}
        </div>
      </div>
    </div>
  );
};

export default Properties;
