import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_LEAD_ACTIVITY,
  APPROVE_DISAPPROVE_PPTY,
} from "../../../Graghql/mutations";
import { LEAD_ACTIVITY_LIST, LEADS_DETAIL } from "../../../Graghql/queries";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables/index";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { DialogBox } from "../../Common";
import Loader from "../../Common/Loader";
import CustomizedModal from "../../Common/customizedModal";
import AgentPrint from "../../Properties/propertyActions/AgentPrint";
import CustomerPrint from "../../Properties/propertyActions/CustomerPrint";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import EmailListing from "../../Properties/propertyActions/EmailListing";
import Popover from "@material-ui/core/Popover";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function ViewMore(props) {
  //console.log("ppp",props)
  let { index } = props;
  // return null
  const [priceList] = useState(false);
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {props.price && (
        <div className="pp-availability">
          {props.price[index] &&
            props.price[index].map((priceItem, index) => {
              if (!priceList && index >= 1) return null;
              return (
                <div className="d-flex align-items-center">
                  <img
                    src="../assets/images/icon-calendar.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                    style={{ marginTop: "-3px" }}
                  />
                  <div key={index}>{`${
                    priceItem.RentalPeriod
                  } :  $${formatePrice(priceItem.Price)}`}</div>
                </div>
              );
            })}

          {
            <div>
              {props.price[index] && props.price[index]?.length > 1 && (
                <>
                  <IconButton
                    aria-describedby={id}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    <label style={{ color: "#D08383" }}>
                      {!priceList
                        ? `+${props.price[index]?.length - 1} More`
                        : "View Less"}
                    </label>
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div>
                      {props.price[index] &&
                        props.price[index].map((priceItem, index) => {
                          if (!priceList && index === 0) return null;
                          return (
                            <div style={{ margin: "10px" }} key={index}>{`${
                              priceItem.RentalPeriod
                            } :  $${formatePrice(priceItem.Price)}`}</div>
                          );
                        })}
                    </div>
                  </Popover>
                </>
              )}
            </div>
          }

          {/* {props.price[index] && props.price[index]?.length>1 && (
    <IconButton onClick= {()=>setPriceList(!priceList)} className="p-0">
    <label style={{color:'#D08383'}}>{!priceList ? `+${props.price[index]?.length-1} More` : "View Less"}</label> <ExpandMoreIcon/>
  </IconButton>

  )} */}
        </div>
      )}
    </div>
  );
}

const LeadsPortfolio = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);
  const [, setIsPaginating] = useState(false);
  const [delId, setDelId] = useState();
  const [itemId, setItemId] = useState();
  const [pricing, setPricing] = useState(null);
  const [multiAgentPrint, setMultiAgentPrint] = useState(false);
  const [multiEmailOwner, setMultiEmailOwner] = useState(false);
  const [multiCustomerPrint, setMultiCustomerPrint] = useState(false);
  const [checked, setChecked] = useState([]);
  const [openPopUpCheck, setOpenPopUpCheck] = useState(false);
  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  const leadActivityListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    tabType: "Owned",
    // tabType: "Property",
    leadId: id,
  };

  const handlePagenation = () => {
    fetchMore({
      variables: leadActivityListVariables,
    });
  };
  React.useEffect(() => {
    handlePagenation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  //User Type
  const {
    data: leadData,
    //loading
  } = useQuery(LEADS_DETAIL, {
    variables: { leadId: id },
  });
  // eslint-disable-next-line no-unused-vars
  let uType = leadData?.leadsDetails?.UserType;
  // console.log("ldata",uType)

  const {
    data: portfolio,
    loading: portfolioLoading,
    fetchMore,
  } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    //getting the available status items with value yes and pushing it to array
    onCompleted: (res) => {
      //console.log("res",res?.leadActivityList?.data)
      if (res?.leadActivityList) {
        let array = [];
        if (res && res.leadActivityList && res.leadActivityList.data) {
          res.leadActivityList.data.forEach((item) => {
            let innerArray = [];
            if (item && item.PriceDataLead && item.PriceDataLead.PriceDetails) {
              item.PriceDataLead.PriceDetails.forEach((element) => {
                if (element && element.AvblStatus === "yes") {
                  innerArray.push(element);
                }
              });
            }
            array.push(innerArray);
          });
        }

        setPricing(array);
      }
    },
  });

  const [approveDisapprove] = useMutation(APPROVE_DISAPPROVE_PPTY, {
    // refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
    // onCompleted: (res) => {
    //   refetch();
    // },
  });
  const [deleteLeadActivity] = useMutation(DELETE_LEAD_ACTIVITY, {
    refetchQueries: ["leadActivityList"],
  });
  //getting featured image ==> one with value true
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  //formate pricing
  // eslint-disable-next-line no-unused-vars
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  let portfolioCount =
    portfolio &&
    portfolio?.leadActivityList &&
    portfolio?.leadActivityList?.count;
  let portfolioData =
    portfolio &&
    portfolio?.leadActivityList &&
    portfolio?.leadActivityList?.data;
  console.log("count", portfolioData);

  const handleDelete = () => {
    try {
      deleteLeadActivity({
        variables: {
          leadActivityId: delId,
        },
      })
        .then((res) => {
          toggleSnackbar({
            message: "Successfully Deleted",
            variant: "success",
            status: true,
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            message: message,
            status: true,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        message: message,
        status: true,
        variant: error,
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  function emailOwnerID(values) {
    let array = [];
    values.forEach((element) => {
      console.log("element", element);
      let obj = element?.OwnerDtls?.OwnerId;
      array.push(obj);
    });
    return array;
  }
  function formatData(data) {
    console.log("ttttt", data);
    let array = [];
    data.forEach((element) => {
      let obj = {};
      obj["PID"] = element?.PropertyDtls?.PID;
      obj["Longitude"] = element?.PropertyDtls?.Longitude;
      obj["Latitude"] = element?.PropertyDtls?.Latitude;
      obj["AdTitle"] = element?.PropertyDtls?.AdTitle;
      obj["Area"] = element?.PropertyDtls?.Area;
      obj["Images"] = element?.ImagesLead;
      obj["ListBathRoom"] = element?.PropertyDtls?.ListBathRoom;
      obj["ListBedRoom"] = element?.PropertyDtls?.ListBedRoom;
      obj["OwnerDtls"] = element?.OwnerDtls;
      obj["OwnerId"] = element?.OwnerDtls?.OwnerId;
      obj["AgentDtls"] = element?.PrtyAgentDtls;
      obj["PriceData"] = element?.PriceDataLead;
      obj["ListAirCondition"] = element?.PropertyDtls?.ListAirCondition;
      obj["ListPet"] = element?.PropertyDtls?.ListPet;
      obj["ListPool"] = element?.PropertyDtls?.ListPool;
      obj["ListStyle"] = element?.PropertyDtls?.ListStyle;
      obj["ListTennis"] = element?.PropertyDtls?.ListTennis;
      obj["ListWater"] = element?.PropertyDtls?.ListWater;
      obj["LivingArea"] = element?.PropertyDtls?.LivingArea;
      obj["State"] = element?.PropertyDtls?.State;
      obj["LotSize"] = element?.PropertyDtls?.LotSize;
      obj["YearBuilt"] = element?.PropertyDtls?.YearBuilt;
      obj["Zip"] = element?.PropertyDtls?.Zip;
      obj["ListBasement"] = element?.PropertyDtls?.ListBasement;
      obj["ListGarage"] = element?.PropertyDtls?.ListGarage;
      obj["ListHeat"] = element?.PropertyDtls?.ListHeat;
      obj["KeyLocation"] = element?.PropertyDtls?.KeyLocation;
      obj["ListMainAmenity"] = element?.PropertyDtls?.ListMainAmenity;
      obj["_id"] = element?.PropertyId;
      obj["OwnerEmail"] = element?.OwnerDtls?.Email;

      array.push(obj);
      console.log("arr", array);
    });
    return array;
  }

  // eslint-disable-next-line no-unused-vars
  function handleChecked(item, value) {
    let checkedArray = [...checked];
    if (value === true) {
      checkedArray.push(item);
      // console.log("chkd",checkedArray)
    } else {
      let isChecked = checkedArray.findIndex((element) => element._id !== 0);
      //console.log("isChecked",isChecked)
      if (isChecked !== -1) {
        checkedArray.splice(isChecked);
      }
    }
    setChecked(checkedArray);
  }

  const handleApproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div className="col-md-8">
      <Loader open={portfolioLoading} />
      <div>
        <DialogBox
          negativeBtn={true}
          open={openPopUpCheck}
          setOpen={setOpenPopUpCheck}
          handlePositiveBtn={() => handleApproveCheck()}
          handleNegativeBtn={() => handleDisapproveCheck()}
          negativeText={"Decline"}
          positiveText={"Approve"}
          title={"Approve/Decline ?"}
          text={
            "Before editing,  you need to either Approve/Decline this Property with the changes made ?"
          }
        />
        <DialogBox
          open={isDeleted}
          setOpen={setIsDeleted}
          handlePositiveBtn={handleDelete}
          text={"Do you want to Delete this Property ?"}
        />
      </div>
      <div className="property-portfolio">
        <div className="property-portfolio-title">
          <label>{portfolioCount} Properties</label>
          {userRole === "owner" && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => history.push("/addProperty/$")}
            >
              Add Property
            </Button>
          )}
          {/* {uType === "renter" ? (<div>
            <Button type="submit" color="primary" variant="outlined">
              Email Property
            </Button>
            {portfolio?.leadActivityList?.data&&
            <Button type="submit" color="primary" variant="outlined"
            onClick ={()=>{
              if(checked.length>0 )
             setMultiCustomerPrint(true)
             else toggleSnackbar({
                  status: true,
                  message: "Select a property",
                  variant: "error",
                })}}>
              MCP
            </Button>}
           {portfolioData && <Button type="submit" color="primary" variant="outlined"
            onClick={()=>{checked.length>0 ? setMultiAgentPrint(true) : toggleSnackbar({
                  status: true,
                  message: "Select a property",
                  variant: "error",
                })}}>
              MAP
            </Button>}
            <Button type="submit" color="primary" variant="outlined"
            onClick={()=>{checked.length>0 ? setMultiEmailOwner(true) : toggleSnackbar({
              status: true,
              message: "Select a property",
              variant: "error",
            })}}
            >
              Email Owner
            </Button>
          </div>) : null} */}
        </div>
        <div className="pp-item-wrap">
          <div className="row">
            {portfolioData && portfolioData.length > 0 ? (
              portfolioData &&
              portfolioData.map((item, index) => (
                <div key={index} className="col-md-6 pl-item-space">
                  <div className="pp-item">
                    {item.ImagesLead && (
                      <div className="pp-img">
                        {userRole === "owner" &&
                        item.UserId === user?.data?._id ? (
                          <img
                            onClick={(e) => {
                              history.push(
                                `/${item?.PropertyDtls.PropertySlug}`
                              );
                            }}
                            src={
                              item?.ImagesLead &&
                              getFeaturedImage(item?.ImagesLead)
                            }
                            className="img-fluid"
                            alt="Property"
                          />
                        ) : (
                          <img
                            onClick={(e) => {
                              history.push(
                                `/${item?.PropertyDtls.PropertySlug}`
                              );
                            }}
                            src={
                              item?.ImagesLead &&
                              getFeaturedImage(item?.ImagesLead)
                            }
                            className="img-fluid"
                            alt="Property"
                          />
                        )}
                        {/* {uType=== "renter"? (<Checkbox className="pp-check" color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                        {/* {userRole === "owner" && item.UserId === user?.data?._id &&  */}
                        <IconButton
                          onClick={(e) => {
                            setItemId(item._id);
                            (user?.data?.Role === "admin" ||
                              user?.data?.Role === "agentAdmin") &&
                            item?.PropertyDtls?.PendingFlag === true
                              ? setOpenPopUpCheck(true)
                              : history.push({
                                  pathname: `/addProperty/${item.PropertyId}`,
                                  state: { title: "portfolioEdit" },
                                });
                          }}
                          className={
                            item?.LeadType === "Suggest"
                              ? "pp-close pp-edit"
                              : "pp-close"
                          }
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                        {/* } */}
                        {item?.LeadType === "Suggest" && (
                          <IconButton
                            onClick={() => {
                              setDelId(item._id);
                              setIsDeleted(true);
                            }}
                            className="pp-close"
                          >
                            <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            />
                            {/* <CancelIcon /> */}
                          </IconButton>
                        )}
                      </div>
                    )}
                    <div className="pp-id">
                      <label>
                        PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                      </label>
                      {item?.LeadType === "Enquiry" && (
                        <span>
                          <img
                            style={{ width: "20px" }}
                            src="../assets/images/Enquiry.png"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {/* <FavoriteIcon color="primary"/> */}
                          {item?.LeadType}
                        </span>
                      )}
                      {item?.LeadType === "Suggest" && (
                        <span>
                          <img
                            style={{ width: "20px" }}
                            src="../assets/images/Suggest.png"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {/* <FavoriteIcon color="primary"/> */}
                          {item?.LeadType}
                        </span>
                      )}
                      {item?.LeadType === "Save" && (
                        <span>
                          <img
                            style={{ width: "20px" }}
                            src="../assets/images/Save.png"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {/* <FavoriteIcon color="primary"/> */}
                          {item?.LeadType}
                        </span>
                      )}
                      {item?.LeadType === "View" && (
                        <span>
                          <img
                            style={{ width: "20px" }}
                            src="../assets/images/View.png"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {/* <FavoriteIcon color="primary"/> */}
                          {item?.LeadType}
                        </span>
                      )}
                      {(item?.LeadType === "Owned" ||
                        item?.LeadType === "Property") && (
                        <span>
                          <img
                            style={{ width: "20px" }}
                            src="../assets/images/Ownericon.svg"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {/* <FavoriteIcon color="primary"/> */}
                          {item?.LeadType === "Property" ? "Owned" : "Owned"}
                        </span>
                      )}
                    </div>
                    <div className="lp-detail">
                      <h3
                        onClick={() =>
                          history.push(`/${item?.PropertyDtls.PropertySlug}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                        {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase()+item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                      </h3>
                      {item?.PropertyDtls?.Area && (
                        <label>
                          <img
                            src="../assets/images/icon-location-outlined.svg"
                            className="img-fluid"
                            alt="Icon"
                          ></img>
                          {item?.PropertyDtls &&
                            item?.PropertyDtls?.Area.charAt(0).toUpperCase() +
                              item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                        </label>
                      )}
                      <div className="lp-rooms d-flex align-items-center">
                        {item?.PropertyDtls?.LivingArea && (
                          <label>
                            <img
                              src="../assets/images/icon-sqft.svg"
                              className="img-fluid"
                              alt="Icon"
                            ></img>
                            {item?.PropertyDtls &&
                              item?.PropertyDtls?.LivingArea}
                            sq
                          </label>
                        )}
                        {item?.PropertyDtls?.ListBedRoom && (
                          <label>
                            <img
                              src="../assets/images/icon-bed.svg"
                              className="img-fluid"
                              alt="Icon"
                            ></img>
                            {item?.PropertyDtls &&
                              item?.PropertyDtls?.ListBedRoom}
                          </label>
                        )}
                        {item?.PropertyDtls?.ListBathRoom && (
                          <label>
                            <img
                              src="../assets/images/icon-bath.svg"
                              className="img-fluid"
                              alt="Icon"
                            ></img>
                            {item?.PropertyDtls &&
                              item?.PropertyDtls?.ListBathRoom}
                          </label>
                        )}
                      </div>
                    </div>
                    <ViewMore price={pricing} index={index} />
                  </div>
                </div>
              ))
            ) : (
              <Typography className="text-center">
                No Properties to list
              </Typography>
            )}

            {!portfolioLoading && portfolioCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={portfolioCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
            {multiAgentPrint === true && (
              <CustomizedModal
                component={AgentPrint}
                label="Multiple Agent Print"
                open={multiAgentPrint}
                data={formatData(checked)}
                setOpenAgentPrint={setMultiAgentPrint}
              />
            )}
            {multiCustomerPrint === true && (
              <CustomizedModal
                component={CustomerPrint}
                label="Multiple Customer Print"
                open={multiCustomerPrint}
                data={formatData(checked)}
                setOpenAgentPrint={setMultiCustomerPrint}
              />
            )}
            {multiEmailOwner === true && (
              <CustomizedModal
                hideHeader={true}
                component={EmailListing}
                emailOwner={multiEmailOwner}
                label="Email Owner's"
                open={multiEmailOwner}
                // data={formatData(checked)}
                setOpenAgentPrint={setMultiEmailOwner}
                propertyDetails={formatData(checked)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsPortfolio;
