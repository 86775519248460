import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_ALL_USERS,LIST_ALL_SUGGEST_USERS } from "../../../Graghql/queries";
import { EMAIL_TO_USERS } from "../../../Graghql/mutations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const EmailListing = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchKeyword, setSearchKeyword] = useState("");
  const [options, setOptions] = useState([])
  const schema = yup.object().shape({
    EmailTo: props.emailOwner
      ? null
      : yup
          .array().of(
            yup.string()
          )
          .required("Email is required").nullable()
          // .matches(
          //   /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
          //   "Email must be valid"
          // )
       ,
    Email: props.emailOwner
      ? null
      : yup
          .string()
          .test("email-validation", "Must be a valid Email", (value) => {
            if (value) {
              const array = value.split(",");
              let flag = true;
              array.forEach((element) => {
                if (
                  !element.match(
                    /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/
                  )
                )
                  flag = false;
              });

              return flag;
            } else return true;
          }),
          Subject: yup.string().required("Subject is required")
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ resolver: yupResolver(schema) });
  let details = props.propertyDetails;


  // eslint-disable-next-line no-unused-vars
  const { data:userList, loading: userLoading } = useQuery(LIST_ALL_SUGGEST_USERS, {
    variables: { 
      search:searchKeyword,
      role: "renter",
    skip:page * rowsPerPage,
    limit: rowsPerPage},
    onCompleted:res=>{
      setOptions([...options,...res.listUsersForSuggest?.data]);
      // if(page * rowsPerPage<res.listUsersForSuggest?.count)
      //   setPage(page+1);
    }
  });
  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(LIST_ALL_USERS, { variables: { role: "renter",skip:0,limit:20} });
  const [emailtoUsers] = useMutation(EMAIL_TO_USERS);
  const [selectEmail, setSelectEmail] = useState([]);
  const filter = createFilterOptions();
  let ownerArr = [];
  details&&details.forEach((element) => {
      let ownerIds = element?.OwnerDtls?.Email;
      ownerArr.push(ownerIds);
    });
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  function onSubmit(params) {
    let ownerArr = [];
    details&&details.forEach((element) => {
      let ownerIds = element?.OwnerDtls?.Email;
      ownerArr.push(ownerIds);
    });
    props.emailOwner
      ? (params.toEmailIDs = ownerArr)
      : (params.toEmailIDs = selectEmail);
    props.emailOwner
      ? (params.ccEmailIDs = [""])
      : (params.ccEmailIDs = params.Email.split(","));
    let arr = [];
    details&&details.forEach((element) => {
      let ids = element?._id;
      arr.push(ids);
    });

    params.propertyIDs = arr;
    params.text = params.Message;
    params.subject=params.Subject;
    props.emailOwner
    ? (params.mailType = "emailToOwner")
    : (params.mailType = null);

    try {
      emailtoUsers({ variables: params })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Email Successfully Sent",
            variant: "success",
          });
          props.setOpenAgentPrint(false);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  function TexttView(props) {
    const [visible, setVisible] = useState(false);
    function formateText(text) {
      if (!visible) {
        if (text.length > 50) return text.slice(0, 50) + " ...";
        else return text.slice(0, 50);
      } else return text;
    }
    return (
      <div style={{ textOverflow: "ellipsis" }}>
        {formateText(props.text)}
        {props.text.length > 50 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{ color: "#A64C48", textDecoration: "underline" }}
            onClick={() => setVisible(!visible)}
          >
            {visible ? " read less" : " read more"}
          </a>
        )}
      </div>
    );
  }
  //  function onChange(value){
  //   let allSelectedEmail = [...selectEmail]
  //   allSelectedEmail.push(value)
  //   setSelectEmail(allSelectedEmail)
  //   console.log("value",value)
  //  }

  //  function handleChangeCC(v){
  //    const splitValue= v.split(",")
  //    console.log("spvalue",splitValue);
  //   let splitString = [...selectedCC]
  //  }
  function getFeaturedImage(images) {
    if (images) {
      let position = images?.findIndex((element) => element.Featured === true);
      if (position !== -1) return images[position]?.FileUrl;
    } else return null;
  }

  function monthRenaming(months) {
    switch (months) {
      case "April/May":
        return "Apr/May";
      case "MD-LD":
        return "MD-LD";
      case "August - LD":
        return "Aug-LD";
      case "July-August-LD":
        return "Jul-Aug-LD";
      case "June/July":
        return "Jun/Jul";
      case "September/October":
        return "Sep/Oct";
      case "Extended Season":
        return "Ext Season";
      case "Year Round":
        return "Yr Round";
      case "Two Weeks":
        return "2 Weeks";
      case "Short Term":
        return "Srt Term";
      case "July - LD":
        return "Jul-LD";

      default:
        return months.substring(0, 3);
    }
  }
  const handleDropdown =(event)=>{
    setRowsPerPage(rowsPerPage+10)
      setTimeout(() => {
        setRowsPerPage(rowsPerPage+10)
  }, 500);
   }
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  const registerRenterEmail = register('EmailTo');
  return (
    <div className="email-listing">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row">
            {props.emailOwner ? (
              <div className="col-md-12">
                <div className="email-listing-send">
                  <div className="els-item">
                    <InputLabel className="form-label">To</InputLabel>
                    <TextField
                      // className="w-100"
                      className="property-boundary-box"
                      variant="outlined"
                      disabled
                      // {...register("EmailTo", { required: true })}
                      // onChange={(e,v)=>handleChangeCC(v)}
                       value={ownerArr}
                      // placeholder="Email"
                      // error={errors?.Email?.message}
                    />
                     {/* {errors.EmailTo && <span className="error-msg">{errors?.EmailTo?.message}</span>} */}
                  </div>
                  {/* <div className="els-item">
                    <InputLabel className="form-label">Cc</InputLabel>
                    <TextField
                      className="w-100"
                      className="property-boundary-box"
                      variant="outlined"
                      {...register("Email")}
                      // onChange={(e,v)=>handleChangeCC(v)}
                      // value={comment}
                      placeholder="Email"
                      error={errors?.Email?.message}
                    />
                    {errors.Email && <span className="error-msg">{errors?.Email?.message}</span>}
                  </div> */}

                  <div className="els-item">
                    <InputLabel className="form-label">Subject<sup>*</sup></InputLabel>
                    <TextField
                      // className="w-100"
                      // multiline="bool"
                      className="property-boundary-box"
                      // rows={4}
                      variant="outlined"
                      {...register("Subject", { required: true })}
                      // onChange={handleChange}
                      // value={comment}
                      placeholder="Subject"
                    />
                    {errors.Subject && <span className="error-msg">{errors?.Subject?.message}</span>}
                  </div>
                  <div className="els-item">
                    <InputLabel className="form-label">Message</InputLabel>
                    <TextField
                      // className="w-100"
                      multiline="bool"
                      className="property-boundary-box"
                      rows={4}
                      variant="outlined"
                      {...register("Message", { required: true })}
                      // onChange={handleChange}
                      // value={comment}
                      placeholder="Message"
                      //inputProps={{maxLength: 100 }}
                    />
                  </div>
                  <div className="els-item d-flex align-items-center justify-content-between">
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      className="els-send"
                      onClick={()=>{props.setOpenAgentPrint(false)}}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="els-send"
                      
                    >
                     Send
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-5">
                <div className="email-listing-send">
                  <div className="els-item">
                    <InputLabel className="form-label">To<sup>*</sup></InputLabel>
                    <Autocomplete
                    
                      multiple
                      id="Renter"
                      value={selectEmail}
                      inputValue={searchKeyword}
                      {...registerRenterEmail}
                      onChange={(e, value) => {
                        setSelectEmail(value);
                        registerRenterEmail.onChange({target:{value:value,name:"EmailTo"}})
                      }}
                      onInputChange={(e,value)=>{ setSearchKeyword(value);}}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push(params.inputValue);
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={
                        options
                          ? options?.map((option) => option?.Email)
                          : {}
                      }
                      getOptionLabel={(option) => option}
                      renderOption={(option) => option}
                      // freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      ListboxProps={{
                        onScroll: (event) => {
                          const listboxNode = event.target;
                                if (Math.floor(listboxNode.scrollTop + listboxNode.clientHeight)+1 === listboxNode.scrollHeight) {
                                                setTimeout(() => {
                                                      handleDropdown(event)
                                                }, listboxNode.scrollTop + 100)
                                }
                        }}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                           variant="outlined"
                          placeholder="Email"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <>
                                   { userLoading? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                            </>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.EmailTo && (
                      <span className="error-msg">
                        {errors?.EmailTo?.message}
                      </span>
                    )}
                  </div>
                  <div className="els-item">
                    <InputLabel className="form-label">Cc</InputLabel>
                    <TextField
                      // className="w-100"
                      className="property-boundary-box "
                      variant="outlined"
                      {...register("Email")}
                      // onChange={(e,v)=>handleChangeCC(v)}
                      // value={comment}
                      placeholder="Email"
                      error={errors?.Email?.message}
                    />
                    {errors.Email && (
                      <span className="error-msg">
                        {errors?.Email?.message}
                      </span>
                    )}
                  </div>
                  <div className="els-item">
                    <InputLabel className="form-label">Subject<sup>*</sup></InputLabel>
                    <TextField
                      // className="w-100"
                      // multiline="bool"
                      className="property-boundary-box"
                      // rows={4}
                      variant="outlined"
                      {...register("Subject", { required: true })}
                      // onChange={handleChange}
                      // value={comment}
                      placeholder="Subject"
                    />
                    {errors.Subject && (
                      <span className="error-msg">
                        {errors?.Subject?.message}
                      </span>
                    )}
                  </div>
                  <div className="els-item">
                    <InputLabel className="form-label">Message</InputLabel>
                    <TextField
                      // className="w-100"
                      multiline="bool"
                      className="property-boundary-box"
                      rows={4}
                      variant="outlined"
                      {...register("Message", { required: true })}
                      // onChange={handleChange}
                      // value={comment}
                      placeholder="Message"
                      //inputProps={{maxLength: 100 }}
                    />
                  </div>
                  <div className="els-item d-flex align-items-center justify-content-between">
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      className="els-send"
                      onClick={()=>{props.setOpenAgentPrint(false)}}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="els-send"
                      
                    >
                     Send
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!props.emailOwner && (
              <div className="col-md-7">
                {props?.propertyDetails?.map((item) => {
                  console.log("itemmm-->",item)
                  return(
                  <div className="email-listing-wrap">
                    <table
                      width="100%"
                      align="center"
                      valign="center"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td>
                          <table
                            className="el-header"
                            align="center"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="100%"
                          >
                            <tr>
                              <td>
                                <img alt="profile" src="https://hrfileuploads.s3.amazonaws.com/systemFiles/logo-email-listing.png" />
                              </td>
                              <td align="right">
                                <span>
                                  Call us <label>631.324.0009</label>
                                </span>
                              </td>
                            </tr>
                          </table>
                          <div className="spacer-1"></div>
                          <table
                            style={{ border: "1px solid #E2E2E2" }}
                            width="100%"
                            align="center"
                            valign="center"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                          >
                            <tr>
                              <td>
                                <table
                                  className="el-image-details"
                                  align="center"
                                  cellpadding="0"
                                  cellspacing="0"
                                  border="0"
                                  width="100%"
                                >
                                  <tr>
                                    <td className="el-image">
                                      <img
                                        src={
                                          getFeaturedImage(item?.Images)
                                            ? getFeaturedImage(item?.Images)
                                            : "../assets/images/no-image.jpg"
                                        }
                                        className="imgfluid"
                                        alt="Property"
                                      />
                                    </td>
                                    <td className="el-details-td">
                                      <div className="el-details">
                                        <label>PID#{item.PID}</label>
                                        <h1>
                                          {item?.StreetAddress?.charAt(
                                            0
                                          ).toUpperCase() +
                                            item?.StreetAddress?.slice(
                                              1
                                            ).toLowerCase()}
                                        </h1>
                                        <ul>
                                          {item?.PriceData?.PriceDetails &&
                                            item?.PriceData?.PriceDetails.slice(
                                              0,
                                              14
                                            // eslint-disable-next-line array-callback-return
                                            ).map((dates) => {
                                              if (
                                                dates.AvblStatus.toLowerCase() ===
                                                "yes"
                                              ) {
                                                return (
                                                  // <table width="100%">
                                                  //   <tr>
                                                  //   <td width="49%">{dates.RentalPeriod}</td>
                                                  //   <td  width="2%">:</td>
                                                  //   <td  width="49%" align="right">${dates.Price}</td>
                                                  //   </tr>
                                                  // </table>
                                                  <li>
                                                    {monthRenaming(
                                                      dates.RentalPeriod
                                                    )}{" "}
                                                    : $
                                                    {formatePrice(dates.Price)}
                                                  </li>
                                                );
                                              }
                                            })}
                                        </ul>

                                        <Link
                                          className="viewBtn"
                                          style={{ color: "#fff" }}
                                          to={`${item?.PropertySlug}`}
                                        >
                                          View Property
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  className="el-bottom-details"
                                  width="100%"
                                  align="center"
                                  valign="center"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <tr>
                                    <td className="el-bottom-details-padding">
                                      <table
                                        className=""
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td width="35%">
                                            <div className="el-agent-detail">
                                              <img
                                                src={
                                                  item?.AgentDtls?.ProfileImage
                                                    ? item?.AgentDtls
                                                        ?.ProfileImage
                                                    : "../assets/images/no-image.jpg"
                                                }
                                                alt="profile"
                                                className="img-fluid el-agent-img"
                                              />
                                              <div className="el-agent-contact">
                                                {item?.AgentDtls?.FirstName && (
                                                  <h6>
                                                    {item?.AgentDtls?.FirstName.charAt(
                                                      0
                                                    ).toUpperCase() +
                                                      item?.AgentDtls?.FirstName.slice(
                                                        1
                                                      ).toLowerCase() +
                                                      " " +
                                                      item?.AgentDtls?.LastName.charAt(
                                                        0
                                                      ).toUpperCase() +
                                                      item?.AgentDtls?.LastName.slice(
                                                        1
                                                      ).toLowerCase()}
                                                  </h6>
                                                )}
                                                <label>
                                                  Your Rosehip Agent
                                                </label>
                                                <ul>
                                                  {item?.AgentDtls?.Phone && (
                                                    <li>
                                                      <img
                                                        src="../assets/images/email-listing-phone.png"
                                                        alt="Phone Icon"
                                                      />
                                                      {item?.AgentDtls?.Phone}
                                                    </li>
                                                  )}
                                                  {item?.AgentDtls?.Email && (
                                                    <li>
                                                      <Link
                                                        to="#" onClick={(e)=>{window.location= "mailto:" +
                                                        item?.AgentDtls?.Email.toString()
                                                      e.preventDefault() }}
                                                      >
                                                        <img
                                                          src="../assets/images/email-listing-mail.png"
                                                          alt="Phone Icon"
                                                        />
                                                        Email
                                                      </Link>
                                                    </li>
                                                  )}
                                                  <li>
                                                    <Link
                                                      to={`property-detail/${item?._id}`}
                                                    >
                                                      <img
                                                        src="../assets/images/email-listing-list.png"
                                                        alt="List Icon"
                                                      />
                                                      My Listing
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </td>
                                          <td width="65%">
                                            <div className="el-location">
                                              <h3>
                                                {item?.AdTitle}
                                                {/* {item?.AdTitle?.charAt(
                                                  0
                                                ).toUpperCase() +
                                                  item?.AdTitle?.slice(
                                                    1
                                                  ).toLowerCase()} */}
                                              </h3>
                                              <p>
                                                {item?.AdDescription?.charAt(
                                                  0
                                                ).toUpperCase() +
                                                  item?.AdDescription?.slice(1)
                                                    .toLowerCase()
                                                    .slice(0, 50)}
                                                <Link
                                                  to={`${item?.PropertySlug}`}
                                                  style={{
                                                    color: "#A64C48",
                                                    textDecoration: "underline",
                                                  }}
                                                >
                                                  read more
                                                </Link>{" "}
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                      <table
                                        className="el-features"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <h6>Other availabilities</h6>
                                            <ul>
                                              {item?.PriceData?.PriceDetails &&
                                                item?.PriceData?.PriceDetails.slice(
                                                  14
                                                // eslint-disable-next-line array-callback-return
                                                ).map((dates) => {
                                                  if (
                                                    dates?.AvblStatus?.toLowerCase() ===
                                                    "yes"
                                                  ) {
                                                    return (
                                                      <li>
                                                        {dates?.RentalPeriod} :
                                                        $
                                                        {dates?.Price &&
                                                          formatePrice(
                                                            dates?.Price
                                                          )}
                                                      </li>
                                                    );
                                                  }
                                                })}
                                            </ul>
                                          </td>
                                        </tr>
                                      </table>
                                      <table
                                        className="el-features"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <h6>Short Term Price (Priced Weekly)</h6>
                                            {item?.PriceData?.ShortTermPriceDetails?.map((items) => (
                                            <Chip
                                              className="me-2 mt-2"
                                              label={`${convertTimeStamp(
                                                items.DateFrom
                                              )} - ${convertTimeStamp(items.DateTo)} $${items.Amount}`}
                                            />
                                           ))}
                                          </td>
                                        </tr>
                                      </table>
                                      <table
                                        className="el-features"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <h6>Features</h6>
                                            <ul>
                                              {item?.ListBasement && (
                                                <li>
                                                  Basement: {item?.ListBasement}
                                                </li>
                                              )}
                                              {item?.ListBedRoom && (
                                                <li>
                                                  Bed: {item?.ListBedRoom}
                                                </li>
                                              )}
                                              {item?.YearBuilt && (
                                                <li>
                                                  Built: {item?.YearBuilt}
                                                </li>
                                              )}
                                              {item?.ListHeat && (
                                                <li>Heat: {item?.ListHeat}</li>
                                              )}
                                              {item?.ListBathRoom && (
                                                <li>
                                                  Bath: {item?.ListBathRoom}
                                                </li>
                                              )}
                                              {item?.ListStory && (
                                                <li>
                                                  Stories: {item?.ListStory}
                                                </li>
                                              )}
                                              {item?.ListStyle && (
                                                <li>
                                                  Style: {item?.ListStyle}
                                                </li>
                                              )}
                                              {item?.LivingArea && (
                                                <li>
                                                  Living Area:{" "}
                                                  {item?.LivingArea}
                                                </li>
                                              )}
                                              {item?.LotSize && (
                                                <li>
                                                  Lot Size: {item?.LotSize}
                                                </li>
                                              )}
                                                  {item?.ListAirCondition && (
                                                <li>
                                                  AC: {item?.ListAirCondition}
                                                </li>
                                              )}
                                              {item?.ListHeat && (
                                                <li>Heat: {item?.ListHeat}</li>
                                              )}
                                              {item?.ListPool && (
                                                <li>Pools: {item?.ListPool}</li>
                                              )}
                                              {item?.ListGarage && (
                                                <li>
                                                  Garage: {item?.ListGarage}
                                                </li>
                                              )}
                                              {item?.ListFireplace && (
                                                <li>
                                                  Fireplace:{" "}
                                                  {item?.ListFireplace}
                                                </li>
                                              )}
                                              {item?.ListTennis && (
                                                <li>
                                                  Tennis: {item?.ListTennis}
                                                </li>
                                              )}
                                            </ul>
                                          </td>
                                        </tr>
                                      </table>
                                      <table
                                        className="el-features"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <h6>Amenities</h6>
                                          {  item?.ListMainAmenity&&
                                            <ul>
                                           {item?.ListMainAmenity &&
                                               item?.ListMainAmenity.map(
                                            (item) => item?.Flag === true && <li>{item?.Title}</li>
                                            )}
                                            </ul>}
                                          </td>
                                        </tr>
                                      </table>

                                      <table
                                        className="el-features"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td></td>
                                        </tr>
                                      </table>

                                      <table
                                        className="el-gallery"
                                        width="100%"
                                        align="center"
                                        valign="center"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <ul>
                                              {item?.Images &&
                                                item?.Images.map((img) => {
                                                  if (
                                                    img.Featured === true ||
                                                    img.Featured === null
                                                  ) {
                                                    return null;
                                                  } else
                                                    return (
                                                      <li>
                                                        <img
                                                          className="list-images imgfluid"
                                                          src={img?.FileUrl}
                                                          // className="imgfluid"
                                                          alt="other images"
                                                        />
                                                      </li>
                                                    );
                                                })}
                                            </ul>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                )})}
              </div>
            )}
            {/* <div className="col-md-7">
             <div>
              <div>
                <img className="feature-image"
                 style={{height:200 ,width:250}}
                  src={getFeaturedImage(props?.propertyDetails?.Images)}
                  
                  className="imgfluid"
                  alt="Icon"
                />
              </div>
              <p>PID#{detailsPID} </p>
              <h4>Address {detailsTitle + " " + detailsAddress} </h4>
                <div>
                  {props?.propertyDetails?.PriceData?.PriceDetails &&
                  props?.propertyDetails?.PriceData?.PriceDetails.map((dates)=>{
                 if(dates.AvblStatus.toLowerCase() === "yes"){
                   return(
                     <ul>
                       <li>{dates.RentalPeriod}</li>
                       <li>{dates.Price}</li>
                     </ul>
                   )
                 }   
                  })}
                  <Button
                  a href={`property-detail/${props?.propertyDetails?.PriceData?.PropertyId}`}
                  >View Property</Button>
                </div>
                

              <ul>
                <li>
                  <img
                    src={props?.propertyDetails?.AgentDtls?.ProfileImage}
                    alt="profile Image"
                    className="img-fluid"
                  />
                </li>
                <li>
                  {props?.propertyDetails?.AgentDtls?.FirstName +
                    " " +
                    props?.propertyDetails?.AgentDtls?.LastName}
                </li>
                <h3>Your Rosehip Agent</h3>
                
                <li>{props?.propertyDetails?.AgentDtls?.Phone}</li>
                <li>{props?.propertyDetails?.AgentDtls?.Email}</li>
              </ul>
              <p>Discription {AdDescription}</p>
              <ul>
                <h3>Features</h3>
                <li>Basement: {props?.propertyDetails?.ListBasement}</li>
                <li>Bed: {props?.propertyDetails?.ListBedRoom}</li>
                <li>Built: {props?.propertyDetails?.YearBuilt}</li>
                <li>Heat: {props?.propertyDetails?.ListHeat}</li>
                <li>Bath: {props?.propertyDetails?.ListBathRoom}</li>
                <li>Stories: {props?.propertyDetails?.ListStory}</li>
                <li>Style: {props?.propertyDetails?.ListStyle}</li>
                <li>Living Area: {props?.propertyDetails?.LivingArea}</li>
                <li>Lot Size: {props?.propertyDetails?.LotSize}</li>
              </ul>

              <div>
                <h3>AMENITIES</h3>
                <li>AC: {props?.propertyDetails?.ListAirCondition}</li>
                <li>Heat: {props?.propertyDetails?.ListHeat}</li>
                <li>Pool: {props?.propertyDetails?.ListPool}</li>
                <li></li>
              </div>

              
              <div >
                <ul>
                  {props?.propertyDetails?.Images && props?.propertyDetails?.Images.map((img)=>{
                  if(img.Featured === true){
                    return null
                  }else
                  return(
                    <li>
                    <img className="list-images"
                      style={{height:100 ,width:150}}
                      src={img.FileUrl}
                      className="imgfluid"
                      alt="other images"
                    />
                    </li>
                    )
                  }) 
                  }
                </ul>
              </div>
              
            </div>   
          </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};
export default EmailListing;
