import React, { useEffect, useState } from "react";
import FrontendHeader from "../Common/frontendHeader";
import Footer from "../Common/footer";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button, IconButton } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery, useMutation,useLazyQuery } from "@apollo/client";
import { LIST_PROPERTY, LIST_KEYWORDS, PROPERTIES_IN_TOWN, LIST_SAVED_PROPERTIES,FEATURED_PPTY_LIST } from "../../Graghql/queries";
import { LEAD_ACTIVITY_ADD, UNSAVE_PROPERTY,APPROVE_DISAPPROVE_PPTY } from "../../Graghql/mutations";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  authVar, userDeatils,
  toggleSnackbar,
} from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom"
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DialogBox} from "../Common"
import {
  homeAreaOptions,
  rentalPeriodOptions,
} from "../Properties/addProperty/options";
export default function Home(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {

      },
    },
    menuPaper: {
      maxHeight: 200,
    },
  }));
  const classes = useStyles();
  const propertyListVariables = {
    skip: 0,
    limit: 7,
    area: "",
    rentalPeriods: [],
    price: [],
    listBed: parseFloat(""),
    listBath: parseFloat(""),
  };

  const {
    data: propertyList,
  } = useQuery(LIST_PROPERTY, { variables: propertyListVariables });
  const {
    data: featuredPropertyList,
  } = useQuery(FEATURED_PPTY_LIST, { variables: {skip:0,limit:10 }});

  const { data: keywordList } = useQuery(LIST_KEYWORDS);
  const { data: townlist } = useQuery(PROPERTIES_IN_TOWN)
   //console.log("tl", propertyList?.propertyList?.data?.length)
  const [area, setArea] = useState("")
  const [period, setPeriod] = useState("")
  var homeSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    cssEase: 'linear',
    slidesToShow: featuredPropertyList?.featuredPropertiesList?.length>4?4:featuredPropertyList?.featuredPropertiesList?.length,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024, // iPad landscape orientation breakpoint
        settings: {
          slidesToShow: 3, // Number of slides to show for iPad landscape
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768, // iPad portrait orientation breakpoint
        settings: {
          slidesToShow: 3, // Number of slides to show for iPad portrait
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480, // Mobile portrait orientation breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

    // responsive: [{
    //   breakpoint: 1024,
    //   settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     arrows: false,
    //   }
    // }],
    // responsive: [{
    //   breakpoint: 768,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //   }
    // }],
    // // eslint-disable-next-line no-dupe-keys
    // responsive: [{
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //   }
    // }]

 
  // var homeSlider = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 800,
  //   cssEase: 'linear',
  //   slidesToShow: featuredPropertyList?.featuredPropertiesList?.length>4||propertyList?.propertyList?.length>4?4:featuredPropertyList?.featuredPropertiesList?.length||propertyList?.propertyList?.length,
  //   slidesToScroll: 1,
  //   autoplay: true,

  //   responsive: [{
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 1,
  //       arrows: false,
  //     }
  //   }],
  //   responsive: [{
  //     breakpoint: 480,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       arrows: false,
  //     }
  //   }]

  // };
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  const handleSelectArea = (e, value) => {
    //console.log("e", value)
    setArea(value)
  }
  const handleSelectPeriod = (e, value) => {
    //console.log(value)
    setPeriod(value)
  }
  function formatKeyword(keyword) {
    if (keywordList) {
      let position = keywordList?.listAllKeywords?.data.findIndex(
        (element) => element.Keyword === keyword
      );
      if (position !== -1) {
        let obj = keywordList?.listAllKeywords?.data[position]
        return {
          area: obj?.Area,
          price: obj?.PriceRange === "" ? null : obj?.PriceRange.replace(/ /g, ''),
          period: obj?.Period,
          bed: obj?.ListBedRoom,
          bath: obj?.ListBathRoom,
          ListWater: obj?.ListWater,
          ListPet: obj?.ListPet,
          ListPool: obj?.ListPool,
          ListTenni: obj?.ListTennis
        };
      }
    }
  }
  return (
    <main>
      <FrontendHeader message search list contact location={props?.location?.pathname} locState={props?.location?.state} />
      <div
        className="banner"
        style={{ backgroundImage: "url(../assets/images/banner.jpg)" }}
      >
        <div className="container">
          <div className="bannerContent text-center">
            <h1>
              FOR THE BEST OF<b>THE HAMPTONS</b>
            </h1>
            <label>
              Beachfront, Waterfront and other Exquisite Properties
              <br />
              just one click away
            </label>
            <div className="banner-search">
              {/* form */}
              <form className={classes.root} noValidate autoComplete="off">
                <div>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={homeAreaOptions?.sort(sortLabels).map((option) => option.value)}
                    onChange={handleSelectArea}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={area}

                        margin="normal"
                        variant="outlined"
                        placeholder="Where would you like to Stay?"
                        InputProps={{
                          ...params.InputProps, type: 'search', startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src="../assets/images/icon-location-banner.svg"
                                className="img-fluid"
                                alt="Location Icon"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={rentalPeriodOptions?.map((option) => option.RentalPeriod)}
                    value={period}
                    onChange={handleSelectPeriod}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        placeholder="Please select your time frame..."
                        InputProps={{
                          ...params.InputProps, type: 'search', startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src="../assets/images/icon-calendar-banner.svg"
                                className="img-fluid"
                                alt="Calendar Icon"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <Link style={{ marginTop: "15px" }} to={{ pathname: area ? `${area.toLowerCase().replace(/\s+/g, '-')}/page1` : "/searchlistings/page1", searchObj: { area: area, period: period } }} >
                  <img
                    src="../assets/images/icon-search-banner.svg"
                    className="img-fluid"
                    alt="Search Icon"
                  />
                  Search Rentals

                </Link>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div className="trendindLocationChip">
        <div className="container">
          <h6>Trending Now</h6>
          <ul>
            {keywordList?.listAllKeywords?.data?.map((item, index) =>
              <li><Link to={{ pathname: "/searchlistings/page1", searchObj: formatKeyword(item?.Keyword) }}>{item?.Keyword}</Link></li>)}
            {/* <li><Link to={{ pathname: "/propertyList", searchObj: formatKeyword("Poolside Picks") }}>Poolside Picks</Link></li>
            <li><Link to={{ pathname: "/propertyList", searchObj: formatKeyword("Beach Side Living") }}>Beach Side Living</Link></li>
            <li><Link to={{ pathname: "/propertyList", searchObj: formatKeyword("Rent in Mountak") }}>Rent in Mountak</Link></li> */}
          </ul>
        </div>
      </div>
      <div className="towns">
        <div className="container">
        <h3>Featured Properties</h3>
          <Slider className="homeSlider" {...homeSlider}>
          {featuredPropertyList?.featuredPropertiesList &&
              featuredPropertyList?.featuredPropertiesList.map((item, index) => (
                <PropertyShortTile item={item} index={index} feature/>
              ))}
            {/* {propertyList?.propertyList?.data &&
              propertyList?.propertyList?.data.map((item, index) => (
                <PropertyShortTile item={item} index={index} />
              ))} */}
          </Slider>
        </div>
      </div>
      {/* <div className="homePropetry"> */}
      <div className="towns">
        <div className="container">
        <h3>Recently Updated Properties</h3>
          <Slider className="homeSlider" {...homeSlider}>
          {/* {featuredPropertyList?.featuredPropertiesList &&
              featuredPropertyList?.featuredPropertiesList.map((item, index) => (
                <PropertyShortTile item={item} index={index} />
              ))} */}
            {propertyList?.propertyList?.data &&
              propertyList?.propertyList?.data.map((item, index) => (
                <PropertyShortTile item={item} index={index} />
              ))}
          </Slider>
        </div>
      </div>
      <div className="towns">
        <div className="container">
          <h3>Choose Area</h3>
          <div className="row">
            {townlist?.propertiesInTown.map((item, index) => (
              item?.town === "Barnes" || item?.town === "Ditch Plains" ? "" :
                <div className="col-md-4">
                  <Link to={{ pathname: item?.town ? `/${item?.town.toLowerCase().replace(/\s+/g, '-')}/page1` : "/searchlistings/page1", searchObj: { area: item?.town } }} >
                    <div className="towns-item">

                      <img
                        src={item?.townImgUrl}
                        className="img-fluid"
                        alt="Towns"
                      />
                      <div className="towns-item-text">


                        <h4>{item?.town}</h4>
                        <label>{item?.count + " "}Rentals</label>


                      </div>
                    </div>
                  </Link>
                </div>))}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

function PreviousArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-prev">
      <img
        alt="prev"
        onClick={onClick}
        className="slick-prev-img"
        src="../assets/images/back.svg"
      />
    </div>
  );
}
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-next">
      <img
        alt="next"
        onClick={onClick}
        className="slick-next-img"
        src="../assets/images/forward.svg"
      />
    </div>
  );
}


function PropertyShortTile(props) {
  const { item,  feature } = props;
  const auth = useReactiveVar(authVar);
  const history = useHistory()
  const user = useReactiveVar(userDeatils);
  const [openPopUpCheck,setOpenPopUpCheck] =useState(false)
  const [itemId,setItemId]=useState()
  const [approveDisapprove] =
  useMutation(APPROVE_DISAPPROVE_PPTY, {
   // refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
    // onCompleted: (res) => {
    //   refetch();
    // },
  });
  const [SavedProperties,{ data: listSavedProperty }] = useLazyQuery(LIST_SAVED_PROPERTIES, {
    // fetchPolicy: "network-only",
    variables: { userId: user?.data?._id },
  });
  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;
  const regOnlyArea = [
    "Wainscott North",
    "Wainscott South",
    "East Hampton Northwest",
    // "East Hampton Village",
    "East Hampton Village Fringe",
    "East Hampton South",
    "Montauk",
    "Amagansett Dunes",
    "Amagansett Lanes",
    "Amagansett North",
    "Amagansett South",
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };

  function getAmenityNames(data) {
    if (data) {
      let array = [];
      data.forEach((element) => {
        if (element.Flag === true) array.push(element.Title);
      });
      return array;
    }
  }
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
  });
  const handleSaveProperty = () => {
    try {
      savedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          Comment: "",
          PropertyIDs: [item?._id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: item?._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex(
        (element) => element.PropertyId === item?._id
      );
      if (position !== -1) return true;
      else return false;
    }
  }
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  useEffect(() => {
    if(user?.data?._id){
      SavedProperties()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user?.data?._id])
  const handleApproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function getAltTag(item){
    return `${item?.PID??''}-${item?.ListBathRoom??''}Bath-${item?.ListBedRoom??''}Bed-${item?.Area??''}`
  }
  return (
    <React.Fragment>
        <DialogBox
           negativeBtn={true}
            open={openPopUpCheck}
            setOpen={setOpenPopUpCheck}
            handlePositiveBtn={() => handleApproveCheck()}
            handleNegativeBtn={() => handleDisapproveCheck()}
            negativeText={"Decline"}
            positiveText={"Approve"}
            title={"Approve/Decline ?"}
            text={"Before editing,  you need to either Approve/Decline this Property with the changes made ?"}
          />
    <div style={{ cursor: !auth?.auth && regOnlyArea.includes(item?.Area) && !item?.RentalRegisteryId ? "default" : "" }} className="pl-item">
      {!auth?.auth && regOnlyArea.includes(item?.Area)&& !item?.RentalRegisteryId ? (
        <div className="not-registered-user">
          <p>
            Due to the recent rental registry law, you must be a registered user
            to access the details of this property.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              document.getElementById("frontend-header-login-btn").click()
            }
          >
            Register
          </Button>
        </div>
      ) : (
        <div className="pl-item-img">
           {auth?.auth && (user?.data?.Role === "owner"||user?.data?.Role === "renter" ) &&(
          <IconButton className="pl-fav" color="secondary"    onClick={() => {
                  isSaved() ? handleUnSaveProperty() : handleSaveProperty();
                }}>
                    {isSaved() ? (
                  <FavoriteIcon color="primary" />
                ) : (
                  <FavoriteBorderIcon />
                )}
            {/* <FavoriteBorderIcon /> */}
          </IconButton>
           )} 
           {( user?.data?.Role === "admin" ||user?.data?.Role === "agent" || user?.data?.Role === "agentAdmin")&& <IconButton
                              color="primary"
                                //onClick={(e) => { history.push(`/addProperty/${item?._id}`) }}
                                className="pl-fav"
                                onClick= {(e)=>{setItemId(item._id);((user?.data?.Role === "admin"||user?.data?.Role === "agentAdmin")&&item?.PendingFlag===true)
                                ? setOpenPopUpCheck(true):history.push({
                                  pathname: `/addProperty/${item?._id}`,
                                  state: { title: 'featuredPptyHomeEdit' }
                                })}}
                              >
                                <EditIcon color="grey" />
                              </IconButton>}
          {/* {item.Images&&item.Images.length > 0 ? ( */}
              <Link
              to={
                !auth?.auth && regOnlyArea.includes(item?.Area)&&!item?.RentalRegisteryId
                  ? null
                  : `/${item.PropertySlug}`
              }
            // target={"_blank"}
            >
            <Slider {...settings}>
              {/* {item.Images.map((imgItem) => {
                if (!imgItem.FileUrl) return null;
                return ( */}
               <img
                    // src={getFeaturedImage(item?.Images)}
                    src={feature?item?.featuredImage===""?"../assets/images/no-image.jpg":item?.featuredImage:item?.Images&&(getFeaturedImage(item?.Images))}
                    className="img-fluid"
                    alt={getAltTag(item)}
                  />
               
                 {/* );
               })} */}
            </Slider></Link>
          {/* // ) : (
          //   <Link
          //   to={
          //     !auth?.auth && regOnlyArea.includes(item?.Area)
          //       ? null
          //       : `/${item.PropertySlug}`
          //   }
          //   // target={"_blank"}
          // >
          //   <img
          //     src={"../assets/images/no-image.jpg"}
          //     className="img-fluid"
          //     alt="Propety Image"
          //   /></Link>
          // )} */}
        </div>
      )}
      <div className="pl-item-details">
        <Link
          to={
            !auth?.auth && regOnlyArea.includes(item?.Area) &&!item?.RentalRegisteryId
              ? null
              : `/${item.PropertySlug}`
          }
        // target={"_blank"}
        >
          <h3 style={{cursor: !auth?.auth && regOnlyArea.includes(item?.Area) &&!item?.RentalRegisteryId ?"default":""}}>
            {item?.AdTitle&&item?.AdTitle}
            {/* {item?.AdTitle&&item?.AdTitle.charAt(0).toUpperCase() +
              item?.AdTitle.slice(1).toLowerCase()} */}
          </h3>
        </Link>
        <div class="lp-detail">
          <label>
            <img
              src="../assets/images/icon-location-outlined.svg"
              class="img-fluid"
              alt="Icon"
            />
            {item?.Area}
          </label>
          <div class="lp-rooms d-flex align-items-center">
            {item?.LivingArea && (
              <label>
                <img
                  src="../assets/images/icon-sqft.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.LivingArea}
              </label>
            )}
            {item?.ListBedRoom && (
              <label>
                <img
                  src="../assets/images/icon-bed.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBedRoom}
              </label>
            )}
            {item?.ListBathRoom && (
              <label>
                <img
                  src="../assets/images/icon-bath.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBathRoom}
              </label>
            )}
          </div>
        </div>
      </div>
      {item.ListMainAmenity &&
        item.ListMainAmenity.find((element) => element.Flag === true) && (
          <div className="pl-item-details pp-availability">
            <ul className="al-style propertyStyleTag">
              {item.ListMainAmenity &&
                getAmenityNames(item.ListMainAmenity).map((name, index) => {
                  if (index === 2){
                    return (
                      <li>{`+${getAmenityNames(item.ListMainAmenity)?.length - 2
                        }`}</li>
                    );}
                  else if (index < 2) {return <li>{name}</li>}
                  return null
                })}
            </ul>
          </div>
        )}
    </div>
    </React.Fragment>
  );
}
