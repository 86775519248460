import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import EditIcon from '@material-ui/icons/Edit';
import { LEAD_ACTIVITY_LIST, LEADS_DETAIL } from "../../../Graghql/queries";
import { LEAD_ACTIVITY_ADD,APPROVE_DISAPPROVE_PPTY } from "../../../Graghql/mutations";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Loader from "../../Common/Loader"
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment, TablePagination } from "@material-ui/core";
import { userDeatils } from "../../../RactiveVariables/index";
import {DialogBox} from "../../Common"
function PropertyLeadActivity(params) {
  const history= useHistory();
  const [propertyId, setPropertyId] = useState([]);
  const [writeComment, setWriteComment] = useState("");
  const [moreComments, setMoreComments] = useState(false);
  const [openPopUpCheck,setOpenPopUpCheck] =useState(false)
  const [itemId,setItemId]=useState()
  const { item, index, leadComment, leadDetails, id } = params;
  // console.log('item',item)
  const user = useReactiveVar(userDeatils);
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  const [approveDisapprove] =
  useMutation(APPROVE_DISAPPROVE_PPTY, {
   // refetchQueries: [{ query: PENDING_PROPERTIES_COUNT }],
    // onCompleted: (res) => {
    //   refetch();
    // },
  });
  // eslint-disable-next-line no-unused-vars
  const regOnlyArea = [
    "Wainscott North",
    "Wainscott South",
    "East Hampton Northwest",
    "East Hampton Village",
    "East Hampton Village Fringe",
    "East Hampton South",
    "Montauk",
    "Amagansett Dunes",
    "Amagansett Lanes",
    "Amagansett North",
    "Amagansett South",
  ];
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  const handleWriteComment = () => {
    if(writeComment.length>0){
    try {
      leadComment({
        variables: {
          UserId: leadDetails.leadsDetails.UserId,
          LeadSrc: "LeadPage",
          LeadType: "PropertyComment",
          RootId: id,
          Comment: writeComment,
          PropertyIDs: propertyId,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Comment added",
            variant: "success",
          });
          setWriteComment("");
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }}else{
      toggleSnackbar({
        message: "Add a note",
        status: true,
        variant: "error",
      });
    }
  };
  const handleApproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleDisapproveCheck = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: itemId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
          history.push(`/addProperty/${itemId}`);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  return (
    <div className="lead-activity-item-wrap" key={index}>
          <DialogBox
           negativeBtn={true}
            open={openPopUpCheck}
            setOpen={setOpenPopUpCheck}
            handlePositiveBtn={() => handleApproveCheck()}
            handleNegativeBtn={() => handleDisapproveCheck()}
            negativeText={"Decline"}
            positiveText={"Approve"}
            title={"Approve/Decline ?"}
            text={"Before editing,  you need to either Approve/Decline this Property with the changes made ?"}
          />
      <div className="lead-activity-item">
        <div className="lead-property">
          <div className="lp-img">
          {/* <Link to={
                     `/${item?.PropertyDtls?.PropertySlug}`
                  }
                   target={"_blank"} 
                   > */}
            {/* {item?.ImagesLead.length>0 ? item?.ImagesLead?.map(
              (item) => 
                 ( */}
                   <Link to={{pathname:"/properties",state:{keywordValue:item?.PropertyDtls?.PID}}} >
                  <img
                  // style={{cursor:"pointer"}} onClick={() => history.push(`/${item?.PropertyDtls?.PropertySlug}`)}
                  //style={{cursor:"pointer"}} onClick={() => {setKeyWordSearch(item?.PropertyDtls?.PID);history.push("/properties");}}
                    
                  src={getFeaturedImage(item?.ImagesLead)}
                    className="img-fluid"
                    alt="Property_img"
                  ></img>
                  </Link>
                {/* )
                ):
            <img
                    src={"../assets/images/no-image.jpg" }
                    className="img-fluid"
                    alt="Property Image"
                  ></img>
            } */}
            {/* </Link> */}
            <IconButton
              onClick= {(e)=>{setItemId(item.PropertyId);((user?.data?.Role === "admin"||user?.data?.Role === "agentAdmin")&&item?.PropertyDtls?.PendingFlag===true)
              ? setOpenPopUpCheck(true):history.push({
                pathname: `/addProperty/${item.PropertyId}`,
                state: { title: 'portfolioEdit' }
              })}}
              className="lead-property-edit p-1"
              
            >
              <EditIcon color="primary"/>
            </IconButton>
          </div>

          <div className="lp-detail">
          <h4>PID#{item?.PropertyDtls?.PID}</h4>
            {/* <h3 style={{cursor:"pointer"}} onClick={() => history.push(`/${item?.PropertyDtls?.PropertySlug}`)}> */}
            <Link to={{pathname:"/properties",state:{keywordValue:item?.PropertyDtls?.PID}}} >
            <h3>
              {item?.PropertyDtls?.AdTitle}
              {/* {item?.PropertyDtls?.AdTitle?.charAt(0).toUpperCase() +
                item?.PropertyDtls?.AdTitle?.slice(1).toLowerCase()} */}
            </h3>
            </Link>
            {item?.PropertyDtls?.Area && (
              <label>
                <img
                  src="../assets/images/icon-location-outlined.svg"
                  className="img-fluid"
                  alt="Icon"
                ></img>
                {item?.PropertyDtls?.Area}
              </label>
            )}
            <div className="lp-rooms d-flex align-items-center">
              {item?.PropertyDtls?.LivingArea && (
                <label>
                  <img
                    src="../assets/images/icon-sqft.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.LivingArea} sq
                </label>
              )}
              {item?.PropertyDtls?.ListBedRoom && (
                <label>
                  <img
                    src="../assets/images/icon-bed.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.ListBedRoom}
                </label>
              )}
              {item?.PropertyDtls?.ListBathRoom && (
                <label>
                  <img
                    src="../assets/images/icon-bath.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.ListBathRoom}
                </label>
              )}
            </div>
          </div>
        </div>
        {item?.PriceDataLead?.PriceDetails && (
          <div className="lp-price-month">
            <ul>
              {item?.PriceDataLead?.PriceDetails?.map((item) => (
                <>
                  {(item.AvblStatus === "yes" ||
                    item.AvblStatus === "rented") && (
                    <li>
                      <label>
                        {item.AvblStatus === "yes" && (
                          <img
                            src="../assets/images/green-check.svg"
                            className="img-fluid"
                            alt="Tick"
                          />
                        )}{" "}
                        {item.AvblStatus === "rented" && (
                          <img
                            src="../assets/images/red-check.svg"
                            className="img-fluid"
                            alt="Tick"
                          />
                        )}
                        {item.RentalPeriod}
                      </label>
                      ${formatePrice(item.Price)}
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        )}
        <div className="lp-commets">
          <div className="lp-comment-box">
            <TextField
              variant="outlined"
              className="formTextBox"
              placeholder="Write comment"
              value={writeComment}
              onKeyPress={(e) => e.key === "Enter" && handleWriteComment()}
              onChange={(e) => {
                setWriteComment(e.target.value);
                setPropertyId([item?.PropertyId]);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img
                        src="../assets/images/icon-send.svg"
                        className="social-icon img-fluid"
                        alt="logo"
                        onClick={() => {
                          handleWriteComment();
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="lp-comment-list">
            {item?.PropertyLeadCmts?.map((items, index) => {
              //console.log("itm",items)
              return(
              items?.LeadActivityComment?.map((item,index)=>{
              if (!moreComments && index > 1) return null;
              return (
                <div className="lp-comment-list-item">
                  <Avatar style={{ backgroundColor: "#0C4946" }} 
                  //  src={item?.AddedByDtls?.ProfileImage}
                   >
                    {item?.CreatedByName?.FirstName?.charAt(0).toUpperCase()+item?.CreatedByName?.LastName?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography paragraph>
                    <TexttView text={item.Comment} />
                  </Typography>
                </div>
              );
               } )
              )
            })}

            {item?.PropertyLeadCmts?.length > 2 && (
              <label style={{cursor:'pointer'}}
                onClick={() => setMoreComments(!moreComments)}
                className="view-comment"
              >
                {moreComments ? "View less comments" : "View more comments"}
              </label>
            )}
          </div>
        </div>
      </div>
      <address>
        <b>
          {item?.AddedByDtls?.FirstName&&item?.AddedByDtls?.LastName&&item?.AddedByDtls?.FirstName.charAt(0).toUpperCase() +
            item?.AddedByDtls?.FirstName.slice(1).toLowerCase() +
            " " +
            item?.AddedByDtls?.LastName.charAt(0).toUpperCase() +
            item?.AddedByDtls?.LastName.slice(1).toLowerCase()}
        </b>
        &nbsp;<font>{item?.LeadType==="View"?"Viewed":item?.LeadType==="Save"?"Saved":
        item?.LeadType==="Enquiry"?"Inquired":item?.LeadType==="Suggest"?"Suggested":
        item?.LeadType==="Registration"&&item?.LeadSrc==="Owner Registration"?"Registered as Owner":
        item?.LeadType==="Registration"&&item?.LeadSrc==="Renter Registration"?"Registered as Renter":
        item?.LeadType==="Property"&&item?.LeadSrc==="Property Registration"?"Created":""}</font>&nbsp;on{" "}
        {moment(new Date(Number(item.CreatedOn))).format("MMM DD YYYY hh:mm A")}
      </address>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 125) return text.slice(0, 125) + " ...";
      else return text.slice(0, 125);
    } else return text;
  }
  if (!props.text) return null;
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 125 && (
        <label
          style={{ color: "#000", fontFamily: "Helvetica-Bold", }}
          onClick={() => setVisible(!visible)}
        >
          {visible ? " See Less" : " See More"}
        </label>
      )}
    </div>
  );
}
const LeadDetail = (props) => {
  const { id } = useParams();
  const [postComment, setPostComment] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const leadActivityListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    leadId: id,
    tabType: "",
  };
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    fetchMore({
      variables: leadActivityListVariables,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [leadComment] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["leadActivityList"],
  });

  const {
    data: leadDetails,
    // loading
  } = useQuery(LEADS_DETAIL, {
    variables: { leadId: id },
  });
  let uType=leadDetails?.leadsDetails?.UserType
  //console.log(".........",uType );
  const {
    data: leadActivity,
    loading,
    fetchMore,
  } = useQuery(LEAD_ACTIVITY_LIST, {
    fetchPolicy: "network-only",
    variables: leadActivityListVariables,
  });
  const leadDetail =
    leadActivity &&
    leadActivity.leadActivityList &&
    leadActivity.leadActivityList.data;
  const leadDetailCount =
    leadActivity &&
    leadActivity.leadActivityList &&
    leadActivity.leadActivityList.count;
  //console.log("leadActivity-----", leadDetail, leadDetailCount);
  //console.log("leadDetail.UserId-----", userId);
  const handlePostComment = () => {
    if(postComment.length>0){
    try {
      leadComment({
        variables: {
          UserId: leadDetails.leadsDetails.UserId,
          LeadSrc: "LeadPage",
          LeadType: "Comment",
          RootId: id,
          Comment: postComment,
          //PropertyId: "",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Comment added",
            variant: "success",
          });
          setPostComment("");
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }}else{
      toggleSnackbar({
        message: "Add a note",
        status: true,
        variant: "error",
      });
    }
  };
  return (
    <div className="col-md-8">
      <Loader open={loading}/>
  
      <div className="lead-comment-post-box">
        <div className="d-flex">
          <Avatar src={leadDetails?.leadsDetails?.UserDtls?.ProfileImage}>
            {leadDetails?.leadsDetails?.UserDtls?.FirstName.charAt(0).toUpperCase()+
            leadDetails?.leadsDetails?.UserDtls?.LastName.charAt(0).toUpperCase()}
          </Avatar>
          {uType==="renter"?(<TextField
            variant="outlined"
            className="formTextBox"
            placeholder="Connect with renter"
            value={postComment}
            onKeyPress={(e) => e.key === "Enter" && handlePostComment()}
            onChange={(e) => {
              setPostComment(e.target.value);
            }}
          />):(
            <TextField
            variant="outlined"
            className="formTextBox"
            placeholder="Connect with owner"
            value={postComment}
            onKeyPress={(e) => e.key === "Enter" && handlePostComment()}
            onChange={(e) => {
              setPostComment(e.target.value);
            }}
          />
          )}
        </div>
         {postComment&& <div className="text-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePostComment()}
          >
            Post comment
          </Button>
        </div>}
      </div>
      {leadDetail?.map((item, index) =>
        item.PropertyId ? (
          <PropertyLeadActivity
            item={item}
            index={index}
            leadComment={leadComment}
            leadDetails={leadDetails}
            id={id}
          />
        ) : (
          <div className="lead-activity-item-wrap">
            <div className="lead-activity-item">
              <div className="lp-comment-list">
                <div
                  className="lp-comment-list-item p-3"
                  style={{ alignItems: "flex-start", display: "flex" }}
                >
                  <Avatar style={{ backgroundColor: "#0C4946" }}  src={item?.AddedByDtls?.ProfileImage}>
                    {item?.AddedByDtls?.FirstName.charAt(0).toUpperCase()+item?.AddedByDtls?.LastName.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography paragraph className="ms-2 mb-0">
                    <TexttView text={item.Comment} />
                    <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center me-2">
                          <img
                            src="../assets/images/icon-calendar.svg"
                            className="me-1 img-fluid"
                            alt="Calendar Icon"
                          />
                          {moment(new Date(parseInt(item.CreatedOn))).format(
                            "ll"
                          )}
                        </span>
                        <span className="d-flex align-items-center">
                          <img
                            src="../assets/images/icon-clock.svg"
                            className="me-1 img-fluid"
                            alt="Clock Icon"
                          />
                          {moment(new Date(parseInt(item.CreatedOn))).format(
                            "LT"
                          )}
                        </span>
                      </div>
                    {/* {item.Comment} */}
                  </Typography>
                </div>

                {/* {leadDetail && leadDetail.length > 3 && (
                  <a
                    onClick={() => setMoreComments(!moreComments)}
                    className="view-comment"
                  >
                    {moreComments ? "View less comments" : "View more comments"}
                  </a>
                )} */}
              </div>
            </div>
          </div>
        )
      )}
      {!loading && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={leadDetailCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

export default LeadDetail;
