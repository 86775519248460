import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import {  useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import { yupResolver } from "@hookform/resolvers/yup";
import {Link} from "react-router-dom"
import * as yup from "yup";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import { ADD_FRONTEND_USER } from "../../Graghql/mutations"
import { toggleSnackbar ,authVar, userDeatils} from '../../RactiveVariables/index';
import logo from  '../../assets/images/logo.svg'

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  //.matches(/^[a-zA-Z]*$/, 'Must be characters'),
  lastName: yup.string().required("Last Name is required"),
  //.matches(/^[a-zA-Z]+$/, 'Must be characters'),
  email: yup.string().required("Email is required").email(),
  phone: yup.string().required("Phone is required").matches(/^\d{3}-\d{3}-\d{4}$/, 'Must be 10 digits'),
  password: yup.string().required("Password is required").matches(/^(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*_]{8,20}$/, 'Password must be 8 to 20 characters long and should contain at least one uppercase(A-Z),one lowercase(a-z), one number and one special character(!,@,#,$,%,^,&,*,_)'),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password and confirm password do not match"),
  role: yup.string().required("Owner/Renter is required"),
});
const Register = (props) => {
  // const history = useHistory()
  const [phone, setPhone] = useState("");
  const [checked, setChecked] = useState(false);

  const { register, handleSubmit, control, formState: { errors } } = useForm({mode: "onChange",defaultValues:{"role":"renter"}, resolver: yupResolver(schema), });
  const [addFrontEndUser, { loading }] = useMutation(ADD_FRONTEND_USER);


  const phoneNumberFormat = (num) => {
    // let newNum = num.replace(/[-]+/g, "");
    let newNum = num.replace(/[^0-9]/g, '');
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
    } else {
      x =
        newNum.slice(0, 3) +
        "-" +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  }


  const onSubmit = (data) => {
    //console.log("dataaaa", data)
    if (checked) {
      try {

        addFrontEndUser({ variables: data })
          .then((res) => {
            //console.log("resss", res)
            toggleSnackbar({
              status: true,
              message: "You have been successfully registered",
              variant: 'success'
            });
            // props.setLogin(true)
            props.setOpen(false);
            const responseData = res.data && res.data.addFrontEndUser;
            const token = responseData.token;
            localStorage.setItem("@authToken", token);
            authVar({
              loaded: true,
              auth: true,
              userType:
                res?.data?.addFrontEndUser?.userNew?.UserType === "HR"
                  ? "admin"
                  : res?.data?.addFrontEndUser?.userNew?.UserType,
            });
            userDeatils({data:res?.data?.addFrontEndUser?.userNew})
         
            // const responseData = res.data && res.data.login;
            //const token = responseData.token;
            //localStorage.setItem('@authToken', token);
            // authVar({
            //   loaded: true,
            //   auth: true,
            // });
          })
          .catch((err) => {
           // console.log("errrrrr", err)
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: 'error'
            });
          });
      } catch (err) {
        //console.log("err", err)
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: 'error'
        });
      }
    } else {
      toggleSnackbar({
        status: true,
        message: "Please agree to Terms of Use",
        variant: 'error'
      });
    }
  };
  const phoneNumberRegister = register("phone", { required: true });
  // eslint-disable-next-line no-unused-vars
  const radioValidation = register("role", { required: true });
  return (
    <div className="login-register-wrap">
      <div className="login-register register">
        <img src={logo} className="login-logo img-fluid d-block m-auto" alt="logo" />
        <div className="login-register-tab">
          <Link to="#" onClick={() => props.setLogin(true)}>Login</Link>
          <Link to="#" onClick={() => props.setLogin(false)} className="active">Register</Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="register-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">First Name <sup>*</sup></label>
                  <input type="text" className="form-control" autoFocus {...register('firstName', { required: true })} />
                  {errors.firstName && <span className="error-msg">{errors.firstName.message}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Last Name <sup>*</sup></label>
                  <input type="text" className="form-control" {...register('lastName', { required: true })} />
                  {errors.lastName && <span className="error-msg">{errors.lastName.message}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Email<sup>*</sup></label>
                  <input type="email" className="form-control" {...register('email', { required: true })} />
                  {errors.email && <span className="error-msg">{errors.email.message}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Phone<sup>*</sup></label>
                  <input type="text" className="form-control"
                    value={phone}
                    {...phoneNumberRegister}
                    onChange={(e) => {
                      if(e.target.value.length <= 12){
                        phoneNumberRegister.onChange(e);
                        setPhone(phoneNumberFormat(e.target.value));
                      }
                      
                    }}
                  // onChange={(e) => {
                  //   if (e.target.value != "") {
                  //     setPhoneErr(false)
                  //   } else {
                  //     setPhoneErr(true)
                  //   }
                  //   setPhone(phoneNumberFormat(e.target.value))
                  // }}

                  />
                  {errors.phone && <span className="error-msg">{errors.phone.message}</span>}
                  {/* {phoneErr && <span className="error-msg">Phone is required</span>} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="" className="form-label">Password <sup>*</sup></label>
                  <input type="password" className="form-control" id="" {...register('password', { required: true })} />
                  {errors.password && <span className="error-msg">{errors.password.message}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label for="" className="form-label">Confirm Password <sup>*</sup></label>
                  <input type="password" className="form-control" id="" {...register('confirmPassword', { required: true })} />
                  {errors.confirmPassword && <span className="error-msg">{errors.confirmPassword.message}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Controller 
                    control={control}
                    name="role"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup className="register-type"
                        value={value}
                        onChange={( e ) => onChange(e.target.value)}
                        //onChange={(e) =>  e.target.value}
                      
                      //     {...radioValidation}
                      //     onChange={(e) => {
                      //       radioValidation.onChange(e);
                      //       setValue(e.target.value);
                      //  }}
                      //  {...register('role', { required: true })}

                      >
                           <FormControlLabel
                          value="renter"
                          control={<Radio color="primary" />}
                          label="Renter"
                        />
                        <FormControlLabel
                          value="owner"
                          control={<Radio color="primary" />}
                          label="Owner"
                        />
                     

                      </RadioGroup>

                    )}
                  />
                  {errors.role&&<span className="error-msg">{errors.role.message}</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" onChange={(e) => setChecked(e.target.checked)} value={checked} id="flexCheckDefault" />
                  <label className="form-check-label" for="flexCheckDefault">
                    I agree to Hamptons Rentals <Link to="/termsOfUse" >Terms of Use</Link>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button disabled={loading} className="login-sumbit-btn" type="submit" color="primary"
            // onClick={() => {
            //   if (phoneErr == null) {
            //     setPhoneErr(true)
            //   }
            // }}
            >
              {loading ? <CircularProgress color="inherit"/> : 'Register'}
            </Button>
          </div>
        </form>
      </div>
    </div >
  );
};



export default Register;
