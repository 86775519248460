import React from "react";
import { DialogBox } from "../Common";
import { useMutation } from "@apollo/client";
import { ADD_INQUIRE } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables";

const AssignToLead = (props) => {
  const [addInquire] = useMutation(ADD_INQUIRE, {
    refetchQueries: ["listContact"],
  });

  const handleAssigned = () => {
    try {
      let messageArray = [];
      props &&
        props.message &&
        props.message.forEach &&
        props.message.forEach((element) => {
          let msg = element.Message;
          messageArray.push(msg);
        });
      // Ensure that addInquire is assigned to a variable or used in a meaningful way
      const inquirePromise = addInquire({
        variables: {
          LeadSource: "Contact",
          FirstName: props?.firstName,
          LastName: props?.lastName,
          Phone: props?.phone,
          Email: props?.email,
          Comments: messageArray[0],
        },
      });

      // Handle the result of addInquire promise
      inquirePromise
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  /* const handleAssigned=()=>{
    // console.log("assigneditem",item)
    try{
        let messageArray = [];
        props?.message?.forEach((element) => {
          let msg = element.Message;
          messageArray.push(msg);
        });
        addInquire({
        variables: {
            LeadSource: "Contact",
            FirstName:props?.firstName ,
            LastName:props?.lastName ,
            Phone:props?.phone ,
            Email: props?.email,
            Comments:messageArray[0] ,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
}*/

  return (
    <div>
      <DialogBox
        open={props.open}
        setOpen={props.setOpen}
        handlePositiveBtn={handleAssigned}
        title={"Assign To Lead?"}
        text={"Are you sure you want to Assign To Lead?"}
      />
    </div>
  );
};

export default AssignToLead;
