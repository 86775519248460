import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  InputLabel,
  Button,
  CircularProgress,
} from "@material-ui/core";
import AddImageIcon from "../../assets/AddImageIcon.svg";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CropImage from "../Common/CropImage";
import CropIcon from "@material-ui/icons/Crop";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { EDIT_PROFILE } from "../../Graghql/mutations";
import { IMAGE_UPLOAD } from "../../Graghql/queries";

const EditProfile = (props) => {
  const user = useReactiveVar(userDeatils);
  //console.log("user,,,,,", user);
  const [cellPh, setCellPh] = useState("");
  const [officePh, setOfficePh] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [, setUploadImageLoading] = useState(false);
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("First Name is required"),
    // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    LastName: yup
      .string()
      .required("Last Name is required"),
    // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    Email: yup.string().required("Email is required").email(),
    CellPhone: yup
      .string()
      .required("Cell Phone is required")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be numbers").nullable(),
    OfficePhone: yup
      .string().nullable()
      .test("Office-phone", "Must be numbers", (value) => {
        if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
        else return true;
      }),
    //  AddressLine1: yup.string().required("Address1 is required"),
    //  State: yup.string().required("State is required"),
    //  City: yup.string().required("City is required"),
  });
  const {
    register,
    handleSubmit,
    formState,
    setValue,
  } = useForm({
    //defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const uplaodImageVariables = {
    originalFilename: fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    // fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  const [editProfile, { loading }] = useMutation(EDIT_PROFILE);

  useEffect(() => {
    setValue("CellPhone", user?.data?.Phone)
    setValue("OfficePhone", user?.data?.OfficePhone)
    // setValue("AddressLine1", user?.data?.AddressLine1)
    // setValue("AddressLine2", user?.data?.AddressLine2)
    setCellPh(user?.data?.Phone);
    setOfficePh(user?.data?.OfficePhone);
    setSelectedFile(user?.data?.ProfileImage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (params) => {
    //console.log("pa>>>>>>",params)
    
    params["_id"] = user?.data?._id;
    params["ProfileImage"] = uploadImageData?.imageUpload?.publicUrl;
    try {
      editProfile({ variables: params })
        .then((res) => {
          console.log("ressuser", res);
          res.data.editProfile.LeadList=user?.data?.LeadList
          userDeatils({
            data: res.data.editProfile,
          });
          toggleSnackbar({
            status: true,
            message: "Profile successfully updated",
            variant: "success",
          });
          //setCellPh(user?.data?.CellPhone);
          props.setOpenEditProfile(false);
          setImageFile(null);
          setFileName("");
          setSelectedFile(null);
          setCropImage(null);
          //   props.handlePagination();
          //   props.setOpen(false);
          //   setCellPh("");
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function handelCropDone(result) {
    setCropImage(result);
  }
  function handleUploadClick(event) {
    var file = event.target.files[0];
    if (!(file?.type === "image/jpeg" || file?.type === "image/png")) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    }
    if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    setFileName(file.name);
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };

    imageCompression(file, options)
      .then((compressedFile) => {
        setSelectedFile(URL.createObjectURL(compressedFile));
        setImageFile(compressedFile);
        upload();
      })
      .catch((err) => {
        //console.log("err: ", err);
      });
  }
  async function uploadImage(params) {
    setUploadImageLoading(true);
    if (fileName !== "" && imageFile !== null) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      //console.log(",,,,,,,",url)
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        url,
        cropImage ? cropImage.file : imageFile,
        {
          headers: {
            "Content-Type": imageFile?.type,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
          },
        }
      );
    }
    setUploadImageLoading(false);
    onSubmit(params);
  }
  function handleDelete(params) {
    setSelectedFile(null);
    setFileName("");
    setImageFile(null);
    setCropImage(null);
    //  if (props?.defaultValue?._id)
    //    deleteProfileImage({
    //      variables: { agentId: props?.defaultValue?._id },
    //    });
  }
  function phoneNumberFormat(num) {
    if (num) {
       //let newNum = num.replace(/[-]+/g, "");
       let newNum = num.replace(/[^0-9]/g, '');
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
      } else {
        x =
          newNum.slice(0, 3) +
          "-" +
          newNum.slice(3, 6) +
          "-" +
          newNum.slice(6, 10);
      }
      return x;
    }
  }
  console.log("[[[[[[",user?.data)
  return (
    <div className="edit-profile">
      <div>
        <form onSubmit={handleSubmit(uploadImage)} noValidate>
          <div>
            <h3>Edit Profile</h3>
          </div>
          <div className="edit-inner-wrap">
            <div className="add-photo">
              <div className="add-photo-img">
                <CropImage
                  open={openCrop}
                  setOpen={setOpenCrop}
                  image={imageFile}
                  handelCropDone={handelCropDone}
                />
                <img
                alt="profile"
                  src={
                    selectedFile
                      ? cropImage
                        ? cropImage.fileUrl
                        : selectedFile
                      : AddImageIcon
                  }
                />
                {imageFile && (
                  <IconButton
                    color=""
                    aria-label="drag"
                    component="span"
                    className=""
                    onClick={() => {
                      setOpenCrop(true);
                    }}
                  >
                    <CropIcon />
                  </IconButton>
                )}
                {selectedFile && (
                  <IconButton
                    aria-label="drag"
                    component="span"
                    className="add-photo-delete"
                    onClick={() => handleDelete()}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
              <Button
                className="outline-btn"
                component="label"
                variant="outlined"
              >
                Add Photo
                <input
                  type="file"
                  hidden
                  onChange={handleUploadClick}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Button>
            </div>
            <div className="container">
            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">
                  FIRST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.FirstName}
                  variant="outlined"
                  {...register("FirstName")}
                  error={errors.FirstName}
                  className={"formTextBox"}
                  autoFocus
                />
                {errors && errors?.["FirstName"] && (
                  <p className="errorMessage">{errors?.FirstName?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  LAST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.LastName}
                  variant="outlined"
                  {...register("LastName")}
                  error={errors.LastName}
                  className={"formTextBox"}
                />
                {errors && errors?.["LastName"] && (
                  <p className="errorMessage">{errors?.LastName?.message}</p>
                )}
              </div>
              <div className="col-md-12">
                <InputLabel className="form-label">
                  EMAIL<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.Email}
                  {...register("Email")}
                  variant="outlined"
                  // onChange={(e) => setValue("designation", e.target.value)}
                  error={errors.Email}
                  className={"formTextBox"}
                />
                {errors && errors?.["Email"] && (
                  <p className="errorMessage">{errors?.Email?.message}</p>
                )}
              </div>
              {/* <div className="col-12">
              <span className="instruction-text">
                Atleast one phone number is required.
              </span>
            </div> */}
              <div className="col-md-6">
                <InputLabel className="form-label">
                  CELL PHONE<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.Phone}
                  {...register("CellPhone")}
                  variant="outlined"
                  value={cellPh}
                  onChange={(e) => setCellPh(phoneNumberFormat(e.target.value))}
                  error={errors.CellPhone}
                  className={"formTextBox"}
                />
                {errors && errors?.["CellPhone"] && (
                  <p className="errorMessage">{errors?.CellPhone?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  OFFICE PHONE
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.OfficePhone}
                  {...register("OfficePhone")}
                  variant="outlined"
                  value={officePh}
                  onChange={(e) => setOfficePh(phoneNumberFormat(e.target.value))}
                  error={errors.OfficePhone}
                  className={"formTextBox"}
                />
                {errors && errors?.["OfficePhone"] && (
                  <p className="errorMessage">{errors?.OfficePhone?.message}</p>
                )}
              </div>
              {/* <h5 className="add-sub-head">Address</h5> */}
              <div className="col-md-6">
                <InputLabel className="form-label">
                  ADDRESS 1<sup></sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.AddressLine1}
                  {...register("AddressLine1")}
                  variant="outlined"
                  error={errors.AddressLine1}
                  className={"formTextBox"}
                />
                {errors && errors?.["AddressLine1"] && (
                  <p className="errorMessage">{errors?.AddressLine1?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  ADDRESS 2
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.AddressLine2}
                  {...register("AddressLine2")}
                  variant="outlined"
                  error={errors.AddressLine2}
                  className={"formTextBox"}
                />
                {errors && errors?.["AddressLine2"] && (
                  <p className="errorMessage">{errors?.AddressLine2?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  CITY<sup></sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.City}
                  {...register("City")}
                  variant="outlined"
                  error={errors.City}
                  className={"formTextBox"}
                />
                {errors && errors?.["City"] && (
                  <p className="errorMessage">{errors?.City?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  STATE<sup></sup>
                </InputLabel>
                <TextField
                  defaultValue={user?.data?.State}
                  {...register("State")}
                  variant="outlined"
                  error={errors.State}
                  className={"formTextBox"}
                />
                {errors && errors?.["State"] && (
                  <p className="errorMessage">{errors?.State?.message}</p>
                )}
              </div>
            </div>
            </div>
          </div>
          <div className="edit-profile-btn d-flex align-items-center justify-content-between">
            <Button
              className="outline-btn"
              onClick={() => {
                props.setOpenEditProfile(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="highlight-btn">
              {loading ? (
                <CircularProgress size="20px" color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditProfile;  
