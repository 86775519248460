import React, { useState } from "react";
import { FRONTEND_TEAM_DETAIL, LIST_AGENT_PROPERTIES, LIST_SAVED_PROPERTIES } from "../../Graghql/queries";
import FrontendHeader from "../Common/frontendHeader";
import { useQuery, useMutation } from "@apollo/client";
import { DialogBox } from "../Common";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  LEAD_ACTIVITY_ADD,
  UNSAVE_PROPERTY,
} from "../../Graghql/mutations"
import {
  IconButton,
} from "@material-ui/core";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { authVar, userDeatils } from "../../RactiveVariables";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import Loader from "../Common/Loader"
const AgentDetails = () => {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [isPaginating, setIsPaginating] = useState(false);
  const { data } = useQuery(FRONTEND_TEAM_DETAIL, {
    variables: { userId: id },
  });
  const agentPropertiesVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    userId: id,
  };

  const handlePagenation = () => {
    fetchMore({
      variables: agentPropertiesVariables,
    });
  };
  React.useEffect(() => {
    handlePagenation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };
  console.log("data", data)
  const { data: agentProperties, loading: agentPropertiesLoading, fetchMore } = useQuery(LIST_AGENT_PROPERTIES, {
    variables: agentPropertiesVariables,
  });
  console.log("agt", agentProperties?.listAgentProperties?.count)
  return (
    <div>
      <Loader open={agentPropertiesLoading} />
      <FrontendHeader />
      <div className="mt-4">
      <div className="container">
        <div className="row">
        {agentProperties?.listAgentProperties?.data &&
          agentProperties?.listAgentProperties?.data.map((items, index) => (
            <SimilarPropertyTile
              item={items} index={index} 
            />
          ))
        }
        {!agentPropertiesLoading && (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={agentProperties?.listAgentProperties?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
      </div>
      </div>
      {/* <Footer /> */}
    </div>)
}

export default AgentDetails

function SimilarPropertyTile(props) {

  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  console.log("user", user)
  const auth = useReactiveVar(authVar);
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
  });
  const { data: listSavedProperty } = useQuery(LIST_SAVED_PROPERTIES, {
    fetchPolicy: "network-only",
    variables: { userId: user?.data?._id },
  });
  const [openUnsaveMsg, setOpenUnsaveMsg] = useState(false);
  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;
  console.log("savedProperties", savedProperties)
  const { id } = useParams();
  console.log("id", id)
  console.log("pr", props)
  const { item } = props;


  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [openAuthPopUp, setOpenAuthPopUp] = React.useState(props?.location === "/login" ? true : false);
  // eslint-disable-next-line no-unused-vars
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };
  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex((element) => element.PropertyId === item._id);
      if (position !== -1) return true;
      else return false;
    }
  }
  const handleSaveProperty = () => {
    // alert("hi");
    // let arr = [];
    // data?.propertyDetails?.forEach((element) => {
    //   let ids = element?._id;
    //   arr.push(ids);
    // });
    // console.log("params.PropertyIDs", arr);
    try {
      savedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [item._id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    // alert("hello")
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: item._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  function PreviousArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="prev"
        onClick={onClick}
        className="slick-prev"
        src="../assets/images/back.svg"
      />
    );
  }
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="next"
        onClick={onClick}
        className="slick-next"
        src="../assets/images/forward.svg"
      />
    );
  }
  function getAltTag(item){
    return `${item?.PID??''}-${item?.ListBathRoom??''}Bath-${item?.ListBedRoom??''}Bed-${item?.Area??''}`
  }

  return (

          <div className="col-md-4">
            <div className="pl-item">
              <DialogBox
                open={openUnsaveMsg}
                setOpen={setOpenUnsaveMsg}
                handlePositiveBtn={handleUnSaveProperty}
                title={"Unsave Property?"}
                text={"Are you sure you want to Unsave the property?"}
              />
              {/* <AuthPopUp open={openAuthPopUp} setOpen={setOpenAuthPopUp} /> */}
              {
                <div className="pl-item-img" >
                  {auth.auth && userRole !== "admin" && <IconButton className="pl-fav" color="secondary" onClick={() => { isSaved() ? setOpenUnsaveMsg(true) : handleSaveProperty() }} >
                    {isSaved() ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
                  </IconButton>}

                  {item?.RelatedPptyImages ? <img
                  onClick={()=>history.push(`/${item.PropertySlug}`)}
                    src={item?.RelatedPptyImages}
                    className="img-fluid"
                    alt={getAltTag(item)}
                  /> : <img
                    onClick={()=>history.push(`/${item.PropertySlug}`)}
                    src={"../assets/images/no-image.jpg"}
                    className="img-fluid"
                    alt={getAltTag(item)}
                  />}
                </div>
              }
              <div className="pl-item-details" onClick={()=>history.push(`/${item.PropertySlug}`)}>
                <h3>
                  {item?.AdTitle}
                  {/* {item?.AdTitle.charAt(0).toUpperCase() +
                    item?.AdTitle.slice(1).toLowerCase()} */}
                </h3>
                <div class="lp-detail">
                  <label>
                    <img
                      src="../assets/images/icon-location-outlined.svg"
                      class="img-fluid"
                      alt="Icon"
                    />
                    {item?.Area}
                  </label>
                  <div class="lp-rooms d-flex align-items-center">
                    {item?.LivingArea && (
                      <label>
                        <img
                          src="../assets/images/icon-sqft.svg"
                          class="img-fluid"
                          alt="Icon"
                        />
                        {item?.LivingArea}
                      </label>
                    )}
                    {item?.ListBedRoom && (
                      <label>
                        <img
                          src="../assets/images/icon-bed.svg"
                          class="img-fluid"
                          alt="Icon"
                        />
                        {item?.ListBedRoom}
                      </label>
                    )}
                    {item?.ListBathRoom && (
                      <label>
                        <img
                          src="../assets/images/icon-bath.svg"
                          class="img-fluid"
                          alt="Icon"
                        />
                        {item?.ListBathRoom}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="pl-item-details pp-availability">
                <ul className="al-style propertyStyleTag">
                  {item?.ListPool && <li>{item?.ListPool}</li>}
                  {item?.ListHeat && <li>{item?.ListHeat}</li>}
                  {item?.ListWater && <li>{item?.ListWater}</li>}
                  {item?.ListAirCondition && <li>{item?.ListAirCondition}</li>}
                  {item?.IsSouthOfHighway === true ? <li>South Of Highway</li> : null}
                  {item?.IsJacuzziSpa === true ? <li>Jacuzzi Spa</li> : null}
                  {item?.IsFurnished === true ? <li>Furnished</li> : null}
                </ul>
              </div>
            </div>
          </div>

  );
}