import React from "react";
import Button from "@material-ui/core/Button";

const PropertyActionHeader = (props) => {
  return ( 
      <div className="propertyActionHeader">
          <h1>{props.label}</h1>
          <div>
            <Button
            
            color="secondary"
            variant="outlined"
            onClick={() => props.setOpenAgentPrint(false)}
            >
            Cancel
            </Button>
     
            <Button
              
              color="secondary"
              variant="outlined"
              onClick={props.handlePrint}
            >
              <img src="../assets/images/icon-print.svg" className="img-fluid me-1" alt="Icon" />Print
            </Button>
          </div>
        
      </div>
  
  );
};

export default PropertyActionHeader;