import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CropImage(props) {
  const classes = useStyles();
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [src, setSrc] = useState(null);
  const [imageRef, setImageRef] = useState();
  let fileUrl;

  useEffect(() => {
    onSelectFile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.image]);

  function onSelectFile() {
    if (props.image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(props.image);
    }
  }
  function onImageLoaded(image) {
    setImageRef(image);
  }
  function onCropComplete(crop) {
    makeClientCrop(crop);
  }
  function onCropChange(crop, percentCrop) {
    setCrop(crop);
  }
  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const imageData = await getCroppedImg(imageRef, crop, "newFile.jpeg");
      setCroppedImageUrl(imageData);
    }
  };
  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve({ fileUrl: fileUrl, file: new File([blob], fileName) });
        },
        "image/jpeg",
        1
      );
    });
  }



  const handleClose = () => {
    props.setOpen(false);
  };
  function handleDone() {
    props.handelCropDone(croppedImageUrl);
    props.setOpen(false);
  }
  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <div className="crop-outer">
              {src && (
                <ReactCrop
                  className="img-fluid"
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              )}
              {/* {croppedImageUrl && (
                <img
                  alt="Crop"
                  style={{ maxWidth: "100%" }}
                  src={croppedImageUrl}
                />
              )} */}
            </div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDone}>Done</Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
