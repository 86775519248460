import React, { useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconButton } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { DialogBox } from "../../Common";
import imageCompression from 'browser-image-compression';

import {
  PROPERTY_IMAGE_UPLOAD,
  VIEW_PROPERTY_IMAGES,
} from "../../../Graghql/queries";
import {
  ADD_PROPERTY_PHOTO,
  EDIT_PROPERTY_PHOTO,
  DELETE_PROPERTY_IMAGE,
} from "../../../Graghql/mutations";

import StarIcon from "@material-ui/icons/Star";
import StarOutlineOutlinedIcon from "@material-ui/icons/StarOutlineOutlined";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import CropImage from "../../Common/CropImage";
import axios from "axios";
import CropIcon from "@material-ui/icons/Crop";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../../RactiveVariables/index";

const AddPhoto = (props) => {
  const user=useReactiveVar(userDeatils)
  const [saveLoading, setSaveLoading] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [videoArray, setVideoArray] = useState("");
  const [imageCount, setImageCount] = useState(0);
  
  const [addPropertyPhoto, { loading }] = useMutation(ADD_PROPERTY_PHOTO);
  const [deletePropertyPhoto] = useMutation(DELETE_PROPERTY_IMAGE);
  const [editPropertyPhoto, { loading: editLoading }] =
    useMutation(EDIT_PROPERTY_PHOTO);
  const [upload] = useLazyQuery(PROPERTY_IMAGE_UPLOAD, {
    onCompleted: (res) => {
      if (res.propertyImageUpload) {
        let array = [...imageArray];
        res.propertyImageUpload.forEach((element) => {
          let position = array.findIndex(
            (item) => item?.file?.name === element?.filename
          );
          if(position>-1){
            let obj = array[position];
            obj["signedUrl"] = element?.signedUrl;
            obj["publicUrl"] = element?.publicUrl;
            array[position] = obj;
          }
        });
        setImageArray(array);
      }
    },
  });
  const { data: viewPropertyImages } = useQuery(VIEW_PROPERTY_IMAGES, {
    fetchPolicy:'network-only',
    variables: {
      propertyId: props.propertyId,
    },
    onCompleted:res=>{
      let array = [];
      let count = imageCount;
      if (res && res.viewPropertyImages && res.viewPropertyImages.data) {
        res.viewPropertyImages.data.forEach((element) => {
          if (element.VideoLink === null || element.VideoLink === "") {
            array.push({
              id: `item-${++count}`,
              _id: element._id,
              image: element.FileUrl,
              publicUrl: element.FileUrl,
              Title: element.Title,
              featured: element.Featured ? element.Featured : false,
              printSelected:element.Featured?true:element.IsPrintable?element.IsPrintable:false
            });
          } else {
            setVideoArray(element.VideoLink);
          }
        });
      }
      setImageCount(count);
      setImageArray(array);
    }
  });
  var compressOptions = {
    maxSizeMB: 1,
    // maxWidthOrHeight: 300,
    useWebWorker: true
}
  const [openCrop, setOpenCrop] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropIndex, setCropIndex] = useState(null);
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const getListStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // display: "flex",
    // padding: grid * 0.5,
    ...draggableStyle,
  });
  function onDragEnd(params) {
    if (!params.destination) return;
    const array = reorder(
      imageArray,
      params.source.index,
      params.destination.index
    );
    setImageArray(array);
  }
  function reorder(array, fromIndex, toIndex) {
    const result = Array.from(array);
    const [removed] = result.splice(fromIndex, 1);
    result.splice(toIndex, 0, removed);
    return result;
  }
  const onDrop = async (acceptedFile) => {
    let array = [...imageArray];
    let count = imageCount;
    let originalFilename = [];

    for(let index = 0; index < acceptedFile.length; index++) {
      let element = acceptedFile[index];
      if(array.length > 14){
        toggleSnackbar({
          status: true,
          message: "Only 15 files can be uploaded",
          variant: "error",
        });
      }
      else{
    let file=element;
      if(!(file?.type==="image/png"||file?.type==="image/jpeg")){
        toggleSnackbar({
          status: true,
          message: "File does not support. You must use .png or .jpg ",
          variant: "error",
        });
      }
      else if (file?.size > 5242880) {
        toggleSnackbar({
          status: true,
          message: "Please upload a file smaller than 5 MB ",
          variant: "error",
        });
      }
      else{
        originalFilename.push({ fileName: element.name });
        let compressedFile = await imageCompression(element, compressOptions)
        array.push({
          id: `item-${count + index + 1}`,
          file: compressedFile,
          featured:imageArray?.length===0 && index===0 ? true:false,
          printSelected:imageArray?.length===0 && index===0 ? true:false,
          image: URL.createObjectURL(compressedFile),
        });
      }
    }
    }
    upload({
      variables: { originalFilename: originalFilename, path: "/property" },
    });
    setImageCount(count + acceptedFile.length);
    setImageArray(array);
  };
  function handleFeatureClick(position) {
    let array = [...imageArray];
    
    array.forEach((element, index) => {
      if (index === position){
        element.featured = element.featured ? !element.featured : true;

        element.printSelected = element.featured;
      }
      else {
        // if (element.featured) {
        //   element.featured = false;
        //   element.printSelected = false; 
        // }
        element.featured = false;
      }
    });
    // const printableImages = array.filter(img => img.printSelected);
    // if (printableImages.length > 3) {
    //   for (let i = 0; i < array.length && printableImages.length > 3; i++) {
    //     if (array[i].printSelected && !array[i].featured) {
    //       array[i].printSelected = false;
    //       printableImages.pop();
    //     }
    //   }
    // }
    let printSelectedCount = array.filter(img => img.printSelected).length;
    if (printSelectedCount > 3) {
      for (let i = array.length - 1; i >= 0 && printSelectedCount > 3; i--) {
        if (!array[i].featured && array[i].printSelected) {
          array[i].printSelected = false;
          printSelectedCount--;
        }
      }
    }
    setImageArray(array);
  
  }
  function handlePrintClick(position) {
    let array = [...imageArray];
    const selectedCount = array.filter(img => img.printSelected).length;
    
    if (array[position].featured) {
      return;
    }

    if (!array[position].printSelected && selectedCount >= 3) {
      toggleSnackbar({
        status: true,
        message: "You can select only 3 images for MCP & MAP Print",
        variant: "error",
      });
      return;
    }

    array[position].printSelected = !array[position].printSelected;
    setImageArray(array);
  }
  
  function handleDelete() {
    let id = deleteId;
    let array = [...imageArray];
    let position = array.findIndex((element) => element.id === id);
    let flag = false;
    if(array[position].featured)
      flag=true;
    if (array[position]._id) {
      let assetId = array[position]._id;
      let fileUrl = array[position].publicUrl;
      deletePropertyPhoto({
        variables: { assetId: assetId, fileUrl: fileUrl },
      });
    }
    array = array.filter((element) => element.id !== id);
    if(flag===true && array.length>0)
      array[0].featured = true;
    setImageArray(array);
    setDeleteId(null);
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // maxSize: 5242880,
    // accept: "image/jpeg, image/png",

  });
  function handelCropDone(result) {
    let imageArr = [...imageArray];
    let obj = imageArr[cropIndex];
    obj["image"] = result.fileUrl;
    obj["cropImage"] = result.file;
    imageArr[cropIndex] = obj;
    setImageArray(imageArr);
    setCropImage(null);
    setCropIndex(null);
  }
  async function handleSubmit() {
    if (imageArray.length === 0 && videoArray === "") {
      toggleSnackbar({
        status: true,
        message: "add atleast 1 image",
        variant: "error",
      });
    } else {
      setSaveLoading(true);
      let data = [];
      let id = props.propertyId;
      let obj = {};
      let deleteArr = [];
      for (let index = 0; index < imageArray.length; ++index) {
        obj = {};
        if (
          imageArray[index].signedUrl &&
          imageArray[index].publicUrl &&
          !imageArray[index]._id
        ) {
          // eslint-disable-next-line no-unused-vars
          let response = await axios.put(
            imageArray[index].signedUrl,
            imageArray[index].cropImage
              ? imageArray[index].cropImage
              : imageArray[index].file,
            {
              headers: {
                "Content-Type": imageArray[index]?.file?.type,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "X-Requested-With",
              },
            }
          );
          toggleSnackbar({
            status: true,
            message: `Photo ${index+1} of ${imageArray.length} uploaded`,
            variant: "success",
          });
        }
        obj = {
          PropertyId: id,
          // VideoLink: "",
          FileUrl: imageArray[index].publicUrl,
          Title: imageArray[index].Title,
          Featured: imageArray[index].featured
            ? imageArray[index].featured
            : false,
            IsPrintable:imageArray[index].printSelected?imageArray[index].printSelected:false,
          OrderByVal: index,
        };
        // if (imageArray[index]._id) {
        //   // obj["_id"] = imageArray[index]._id;
        //   deleteArr.push(imageArray[index]._id);
        // }
        data.push(obj);
      }
      if (videoArray && videoArray !== "")
        data.push({
          PropertyId: id,
          VideoLink: videoArray,
        });
        if (viewPropertyImages && viewPropertyImages.viewPropertyImages && viewPropertyImages.viewPropertyImages.data) {
          viewPropertyImages.viewPropertyImages.data.forEach((element) => {
            if (element._id) {
              deleteArr.push(element._id);
            }
          });
        }        
      if (viewPropertyImages?.viewPropertyImages?.data?.length > 0) {
        try {
          editPropertyPhoto({ variables: { data: data, deleteIDs: deleteArr } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "success",
                variant: "success",
              });
             props.handleNext(user?.data?.UserType==="USER"?5:4);
              setSaveLoading(false);
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
              setSaveLoading(false);
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
          setSaveLoading(false);
        }
      } else {
        try {
          addPropertyPhoto({ variables: { data: data } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "success",
                variant: "success",
              });
              // eslint-disable-next-line no-lone-blocks
              {user?.data?.UserType==="USER"?props.handleNext(5,5,true):props.handleNext(4,4)}
             
              setSaveLoading(false);
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
              setSaveLoading(false);
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
          setSaveLoading(false);
        }
      }
    }
  }
  function handleDescriptionChange(value, index) {
    let array = [...imageArray];
    let obj = array?.[index];
    obj["Title"] = value;
    array[index] = obj;
    setImageArray(array);
  } 
  // function handleNext(state) {
  //   if(user?.data?.Role=="owner"){
  //   if (state && !props.completed[state - 2]) {
  //     let cmp = [...props.completed];
  //     cmp[state - 2] = true;
  //     props.setCompleted(cmp);
  //   }
  //   if (state && props.id === "$") {
  //     props.setStepState(state);
  //     if (props.step < props.totalStep && props.step < state) props.setStep(props.step + 2);
  //   } else if (props.step < props.totalStep && props.step < props.stepState) props.setStep(props.step + 2);
  // }
  // if(user?.data?.Role=="admin"){
  //   if (state && !props.completed[state - 2]) {
  //     let cmp = [...props.completed];
  //     cmp[state - 2] = true;
  //     props.setCompleted(cmp);
  //   }
  //   if (state &&props.id === "$") {
  //     props.setStepState(state);
  //     if (props.step < props.totalStep && props.step < state) props.setStep(props.step + 1);
  //   } else if (props.step < props.totalStep && props.step < props.stepState) props.setStep(props.step + 1);
  // }
  //   } 

  return (
    <div className="add-property-form-wrap">
      <DialogBox
        open={visible}
        setOpen={setVisible}
        handlePositiveBtn={handleDelete}
        title={"Delete?"}
        text={"Are your sure you want to delete the image ?"}
      />
      <CropImage
        open={openCrop}
        setOpen={setOpenCrop}
        image={cropImage}
        handelCropDone={handelCropDone}
      />
      
        <div className="add-property-form-step">
          <div className="add-property-add-photo">
            <div {...getRootProps()} className="upload-drop">
              <input {...getInputProps()} hidden />
              <img
                src="../assets/images/add-file.svg"
                className="img-fluid"
                alt="Add File"
              />
              <h6>Drag and Drop File here</h6>
              <p>Files Supported, JPG, PNG</p>
              <label style={{ cursor:'pointer' }}>Choose File</label>
              <span>Maximum Size: 5MB</span>
            </div>
            {/* <Button className="upload-drop" component="label">
              <input type="file" hidden />
              <img
                src="../assets/images/add-file.svg"
                className="img-fluid"
                alt="Add File"
              />
              <h6>Drag and Drop File here</h6>
              <p>Files Supported, JPG,PNG</p>
              <a href="#">Choose File</a>
              <span>Maximum Size: 5MB</span>
            </Button> */}
          </div>
          <div className="add-photo-info">
            <div className="d-flex align-items-center me-3">
              <StarIcon color="primary" />
              <p>Featured Image</p>
            </div>
            <div className="d-flex align-items-center me-3">
              {/* <DragIndicatorIcon style={{color:'#C4C4C4'}}/> */}
              <img
                src="../assets/images/drag-icon.svg"
                className="img-fluid drag-icon"
                alt="Drag"
              />
              <p>Drag & Order</p>
            </div>
            <div className="d-flex align-items-center ">
              {/* <DragIndicatorIcon style={{color:'#C4C4C4'}}/> */}
              <img
                src="../assets/images/print-Active.svg"
                // className="img-fluid drag-icon"
                alt="Drag"
              />
              <p>MCP & MAP Print</p>
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // eslint-disable-next-line no-sequences
                  style={getListStyle(snapshot.isDraggingOver), { height: `${imageArray.length * 97}px` }}
                >
                  {imageArray.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={getListStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="uploaded-images">
                            <div className="uploaded-image-item">
                              <div className="uploaded-image-dragIcon-preview">
                                <IconButton
                                  color=""
                                  aria-label="drag"
                                  component="span"
                                  className=""
                                >
                                  <img
                                    src="../assets/images/drag-icon.svg"
                                    className="img-fluid"
                                    alt="Drag"
                                  />
                                </IconButton>
                                {item.file && (
                                  <IconButton
                                    color=""
                                    aria-label="drag"
                                    component="span"
                                    className=""
                                    onClick={() => {
                                      setOpenCrop(true);
                                      setCropImage(item.file);
                                      setCropIndex(index);
                                    }}
                                  >
                                    <CropIcon />
                                  </IconButton>
                                )}
                                <div className="previewImage">
                                  <img
                                    src={
                                      item.image
                                        ? item.image
                                        : "../assets/images/no-image.jpg"
                                    }
                                    className="img-fluid"
                                    alt="Preview"
                                  />
                                </div>
                              </div>
                              <div className="uploaded-image-description">
                                <TextField
                                  variant="outlined"
                                  defaultValue={imageArray[index].Title}
                                  className="formTextBox w-100 mb-0"
                                  placeholder="Enter description..."
                                  inputProps = {{maxLength: 50}}
                                  onChange={(e) =>
                                    handleDescriptionChange(
                                      e.target.value,
                                      index
                                    )
                                  }
                                ></TextField>
                              </div>
                              <div className="uploaded-image-action">
                                <IconButton
                                  color=""
                                  aria-label="drag"
                                  component="span"
                                  className=""
                                  onClick={() => handleFeatureClick(index)}
                                >
                                  {item.featured ? (
                                    <StarIcon color="primary" />
                                  ) : (
                                    <StarOutlineOutlinedIcon />
                                  )}
                                </IconButton>
                                <IconButton
                              color=""
                              aria-label="print"
                              component="span"
                              className=""
                              onClick={() => handlePrintClick(index)}
                            >
                              <img
                                src={item.printSelected ? "../assets/images/print-Active.svg" : "../assets/images/print-Disabled.svg"}
                                className="img-fluid"
                                alt="Print"
                              />
                            </IconButton>
                                <IconButton
                                  color=""
                                  aria-label="drag"
                                  component="span"
                                  className=""
                                  onClick={() => {
                                    setDeleteId(item.id);
                                    setVisible(true);
                                  }}
                                >
                                  <img
                                    src="../assets/images/icon-delete.svg"
                                    className="img-fluid"
                                    alt="Delete"
                                  />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="uploaded-images uploaded-video">
            <h6>Video</h6>
            <div className="uploaded-image-item">
              <div className="uploaded-image-dragIcon-preview">
                <label>Youtube Video</label>
              </div>
              <div className="uploaded-image-description">
                <TextField
                  variant="outlined"
                  className="formTextBox w-100 mb-0"
                  placeholder="Enter the video URL"
                  value={videoArray}
                  onChange={(e) => setVideoArray(e.target.value)}
                ></TextField>
              </div>
              <div className="uploaded-image-action"></div>
            </div>
          </div>
        </div>
        <div
          className="add-property-form-step add-property-btn"
          style={{ borderTop: "1px solid #CFCFCF" }}
        >
          <Button className="outline-btn" variant="outline" onClick={props.handlePrevious}>
            Back
          </Button>
          <div>
            <Button
              type="submit"
              color="primary"
              className="highlight-btn"
              variant="contained"
              onClick={handleSubmit}
              id="property-save-btn"
              disabled={loading || saveLoading || editLoading}
            >
              {loading || saveLoading || editLoading ? (
                <CircularProgress size="20px" color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      
    </div>
  );
};
export default AddPhoto;
