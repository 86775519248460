import React from 'react'
import { Dialog, CircularProgress } from "@material-ui/core";
import "./Loader.css";
export default function Loader(props) {
  return (
    <Dialog
      className="loader"
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <div className="progress">
        <CircularProgress />
      </div>
    </Dialog>
  );
}
