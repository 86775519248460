import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import {Link} from "react-router-dom"
import {
  LEAD_ACTIVITY_LIST,
  LEADS_DETAIL,
  LIST_RELATED_PROPERTIES,
} from "../../../Graghql/queries";
import { LEAD_ACTIVITY_ADD } from "../../../Graghql/mutations";
import { toggleSnackbar, userDeatils ,authVar} from "../../../RactiveVariables/index";
import Avatar from "@material-ui/core/Avatar";
import Loader from "../../Common/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment, TablePagination } from "@material-ui/core";
import FrontendHeader from "../../Common/frontendHeader";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";

function PropertyLeadActivity(params) {
  console.log('params>>>>>',params)
  const history = useHistory();
  const [propertyId, setPropertyId] = useState([]);
  const [writeComment, setWriteComment] = useState("");
  const [moreComments, setMoreComments] = useState(false);
  const { item, index, leadComment, leadDetails, id } = params;
  //console.log("item..........>",item)
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  const [viwedProperty] = useMutation(LEAD_ACTIVITY_ADD);
  const handleWriteComment = () => {
    if (writeComment.length > 0) {
      try {
        leadComment({
          variables: {
            UserId: leadDetails.leadsDetails.UserId,
            LeadSrc: "LeadPage",
            LeadType: "PropertyComment",
            RootId: id,
            Comment: writeComment,
            PropertyIDs: propertyId,
          },
        })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Comment added",
              variant: "success",
            });
            setWriteComment("");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      toggleSnackbar({
        message: "Add a note",
        status: true,
        variant: "error",
      });
    }
  };
  const handleViewListing = () => {
    if(auth?.auth ){
    try {
      viwedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property View",
          LeadType: "View",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [item?.PropertyId],
        },
      })
        .then((res) => {
          // toggleSnackbar({
          //   status: true,
          //   message: "You have been successfully saved property",
          //   variant: "success",
          // });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  };
  return (
    <div className="lead-activity-item-wrap" key={index}>
      <div className="lead-activity-item">
        <div className="lead-property">
          <div className="lp-img">
          <Link to={
                     `/${item?.PropertyDtls?.PropertySlug}`
                  }
                  //  target={"_blank"} 
                  onClick={()=> handleViewListing()}
                   >
            {item?.ImagesLead?.map(
              (item) =>
                item.Featured === true && (
                  <img
                    src={item?.FileUrl}
                    className="img-fluid"
                    alt="Property"
                  ></img>
                )
            )}
            </Link>
          </div>

          <div className="lp-detail">
            <h4>PID#{item?.PropertyDtls?.PID}</h4>
            <h3 style={{cursor:"pointer"}} onClick={() =>{ history.push(`/${item?.PropertyDtls?.PropertySlug}`);handleViewListing()}} >
              {item?.PropertyDtls?.AdTitle}
              {/* {item?.PropertyDtls?.AdTitle?.charAt(0).toUpperCase() +
                item?.PropertyDtls?.AdTitle?.slice(1).toLowerCase()} */}
            </h3>
            {item?.PropertyDtls?.Area && (
              <label>
                <img
                  src="../assets/images/icon-location-outlined.svg"
                  className="img-fluid"
                  alt="Icon"
                ></img>
                {item?.PropertyDtls?.Area}
              </label>
            )}
            <div className="lp-rooms d-flex align-items-center">
              {item?.PropertyDtls?.LivingArea && (
                <label>
                  <img
                    src="../assets/images/icon-sqft.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.LivingArea} sq
                </label>
              )}
              {item?.PropertyDtls?.ListBedRoom && (
                <label>
                  <img
                    src="../assets/images/icon-bed.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.ListBedRoom}
                </label>
              )}
              {item?.PropertyDtls?.ListBathRoom && (
                <label>
                  <img
                    src="../assets/images/icon-bath.svg"
                    className="img-fluid"
                    alt="Icon"
                  ></img>
                  {item?.PropertyDtls?.ListBathRoom}
                </label>
              )}
            </div>
          </div>
        </div>
        {item?.PriceDataLead?.PriceDetails && (
          <div className="lp-price-month">
            <ul>
              {item?.PriceDataLead?.PriceDetails?.map((item) => (
                <>
                  {(item.AvblStatus === "yes" ||
                    item.AvblStatus === "rented") && (
                    <li>
                      <label>
                        {item.AvblStatus === "yes" && (
                          <img
                            src="../assets/images/green-check.svg"
                            className="img-fluid"
                            alt="Tick"
                          />
                        )}{" "}
                        {item.AvblStatus === "rented" && (
                          <img
                            src="../assets/images/red-check.svg"
                            className="img-fluid"
                            alt="Tick"
                          />
                        )}
                        {item.RentalPeriod}
                      </label>
                      ${formatePrice(item.Price)}
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        )}
        <div className="lp-commets">
          <div className="lp-comment-box">
            <TextField
              variant="outlined"
              className="formTextBox"
              placeholder="Write comment"
              value={writeComment}
              onKeyPress={(e) => e.key === "Enter" && handleWriteComment()}
              onChange={(e) => {
                setWriteComment(e.target.value);
                setPropertyId([item?.PropertyId]);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img
                        src="../assets/images/icon-send.svg"
                        className="social-icon img-fluid"
                        alt="logo"
                        onClick={() => {
                          handleWriteComment();
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="lp-comment-list">
            {item?.PropertyLeadCmts?.map((items, index) => {
              return(
                items?.LeadActivityComment?.map((item,index)=>{
              if (!moreComments && index > 1) return null;
              return (
                <div className="lp-comment-list-item">
                  <Avatar style={{ backgroundColor: "#0C4946" }}  
                  // src={item?.AddedByDtls?.ProfileImage}
                  >
                    {item?.CreatedByName.FirstName?.charAt(0).toUpperCase()+item?.CreatedByName.LastName?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography paragraph>
                    <TexttView text={item.Comment} />
                  </Typography>
                </div>
              );
            } )
            )
            })}

            {item?.PropertyLeadCmts.length > 2 && (
              <label
              style={{cursor:'pointer'}}
                onClick={() => setMoreComments(!moreComments)}
                className="view-comment"
              >
                {moreComments ? "View less comments" : "View more comments"}
              </label>
            )}
          </div>
        </div>
      </div>
      <address>
        <b>
          {item?.AddedByDtls?.FirstName &&
            item?.AddedByDtls?.LastName &&
            item?.AddedByDtls?.FirstName.charAt(0).toUpperCase() +
              item?.AddedByDtls?.FirstName.slice(1).toLowerCase() +
              " " +
              item?.AddedByDtls?.LastName.charAt(0).toUpperCase() +
              item?.AddedByDtls?.LastName.slice(1).toLowerCase()}
        </b>
        &nbsp;<font>{item?.LeadType==="View"?"Viewed":item?.LeadType==="Save"?"Saved":
        item?.LeadType==="Enquiry"?"Inquired":item?.LeadType==="Suggest"?"Suggested":
        item?.LeadType==="Registration"&&item?.LeadSrc==="Owner Registration"?"Registered as Owner":
        item?.LeadType==="Registration"&&item?.LeadSrc==="Renter Registration"?"Registered as Renter":
        item?.LeadType==="Property"&&item?.LeadSrc==="Property Registration"?"Created":""}</font>&nbsp;on{" "}
        {moment(new Date(Number(item.CreatedOn))).format("MMM DD YYYY hh:mm A")}
      </address>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 125) return text.slice(0, 125) + " ...";
      else return text.slice(0, 125);
    } else return text;
  }
  if (!props.text) return null;
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 125 && (
        <label
          style={{ color: "#000", fontFamily: "Helvetica-Bold",cursor:'pointer' }}
          onClick={() => setVisible(!visible)}
        >
          {visible ? " See Less" : " See More"}
        </label>
      )}
    </div>
  );
}
const UserActivity = (props) => {
  const history = useHistory();
  const user = useReactiveVar(userDeatils);
  const { id } = useParams();
  const [postComment, setPostComment] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const leadActivityListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    leadId: id,
    tabType: "",
  };
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    fetchMore({
      variables: leadActivityListVariables,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [leadComment] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["leadActivityList"],
  });

  const {
    data: leadDetails,
    // loading
  } = useQuery(LEADS_DETAIL, {
    variables: { leadId: id },
  });

  const {
    data: leadActivity,
    loading,
    fetchMore,
  } = useQuery(LEAD_ACTIVITY_LIST, {
    fetchPolicy: "network-only",
    variables: leadActivityListVariables,
  });
  const leadDetail =
    leadActivity &&
    leadActivity.leadActivityList &&
    leadActivity.leadActivityList.data;
  const leadDetailCount =
    leadActivity &&
    leadActivity.leadActivityList &&
    leadActivity.leadActivityList.count;
  //console.log("leadActivity-----", leadDetail, leadDetailCount);
  //console.log("leadDetail.UserId-----", userId);
  const { data: listRelatedProperty } = useQuery(LIST_RELATED_PROPERTIES, {
    variables: { id: user?.data?._id, type: "user" },
  });
  const listRelateProperty =
    listRelatedProperty &&
    listRelatedProperty.listRelatedProperties &&
    listRelatedProperty.listRelatedProperties.data;
  console.log("listRelatedProperty-----", listRelateProperty);
  const handlePostComment = () => {
    if (postComment.length > 0) {
      try {
        leadComment({
          variables: {
            UserId: leadDetails.leadsDetails.UserId,
            LeadSrc: "LeadPage",
            LeadType: "Comment",
            RootId: id,
            Comment: postComment,
            //PropertyId: "",
          },
        })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Comment added",
              variant: "success",
            });
            setPostComment("");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      toggleSnackbar({
        message: "Add a note",
        status: true,
        variant: "error",
      });
    }
  };
  return (
    <section className="leadActivityWrap">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <FrontendHeader
              contact
              list
              search
              message
              count={leadDetailCount}
              location={props.location.pathname}
            />
            <Loader open={loading} />
            <div className="lead-comment-post-box">
              <div className="d-flex">
                <Avatar src={leadDetails?.leadsDetails?.UserDtls?.ProfileImage}>
                  {leadDetails?.leadsDetails?.UserDtls?.FirstName.charAt(0).toUpperCase()+
                  leadDetails?.leadsDetails?.UserDtls?.LastName.charAt(0).toUpperCase()}
                </Avatar>
                <TextField
                  variant="outlined"
                  className="formTextBox"
                  placeholder="Connect with your agent"
                  value={postComment}
                  onKeyPress={(e) => e.key === "Enter" && handlePostComment()}
                  onChange={(e) => {
                    setPostComment(e.target.value);
                  }}
                />
              </div>
              {postComment&& <div className="text-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePostComment()}
                >
                  Post comment
                </Button>
              </div>}
            </div>
            {leadDetail?.map((item, index) =>
              item.PropertyId ? (
                <PropertyLeadActivity
                  item={item}
                  index={index}
                  leadComment={leadComment}
                  leadDetails={leadDetails}
                  id={id}
                  //user={user}
                />
              ) : (
                <div className="lead-activity-item-wrap">
                  <div className="lead-activity-item">
                    <div className="lp-comment-list">
                      <div
                        className="lp-comment-list-item p-3"
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <Avatar  style={{ backgroundColor: "#0C4946" }} src={item?.AddedByDtls?.ProfileImage}>
                          {item?.AddedByDtls?.FirstName.charAt(0).toUpperCase()+item?.AddedByDtls?.LastName.charAt(0).toUpperCase()}
                        </Avatar>
                        <Typography paragraph className="ms-2 mb-0">
                          <TexttView text={item.Comment} />
                          {/* {item.Comment} */}
                        </Typography>
                      </div>

                      {/* {leadDetail && leadDetail.length > 3 && (
                    <a
                      onClick={() => setMoreComments(!moreComments)}
                      className="view-comment"
                    >
                      {moreComments ? "View less comments" : "View more comments"}
                    </a>
                  )} */}
                    </div>
                  </div>
                </div>
              )
            )}
            {!loading && (
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={leadDetailCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
              />
            )}
          </div>
          <div className="col-md-4">
            <div className="leadActivitySide">
              <div className="sideBox">
                <div className="la-agent">
                  <h5>My Agent</h5>
                  <div className="d-flex">
                    <Avatar
                      src={leadDetails?.leadsDetails?.AgentDtls?.ProfileImage}
                    >
                      {leadDetails?.leadsDetails?.AgentDtls?.FirstName.charAt(0).toUpperCase()+
                      leadDetails?.leadsDetails?.AgentDtls?.LastName.charAt(0).toUpperCase()}
                    </Avatar>
                    <div>
                      <h6>
                        {leadDetails?.leadsDetails?.AgentDtls?.FirstName.charAt(
                          0
                        ).toUpperCase() +
                          leadDetails?.leadsDetails?.AgentDtls?.FirstName.slice(
                            1
                          ).toLowerCase() +
                          " " +
                          leadDetails?.leadsDetails?.AgentDtls?.LastName.charAt(
                            0
                          ).toUpperCase() +
                          leadDetails?.leadsDetails?.AgentDtls?.LastName.slice(
                            1
                          ).toLowerCase()}
                      </h6>
                      <Link to='#' style={{cursor:'pointer'}} >
                        <img
                          src="../assets/images/icon-location-outlined.svg"
                          className="img-fluid"
                          alt="Icon"
                        />
                        Barnes
                      </Link>

                      {leadDetails?.leadsDetails?.AgentDtls?.Phone && (
                        <Link to='#'onClick={(e) => {window.location ="tel:" + leadDetails?.leadsDetails?.AgentDtls?.Phone
                        e.preventDefault();
                        }}>
                          <img
                            src="../assets/images/icon-mobile-2.svg"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {leadDetails?.leadsDetails?.AgentDtls?.Phone}
                        </Link>
                      )}
                      {leadDetails?.leadsDetails?.AgentDtls?.OfficePhone && (
                        <Link to='#'onClick={(e) => {window.location ="tel:" + leadDetails?.leadsDetails?.AgentDtls?.OfficePhone
                        e.preventDefault();
                        }}>
                          <img
                            src="../assets/images/icon-mobile-2.svg"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {leadDetails?.leadsDetails?.AgentDtls?.OfficePhone}
                        </Link>
                      )}
                      {leadDetails?.leadsDetails?.AgentDtls?.Email && (
                        <Link
                          to="#"onClick={(e)=>{window.location = "mailto: " +
                          leadDetails?.leadsDetails?.AgentDtls?.Email.toString()
                          e.preventDefault(); }}
                        >
                          <img
                            src="../assets/images/icon-email-double.svg"
                            className="img-fluid"
                            alt="Icon"
                          />
                          {leadDetails?.leadsDetails?.AgentDtls?.Email}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {user?.data?.Role === "renter" && (
                <div className="sideBox">
                  <div className="la-related-property">
                    <h5>Related Properties</h5>
                    {listRelateProperty &&listRelateProperty.length?(
                      listRelateProperty?.map(
                        (item, index) =>
                          index < 10 && (
                            // <Link to={
                            //   `/${item?.PropertySlug}`
                            // }
                            // // target={"_blank"} 
                            // >
                            <div
                              className="la-related-property-item"
                              key={index}
                              style={{cursor: 'pointer'}}
                              onClick={()=>history.push(`/${item?.PropertySlug}`)}
                            >
                              <div className="la-img">
                                {/* {item?.Images?.map(
                                  (item) =>
                                    item.Featured === true && (
                                      <img
                                        src={item?.FileUrl}
                                        className="img-fluid"
                                        alt="Icon"
                                      ></img>
                                    )
                                )} */}
                                {/* <Link to={
                                    `/${item?.PropertySlug}`
                                  }
                                  // target={"_blank"} 
                                  > */}
                                {item.RelatedPptyImages ? (
                                  
                                  <img 
                                  // onClick={()=>history.push("/property-detail/" + item?.PropertySlug)}
                                    src={item.RelatedPptyImages}
                                    className="img-fluid"
                                    alt="Icon"
                                    style={{cursor: 'pointer'}}
                                  />
                                  
                                ) : (
                                  <img
                                    src="../assets/images/no-image.jpg"
                                    className="img-fluid"
                                    alt="Icon"
                                    style={{cursor: 'pointer'}}
                                  />
                                )}
                                {/* </Link> */}
                              </div>
                              <div className="la-property-detail">
                                <h6>{item.AdTitle}
                                  {/* {item.AdTitle?.charAt(0).toUpperCase() +
                                    item?.AdTitle?.slice(1).toLowerCase()} */}
                                </h6>
                                <ul>
                                  <li>
                                    <img
                                      src="../assets/images/icon-location-outlined.svg"
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                    {item.Area}
                                  </li>
                                  {item.LivingArea && (
                                    <li>
                                      <img
                                        src="../assets/images/icon-sqft-light.svg"
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                      {item.LivingArea} sq
                                    </li>
                                  )}
                                  {item.ListBedRoom && (
                                    <li>
                                      <img
                                        src="../assets/images/icon-bed-light.svg"
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                      {item.ListBedRoom}
                                    </li>
                                  )}
                                  {item.ListBathRoom && (
                                    <li>
                                      <img
                                        src="../assets/images/icon-bath-light.svg"
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                      {item.ListBathRoom}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            // </Link>
                          )
                      )):"No Properties to show"}
                  </div>
                </div>
              )}

              {/*
              <div className="about-lead-details about-lead-blocks">
                <h2>My Agent</h2>
                <div>
                  <Avatar src={leadDetails?.leadsDetails?.AgentDtls?.ProfileImage}>{leadDetails?.leadsDetails?.AgentDtls?.FirstName.charAt(0).toUpperCase()}</Avatar>
                </div>
                <h2>{leadDetails?.leadsDetails?.AgentDtls?.FirstName.charAt(0).toUpperCase()+leadDetails?.leadsDetails?.AgentDtls?.FirstName.slice(1).toLowerCase(),
                  leadDetails?.leadsDetails?.AgentDtls?.LastName.charAt(0).toUpperCase()+leadDetails?.leadsDetails?.AgentDtls?.LastName.slice(1).toLowerCase()}</h2>
                <div className="about-lead-mail-phone">
                  {leadDetails?.leadsDetails?.AgentDtls?.Email && (
                    <a
                      href={
                        "mailto: " + leadDetails?.leadsDetails?.AgentDtls?.Email.toString()
                      }
                    >
                      <img
                        src="../assets/images/icon-email-double.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      {leadDetails?.leadsDetails?.AgentDtls?.Email}
                    </a>
                  )}
                  {leadDetails?.leadsDetails?.AgentDtls?.Phone && (
                    <a href="mailto:Drt@abcmgmt.net">
                      <img
                        src="../assets/images/icon-mobile-2.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      {leadDetails?.leadsDetails?.AgentDtls?.Phone}
                    </a>
                  )}
                  {leadDetails?.leadsDetails?.AgentDtls?.OfficePhone && (
                    <a href="mailto:Drt@abcmgmt.net">
                      <img
                        src="../assets/images/icon-mobile-2.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      {leadDetails?.leadsDetails?.AgentDtls?.OfficePhone}
                    </a>
                  )}
                </div>
              </div> 
              */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserActivity;
