import React, { useEffect } from "react";
import { userDeatils } from "../../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { Loader } from "@googlemaps/js-api-loader";
import { Chip } from "@material-ui/core";
import moment from "moment";
const key = process.env.REACT_APP_GOOGLE_API_KEY;

// eslint-disable-next-line no-unused-vars
function AgentPrintMap(props) {
  const stylesArr = [
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          color: "#878787",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f9f5ed",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#aee0f4",
        },
      ],
    },
  ];
  useEffect(() => {
    const loader = new Loader({
      apiKey: key,
      libraries: ["places"],
    });

    loader.load().then(() => {
      let map = new window.google.maps.Map(
        document.getElementById("map" + props.index),
        {
          center: props?.latLng,
          zoom: 12,
          draggable: false,
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }
      );
      const styledMapType = new window.google.maps.StyledMapType(stylesArr, {
        name: "Styled Map",
      });
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map");
      new window.google.maps.Marker({
        position: props?.latLng,
        map: map,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      id={"map" + props.index}
      style={{ width: "100%", height: "275px" }}
    ></div>
  );
}
const AgentPrint = React.forwardRef((props, ref) => {
  const agentData = props.data;
  // eslint-disable-next-line no-unused-vars
  const user = useReactiveVar(userDeatils);
  //console.log("props.data", agentData);
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  // eslint-disable-next-line no-unused-vars
  const formateData = (value) => {
    const finalSentence = value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
    return finalSentence;
    //   const words = value.split(" ");
    //   for (let i = 0; i < words.length; i++) {
    //     words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    // }

    // words.join(" ");
  };
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  return (
    <div className="printModalContent" ref={ref}>
      {agentData.map((item, index) => (
        <div className="pagebreak">
          <div className="printHeader">
            <img
              src="../assets/images/logo-hr.jpg"
              className="img-fluid"
              alt="Logo"
            />
            <div className="printHeaderAddress">
              {/* <label>
                <b>
                  {user?.data?.FirstName.charAt(0).toUpperCase() +user?.data?.FirstName.slice(1).toLowerCase()+ " "+ user?.data?.LastName.charAt(0).toUpperCase() +user?.data?.LastName.slice(1).toLowerCase()}
                   
                </b>
                &nbsp;
              </label>
              {user?.data?.Role!=="agent"&&user?.data?.Phone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.Phone}
                </label>
              )}
              {user?.data?.CellPhone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.CellPhone }
                </label>
              )}
              {user?.data?.Role!=="agent"&&user?.data?.HomePhone  && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.HomePhone }
                </label>
              )}
              {user?.data?.Role!=="agent"&&user?.data?.LocalPhone  && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.LocalPhone }
                </label>
              )}
              {user?.data?.OfficePhone  && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.OfficePhone}
                </label>
              )}
              {user?.data?.Email && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/email.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {user?.data?.Email}
                </label>
              )} */}

              <label>
                <b>
                  {item?.OwnerDtls?.FirstName.charAt(0).toUpperCase() +
                    item?.OwnerDtls?.FirstName.slice(1).toLowerCase() +
                    " " +
                    item?.OwnerDtls?.LastName.charAt(0).toUpperCase() +
                    item?.OwnerDtls?.LastName.slice(1).toLowerCase()}
                </b>
                &nbsp; (Owner)
              </label>

              {item?.OwnerDtls?.Phone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.Phone}
                </label>
              )}
              {item?.OwnerDtls?.CellPhone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.CellPhone}
                </label>
              )}
              {item?.OwnerDtls?.HomePhone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.HomePhone}
                </label>
              )}
              {item?.OwnerDtls?.LocalPhone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.LocalPhone}
                </label>
              )}
              {item?.OwnerDtls?.OfficePhone && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.OfficePhone}
                </label>
              )}
              {item?.OwnerDtls?.Email && (
                <label>
                  {" "}
                  <img
                    src="../assets/images/email.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {item?.OwnerDtls?.Email}
                </label>
              )}
            </div>
          </div>
          <div className="printPropertyImage">
            {item?.Images?.map(
              (items, index) =>
                index < 3 &&
                items?.FileUrl != null && (
                  <div className="printPropertyImageItem" key={index}>
                    <img
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="Property"
                    />
                  </div>
                )
            )}
            {item?.PendingImages?.map(
              (items, index) =>
                index < 3 &&
                items?.FileUrl != null && (
                  <div className="printPropertyImageItem" key={index}>
                    <img
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="Property"
                    />
                  </div>
                )
            )}
          </div>
          {/* <div className="printMap">
            {item.Latitude && (
              <AgentPrintMap
                isMarkerShown
                latLng={{
                  lat: parseFloat(item?.Latitude),
                  lng: parseFloat(item?.Longitude),
                }}
                index={index}
              />
            )}
          </div> */}
          <div className="printPropertyDetail">
            <div className="printPropertyDetail-Img">
              {item?.Images?.map((items, index) => (
                <div>
                  {items.Featured === true && (
                    <img
                      key={index}
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="{item?.AdTitle}"
                    ></img>
                  )}
                </div>
              ))}
              {item?.PendingImages?.map((items, index) => (
                <div>
                  {items.Featured === true && (
                    <img
                      key={index}
                      src={items?.FileUrl}
                      className="img-fluid"
                      alt="{item?.AdTitle}"
                    ></img>
                  )}
                </div>
              ))}
            </div>
            <div>
              <h2>
                <b>PID#&nbsp;{item?.PID}</b>&nbsp;
                {item?.AdTitle + ", " + item?.StreetAddress + ", " + item.Area}
              </h2>
              <ul className="propertyStyleTag">
                {item?.ListMainAmenity?.map(
                  (items) => items?.Flag === true && <li>{items.Title}</li>
                )}
              </ul>
            </div>
          </div>

          <div className="printPriceFeatures">
            <h3>
              <img
                src="../assets/images/icon-home.svg"
                className="imgfluid"
                alt="Icon"
              />
              Property Features
            </h3>
            <ul>
              {item?.ListBedRoom && (
                <li>
                  <label>Bed:</label>
                  <span>{item?.ListBedRoom}</span>
                </li>
              )}
              {item?.ListBathRoom && (
                <li>
                  <label>Bath:</label>
                  <span>{item?.ListBathRoom}</span>
                </li>
              )}
              {item?.ListStyle && (
                <li>
                  <label>Style:</label>
                  <span>{item?.ListStyle}</span>
                </li>
              )}
              {item?.ListFireplace && (
                <li>
                  <label>Fireplace:</label>
                  <span>{item?.ListFireplace}</span>
                </li>
              )}
              {item?.LivingArea && (
                <li>
                  <label>Living Area:</label>
                  <span>{item?.LivingArea}</span>
                </li>
              )}
              {item?.ListGarage && (
                <li>
                  <label>Garage:</label>
                  <span>{item?.ListGarage}</span>
                </li>
              )}
              {item?.YearBuilt && (
                <li>
                  <label>Year Built:</label>
                  <span>{item?.YearBuilt}</span>
                </li>
              )}
              {item?.ListHeat && (
                <li>
                  <label>Heat:</label>
                  <span>{item?.ListHeat}</span>
                </li>
              )}
              {item?.ListBasement && (
                <li>
                  <label>Basement:</label>
                  <span>{item?.ListBasement}</span>
                </li>
              )}
              {item?.ListAirCondition && (
                <li>
                  <label>A/c:</label>
                  <span>{item?.ListAirCondition}</span>
                </li>
              )}
              {item?.LotSize && (
                <li>
                  <label>Lot Size:</label>
                  <span>{item?.LotSize}</span>
                </li>
              )}
              {item?.ListPool && (
                <li>
                  <label>Pool:</label>
                  <span>{item?.ListPool}</span>
                </li>
              )}
              {item?.ListStory && (
                <li>
                  <label>Stories:</label>
                  <span>{item?.ListStory}</span>
                </li>
              )}
              {item?.AlaramCode&&(
                <li>
                  <label>Alarm Code:</label>{" "} 
                  {<span>{item?.AlaramCode}</span>}
                </li>
              )}
              {item?.KeyLocation&&(
                <li>
                  <label>Key Location:</label>{" "} 
                  {<span>{item?.KeyLocation}</span>}
                </li>
              )}
              {item?.SpecialInfo&&(
                <li>
                  <label>Special Info:</label>{" "} 
                  {<span>{item?.SpecialInfo}</span>}
                </li>
              )}
              {item?.ShowingIns&&(
                <li>
                  <label>Showing Instructions:</label>{" "} 
                  {<span>{item?.ShowingIns}</span>}
                </li>
              )}
              {item?.SuffCoTaxMap?.Value1 != null && (
                <li>
                  <label>TaxMap:</label>{" "}
                  {item?.SuffCoTaxMap && (
                    <span>
                      {item?.SuffCoTaxMap?.Value1 +
                        "/" +
                        item?.SuffCoTaxMap?.Value2 +
                        "/" +
                        item?.SuffCoTaxMap?.Value3 +
                        "/" +
                        item?.SuffCoTaxMap?.Value4 +
                        "/" +
                        item?.SuffCoTaxMap?.Value5 +
                        "/" +
                        item?.SuffCoTaxMap?.Value6 +
                        "/" +
                        item?.SuffCoTaxMap?.Value7}
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>
          {item?.PriceData?.PriceDetails && (
            <div className="printPriceFeatures">
              <h3>
                <img
                  src="../assets/images/icon-price.svg"
                  className="imgfluid"
                  alt="Icon"
                />
                Pricing & Availability
              </h3>
              <ul>
                {item?.PriceData?.PriceDetails?.map((items) => (
                  <>
                    {(items.AvblStatus === "yes" ||
                      items.AvblStatus === "rented") && (
                      <li>
                        <label>
                          {items.AvblStatus === "yes" && (
                            <img
                              src="../assets/images/green-check.svg"
                              className="imgfluid"
                              alt="Icon"
                            />
                          )}{" "}
                          {items.AvblStatus === "rented" && (
                            <img
                              src="../assets/images/red-check.svg"
                              className="imgfluid"
                              alt="Icon"
                            />
                          )}
                          {items.RentalPeriod}
                        </label>
                        <span>${formatePrice(items.Price)}</span>
                      </li>
                    )}
                  </>
                ))}
              </ul>
            </div>
          )}

{item?.PriceData?.PriceDetails && (
            <div className="printPriceFeatures border-0">
              <h3>
                <img
                  src="../assets/images/icon-price.svg"
                  className="imgfluid"
                  alt="Icon"
                />
                Short term Price (Priced Weekly)
              </h3>
              <ul>
                {item?.PriceData?.ShortTermPriceDetails?.map((items) => (
                      <Chip
                        className="me-2 mt-2"
                        label={`${convertTimeStamp(
                          items.DateFrom
                        )} - ${convertTimeStamp(items.DateTo)} $${items.Amount}`}
                      />
                ))}
              </ul>
            </div>
          )}

          <div className="printFooter">
            <p className="mb-2">
              {/* {item?.StreetAddress + ", " + item.Area} */}
              15 Railroad Avenue - Suite 3 . East Hampton - NY. 11937 . East Hampton
            </p>
            <p>
              All information is provided is deemed reliable but is not
              guranteed and should be independently verified
            </p>
          </div>
        </div>
      ))}
    </div>
  );
});
export default AgentPrint;
