import React, { useRef } from "react";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import PropertyActionHeader from "./PropertyActionHeader";
import { useReactToPrint } from 'react-to-print';

const CustomizedModal = (props) => {
  const componentRef = useRef();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "0px 0px 6px rgba(105, 105, 105, 0.25)",
    // eslint-disable-next-line no-dupe-keys
    boxShadow: 24,
    p: 4,
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const Component = props.component;
  return (
    <Modal
      onClose={() => props.setOpenAgentPrint(false)}
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={props.label==="Email Listing"?'emailListingModalWrap':"printModalWrap"}
    >
      <>
        {props.hideHeader
          ? !props.hideHeader
          : true && (
              <PropertyActionHeader
                label={props.label}
                setOpenAgentPrint={props.setOpenAgentPrint}
                handlePrint={handlePrint}
              />
            )}
        <Box sx={style}>
          {Component && (
            <Component
              ref={componentRef}
              data={props.data}
              propertyId={props.propertyId}
              setOpenAgentPrint={props.setOpenAgentPrint}
              propertyDetails={props.propertyDetails}
              emailOwner={props.emailOwner}
             handlePagination={props?.handlePagination}
             approvalPropertyId={props?.approvalPropertyId}
             suggestApproval={props?.suggestApproval}
            />
          )}
        </Box>
      </>
    </Modal>
  );
};
export default CustomizedModal;

// const CustomizedModal = (props) => {
//  // const toggleModalVar = useReactiveVar(toggleModal);
//   // const handleClose = () => {
//   //   toggleModal({ ...toggleModalVar, status: false });
//   // };
//   const Component = props.component;

//   return (
//     <>
//      <PropertyActionHeader/>
//       <Dialog onClose={() => props.setOpenAgentPrint(false)} open={props.open}>
//         {Component && <Component data={props.data}/>}
//       </Dialog>
//       </>
//   );
// };
// export default CustomizedModal;
