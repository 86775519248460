import { Loader } from "@googlemaps/js-api-loader";
import { useEffect } from "react";
import React from "react";
 var infoWindow;
export default function Map(props) {
  const [placesDetails, setPlacesDetails] = React.useState([]);
  const [mapActive, setMapActive] = React.useState("restaurant");
  const key = process.env.REACT_APP_GOOGLE_API_KEY;
  const lat = props.lat;
  const lng = props.lng;
  const zoom = 15;
  const stylesArr = [
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#878787"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f9f5ed"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#c9c9c9"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#aee0f4"
            }
        ]
    }
];
function getFeaturedImage(images) {
  if(images){
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
}

  useEffect(() => {
    const loader = new Loader({
      apiKey: key,
      libraries: ["places"],
    });

    loader.load().then(() => {
      let map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: lat, lng: lng },
        zoom: zoom,
        //mapTypeControl: false,
      });
      const styledMapType = new window.google.maps.StyledMapType(
        stylesArr,
        { name: "Styled Map" }
      );
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map");
      const marker = new window.google.maps.Marker({
        position: { lat: lat, lng: lng },
        animation: window.google.maps.Animation.DROP,
        map: map,
      });
    const contentString =
    "<div class='map_info_wrapper pl-item pd-pl-item'><div class='img_wrapper pl-item-img'><img src=" +
      getFeaturedImage(props?.data?.Images) +
    "></div>" +
    "<div class='property_content_wrap'>" +
    "<div class='property_title pl-item-details'>" +
    "<h3>" +
    props?.data?.AdTitle +
    // props?.data?.AdTitle.charAt(0).toUpperCase() +
    // props?.data?.AdTitle.slice(1).toLowerCase() +
    "</h3>" +
    "<div class='property_bed_type lp-detail'>" +
    "<label><img src='../assets/images/icon-location-dark.svg' class='img-fluid' alt='Icon'/>" +
    props?.data.Area +
    "</label>" +
    "<div class='lp-rooms d-flex align-items-center'>" +
    "<label><img src='../assets/images/icon-sqft.svg' class='img-fluid' alt='Icon'/>" +
    props?.data.LivingArea +
    "</label><label><img src='../assets/images/icon-bed.svg' class='img-fluid' alt='Icon'/>" +
    props?.data.ListBedRoom +
    "</label><label><img src=../assets/images/icon-bath.svg>" +
    props?.data.ListBathRoom +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div></div>";
  marker.addListener("click", () => {
    if (infoWindow)
    infoWindow.close(map);
    infoWindow=  new window.google.maps.InfoWindow({
      content: contentString,
    })
    infoWindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });
  });  
  GetPlaces("restaurant")
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function GetPlaces(type) {
    let url = "";
    if(type==="restaurant")
      url = "../assets/images/Hotel.png";
    else if(type==="hospital")
      url = "../assets/images/Hospital.png";
    else if(type==="gym")
      url = "../assets/images/Gym.png";
    else if(type==="shopping_mall")
      url = "../assets/images/Shop.png";
    else if(type==="bank")
      url = "../assets/images/Bank.png";
    else if(type==="church")
      url = "../assets/images/Church.png";
    setMapActive(type)
    let map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: lat, lng: lng },
      zoom: zoom,
    });
    // map.setOptions({
    //   styles: [
    //     {
    //       featureType: "poi.business",
    //       stylers: [{ visibility: "off" }],
    //     },
    //     {
    //       featureType: "transit",
    //       elementType: "labels.icon",
    //       stylers: [{ visibility: "off" }],
    //     },
    //   ],
    // });
    var request = {
      location: new window.google.maps.LatLng(lat, lng),
      radius: "50000",
      type: [type],
    };

    function SplitAddress(address, index) {
      let addr = address.split(",");
      return addr[index] ? addr[index] : null;
    }

    var service = new window.google.maps.places.PlacesService(map);
    service.nearbySearch(request, function (results, status) {
      console.log("resultssss---->",results)
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        new window.google.maps.Marker({
          position: { lat: lat, lng: lng },
          animation: window.google.maps.Animation.DROP,
          map: map,
        });
        getDistance(results);
        for (var i = 0; i < results.length; i++) {
          const marker = new window.google.maps.Marker({
            position: {
              lat: results[i].geometry?.location.lat(),
              lng: results[i].geometry?.location.lng(),
            },
            icon: {
              // url: results[i].icon,
              url: url,
              scaledSize: new window.google.maps.Size(25, 25),
            },
            animation: window.google.maps.Animation.DROP,
            map: map,
          });
          const contentString =
            "<div class='d-flex align-items-center neighborhood-map-view'><div><img class='neighborhood-map-img' src=" +
            results[i]?.photos?.[0]?.getUrl() +
            "></div><div  class='neighborhood-map-details'><b>" +
            results[i].name +
            "</b><br/>" +
            SplitAddress(results[i].vicinity, 0) +
            "<br/>" +
            SplitAddress(results[i].vicinity, 1) +
            "</div></div>";
          // eslint-disable-next-line no-loop-func
          marker.addListener("click", () => {
            if (infoWindow)
            infoWindow.close(map);
            infoWindow= new window.google.maps.InfoWindow({
              content: contentString,
            })
            infoWindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });
        }
        // map.setCenter(results[0].geometry.location);
      }
    });
  }
  async function getDistance(results) {
    var directionsService = new window.google.maps.DirectionsService();
    let placeArray = [];
    for (let index = 0;index<results.length;++index){
      if (index < 4) {
        let response = await directionsService.route({
          origin: `${lat},${lng}`,
          destination: results[index].geometry.location,
          travelMode: window.google.maps.TravelMode.DRIVING,
        });
        placeArray.push({
          name: results[index].name,
          distance: response?.routes?.[0]?.legs?.[0]?.distance?.text,
          time: response?.routes?.[0]?.legs?.[0]?.duration?.text,
        });
      }
    }
    setPlacesDetails(placeArray);
  }
 
  return (
    <div className="neighborhood">
      <h3>Nearby in the neighborhood</h3>
      <div className="neighborhood-view">
        <ul className="neighborhood-facility">
          <li className={mapActive==="restaurant"?"active":null} onClick={() => GetPlaces("restaurant")}>
            <img
              src="../assets/images/icon-restuarant.svg"
              className="imgfluid"
              alt="Icon"
            />
            Restaurants
          </li>
          <li  className={mapActive==="hospital"?"active":null} onClick={() => GetPlaces("hospital")}>
            <img
              src="../assets/images/icon-hospital.svg"
              className="imgfluid"
              alt="Icon"
            />
            Hospitals
          </li>
          <li className={mapActive==="gym"?"active":null} onClick={() => GetPlaces("gym")}>
            <img
              src="../assets/images/icon-gym.svg"
              className="imgfluid"
              alt="Icon"
            />
            Gym
          </li>
          <li className={mapActive==="shopping_mall"?"active":null} onClick={() => GetPlaces("shopping_mall")}>
            <img
              src="../assets/images/icon-shop.svg"
              className="imgfluid"
              alt="Icon"
            />
            Shops
          </li>
          <li className={mapActive==="bank"?"active":null} onClick={() => GetPlaces("bank")}>
            <img
              src="../assets/images/icon-bank.svg"
              className="imgfluid"
              alt="Icon"
            />
            Bank
          </li>
          <li className={mapActive==="church"?"active":null} onClick={() => GetPlaces("church")}>
            <img
              src="../assets/images/icon-church.svg"
              className="imgfluid"
              alt="Icon"
            />
            Church
          </li>
        </ul>

        <div className="neighborhood-map">
          <div id="map" style={{ width: "100%", height: "275px" }}></div>
          <ul>
            {placesDetails.map((item, index) => {
              return (
                <li>
                  <label>{item.name}</label>
                  <b>
                    {item.distance} | {item.time}
                  </b>
                </li>
              );
            })}
            {/* <li>
              <label>Brunetti Pizza Westhampton Beach</label>
              <b>1.54 Mi | 3 Min</b>
            </li>
            <li>
              <label>Dockers Waterside Marina & Restaurant</label>
              <b>1.54 Mi | 3 Min</b>
            </li>
            <li>
              <label>Sakura House </label>
              <b>1.54 Mi | 3 Min</b>
            </li>{" "}
            <li>
              <label>Margarita Grille </label>
              <b>1.54 Mi | 3 Min</b>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
