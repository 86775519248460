import React, { useEffect, useState } from "react";
import { useParams, useHistory,useLocation,Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { LEADS_DETAIL } from "../../../Graghql/queries";
import SideBar from "../../Common/sidebar";
import LoggedInHeader from "../../Common/loggedinHeader";

import { makeStyles } from "@material-ui/core/styles";

import LeadDetail from "./LeadDetail";
import LeadsPortfolio from "./LeadsPortfolio";
import LeadAgentNote from "./LeadAgentNote";
import LeadProfile from "./LeadProfile";
import { userDeatils } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "5px",
  },
}));

const LeadDetails = () => {
  const locations = useLocation();
  const history = useHistory();
  const { id } = useParams();
  //console.log("idddd",id)
  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  const [tabs, setTabs] = useState(userRole==="owner"?1:0);
  //const [leadId, setLeadId] = useState(null);
  const {
    data: leadDetails,
    //loading
  } = useQuery(LEADS_DETAIL, {
    variables: { leadId: id },
  });
  const handleTabs = (value) => {
    setTabs(value);
    //setLeadId(id)
  };
  function handleBack() {
    history.push("/leads");
  }
  function formatName(firstName, lastName) {
    // eslint-disable-next-line no-sequences
    if(firstName, lastName)
    return (
      firstName.charAt(0).toUpperCase() +
      firstName.slice(1).toLowerCase() +
      " " +
      lastName.charAt(0).toUpperCase() +
      lastName.slice(1).toLowerCase()
    );
  }
  useEffect(()=>{
if(locations?.state?.title==="ownerPortfolios"||locations?.state?.title==="renterPortfolios") handleTabs(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  console.log(",,,,,,,,",leadDetails?.leadsDetails?.UserType)
  return (
    <div className="custom-layout">
      <LoggedInHeader
        label={
          // userRole === "admin"
          //   ? 
            formatName(
                leadDetails?.leadsDetails?.UserDtls?.FirstName,
                leadDetails?.leadsDetails?.UserDtls?.LastName
              )
            // : formatName(
            //     leadDetails?.leadsDetails?.AgentDtls?.FirstName,
            //     leadDetails?.leadsDetails?.AgentDtls?.LastName
            //   )
        }
        handleBack={handleBack}
      />
      <div className="custom-layout-content">
        <div class="search-band lead-tab">
          <ul>
            <li
              className={tabs === 0 ? "active" : null}
              value={tabs}
              onClick={() => handleTabs(0)}
            >
              <Link to= "#">Activity Panel</Link>
            </li>
            <li
              className={tabs === 1 ? "active" : null}
              value={tabs}
              onClick={() => handleTabs(1)}
            >
              {leadDetails?.leadsDetails?.UserType==="renter"?<Link to="#">Renter Portfolio</Link>:<Link to="#">Owner Portfolio</Link>}
            </li>
            {(userRole === "admin"  || userRole === "agent"||userRole==="agentAdmin") && (
              <li
                className={tabs === 2 ? "active" : null}
                value={tabs}
                onClick={() => handleTabs(2)}
              >
                <Link to= "#">Private Agent Note</Link>
              </li>
            )}
          </ul>
        </div>
        <SideBar selected="leads" />

        <div className="content-wrap">
          <div className="row">
            {tabs === 0 && <LeadDetail />}
            {tabs === 1 && <LeadsPortfolio />}
            {tabs === 2 && <LeadAgentNote />}
            <LeadProfile />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeadDetails;
