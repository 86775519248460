import React from "react";
import {  useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";


const ChangePassword = (props) => {

  const [changePassword, { loading: changePassLoading }] =
    useMutation(CHANGE_PASSWORD);
  const schema = yup.object().shape({
    oldPassword: yup.string().required("Old Password is required"),
    newPassword: yup
      .string()
      .required("New Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
        "Password must contain atleast 8 characters, including UPPER/lower case, numbers and special characters."
      ),
    confirmNewPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("newPassword"), null],
        "New Password and Confirm Password do not match"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    try {
      changePassword({
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      })
        .then((res) => {
          //const message = res?.data?.resetPassword?.message;
          toggleSnackbar({
            status: true,
            message: "Password changed successfully",
            variant: "success",
          });
          props.setOpenChangePassword(false);
          //   history.push("/login")
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div className="login-register-wrap page-wrap-height h-auto">
      <div className="login-register">
        <img
          src="../assets/images/logo.svg"
          className="login-logo img-fluid d-block m-auto"
          alt="logo"
        />
        <div className="login-register-tab">
          <Link to="#" className="active">CHANGE PASSWORD</Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label className="form-label">
              Old Password<sup>*</sup>
            </label>
            <input
              type="password"
              className="form-control"
              {...register("oldPassword")}
            />
            {errors.oldPassword && (
              <span className="error-msg">{errors?.oldPassword?.message}</span>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">
              New Password<sup>*</sup>
            </label>
            <input
              type="password"
              className="form-control"
              {...register("newPassword")}
            />
            {errors.newPassword && (
              <span className="error-msg">{errors?.newPassword?.message}</span>
            )}
          </div>
          <div className="form-group">
            <label className="form-label">
              Confirm New Password<sup>*</sup>
            </label>
            <input
              type="password"
              className="form-control"
              {...register("confirmNewPassword")}
            />
            {errors.confirmNewPassword && (
              <span className="error-msg">
                {errors?.confirmNewPassword?.message}
              </span>
            )}
          </div>
          <div>
            <Button
              disabled={changePassLoading}
              className="login-sumbit-btn"
              type="submit"
              color="primary"
            >
              {changePassLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
