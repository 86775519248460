import React, { useEffect } from 'react';
import FrontendHeader from "../Common/frontendHeader"
import Footer from "../Common/footer";
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet";
// import MetaTags from 'react-meta-tags';
const Terms = (props) => {
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[]);

  return (
    <div>
      <Helmet>
        <title>Terms Of Use  | Rosehip Partners Real Estate</title>
        <meta name="Keywords" />
        <meta name="description" content="Our terms of use for using our award winning Hamptonsrentals.com web site." />
      </Helmet>
      {/* <MetaTags>
        <title>Page 1</title>
        <meta name="description" content="Some description." />
      </MetaTags> */}
      <FrontendHeader search list contact location={props.location.pathname} />
      <div className="inner-banner team-banner" style={{ backgroundImage: 'url(./assets/images/teams-bg.jpg)' }}>
        <div className="container">
          <div className="inner-banner-content">
            <h1>Terms of Use</h1>
          </div>
        </div>
      </div>
      <div className="common-template">
        <div className="container">
          
            <p>By using the Hamptonsrentals.com web site, a service of Rosehip Partners LLC, you are agreeing to be bound by the following terms and conditions (“Terms of Use”). Use of this web site is also subject to our Privacy Policy . By using this web site, you are also agreeing to be bound by the terms and conditions in our privacy policy.</p>
            <h3>Basic Terms:</h3>
            <p>Hamptonsrentals.com , is the property of Rosehip Partners LLC, licensed Real Estate Brokers in the State of New York, Other product, service, or company names used on our web site are for identification purposes only. Use of this web site does not grant you any license or right to use any of the marks included in our web site. You are responsible for the safekeeping of your password and preventing others from using your account.</p>
            <ul>
              <li>You are responsible for any uses of our web site that occur under your account.</li>
              <li>You represent that you are legally authorized by virtue of ownership, agency representation or otherwise, to post properties on Hamptonsrentals.com. You may not use this service for any illegal or unauthorized purpose. </li>
              <li>You may not use this web site to make false, fraudulent or speculative offers or claims. You warrant that your use of Hamptonsrentals.com, does not violate any laws in your jurisdiction (including but not limited to copyright laws). </li>
              <li>You may not use Hamptonsrentals.com content for other than your personal use or for inclusion of Hamptonsrentals.com pages in a search index. </li>
            </ul>
            <p>Violation of any of these agreements will result in the termination of your Hamptonsrentals.com account.</p>
            <h3>General Conditions:</h3>
            <ul>
              <li>We reserve the right to modify or terminate the Hamptonsrentals.com service for any reason, without notice, at any time. </li>
              <li>We reserve the right to alter these Terms of Use at any time. We reserve the right to refuse service to anyone for any reason at any time. </li>
              <li>We reserve the right to edit all submitted content at our sole discretion and without limitation. This may be for, but not limited to, compliance with New York State real estate law and insuring uniformity with the general style and presentation of existing content on the website. This may also include direct contact information, information related to the ownership of submitted listings or information related to the broker representation of properties listed. </li>
              <li>We may, but have no obligation to, remove Content and accounts containing Content that we determine in our sole discretion to be unlawful or otherwise objectionable or violates these Terms of Use. </li>
            </ul>
            <h3>Tools and Functionality:</h3>
            <p>Hamptonsrentals.com offers it’s users a suite of tools designed to assist property owners and related stake holders in the management and marketing of real estate. The services provided on this web site are provided on an "as is" and "as available" basis. Hamptonsrentals.com makes no warranty, and expressly disclaims any obligation, that:</p>
            <ul>
              <li>This web site will meet your requirements or will be available on an uninterrupted, timely, secure, or error-free basis; </li>
              <li>The content will be up-to-date, complete, comprehensive, accurate or applicable to your circumstances; </li>
              <li>The results that may be obtained from the use of this web site or any services offered through the site will be accurate or reliable; or </li>
              <li>The quality of any products, services, information, or other material obtained by you through the site will meet your expectations. </li>
            </ul>
            <h3>Warranty Disclaimer:</h3>
            <p>This web site and all content and information provided is from sources deemed reliable. No representation is made as to the accuracy thereof, and such information is subject to errors, omission, change of price, rental, commission, prior sale, lease or financing, or withdrawal without notice.</p>
            <h3>Limitation of Liability:</h3>
            <p>Hamptonsrentals.com, together with our owners, officers, directors, employees, representatives, affiliates, and providers, will not be responsible or liable for</p>
            <ul>
              <li>Any damages to or viruses that may infect your computer equipment or other property as the result of your access to this web site or your downloading of any content from this web site or</li>
              <li>
                Any injury, death, loss, claim, act of god, accident, delay, or any direct, special, exemplary, punitive, indirect, incidental or consequential damages of any kind (including without limitation lost profits or lost savings), whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with
                <ul>
                  <li>Any use of this web site or content, or</li>
                  <li>The performance or non performance by us or any provider, even if we have beenadvised of the possibility of damages to such parties or any other party.</li>
                </ul>
              </li>
            </ul>
            <h3>Questions:</h3>
            <p>We encourage you to contact us if you have any questions or concerns. We will do our best to respond to them. We can be reached during normal business hours at 631-324-0009 or by email to <Link to="#" onClick={(e)=>{window.location ="mailto:info@Hamptonsrentals.com"; e.preventDefault()}} class="red_a">info@Hamptonsrentals.com</Link>.</p>
          
        </div>

      </div>
      <Footer />
    </div>
  );
};
export default Terms;