import { useState } from "react";
import React from "react";
import { Dialog } from "@material-ui/core";
import Login from "./Login";
import Register from "./register";

export default function AuthPopUp(props) {
  const [login, setLogin] = useState(true);

  const handleClose = (value) => {
    props.setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={props.open}>
      {login ? (
        <Login
          setLogin={setLogin}
          setOpen={props.setOpen}
          locState={props?.locState}
        />
      ) : (
        <Register setLogin={setLogin} setOpen={props.setOpen} />
      )}
    </Dialog>
  );
}
