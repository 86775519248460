import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FrontendHeader from "../Common/frontendHeader"
import Footer from "../Common/footer";
import { useHistory } from "react-router-dom";
import { useQuery } from '@apollo/client'
import { userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import AuthPopUp from "../Login/AuthPopUp";
import { PROPERTIES_IN_TOWN, ALL_ACTIVE_USERS,ALL_ACTIVE_OWNERS,ALL_ACTIVE_PROPERTIES_COUNT} from "../../Graghql/queries";
import { Link } from "react-router-dom"
const About = (props) => {
  const history= useHistory();
  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  const [openAuthPopUp, setOpenAuthPopUp] = useState(
    props?.location === "/login" ? true : false
  );
  const { data: townlist } = useQuery(PROPERTIES_IN_TOWN)
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[]);
  const { data: allActiveUsers } = useQuery(ALL_ACTIVE_USERS)
  const { data: allActiveOwners } = useQuery(ALL_ACTIVE_OWNERS)
  const { data: allActivePropertiesCount } = useQuery(ALL_ACTIVE_PROPERTIES_COUNT)
  return (
    <div>
      <AuthPopUp open={openAuthPopUp} setOpen={setOpenAuthPopUp} />
      <FrontendHeader search list contact location={props.location.pathname}/>
      <div className="inner-banner team-banner" style={{ backgroundImage: 'url(./assets/images/about-bg.jpg)' }}>
        <div className="container">
          <div className="inner-banner-content">
            <h1>About Us</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="about-content">
          <h4>Hamptons Rentals</h4>
          <label>More personalized service, technical leadership and deep local roots.</label>
          <p>Hamptonsrentals.com is owned and operated by Rosehip Partners LLC, a real estate brokerage located in East Hampton, NY. Staffed by local licensed real estate agents with years of experience in meeting the needs of buyers, sellers, tenants and landlords, we look forward to helping you achieve all of your real estate objectives.</p>
          <p>We can be reached at  <Link
            to='#'
            onClick={(e) => {
                window.location = 'mailto:info@rosehippartners.com'
                e.preventDefault();
            }}
        >
           info@rosehippartners.com
        </Link> or by calling 631-324-0009</p>
          {/* <p>Rosehip is a Hamptons real estate brokerage, and a leader locally in championing transparent business practices and generally opening up the Hamptons real estate market. The Internet has so transformed real estate brokerage that it would not be an exaggeration to suggest it is a completely different business than fifteen years ago. Rosehip embraces technical innovation in the Hamptons marketplace and combines it with the personalized service you expect when dealing with a locally owned firm with deep roots in the Hamptons.</p> */}

          {/* <p>Today we operate in a world of 24/7 information and instant communication and this has completely changed the successful agent’s role in the real estate relationship. Realtors can no longer afford to be information gatekeepers. To truly service clients, today’s successful realtor must act as a catalyst or a facilitator providing local knowledge,</p> */}
        </div>
        <div className="about-count">
          <div className="about-count-item">
            <div className="about-count-icon">
              <img src="../assets/images/icon-rent.svg" className="img-fluid" alt="Icon" />
            </div>
            <div className="about-count-text">
              <b>{allActivePropertiesCount?.allActivePropertiesCount}</b>
              <label>Properties for Rent</label>
            </div>
          </div>
          <div className="about-count-item">
            <div className="about-count-icon">
              <img src="../assets/images/icon-user.svg" className="img-fluid" alt="Icon" />
            </div>
            <div className="about-count-text">
              <b>{allActiveUsers?.allActiveUsers}</b>
              <label>Registered Users</label>
            </div>
          </div>
          <div className="about-count-item">
            <div className="about-count-icon">
              <img src="../assets/images/icon-owner-count.svg" className="img-fluid" alt="Icon" />
            </div>
            <div className="about-count-text">
              <b>{allActiveOwners?.allActiveOwners}</b>
              <label>Registered Owners</label>
            </div>
          </div>
          <div className="about-count-item">
            <div className="about-count-icon">
              <img src="../assets/images/icon-verified.svg" className="img-fluid" alt="Icon" />
            </div>
            <div className="about-count-text">
              <b>100%</b>
              <label>Verified & reliable Listings</label>
            </div>
          </div>
        </div>
        <div className="about-action">
          <div className="about-action-item about-action-list">
            <h2><img src="../assets/images/icon-list-property.svg" className="img-fluid" alt="Icon" />List My Property</h2>
            <p>Register to list your property with Rosehip Partners and  reach the thousands of renters who visit hamptonsrentals.com  each month.</p>
            {(userRole === "admin" || userRole === "agent" ||userRole === "agentAdmin" )? 
            (<Button color="primary" variant="contained" onClick={()=> {history.push("/properties")}}>List Property</Button>):
            (userRole === "owner" || userRole === "renter") ? 
            (<Button color="primary" variant="contained" onClick={()=> {history.push(`/lead-portfolio/${user?.data?.LeadList}`)}}>List Property</Button>):
            <Button color="primary" variant="contained" onClick={() => setOpenAuthPopUp(true)}>Sign Up</Button>
            }
          </div>
          <div className="about-action-item about-action-rental">
            <h2><img src="../assets/images/icon-search-rentals.svg" className="img-fluid" alt="Icon" />Search Rentals</h2>
            <p>Register as a user to unlock features that make your rental search efficient and easy. Start now!</p>
            <Button color="primary" variant="contained" onClick={()=>{history.push("/searchlistings/page1")}}>Search</Button>
          </div>
        </div>

        <div className="towns">
        <div className="container">
          <h3>Towns</h3>
          <div className="row">
          {townlist?.propertiesInTown.map((item, index) => (
              item?.town==="Barnes"||item?.town==="Ditch Plains"?"":
              <div className="col-md-4">
                <Link to={{ pathname: item?.town ? `/${item?.town.toLowerCase().replace(/\s+/g, '-')}/page1` : "/searchlistings/page1", searchObj: { area: item?.town } }} >
                <div className="towns-item">
                
                  <img
                    src={item?.townImgUrl}
                    className="img-fluid"
                    alt="Towns"
                  />
                  <div className="towns-item-text">
                    

                      <h4>{item?.town}</h4>
                      <label>{item?.count + " "}Rentals</label>

                    
                  </div>
                </div>
                </Link>
              </div>))}
          </div>
        </div>
      </div>

        {/* <div className="about-towns">
            <h4>Towns</h4>
            <div className="row">

            {townlist?.propertiesInTown.map((item, index) => (
              <div className="col-md-4">
                  <div className="about-town-tile" style={{backgroundImage:'url(./assets/images/team-bg.jpg)'}}>
                  <Link to={{ pathname: "/propertyList", searchObj: { area: item?.town } }} >
                    <h5>{item?.town}</h5>
                    <span>{item?.count + " "}Rentals<img src="../assets/images/right-arrow.svg" className="img-fluid" alt="Icon" /></span>
                    </Link>
                  </div>
              </div>))}
              
            </div>
        </div> */}
      </div>
      <Footer/>
    </div>
  );
};
export default About;