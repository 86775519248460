import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userDeatils, authVar } from "../RactiveVariables";

export default function RouteWrapper({
  // eslint-disable-next-line no-useless-rename
  component: component,
  isPrivate,
  isAuthPage,
  admin,
  type,
  ...rest
}) {
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
 
   //const routes=useReactiveVar(routeDetails)
  //console.log("routesss",routes)
  const isLoggedin = auth?.auth;
  const userType = user?.data?.Role;
  if (isPrivate && !isLoggedin) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { url: window.location.pathname },
        }}

      />
    );
  } else if (isAuthPage && isLoggedin) {
    if (userType === "admin" || userType === "agent") 
      return <Redirect to="/agent" />;
    else return <Redirect to="/" /> ;
  } else if (type && !type.includes(userType)) {
    return <Redirect to="/" />;
  }
  return <Route {...rest} component={component} />;
}
