import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {toggleSnackbar} from "../../RactiveVariables/index";
import { useReactiveVar } from '@apollo/client';
//import { makeStyles } from "@material-ui/core/styles";

const CustomizedSnackbars = (props) => {
  const toggleSnackbarVar=useReactiveVar(toggleSnackbar)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    toggleSnackbar({...toggleSnackbarVar,status:false})
  };

  return (
    <Snackbar open={toggleSnackbarVar.status} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{"vertical":"top","horizontal": "right"}}>
      <MuiAlert onClose={handleClose} severity={toggleSnackbarVar.variant || 'success'} elevation={6} variant="filled">
        {toggleSnackbarVar.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;