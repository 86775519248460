import React from 'react';
import { useReactiveVar } from "@apollo/client";
import { PROPERTIES_IN_TOWN_FOOTER } from "../../Graghql/queries"
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom"
import { userDeatils } from "../../RactiveVariables/index";
const Footer = () => {
    const user = useReactiveVar(userDeatils);
    const now = new Date();
    const currentYear = now.getFullYear();
    
    const { data: townlist } = useQuery(PROPERTIES_IN_TOWN_FOOTER,{
        variables:{skip:0,limit:7}
    })
    const { data: townlistData } = useQuery(PROPERTIES_IN_TOWN_FOOTER,{
        variables:{skip:7,limit:7}
    })
    //console.log("td",townlist)
    return (
        <footer>
            <div className="container">
                <h4>HAMPTONS <font>RENTALS</font></h4>
                <div className="row">
                    <div class="col-md-4 col-6">

                        <ul>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/agents" >Agents</Link></li>
                            
                            {(user?.data?.Role !== "admin" && user?.data?.Role !== "agentAdmin") && <li><Link to="/contact">Contact Us</Link></li>}
                            <li><Link to="/termsOfUse">Terms of Use</Link></li>
                            <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
                            <li><Link target="_blank" to={{pathname : "https://hrfileuploads.s3.amazonaws.com/systemFiles/pdfuploads/SKMBT_C20322042612250.pdf"}}>Standard Operating Procedure</Link></li>
                            <li><Link target="_blank" to={{pathname : "https://fileuploads-hr.s3.amazonaws.com/skmbt/SKMBT_C20322090613230.pdf"}}>Tenants' Rights to Reasonable Accomodations</Link></li>
                        </ul>
                    </div>
                    <div class="col-md-3 col-6">
                        {townlist?.propertiesInTownFooter && <ul>
                            {townlist?.propertiesInTownFooter.map((item) => (
                                item?.town === "Barnes" || item?.town === "Ditch Plains" ? "" : <li><Link to={{ pathname: item?.town ? `/${item?.town.toLowerCase().replace(/\s+/g, '-')}/page1` : "/searchlistings/page1", searchObj: { area: item?.town } }}  >{`${item?.town}(${item.count})`}</Link></li>
                            ))}
                        </ul>}
                    </div>
                    <div class="col-md-3 col-6">
                        {townlistData?.propertiesInTownFooter && <ul>
                            {townlistData?.propertiesInTownFooter.map((item) => (
                                item?.town === "Barnes" || item?.town === "Ditch Plains" ? "" : <li><Link to={{ pathname: item?.town ? `/${item?.town.toLowerCase().replace(/\s+/g, '-')}/page1` : "/searchlistings/page1", searchObj: { area: item?.town } }}  >{`${item?.town}(${item.count})`}</Link></li>
                            ))}
                        </ul>}
                    </div>
                    {/* <div class="col-md-3 col-6 mt-3 mt-md-auto">
                               <ul>
                                   <li><a href="#">Bridgehampton (12)</a></li>
                                   <li><a href="#">Flanders (19)</a></li>
                                   <li><a href="#">Manorville (94)</a></li>
                                   <li><a href="#">Quogue (81)</a></li>
                                   <li><a href="#">Riverhead (95)</a></li>
                               </ul>
                           </div>
                           <div class="col-md-3 col-6 mt-3 mt-md-auto">
                               <ul>
                                    <li><a href="#">Bridgehampton (12)</a></li>
                                    <li><a href="#">Flanders (19)</a></li>
                                    <li><a href="#">Manorville (94)</a></li>
                                    <li><a href="#">Quogue (81)</a></li>
                                    <li><a href="#">Riverhead (95)</a></li>
                               </ul>
                           </div> */}

                    {/* <div class=" col-md-3 col-6">
                        <h4>Support</h4>
                        <ul>
                            <li><Link to='#' onClick={(e) => {
                                window.location = 'mailto:info@rosehippartners.com'
                                e.preventDefault();
                            }}>info@rosehippartners.com</Link></li>
                        </ul>
                    </div> */}
                    <div class="col-md-2 col-6">
                        <h4>Connect</h4>
                        <ul className="social-connect d-flex align-items-center">
                            <li><Link to={{ pathname: "https://www.facebook.com/hamptonsrental/" }} target="_blank"><img src="../assets/images/icon-fb.svg" className="img-fluid" alt="Icon" /></Link></li>
                            <li><Link to={{ pathname: "https://www.instagram.com/rosehiprealestate/"}} target="_blank"><img src="../assets/images/icon-instagram.svg" className="img-fluid" alt="Icon" /></Link></li>
                        </ul>
                        <h4>Support</h4>
                        <ul>
                            {/* <li><Link to="#">Help & Feedback</Link></li> */}
                            <li><Link to='#' onClick={(e) => {
                                window.location = 'mailto:info@rosehippartners.com'
                                e.preventDefault();
                            }}>info@rosehippartners.com</Link></li>
                            {/* <li><Link to="#">Accessibility</Link></li> */}
                        </ul>
                    </div>
                </div>

                <div className="copy-right">
                    <Link to="#">© {currentYear} Hamptons Rentals</Link>
                    {/* <Link to="/privacyPolicy">Privacy Policy</Link>    */}
                </div>
            </div>
        </footer>
    );
};



export default Footer;
