import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory,useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../RactiveVariables";

const PropertyHeader = (props) => {
  const user=useReactiveVar(userDeatils)
  const history = useHistory();
  const {id} = useParams()
  return (
    <header className="fixed-top propertyHeader">
      <div className="property-step-title">
        <label>
          Step {props.step ? props.step : 0} /{" "}
          {props.totalStep ? props.totalStep : 0}
        </label>
        <h6>{props.menuItems[props.step - 1].label}</h6>
      </div>
      <div className="header-right d-none d-md-block">
        <Button
          className="outline-btn"
          color="primary"
          variant="outlined"
          onClick={() => props?.handleCancel()}
        >
         Cancel
        </Button>
        <Button
            className="highlight-btn"
            //disabled={props.step >= props.stepState}
            color="primary"
            variant="contained"
            onClick={()=>props.step === 5 ?history.push("/properties"):document.getElementById("property-save-btn").click()}
            // onClick={() => props?.handleNext(user?.data?.UserType==="USER"&&props.step===3?props.step+2:props.step+1)}
          >
            Save & Proceed
          </Button>
        {id!=='$' &&<> {props.step === 5 ? (
          <Button
            className="highlight-btn"
            //disabled={props.step >= props.stepState}
            color="primary"
            variant="contained"
            onClick={() => history.push("/properties")}
          >
            Done
          </Button>
        ) : (
          <Button
            className="highlight-btn"
            //disabled={props.step >= props.stepState}
            color="primary"
            variant="contained"
            // onClick={()=>document.getElementById("property-save-btn").click()}
            onClick={() => props?.handleNext(user?.data?.UserType==="USER"&&props.step===3?props.step+2:props.step+1)}
          >
           Next
          </Button>
        )}</>}
      </div>
    </header>
  );
};

export default PropertyHeader;
