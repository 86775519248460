import React from "react";
import "../Agent/AddAgent.css";
import { useForm } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Drawer,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { ADD_RENTER, EDIT_RENTER } from "../../Graghql/mutations";
import { LIST_ALL_AGENT, LIST_ACTIVE_AGENTS } from "../../Graghql/queries";
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";

export default function AddRenter(props) {
  const user = useReactiveVar(userDeatils);
  console.log("prosssssss",props)
  const [cellPh, setCellPh] = React.useState("");
  const [draftFlag, setDraftFlag] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [assocAgent, setAssocAgent] = React.useState("")

  const [addRenter, { loading }] = useMutation(ADD_RENTER);
  const [editRenter] = useMutation(EDIT_RENTER,{refetchQueries:["leadsDetails","listRenter"] ,});
  const { data: agentList } = useQuery(LIST_ALL_AGENT);
  let agentQueue =
    agentList && agentList.listAllAgents && agentList.listAllAgents.data;
  const { data: activeAgentList } = useQuery(LIST_ACTIVE_AGENTS,{variables:{skip:0,limit:100}});
  let activeAgentLists =
    activeAgentList &&
    activeAgentList.listActiveAgents &&
    activeAgentList.listActiveAgents.data;
  //console.log("activeAgentLists", activeAgentLists);
  React.useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);
  React.useEffect(() => {
    setCellPh(props.defaultValue?.Phone);
  }, [props.defaultValue]);
  // React.useEffect(() => {
  //     if (props.renterEdit && props.isEdit) reset(props.renterEdit);
  // }, []);
  //   const [chechEmail, chechEmailData] = useLazyQuery(CHECK_EMAIL, {
  //     fetchPolicy: "network-only",
  //     variables: {
  //       Email: email,
  //     },
  //   });
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("First Name is required"),
      // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    lastName: yup
      .string()
      .required("Last Name is required"),
      // .matches(/^[a-zA-Z]+$/, "Must be characters"),
    email: yup.string().required("Email is required").email(),
    phone: yup
      .string()
      .required("Phone Number is required")
      .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
    //   .test("phone", "Must be a number", (value) => {
    //     if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    //     else return true;
    // }),
    Agent: yup.string().required("Agent is required"),
  });
  const {
    register,
    handleSubmit,
    formState,
    reset, 
  } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const onSubmit = (params) => {
    // params.SaveAsDraft = draftFlag;
    if (params?.Status === "active") params.Status = "active";
    else params.Status = "inactive";

    if (props.defaultValue === null) {
      try {
        addRenter({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Renter successfully created",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
            setCellPh("");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        props.defaultValue?.AgentId?params["id"] = props?.defaultValue?.UserId: params["id"] = props?.defaultValue?._id;
        editRenter({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Renter successfully updated",
              variant: "success",
            });
            //props.handlePagination();
            props.setOpen(false);
            props.setDefaultValue(null);
            setCellPh("");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
    //     addRenter({ variables: params })
    //         .then((res) => {
    //             reset();
    //             toggleSnackbar({
    //                 status: true,
    //                 message: "Renter successfully created",
    //                 variant: "success",
    //             });
    //             props.handlePagination();
    //             props.setOpen(false);
    //         })
    //         .catch((error) => {
    //             const message = error && error.message;
    //             toggleSnackbar({
    //                 status: true,
    //                 message: message,
    //                 variant: "error",
    //             });
    //         });
    // } catch (error) {
    //     const message = error && error.message;
    //     toggleSnackbar({
    //         status: true,
    //         message: message,
    //         variant: "error",
    //     });
    // }
  };

  function toggleDrawer() {
    //reset();
    props.setOpen(!props.open);
  }
  function phoneNumberFormat(num) {
 
    if (num) {
     // let newNum = num.replace(/[-]+/g, "");
     let newNum = num.replace(/[^0-9]/g, '');
      let x;
      if (newNum.length <= 3) {
        x = newNum;
      } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
      } else {
        x =
          newNum.slice(0, 3) +
          "-" +
          newNum.slice(3, 6) +
          "-" +
          newNum.slice(6, 10);
      }
      return x;
    }
  }
  const handleClose = () => {
    props.setDefaultValue(null);
    setCellPh("");
    toggleDrawer();
  };
  // eslint-disable-next-line no-unused-vars
  let agentName = activeAgentList && [...activeAgentList.listActiveAgents.data];
  // eslint-disable-next-line no-unused-vars
  function sortLabels(a, b) {
    var nameA = a.FirstName.toUpperCase();
    var nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  // eslint-disable-next-line no-unused-vars
  const handleSelectAssocAgent = (e, value) => {
    //console.log("e", value)
    setAssocAgent(value)
  }
  console.log("props.defaultValue------",user?.data?._id)
  const phoneNumberEdit = register("phone", { required: true });
  
  // eslint-disable-next-line no-unused-vars
  const NumberChecking=(e)=>{
    const re = /^[0-9\b]+$/;
    if(e===''||re.test(e)){
      return e
    }else{
      let newVal = e.replace(/\D/g,'');
      return newVal
    }
    
  }
  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={handleClose}>
        <div role="presentation" className="addAgent">
          <h1 className={"addAgentHead"}>Renter Profile</h1>
          <h5 className="add-sub-head">Primary Contact Information</h5>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="active-radio">
              <h6>Active</h6>
              <RadioGroup
                //defaultValue={props.defaultValue?.Status === "Active" ? "yes" : props.defaultValue?.Status === "Inactive" ? "no" : null}

                defaultValue={
                  props.defaultValue?.Status
                    ? props.defaultValue?.Status:props.defaultValue?.UserDtls?.Status?props.defaultValue?.UserDtls?.Status
                    : "active"
                }
                name="status"
                className={"addAgentRadio"}
                //   value={value}
                //onChange={(e) => setValue("status", e.target.value)}
                {...register("Status")}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </div>

            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">
                  FIRST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.FirstName||props.defaultValue?.UserDtls?.FirstName}
                  variant="outlined"
                  {...register("firstName")}
                  error={errors.firstName}
                  className={"formTextBox"}
                  autoFocus
                />
                {errors && errors?.["firstName"] && (
                  <p className="errorMessage">{errors?.firstName?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  LAST NAME<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.LastName||props.defaultValue?.UserDtls?.LastName}
                  {...register("lastName")}
                  variant="outlined"
                  error={errors.lastName}
                  className={"formTextBox"}
                />
                {errors && errors?.["lastName"] && (
                  <p className="errorMessage">{errors?.lastName?.message}</p>
                )}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  EMAIL<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.Email||props.defaultValue?.UserDtls?.Email}
                  {...register("email")}
                  variant="outlined"
                  // onChange={(e) => setValue("designation", e.target.value)}
                  error={errors.email}
                  className={"formTextBox"}
                />
                {errors && errors?.["email"] && (
                  <p className="errorMessage">{errors?.email?.message}</p>
                )}
              </div>

              <div className="col-md-6">
                <InputLabel className="form-label">
                  PHONE NUMBER<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.Phone||props.defaultValue?.UserDtls?.Phone}
                  {...phoneNumberEdit}
                  // {...register('phone')}
                  variant="outlined"
                  value={cellPh}
                  
                  onChange={(e) => {
                    phoneNumberEdit.onChange(e);
                      setCellPh(phoneNumberFormat(e.target.value));
                 
                    
                  }}
                  error={errors.phone}
                  className={"formTextBox"}
                />
                {errors && errors?.["phone"] && (
                  <p className="errorMessage">{errors?.phone?.message}</p>
                )}
              </div>

              <h5 className="add-sub-head">Associated Agent</h5>
              <div className="col-md-6">
                <InputLabel className="form-label">AGENT<sup>*</sup></InputLabel>
                {user?.data?.Role === "agent" ? (
                  <Select
                    variant="outlined"
                    {...register("Agent")}
                    error={errors.Agent}
                    className={"formTextBox"}
                    defaultValue={props.defaultValue?.Agent?props.defaultValue?.Agent:props.defaultValue?.AgentDtls?._id?props.defaultValue?.AgentDtls?._id:user?.data?._id}
                    disabled
                  >
                    {props.defaultValue||user?.data?._id?
                    activeAgentLists &&
                      activeAgentLists.map((item) => (
                        <MenuItem value={item._id}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      )):agentQueue &&
                      agentQueue.map((item) => (
                        <MenuItem value={item.userId}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                //   <Autocomplete
                //   id="demo-simple-select-outlined"
                //   options={agentName?.sort(sortLabels) ?? []}
                //   //options={activeAgentLists && activeAgentLists?.map((option) => option._id)}
                //   inputValue={agent}
                //   label="Agent"
                //   //freeSolo
                //   getOptionLabel={(option) =>
                //     option?.FirstName?.charAt(0).toUpperCase() +
                //     option?.FirstName?.slice(1).toLowerCase() +
                //     " " +
                //     option?.LastName?.charAt(0).toUpperCase() +
                //     option?.LastName?.slice(1).toLowerCase()
                //   }
                //   className="w-100 fullWidthSearch"
                //   defaultValue={props.defaultValue?.Agent||props.defaultValue?.AgentDtls?._id}
                //   renderInput={(params) => (
                //     <TextField {...params} variant="outlined" />
                //   )}
                //   //value={renderdetails?.Email}
                //   //value={error.suggest}
                //   onChange={(e, value) => {
                //     setRenderdetails(value);
                //     // setEmailError()
                //   }}
                //   onInputChange={(e, value) => {
                //     setAgent(value);
                //   }}
                // />
                  <Select
                    variant="outlined"
                    {...register("Agent")}
                    error={errors.Agent}
                    className={"formTextBox"}
                    defaultValue={props.defaultValue?.Agent||props.defaultValue?.AgentDtls?._id}
                  >
                    {props.defaultValue?activeAgentLists &&
                      activeAgentLists.map((item) => (
                        <MenuItem value={item._id}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      )):agentQueue &&
                      agentQueue.map((item) => (
                        <MenuItem value={item.userId}>
                          {item.FirstName + " " + item.LastName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                {errors && errors?.["Agent"] && (
                  <p className="errorMessage">{errors?.Agent?.message}</p>
                )}
              </div>
            </div>

            <div className="addAgentButton">
              <Button className="outline-btn" onClick={() => handleClose()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                {/* <Button
                                    className="save-draft-btn"
                                    type="submit"
                                    onClick={() => {
                                        setDraftFlag(true);
                                    }}>
                                    {draftFlag && loading ? (
                                        <CircularProgress size="20px" color="inherit" />
                                    ) : (
                                        "Save as Draft?"
                                    )}
                                </Button> */}
                <Button
                  type="submit"
                  className="highlight-btn"
                  onClick={() => {
                    setDraftFlag(false);
                  }}
                >
                  {!draftFlag && loading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
