import React from "react";
import { DialogBox } from "../../Common";
import { useMutation } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import { UPDATE_PROPERTY_STATUS } from "../../../Graghql/mutations";
import { toggleSnackbar, userDeatils } from "../../../RactiveVariables";

const Sold = (props) => {
  const user = useReactiveVar(userDeatils);
  const [isSold] = useMutation(UPDATE_PROPERTY_STATUS);

  const handlesold = () => {
    try {
      isSold({
        variables: { PropertyId: props.propertyId, ActionType: "Sold" },
      })
        .then((res) => {
          props && props.handlePagination();
          user?.data?.Role === "admin" || user?.data?.Role === "agentAdmin"
            ? toggleSnackbar({
                status: true,
                message: "Property marked as Sold",
                variant: "success",
              })
            : toggleSnackbar({
                status: true,
                message: "Sold Request has been sent to Admin",
                variant: "success",
              });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div>
      <DialogBox
        open={props.open}
        setOpen={props.setOpen}
        handlePositiveBtn={handlesold}
        title={`Sold #PID ${props.pid} ?`}
        text={"Do you want to sold this Property ?"}
      />
    </div>
  );
};

export default Sold;
