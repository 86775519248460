import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Chip, Divider } from "@material-ui/core";
import moment from "moment";

export default function AdminPropertyListing(props) {
  const [moreComment, setMoreComment] = useState(false);
  const { property } = props;
  function formatePrice(value) {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function convertTimeStampWithTime(date) {
    // return moment(new Date(Number(date))).format("MMM DD,hh:mm");
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function formateTaxMap(value) {
    if (value?.Value1&&value?.Value2&&value?.Value3&&value?.Value4&&value?.Value5&&value?.Value6&&value?.Value7)
      return `${value?.Value1}/${value?.Value2}/${value?.Value3}/${value?.Value4}/${value?.Value5}/${value?.Value6}/${value?.Value7}`;
  }
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  function TexttView(props) {
    const [visible, setVisible] = useState(false);
    function formateText(text) {
      if (!visible) {
        if (text.length > 100) return text.slice(0, 100) + " ...";
        else return text.slice(0, 100);
      } else return text;
    }
    if (!props.text) return null;
    return (
      <p style={{ textOverflow: "ellipsis" }}>
        {formateText(props.text)}
        {props.text.length > 100 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={() => setVisible(!visible)}>
            {visible ? " Less" : " More"}
          </a>
        )}
      </p>
    );
  }
  function chechForPrice(priceArray) {
    let position = priceArray.findIndex(
      (element) =>
        element.AvblStatus.toLowerCase() === "yes" ||
        element.AvblStatus.toLowerCase() === "rented"||element.AvblStatus.toLowerCase() === "no"
    );
    if (position === -1) return false;
    else return true;
  }
  const expirydate =  
  // typeof property.RRExp == "object"
  // ? moment(property.RRExp).format("MM/DD/YYYY")
  // : moment(new Date(parseInt(property.RRExp))).format(
  //     "MM/DD/YYYY"
  //   )
   
     property.RRExp && property.RRExp.length>13? moment(property.RRExp).format("MM/DD/YYYY"): moment.unix(property.RRExp/1000).format("MM/DD/YYYY");
  return (
    <div className="adminListing">
      <div className="adminListingItem">
        <div className="al-itemImg-initialDetails">
          <div className="row">
            <div className="col-md-3">
              <div className="al-itemImg">
                <img
                  style={{cursor: "pointer"}}
                  onClick={()=>  window.open(property?.PropertySlug, "_blank")
               
                  //history.push(`/${property?.PropertySlug}`)
                }
                  src={getFeaturedImage(property?.Images)}
                  className="img-fluid"
                  alt=""
                />
                {property?.IsPrivate&&<label className="exclusive-label">{property?.IsPrivate===true? "Exclusive": null}</label>}
                <div>
                  {property.Status==="inactive"?"":<Checkbox
                    disabled={
                      property.Status === "Delete" ||
                      property.Status === "Sold" ||
                      property.Status === "Off the Market"
                      ||
                      property.Status==="inactive"
                      ||
                      property.Status==null
                    }
                     color="secondary"
                    size="small"
                    checked={props?.selectedId?.includes(property._id)}
                    onChange={(e) =>
                      props.handleChecked(property, e.target.checked)
                    }
                  />}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="al-initialDetails">
                <div className="al-head">
                  <div>
                    {property?.IsFeatured===true&&<div className="adminFeaturedTag">Featured</div>}
                    <address>
                      PID#{property.PID} {property.StreetAddress}{","} {property?.Area}
                   
                      {/* {property.City}, {property.Area} */}
                    </address>
                    <h3>{property?.AdTitle}
                      {/* {property?.AdTitle?.charAt(0).toUpperCase() +
                        property?.AdTitle?.slice(1).toLowerCase()} */}
                    </h3>
                  
                    {property?.PriceData?.SalesPrice &&
                    property?.PriceData?.SalesPrice !== 0 ? (
                      <h6>
                        SalePrice:{" "}
                        <font>
                          ${formatePrice(property?.PriceData?.SalesPrice)}
                        </font>
                      </h6>
                    ) : null}
                  </div>
                  <div className="al-head-btn">
                   {property?.PendingFlag===true&& <Button
                     color="primary"
                     variant="contained"
                     className="highlight-btn ms-0"
                    >
                      Pending Approval
                    </Button>}
                    {property?.Status && (
                      <Button
                        //type="submit"
                        color="sucess"
                        variant="contained"
                        className="green-btn"
                      >
                        {(property?.Status!=="Delete"&&property?.Status!=="Off the Market")
                          ? property?.Status?.charAt(0).toUpperCase() +
                            property?.Status?.slice(1).toLowerCase()
                          :property?.Status==="Delete"?"Deleted":property?.Status==="Off the Market"?"OTM/Inactive": ""}
                      </Button>
                    )}
                    <Button
                      style={{ backgroundColor: "#000" }}
                      onClick={(e) =>
                        props.handleMenuChange(
                          "MENU",
                          property,
                          e.currentTarget
                        )
                      }
                    >
                      <img
                        src="../assets/images/dot-group.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                    </Button>
                  </div>
                </div>
                {property?.AdDescription && (
                       <TexttView
                       text={
                        property?.AdDescription?.charAt(
                           0
                         ).toUpperCase() +
                         property?.AdDescription?.slice(
                           1
                         ).toLowerCase()
                       }
                     />
                  // <p>
                  //   {property?.AdDescription.charAt(0).toUpperCase() +
                  //     property?.AdDescription.slice(1).toLowerCase()}
                  // </p>
                )}
                {property?.ListMainAmenity && (
                  <ul className="al-style propertyStyleTag">
                    {property?.ListMainAmenity &&
                      property?.ListMainAmenity.map(
                        (item) => item?.Flag === true && <li>{item?.Title}</li>
                      )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="al-aminities-price">
          <ul>
            {property.ListBedRoom && (
              <li>
                <label>Bed:</label>
                {property.ListBedRoom}
              </li>
            )}
            {property.ListBathRoom && (
              <li>
                <label>Bath:</label>
                {property.ListBathRoom}
              </li>
            )}
            {property.ListStyle && (
              <li>
                <label>Style:</label>
                {property.ListStyle}
              </li>
            )}
            {property.LivingArea && (
              <li>
                <label>Living Area:</label>
                {property.LivingArea}
              </li>
            )}
            {property.YearBuilt && (
              <li>
                <label>Year Build:</label>
                {property.YearBuilt}
              </li>
            )}
            {property.ListHeat && (
              <li>
                <label>Heat:</label>
                {property.ListHeat}
              </li>
            )}
            {property.ListGarage && (
              <li>
                <label>Garage:</label>
                {property.ListGarage}
              </li>
            )}
            {property.ListBasement && (
              <li>
                <label>Basement:</label>
                {property.ListBasement}
              </li>
            )}
            {property.ListAirCondition && (
              <li>
                <label>A/C:</label>
                {property.ListAirCondition}
              </li>
            )}
            {property.LotSize && (
              <li>
                <label>Lot Size:</label>
                {property.LotSize}
              </li>
            )}
            {property.BedBathConfiguration && (
              <li>
                <label>Bed Bath Configuration:</label>
                {property.BedBathConfiguration}
              </li>
            )}
             {property.ListPool && (
              <li>
                <label>Pool:</label>
                {property.ListPool}
              </li>
            )}
            {property.ListStory && (
              <li>
                <label>Stories:</label>
                {property.ListStory}
              </li>
            )}
            {formateTaxMap(property.SuffCoTaxMap) && (
              <li>
                <label>TaxMap:</label>
                {formateTaxMap(property.SuffCoTaxMap)}
              </li>
            )}
            {property.RentalRegisteryId && (
              <li>
                <label>RRN ID:</label>
                {property.RentalRegisteryId}
              </li>
            )}
            {property.RRExp && property.RRExp!==""&&(
              <li>
                <label>RR Expires on:</label>
                {expirydate}
              </li>
            )}
            {property.ListAgentJustification && (
              <li>
                <label>Agent Justification:</label>
                {property.ListAgentJustification}
              </li>
            )}
            {property.ListKey && (
              <li>
                <label>Key:</label>
                {property.ListKey}
              </li>
            )}
            {property.KeyLocation && (
              <li>
                <label>Key Location:</label>
                {property.KeyLocation}
              </li>
            )}
          </ul>

          {property?.PriceData?.PriceDetails &&
            chechForPrice(property?.PriceData?.PriceDetails) && (
              <ul>
                {property?.PriceData?.PriceDetails &&
                  property?.PriceData?.PriceDetails.map((item) => {
                    if (
                      item.AvblStatus?.toLowerCase() === "yes" ||
                      item.AvblStatus?.toLowerCase() === "rented"||item.AvblStatus.toLowerCase() === "no"
                    ){
                      return (
                        <li>
                          <label>
                            <img
                              src={
                                item.AvblStatus?.toLowerCase() === "yes"
                                  ? "../assets/images/green-check.svg"
                                  :item.AvblStatus?.toLowerCase() === "rented"? "../assets/images/red-check.svg":"../assets/images/red-cross.svg"
                              }
                              className="img-fluid"
                              alt="Tick"
                            />{" "}
                            {item.RentalPeriod}
                          </label>
                          ${formatePrice(item.Price)}
                        </li>
                      );}
                      return null
                  })}
              </ul>
            )}
        </div>
        {}
        {property?.PriceData?.ShortTermPriceDetails &&
          property?.PriceData?.ShortTermPriceDetails.length > 0 && (
            <div>
              <br />
              <h5>Short Term Values (Priced Weekly)</h5>
              <div style={{ paddingBottom: "20px" }}>
                {property?.PriceData?.ShortTermPriceDetails.map((item) => {
                  return (
                    <Chip
                      className="me-2"
                      label={`${convertTimeStamp(
                        item.DateFrom
                      )} - ${convertTimeStamp(item.DateTo)} $${item.Amount}`}
                    />
                  );
                })}
              </div>
              <Divider />
            </div>
          )}
        <div className="al-owner-agent-details">
          <div className="row">
            <div className="col-md-3">
              {property?.OwnerDtls?.FirstName && (
                <h6>
                  {`${property?.OwnerDtls?.FirstName} ${property?.OwnerDtls?.LastName}`}
                  <span>(OWNER)</span>
                </h6>
              )}
              <ul>
                {/* {property?.OwnerDtls?.Phone && <li>
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  {property?.OwnerDtls?.Phone}
                </li>} */}
                {property?.OwnerDtls?.HomePhone && (
                  <li>
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    H-{property?.OwnerDtls?.HomePhone}
                  </li>
                )}
                {property?.OwnerDtls?.OfficePhone && (
                  <li>
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    O-{property?.OwnerDtls?.OfficePhone}
                  </li>
                )}
                {property?.OwnerDtls?.CellPhone && (
                  <li>
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    C-{property?.OwnerDtls?.CellPhone}
                  </li>
                )}
                {property?.OwnerDtls?.LocalPhone && (
                  <li>
                    <img
                      src="../assets/images/phone.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    L-{property?.OwnerDtls?.LocalPhone}
                  </li>
                )}
                {/* <li>
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  H-718-423-9889
                </li> */}
                {property?.OwnerDtls?.Email && (
                  <li>
                    <img
                      src="../assets/images/email.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    {property?.OwnerDtls?.Email}
                  </li>
                )}
              </ul>
            </div>
            {property?.AgentDtls && (
              <div className="col-md-3">
                {property?.AgentDtls?.FirstName && (
                  <h6>
                    {`${property?.AgentDtls?.FirstName} ${property?.AgentDtls?.LastName}`}
                    <span>(PROPERTY AGENT)</span>
                  </h6>
                )}
                <ul>
                  {/* {property?.AgentDtls?.Phone && (
                    <li>
                      <img
                        src="../assets/images/phone.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      {property?.AgentDtls?.Phone}
                    </li>
                  )} */}
                  {property?.AgentDtls?.HomePhone && (
                    <li>
                      <img
                        src="../assets/images/phone.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      H-{property?.AgentDtls?.HomePhone}
                    </li>
                  )}
                  {property?.AgentDtls?.OfficePhone && (
                    <li>
                      <img
                        src="../assets/images/phone.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      O-{property?.AgentDtls?.OfficePhone}
                    </li>
                  )}
                  {property?.AgentDtls?.Phone && (
                    <li>
                      <img
                        src="../assets/images/phone.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      C-{property?.AgentDtls?.Phone}
                    </li>
                  )}
                  {property?.AgentDtls?.LocalPhone && (
                    <li>
                      <img
                        src="../assets/images/phone.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      L-{property?.AgentDtls?.LocalPhone}
                    </li>
                  )}
                  {/* <li>
                  <img
                    src="../assets/images/phone.svg"
                    className="img-fluid"
                    alt="Icon"
                  />
                  H-718-423-9889
                </li> */}
                  {property?.AgentDtls?.Email && (
                    <li>
                      <img
                        src="../assets/images/email.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                      {property?.AgentDtls?.Email}
                    </li>
                  )}
                  <li>(Agent Input Listing)</li>
                </ul>
              </div>
            )}
            <div className="col-md-3">
              <ul className="al-extra">
                {property.SpecialInfo && (
                  <li>
                    {/* Key Special Info:<label>rjp 3/10/10</label> */}
                    <span>Key Special Info:</span>
                    <label>{property.SpecialInfo}</label>
                  </li>
                )}
                {property.ShowingIns && (
                  <li>
                    <span>SI:</span>
                    <label>{property.ShowingIns}</label>
                  </li>
                )}
                {property.AlaramCode && (
                  <li>
                    <span>Alarm:</span>
                    <label>{property.AlaramCode}</label>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <hr />
        {property?.Comments &&
        <div className="al-comments">
          <ul>
            {property?.Comments &&
              [...property?.Comments].map((item, index) => {
                if (!moreComment && index > 1) return null;
                return (
                  <li>
                    {item.Comment},{" "}
                    <b>
                      - {item.UserName}{" "}
                      {convertTimeStampWithTime(item.CreatedOn)}
                    </b>
                  </li>
                );
              })}
          </ul>
          {property?.Comments && property?.Comments.length > 2 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="view-comment"
              onClick={() => setMoreComment(!moreComment)}
            >
              {moreComment ? "View less Comments" : "View more Comments"}
            </a>
          )}
        </div>}
        <div className="al-dates">
          <ul>
            {property.CreatedOn && (
              <li>
                Listing Date :&nbsp;
                <b>{convertTimeStamp(property.CreatedOn)}</b>
              </li>
            )}
            {property.UpdatedOn && (
              <li>
                Last Update :&nbsp;<b>{convertTimeStamp(property.UpdatedOn)}</b>
              </li>
            )}
            {property?.UpdatedByDtls?.FirstName && (
              <li>
                Last Update by :&nbsp;
                <b>
                  {property?.UpdatedByDtls?.FirstName.charAt(0).toUpperCase() +
                    property?.UpdatedByDtls?.FirstName.slice(
                      1
                    ).toLowerCase()}{" "}
                  {property?.UpdatedByDtls?.LastName.charAt(0).toUpperCase() +
                    property?.UpdatedByDtls?.LastName.slice(1).toLowerCase()}
                </b>
              </li>
            )}
            <li>
              Status :&nbsp;<b>{property?.Status
                          && property?.Status?.charAt(0).toUpperCase() +
                            property?.Status?.slice(1).toLowerCase()}</b>
            </li>
           {(property?.AgreementPdfUrl===""||property?.AgreementPdfUrl==null)&& <li>
              Listing Type :&nbsp;<b>Need Listing agreement</b>
            </li>}
          </ul>
        </div>
      </div>
    </div>
  );
}
