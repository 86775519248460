import React from "react";
import "../Agent/AddAgent.css";
import { useForm ,Controller} from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Drawer,
  Button,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
  ListItemText
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { ADD_SEARCH, EDIT_SEARCH } from "../../Graghql/mutations";
import {LIST_SAVED_SEARCH} from "../../Graghql/queries"
import { toggleSnackbar, userDeatils } from "../../RactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  areaOptions,
  rentalPeriodOptions,
  bedRoomsOptions,
  bathroomOptions,
  poolOptions,
  tennisOptions,
  petsOptions,
  waterfrontOptions,
} from "../Properties/addProperty/options";
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// eslint-disable-next-line no-unused-vars
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function AddSavedSearch(props) {
  // console.log("p>>>>>>>>",props)
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  const user = useReactiveVar(userDeatils);
  // eslint-disable-next-line no-unused-vars
  const [waterfront, setWaterfront] = React.useState([]);

  const [addSavedSearch, { loading }] = useMutation(ADD_SEARCH,{refetchQueries:[{query:LIST_SAVED_SEARCH}]});
  // eslint-disable-next-line no-unused-vars
  const [editSavedSearch, { loading: editSavedSearchLoading }] =
    useMutation(EDIT_SEARCH);

  React.useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);
  React.useEffect(() => {
    //setCellPh(props.defaultValue?.Phone);
  }, [props.defaultValue]);
console.log("props.defaultValue",props.defaultValue)
  // const schema = yup.object().shape({
    // firstName: yup
    //   .string()
    //   .required("First Name is required")
    //   .matches(/^[a-zA-Z]+$/, "Must be characters"),
    // lastName: yup
    //   .string()
    //   .required("Last Name is required")
    //   .matches(/^[a-zA-Z]+$/, "Must be characters"),
    // Keyword: yup.string().required("Keyword is required"),
    // phone: yup
    //   .string()
    //   .required("Phone Number is required")
    //   .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
    // //   .test("phone", "Must be a number", (value) => {
    // //     if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
    // //     else return true;
    // // }),
    // Agent: yup.string().required("Agent is required"),
  // });
  const schema = yup.object().shape({
    Keyword: yup.string().required('Keyword is required'),
    //Waterfront:yup.array().required('Waterfront is required'),
  });
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
  } = useForm({
    defaultValues: props.defaultValue,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setWaterfront(event.target.value);
  };
  //console.log("props.defaultValue----", props.defaultValue);
  const onSubmit = (params) => {
    if (props.defaultValue === null) {
      try {
        params["SavedType"] = "keyword";
        params["Area"]=params?.Town
        params["Type"] = "add";
        params["ListBathRoom"]= parseFloat(params["ListBathRoom"])
        params["ListBedRoom"] = parseFloat(params["ListBedRoom"])
        addSavedSearch({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "success",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      try {
        params["_id"] = props?.defaultValue?._id;
        params["SavedType"] = "keyword";
        params["Type"] = "edit";
        params["UserId"]=user?.data?._id
        params["ListBathRoom"]= parseFloat(params["ListBathRoom"])
        params["ListBedRoom"] = parseFloat(params["ListBedRoom"])
        addSavedSearch({ variables: params })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "success",
              variant: "success",
            });
            props.handlePagination();
            props.setOpen(false);
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }
  };

  function toggleDrawer() {
    //reset();
    props.setOpen(!props.open);
  }

  const handleClose = () => {
    props.setDefaultValue(null);
    toggleDrawer();
  };
  // eslint-disable-next-line no-unused-vars
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  console.log("test",props.defaultValue);
  return (
    <div>
      <Drawer anchor={"right"} open={props.open} onClose={handleClose}>
        <div role="presentation" className="addAgent">
          <h1 className={"addAgentHead"}>Add Trending</h1>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <InputLabel className="form-label">
                  KEYWORD<sup>*</sup>
                </InputLabel>
                <TextField
                  defaultValue={props.defaultValue?.Keyword}
                  variant="outlined"
                  {...register("Keyword")}
                  className={"formTextBox"}
                  autoFocus
                />
                {errors.Keyword && (
                    <p className="errorMessage">{errors.Keyword.message}</p>
                  )}
                {/* <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.Keyword
                    // ? props.defaultValue?.Keyword
                    // : "Any Keyword"
                  }
                  //MenuProps={{ classes: { paper: classes.menuPaper } }}
                  {...register("Keyword")}
                >
                  <MenuItem value="Any Keyword">{"Any Keyword"}</MenuItem>
                  {keywordOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select> */}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  TOWN
                </InputLabel>
                {/* <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.Town
                    ? props.defaultValue?.Town
                    : "Any Area"
                  }
                  {...register("Town")}
                  InputProps={{ disableUnderline: true }}
                >
                  <MenuItem value="Any Area">{"Which Area?"}</MenuItem>
                  {areaOptions?.sort(sortLabels).map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select> */}
                <Controller
                      control={control}
                      name="Town"
                      defaultValue={
                        props.defaultValue===null||props.defaultValue?.Town===null? []:props.defaultValue.Town
                      }
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", props.defaultValue)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            // variant="outlined"
                            className={"formTextBox"}
                            // defaultValue={
                            //   props.defaultValue===null||props.defaultValue?.ListWater[0]===""? "WaterFront":props.defaultValue.ListWater[0]
                            // }
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected[0] === "" ? "Town" : `Town (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Town</MenuItem>
                            {/* <MenuItem key={0} disabled value={"Any WaterFront"}>Any WaterFront</MenuItem> */}
                            {areaOptions?.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  PERIOD
                </InputLabel>
                {/* <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.Period
                    ? props.defaultValue?.Period
                    : "Any Periods"
                  }
                  {...register("Period")}
                >
                  <MenuItem value="Any Periods">{"Any Periods"}</MenuItem>
                  {rentalPeriodOptions.map((item) => (
                    <MenuItem value={item.RentalPeriod}>
                      {item.RentalPeriod}
                    </MenuItem>
                  ))}
                </Select> */}
                <Controller
                      control={control}
                      name="Period"
                      defaultValue={
                        props.defaultValue===null||props.defaultValue?.Period===null? []:props.defaultValue.Period
                      }
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", props.defaultValue)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            // variant="outlined"
                            className={"formTextBox"}
                            // defaultValue={
                            //   props.defaultValue===null||props.defaultValue?.ListWater[0]===""? "WaterFront":props.defaultValue.ListWater[0]
                            // }
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected[0] === "" ? "Period" : `Period (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>Period</MenuItem>
                            {/* <MenuItem key={0} disabled value={"Any WaterFront"}>Any WaterFront</MenuItem> */}
                            {rentalPeriodOptions?.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.RentalPeriod} value={item.RentalPeriod}>
                                <Checkbox color="primary" checked={value?.indexOf(item.RentalPeriod) > -1} />
                                <ListItemText primary={item.RentalPeriod} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  BED
                </InputLabel>
                <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.ListBedRoom
                    ? props.defaultValue?.ListBedRoom
                    : "Any Beds"
                  }
                  {...register("ListBedRoom")}
                >
                  <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                  {bedRoomsOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select>
                {/* {errors && errors?.["firstName"] && (
                  <p className="errorMessage">{errors?.firstName?.message}</p>
                )} */}
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  BATH
                </InputLabel>
                <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.ListBathRoom
                    ? props.defaultValue?.ListBathRoom
                    : "Any Baths"
                  }
                  {...register("ListBathRoom")}
                >
                  <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                  {bathroomOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  POOL
                </InputLabel>
                <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.ListPool
                    ? props.defaultValue?.ListPool
                    : "Any Pools"
                  }
                  {...register("Pool")}
                >
                  <MenuItem value="Any Pools">{"Any Pools"}</MenuItem>
                  {poolOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  TENNIS
                </InputLabel>
                <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.ListTennis
                    ? props.defaultValue?.ListTennis
                    : "Any Tennis"
                  }
                  {...register("Tennis")}
                >
                  <MenuItem value="Any Tennis">{"Any Tennis"}</MenuItem>
                  {tennisOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  PETS
                </InputLabel>
                <Select
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue?.ListPet
                    ? props.defaultValue?.ListPet
                    : "Any Pets"
                  }
                  {...register("Pets")}
                >
                  <MenuItem value="Any Pets">{"Any Pets"}</MenuItem>
                  {petsOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select>
              </div>
              <div className="col-md-6">
                <InputLabel className="form-label">
                  WATERFRONT
                </InputLabel>
                {/* <Select
                // multiple
                  variant="outlined"
                  className={"formTextBox"}
                  defaultValue={
                    props.defaultValue===null||props.defaultValue?.ListWater[0]===""? "Any WaterFront":props.defaultValue.ListWater[0]
                  }
                  {...register("Waterfront")}
                >
                  <MenuItem value="Any WaterFront">{"Any WaterFront"}</MenuItem>
                  {waterfrontOptions.map((item) => (
                    <MenuItem value={item.label}>{item.value}</MenuItem>
                  ))}
                </Select> */}
                <Controller
                      control={control}
                      name="Waterfront"
                      // defaultValue={
                      //   props.defaultValue===null||props.defaultValue?.ListWater[0]===""? null:props.defaultValue?.ListWater
                      // }
                      defaultValue={
                        props.defaultValue===null? null:props.defaultValue?.ListWater
                      }
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", props.defaultValue)
                        return (
                          <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            // variant="outlined"
                            className={"formTextBox"}
                            // defaultValue={
                            //   props.defaultValue===null||props.defaultValue?.ListWater[0]===""? "WaterFront":props.defaultValue.ListWater[0]
                            // }
                            value={value && value?.length > 0 ? value : [""]}
                            onChange={(e) => onChange(e.target.value[0] === "" ? [e.target.value[1]] : e.target.value)}
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected[0] === "" ? "WaterFront" : `WaterFront (${selected.length} selected)`}
                            MenuProps={MenuProps}
                          >
                            <MenuItem key={0} disabled value={""}>WaterFront</MenuItem>
                            {/* <MenuItem key={0} disabled value={"Any WaterFront"}>Any WaterFront</MenuItem> */}
                            {waterfrontOptions?.map((item) => (
                              <MenuItem className="advance-filter-listItem" key={item.value} value={item.value}>
                                <Checkbox color="primary" checked={value?.indexOf(item.value) > -1} />
                                <ListItemText primary={item.value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )
                      }}
                    />
                {errors.Waterfront && (
                    <p className="errorMessage">{errors.Waterfront.message}</p>
                  )}
              </div>
            </div>

            <div className="addAgentButton">
              <Button className="outline-btn" onClick={() => handleClose()}>
                Cancel
              </Button>
              <div className="d-flex align-items-center">
                <Button type="submit" className="highlight-btn">
                  {loading || editSavedSearchLoading ? (
                    <CircularProgress size="20px" color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}
