import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, InputLabel,CircularProgress } from "@material-ui/core";
import FrontendHeader from "../Common/frontendHeader";
import Footer from "../Common/footer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation,  } from "@apollo/client";
import { ADD_CONTACT_US } from "../../Graghql/mutations";
import { toggleSnackbar } from '../../RactiveVariables/index';
import {Link} from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";

const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    //.matches(/^[a-zA-Z]+$/, 'Must be characters'),
    lastName: yup.string().required("Last Name is required"),
    //.matches(/^[a-zA-Z]+$/, 'Must be characters'),
    email: yup.string().required("Email is required").email(),
    phone: yup.string().required("Phone is required").matches(/^\d{3}-\d{3}-\d{4}$/, 'Must be 10 digits'),
   message:yup.string().required("Message is required"),
  });

const Contact = (props) => {
  // console.log("pr12",props)
  
    const [phone, setPhone] = useState("");
    const [captchaValue, setCaptchaValue] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  const [addContactUs, { loading }] = useMutation(ADD_CONTACT_US);

  const phoneNumberFormat = (num) => {
    // let newNum = num.replace(/[-]+/g, "");
    let newNum = num.replace(/[^0-9]/g, '');
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
    } else {
      x =
        newNum.slice(0, 3) +
        "-" +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  }
  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaValue(value)
    //if (value === null) setCaptchaValue(false);
  }
  const onSubmit = (data) => {
    //console.log("dataaaa", data);
if(captchaValue){
    try {
      addContactUs({ variables: data })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Your enquiry has been sent",
            variant: "success",
          });
          setPhone("");
          reset();
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }}else{
      toggleSnackbar({
        status: true,
        message: "Invalid",
        variant: "error",
      });
    }
  };
  const phoneNumberRegister = register("phone", { required: true });
  return (
    <div>
      <FrontendHeader  message contact search list location={props.location.pathname}/>
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="contact-address-bg"
                style={{
                  backgroundImage:
                    "url(../assets/images/contact-address-bg.jpg)",
                }}
              >
                <div className="contact-address-box">
                  <img
                    src="../assets/images/logo.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                  <address>
                    15 Railroad Avenue - Suite 3
                    <br />
                    East Hampton, NY 11937
                    <br />
                    <Link to='#'onClick={(e) => {window.location ="tel:631-324-0009"
                    e.preventDefault();
                    }}> O) 631-324-0009</Link>
                    <br />
                    <Link to='#'onClick={(e) => {window.location ="tel:631-907-3309"
                    e.preventDefault();
                    }}> F) 631-907-3309</Link>
                    <br />
                  </address>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <h4>Questions or comments?</h4>
                <label className="sub-line">
                  We are happy to be of service to you, so just let us know!
                </label>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="formTextBoxOuter">
                      <InputLabel className="form-label">FIRST NAME <sup>*</sup></InputLabel>
                      <TextField
                        variant="outlined"
                        className="formTextBox w-100"
                        autoFocus {...register('firstName', { required: true })}
                      />
                      {errors.firstName && <span className="errorMessage">{errors.firstName.message}</span>}
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="formTextBoxOuter">
                      <InputLabel className="form-label">LAST NAME <sup>*</sup></InputLabel>
                      <TextField
                        variant="outlined"
                        className="formTextBox w-100"
                         {...register('lastName', { required: true })}
                      />
                      {errors.lastName && <span className="errorMessage">{errors.lastName.message}</span>}
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="formTextBoxOuter">
                      <InputLabel className="form-label">EMAIL <sup>*</sup></InputLabel>
                      <TextField
                        variant="outlined"
                        className="formTextBox w-100"
                        {...register('email', { required: true })}
                      />
                       {errors.email && <span className="errorMessage">{errors.email.message}</span>}
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="formTextBoxOuter">
                      <InputLabel className="form-label">PHONE <sup>*</sup></InputLabel>
                      <TextField
                        variant="outlined"
                        className="formTextBox w-100"
                        value={phone}
                        {...phoneNumberRegister}
                        onChange={(e) => {
                          if(e.target.value.length <= 12){
                            phoneNumberRegister.onChange(e);
                            setPhone(phoneNumberFormat(e.target.value));
                          }
                        }}
                      />
                      {errors.phone && <span className="errorMessage">{errors.phone.message}</span>}
                    </div>
                    </div>
                    <div className="col-md-12">
                    <div className="formTextBoxOuter">
                      <InputLabel className="form-label">MESSAGE <sup>*</sup></InputLabel>
                      <TextField
                        variant="outlined"
                        className="formTextBox w-100"
                        {...register('message', { required: true })}
                      />
                       {errors.message && <span className="errorMessage">{errors.message.message}</span>}
                    </div>
                    </div>
                    <ReCAPTCHA
                       sitekey = {process.env.REACT_APP_SITE_KEY}
                       onChange={onChange}
                    />
                    <div className="col-12 mt-3">
                      <Button
                        color="primary"
                        className="highlight-btn w-100"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                       {loading ? <CircularProgress size="20px" color="inherit"/> : 'Send'} 
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map">
        {/* <img src="../assets/images/map.jpg" className="img-fluid" alt="map"/> */}
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.75629608643!2d-72.19384938466465!3d40.96491733004105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e8bb02ff5dfcf3%3A0x225bfebae3b19183!2s3%20Railroad%20Ave%2C%20East%20Hampton%2C%20NY%2011937%2C%20USA!5e0!3m2!1sen!2sin!4v1632476288162!5m2!1sen!2sin"></iframe>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
