import React, { useState } from "react"
import SideBar from "../Common/sidebar"
import LoggedInHeader from "../Common/loggedinHeader"
import { useQuery, useMutation } from "@apollo/client";
import { CONTACT_LISTING } from "../../Graghql/queries"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox"
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField"
import { makeStyles } from '@material-ui/core/styles';
import { toggleSnackbar } from "../../RactiveVariables/index";
import { DELETE_CONTACT } from "../../Graghql/mutations";
import { DialogBox } from "../Common";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AssignToLead from "./AssignToLead";
const useStyles = makeStyles({
    list: {
        width: 150,
    },
    fullList: {
        width: 200,
    },
});
const options = [
    { label: "View", id: "VIEW" },
    { label: "Assign To Lead", id: "AssignedTo" },
    // { label: "None", id: "None" }, //remove when adding a new menu item
  ];
const ContactListing = () => {
    const classes = useStyles();
    const [itemDetails, setItemDetails] = useState([]);
    const [openMessage, setOpenMessage] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState("")
    const [, setIsPaginating] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState([]);
    const [deleteVar, setDeleteVar] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleAssign, setVisibleAssign] = useState(false);
    const [viewMessage, setViewMessage] = useState(false);

    const contactVariables = {
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        search: searchKeyword
    }
    const { data, loading: contactLoading, fetchMore } = useQuery(CONTACT_LISTING, {
        fetchPolicy: "network-only",
        variables: contactVariables,
        onCompleted: (res) => {
            console.log("res", res)
        }
    })
   // console.log("dataaa", data)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setIsPaginating(true);
    };
    const [deleteContact] = useMutation(DELETE_CONTACT)
    // eslint-disable-next-line no-unused-vars
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        setIsPaginating(true);
    };
    const handlePagination = () => {
        fetchMore({
            variables: contactVariables,
        });
    };

    // eslint-disable-next-line no-unused-vars
    const handleClick = (item) => {

        // setAnchorEl(event.currentTarget);
        setOpenMessage(item?.Message)
        // console.log("eee",event)
        console.log("item", item)

    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMessage(null)
    };

    const open = Boolean(anchorEl);
    // eslint-disable-next-line no-unused-vars
    const id = open ? 'simple-popover' : undefined;

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setViewMessage(open)
    };


    React.useEffect(() => {
        handlePagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchKeyword]);
    const contactCount = data?.listContact?.count
    function handleDelete() {
        try {
            deleteContact({
                variables: {
                    contactIDs: selectedItem
                },
            })
                .then((res) => {
                    toggleSnackbar({
                        status: true,
                        message: "Contact successfully deleted",
                        variant: "success",
                    });
                    handlePagination();
                    setSelectedItem([]);
                })
                .catch((error) => {
                    const message = error && error.message;
                    toggleSnackbar({
                        status: true,
                        message: message,
                        variant: "error",
                    });
                });
        } catch (error) {
            const message = error && error.message;
            toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
            });
        }
    }
    const handleCheckboxChange = (item, value) => {
        let arr1 = JSON.stringify(selectedItem);
        let arr = JSON.parse(arr1);
        if (value === true) {
            arr.push(item.contactID);
        } else {
            let index = arr.indexOf(item.contactID);
            arr.splice(index, 1);
        }
        setSelectedItem(arr);
    };


    // eslint-disable-next-line no-unused-vars
    const list = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >


        </div>
    );
    const handleMenuChange = (type, item, target,element) => {
        // console.log("itemss-->",item)
        item && setItemDetails(item);
        item && item.Message && setOpenMessage(item?.Message)
        setAnchorEl(true);
        // eslint-disable-next-line default-case
        switch (type) {
          case "MENU":
            setAnchorEl(target);
            break;
         
          case "VIEW":
            setViewMessage(true)
            item && item.Message &&setOpenMessage(item?.Message)
            // handleClick(item)
            setAnchorEl(false);
            break;
          case "AssignedTo":
             // handleAssigned(itemDetails)
             // setAssignToLead(true)
              setVisibleAssign(true);
            setAnchorEl(false);
            break;
        }
      };
      const phoneNumberFormat = (num) => {
       if(num.length===10)
        return num.slice(0, 3) +
        "-" +
        num.slice(3, 6) +
        "-" +
        num.slice(6, 10);
        else{
          return num.slice(0, 3) +
        "-" +
        num.slice(3, 6) +
        "-" +
        num.slice(6, );
        }
       
      }
    return (
        <div className="custom-layout">
              <AssignToLead
        open={visibleAssign}
        setOpen={setVisibleAssign}
        firstName={itemDetails.FirstName}
        lastName={itemDetails.LastName}
        email={itemDetails.Email}
        phone={itemDetails.Phone}
        message={itemDetails.Message}
        //handlePagination={props?.handlePagination}
      />
            <LoggedInHeader
                label="Contact Us Listing"
                handleDelete={() => {
                    if (selectedItem.length > 0 || deleteVar != null) setVisible(true);
                    else {
                        toggleSnackbar({
                            status: true,
                            message: "Please select a Contact",
                            variant: "error",
                        });
                    }
                }}
            />
            {/* <LoggedInHeader label="Contact Us Listing" /> */}
           <DialogBox
                open={visible}
                setOpen={setVisible}
                setDeleteVar={setDeleteVar}
                handlePositiveBtn={handleDelete}
                title={"Delete?"}
                text={
                    selectedItem.length === 1 || deleteVar == null
                        ? "Are you sure you want to delete this Enquiry?"
                        : "Are you sure you want to delete this Enquiries?"
                }
            // positiveText={"Delete"}
            // negativeText={"Cancel"}
            />

            <div className="custom-layout-content">
                <div className="search-band">
                {/* <DialogBox
                open={visibleAssign}
                setOpen={setVisibleAssign}
                setDeleteVar={setDeleteVar}
                handlePositiveBtn={handleAssigned(itemDetails)}
                title={"Assign To Lead?"}
                text={ "Are you sure you want to Assign To Lead?"
                }
            // positiveText={"Delete"}
            // negativeText={"Cancel"}
            /> */}
                    <TextField
                        id="standard-full-width"
                        className="fullWidthSearch"
                        label="SEARCH"
                        disableUnderline={false}
                        placeholder="Name, Email, Phone"
                        type="search"
                        onChange={(event) => setSearchKeyword(event.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                            disableUnderline: false,
                        }}
                    />
                </div>
                <div className="content-wrap">
                    <SideBar selected="contact-listing" />
                    <div>
                        <Table
                            className="common-table lead-table"
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell className="text-left">Last Name</TableCell>
                                    <TableCell className="text-left">Email</TableCell>
                                    <TableCell className="text-left">Last Received</TableCell>
                                    <TableCell className="text-left">Phone</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table-body">
                                {contactLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <p> Loading Contact Us...</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : data?.listContact?.data.length? ( data?.listContact?.data.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer' }}>
                                        <TableCell component="th" scope="row">
                                        <FormControlLabel
                                                className="table-checkbox"
                                                control={
                                                    <Checkbox
                                                    disabled={item?.IsLead===true}
                                                        checked={selectedItem.includes(item.contactID)}
                                                        onChange={(event) =>
                                                            handleCheckboxChange(
                                                                item,
                                                                event.target.checked
                                                            )
                                                        }
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                            {item?.FirstName?.charAt(0).toUpperCase() + item?.FirstName?.slice(1).toLowerCase()}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {item?.LastName === null ? "" : item?.LastName?.charAt(0).toUpperCase() + item?.LastName?.slice(1).toLowerCase()}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {item?.Email}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {item?.CreatedOn===null?"-":moment(
                                  new Date(parseInt(item?.CreatedOn))
                                ).format("lll")}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {item?.Phone?phoneNumberFormat(item?.Phone):"--"}
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                            {/* <Button
                                            variant="outlined"
                                            style={{ fontSize: '10px', color: '#a4a4a4' }}
                    //   style={{ backgroundColor: "#000" }}
                      onClick={(e) =>
                        handleMenuChange(
                          "MENU",
                          item,
                          e.currentTarget
                        )
                      }
                    >
                      <img
                        src="../assets/images/dot-group.svg"
                        className="img-fluid"
                        alt="Icon"
                      />
                    </Button> */}
                      <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuChange("MENU", item, e.currentTarget)
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>
                                                {/* <Button variant="outlined" onClick={(e) => { setViewMessage(true); handleClick(item) }} style={{ fontSize: '10px', color: '#a4a4a4' }}>View</Button> */}
                                                {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>handleClick(e,item)}>
                                                    View
                                                </Button> */}

                                            </div>
                                        </TableCell>
                                    

                                    </TableRow>
                                )))

                                    : searchKeyword ? (
                                        <TableRow>
                                          <TableCell colSpan={6}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <p>No Matching Contact Found</p>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={6}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <p>No Contact Added Yet!</p>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            <div>

                                <React.Fragment >

                                    <Drawer className="contact-listing-drawer" anchor={"right"} open={viewMessage} onClose={toggleDrawer(false)}>
                                        <h3>Messages <IconButton aria-label="Close"><CloseIcon  fontSize="small" color='secondary' onClick={toggleDrawer(false)}/></IconButton ></h3>
                                        <div className="contact-listing-drawer-cnt">
                                            
                                            {openMessage?.map((item, index) => (
                                                <p key={index}>{item?.Message}  
                                                <div className="mt-2" >{moment(
                                                    new Date(parseInt(item?.CreatedOn))
                                                  ).format("lll")}</div></p>

                                            ))}
                                        </div>
                                    </Drawer>
                                </React.Fragment>

                            </div>
                            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {options.map((option, index) => {
                  return (
                 
                    <MenuItem
                      key={index}
                      disabled={itemDetails?.IsLead===true&&option.label==="Assign To Lead"
                      }
                    onClick={() => handleMenuChange(option.id)}
                    >
                      {option.label}
                    </MenuItem>
                
                  );
                })}
              </Menu>
                            {/* <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "5px" }}
          >
            <div
              style={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.43)",
                borderRadius: "4px",
              }}
            >
              <div className="al-action-popup">
                {options.map((option, index) => {
                  return (
                    <>
                      <Button
                        // disabled={
                        //   !option.disabled && itemDetails.Status != "approved"
                        // }
                        style={
                          option.label === "None" && option.id === "None"
                            ? { opacity: "0", cursor: "auto" }
                            : null
                        }
                        onClick={() => handleMenuChange(option.id)}
                        variant="outlined"
                        color="primary"
                      >
                        {option.label}
                      </Button>
                    </>
                  );
                })}
              </div>
            </div>
          </Popover> */}
                            {/* <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {openMessage?.map(item=>(
                                    <Typography >{item?.Message}</Typography>
                                ))}
                            </Popover> */}

                        </Table>
                        {!contactLoading && (
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={contactCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactListing