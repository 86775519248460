import React from "react";
import { DialogBox } from "../../Common";
import { useMutation } from "@apollo/client";
import { FEATURED_PPTY } from "../../../Graghql/mutations";
import { toggleSnackbar } from "../../../RactiveVariables";
const Featured = (props) => {
  //console.log(",,,,,,,,,,,", props?.propertyId);
  const [featured] = useMutation(
    FEATURED_PPTY, {
      refetchQueries: ["propertySearch"]
    }
  );

  const handleFeatured = () => {
    try {
        featured({
        variables: {
            propertyIds: [props.propertyId],
        },
      })
        .then((res) => {
          //props?.handlePagination();
          // eslint-disable-next-line no-lone-blocks
          {props?.propertyStatus === true? toggleSnackbar({
            status: true,
            message: "Property UnFeatured",
            variant: "success",
          }):
          toggleSnackbar({
            status: true,
            message: "Property Featured",
            variant: "success",
          })
        }
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };


  return (
    <div>
      {props?.propertyStatus === true? (
        <DialogBox
          open={props.open}
          setOpen={props.setOpen}
          handlePositiveBtn={handleFeatured}
          title={`UnFeature Property - #PID ${props.pid}`}
          text={"Are you sure want to make property UnFeature?"}
        />
      ) : (
        <DialogBox
          open={props.open}
          setOpen={props.setOpen}
          handlePositiveBtn={handleFeatured}
          title={`Feature Property - #PID ${props.pid}`}
          text={"Are you sure want to make property Feature?"}
        />
      )}
    </div>
  );
};

export default Featured;
