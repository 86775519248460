import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
export default function DialogBox(props) {
  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <div className="d-flex justify-content-between">
        <DialogTitle className="pb-0">{props?.title}</DialogTitle>
        {props.negativeBtn === true ? (
          <CloseIcon
            className="modal-close-btn"
            onClick={() => props.setOpen(false)}
          />
        ) : (
          ""
        )}
      </div>

      <DialogContent className="">
        <DialogContentText>{props?.text}</DialogContentText>
        <DialogActions
          className="d-flex justify-content-between pe-0 ps-0"
          style={{ marginBottom: "14px" }}
        >
          <Button
            onClick={() =>
              props.negativeBtn === true
                ? props.handleNegativeBtn()
                : props.setOpen(false)
            }
            className="outline-btn"
          >
            {props?.negativeText ? props?.negativeText : "No"}
          </Button>

          <Button
            className="highlight-btn"
            onClick={() => {
              props.handlePositiveBtn();
              props.setOpen(false);
              props.setDeleteVar && props.setDeleteVar(null);
            }}
            color="primary"
            autoFocus
          >
            {props?.positiveText ? props?.positiveText : "Yes"}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
