import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { LEAD_ACTIVITY_ADD } from "../../../Graghql/mutations";
import { LEAD_ACTIVITY_LIST, LEADS_DETAIL } from "../../../Graghql/queries";
import { toggleSnackbar } from "../../../RactiveVariables/index";

import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";

import { useParams } from "react-router-dom";

import { IconButton, InputAdornment } from "@material-ui/core";


// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "5px",
  },
}));
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 50) return text.slice(0, 50) + " ...";
      else return text.slice(0, 50);
    } else return text+' ';
  }
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 50 && (
        <label style={{cursor:'pointer'}} onClick={() => setVisible(!visible)}>
          {visible ? " See Less" : " See More"}
        </label>
      )}
    </div>
  );
}
const LeadAgentNote = (props) => {
  const { id } = useParams();
  const [note, setNote] = useState("");
  const [moreComments, setMoreComments] = useState(false);

  const leadActivityListVariables = {
    skip: 0,
    limit: 10,
    tabType: "AgentComment",
    leadId: id,
  };

  const { data } = useQuery(LEAD_ACTIVITY_LIST, {
    fetchPolicy: "network-only",
    variables: leadActivityListVariables,
  });
  let notesData = data && data.leadActivityList && data.leadActivityList.data;
  // console.log("----->>>>>", notesData)
  const [leadActivityAdd] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["leadActivityList"],
  });
  const handleChange = (event) => {
    setNote(event.target.value);
  };
  const {
    data: leadDetails,
    //loading
  } = useQuery(LEADS_DETAIL, {
    fetchPolicy: "network-only",
    variables: { leadId: id },
  });
  //  console.log("*****",leadDetails)
  const handleNotes = () => {
    if (note.length > 0) {
      try {
        leadActivityAdd({
          variables: {
            UserId: leadDetails.leadsDetails.UserId,
            LeadSrc: "LeadPage",
            LeadType: "AgentComment",
            // RootId : "0",
            Comment: note,
          },
        })
          .then((res) => {
            toggleSnackbar({
              message: "Notes Successfully Submitted",
              status: true,
              variant: "success",
            });
            setNote("");
          })
          .catch((error) => {
            const message = error && error.message;
            toggleSnackbar({
              message: message,
              status: true,
              variant: "error",
            });
          });
      } catch (error) {
        const message = error && error.message;
        toggleSnackbar({
          message: message,
          status: true,
          variant: "error",
        });
      }
    } else {
      toggleSnackbar({
        message: "Add a note",
        status: true,
        variant: "error",
      });
    }
  };
  return (
    <div className="col-md-8">
      <div className="private-agent-note">
        <div className="lp-commets">
          <div className="private-agent-detail d-flex align-items-center">
            <Avatar src={leadDetails?.leadsDetails?.UserDtls?.ProfileImage}>
              {leadDetails?.leadsDetails?.UserDtls?.FirstName.charAt(0).toUpperCase()+
              leadDetails?.leadsDetails?.UserDtls?.LastName.charAt(0).toUpperCase()}
            </Avatar>
            <div className="ms-2">
              <h4>
                {leadDetails?.leadsDetails?.UserDtls?.FirstName&&leadDetails?.leadsDetails?.UserDtls?.FirstName.toUpperCase()}
                 { " " }
                  {leadDetails?.leadsDetails?.UserDtls?.LastName&&leadDetails?.leadsDetails?.UserDtls?.LastName!== null &&leadDetails?.leadsDetails?.UserDtls?.LastName.toUpperCase()}
              </h4>
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center me-2">
                  <img
                    src="../assets/images/icon-calendar.svg"
                    className="me-1 img-fluid"
                    alt="Calendar Icon"
                  />
                  {moment(
                    new Date(parseInt(leadDetails?.leadsDetails?.UpdatedOn))
                  ).format("ll")}
                </span>
                <span className="d-flex align-items-center">
                  <img
                    src="../assets/images/icon-clock.svg"
                    className="me-1 img-fluid"
                    alt="Clock Icon"
                  />
                  {moment(
                    new Date(parseInt(leadDetails?.leadsDetails?.UpdatedOn))
                  ).format("LT")}
                </span>
              </div>
            </div>
          </div>
          <div className="lp-comment-box">
            <TextField
              variant="outlined"
              className="formTextBox"
              placeholder="Add a note"
              onKeyPress={(e) => e.key === "Enter" && handleNotes()}
              onChange={handleChange}
              value={note}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        handleNotes();
                      }}
                    >
                      <img
                        src="../assets/images/icon-send.svg"
                        className="img-fluid"
                        alt="Send"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {notesData &&
            notesData.map((items, index) => {
              if (!moreComments && index > 1) return null;
              return (
                <div key={index} className="lp-comment-list">
                  <div className="lp-comment-list-item">
                    <Avatar
                      style={{ backgroundColor: "#0C4946" }}
                      src={items?.AddedByDtls?.ProfileImage}
                    >
                      {items?.AddedByDtls?.FirstName.charAt(0).toUpperCase()+items?.AddedByDtls?.LastName.charAt(0).toUpperCase()}
                    </Avatar>

                    <Typography paragraph>
                      <TexttView text={items.Comment} />
                      <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center me-2">
                          <img
                            src="../assets/images/icon-calendar.svg"
                            className="me-1 img-fluid"
                            alt="Calendar Icon"
                          />
                          {moment(new Date(parseInt(items.CreatedOn))).format(
                            "ll"
                          )}
                        </span>
                        <span className="d-flex align-items-center">
                          <img
                            src="../assets/images/icon-clock.svg"
                            className="me-1 img-fluid"
                            alt="Clock Icon"
                          />
                          {moment(new Date(parseInt(items.CreatedOn))).format(
                            "LT"
                          )}
                        </span>
                      </div>
                    </Typography>
                  </div>
                </div>
              );
            })}
          {notesData && notesData?.length > 2 && (
            <label
              style={{cursor:'pointer'}}
              onClick={() => setMoreComments(!moreComments)}
              className="view-comment"
            >
              {moreComments ? "view Less" : "view More"}
            </label>
          )}
        </div>
      </div>

    </div>
    
  );
};

export default LeadAgentNote;
