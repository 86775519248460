import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_SAVED_SEARCH } from "../../Graghql/queries";
import { DELETE_SAVED_SEARCH } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import SideBar from "../Common/sidebar";
import LoggedInHeader from "../Common/loggedinHeader";
import { DialogBox } from "../Common";
import Avatar from "@material-ui/core/Avatar";
import AddSavedSearch from "./AddSavedSearch";
import { Link } from "react-router-dom";

const options = [{ label: "Edit", id: "EDIT" }];
const SavedSearch = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteVar, setDeleteVar] = React.useState(null);
  const open = Boolean(anchorEl);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isPaginating, setIsPaginating] = React.useState(false);
  const [openAddSavedSearch, setOpenAddSavedSearch] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState(null);
  const [view, setView] = useState("keywordSearch");

  const savedSearchListVariables = {
    type: "list",
    skip: page * rowsPerPage,
    limit: rowsPerPage,
  };

  const {
    data,
    loading: savedSearchLoading,
    fetchMore,
  } = useQuery(LIST_SAVED_SEARCH, {
    fetchPolicy: "network-only",
    variables: savedSearchListVariables,
  });
  const keywordSearchListVariables = {
    type: "Keyword",
    skip: page * rowsPerPage,
    limit: rowsPerPage,
  };

  const { data: keywordSearchData, loading: keywordSearchLoading } = useQuery(
    LIST_SAVED_SEARCH,
    {
      fetchPolicy: "network-only",
      variables: keywordSearchListVariables,
    }
  );
  const handlePagination = () => {
    fetchMore({
      variables: keywordSearchListVariables,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  React.useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [deleteSavedSearch] = useMutation(DELETE_SAVED_SEARCH);

  // eslint-disable-next-line no-unused-vars
  let savedSearchListCount =
    data && data.savedSearchList && data.savedSearchList.count;
  let savedSearchList =
    data && data.savedSearchList && data.savedSearchList.data;

  let keywordSearchListCount =
    keywordSearchData &&
    keywordSearchData.savedSearchList &&
    keywordSearchData.savedSearchList.count;
  let keywordSearchList =
    keywordSearchData &&
    keywordSearchData.savedSearchList &&
    keywordSearchData.savedSearchList.data;
  //console.log("savedSearchList", savedSearchList, savedSearchListCount);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCheckboxChange = (item, value) => {
    let arr1 = JSON.stringify(selectedItem);
    let arr = JSON.parse(arr1);
    if (value === true) {
      arr.push(item._id);
    } else {
      let index = arr.indexOf(item._id);
      arr.splice(index, 1);
    }
    setSelectedItem(arr);
  };
  const handleMenuChange = (type, item, target, element) => {
    item && setDefaultValue(item);
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "EDIT":
        setAnchorEl(target);
        break;
      case "Edit":
        setAnchorEl(null);
        setOpenAddSavedSearch(true);
        break;
    }
  };
  function handleDelete() {
    try {
      deleteSavedSearch({
        variables: {
          searchIds: deleteVar === null ? selectedItem : null,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Trending successfully deleted",
            variant: "success",
          });
          handlePagination();
          setSelectedItem([]);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleAddBtnClick(params) {
    setOpenAddSavedSearch(true);
    setDefaultValue(null);
  }
  // function formatKeyword(item) {
  // return(
  //         area: obj?.Area,
  //         price: obj?.PriceRange === "" ? null : obj?.PriceRange.replace(/ /g, ''),
  //         period: obj?.RentalPeriod,
  //         bed: obj?.ListBedRoom,
  //         bath: obj?.ListBathRoom,
  // )
  // }
  return (
    <div className="custom-layout">
      <AddSavedSearch
        handlePagination={handlePagination}
        open={openAddSavedSearch}
        setOpen={setOpenAddSavedSearch}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
      <LoggedInHeader
        handleAddBtnClick={handleAddBtnClick}
        label="Trending"
        addBtnLabel="Add Trending"
        handleDelete={() => {
          if (selectedItem.length > 0 || deleteVar != null) setVisible(true);
          else {
            toggleSnackbar({
              status: true,
              message: "Please select Saved Search",
              variant: "error",
            });
          }
        }}
      />
      <div className="custom-layout-content">
        <DialogBox
          open={visible}
          setOpen={setVisible}
          setDeleteVar={setDeleteVar}
          handlePositiveBtn={handleDelete}
          title={"Delete?"}
          text={
            selectedItem.length === 1 || deleteVar == null
              ? "Are you sure you want to delete this Trending?"
              : "Are you sure you want to delete this Trendings?"
          }
          // positiveText={"Delete"}
          // negativeText={"Cancel"}
        />
        <div className="content-wrap saved-search">
          <SideBar selected="trending" />
          <div class="search-band lead-tab saved-search-tab">
            <ul>
            <li
                className={view === "keywordSearch" ? "active" : null}
                onClick={() => setView("keywordSearch")}
              >
                <Link to="#">Keyword Search</Link>
              </li>
              {/* <li
                className={view === "savedSearch" ? "active" : null}
                onClick={() => setView("savedSearch")}
              >
                <a>Saved Search</a>
              </li> */}
            
            </ul>
            
          </div>
          <div className="row">
            {view === "savedSearch" && (
              <div className="col-md-12">
                <div>
                  <Table
                    className="common-table"
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Search Criteria Name</TableCell>
                        <TableCell className="text-left">
                          Created Date
                        </TableCell>
                        <TableCell className="text-left">Created</TableCell>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {
                        savedSearchLoading ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p> Loading Saved Searches...</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : savedSearchList && savedSearchList.length ? (
                          savedSearchList.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <FormControlLabel
                                  className="table-checkbox"
                                  control={
                                    <Checkbox
                                      checked={selectedItem.includes(item._id)}
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          item,
                                          event.target.checked
                                        )
                                      }
                                      name="checkedB"
                                      color="primary"
                                    />
                                  }
                                />
                                <label>
                                  <Link
                                    className="saved-search-link"
                                    to={{
                                      pathname: "/properties",
                                      searchObj: {
                                        area: item?.Area,
                                        price:
                                          item?.PriceRange === "" ||
                                          item?.PriceRange === undefined
                                            ? null
                                            : item?.PriceRange.replace(
                                                / /g,
                                                ""
                                              ),
                                        period: item?.Period,
                                        bed: parseFloat(item?.ListBedRoom),
                                        bath: parseFloat(item?.ListBathRoom),
                                      },
                                    }}
                                  >
                                    {moment(
                                      new Date(parseInt(item.CreatedOn))
                                    ).format("lll")}
                                    {/* {item.Keyword && item.Keyword + "-"}
                                  {item.ListBedRoom && item.ListBedRoom + "-"}
                                  {item.ListBathRoom && item.ListBathRoom + "-"}
                                  {item.RentalPeriod && item.RentalPeriod + "-"}
                                  {item.Town && item.Town + "-"}
                                  {item.Pool && item.Pool + "-"}
                                  {item.Tennis && item.Tennis + "-"}
                                  {item.Pets && item.Pets + "-"}
                                  {item.Waterfront && item.Waterfront + "-"} */}
                                  </Link>
                                </label>
                              </TableCell>
                              <TableCell>
                                {moment(
                                  new Date(parseInt(item.CreatedOn))
                                ).format("L")}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                <div className="d-flex align-items-center">
                                  {item?.UserDtlsNew==null?"Unauthorized User":<>
                                  <Avatar
                                    className="me-1"
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      fontSize: "14px",
                                    }}
                                    src={item?.UserDtlsNew?.ProfileImage}
                                  >
                                    {item?.UserDtlsNew?.FirstName.charAt(0).toUpperCase()+item?.UserDtlsNew?.LastName.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <label>
                                    {" "}
                                    {item?.UserDtlsNew?.FirstName.charAt(
                                      0
                                    ).toUpperCase() +
                                      item?.UserDtlsNew?.FirstName.slice(
                                        1
                                      ).toLowerCase() +
                                      " " +
                                      item?.UserDtlsNew?.LastName.charAt(
                                        0
                                      ).toUpperCase() +
                                      item?.UserDtlsNew?.LastName.slice(
                                        1
                                      ).toLowerCase()}
                                  </label></>}
                                </div>
                              </TableCell>

                              {/* <TableCell>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) =>
                                    handleMenuChange(
                                      "EDIT",
                                      item,
                                      e.currentTarget
                                    )
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              </TableCell> */}
                            </TableRow>
                          ))
                        ) : null
                        // <TableRow>
                        //   <TableCell colSpan={6}>
                        //     <div
                        //       style={{
                        //         display: "flex",
                        //         justifyContent: "center",
                        //       }}
                        //     >
                        //       <p>No Saved Searches Yet!</p>
                        //     </div>
                        //   </TableCell>
                        // </TableRow>
                      }
                    </TableBody>
                  </Table>
                  {/* {!savedSearchLoading && (
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={savedSearchListCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )} */}
                </div>
                {/* <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMenuChange(option.label)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu> */}
              </div>
            )}
            {view === "keywordSearch" && (
              <div className="col-md-12">
                <div>
                  <Table
                    className="common-table"
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Search Criteria Name</TableCell>
                        <TableCell className="text-left">
                          Created Date
                        </TableCell>
                        <TableCell className="text-left">Created</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {
                        keywordSearchLoading ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p> Loading Keyword Searches...</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : keywordSearchList && keywordSearchList.length ? (
                          keywordSearchList.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <FormControlLabel
                                  className="table-checkbox"
                                  control={
                                    <Checkbox
                                      checked={selectedItem.includes(item._id)}
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          item,
                                          event.target.checked
                                        )
                                      }
                                      name="checkedB"
                                      color="primary"
                                    />
                                  }
                                />
                                <label>    
                                  <Link
                                    className="saved-search-link"
                                    to={{
                                      pathname: "/properties",
                                      searchObj: {
                                        area: item?.Area,
                                        price:
                                          item?.PriceRange === "" ||
                                          item?.PriceRange === undefined
                                            ? null
                                            : item?.PriceRange.replace(
                                                / /g,
                                                ""
                                              ),
                                        period: item?.Period,
                                        bed: parseFloat(item?.ListBedRoom),
                                        bath: parseFloat(item?.ListBathRoom),
                                        pool: item?.ListPool,
                                        tennis: item?.ListTennis,
                                        pet: item?.ListPet,
                                        water: item?.ListWater,
                                      },
                                    }}
                                  >
                                  {item.Keyword && item.Keyword }
                                  {/* {item.ListBedRoom && item.ListBedRoom + "-"}
                                  {item.ListBathRoom && item.ListBathRoom + "-"}
                                  {item.RentalPeriod && item.RentalPeriod + "-"}
                                  {item.Town && item.Town + "-"}
                                  {item.Pool && item.Pool + "-"}
                                  {item.Tennis && item.Tennis + "-"}
                                  {item.Pets && item.Pets + "-"}
                                  {item.Waterfront && item.Waterfront + "-"} */}
                                  </Link>
                                </label>
                              </TableCell>
                              <TableCell  className="text-left">
                                {moment(
                                  new Date(parseInt(item.CreatedOn))
                                ).format("L")}
                              </TableCell>

                              <TableCell component="th" scope="row">
                              <div className="d-flex align-items-center">
                              {  item?.UserDtlsNew==null?"Unauthorized User":<>
                                  <Avatar
                                    className="me-1"
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      fontSize: "14px",
                                    }}
                                    src={item?.UserDtlsNew?.ProfileImage}
                                  >
                                    {item?.UserDtlsNew?.FirstName.charAt(0).toUpperCase()+item?.UserDtlsNew?.LastName.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <label>
                                    {" "}
                                    {item?.UserDtlsNew?.FirstName.charAt(
                                      0
                                    ).toUpperCase() +
                                      item?.UserDtlsNew?.FirstName.slice(
                                        1
                                      ).toLowerCase() +
                                      " " +
                                      item?.UserDtlsNew?.LastName.charAt(
                                        0
                                      ).toUpperCase() +
                                      item?.UserDtlsNew?.LastName.slice(
                                        1
                                      ).toLowerCase()}
                                  </label></>}
                                </div>
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) =>
                                    handleMenuChange(
                                      "EDIT",
                                      item,
                                      e.currentTarget
                                    )
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) :  <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Keyword Search Added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                        // <TableRow>
                        //   <TableCell colSpan={6}>
                        //     <div
                        //       style={{
                        //         display: "flex",
                        //         justifyContent: "center",
                        //       }}
                        //     >
                        //       <p>No Saved Searches Yet!</p>
                        //     </div>
                        //   </TableCell>
                        // </TableRow>
                      }
                    </TableBody>
                  </Table>
                  {!keywordSearchLoading &&keywordSearchListCount>0&& (
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={keywordSearchListCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </div>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMenuChange(option.label)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedSearch;
