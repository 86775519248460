import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Route from "./Route";
import Header from "./Modules/Common/header";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import "./Stylesheets/style.css";
import "./Stylesheets/style-secondary.css";
import { useReactiveVar } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { authVar,userDeatils } from "./RactiveVariables/index";
import { GET_CURRENT_USER } from "./Graghql/queries";
import CustomizedSnackbars from "./Modules/Common/customSnackbar";
import CustomizedModal from "./Modules/Common/customizedModal"
import Agent from "./Modules/Agent/index";
import Owner from "./Modules/Owner";
import LeadsList from "./Modules/Leads/LeadsList"

import Renter from "./Modules/Renter"
import Home from "./Modules/Home";
import About from "./Modules/About"
import Terms from "./Modules/Terms-Privacy/terms"
import Policy from "./Modules/Terms-Privacy/policy"
import ContactListing from "./Modules/ContactListing/ContactListing";
import Contact from "./Modules/Contact"
import Team from "./Modules/Team";
import TeamDetails from "./Modules/Team/teamDetails";
import AgentDetails from "./Modules/Team/AgentDetails";
import ResetPassword from "./Modules/Login/ResetPassword";
import Properties from "./Modules/Properties";
import AddProperty from "./Modules/Properties/addProperty";
import PropertyDetail from "./Modules/Properties/propertyActions/PropertyDetail";
import LeadsDetails from "./Modules/Leads/LeadsDetails";
import PropertyFrontUser from "./Modules/Properties/PropertyFrontUser";
import UserActivity from "./Modules/Leads/LeadsDetails/UserActivity";
import UserPortfolio from "./Modules/Leads/LeadsDetails/UserPortfolio";
import SavedSearch from "./Modules/SavedSearch";
import PendingList from "./Modules/Pending";
import PendingAgreement from "./Modules/Pending/pendingAgreement";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import Dashboard  from "./Modules/Dashboard"
import FeaturedList from "./Modules/Featured/FeaturedListing";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A64C48",
      contrastText: "#fff",
      '&:hover': {
       background: "#000",
      },
    },
    secondary: {
      main: "#fff",
      contrastText: "#7f7f7f",
    },
    success: {
      main: "#309E69",
      contrastText: "#FFFFFF",
    },
  },
});

const App = () => {
  const auth = useReactiveVar(authVar);
  const { loading } = useQuery(GET_CURRENT_USER, {
    onCompleted: (res) => {
      userDeatils({
        data: res.currentUserDetails,
      });
      authVar({
        loaded: true,
        auth: true,
        userType:res?.currentUserDetails?.UserType === "HR"  ? "admin" : res?.currentUserDetails?.UserType
      });
    },
    onError: (err) => {
      authVar({
        loaded: true,
        auth: false,
      });
    },
  });
  //-----------------user unique id---------------------------- 
  useEffect(() => {
    let id = localStorage.getItem("userUniqueId");
    if (!id) {
      localStorage.setItem("userUniqueId", uuidv4());
    }
  }, []);
  //-----------------user unique id---------------------------- 

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars />
     
      <CustomizedModal/>
      {!auth.auth &&!loading&& <Header />}
      {auth.loaded &&
      <Router>
        <Switch>
          <Route exact path="/resetPassword/:id" component={ResetPassword} isAuthPage />
          <Route exact path="/login" component={Home} isAuthPage/>
          <Route exact path="/pendingApproval" component={PendingList} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/pendingAgreement" component={PendingAgreement} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/agent" component={Agent} isPrivate type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/owner" component={Owner} isPrivate type={["admin","agent","agentAdmin"]} />
          <Route exact path="/dashboard" component={Dashboard} isPrivate type={["admin","agent","agentAdmin"]} />
          <Route exact path="/renter" component={Renter} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/trending" component={SavedSearch} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/agents" component={Team} />
          <Route exact path="/agents/:id" component={TeamDetails} />
          <Route exact path="/agent-details/:id" component={AgentDetails} />
          <Route exact path="/about" component={About} />
          <Route exact path="/termsOfUse" component={Terms} />
          <Route exact path="/privacyPolicy" component={Policy} />
          {/* <Route exact path="/agreementPdf/:id" component={PropertyDetail} /> */}
          <Route exact path="/contact" component={Contact} /> 
          <Route exact path="/properties" component={Properties} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/featured" component={FeaturedList} isPrivate  type={["admin","agent","agentAdmin"]}/>
          <Route exact path="/addProperty/:id" component={AddProperty} isPrivate type={["admin","owner","renter","agent","agentAdmin"]}/>
          <Route exact path="/addPendingProperty/:id" component={AddProperty} isPrivate type={["admin","owner","renter","agent","agentAdmin"]}/>
          <Route exact path="/leads" component={LeadsList} isPrivate type={["admin","agent","agentAdmin"]} />
          <Route exact path="/lead-detail/:id" component={LeadsDetails} isPrivate type={["admin","owner","renter","agent","agentAdmin"]}  />
          <Route exact path="/lead-portfolio/:id" component={UserPortfolio} isPrivate type={["owner","renter","agent"]}  />
          <Route exact path="/lead-activity/:id" component={UserActivity} isPrivate type={["owner","renter","agent"]}  />
          <Route exact path="/searchlistings/page:page" component={PropertyFrontUser} />
          <Route exact path="/contact-listing" component={ContactListing} isPrivate type={["admin","agent","agentAdmin"]} />
          <Route exact path="/agreement/:id" component={AddProperty} isPrivate type={["admin","owner","renter","agent","agentAdmin"]}/>
          <Route exact path="/:area/page:page" component={PropertyFrontUser} />  
          <Route exact path="/:area/:slug" component={PropertyDetail} />
          <Route component={Home} />
         
         
        </Switch>
      </Router>}
    </ThemeProvider>
  );
};

export default App;
