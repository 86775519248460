import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {useForm} from 'react-hook-form';
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { InputLabel, CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EMAIL_TO_LEAD } from "../../../Graghql/mutations"; 
import { toggleSnackbar } from "../../../RactiveVariables/index";
import { useParams } from "react-router-dom";

export default function LeadProfileEmail(props) {
  const { id } = useParams();
  console.log("props?.recipientId",props?.recipientId)
  const schema = yup.object().shape({
    subject: yup
      .string()
      .required("Subject is required"),
  })
    const {register, handleSubmit,formState} = useForm({
      resolver: yupResolver(schema),
    });
    const { errors } = formState;
    const [emailtoLead,{loading}] = useMutation(EMAIL_TO_LEAD,{refetchQueries:["leadActivityList"]});
   console.log("errors",errors)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: "0px 0px 6px rgba(105, 105, 105, 0.25)",
        // eslint-disable-next-line no-dupe-keys
        boxShadow: 24,
        p: 4,
      };
      function onSubmit(params) {
        params["recipientEmailId"]=props?.data
        params["recipientId"]=props?.recipientId
        params["rootId"]=id
        try {
          emailtoLead({ variables: params })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "Email Successfully Sent",
                variant: "success",
              });
              props.setOpenEmail(false);
            })
            .catch((error) => {
              const message = error && error.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
        } catch (error) {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
  return (
    <Modal
      onClose={() => props.setOpenEmail(false)}
      open={props.openEmail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="printModalWrap"
    >
    <Box sx={style}>
    
    <form onSubmit={handleSubmit(onSubmit)} className="property-comment-box">
      <div>
        <InputLabel className="form-label">To</InputLabel>
        <TextField
          disabled
          className="property-boundary-box pt-0 w-100"
          id="outlined-basic"
          variant="outlined"
          {...register("recipientEmailId")}
          defaultValue={props?.data}
        />
      </div>
      <div style={{position:'relative'}}>
        <InputLabel className="form-label">Subject<sup>*</sup></InputLabel>
        <TextField
          className="property-boundary-box pt-0 w-100"
          id="outlined-basic"
          variant="outlined"
          placeholder="Add your subject here"
          {...register("subject", { required: true })}
        />
         {errors && errors?.["subject"] && (
                  <p className="error-msg lead-profile-mail-error-msg">{errors?.subject?.message}</p>
                )}
      </div>
      <div>
        <InputLabel className="form-label">Message</InputLabel>
        <TextField
          multiline="bool"
          rows={4}
          className="property-boundary-box pt-0 w-100"
          id="outlined-basic"
          variant="outlined"
          placeholder="Add your message here"
          {...register("msg", { required: true })}
        />
      </div>
      <div className="add-comment-btn d-flex justify-content-between">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            props.setOpenEmail(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {loading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : (
          "Send"
          )} 
        </Button>
      </div>
   
    </form>
    
    </Box>
    </Modal>
  );
}
