import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SideBar from "../Common/sidebar";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import LoggedInHeader from "../Common/loggedinHeader";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import {
  areaOptions,
  rentalPeriodOptions,
  bedRoomsOptions,
  bathroomOptions,
} from "../Properties/addProperty/options";
import {
  PENDING_PROPERTY_LIST,
  LEADS_LIST,
  LIST_ALL_AGENT,
} from "../../Graghql/queries";
import { useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import Slider from "@material-ui/core/Slider";
import moment from "moment";
const Agent = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;  
  const {
    data: pendingList,
    loading: pendingLoading,
  } = useQuery(PENDING_PROPERTY_LIST, {
    fetchPolicy: "network-only",
    variables: {
        skip: 0,
        limit: 5,
    },
  });
  const { data: agentList, loading: agentLoading } = useQuery(
    LIST_ALL_AGENT,{ fetchPolicy: "network-only",}
  )
  const {
    data: leadsList,
    loading: leadsLoading,
  } = useQuery(LEADS_LIST, {
    fetchPolicy: "network-only",
    variables: {search: null,
      skip: 0,
      limit: 5,
      agent: "",
      userType: "" },
  });
  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      width: 450,
    },
    margin: {
      margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    menuPaper: {
      maxHeight: 300,
      marginTop: "50px",
      color: "#6E6E6E",
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };
  const followersMarks = [
    {
      value: 1000,
      scaledValue: 1000,
      label: "1k",
    },
    {
      value: 5000,
      scaledValue: 5000,
      label: "5k",
    },
    {
      value: 10000,
      scaledValue: 10000,
      label: "10k",
    },
    {
      value: 25000,
      scaledValue: 25000,
      label: "25k",
    },
    {
      value: 50000,
      scaledValue: 50000,
      label: "50k",
    },
    {
      value: 100000,
      scaledValue: 100000,
      label: "100k+",
    },
  ];

  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 100000) {
      return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  };
  const handleChange = (event, newValue) => {
    let strArray = newValue;
    let strArr = strArray.map(function (e) {
      return e.toString();
    });
    setAmount(strArr);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [amount, setAmount] = useState(["0", "1000000"]);
  const {  handleSubmit, control } = useForm({
    defaultValues:{
      bed:"Any Beds",
      bath:"Any Baths"
    }
  });
  const classes = useStyles();
  function handleSearch(params) {
    let obj = {
      area:params.town,
      perioddashboard: params.rentalPeriod,
      bed: params.bed === "Any Beds" ? "" : params.bed,
      bath: params.bath === "Any Baths" ? "" : params.bath,
      price: amount
    }
    history.push({pathname:"/properties", searchObj:obj})
  }
  return (
    <div className="custom-layout">
      <LoggedInHeader label="Dashboard" />
      <div className="custom-layout-content">
        <div className="content-wrap dashboard-wrap">
          <SideBar selected={"dashboard"} />
          <div className="row">
            <div className="col-lg-6 dashboard-col">
              <div className="dashboard-table-wrap">
                <div className="dashboard-table-title">
                  <h2>
                    <img
                      src="../assets/images/icon-dashboard-leads.svg"
                      className="img-fluid"
                      alt="Icon Leads"
                    />
                    Latest Leads
                  </h2>
                  <Button variant="contained" color="primary" onClick={()=>history.push(`/leads`)}>
                    View All
                  </Button>
                </div>
                <Table className="dashboard-table" aria-label="a dense table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Source</TableCell>
                      <TableCell align="left">Agent Assigned</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {leadsLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Leads...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : leadsList && leadsList.leadsList.data.length > 0 ? (
                      leadsList.leadsList.data.map((item, index) => (
                        <TableRow key={index} style={{cursor:"pointer"}} onClick={()=>history.push(`/lead-detail/${item?._id}`)}>
                          <TableCell>{item?.UserDtls?.FirstName + " " + item?.UserDtls?.LastName}</TableCell>
                          <TableCell>{item.LeadSrc}</TableCell>
                          <TableCell>{item?.AgentDtls?.FirstName + " " + item?.AgentDtls?.LastName}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Leads Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="col-lg-6 dashboard-col">
              <div className="dashboard-table-wrap">
                <div className="dashboard-table-title">
                  <h2>
                    <img
                      src="../assets/images/icon-dashboard-agent.svg"
                      className="img-fluid"
                      alt="Icon Leads"
                    />
                    Agent Queue
                  </h2>
                  <Button variant="contained" color="primary" onClick={()=>history.push(`/agent`)}>
                    View All
                  </Button>
                </div>
                <Table className="dashboard-table" aria-label="a dense table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="left">Agent Name</TableCell>
                      <TableCell align="left">Last Received</TableCell>
                      <TableCell align="left">Lead</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {agentLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading agents...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : agentList && agentList.listAllAgents.data.length ? (
                      agentList.listAllAgents.data.map((item, index) => {
                        if(index>4)
                          return null
                        return(
                        <TableRow key={index}>
                          <TableCell>
                            <div className="d-flex align-items-center">
                              <Avatar
                                alt="James Makrianes"
                                src={item?.ProfileImage}
                                className="dashboard-agent-avatar"
                              >
                                {item?.FirstName.charAt(
                                  0
                                ).toUpperCase()}
                              </Avatar>
                              {item.FirstName + " " + item.LastName}
                            </div>
                          </TableCell>
                          <TableCell>{moment(new Date(parseInt(item.LastLeadRvd.LastLeadDate))).format('ll')}</TableCell>
                          <TableCell>{item.LastLeadRvd.UserFName + " " + item.LastLeadRvd.UserLName}</TableCell>
                        </TableRow>
                      )})
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Leads Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="col-lg-6 dashboard-col">
              <div className="dashboard-table-wrap">
                <div className="dashboard-table-title">
                  <h2>
                    <img
                      src="../assets/images/icon-dashboard-pending.svg"
                      className="img-fluid"
                      alt="Icon Leads"
                    />
                    Pending Review
                  </h2>
                  <Button variant="contained" color="primary" onClick={()=>history.push(`/pendingApproval`)}>
                    View All
                  </Button>
                </div>
                <Table className="dashboard-table" aria-label="a dense table">
                  <TableHead className="table-head">
                    <TableRow >
                      <TableCell align="left">PID #</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="left">Location</TableCell>
                      <TableCell align="left">Owner</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {pendingLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading pending list...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : pendingList && pendingList.pendingPropertiesList?.data.length ? (
                      pendingList.pendingPropertiesList?.data.map((item, index) => (
                        <TableRow key={index}  style={{cursor:"pointer"}} onClick={()=>history.push(`/pendingApproval`)}>
                          <TableCell>{`#${item.PID}`}</TableCell>
                          <TableCell align="center">
                            <Avatar
                              alt="Property"
                              src={item.PendingImages.length >0 ? item.PendingImages[0].FileUrl : "../assets/images/no-image.jpg"}
                              className="dashboard-property-image"
                            />
                          </TableCell>
                          <TableCell>
                            {`${item.Area}, ${item.City}, ${item.State}`}
                          </TableCell>
                          <TableCell>{`${item.OwnerDtls.FirstName} ${item.OwnerDtls.LastName}`}</TableCell>
                          <TableCell>{item.Status.charAt(0).toUpperCase() + item.Status.slice(1)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No pending review!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="col-lg-6 dashboard-col">
              <div className="dashboard-table-wrap">
                <div className="dashboard-table-title">
                  <h2>
                    <img
                      src="../assets/images/icon-dashboard-search.svg"
                      className="img-fluid"
                      alt="Icon Leads"
                    />
                    Property Search
                  </h2>
                  <Button variant="contained" color="primary" onClick={()=>history.push(`/properties`)}>
                    View All
                  </Button>
                  {/* <a href="/properties" className="dashboard-view-all">
                    View All
                  </a> */}
                </div>
                <div className="dashboad-search">
                  <form onSubmit={handleSubmit(handleSearch)}>
                  <div className="dashboad-search-item">
                      <FormControl>
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Location
                        </InputLabel>
                        <Controller
                          control={control}
                          name="town"
                          render={({ field: { onChange, value } }) => {
                            // console.log("vl", value)
                            return (
                              <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                // variant="outlined"
                                value={value && value?.length > 0 ? value : [""]}
                                onChange={(e) =>
                                  onChange(
                                    e.target.value[0] === ""
                                      ? [e.target.value[1]]
                                      : e.target.value
                                  )
                                }
                                // input={<Input />}
                                renderValue={(selected) =>
                                  selected[0] === ""
                                    ? "Location"
                                    : ` Location (${selected.length} selected)`
                                }
                                MenuProps={MenuProps}
                              >
                                <MenuItem key={0} disabled value={""}>
                                  Which area?
                                </MenuItem>
                                {areaOptions.map((item) => (
                                  <MenuItem
                                    className="advance-filter-listItem"
                                    key={item.value}
                                    value={item.value}
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        value?.indexOf(item.value) > -1
                                      }
                                    />
                                    <ListItemText primary={item.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="dashboad-search-item">
                      <FormControl>
                        <InputLabel id="demo-mutiple-checkbox-label">
                          PERIOD
                        </InputLabel>
                        <Controller
                          control={control}
                          name="rentalPeriod"
                          render={({ field: { onChange, value } }) => {
                            // console.log("vl", value)
                            return (
                              <Select
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                // variant="outlined"
                                value={value && value?.length > 0 ? value : [""]}
                                onChange={(e) =>
                                  onChange(
                                    e.target.value[0] === ""
                                      ? [e.target.value[1]]
                                      : e.target.value
                                  )
                                }
                                // input={<Input />}
                                renderValue={(selected) =>
                                  selected[0] === ""
                                    ? "Periods"
                                    : ` Periods (${selected.length} selected)`
                                }
                                MenuProps={MenuProps}
                              >
                                <MenuItem key={0} disabled value={""}>
                                  Periods
                                </MenuItem>
                                {rentalPeriodOptions.map((item) => (
                                  <MenuItem
                                    className="advance-filter-listItem"
                                    key={item.RentalPeriod}
                                    value={item.RentalPeriod}
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        value?.indexOf(item.RentalPeriod) > -1
                                      }
                                    />
                                    <ListItemText primary={item.RentalPeriod} />
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className="dashboad-search-item dashboard-price-range">
                      <FormControl>
                        <div
                          className="price-filter-box-outer"
                          aria-describedby={id}
                          variant="contained"
                          onClick={handleClick}
                        >
                          <b
                            style={{ cursor: "pointer" }}
                            className="price-filter-label"
                          >
                            PRICE
                          </b>
                          <div
                            style={{ cursor: "pointer" }}
                            className="price-filter-box"
                          >
                            {amount[0] === "0" && amount[1] === "1000000"
                              ? "Any Price "
                              // eslint-disable-next-line no-useless-concat
                              : "$" + amount[0] + " - " + "$" + amount[1]}
                            <ArrowDropDownIcon />
                          </div>
                        </div>
                        <Popover
                          className="price-filter"
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorPosition={{ top: 2000, left: 400 }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Typography className={classes.typography}>
                            <div className={classes.root}>
                              <Typography id="range-slider" gutterBottom>
                                <CloseIcon
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "460px",
                                    marginTop: "5px",
                                  }}
                                  onClick={() => handleClose()}
                                />
                                Price range
                              </Typography>
                              <Slider
                                value={amount}
                                step={1}
                                valueLabelFormat={numFormatter}
                                marks={followersMarks}
                                // scale={scale}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                max={100000}
                                min={0}
                              />
                            </div>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                value={amount[0]}
                                placeholder="Min"
                                onChange={(e) => {
                                  setAmount([e.target.value, amount[1]]);
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                labelWidth={30}
                              />
                            </FormControl>
                            <FormControl
                              fullWidth
                              className={classes.margin}
                              variant="outlined"
                            >
                              {/* <InputLabel >Max</InputLabel> */}
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                placeholder="Max"
                                value={amount[1]}
                                onChange={(e) => {
                                  setAmount([amount[0], e.target.value]);
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                labelWidth={30}
                              />
                            </FormControl>
                          </div>
                        </Popover>
                      </FormControl>
                    </div>
                    <div  className="dashboad-search-item">
                      <FormControl>
                        <InputLabel>BED</InputLabel>
                        <Controller
                          control={control}
                          name="bed"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              >
                                <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                                {bedRoomsOptions.map((item) => (
                                  <MenuItem value={item.label}>
                                    <img
                                      src="../assets/images/icon-bed.svg"
                                      style={{ height: "12px" }}
                                      className="img-fluid me-2 sb-icon"
                                      alt="Icon"
                                    />
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                    <div  className="dashboad-search-item">
                      <FormControl>
                        <InputLabel>BATH</InputLabel>
                        <Controller
                          control={control}
                          name="bath"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              >
                                <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                                {bathroomOptions.map((item) => (
                                  <MenuItem value={item.label}>
                                    <img
                                      src="../assets/images/icon-bath.svg"
                                      style={{ height: "12px" }}
                                      className="img-fluid me-2 sb-icon"
                                      alt="Icon"
                                    />
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </FormControl>
                    </div>
                    <Button color="primary" type="submit" variant="contained" className="dashboard-search-btn">SEARCH</Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agent;
