import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Chip,
  Divider,
  FormControl,
  TextField,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { PENDING_PROPERTY_AGREEMENT_LIST } from "../../Graghql/queries";
import { APPROVE_DISAPPROVE_PPTY } from "../../Graghql/mutations";
import LoggedInHeader from "../Common/loggedinHeader";
import Loader from "../Common/Loader";
import SideBar from "../Common/sidebar";
import { toggleSnackbar } from "../../RactiveVariables/index";
import Locations from "../Properties/Locations";
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
  typography: {
    padding: theme.spacing(2),
  },

  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 450,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// eslint-disable-next-line no-unused-vars
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 100) return text.slice(0, 100) + " ...";
      else return text.slice(0, 100);
    } else return text;
  }
  if (!props.text) return null;
  return (
    <div style={{ textOverflow: "ellipsis" }}>
      {formateText(props.text)}
      {props.text.length > 100 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => setVisible(!visible)}>
          {visible ? " Less" : " More"}
        </a>
      )}
    </div>
  );
}
// eslint-disable-next-line no-unused-vars
function valuetext(value) {
  return value;
}
const followersMarks = [
  {
    value: 1000,
    scaledValue: 1000,
    label: "1k"
  },
  {
    value: 5000,
    scaledValue: 5000,
    label: "5k"
  },
  {
    value: 10000,
    scaledValue: 10000,
    label: "10k"
  },
  {
    value: 25000,
    scaledValue: 25000,
    label: "25k"
  },
  {
    value: 50000,
    scaledValue: 50000,
    label: "50k"
  },
  {
    value: 100000,
    scaledValue: 100000,
    label: "100k+"
  },
  // {
  //   value: 250000,
  //   scaledValue: 250000,
  //   label: "250k"
  // },
  // {
  //   value: 500000,
  //   scaledValue: 500000,
  //   label: "500k"
  // },
  // {
  //   value: 1000000,
  //   scaledValue: 1000000,
  //   label: "1M+"
  // }
];
// eslint-disable-next-line no-unused-vars
const scale = value => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

// function numFormatter(num) {
//   if (num > 999 && num < 1000000) {
//     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
//   } else if (num >= 1000000) {
//     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
//   }else if (num < 900) {
//     return num; // if value < 1000, nothing to do
//   }
// }
// eslint-disable-next-line no-unused-vars
function numFormatter(num) {
  if (num > 999 && num < 100000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 100000) {
    return (num / 1000).toFixed(0) + "K+"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}
const PendingAgreement = (props) => {
  //   const options = [
  //     { label: "Edit Listing", id: "EDIT" },
  //     { label: "P & S History", id: "P&S" },
  //     { label: "None", id: "None" }, //remove when adding a new menu item
  //   ];
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const history = useHistory();
  const rowsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [propertyId, ] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [openPAndS, setOpenPAndS] = useState(false);
  const [itemDetails, setitemDetails] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [openPopUp, setOpenPopUp] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openPopUpDisapprove, setOpenPopUpDisapprove] = useState(false);
  const [moreComment, setMoreComment] = useState(false);
  const [areaFilter, setAreaFilter] = useState("");
  const [rentalPeriodFilter, setRentalPeriodFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [bedFilter, setBedFilter] = useState("");
  const [bathFilter, setBathFilter] = useState("");
  const [keyWordSearch, setKeyWordSearch] = useState("");
  const [openClicked, setOpenClicked] = useState(true);
  const [amenityName, setAmenityName] = useState([]);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sqfeet, setSqFeet] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isCommercial, setIsCommercial] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [waterfront, setWaterfront] = useState([]);
  const [tennis, setTennis] = useState("");
  const [pools, setPools] = useState("");
  const [pets, setPets] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [propertyType, setPropertyType] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [propertyTypeCommercial, setPropertyTypeCommercial] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPrivateState, setIsPrivateState] = useState(false);
  const [amount, setAmount] = useState(["0", "1000000"]);
  const [isCheck, setIsCheck] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variable1, setVariable1] = useState([]);
  const [variable2, setVariable2] = useState([]);
  const [subArea, setSubArea] = useState([]);
  const [openLocations, setOpenLocations] = useState(false);
  const { register, handleSubmit, reset} = useForm();
  // eslint-disable-next-line no-unused-vars
  const handleClose = () => {
    setAnchorEl(null);
  };
  function formatePrice(value) {
    return value?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  function convertTimeStampWithTime(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
    // return moment(new Date(Number(date))).format("MMM DD,hh:mm");
  }
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function formateTaxMap(value) {
    if (
      value?.Value1 &&
      value?.Value2 &&
      value?.Value3 &&
      value?.Value4 &&
      value?.Value5 &&
      value?.Value6 &&
      value?.Value7
    )
      return `${value?.Value1}/${value?.Value2}/${value?.Value3}/${value?.Value4}/${value?.Value5}/${value?.Value6}/${value?.Value7}`;
  }
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  function chechForPrice(priceArray) {
    let position = priceArray.findIndex(
      (element) =>
        element.AvblStatus.toLowerCase() === "yes" ||
        element.AvblStatus.toLowerCase() === "rented"
    );
    if (position === -1) return false;
    else return true;
  }
  //   const expirydate =
  //     item.RRExp && moment(item.RRExp).format("DD/MM/YYYY");
  const [approveDisapprove] =
    useMutation(APPROVE_DISAPPROVE_PPTY, {
      refetchQueries: ["pendingPropertiesList"],
    });

  const pendingAgreementListVariables = {
    skip: (page-1) * rowsPerPage,
    limit: rowsPerPage,
    selectedArea: areaFilter,
    selectedPeriod: rentalPeriodFilter,
    selectedPrice: priceFilter,
    selectedBed: parseFloat(bedFilter),
    selectedBath: parseFloat(bathFilter),
    keywordSearch: keyWordSearch,
    rentalPeriods: rentalPeriod,
    locations: variable1,
    isPrivate: isPrivate,
    amenities: amenityName,
    isCommercial: isCommercial,
    isResidential: isResidential,
    listWater: waterfront,
    listTennis: tennis,
    listPool: pools,
    listPet: pets,
    subArea:variable2
  };
  useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = () => {
    fetchMore({
      variables: pendingAgreementListVariables,
    });
  };
  // eslint-disable-next-line no-unused-vars
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChange = (event, newValue) => {
    // console.log("nv", newValue)
    let strArray=newValue
    let strArr = strArray.map(function(e){return e.toString()});
    setAmount(strArr);
  };

  // eslint-disable-next-line no-unused-vars
  const id = open ? 'simple-popover' : undefined;
  const {
    data: pendingAgreementList,
    loading,
    fetchMore,
  } = useQuery(PENDING_PROPERTY_AGREEMENT_LIST, {
    fetchPolicy: "network-only",
    variables: pendingAgreementListVariables,
  });
  const pendingAgreementPropertyLists =
    pendingAgreementList &&
    pendingAgreementList.pendingAgreements &&
    pendingAgreementList.pendingAgreements.data;
console.log("pnd",pendingAgreementPropertyLists)
  const pendingAgreementPropertyCount =
    pendingAgreementList?.pendingAgreements &&
    pendingAgreementList?.pendingAgreements?.count;
  //console.log("pendinggg", pendingPropertyLists, pendingPropertyCount);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function handleSearch(params) {
    setVariable2(isCheck);
    setVariable1(selected);
    if(openClicked===false ){  setOpenClicked(!openClicked);}

    if (params.search) {
      setKeyWordSearch(params.search);
    }else {setKeyWordSearch("")}
    if (params.area) {
      if (params.area === "Any Area") setAreaFilter("");
      else setAreaFilter(params.area);
    }
    if (params.period) {
      if (params.period === "Any Periods") setRentalPeriodFilter("");
      else setRentalPeriodFilter(params.period);
    }
    setPriceFilter(amount);
    if (params.bed) {
      if (params.bed === "Any Beds") setBedFilter("");
      else setBedFilter(params.bed);
    }
    if (params.bath) {
      if (params.bath === "Any Baths") setBathFilter("");
      else setBathFilter(params.bath);
    }
    if (params.sqFeet) {
      if (params.sqFeet === "Any Feet") setSqFeet("");
      else setSqFeet(params.sqFeet);
    }
    if (params.tennis) {
      if (params.tennis === "Any Tennis") setTennis("");
      else setTennis(params.tennis);
    }
    if (params.pools) {
      if (params.pools === "Any Pools") setPools("");
      else setPools(params.pools);
    }
    if (params.pets) {
      if (params.pets === "Any Pets") setPets("");
      else setPets(params.pets);
    }
    if (params.location) {
      setLocation(params.location);
    }
    if (params.rentalPeriod) {
      setRentalPeriod(params.rentalPeriod);
    }
    if (params.amenity) {
      setAmenityName(params.amenity);
    }
    if (params.waterfront) {
      setWaterfront(params.waterfront);
    }
    setIsPrivate(isPrivateState);
    setIsCommercial(propertyTypeCommercial);
    setIsResidential(propertyType);
  }

  // eslint-disable-next-line no-unused-vars
  function sortLabels(a, b) {
    var nameA = a.label.toUpperCase();
    var nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  // eslint-disable-next-line no-unused-vars
  const handleApprove = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: propertyId,
          ActionType: "Approve",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Approved Successfully",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleDisapprove = () => {
    try {
      approveDisapprove({
        variables: {
          PropertyId: propertyId,
          ActionType: "DisApprove",
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Declined Successfully",
            variant: "success",
          });
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleMenuChange = (type, item, target) => {
    console.log("item.....", item?._id);
    item && item._id && setitemDetails(item._id);
    item && setitemDetails(item);
    if (item) {
      setitemDetails(item);
    }
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "MENU":
        setAnchorEl(target);
        break;
      case "EDIT":
        setAnchorEl(false);
        history.push(`/addProperty/${itemDetails?.PropertyId}`);
        break;
      case "P&S":
        setOpenPAndS(true);
        setAnchorEl(false);
        break;
    }
  };
  //console.log("itemDetails------>",itemDetails)
  const handleClearAll=()=>{
    setAreaFilter("")
     setKeyWordSearch("")
     setVariable1([])
     setVariable2([])
     setSelected([])
     setSubArea([])
     setIsCheck([])
    
   }
  return (
    <div className="custom-layout">
      {openLocations &&
        <Locations
          openLocations={openLocations}
          setOpenLocations={setOpenLocations}
          setLocation={setLocation}
          setSubArea={setSubArea}
          setSelected={setSelected}
          selected={selected}
          setIsCheck={setIsCheck}
          isCheck={isCheck}
        // data= {data?.leadsDetails?.UserDtls?.Email}
        // recipientId={data?.leadsDetails?.UserId}
        />
      }
      <Loader open={loading} />
      <LoggedInHeader
        //handleAddBtnClick={handleAddBtnClick}
        label="Pending Agreement"
        //addBtnLabel="Add Property"
      />
      <div className="custom-layout-content">
        <div className="search-band search-band-properties ps-0">
          <form onSubmit={handleSubmit(handleSearch)}>
            <FormControl className="search-town ps-2">
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Ad's Title, Address, PID"
                {...register("search")}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>
            <FormControl className="search-town ps-2" onClick={() => setOpenLocations(true)} style={{cursor:'pointer'}}>
              {/* <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
              <Controller
              control={control}
              name="location"
              render={({ field: { onChange, value } }) => {
                console.log("vl", value);
                return (
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    // variant="outlined"
                    value={value && value?.length > 0 ? value : [""]}
                    onChange={(e) =>
                      onChange(
                        e.target.value[0] === ""
                          ? [e.target.value[1]]
                          : e.target.value
                      )
                    }
                    // input={<Input />}
                    renderValue={(selected) =>
                      selected[0] === ""
                        ? "Locations"
                        : ` Locations (${selected.length} selected)`
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} disabled value={""}>
                      Locations
                    </MenuItem>
                    {areaOptions?.sort(sortLabels).map((item) => (
                      <MenuItem
                        className="advance-filter-listItem"
                        key={item.value}
                        value={item.value}
                      >
                        <Checkbox
                          color="primary"
                          checked={value?.indexOf(item.value) > -1}
                        />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            /> */}
            <TextField
              // disabled={true}
              id="standard-full-width"
              className="fullWidthSearch"
              label="LOCATION"
              disableUnderline={false}
              onClick={() => setOpenLocations(true)}
              placeholder={selected.length>0||subArea.length>0? selected.length+subArea.length+ " Selected":"City/Town"}
              // {...register("search")}
              fullWidth
              style={{pointerEvents:'none'}}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                disableUnderline: false,
              }}
            />
              {/* <InputLabel>TOWN</InputLabel>
              <Select
                defaultValue="Any Area"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("area")}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value="Any Area">{"Which Area?"}</MenuItem>
                {areaOptions?.sort(sortLabels).map((item) => (
                  <MenuItem value={item.label}><img src="../assets/images/serach-band-location.svg" className="img-fluid me-2 sb-icon" alt="Icon"/>{item.value}</MenuItem>
                ))}
              </Select> */}
            </FormControl>
            {/* <FormControl>
              <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
              <Controller
              control={control}
              name="rentalPeriod"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={value && value?.length > 0 ? value : [""]}
                    onChange={(e) =>
                      onChange(
                        e.target.value[0] === ""
                          ? [e.target.value[1]]
                          : e.target.value
                      )
                    }
                    renderValue={(selected) =>
                      selected[0] === ""
                        ? "Periods"
                        : ` Periods (${selected.length} selected)`
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} disabled value={""}>
                      Periods
                    </MenuItem>
                    {rentalPeriodOptions.map((item) => (
                      <MenuItem
                        className="advance-filter-listItem"
                        key={item.RentalPeriod}
                        value={item.RentalPeriod}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            value?.indexOf(item.RentalPeriod) > -1
                          }
                        />
                        <ListItemText primary={item.RentalPeriod} />
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            /> */}
              {/* <InputLabel>PERIOD</InputLabel>
              <Select
                defaultValue="Any Periods"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("period")}
              >
                <MenuItem value="Any Periods">{"Any Periods"}</MenuItem>
                {rentalPeriodOptions.map((item) => (
                  <MenuItem value={item.RentalPeriod}>
                    <img src="../assets/images/serach-band-calendar.svg" style={{height:'14px'}} className="img-fluid me-2 sb-icon" alt="Icon"></img>
                    {item.RentalPeriod}
                  </MenuItem>
                ))}
              </Select> */}
            {/* </FormControl> */}
            {/* <FormControl>
              <div className="price-filter-box-outer" aria-describedby={id} variant="contained" onClick={handleClick}>
                <b style={{cursor: "pointer"}} className="price-filter-label">PRICE</b>
                <div style={{cursor: "pointer"}} className="price-filter-box">{amount[0]==="0"&& amount[1]==="1000000"?"Any Price ": "$"+amount[0]+" - "+"$"+ amount[1]}<ArrowDropDownIcon /></div>

              </div>
              <Popover
                className="price-filter"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorPosition={{ top: 2000, left: 400 }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={classes.typography}>
                  <div className={classes.root}>
                    <Typography id="range-slider" gutterBottom>
                    <CloseIcon style={{cursor: "pointer",marginLeft:"460px",marginTop:"5px"}}  onClick={()=>(handleClose())} />
                      Price range
                    </Typography>
                    <Slider
                      value={amount}
                      step={1}
                      valueLabelFormat={numFormatter}
                      marks={followersMarks}
                      // scale={scale}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      max={100000}
                      min={0}
                    />

                  </div>
                </Typography>
                <div style={{ display: "flex" }}>

                  <FormControl fullWidth className={classes.margin} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={amount[0]}
                      placeholder="Min"
                      onChange={(e) => { setAmount([e.target.value, amount[1]]) }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      labelWidth={30}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.margin} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      placeholder="Max"
                      value={amount[1]}
                      onChange={(e) => { setAmount([amount[0], e.target.value]) }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      labelWidth={30}
                    />
                  </FormControl>
                </div>
              </Popover>
            </FormControl> */}
            {/* <FormControl>
              <InputLabel>BED</InputLabel>
              <Select
                defaultValue="Any Beds"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("bed")}
              >
                <MenuItem value="Any Beds">{"Any Beds"}</MenuItem>
                {bedRoomsOptions.map((item) => (
                  <MenuItem value={item.label}><img src="../assets/images/icon-bed.svg" style={{height:'12px'}} className="img-fluid me-2 sb-icon" alt="Icon"></img>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

            {/* <FormControl>
              <InputLabel>BATH</InputLabel>
              <Select
                defaultValue="Any Baths"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                {...register("bath")}
              >
                <MenuItem value="Any Baths">{"Any Baths"}</MenuItem>
                {bathroomOptions.map((item) => (
                  <MenuItem value={item.label}><img src="../assets/images/icon-bath.svg" style={{height:'14px'}} className="img-fluid me-2 sb-icon" alt="Icon"></img>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl className="search-btn-outer">
              <Button
                className="search-btn"
                variant="contained"
                style={{ backgroundColor: "#000", color: "#fff" }}
                type="submit"

              >
                Search
              </Button>
              <Button  type="submit" onClick={()=>{reset();handleClearAll()}}  className="clear-filter p-2 ms-5" style={{minWidth: "auto"}} data-toggle="tooltip" title="Clear Filter">
                    <img src="../assets/images/icon-reset.svg" className="img-fluid" alt="Clear Filter"/>
                  </Button>
              {/* <label
                onClick={() => {
                  setOpenClicked(!openClicked);
                }}
                className="advanced-filter-btn"
              >
                {openClicked === true ? <>More</> : <>Less</>}
                {openClicked === true ? (
                  <img
                    src="../assets/images/more-open.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                ) : (
                  <img
                    src="../assets/images/more-close.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                )}
              </label> */}
            </FormControl>
          </form>
        </div>
        {/* {!openClicked && 
          <div>
            <div className="advance-filter advance-filter-admin">
              <form onSubmit={handleSubmit(handleSearch)}>
                <div className="row">
                <h6>Advanced Filter</h6> */}
                  {/* <div className="col-md-12 ms-2">
                    <h6>Property Type</h6>
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={propertyType}
                           onChange={(e)=>setPropertyType(e.target.checked)}
                          name="residential"
                          color="primary"
                        //   {...register("residential")}
                        />
                      }
                      label="Residential"
                    />
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                        checked={propertyTypeCommercial}
                        onChange={(e)=>setPropertyTypeCommercial(e.target.checked)}
                          name="commercial"
                          color="primary"
                          //{...register("commercial")}
                        />
                      }
                      label="Commercial"
                    />
                  </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        name="amenity"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value.length)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Amenities?"
                                  : ` Amenities (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Amenities
                              </MenuItem>
                              {mainAmenityOptions.map((name) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={name.value}
                                  value={name.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(name.value) > -1}
                                  />
                                  <ListItemText primary={name.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}

                      {/* <Controller
                      control={control}
                      name="amenity"
                      render={({ field: { onChange, value } }) => {
                        console.log("vl", value)
                        return (
                          <Select
                            variant="oulined"
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={value ? value : []}
                            onChange={(e) => onChange(e.target.value)}
                            // onChange={(e)=>{setAmenityName(e.target.value)}}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} className={classes.chip} />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {amenities.map((amenity) => (
                              <MenuItem key={amenity.value} value={amenity.value} >
                                {amenity.label}
                              </MenuItem>
                            ))}
                          </Select>)
                      }}
                    /> */}
                    {/* </FormControl>
                  </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-checkbox-label">PERIOD</InputLabel>
                      <Controller
                        control={control}
                        name="rentalPeriod"
                        render={({ field: { onChange, value } }) => {
                          console.log("vl", value)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Periods"
                                  : ` Periods (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Periods
                              </MenuItem>
                              {rentalPeriodOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={item.RentalPeriod}
                                  value={item.RentalPeriod}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      value?.indexOf(item.RentalPeriod) > -1
                                    }
                                  />
                                  <ListItemText primary={item.RentalPeriod} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}
                      {/* <Controller
                      control={control}
                      name="rentalPeriod"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {rentalPeriodOptions.map((item) => (
                            <MenuItem key={item.RentalPeriod} value={item.RentalPeriod}>
                              {item.RentalPeriod}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                    {/* </FormControl>
                  </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Controller
                        control={control}
                        name="waterfront"
                        render={({ field: { onChange, value } }) => {
                          // console.log("vl", value)
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              // input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Waterfronts?"
                                  : ` Waterfronts (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Waterfronts
                              </MenuItem>
                              {waterfrontOptions.map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={item.value}
                                  value={item.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(item.value) > -1}
                                  />
                                  <ListItemText primary={item.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}
                      {/* <Controller
                      control={control}
                      name="waterfront"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setRentalPeriod(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {waterfrontOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>)}
                    /> */}
                    {/* </FormControl>
                  </div> */}

                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-checkbox-label">LOCATION</InputLabel>
                      <Controller
                        control={control}
                        name="location"
                        render={({ field: { onChange, value } }) => {
                          console.log("vl", value);
                          return (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              variant="outlined"
                              value={value && value?.length > 0 ? value : [""]}
                              onChange={(e) =>
                                onChange(
                                  e.target.value[0] === ""
                                    ? [e.target.value[1]]
                                    : e.target.value
                                )
                              }
                              input={<Input />}
                              renderValue={(selected) =>
                                selected[0] === ""
                                  ? "Locations"
                                  : ` Locations (${selected.length} selected)`
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem key={0} disabled value={""}>
                                Locations
                              </MenuItem>
                              {areaOptions?.sort(sortLabels).map((item) => (
                                <MenuItem
                                  className="advance-filter-listItem"
                                  key={item.value}
                                  value={item.value}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={value?.indexOf(item.value) > -1}
                                  />
                                  <ListItemText primary={item.value} />
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      /> */}

                      {/* <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={value ? value : []}
                          onChange={(e) => onChange(e.target.value)}
                          // onChange={(e)=>setLocation(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {areaOptions?.sort(sortLabels).map((item) => (
                            <MenuItem value={item.label}>{item.value}</MenuItem>
                          ))}

                        </Select>)}
                    /> */}
                    {/* </FormControl>
                  </div> */}

                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Select
                        // input={<Input/>}
                        variant="outlined"
                        defaultValue={tennis===""?"Any Tennis": tennis}
                        MenuProps={MenuProps}
                        {...register("tennis")}
                      >
                        <MenuItem
                          style={{ color: "#6e7073" }}
                          value="Any Tennis"
                        >
                          {"Tennis?"}
                        </MenuItem>
                        {tennisOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                      <Select
                        // input={<Input/>}
                        variant="outlined"
                        defaultValue={pools===""?"Any Pools": pools}
                        MenuProps={MenuProps}
                        {...register("pools")}
                      >
                        <MenuItem
                          style={{ color: "#6e7073" }}
                          value="Any Pools"
                        >
                          {"Pool?"}
                        </MenuItem>
                        {poolOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}
                  {/* <div className="col-md-4">
                    <FormControl className={classes.formControl}>
                     
                      <Select
                        variant="outlined"
                        // input={<Input/>}
                        defaultValue={pets===""?"Any Pets": pets}
                        MenuProps={MenuProps}
                        {...register("pets")}
                      >
                        <MenuItem style={{ color: "#6e7073" }} value="Any Pets">
                          {"Pets?"}
                        </MenuItem>
                        {petsOptions.map((item) => (
                          <MenuItem
                            className="advance-filter-listItem"
                            value={item.value}
                          >
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* <div className="col-md-12 mb-2 ms-2">
                    <h6 className="mt-3">Property List as</h6>
                    <FormControlLabel
                      className="advance-filter-Property-type"
                      control={
                        <Checkbox
                          checked={isPrivateState}
                          onChange={(e)=>setIsPrivateState(e.target.checked)}
                          name="isPrivate"
                          color="primary"
                          //{...register("isPrivate")}
                        />
                      }
                      label="Private"
                    />
                  </div> */}
                {/* </div>
              </form>
            </div>
          </div>
        } */}
        <div className="content-wrap">
     {pendingAgreementPropertyCount>0&& <label className="admin-property-count">{pendingAgreementPropertyCount+" results"} </label>}
          <SideBar
            selected="pendingAgreement"
            count={pendingAgreementPropertyCount}
          />
          <div className="adminListing">
            {pendingAgreementPropertyLists &&
            pendingAgreementPropertyLists.length
              ? pendingAgreementPropertyLists?.map((item, index) => (
                  <div className="adminListingItem">
                    <div className="al-itemImg-initialDetails">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="al-itemImg">
                            <img
                              src={getFeaturedImage(item?.Images)}
                              className="img-fluid"
                              alt=""
                            />
                            {item?.IsPrivate && (
                              <label className="exclusive-label">
                                {item?.IsPrivate === true ? "Exclusive" : null}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="al-initialDetails">
                            <div className="al-head">
                              <div>
                                <address>
                                  PID#{item.PID} {item.StreetAddress}{", "}{item?.Area}
                                </address>
                                <h3>{item?.AdTitle}
                                  {/* {item?.AdTitle?.charAt(0).toUpperCase() +
                                    item?.AdTitle?.slice(1).toLowerCase()} */}
                                </h3>
                                {item?.PriceData?.SalesPrice &&
                                item?.PriceData?.SalesPrice !== 0 ? (
                                  <h6>
                                    SalePrice:{" "}
                                    <font>
                                      $
                                      {formatePrice(
                                        item?.PriceData?.SalesPrice
                                      )}
                                    </font>
                                  </h6>
                                ) : null}
                              </div>
                              <div className="al-head-btn">
                                {item?.Status && (
                                  <Button
                                    type="submit"
                                    color="sucess"
                                    variant="contained"
                                    className="green-btn"
                                  >
                                    {item?.Status === "requested"
                                      ? "Pending"
                                      : item?.Status?.charAt(0).toUpperCase() +
                                        item?.Status?.slice(1).toLowerCase()}
                                  </Button>
                                )}
                                <Button
                                onClick={()=>{history.push(`/agreement/${item?._id}`);}}
                                variant="contained"
                                color="primary"
                                style={{fontFamily: "Helvetica-Light",textTransform: 'capitalize'}}
                                >
                                  View Agreement
                                </Button>
                                {/* <Button
                                style={{ backgroundColor: "#000" }}
                                onClick={(e) =>
                                  handleMenuChange(
                                    "MENU",
                                    item,
                                    e.currentTarget
                                  )
                                }
                              >
                                <img
                                  src="../assets/images/dot-group.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                              </Button> */}
                              </div>
                            </div>
                            {item?.AdDescription && (
                              <TexttView
                                text={
                                  item?.AdDescription?.charAt(0).toUpperCase() +
                                  item?.AdDescription?.slice(1).toLowerCase()
                                }
                              />
                              // <p>
                              //   {item?.AdDescription.charAt(0).toUpperCase() +
                              //     item?.AdDescription.slice(1).toLowerCase()}
                              // </p>
                            )}
                            {item?.ListMainAmenity && (
                              <ul className="al-style propertyStyleTag">
                                {item?.ListMainAmenity &&
                                  item?.ListMainAmenity.map(
                                    (items) =>
                                      items?.Flag === true && (
                                        <li>{items?.Title}</li>
                                      )
                                  )}
                                {/* {item?.ListMainAmenity &&
                                item?.ListMainAmenity.map((items) =>
                                  items?.Flag === true ? (
                                    <li
                                      style={{
                                        backgroundColor: "#A64C48",
                                        color: "#fff",
                                      }}
                                    >
                                      {items?.Title}
                                    </li>
                                  ) : items.Flag === false ? (
                                    <li
                                      style={{
                                        backgroundColor: "#A64C48",
                                        color: "#fff",
                                      }}
                                    >
                                      <del>{items?.Title}</del>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                )} */}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="al-aminities-price">
                      <ul>
                        {item.ListBedRoom && (
                          <li>
                            <label>Bed:</label>
                            {item.ListBedRoom}
                          </li>
                        )}
                        {item.ListBathRoom && (
                          <li>
                            <label>Bath:</label>
                            {item.ListBathRoom}
                          </li>
                        )}
                        {item.ListStyle && (
                          <li>
                            <label>Style:</label>
                            {item.ListStyle}
                          </li>
                        )}
                        {item.LivingArea && (
                          <li>
                            <label>Living Area:</label>
                            {item.LivingArea}
                          </li>
                        )}
                        {item.YearBuilt && (
                          <li>
                            <label>Year Build:</label>
                            {item.YearBuilt}
                          </li>
                        )}
                        {item.ListHeat && (
                          <li>
                            <label>Heat:</label>
                            {item.ListHeat}
                          </li>
                        )}
                        {item.ListGarage && (
                          <li>
                            <label>Garage:</label>
                            {item.ListGarage}
                          </li>
                        )}
                        {item.ListBasement && (
                          <li>
                            <label>Basement:</label>
                            {item.ListBasement}
                          </li>
                        )}
                        {item.ListAirCondition && (
                          <li>
                            <label>A/C:</label>
                            {item.ListAirCondition}
                          </li>
                        )}
                        {item.LotSize && (
                          <li>
                            <label>Lot Size:</label>
                            {item.LotSize}
                          </li>
                        )}
                        {item.BedBathConfiguration && (
                          <li>
                            <label>Bed Bath Configuration:</label>
                            {item.BedBathConfiguration}
                          </li>
                        )}
                         {item.ListPool && (
                          <li>
                            <label>Pool:</label>
                            {item.ListPool}
                          </li>
                        )}
                        {item.ListStory && (
                          <li>
                            <label>Stories:</label>
                            {item.ListStory}
                          </li>
                        )}
                        {formateTaxMap(item.SuffCoTaxMap) && (
                          <li>
                            <label>TaxMap:</label>
                            {formateTaxMap(item.SuffCoTaxMap)}
                          </li>
                        )}
                        {item.RentalRegisteryId && (
                          <li>
                            <label>RRN ID:</label>
                            {item.RentalRegisteryId}
                          </li>
                        )}
                        {item.RRExp && (
                          <li>
                            <label>RR Expires on:</label>
                            {item.RRExp && item.RRExp.length>13? moment(item.RRExp).format("MM/DD/YYYY"): moment.unix(item.RRExp/1000).format("MM/DD/YYYY")}
                            {/* {moment(item.RRExp).format("MM/DD/YYYY")} */}
                          </li>
                        )}
                        {item.ListAgentJustification && (
                          <li>
                            <label>Agent Justification:</label>
                            {item.ListAgentJustification}
                          </li>
                        )}
                        {item.ListKey && (
                          <li>
                            <label>Key:</label>
                            {item.ListKey}
                          </li>
                        )}
                        {item.KeyLocation && (
                          <li>
                            <label>Key Location:</label>
                            {item.KeyLocation}
                          </li>
                        )}
                      </ul>
                      {item?.PriceData?.PriceDetails &&
                        chechForPrice(item?.PriceData?.PriceDetails) && (
                          <ul>
                            {item?.PriceData?.PriceDetails &&
                              item?.PriceData?.PriceDetails.map((items) => {
                                if (
                                  items.AvblStatus.toLowerCase() === "yes" ||
                                  items.AvblStatus.toLowerCase() === "rented"
                                ){
                                  return (
                                    <li>
                                      <label>
                                        <img
                                          src={
                                            items.AvblStatus.toLowerCase() ===
                                            "yes"
                                              ? "../assets/images/green-check.svg"
                                              : "../assets/images/red-check.svg"
                                          }
                                          className="img-fluid"
                                          alt="Tick"
                                        />{" "}
                                        {items.RentalPeriod}
                                      </label>
                                      ${formatePrice(items.PrePrice)}
                                    </li>
                                  );}
                                  return null
                              })}
                          </ul>
                        )}
                    </div>
                    {}
                    {item?.PriceData?.ShortTermPriceDetails &&
                      item?.PriceData?.ShortTermPriceDetails.length > 0 && (
                        <div>
                          <br />
                          <h5>Short Term Values (Priced Weekly)</h5>
                          <div style={{ paddingBottom: "20px" }}>
                            {item?.PriceData?.ShortTermPriceDetails.map(
                              (items) => {
                                return (
                                  <Chip
                                    className="me-2"
                                    label={`${convertTimeStamp(
                                      items.DateFrom
                                    )} - ${convertTimeStamp(items.DateTo)} $${
                                      items.Amount
                                    }`}
                                  />
                                );
                              }
                            )}
                          </div>
                          <Divider />
                        </div>
                      )}
                    <div className="al-owner-agent-details">
                      <div className="row">
                        <div className="col-md-3">
                          {item?.OwnerDtls?.FirstName && (
                            <h6>
                              {`${item?.OwnerDtls?.FirstName} ${item?.OwnerDtls?.LastName}`}
                              <span>(OWNER)</span>
                            </h6>
                          )}
                          <ul>
                            {item?.OwnerDtls?.HomePhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                H-{item?.OwnerDtls?.HomePhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.OfficePhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                O-{item?.OwnerDtls?.OfficePhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.CellPhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                C-{item?.OwnerDtls?.CellPhone}
                              </li>
                            )}
                            {item?.OwnerDtls?.LocalPhone && (
                              <li>
                                <img
                                  src="../assets/images/phone.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                L-{item?.OwnerDtls?.LocalPhone}
                              </li>
                            )}

                            {item?.OwnerDtls?.Email && (
                              <li>
                                <img
                                  src="../assets/images/email.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                {item?.OwnerDtls?.Email}
                              </li>
                            )}
                          </ul>
                        </div>
                        {item?.AgentDtls && (
                          <div className="col-md-3">
                            {item?.AgentDtls?.FirstName && (
                              <h6>
                                {`${item?.AgentDtls?.FirstName} ${item?.AgentDtls?.LastName}`}
                                <span>(PROPERTY AGENT)</span>
                              </h6>
                            )}
                            <ul>
                              {item?.AgentDtls?.HomePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  H-{item?.AgentDtls?.HomePhone}
                                </li>
                              )}
                              {item?.AgentDtls?.OfficePhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  O-{item?.AgentDtls?.OfficePhone}
                                </li>
                              )}
                              {item?.AgentDtls?.Phone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  C-{item?.AgentDtls?.Phone}
                                </li>
                              )}
                              {item?.AgentDtls?.LocalPhone && (
                                <li>
                                  <img
                                    src="../assets/images/phone.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  L-{item?.AgentDtls?.LocalPhone}
                                </li>
                              )}

                              {item?.AgentDtls?.Email && (
                                <li>
                                  <img
                                    src="../assets/images/email.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  />
                                  {item?.AgentDtls?.Email}
                                </li>
                              )}
                              <li>(Agent Input Listing)</li>
                            </ul>
                          </div>
                        )}
                        <div className="col-md-3">
                          <ul className="al-extra">
                            {item.SpecialInfo && (
                              <li>
                                <span>Key Special Info:</span>
                                <label>{item.SpecialInfo}</label>
                              </li>
                            )}
                            {item.ShowingIns && (
                              <li>
                                <span>SI:</span>
                                <label>{item.ShowingIns}</label>
                              </li>
                            )}
                            {item.AlaramCode && (
                              <li>
                                <span>Alarm:</span>
                                <label>{item.AlaramCode}</label>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="al-comments">
                      <ul>
                        {item?.Comments &&
                          [...item?.Comments].map((items, index) => {
                            if (!moreComment && index > 1) return null;
                            return (
                              <li>
                                {items.Comment},{" "}
                                <b>
                                  - {items.UserName}{" "}
                                  {convertTimeStampWithTime(items.CreatedOn)}
                                </b>
                              </li>
                            );
                          })}
                      </ul>
                      {item?.Comments && item?.Comments.length > 2 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          className="view-comment"
                          onClick={() => setMoreComment(!moreComment)}
                        >
                          {moreComment
                            ? "View less Comments"
                            : "View more Comments"}
                        </a>
                      )}
                    </div>
                    <div className="al-dates">
                      <ul>
                        {item.CreatedOn && (
                          <li>
                            Listing Date :&nbsp;
                            <b>{convertTimeStamp(item.CreatedOn)}</b>
                          </li>
                        )}
                        {item.UpdatedOn && (
                          <li>
                            Last Update :&nbsp;
                            <b>{convertTimeStamp(item.UpdatedOn)}</b>
                          </li>
                        )}
                        {item?.UpdatedByDtls?.FirstName && (
                          <li>
                            Last Update by :&nbsp;
                            <b>
                              {item?.UpdatedByDtls?.FirstName.charAt(
                                0
                              ).toUpperCase() +
                                item?.UpdatedByDtls?.FirstName.slice(
                                  1
                                ).toLowerCase()}{" "}
                              {item?.UpdatedByDtls?.LastName.charAt(
                                0
                              ).toUpperCase() +
                                item?.UpdatedByDtls?.LastName.slice(
                                  1
                                ).toLowerCase()}
                            </b>
                          </li>
                        )}
                        <li>
                          Status :&nbsp;
                          <b>
                            {item?.Status === "requested"
                              ? "Pending"
                              : item?.Status?.charAt(0).toUpperCase() +
                                item?.Status?.slice(1).toLowerCase()}
                          </b>
                        </li>
                        {/* <li>
              Listing Type :&nbsp;<b>Need Listing agreement</b>
            </li> */}
                      </ul>
                    </div>
                    {/* <div className="d-flex pt-3 pb-4 justify-content-between">
                    <Button
                      color="sucess"
                      variant="contained"
                      className="green-btn"
                      onClick={() => {
                        setOpenPopUp(true);
                        setPropertyId(item?.PropertyId);
                      }}
                    >
                      Approve
                     
                    </Button>
                 
                    <Button
                      color="primary"
                      variant="contained"
                      className=""
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "Helvetica-Light",
                      }}
                      onClick={() => {
                        setOpenPopUpDisapprove(true);
                        setPropertyId(item?.PropertyId);
                      }}
                    >
                      Decline
                   
                    </Button>
                    
                  </div> */}
                  </div>
                ))
              : "No pending property to show"}
          </div>
          {/* <DialogBox
            open={openPopUp}
            setOpen={setOpenPopUp}
            handlePositiveBtn={() => handleApprove()}
            title={"Approve" + "?"}
            text={"Are you sure you want to Approve this Property ?"}
          />
          <DialogBox
            open={openPopUpDisapprove}
            setOpen={setOpenPopUpDisapprove}
            handlePositiveBtn={() => handleDisapprove()}
            title={"Decline" + "?"}
            text={"Are you sure you want to Decline this Property ?"}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "5px" }}
          >
            <div
              style={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.43)",
                borderRadius: "4px",
              }}
            >
              <div className="al-action-popup">
                {options.map((option, index) => {
                  return (
                    <>
                      <Button
                        // disabled={
                        //   !option.disabled && itemDetails.Status != "approved"
                        // }
                        style={
                          option.label === "None" && option.id === "None"
                            ? { opacity: "0", cursor: "auto" }
                            : null
                        }
                        onClick={() => handleMenuChange(option.id)}
                        variant="outlined"
                        color="primary"
                      >
                        {option.label}
                      </Button>
                    </>
                  );
                })}
              </div>
            </div>
          </Popover>
          <PAndSHistory
        open={openPAndS}
        setOpen={setOpenPAndS}
        pandsId={itemDetails.PropertyId}
        details={itemDetails}
      /> */}
          {!loading &&pendingAgreementPropertyCount>0&& (
            <Pagination
              component="div"
              className="propertyPagination"
              //rowsPerPageOptions={[]}
              count={Math.ceil(pendingAgreementPropertyCount / rowsPerPage)}
              //rowsPerPage={rowsPerPage}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PendingAgreement;
