import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { authVar, userDeatils } from '../../RactiveVariables/index';
import { useReactiveVar } from "@apollo/client";
import EditProfile from "./editProfile";
import ChangePassword from "./changePassword";
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {
  Drawer
} from "@material-ui/core";
import SideBar from './sidebar';
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   "&:focus": {
  //     backgroundColor: theme.palette.primary.main,
  //     "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //       color: theme.palette.common.white
  //     }
  //   }
  // }
}))(MenuItem);
const LoggedInHeader = (props) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logoutAlert, setLogoutAlert] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const user = useReactiveVar(userDeatils);
  //console.log("proppss---------------------------------",user)
  const userRole = user?.data?.Role;
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItems = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "EDIT_PROFILE":
        setOpenEditProfile(true);

        setAnchorEl(false);
        break;
      case "CHANGE_PASSWORD":
        setOpenChangePassword(true);
        //setOpenAuthPopUp(true)
        setAnchorEl(false);
        break;
      case 'TOGGLE_LOGOUT_ALERT':
        setLogoutAlert(!logoutAlert);
        setAnchorEl(null);
        break;
      case 'CONFIRM_LOGOUT':
        handleExit();
        setLogoutAlert(!logoutAlert);
        break;
    }
  };
  const handleExit = () => {
    localStorage.clear();
    authVar({
      loaded: true,
      auth: false,
    });
    userDeatils(null);
    //props.setUserInfo();
  };
  const handleClose = (value) => {
    setOpenEditProfile(false);
    setOpenMenu(false);
  };
  const handleClosePassword = (value) => {
    setOpenChangePassword(false);
  };
  return (
    <div >
      <Drawer className="menuDrawer" anchor={"right"} open={openMenu} onClose={handleClose}>
        <IconButton onClick={() => setOpenMenu(false)} className="p-2" style={{ marginLeft: 'auto' }} aria-label="Close">
          <CloseIcon />
        </IconButton>
        <ul>
          <li>
            <SideBar />
          </li>

        </ul>


      </Drawer>
      <header className="loggedInHeader fixed-top">
        <div className="d-flex align-items-center">
          <Link to="/" className="header-logo "><img src="../assets/images/header-logo.svg" className="img-fluid" alt="logo" /></Link>

          {/* {props.handleBack && <div  onClick={()=>props.handleBack()} style={{cursor:'pointer'}}><ArrowBackIosIcon/> Back</div>} */}
          {props.handleBack &&
            <IconButton
              className="lead-header-back"
              onClick={() => props.handleBack()} style={{ cursor: 'pointer' }}

            >
              <img src='../assets/images/icon-left-arrow.svg' className='img-fluid' alt="Icon"></img>
              Back
            </IconButton>
          }
          <h1 className="page-title">{props.label}</h1>
        </div>
        <div className="header-btn d-flex align-items-center">
          {props.sendEmail && <Button className="outline-btn" onClick={() => props.sendEmail()} disabled={props.sendEmail ? false : true}>
            Email
          </Button>}
          {props.handleDelete && user?.data?.Role !== "agent" && <Button className="highlight-btn" color="primary" onClick={props.handleDelete}>
            Delete
          </Button>}
          {props.addBtnLabel && <Button className="highlight-btn" onClick={props.handleAddBtnClick}>
            {props.addBtnLabel}
          </Button>}


          <IconButton className="header-avatar" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
            <Avatar src={user?.data?.ProfileImage}>{user?.data?.FirstName?.charAt(0).toUpperCase() + user?.data?.LastName?.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
          {/* <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={()=>setAnchorEl(null)}> */}
          <div className="d-block d-md-none"><IconButton className='p-0 ms-2'><MenuIcon onClick={() => setOpenMenu(true)} /></IconButton></div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {userRole === "agent" && <StyledMenuItem onClick={() => handleMenuItems("EDIT_PROFILE")}>
              Edit Profile
            </StyledMenuItem>}
            {userRole === "agent" && <StyledMenuItem onClick={() => handleMenuItems("CHANGE_PASSWORD")}>
              Change Password
            </StyledMenuItem>}
            <StyledMenuItem onClick={() => handleMenuItems('TOGGLE_LOGOUT_ALERT')}>Logout</StyledMenuItem>
            {/* </Menu> */}
          </StyledMenu>

          {EditProfile && <Dialog onClose={handleClose} open={openEditProfile}>
            <EditProfile setOpenEditProfile={setOpenEditProfile} />
          </Dialog>}
          {ChangePassword && <Dialog onClose={handleClosePassword} open={openChangePassword}>
            <ChangePassword setOpenChangePassword={setOpenChangePassword} />
          </Dialog>}
          <Dialog open={logoutAlert} onClose={() => handleMenuItems('TOGGLE_LOGOUT_ALERT')} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Logout ?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to logout?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleMenuItems('TOGGLE_LOGOUT_ALERT')} color="primary">
                No
              </Button>
              <Button onClick={() => handleMenuItems('CONFIRM_LOGOUT')} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>


        </div>
      </header>

    </div>
  );
};



export default LoggedInHeader;
