import React, { useEffect } from 'react';
import FrontendHeader from "../Common/frontendHeader"
import Footer from "../Common/footer";
import {Link} from "react-router-dom"
const Policy = (props) => {
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[]);
  return (
    <div>

      <FrontendHeader search list contact location={props.location.pathname} />
      <div className="inner-banner team-banner" style={{ backgroundImage: 'url(./assets/images/team-bg.jpg)' }}>
        <div className="container">
          <div className="inner-banner-content">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div className="common-template">
        <div className="container">
        
            <p>Rosehip Partners LLC and its directly affiliated web domains and DBAs <Link to="#" title="Hamptonsrentals">hamptonsrentals.com</Link>, <Link to={{pathname: "http://www.hamptonsproperties.com"}}  title="Hamptonsproperties" target="_blank">hamptonsproperties.com</Link>, <Link to={{pathname: "http://www.northforkrentals.com"}}  title="Northforkrentals" target="_blank">northforkrentals.com</Link>, <Link to={{pathname: "http://www.northforkproperties.com"}}  title="northforkproperties" target="_blank">
                    northforkproperties.com
                </Link> ("<strong>Rosehip</strong>", "<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>") understand the importance of maintaining the confidentiality of our clients' personal
                information and is committed to ensuring the security of any information provided by our clients through all our affiliated websites, (the "<strong>Site</strong>" or “<strong>Sites</strong>”).
            </p>
            <h3>The Information we collect:</h3>
            <p>In order to provide real estate brokerage and related services to our clients efficiently and accurately, we may collect personal and other information about our clients from a number of sources, including: (1) information we receive from account documentation, including leases, contracts of sale, agency disclosure documents; and (2) information about our clients' transactions with us. This may include information about the users of our services, third party suppliers, and other individuals. This information may come from our clients (either directly or indirectly by using our web based real estate services).</p>
            <p>If you are a registered to use one or more of our services, we may also gather certain information about your use of such services. This may include information from which you and/or your company can be identified such as the times you log on, your volume of use of the services, the types of data, systems and reports you access, the locations from which you log on, duration of sessions and other similar data.</p>
            <h3>Uses Made Of Your Personal Information:</h3>
            <p>We gather this information to allow us to administer, support, and obtain feedback on our services, to identify and prevent faults with our services and the Site, to improve the services offered by us, to ensure we are maintaining adequate security measures and to prevent any security breaches of our Site.</p>
            <p>We may also use your information, including aggregate information and statistics, for research and analysis purposes such as monitoring website usage in order to help us develop the Site and improve our services. From time to time we may also provide this information to third parties (for example our affiliates) to assist us with this research.</p>
            <h3>Further Uses Of Your Personal Information; Marketing</h3>
            <p>In addition to providing you with the information you have requested, we may process your personal data for marketing purposes and there are several ways in which we may use the personal information you have provided to us, including the following:</p>
            <p>We may contact you via email, fax, post and/or telephone about:</p>
            <ul>
                <li>Our services including properties for sale and for rent that we think may be of interest to you. </li>
                <li>Information about special features of our Web Sites that we think may be of interest to you.</li>
                <li>Information and offers from service providers in our local service directory.</li>
                <li>Client functions or entertainment events. </li>
            </ul>
            <p>
                If you do not want us to use your personal information in this way, please send an email to <Link to="#" onClick={(e)=>{window.location ="mailto:info@Hamptonsrentals.com"; e.preventDefault()}} class="red_a">info@Hamptonsrentals.com</Link>  or write to Rosehip Partners LLC, 15 Railroad Avenue - Suite 3, East Hampton, NY 11937 asking not to be contacted for any marketing purposes or specifying which of the above
                you do not wish to be contacted about. Please include your name, address and postcode along with any account number or other reference you may have. If you are an on-line subscriber, you may update your account to amend your marketing preferences at any
                time.
            </p>
            <h3>Disclosure of your information:</h3>
            <p>We will not, without your consent, disclose to third parties any individually identifying information, such as names, postal and e-mail addresses, telephone numbers, which you have provided to Rosehip Partners via this Web Site and others.</p>
            <p>In some circumstances, we may pass on information to third parties such as our professional advisors, administrators or subcontractors, or to licensors of the software used on the Site.</p>
            <p>We may also disclose your personal information to third parties if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Site terms and conditions.</p>
            <h3>Your consent:</h3>
            <p>Rosehip Partners LLC, may store your information in its databases for reference in order to answer queries or resolve problems, provide improved and new services and to meet any legal data retention requirements. This means we may retain your information after you stop using the Site or our services or otherwise interacting with us.</p>
            <p>All information you provide to us is stored on secure servers. Unfortunately, the transmission of information via the internet is not completely secure. However we have put in place various security procedures as set out in this policy. Although we will do our best to protect your data, we cannot guarantee the security of your data transmitted to the Site; any transmission is at your own risk. Once we have received your information, we will use procedures and security features to try to prevent unauthorized access.</p>
        
        </div>

      </div>
      <Footer />
    </div>
  );
};
export default Policy;