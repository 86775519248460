import { useState, useEffect } from "react";
import React from "react";
import PromotionAndPrice from "./promotion-and-price";
import PropertyInformation from "./propertyInformation";
import AddPhoto from "./addPhoto";
import PropertyHeader from "../../Common/propertyHeader";
import MiniSideBar from "./MiniSideBar";
import { useHistory, useParams } from "react-router-dom";
import AgentInfo from "./agentInfo";
import Agreement from "./agreement";
import { useLazyQuery } from "@apollo/client";
import { PROPERTY_INFO_CHECK } from "../../../Graghql/queries";
import { userDeatils } from "../../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { useLocation } from "react-router-dom";
export default function AddProperty(params) {
  const locations = useLocation();
  const history = useHistory();
  const menuItems = [
    { label: "Property Information", mLabel: "Info" },
    { label: "Promotion & Price", mLabel: "Price" },
    { label: "Add Photos", mLabel: "Photo" },
    { label: "Agent Info", mLabel: "Agent" },
    { label: "Listing Agreement & Agency Disclosure", mLabel: "Agreement" },
  ];
  const [step, setStep] = useState(
    params?.location.pathname.includes("agreement/") ? 5 : 1
  );
  const { id } = useParams();
  //console.log(",,,,,,,,,,",id)

  const [propertyId, setPropertyId] = useState(id && id !== "$" ? id : null);
  const [stepState, setStepState] = useState(
    (id && id !== "$") || params?.location.pathname.includes("agreement/")
      ? 5
      : 1
  );
  const [completed, setCompleted] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  // eslint-disable-next-line no-unused-vars
  const [pathnameCheck, setPathnameCheck] = useState(
    params?.location.pathname.includes("addPendingProperty/") ? true : false
  );
  //params?.location.pathname=="addPendingProperty/"?setPathnameCheck(true):setPathnameCheck(false)
  const user = useReactiveVar(userDeatils);
  const totalStep = menuItems.length;
  const userRole = user?.data?.Role;
  // eslint-disable-next-line no-unused-vars
  const [propertyInfoCheck, propertyInfoCheckData] = useLazyQuery(
    PROPERTY_INFO_CHECK,
    {
      onCompleted: (res) => {
        let array = [];
        if (res?.propertyInfoCheck?.propertyExist >= 1) array.push(true);
        else array.push(false);
        if (res?.propertyInfoCheck?.propertyPrice >= 1) array.push(true);
        else array.push(false);
        if (res?.propertyInfoCheck?.propertyAsset >= 1) array.push(true);
        else array.push(false);
        if (res?.propertyInfoCheck?.propertyAgent >= 1) array.push(true);
        else array.push(false);
        if (res?.propertyInfoCheck?.propertyAgreement >= 1) array.push(true);
        else array.push(false);
        setCompleted(array);
      },
    }
  );

  useEffect(() => {
    if (propertyId)
      propertyInfoCheck({
        variables: { propertyId: propertyId },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // function handleNext(state) {
  //   if (state && !completed[state - 2]) {
  //     let cmp = [...completed];
  //     cmp[state - 2] = true;
  //     setCompleted(cmp);
  //   }
  //   if (state && id === "$") {
  //     setStepState(state);
  //     if (step < totalStep && step < state) setStep(state);
  //   } else if (step < totalStep && step < stepState) setStep(step===3&&user?.data?.Role==="owner"?step+2:step+1);
  // }
  function handleNext(step, limit, owner) {
    //limit-->maximum allowed step
    if (step) setStep(step);
    if (limit) {
      let cmp = [...completed];
      cmp[owner ? limit - 3 : limit - 2] = true;
      setCompleted(cmp);
      setStepState(limit);
    }
  }
  function handlePrevious() {
    if (step > 0)
      if (step === 5 && user?.data?.UserType === "USER") setStep(step - 2);
      else setStep(step - 1);
  }
  function handleCancel() {
    locations?.state?.title === "portfolioEdit"
      ? window.history.back()
      : locations?.state?.title === "ppptyFrontUserEdit"
      ? window.history.back()
      : locations?.state?.title === "featuredPptyHomeEdit"
      ? window.history.back()
      : userRole === "owner" || userRole === "renter"
      ? history.push(`/lead-portfolio/${user?.data?.LeadList}`)
      : pathnameCheck === true
      ? history.push("/pendingApproval")
      : history.push("/properties");
  }

  return (
    <div>
      <PropertyHeader
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
        handleCancel={handleCancel}
        menuItems={menuItems}
        stepState={stepState}
      />

      <div className="add-property-wrap">
        <div className="container">
          <div className="row row-outer">
            <div className="col-md-3 col-pad">
              <MiniSideBar
                menuItems={menuItems}
                step={step}
                setStep={setStep}
                stepState={stepState}
                completed={completed}
              />
            </div>
            <div className="col-md-9 col-pad">
              {step === 1 && (
                <PropertyInformation
                  handleNext={handleNext}
                  handleCancel={handleCancel}
                  setPropertyId={setPropertyId}
                  propertyId={propertyId}
                  pathnameCheck={pathnameCheck}
                />
              )}
              {step === 2 && (
                <PromotionAndPrice
                  handleNext={handleNext}
                  propertyId={propertyId}
                  handleCancel={handleCancel}
                  handlePrevious={handlePrevious}
                  pathnameCheck={pathnameCheck}
                />
              )}

              {step === 3 && (
                <AddPhoto
                  handleNext={handleNext}
                  propertyId={propertyId}
                  handleCancel={handleCancel}
                  handlePrevious={handlePrevious}
                />
              )}

              {step === 4 && (
                <AgentInfo
                  handleNext={handleNext}
                  propertyId={propertyId}
                  handleCancel={handleCancel}
                  handlePrevious={handlePrevious}
                  pathnameCheck={pathnameCheck}
                />
              )}

              {step === 5 && (
                <Agreement
                  handleNext={handleNext}
                  propertyId={propertyId}
                  handleCancel={handleCancel}
                  handlePrevious={handlePrevious}
                  step={step}
                  setStep={setStep}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
