import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useHistory } from "react-router";
import { userDeatils } from "../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";
import Badge from '@material-ui/core/Badge';
import { useQuery } from "@apollo/client";
import { PENDING_AGREEMENTS_COUNT,PENDING_PROPERTIES_COUNT } from "../../Graghql/queries";

const SideBar = (props) => {
  const history = useHistory();
  const user = useReactiveVar(userDeatils);
  const { data: pendingAgreementCount } = useQuery(PENDING_AGREEMENTS_COUNT)
  const { data: pendingPropertyCount } = useQuery(PENDING_PROPERTIES_COUNT,{ fetchPolicy: "network-only"})
  //console.log(".........>",pendingAgreementCount,pendingPropertyCount)
  const values = [
    {
      image: "../assets/images/icon-dashboardx2.svg",
      label: "Dashboard",
      value: "dashboard",
      url: "/dashboard",
      type: ["admin","agent", "owner", "renter","agentAdmin"],
    },
    {
      image: "../assets/images/icon-leads.svg",
      label: "Leads",
      value: "leads",
      url: "/leads",
      frontendUser: `/lead-detail/${user?.data?.LeadList}`,
      type: ["admin","agent", "owner", "renter","agentAdmin"],
    },
    {
      image: "../assets/images/icon-property.svg",
      label: "Properties",
      value: "properties",
      url: "/properties",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-featured-property.svg",
      label: "Featured Properties",
      value: "featured",
      url: "/featured",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-pending.svg",
      label: "Pending Approval",
      value:"pendingApproval",
      url:"/pendingApproval",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-pending-agreement.svg",
      label: "Pending Agreement",
      value:"pendingAgreement",
      url:"/pendingAgreement",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-owner.svg",
      label: "Owners",
      value: "owner",
      url: "/owner",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-renter.svg",
      label: "Renters",
      value: "renter",
      url: "/renter",
      type: ["admin","agent","agentAdmin"],
    },
    {
      image: "../assets/images/icon-agent.svg",
      label: "Agents",
      value: "agent",
      url: "/agent",
      type: ["admin","agentAdmin"],
    },
    {
      image: "../assets/images/icon-saved-search.svg",
      label: "Trending",
      value:"trending",
      url:"/trending",
      type: ["admin","agent", "owner", "renter","agentAdmin"],
    },
    {
      image: "../assets/images/icon-contact.svg",
      label: "Contact Us Listing",
      value: "contact-listing",
      url: "/contact-listing",
      type: ["admin", "owner", "renter","agentAdmin"],
    },
  ];
  const userRole = user?.data?.Role;
  return (
    <div className="custom-sidebar">
      <List component="nav" className="p-0">
        {values.map((item) => {
          if (item.type.includes(userRole)){
            return (
              <ListItem
                button
                onClick={() =>
                  item.frontendUser && user?.data?.UserType === "USER"
                    ? history.push(item.frontendUser)
                    : item.url
                    ? history.push(item.url)
                    : null
                }
                selected={item?.value && props.selected === item?.value}
                key={item?.label}
              >
                <img src={item.image} className="img-fluid" alt="logo" />
                <label>{item.label}</label>
              {item?.label==="Pending Agreement"? <Badge className="p-1 badge-count" badgeContent={pendingAgreementCount?.pendingAgreementsCount}  ></Badge>:
              item?.label==="Pending Approval"? <Badge className="p-1 badge-count" badgeContent={pendingPropertyCount?.pendingPropertiesCount} ></Badge>:""}
              </ListItem>
               
            );
              }
              return null
        })}
       
      </List>
    </div>
  );
};

export default SideBar;
