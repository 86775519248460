import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FrontendHeader from "../Common/frontendHeader"
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import { useQuery, } from '@apollo/client'
import { LIST_FRONTEND_TEAMS } from '../../Graghql/queries';
import Footer from '../Common/footer';
import {Link} from "react-router-dom"
const Team = () => {
  const history = useHistory()
  const { data, loading } = useQuery(LIST_FRONTEND_TEAMS);
  let teamList = data && data.listFrontEndTeams && [...data.listFrontEndTeams.data]
  // eslint-disable-next-line no-unused-vars
  let teamListCount = data && data.listFrontEndTeams && data.listFrontEndTeams.count

  //console.log("teamData", teamList)
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[]);

  const skeleton = []
  for (let i = 0; i < 10; i++) {
    skeleton.push(<div className="col-md-4" >
      <div className="team-item">
        <Skeleton variant="circle" width={200} height={200} animation="wave" className="img-fluid m-auto" />
        <div className="team-primary-data">
          <h4><Skeleton variant="h4" height="24px" animation="wave" /></h4>
          <label className="designation"><Skeleton variant="text" animation="wave" /></label>
          <div className="team-phone">
            <><Skeleton variant="text" width="100px" height="30px" animation="wave" /></>
            <><Skeleton variant="text" width="100px" height="30px" animation="wave" /></>
          </div>
          <><Skeleton variant="text" width="200px" height="30px" animation="wave" /></>
        </div>
        <Divider variant="outset" component="div" />
        <Skeleton variant="h1" width="100%" height="60px" animation="wave" />
      </div>
    </div>)
  }
  function sortLabels(a, b) {
    var nameA = a.FirstName.toUpperCase();
    var nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  return (
    <div>
      <FrontendHeader search list contact message/>
      <div className="inner-banner team-banner tb" style={{ backgroundImage: 'url(./assets/images/agentbg.jpg)', backgroundPosition:'bottom'}}>
        
        <div className="container">
          <div className="inner-banner-content">
            <h1>Our Team</h1>
          </div>
        </div>
      </div>
      <section className="team-listing">
        <div className="container">
          <div className="row">
            {loading ?

              <>
                {skeleton}
              </> : teamList && teamList.length ? teamList.sort(sortLabels).map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="team-item">
                    {item.ProfileImage ?
                      <Avatar src={item.ProfileImage} alt="Name"></Avatar> : <Avatar src="https://hrfileuploads.s3.amazonaws.com/systemFiles/no-image.jpg
                      " alt="Name"></Avatar>}
                    <div className="team-primary-data" style={{height: "180px"}}>
                      <h4>{item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1) + " " + item.LastName.charAt(0).toUpperCase() + item.LastName.slice(1)}</h4>
                      <label className="designation">{item.Designation ? item.Designation.charAt(0).toUpperCase() + item.Designation.slice(1) : ""}</label>
                      <div className="team-phone">
                        {item.Phone&&<Link to="#" onClick={(e)=>{window.location ="tel:"+ item.Phone.toString(); e.preventDefault()}}><img src="./assets/images/phone.svg" className="img-fluid" alt="Icon" />
                          {item.Phone}
                        </Link>}
                        {item.OfficePhone&&<Link to="#" onClick={(e)=>{window.location ="tel:"+ item.OfficePhone.toString(); e.preventDefault()}}><img src="./assets/images/mobile.svg" className="img-fluid" alt="Icon" />
                          {item.OfficePhone}
                        </Link>}
                      </div>
                      {item.Email&&<Link to="#" onClick={(e)=>{window.location ="mailto:"+ item.Email.toString(); e.preventDefault()}}className="team-email"><img src="./assets/images/email.svg" className="img-fluid" alt="Icon" />
                        {item.Email}
                      </Link>}
                    </div>
                    <Divider variant="outset" component="div" />
                    <Button className="checkout-listing" color="primary" variant="outlined" onClick={() => history.push("/agents/" + item._id)}>CHECK OUT MY LISTINGS<img src="./assets/images/checkout-pointer.svg" className="img-fluid" alt="Icon" /></Button>
                  </div>
                </div>)) : "No data"}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Team;