import { gql } from "@apollo/client";


export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      userNew {
        _id
        FirstName
        LastName
        Email
        Password
        Phone
        HomePhone
        LocalPhone
        OfficePhone
        CellPhone
        Role
        UserType
        ProfileImage
        Status
        LeadList
        AddressLine1
        AddressLine2
        City
        State
      }
      token
    }
  }
`;

export const CHANGE_READ_STATUS= gql`
mutation changeReadStatus($userId: String!){
  changeReadStatus(userId: $userId){
    status
    message
  }
}
`;

export const DEACTIVATE_AGENT = gql`
  mutation deactivateAgent($agentId: String!, $status: String!) {
    deactivateAgent(agentId: $agentId, status: $status) {
      status
      message
    }
  }
`;

export const EMAIL_TO_USERS = gql`
mutation emailtoUsers(
  $toEmailIDs:[String!]!
   $ccEmailIDs: [String] 
   $propertyIDs: [String!]!
    $text: String
    $subject:String
    $mailType:String){
      emailtoUsers(
        toEmailIDs:$toEmailIDs
        ccEmailIDs:$ccEmailIDs
        propertyIDs:$propertyIDs
        text:$text
        subject:$subject
        mailType:$mailType
      ){
        status
        message
      }
    }

`;


export const ADD_COMMENT =gql`
mutation addCmnts(
  $PropertyId: String!
  $Comment: String
  $UserId: String
  $UserName: String
  $CreatedBy: String
  $UpdatedBy: String
){
  addCmnts(
  PropertyId:$PropertyId
  Comment:$Comment
  UserId: $UserId
  UserName: $UserName
  CreatedBy: $CreatedBy
  UpdatedBy: $UpdatedBy
  ){
    PropertyId
    Comment
    UserId
    UserName
    CreatedBy
    UpdatedBy
  }
}

`;

export const DELETE_CONTACT = gql`
mutation deleteContact($contactIDs: [String!]! ){
  deleteContact(contactIDs: $contactIDs){
    message
    status
  }
}
`;

export const ADD_AGENT = gql`
  mutation addAgent(
    $firstName: String!
    $lastName: String!
    $designation: String!
    $primaryEmail: String!
    $address1: String!
    $address2: String!
    $state: String!
    $city: String!
    $cellPhone: String!
    $officePhone: String
    $InLeadCycle : Boolean
    $AccessRights: String
    $Status: String
    $zip:String
    $PersonalStatement: String
    $ProfileImage: String
  ) {
    addAgent(
      FirstName: $firstName
      LastName: $lastName
      Designation: $designation
      Email: $primaryEmail
      AddressLine1: $address1
      AddressLine2: $address2
      State: $state
      City: $city
      Zip:$zip
      Phone: $cellPhone
      OfficePhone: $officePhone
      InLeadCycle: $InLeadCycle
      AccessRights: $AccessRights
      Status: $Status
      PersonalStatement: $PersonalStatement
      ProfileImage: $ProfileImage
    ) {
      FirstName
    }
  }
`;

export const EDIT_AGENT = gql`
  mutation editAgent(
    $id: String!
    $firstName: String!
    $lastName: String!
    $designation: String!
    $address1: String!
    $address2: String!
    $primaryEmail: String!
    $state: String!
    $city: String!
    $zip:String
    $cellPhone: String!
    $officePhone: String
    $InLeadCycle : Boolean
    $AccessRights: String
    $Status: String
    $PersonalStatement: String
    $ProfileImage: String
  ) {
    editAgent(
      _id: $id
      FirstName: $firstName
      LastName: $lastName
      Designation: $designation
      Email: $primaryEmail
      AddressLine1: $address1
      AddressLine2: $address2
      State: $state
      City: $city
      Zip:$zip
      Phone: $cellPhone
      OfficePhone: $officePhone
      InLeadCycle: $InLeadCycle
      AccessRights: $AccessRights
      Status: $Status
      PersonalStatement: $PersonalStatement
      ProfileImage: $ProfileImage
    ) {
      FirstName
    }
  }
`;

export const ADD_FRONTEND_USER = gql`
  mutation addFrontEndUser(
    $role: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phone: String!
    $status: String
  ) {
    addFrontEndUser(
      Role: $role
      FirstName: $firstName
      LastName: $lastName
      Email: $email
      Password: $password
      Phone: $phone 
      Status: $status
    ) {
      userNew{
        _id
        FirstName
        LastName
        Email
        Phone
        Status
        Role
        ProfileImage
        UserType
        LeadList
      }
      token
    }
  }
`;

export const ADD_RENTER = gql`
  mutation addRenter(
    $Status:String!
    $firstName: String!
    $lastName: String!
    $email:String!
    $phone:String!
    $Agent:String!
  ) {
    addRenter(
      Status:$Status
      FirstName: $firstName
      LastName: $lastName
      Email: $email
      Phone: $phone
      Agent:$Agent
    ) {
 FirstName
    }
  }
`;
export const EDIT_RENTER = gql`
  mutation editRenter(
    $id:String
    $Status:String!
    $firstName: String!
    $lastName: String!
    $email:String!
    $phone:String!
    $Agent:String!
  ) {
    editRenter(
      _id:$id
      Status:$Status
      FirstName: $firstName
      LastName: $lastName
      Email: $email
      Phone: $phone
      Agent:$Agent
    ) 
    {
     FirstName
    }
    
  }
`;

export const DELETE_RENTERS = gql`
  mutation deleteRenters($renterIds: [String]) {
    deleteRenters(renterIds: $renterIds) {
      status
      message
    }
  }
`;
export const DEACTIVATE_RENTER = gql`
  mutation deactivateRenter($renterId: String!, $status: String!) {
    deactivateRenter(renterId: $renterId, status: $status) {
      status
      message
    }
  }
`;
export const ADD_OWNER = gql`
  mutation addOwner(
    $firstName: String!
    $lastName: String
    $primaryEmail: String!
    $address1: String
    $address2: String
    $state: String
    $city: String
    $Status: String!
    $Agent: String!
    $HomePhone: String
    $CellPhone: String
    $OfficePhone: String
    $LocalPhone: String
    $ContactPreference: String
    $Zip: String
    $Country: String
    $SecondContactName: String
    $SecondContactPhone: String
    $SecondContactEmail: String
  ) {
    addOwner(
      FirstName: $firstName
      LastName: $lastName
      Email: $primaryEmail
      AddressLine1: $address1
      AddressLine2: $address2
      State: $state
      City: $city
      Status: $Status
      Agent: $Agent
      HomePhone: $HomePhone
      CellPhone: $CellPhone
      OfficePhone: $OfficePhone
      LocalPhone: $LocalPhone
      ContactPreference: $ContactPreference
      Zip: $Zip
      Country: $Country
      SecondContactName: $SecondContactName
      SecondContactPhone: $SecondContactPhone
      SecondContactEmail: $SecondContactEmail

    ) {
      FirstName
    }
  }
`;
export const DELETE_OWNER = gql`
  mutation deleteOwners(
    $ownerIds: [String]
  ) {
    deleteOwners(
      ownerIds: $ownerIds
    ) {
      message 
      status
    }
  }
`;

export const EDIT_OWNER = gql`
  mutation editOwner(
    $id: String!
    $firstName: String!
    $lastName: String
    $primaryEmail: String!
    $address1: String
    $address2: String
    $state: String
    $city: String
    $Status: String!
    $Agent: String!
    $HomePhone: String
    $CellPhone: String
    $OfficePhone: String
    $LocalPhone: String
    $ContactPreference: String
    $Zip: String
    $Country: String
    $SecondContactName: String
    $SecondContactPhone: String
    $SecondContactEmail: String
  ) {
    editOwner(
      _id: $id
      FirstName: $firstName
      LastName: $lastName
      Email: $primaryEmail
      AddressLine1: $address1
      AddressLine2: $address2
      State: $state
      City: $city
      Status: $Status
      Agent: $Agent
      HomePhone: $HomePhone
      CellPhone: $CellPhone
      OfficePhone: $OfficePhone
      LocalPhone: $LocalPhone
      ContactPreference: $ContactPreference
      Zip: $Zip
      Country: $Country
      SecondContactName: $SecondContactName
      SecondContactPhone: $SecondContactPhone
      SecondContactEmail: $SecondContactEmail

    ) {
      FirstName
    }
  }
`;
export const DEACTIVATE_OWNER = gql`
  mutation deactivateOwner($ownerId: String!, $status: String!) {
    deactivateOwner(ownerId: $ownerId, status: $status) {
      status
      message
    }
  }
`;
export const FORGET_PASS = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;
export const RESET_PASS = gql`
  mutation resetPassword($resetLink: String!, $newPassword: String!) {
    resetPassword(resetLink: $resetLink, newPassword: $newPassword) {
      status
      message
    }
  }
`;
export const ADD_PROPERTY = gql`
  mutation addProperty(
    $PropertyIdOld: String
    $PropertyType: String
    $PropertyOwnerId: String
    $Area: String
    $PropertySlug: String
    $IsSouthOfHighway: Boolean
    $ListStyle: String
    $ListBedRoom: Float
    $ListBathRoom: Float
    $BedBathConfiguration: String
    $IsFurnished: Boolean
    $IsPrivate:Boolean
    $IsWirelessWeb: Boolean
    $ListAirCondition: String
    $ListFireplace: String
    $ListPool: String
    $IsChildSafetyFence: Boolean
    $ListTennis: String
    $IsDownstairsMaster: Boolean
    $ListWater: String
    $ListStory: String
    $ListHeat: String
    $YearBuilt: String
    $ListBasement: String
    $ListGarage: String
    $ListPet: String
    $IsPoolHouseOrArtStudio: Boolean
    $IsJacuzziSpa: Boolean
    $ListMainAmenity: [ListMainAmenity]
    $TotalAnnualREtaxes: String
    $AvailableSqFt: String
    $AdTitle: String
    $AdDescription: String
    $SalesPrice: String
    $IsSalePricePublic: Boolean
    $ListLeaseTerm: String
    $OwnerId: String
    $PropertyAgentId: String
    $ListAgentJustification: String
    $StreetAddress: String
    $City: String
    $State: String
    $Zip: String
    $RentalRegisteryId: String
    $RRExp: String
    $LivingArea: String
    $LotSize: String
    $VillageLocation: String
    $ListNatureOfParty: String
    $ListPropertyTypeCommercial: String
    $ListTimeFrame: String
    $ListKey: String
    $UniqueCode: String
    $Latitude: String
    $Longitude: String
  ) {
    addProperty(
      PropertyIdOld: $PropertyIdOld
      PropertyType: $PropertyType
      PropertyOwnerId: $PropertyOwnerId
      Area: $Area
      PropertySlug: $PropertySlug
      IsSouthOfHighway: $IsSouthOfHighway
      ListStyle: $ListStyle
      ListBedRoom: $ListBedRoom
      ListBathRoom: $ListBathRoom
      BedBathConfiguration: $BedBathConfiguration
      IsFurnished: $IsFurnished
      IsPrivate:$IsPrivate
      IsWirelessWeb: $IsWirelessWeb
      ListAirCondition: $ListAirCondition
      ListFireplace: $ListFireplace
      ListPool: $ListPool
      IsChildSafetyFence: $IsChildSafetyFence
      ListTennis: $ListTennis
      IsDownstairsMaster: $IsDownstairsMaster
      ListWater: $ListWater
      ListStory: $ListStory
      ListHeat: $ListHeat
      YearBuilt: $YearBuilt
      ListBasement: $ListBasement
      ListGarage: $ListGarage
      ListPet: $ListPet
      IsPoolHouseOrArtStudio: $IsPoolHouseOrArtStudio
      IsJacuzziSpa: $IsJacuzziSpa
      ListMainAmenity: $ListMainAmenity
      TotalAnnualREtaxes: $TotalAnnualREtaxes
      AvailableSqFt: $AvailableSqFt
      AdTitle: $AdTitle
      AdDescription: $AdDescription
      SalesPrice: $SalesPrice
      IsSalePricePublic: $IsSalePricePublic
      ListLeaseTerm: $ListLeaseTerm
      OwnerId: $OwnerId
      PropertyAgentId: $PropertyAgentId
      ListAgentJustification: $ListAgentJustification
      StreetAddress: $StreetAddress
      City: $City
      State: $State
      Zip: $Zip
      RentalRegisteryId: $RentalRegisteryId
      RRExp: $RRExp
      LotSize: $LotSize
      LivingArea: $LivingArea
      VillageLocation: $VillageLocation
      ListNatureOfParty: $ListNatureOfParty
      ListPropertyTypeCommercial: $ListPropertyTypeCommercial
      ListTimeFrame: $ListTimeFrame
      ListKey: $ListKey
      UniqueCode: $UniqueCode
      Latitude: $Latitude
      Longitude: $Longitude
    ) {
      PropertyIdOld
      PropertyId
    }
  }
`;

export const ADD_PROPERTY_PRICE_DETAILS = gql`
  mutation addPropertyPriceDetails(
    $PropertyId:String!
    $PropertyIdOld: String
    $PriceDetails: [monthlyPrice]
    $ShortTermPriceDetails:[shortTermPrice]
    $SalesPrice:Float
  ) {
    addPropertyPriceDetails(
      PropertyId:$PropertyId
      PropertyIdOld: $PropertyIdOld
      PriceDetails: $PriceDetails
      ShortTermPriceDetails: $ShortTermPriceDetails
      SalesPrice: $SalesPrice
    ) {
      PropertyId
    }
  }
`;

export const EDIT_PROPERTY_PRICE_DETAILS = gql`
  mutation editPropertyPriceDetails(
    $PropertyId:String!
    $Type: String
    $PriceDetails: [monthlyPrice]
    $ShortTermPriceDetails:[shortTermPrice]
    $SalesPrice:Float
    $EditedSalesPrice:Float
  ) {
    editPropertyPriceDetails(
      PropertyId:$PropertyId
      Type: $Type
      PriceDetails: $PriceDetails
      ShortTermPriceDetails: $ShortTermPriceDetails
      SalesPrice: $SalesPrice
      EditedSalesPrice: $EditedSalesPrice
    ) {
      _id
      PropertyId
    }
  }
`;

export const ADD_CONTACT_US = gql`
  mutation addContactUs(
    $firstName:String!
    $lastName: String
    $email: String!
    $phone:String!
    $message:String!
  ) {
    addContactUs(
      FirstName:$firstName
      LastName: $lastName
      Email: $email
      Phone: $phone
      Message: $message
    ) {
      FirstName
    }
  }
`;

export const EDIT_PROPERTY= gql`
  mutation editProperty(
    $PropertyId: String!
    $PID: String
    $Type:String
    $PropertyIdOld: String
    $PropertyType: String
    $PropertyOwnerId: String
    $Area: String
    $PropertySlug: String
    $IsSouthOfHighway: Boolean
    $ListStyle: String
    $ListBedRoom: Float
    $ListBathRoom: Float
    $BedBathConfiguration: String
    $IsFurnished: Boolean
    $IsPrivate:Boolean
    $IsWirelessWeb: Boolean
    $ListAirCondition: String
    $ListFireplace: String
    $ListPool: String
    $IsChildSafetyFence: Boolean
    $ListTennis: String
    $IsDownstairsMaster: Boolean
    $ListWater: String
    $ListStory: String
    $ListHeat: String
    $YearBuilt: String
    $ListBasement: String
    $ListGarage: String
    $ListPet: String
    $IsPoolHouseOrArtStudio: Boolean
    $IsJacuzziSpa: Boolean
    $ListMainAmenity: [ListMainAmenity]
    $TotalAnnualREtaxes: String
    $AvailableSqFt: String
    $AdTitle: String
    $AdDescription: String
    $SalesPrice: String
    $IsSalePricePublic: Boolean
    $ListLeaseTerm: String
    $OwnerId: String
    $PropertyAgentId: String
    $ListAgentJustification: String
    $StreetAddress: String
    $City: String
    $State: String
    $Zip: String
    $RentalRegisteryId: String
    $RRExp: String
    $LivingArea: String
    $LotSize: String
    $VillageLocation: String
    $ListNatureOfParty: String
    $ListPropertyTypeCommercial: String
    $ListTimeFrame: String
    $ListKey: String
    $UniqueCode: String
    $Latitude: String
    $Longitude: String
    $PropertyEditedFields: EditedFields
    $EditedKeys:[String]
  ) {
    editProperty(
      PropertyId: $PropertyId
      PID: $PID
      Type: $Type
      PropertyIdOld: $PropertyIdOld
      PropertyType: $PropertyType
      PropertyOwnerId: $PropertyOwnerId
      Area: $Area
      PropertySlug: $PropertySlug
      IsSouthOfHighway: $IsSouthOfHighway
      ListStyle: $ListStyle
      ListBedRoom: $ListBedRoom
      ListBathRoom: $ListBathRoom
      BedBathConfiguration: $BedBathConfiguration
      IsFurnished: $IsFurnished
      IsPrivate:$IsPrivate
      IsWirelessWeb: $IsWirelessWeb
      ListAirCondition: $ListAirCondition
      ListFireplace: $ListFireplace
      ListPool: $ListPool
      IsChildSafetyFence: $IsChildSafetyFence
      ListTennis: $ListTennis
      IsDownstairsMaster: $IsDownstairsMaster
      ListWater: $ListWater
      ListStory: $ListStory
      ListHeat: $ListHeat
      YearBuilt: $YearBuilt
      ListBasement: $ListBasement
      ListGarage: $ListGarage
      ListPet: $ListPet
      IsPoolHouseOrArtStudio: $IsPoolHouseOrArtStudio
      IsJacuzziSpa: $IsJacuzziSpa
      ListMainAmenity: $ListMainAmenity
      TotalAnnualREtaxes: $TotalAnnualREtaxes
      AvailableSqFt: $AvailableSqFt
      AdTitle: $AdTitle
      AdDescription: $AdDescription
      SalesPrice: $SalesPrice
      IsSalePricePublic: $IsSalePricePublic
      ListLeaseTerm: $ListLeaseTerm
      OwnerId: $OwnerId
      PropertyAgentId: $PropertyAgentId
      ListAgentJustification: $ListAgentJustification
      StreetAddress: $StreetAddress
      City: $City
      State: $State
      Zip: $Zip
      RentalRegisteryId: $RentalRegisteryId
      RRExp: $RRExp
      LotSize: $LotSize
      LivingArea: $LivingArea
      VillageLocation: $VillageLocation
      ListNatureOfParty: $ListNatureOfParty
      ListPropertyTypeCommercial: $ListPropertyTypeCommercial
      ListTimeFrame: $ListTimeFrame
      ListKey: $ListKey
      UniqueCode: $UniqueCode
      Latitude: $Latitude
      Longitude: $Longitude
      PropertyEditedFields: $PropertyEditedFields
      EditedKeys:$EditedKeys
    ) {
      PropertyIdOld
      PropertyId
    }
  }
`;

export const ADD_AGENT_INFO = gql`
  mutation agentInfo(
    $PropertyId: String!
    $PropertyIdOld: String
    $PropertyType: String
    $PropertyOwnerId: String
    $Area: String
    $PropertySlug: String
    $IsSouthOfHighway: Boolean
    $ListStyle: String
    $ListBedRoom: Float
    $ListBathRoom: Float
    $BedBathConfiguration: String
    $IsFurnished: Boolean
    $IsWirelessWeb: Boolean
    $ListAirCondition: String
    $ListFireplace: String
    $ListPool: String
    $IsChildSafetyFence: Boolean
    $ListTennis: String
    $IsDownstairsMaster: Boolean
    $ListWater: String
    $ListStory: String
    $ListHeat: String
    $YearBuilt: String
    $ListBasement: String
   $ListGarage: String
    $ListPet: String
    $IsPoolHouseOrArtStudio: Boolean
    $IsJacuzziSpa: Boolean
    $ListMainAmenity: [ListMainAmenity]
    $TotalAnnualREtaxes: String
    $AvailableSqFt: String
    $AdTitle: String
    $AdDescription: String
    $SalesPrice: String
    $IsSalePricePublic: Boolean
    $ListLeaseTerm: String
    $OwnerId: String
    $PropertyAgentId: String
    $ListAgentJustification: String
    $Status: String
    $StreetAddress: String
    $LotSize: String
    $ListKey: String
    $City: String
    $State: String
    $Zip: String
    $RentalRegisteryId: String
    $RRExp: String
    $LivingArea: String
    $VillageLocation: String
    $ListNatureOfParty: String
    $ListPropertyTypeCommercial: String
    $ListTimeFrame: String
    $UniqueCode: String
    $ActionType: String
    $KeyLocation: String
    $AlaramCode: String
    $ShowingIns: String
    $SpecialInfo: String
    $SuffCoTaxMap: SuffCoTaxMapObjInput
    $EdtdAgntInfoFields: EditedAgentFields
    $OwnerAgentId:String
    $AgentInfoEditedKeys:[String]
  ) {
    agentInfo(
      PropertyId: $PropertyId
      PropertyIdOld: $PropertyIdOld
      PropertyType: $PropertyType
      PropertyOwnerId: $PropertyOwnerId
      Area: $Area
      PropertySlug: $PropertySlug
      IsSouthOfHighway: $IsSouthOfHighway
      ListStyle: $ListStyle
      ListBedRoom: $ListBedRoom
      ListBathRoom: $ListBathRoom
      BedBathConfiguration: $BedBathConfiguration
      IsFurnished: $IsFurnished
      IsWirelessWeb: $IsWirelessWeb
      ListAirCondition: $ListAirCondition
      ListFireplace: $ListFireplace
      ListPool: $ListPool
      IsChildSafetyFence: $IsChildSafetyFence
      ListTennis: $ListTennis
      IsDownstairsMaster: $IsDownstairsMaster
      ListWater: $ListWater
      ListStory: $ListStory
      ListHeat: $ListHeat
      YearBuilt: $YearBuilt
      ListBasement: $ListBasement
     ListGarage: $ListGarage
      ListPet: $ListPet
      IsPoolHouseOrArtStudio: $IsPoolHouseOrArtStudio
      IsJacuzziSpa: $IsJacuzziSpa
      ListMainAmenity: $ListMainAmenity
      TotalAnnualREtaxes: $TotalAnnualREtaxes
      AvailableSqFt: $AvailableSqFt
      AdTitle: $AdTitle
      AdDescription: $AdDescription
      SalesPrice: $SalesPrice
      IsSalePricePublic: $IsSalePricePublic
      ListLeaseTerm: $ListLeaseTerm
      OwnerId: $OwnerId
      PropertyAgentId: $PropertyAgentId
      ListAgentJustification: $ListAgentJustification
      Status: $Status
      StreetAddress: $StreetAddress
      LotSize: $LotSize
      ListKey: $ListKey
      City: $City
      State: $State
      Zip: $Zip
      RentalRegisteryId: $RentalRegisteryId
      RRExp: $RRExp
      LivingArea: $LivingArea
      VillageLocation: $VillageLocation
      ListNatureOfParty: $ListNatureOfParty
      ListPropertyTypeCommercial: $ListPropertyTypeCommercial
      ListTimeFrame: $ListTimeFrame
      UniqueCode: $UniqueCode
      ActionType: $ActionType
      KeyLocation: $KeyLocation
      AlaramCode: $AlaramCode
     ShowingIns: $ShowingIns
     SpecialInfo: $SpecialInfo
     SuffCoTaxMap: $SuffCoTaxMap
     EdtdAgntInfoFields: $EdtdAgntInfoFields
     OwnerAgentId:$OwnerAgentId
     AgentInfoEditedKeys:$AgentInfoEditedKeys
    ) {
      PropertyIdOld
      PropertyId
    }
  }
`;
export const ADD_PROPERTY_PHOTO = gql`
  mutation addPropertyImages(
    $data: [imageData]
  ) {
    addPropertyImages(
      data: $data
    ) {
      PropertyId
    }
  }
`;
export const DELETE_PROFILE_IMAGE = gql`
  mutation deleteProfileImage( $agentId:String! ) {
    deleteProfileImage( agentId:$agentId ) {
      status
      message
    }
  }
`;
export const EDIT_PROPERTY_PHOTO = gql`
  mutation updatePropertyImages(
    $data: [imageData]
    $deleteIDs: [String]
  ) {
    updatePropertyImages(
      data: $data
      deleteIDs: $deleteIDs
    ) {
      PropertyId
    }
  }
`;
export const ADD_AGREEMENT = gql`
  mutation addAgreement(
    $StreetAddress:String!
    $TownStateZip:String!
    $AgencyDisclosure:String!
    $Party:String!
    $PartyAs:String!
    $PropertyId: String!
    $ChkLandlordName1:Boolean
    $ChkLandlordName2:Boolean
    $LandlordName1:String
    $LandlordName2:String
    $ListingTermChk:Boolean
    $saveAndProceed:Boolean
  ) {
    addAgreement(
      StreetAddress:$StreetAddress
      TownStateZip:$TownStateZip
      AgencyDisclosure:$AgencyDisclosure
      Party:$Party
      PartyAs:$PartyAs
      PropertyId: $PropertyId
      ChkLandlordName1:$ChkLandlordName1
      ChkLandlordName2:$ChkLandlordName2
      LandlordName1:$LandlordName1
      LandlordName2:$LandlordName2
      ListingTermChk:$ListingTermChk
      saveAndProceed:$saveAndProceed
    ) {
      PropertyId
    }
  }
`;

export const EDIT_AGREEMENT = gql`
  mutation EditAgreement(
    $StreetAddress:String
    $TownStateZip:String
    $AgencyDisclosure:String
    $Party:String
    $PartyAs:String
    $PropertyId: String!
    $ChkLandlordName1:Boolean
    $ChkLandlordName2:Boolean
    $LandlordName1:String
    $LandlordName2:String
    $ListingTermChk:Boolean
  ) {
    EditAgreement(
      StreetAddress:$StreetAddress
      TownStateZip:$TownStateZip
      AgencyDisclosure:$AgencyDisclosure
      Party:$Party
      PartyAs:$PartyAs
      PropertyId: $PropertyId
      ChkLandlordName1:$ChkLandlordName1
      ChkLandlordName2:$ChkLandlordName2
      LandlordName1:$LandlordName1
      LandlordName2:$LandlordName2
      ListingTermChk:$ListingTermChk
    ) {
      PropertyId
      AgencyDisclosure
    }
  }
`;

export const DELETE_PROPERTY_IMAGE = gql`
  mutation deletePropertyImage( $assetId:String!, $fileUrl:String! ) {
    deletePropertyImage( assetId:$assetId, fileUrl:$fileUrl ) {
      status
      message
    }
  }
`;

export const ADD_INQUIRE = gql`
  mutation addRenterByEnquiry(
    $FirstName:String!
    $LastName: String
    $Phone:String
    $Email:String!
    $PropertyId:String
    $InterestedIn:String
    $Comments:String
    $LeadSource:String
  ) {
    addRenterByEnquiry(
      FirstName:$FirstName
      LastName: $LastName
      Phone: $Phone
      Email:$Email
      PropertyId:$PropertyId
      InterestedIn:$InterestedIn
      Comments: $Comments
      LeadSource:$LeadSource
    ) {
      FirstName
    }
  }
`;


export const UPDATE_PROPERTY_STATUS =gql`
   mutation updatePropertyStatus($PropertyId:String!,$ActionType:String!){
     updatePropertyStatus(PropertyId:$PropertyId,ActionType:$ActionType){
         Msg
         Status
         ActionType
     }
   }

`;

export const LEAD_ACTIVITY_ADD=gql`
   mutation leadActivityAdd(
    $UserId: String
    $LoginedUser:String
    $LeadSrc: String
    $LeadType: String
    $RootId: String
    $Comment: String
    $PropertyIDs: [String]) 
    {
      leadActivityAdd(
        UserId: $UserId
        LoginedUser:$LoginedUser
        LeadSrc:  $LeadSrc
        LeadType:$LeadType
        RootId: $RootId
        Comment: $Comment
        PropertyIDs: $PropertyIDs )
        {
          _id
          LeadId
          UserId
          PropertyId
          LeadType
          LeadStatus
          Comment
          IsOpen
          RootId
          DisplayLevel
          Status
          BackendComments
          CreatedBy
          CreatedOn
          UpdatedBy
          UpdatedOn
          PropertyDtls{
            PropertyId
            
          }
          
        }
    } 
`;

export const DELETE_LEAD_ACTIVITY = gql`
mutation deleteLeadActivity($leadActivityId: String!){
  deleteLeadActivity(leadActivityId: $leadActivityId){
    message
  }
}
`;

export const UNSAVE_PROPERTY = gql`
  mutation unsaveProperty(
    $userId:String!
    $propertyId: String!
  ) {
    unsaveProperty(
      userId:$userId
      propertyId: $propertyId
    ) {
      status
      message
    }
  }
`;

export const SEND_AGREEMENT = gql`
mutation sendAgreement($PropertyId: String!){
  sendAgreement(PropertyId: $PropertyId){
    message
    status
  }
}
`;

export const AUTH_FOR_SIGNIN = gql`
  mutation authForSign($email: String!, $password: String!) {
    authForSign(email: $email, password: $password) {
      status
      message
    }
  }
`;

export const SIGN_AGREEMENT= gql`
  mutation signAgreement(
    $PropertyId: String!
    $SigSigneeName: String!
    $SigSigneeName2:String
    $SigAddress:String!
    $SigIp:String!
    $SigHost:String
    $SigDateTime:String!
    $IsListingAgreement:Boolean
    $StreetAddress:String!
    $TownStateZip:String!
    $AgencyDisclosure:String!
    $Party:String!
    $PartyAs:String!
    $ChkLandlordName1:Boolean
    $ChkLandlordName2:Boolean
    $LandlordName1:String
    $LandlordName2:String
    $ListingTermChk:Boolean
    ) {
    signAgreement(
      PropertyId: $PropertyId
       SigSigneeName: $SigSigneeName
       SigSigneeName2:$SigSigneeName2
       SigAddress:$SigAddress
       SigIp:$SigIp
       SigHost:$SigHost
       SigDateTime:$SigDateTime
       IsListingAgreement:$IsListingAgreement
       StreetAddress:$StreetAddress
       TownStateZip:$TownStateZip
       AgencyDisclosure:$AgencyDisclosure
       Party:$Party
       PartyAs:$PartyAs
       ChkLandlordName1:$ChkLandlordName1
       ChkLandlordName2:$ChkLandlordName2
       LandlordName1:$LandlordName1
       LandlordName2:$LandlordName2
       ListingTermChk:$ListingTermChk
       ) {
        Status
      message
      pdfUrl
    }
  }
`;


export const DELETE_SAVED_SEARCH = gql`
  mutation deleteSearch($searchIds: [String]) {
    deleteSearch(searchIds: $searchIds) {
      status
      message
    }
  }
`;

export const ADD_SEARCH = gql`
  mutation addSearch(
    $_id:String
    $Type: String
    $UserId:String
    $BudgetFrom:String
    $BudgetTo:String
    $ListBedRoom:Float
    $ListBathRoom:Float
    $Period: [String]
    $Comments:String
    $Pool:String
    $Tennis:String
    $Waterview:String
    $Waterfront:[String]
    $Oceanfront:String
    $Pets:String
    $SouthOfHighway:String
    $BrowserSessionId:String
    $SavedType:String
    $Keyword:String
    $Town:[String]
  ) {
    addSearch(
      _id:$_id
      Type:$Type
      UserId:$UserId
      BudgetFrom:$BudgetFrom
      BudgetTo:$BudgetTo
      ListBedRoom:$ListBedRoom
      ListBathRoom:$ListBathRoom
      Period: $Period
      Comments:$Comments
      Pool:$Pool
      Tennis:$Tennis
      Waterview:$Waterview
      Waterfront:$Waterfront
      Oceanfront:$Oceanfront
      Pets:$Pets
      SouthOfHighway:$SouthOfHighway
      BrowserSessionId:$BrowserSessionId
      SavedType:$SavedType
      Keyword:$Keyword
      Town:$Town
    ) {
      _id
      UserId
    }
  }
`;

export const EDIT_SEARCH = gql`
  mutation editSearch(
    $_id:String!
    $UserId:String
    $BudgetFrom:String
    $BudgetTo:String
    $ListBedRoom:Float
    $ListBathRoom:Float
    $RentalPeriod: String
    $Comments:String
    $Pool:String
    $Tennis:String
    $Waterview:String
    $Waterfront:String
    $Oceanfront:String
    $Pets:String
    $SouthOfHighway:String
    $BrowserSessionId:String
    $SavedType:String
    $Keyword:String
    $Town:String
  ) {
    editSearch(
      _id:$_id
      UserId:$UserId
      BudgetFrom:$BudgetFrom
      BudgetTo:$BudgetTo
      ListBedRoom:$ListBedRoom
      ListBathRoom:$ListBathRoom
      RentalPeriod: $RentalPeriod
      Comments:$Comments
      Pool:$Pool
      Tennis:$Tennis
      Waterview:$Waterview
      Waterfront:$Waterfront
      Oceanfront:$Oceanfront
      Pets:$Pets
      SouthOfHighway:$SouthOfHighway
      BrowserSessionId:$BrowserSessionId
      SavedType:$SavedType
      Keyword:$Keyword
      Town:$Town
    ) {
      _id
      UserId
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editProfile(
    $_id:String!
    $FirstName:String
    $LastName:String
    $Email:String
    $OfficePhone:String
    $CellPhone:String
    $LocalPhone: String
    $AddressLine1:String
    $AddressLine2:String
    $City:String
    $State:String
    $ProfileImage:String
    $UserType:String
  ) {
    editProfile(
      _id:$_id
      FirstName:$FirstName
      LastName:$LastName
      Email:$Email
      OfficePhone:$OfficePhone
      CellPhone:$CellPhone
      LocalPhone: $LocalPhone
      AddressLine1:$AddressLine1
      AddressLine2:$AddressLine2
      City:$City
      State:$State
      ProfileImage:$ProfileImage
      UserType:$UserType
    ) {
      _id
      FirstName
      LastName
      Email
      Role
      UserType
      Status
      Phone
      CellPhone
      OfficePhone
      AddressLine1
      AddressLine2
      ProfileImage
      State
      City
      LeadList
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const APPROVE_DISAPPROVE_PPTY = gql`
  mutation approveDisApproveProperties($PropertyId: String!, $ActionType: String!) {
    approveDisApproveProperties(PropertyId: $PropertyId, ActionType: $ActionType) {
      Status
      Msg
      ActionType
    }
  }
`;

export const FEATURED_PPTY = gql`
  mutation setFeaturedProperties($propertyIds: [String]!) {
    setFeaturedProperties(propertyIds: $propertyIds) {
      status
      message
    }
  }
`;

export const EMAIL_TO_LEAD = gql`
  mutation emailtoLead($recipientId: String!,$recipientEmailId: String!,$subject: String,$msg: String,$rootId:String) {
    emailtoLead(recipientId: $recipientId,recipientEmailId: $recipientEmailId,subject: $subject,msg: $msg,rootId:$rootId) {
      status
      message
    }
  }
`;