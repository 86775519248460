import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_LEAD_ACTIVITY,UPDATE_PROPERTY_STATUS,UNSAVE_PROPERTY,LEAD_ACTIVITY_ADD } from "../../../Graghql/mutations";
import {
  LEAD_ACTIVITY_LIST, LEADS_DETAIL
} from "../../../Graghql/queries";
import { toggleSnackbar, userDeatils,authVar } from "../../../RactiveVariables/index";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { DialogBox } from "../../Common"
import Loader from "../../Common/Loader"
import CustomizedModal from "../../Common/customizedModal"
import AgentPrint from "../../Properties/propertyActions/AgentPrint";
import CustomerPrint from "../../Properties/propertyActions/CustomerPrint";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmailListing from "../../Properties/propertyActions/EmailListing";
import CancelIcon from '@material-ui/icons/Cancel';
import FrontendHeader from "../../Common/frontendHeader";
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import AddComment from "./AddComment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function ViewMore(props) {
  console.log("ppp", props)
  // return null
  const [priceList, ] = useState(false)
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  function addPrice(value) {
    console.log("value", value)
    let pArray = []
    value.forEach(element => {
      if (element?.AvblStatus === "yes") {
        pArray.push(element)
      }
    })
    return pArray
  }
  // addPrice(props.price)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      {props.price && <div className="pp-availability">
    
      <div className="d-flex align-items-center">
        <img
          src="../assets/images/icon-calendar.svg"
          className="img-fluid me-1"
          alt="Icon"
          style={{marginTop:'-3px'}}
        />
        {addPrice(props.price).map((priceItem, index) => {

          if (!priceList && index >= 1) return null
          return (
            <> {priceItem.AvblStatus === "yes" && <div key={index}>{`${priceItem.RentalPeriod} :  $ ${formatePrice(priceItem.Price)}`}</div>}
            </>
          )
          })}
      </div>



        {<div>{props.price && addPrice(props.price)?.length>1 && (<>
        <IconButton aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        <label style={{color:'#D08383'}}>{!priceList ? `+${addPrice(props.price)?.length-1} More` : "View Less"}</label>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
              <div>
              {addPrice(props.price).map((priceItem, index) => {

if (!priceList && index ===0) return null
return (
  <> {priceItem.AvblStatus === "yes" && <div style={{ margin: "10px" }} key={index}>{`${priceItem.RentalPeriod}  : $ ${formatePrice(priceItem.Price)}`}</div>}
  </>
)
})}
              </div>
            </Popover></>)}
    </div>}

          {/* {props.price && addPrice(props.price)?.length>1 && (
                <IconButton onClick= {()=>setPriceList(!priceList)} className="p-0">
                <label style={{color:'#D08383'}}>{!priceList ? `+${addPrice(props.price)?.length-1} More` : "View Less"}</label> <ExpandMoreIcon/>
              </IconButton>
          
               )} */}
      </div>}
    </div>)
}
const UserPortfolio = (props) => {
  // console.log("props",props)
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  console.log('user>>>',user);
  const userRole = user?.data?.Role;
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [isDeleted, setIsDeleted] = useState(false)
  const [, setIsPaginating] = useState(false);
  const [delId, setDelId] = useState()
  const [, setPricing] = useState(null)
  // const [priceList , setPriceList]= useState(false)
  const [multiAgentPrint, setMultiAgentPrint] = useState(false)
  const [multiEmailOwner, setMultiEmailOwner] = useState(false)
  const [multiCustomerPrint, setMultiCustomerPrint] = useState(false)
  //const [view, setView] = useState("owned");
  const [checked, setChecked] = useState([])
  const [openAddCmt,setOpenAddCmt] = useState(null);
  const leadActivityListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    tabType: "Property",
    leadId: id,
  };

  const handlePagenation = () => {
    fetchMore({
      variables: leadActivityListVariables,
    });
  };
  React.useEffect(() => {
    handlePagenation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  //User Type
  const {
    data: leadData,
    //loading
  } = useQuery(LEADS_DETAIL, {
    variables: { leadId: id },
  });
  const [viwedProperty] = useMutation(LEAD_ACTIVITY_ADD);
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["leadActivityList"],
    // onCompleted: (res) => {
    //   refetch()
    // }
  });

  // eslint-disable-next-line no-unused-vars
  let uType = leadData?.leadsDetails?.UserType
  // console.log("ldata",uType)
  const { data: viewedList } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    fetchPolicy: "network-only",
    // eslint-disable-next-line no-dupe-keys
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      tabType: "Viewed",
      leadId: user?.data?.LeadList
    },
  });
  console.log("vdata--->", viewedList)

  const { data: savedList } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    fetchPolicy: "network-only",
    // eslint-disable-next-line no-dupe-keys
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      tabType: "Saved",
      leadId: user?.data?.LeadList
    },

  });
  // const [view, setView] = useState(savedList?.leadActivityList?.count > 0?"saved":"owned");
  console.log("ssdata", savedList?.leadActivityList?.count)
  const { data: suggestedList } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    fetchPolicy: "network-only",
    // eslint-disable-next-line no-dupe-keys
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      tabType: "Suggest",
      leadId: user?.data?.LeadList
    },

  });
  console.log("sdata", suggestedList)
  const { data: enquiredList } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    fetchPolicy: "network-only",
    // eslint-disable-next-line no-dupe-keys
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      tabType: "Enquiry",
      leadId: user?.data?.LeadList
    },

  });
  console.log("edata", enquiredList)
  const { data: ownedList } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    fetchPolicy: "network-only",
    // eslint-disable-next-line no-dupe-keys
    variables: {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      tabType: "Owned",
      leadId: user?.data?.LeadList
    },

  });
  console.log("odata", ownedList)
  const [view, setView] = useState("owned");
  const {
    data: portfolio,
    loading: portfolioLoading,
    fetchMore,
  } = useQuery(LEAD_ACTIVITY_LIST, {
    variables: leadActivityListVariables,
    //getting the available status items with value yes and pushing it to array
    onCompleted: (res) => {
      // console.log("res",res?.leadActivityList?.data)
      if (res?.leadActivityList) {
        let array = [];
        if (res && res.leadActivityList && res.leadActivityList.data) {
          res.leadActivityList.data.forEach((item) => {
            let innerArray = [];
            if (item && item.PriceDataLead && item.PriceDataLead.PriceDetails) {
              item.PriceDataLead.PriceDetails.forEach((element) => {
                if (element && element.AvblStatus === "yes") {
                  innerArray.push(element);
                }
              });
            }
            array.push(innerArray);
          });
        }

        setPricing(array);
        // console.log("array",array)
      }
    }
  });

  
  // eslint-disable-next-line no-unused-vars
  const [deleteLeadActivity] = useMutation(DELETE_LEAD_ACTIVITY, { refetchQueries: ["leadActivityList"], });
  const [deletePropertyStatus] = useMutation(UPDATE_PROPERTY_STATUS, { refetchQueries: ["leadActivityList"], });
  //getting featured image ==> one with value true
  function getFeaturedImage(images) {
    let position = images.findIndex((element) => element.Featured === true);
    if (position !== -1) {
      if (images[position]?.FileUrl) return images[position]?.FileUrl;
      else return "../assets/images/no-image.jpg";
    } else return "../assets/images/no-image.jpg";
  }
  //formate pricing
  // const formatePrice = (value) => {
  //   return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // };
  let portfolioCount =
    portfolio &&
    portfolio?.leadActivityList &&
    portfolio?.leadActivityList?.count;
  let portfolioData =
    portfolio &&
    portfolio?.leadActivityList &&
    portfolio?.leadActivityList?.data;
  // console.log("count", portfolioData); console.log("count1111", portfolioCount);


  const handleUnSaveProperty = (params) => {
    // console.log("tst>>",params)
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: params,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property Unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  const handleDelete = () => {
    try {
      deletePropertyStatus({
        variables: {
          PropertyId: delId,
          ActionType : "Delete"
        }
      }).then((res) => {
        toggleSnackbar({
          message: "Delete Request has been sent to Admin",
          variant: "success",
          status: true,
        })
      }).catch((error) => {
        const message = error && error.message
        toggleSnackbar({
          message: message,
          status: true,
          variant: "error"
        })
      })
    } catch (error) {
      const message = error && error.message
      toggleSnackbar({
        message: message,
        status: true,
        variant: error
      })
    }
  };


  function formatData(data) {
    // console.log("ttttt", data)
    let array = [];
    data.forEach(element => {
      let obj = {}
      obj["PID"] = element?.PropertyDtls?.PID
      obj["Longitude"] = element?.PropertyDtls?.Longitude
      obj["Latitude"] = element?.PropertyDtls?.Latitude
      obj["AdTitle"] = element?.PropertyDtls?.AdTitle
      obj["Area"] = element?.PropertyDtls?.Area
      obj["Images"] = element?.ImagesLead
      obj["ListBathRoom"] = element?.PropertyDtls?.ListBathRoom
      obj["ListBedRoom"] = element?.PropertyDtls?.ListBedRoom
      obj["OwnerDtls"] = element?.OwnerDtls
      obj["OwnerId"] = element?.OwnerDtls?.OwnerId
      obj["AgentDtls"] = element?.PrtyAgentDtls
      obj["PriceData"] = element?.PriceDataLead
      obj["ListAirCondition"] = element?.PropertyDtls?.ListAirCondition
      obj["ListPet"] = element?.PropertyDtls?.ListPet
      obj["ListPool"] = element?.PropertyDtls?.ListPool
      obj["ListStyle"] = element?.PropertyDtls?.ListStyle
      obj["ListTennis"] = element?.PropertyDtls?.ListTennis
      obj["ListWater"] = element?.PropertyDtls?.ListWater
      obj["LivingArea"] = element?.PropertyDtls?.LivingArea
      obj["State"] = element?.PropertyDtls?.State
      obj["LotSize"] = element?.PropertyDtls?.LotSize
      obj["YearBuilt"] = element?.PropertyDtls?.YearBuilt
      obj["Zip"] = element?.PropertyDtls?.Zip
      obj["ListBasement"] = element?.PropertyDtls?.ListBasement
      obj["ListGarage"] = element?.PropertyDtls?.ListGarage
      obj["ListHeat"] = element?.PropertyDtls?.ListHeat
      obj["KeyLocation"] = element?.PropertyDtls?.KeyLocation
      obj["ListMainAmenity"] = element?.PropertyDtls?.ListMainAmenity
      obj["_id"] = element?.PropertyId
      obj["OwnerEmail"] = element?.OwnerDtls?.Email


      array.push(obj)
      // console.log("arr", array)
    }); return array
  }

  // eslint-disable-next-line no-unused-vars
  function handleChecked(item, value) {
    let checkedArray = [...checked]
    if (value === true) {
      checkedArray.push(item)
      // console.log("chkd",checkedArray)
    }
    else {
      let isChecked = checkedArray.findIndex((element) => element._id !== 0)
      //console.log("isChecked",isChecked)
      if (isChecked !== -1) {
        checkedArray.splice(isChecked)
      }
    }
    setChecked(checkedArray)

  }

  // useEffect(()=>{
  //   // if(savedList?.leadActivityList?.count===0)  setView("owned") 
  //   // },[savedList?.leadActivityList?.count])

    useEffect(()=>{
      if(ownedList?.leadActivityList?.count>0)  setView("owned") 
     else if(savedList?.leadActivityList?.count>0)  setView("saved") 
     else if(enquiredList?.leadActivityList?.count>0)  setView("enquired") 
     else if(viewedList?.leadActivityList?.count>0)  setView("viewed") 
     else return setView("suggested") 
      //if(suggestedList?.leadActivityList?.count>0)  setView("suggested") 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[viewedList?.leadActivityList?.count,savedList?.leadActivityList?.count,enquiredList?.leadActivityList?.count,ownedList?.leadActivityList?.count,suggestedList?.leadActivityList?.count])
      // useEffect(()=>{
      //   if(savedList?.leadActivityList?.count>0)  setView("saved") 
      //   },[savedList?.leadActivityList?.count])
      //   useEffect(()=>{
      //     if(savedList?.leadActivityList?.count>0)  setView("saved") 
      //     },[savedList?.leadActivityList?.count])
      //     useEffect(()=>{
      //       if(enquiredList?.leadActivityList?.count>0)  setView("enquired") 
      //       },[enquiredList?.leadActivityList?.count])
      //       useEffect(()=>{
      //         if(ownedList?.leadActivityList?.count>0)  setView("owned") 
      //         },[ownedList?.leadActivityList?.count])
      // useEffect(()=>{
      //   if(viewedList?.leadActivityList?.count>0||savedList?.leadActivityList?.count===0||enquiredList?.leadActivityList?.count===0||suggestedList?.leadActivityList?.count===0||ownedList?.leadActivityList?.count ===0)  setView("viewed") 
        
      // },[viewedList?.leadActivityList?.count,savedList?.leadActivityList?.count>0,enquiredList?.leadActivityList?.count,suggestedList?.leadActivityList?.count,ownedList?.leadActivityList?.count])
      
        const handleViewListing = (ids) => {
          if(auth?.auth ){
          try {
            viwedProperty({
              variables: {
                UserId: user?.data?._id,
                LeadSrc: "Property View",
                LeadType: "View",
                //RootId:"0",
                Comment: "",
                PropertyIDs: [ids],
              },
            })
              .then((res) => {
                // toggleSnackbar({
                //   status: true,
                //   message: "You have been successfully saved property",
                //   variant: "success",
                // });
              })
              .catch((err) => {
                const message = err && err.message;
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              });
          } catch (err) {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }}
        };
        console.log(" item.UserId----->",user?.data?._id)
  return (

    <div>
      <AddComment 
        open={openAddCmt}
        setOpen={setOpenAddCmt}
        id={id}
      />
      <FrontendHeader
        contact
        list
        message
        search
        location={props.location.pathname} />
      <Loader open={portfolioLoading} />
      <div>
        <DialogBox
          open={isDeleted}
          setOpen={setIsDeleted}
          handlePositiveBtn={handleDelete}
          text={"Do you want to Delete this Property ?"}
        />
      </div>
      <div className="property-portfolio owner-property-portfolio">
        <div className="property-portfolio-title">
          <div className="container d-flex align-items-center justify-content-between">
            <ul>
              {/* <li className="active">Owned</li> */}
              {/* <li className={view==="all"?"active":null} onClick={()=>setView("all")}>
               All<Badge badgeContent={0} ></Badge>
             </li> */}
               {ownedList?.leadActivityList?.count > 0 &&
                <li className={view === "owned" ? "active" : null} onClick={() => setView("owned")}>
                  Owned
                  <Badge badgeContent={0} ></Badge>
                </li>}
              {enquiredList?.leadActivityList?.count > 0 &&
                <li className={view === "enquired" ? "active" : null} onClick={() => setView("enquired")}>
                  Inquired
                  <Badge badgeContent={0} ></Badge>
                </li>
}
            
              {savedList?.leadActivityList?.count > 0 &&
                <li className={view === "saved" ? "active" : null} onClick={() => setView("saved")}>
                  Saved
                  <Badge badgeContent={0} ></Badge>
                </li>}
              {suggestedList?.leadActivityList?.count > 0 &&
                <li className={view === "suggested" ? "active" : null} onClick={() => setView("suggested")}>
                  Suggested
                  <Badge badgeContent={0} ></Badge>
                </li>}
              {viewedList?.leadActivityList?.count > 0 && <li className={view === "viewed" ? "active" : null} onClick={() => setView("viewed")}>
                Viewed
                <Badge badgeContent={0} ></Badge>
              </li>}
            </ul>
            {/* <label>{portfolioCount} Properties</label> */}
            {userRole === "owner" || userRole === "renter" ? <Button color="primary" variant="outlined" onClick={() => history.push("/addProperty/$")}>Add Property</Button> : ""}
            {/* {uType === "renter" ? (<div>
            <Button type="submit" color="primary" variant="outlined">
              Email Property
            </Button>
            {portfolio?.leadActivityList?.data&&
            <Button type="submit" color="primary" variant="outlined"
            onClick ={()=>{
              if(checked.length>0 )
             setMultiCustomerPrint(true)
             else toggleSnackbar({
                  status: true,
                  message: "Select a property",
                  variant: "error",
                })}}>
              MCP
            </Button>}
           {portfolioData && <Button type="submit" color="primary" variant="outlined"
            onClick={()=>{checked.length>0 ? setMultiAgentPrint(true) : toggleSnackbar({
                  status: true,
                  message: "Select a property",
                  variant: "error",
                })}}>
              MAP
            </Button>}
            <Button type="submit" color="primary" variant="outlined"
            onClick={()=>{checked.length>0 ? setMultiEmailOwner(true) : toggleSnackbar({
              status: true,
              message: "Select a property",
              variant: "error",
            })}}
            >
              Email Owner
            </Button>
          </div>) : null} */}
          </div>
        </div>
        <div className="owner-property-portfolio-list">
          <div className="container">
            <div className="pp-item-wrap">
              <div className="row">
                {view === "all" && <>{portfolioData && portfolioData.length > 0 ?
                 portfolioData &&
                  portfolioData.map((item, index) => (
                      <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                        <div className="pp-item">
                          {item.ImagesLead && (
                            <div className="pp-img">
                              {userRole === "owner" && item.UserId === user?.data?._id ?
                                <img
                                  // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                /> : <img
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                />}

                                {(item?.LeadType === "Owned"||item?.LeadType === "Property") &&( userRole === "owner" ||userRole === "renter")? item.UserId === user?.data?._id && <IconButton
                              color="primary"
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              >
                                <EditIcon color="grey" />
                              </IconButton>: ""}
                              {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                              {/* {userRole === "owner" && item.UserId === user?.data?._id && <IconButton
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              >
                                <EditIcon color="grey" />
                              </IconButton>} */}
                              {/* <IconButton
                                onClick={() =>{ 
                                setDelId(item._id)
                                setIsDeleted(true)}
                              }
                                className="pp-close"
                              > */}
                                {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                {/* <CancelIcon color="primary" />
                              </IconButton> */}
                              {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"? "availablity-status is_available": "availablity-status not_available"}>{item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                            </div>
                          )}
                          <div className="pp-id">
                            <label>
                              PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                            </label>
                            {item?.LeadType === "Enquiry" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Enquiry.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {item?.LeadType === "Suggest" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Suggest.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {item?.LeadType === "Save" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Save.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {item?.LeadType === "View" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/View.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {(item?.LeadType === "Owned"||item?.LeadType === "Property") && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Ownericon.svg"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType === "Property"? "Owned" : "Owned"}
                            </span>}
                          </div>
                          <div className="lp-detail">
                            <h3>
                              {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                              {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                            </h3>
                            {item?.PropertyDtls?.Area && <label>
                              <img
                                src="../assets/images/icon-location-outlined.svg"
                                className="img-fluid"
                                alt="Icon"
                              ></img>
                              {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                            </label>}
                            <div className="lp-rooms d-flex align-items-center">
                              {item?.PropertyDtls?.LivingArea && <label>
                                <img
                                  src="../assets/images/icon-sqft.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                sq
                              </label>}
                              {item?.PropertyDtls?.ListBedRoom && <label>
                                <img
                                  src="../assets/images/icon-bed.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBedRoom}
                              </label>}
                              {item?.PropertyDtls?.ListBathRoom && <label>
                                <img
                                  src="../assets/images/icon-bath.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBathRoom}
                              </label>}
                            </div>
                          </div>
                          {/* <ViewMore price={pricing} index={index}/> */}
                        </div>
                      </div>
                  )):"You don't have any properites to list"}
                  {!portfolioLoading &&portfolioCount>0&&
                    <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={portfolioCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  }

                  </>}

                  {view === "enquired" && <>{enquiredList && enquiredList?.leadActivityList?.data?.length > 0 ?
                  enquiredList?.leadActivityList?.data?.map((item, index) => (
                      <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                        <div className="pp-item">
                          {item.ImagesLead && (
                            <div className="pp-img">
                              <Link to={
                                  `/${item?.PropertyDtls?.PropertySlug}`
                                }
                                // target={"_blank"} 
                                onClick={()=> handleViewListing(item?.PropertyId)}
                                >
                              {userRole === "owner" && item.UserId === user?.data?._id ?
                                <img
                                  // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                /> : <img
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                />}</Link>
                                <div className="view-add-btn" style={{display:"flex",justifyContent:'space-between',padding:"5px 10px 5px 10px"}}>
                                  <Button onClick={()=>{history.push(`/${item?.PropertyDtls?.PropertySlug}`);handleViewListing(item?.PropertyId)}} variant="outlined">View Details</Button>
                                  <Button onClick={()=>setOpenAddCmt(item.PropertyId)}variant="outlined">Add Comments</Button>
                                </div>
                              {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                              {/* {userRole === "owner" && item.UserId === user?.data?._id && <IconButton
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              > */}
                                {/* <EditIcon color="grey" />
                              </IconButton>} */}
                              {/* <IconButton
                                onClick={() =>{ 
                                setDelId(item._id)
                                setIsDeleted(true)}}
                                className="pp-close"
                              > */}
                                {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                {/* <CancelIcon color="primary" />
                                </IconButton> */}
                                {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"? "availablity-status is_available": "availablity-status not_available"}>{item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                            </div>
                          )}
                          <div className="pp-id">
                            <label>
                              PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                            </label>
                            {item?.LeadType === "Enquiry" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Enquiry.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {/* {item?.LeadType === "Suggest" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Suggest.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "Save" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Save.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "View" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/View.png"
                            className="img-fluid"
                            alt="Icon" */}
                            {/* /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                          </div>
                          <div className="lp-detail">
                            <h3>
                              {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                              {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                            </h3>
                            {item?.PropertyDtls?.Area && <label>
                              <img
                                src="../assets/images/icon-location-outlined.svg"
                                className="img-fluid"
                                alt="Icon"
                              ></img>
                              {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                            </label>}
                            <div className="lp-rooms d-flex align-items-center">
                              {item?.PropertyDtls?.LivingArea && <label>
                                <img
                                  src="../assets/images/icon-sqft.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                sq
                              </label>}
                              {item?.PropertyDtls?.ListBedRoom && <label>
                                <img
                                  src="../assets/images/icon-bed.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBedRoom}
                              </label>}
                              {item?.PropertyDtls?.ListBathRoom && <label>
                                <img
                                  src="../assets/images/icon-bath.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBathRoom}
                              </label>}
                            </div>
                          </div>
                          <ViewMore price={item?.PriceDataLead?.PriceDetails} index={index} />
                        </div>

                      </div>
                  )):"You don't have any properites to list"}
                  {/* {!enquiredLoading && ( */}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={enquiredList?.leadActivityList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {/* )} */}
                </>}


                {view === "viewed" && <> {viewedList && viewedList?.leadActivityList?.data?.length > 0 &&
                  viewedList?.leadActivityList?.data?.map((item, index) => {
                     return (
                        <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                          <div className="pp-item">
                            {item.ImagesLead && (
                              <div className="pp-img">
                                <Link to={
                                      `/${item?.PropertyDtls?.PropertySlug}`
                                    }
                                    // target={"_blank"} 
                                    >
                                {userRole === "owner" && item.UserId === user?.data?._id ?
                                  <img
                                    // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                    src={
                                      item?.ImagesLead &&
                                      getFeaturedImage(item?.ImagesLead)
                                    }
                                    className="img-fluid"
                                    alt="Property"
                                  /> : <img
                                    src={
                                      item?.ImagesLead &&
                                      getFeaturedImage(item?.ImagesLead)
                                    }
                                    className="img-fluid"
                                    alt="Property"
                                  />}
                                  </Link>
                                {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                                {/* {userRole === "owner" && item.UserId === user?.data?._id && <IconButton
                                  onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                  className="pp-close"
                                  style={{ right: "26px" }}
                                >
                                  <EditIcon color="grey" />
                                </IconButton>} */}
                                {/* <IconButton
                                  onClick={() =>{ 
                                  setDelId(item._id)
                                  setIsDeleted(true)}}
                                  className="pp-close"
                                > */}
                                  {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                  {/* <CancelIcon color="primary" />
                                </IconButton> */}
                                {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"? "availablity-status is_available": "availablity-status not_available"}>{item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                              </div>
                            )}
                            <div className="pp-id">
                              <label>
                                PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                              </label>
                              {/* {item?.LeadType === "Enquiry" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Enquiry.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                              {/* <FavoriteIcon color="primary"/> */}
                              {/* {item?.LeadType}
                        </span>} */}
                              {/* {item?.LeadType === "Suggest" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Suggest.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                              {/* <FavoriteIcon color="primary"/> */}
                              {/* {item?.LeadType}
                        </span>} */}
                              {/* {item?.LeadType === "Save" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Save.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                              {/* <FavoriteIcon color="primary"/> */}
                              {/* {item?.LeadType}
                        </span>} */}
                              {item?.LeadType === "View" && <span>
                                <img style={{ width: "20px" }}
                                  src="../assets/images/View.png"
                                  className="img-fluid"
                                  alt="Icon"
                                />
                                {/* <FavoriteIcon color="primary"/> */}
                                {item?.LeadType}
                              </span>}
                            </div>
                            <div className="lp-detail">
                              <h3>
                                {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                                {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                              </h3>
                              {item?.PropertyDtls?.Area && <label>
                                <img
                                  src="../assets/images/icon-location-outlined.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                              </label>}
                              <div className="lp-rooms d-flex align-items-center">
                                {item?.PropertyDtls?.LivingArea && <label>
                                  <img
                                    src="../assets/images/icon-sqft.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  ></img>
                                  {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                  sq
                                </label>}
                                {item?.PropertyDtls?.ListBedRoom && <label>
                                  <img
                                    src="../assets/images/icon-bed.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  ></img>
                                  {item?.PropertyDtls &&
                                    item?.PropertyDtls?.ListBedRoom}
                                </label>}
                                {item?.PropertyDtls?.ListBathRoom && <label>
                                  <img
                                    src="../assets/images/icon-bath.svg"
                                    className="img-fluid"
                                    alt="Icon"
                                  ></img>
                                  {item?.PropertyDtls &&
                                    item?.PropertyDtls?.ListBathRoom}
                                </label>}
                              </div>
                            </div>
                            <ViewMore price={item?.PriceDataLead?.PriceDetails} index={index} />
                          </div>

                        </div>
                    )
                  })}
                  {/* {!viewedLoading && ( */}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={viewedList?.leadActivityList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {/* )} */}
                </>
                }


                {view === "saved" && <> {savedList && savedList?.leadActivityList?.data?.length > 0 &&
                  savedList?.leadActivityList?.data?.map((item, index) => (
                      <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                        <div className="pp-item">
                          {item.ImagesLead && (
                            <div className="pp-img">
                              <Link to={
                                  `/${item?.PropertyDtls?.PropertySlug}`
                                }
                                // target={"_blank"} 
                                onClick={()=> handleViewListing(item?.PropertyId)}
                                >
                              {userRole === "owner" && item.UserId === user?.data?._id ?
                                <img
                                  // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                  
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                /> : <img
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                />}</Link>
                                <div className="view-add-btn" style={{display:"flex",justifyContent:'space-between',padding:"5px 10px 5px 10px"}}>
                                  <Button onClick={()=>{history.push(`/${item?.PropertyDtls?.PropertySlug}`);handleViewListing(item?.PropertyId)}} variant="outlined">View Details</Button>
                                  <Button onClick={()=>setOpenAddCmt(item.PropertyId)}variant="outlined">Add Comments</Button>
                                </div>
                              {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                              {/* {userRole === "owner" && item.UserId === user?.data?._id && <IconButton
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              >
                                <EditIcon color="grey" />
                              </IconButton>} */}
                              {/* <IconButton
                                onClick={() =>{ 
                                setDelId(item._id)
                                setIsDeleted(true)}}
                                className="pp-close"
                              > */}
                                {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                {/* <CancelIcon color="primary" />
                              </IconButton> */}
                              {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"?
                               "availablity-status is_available": "availablity-status not_available"}>
                                {item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                                <IconButton
                                  className="pl-fav"
                                  color="secondary"
                                  onClick={(e) => {
                                    handleUnSaveProperty(item?.PropertyId);

                                  }}
                                >
                                    <FavoriteIcon color="primary" />
                                  
                                </IconButton>
                            </div>
                          )}
                          <div className="pp-id">
                            <label>
                              PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                            </label>
                            {/* {item?.LeadType === "Enquiry" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Enquiry.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "Suggest" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Suggest.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {item?.LeadType === "Save" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Save.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {/* {item?.LeadType === "View" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/View.png"
                            className="img-fluid"
                            alt="Icon" */}
                            {/* /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                          </div>
                          <div className="lp-detail">
                            <h3>
                              {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                              {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                            </h3>
                            {item?.PropertyDtls?.Area && <label>
                              <img
                                src="../assets/images/icon-location-outlined.svg"
                                className="img-fluid"
                                alt="Icon"
                              ></img>
                              {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                            </label>}
                            <div className="lp-rooms d-flex align-items-center">
                              {item?.PropertyDtls?.LivingArea && <label>
                                <img
                                  src="../assets/images/icon-sqft.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                sq
                              </label>}
                              {item?.PropertyDtls?.ListBedRoom && <label>
                                <img
                                  src="../assets/images/icon-bed.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBedRoom}
                              </label>}
                              {item?.PropertyDtls?.ListBathRoom && <label>
                                <img
                                  src="../assets/images/icon-bath.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBathRoom}
                              </label>}
                            </div>
                          </div>
                          <ViewMore price={item?.PriceDataLead?.PriceDetails} index={index} />
                        </div>

                      </div>
                  ))}
                  {/* {!savedLoading && ( */}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={savedList?.leadActivityList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {/* )} */}
                </>}

                {view === "suggested" && <>{suggestedList && suggestedList?.leadActivityList?.data?.length > 0 &&
                  suggestedList?.leadActivityList?.data?.map((item, index) => (
                      <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                        <div className="pp-item">
                          {item.ImagesLead && (
                            <div className="pp-img">
                              <Link to={
                                  `/${item?.PropertyDtls?.PropertySlug}`
                                }
                                // target={"_blank"} 
                                onClick={()=> handleViewListing(item?.PropertyId)}
                                >
                              {userRole === "owner" && item.UserId === user?.data?._id ?
                                <img
                                  // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                /> : <img
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                />}</Link>
                              {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                              {/* {userRole === "owner" && item.UserId === user?.data?._id && <IconButton
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              >
                                <EditIcon color="grey" />
                              </IconButton>} */}
                              {/* <IconButton
                                onClick={() =>{ 
                                setDelId(item._id)
                                setIsDeleted(true)}}
                                className="pp-close"
                              > */}
                                {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                {/* <CancelIcon color="primary" />
                                </IconButton> */}
                                {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"? "availablity-status is_available": "availablity-status not_available"}>{item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                            </div>
                          )}
                          <div className="pp-id">
                            <label>
                              PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                            </label>
                            {/* {item?.LeadType === "Enquiry" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Enquiry.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {item?.LeadType === "Suggest" && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Suggest.png"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType}
                            </span>}
                            {/* {item?.LeadType === "Save" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Save.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "View" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/View.png"
                            className="img-fluid"
                            alt="Icon" */}
                            {/* /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                          </div>
                          <div className="lp-detail">
                            <h3>
                              {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                              {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                            </h3>
                            {item?.PropertyDtls?.Area && <label>
                              <img
                                src="../assets/images/icon-location-outlined.svg"
                                className="img-fluid"
                                alt="Icon"
                              ></img>
                              {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                            </label>}
                            <div className="lp-rooms d-flex align-items-center">
                              {item?.PropertyDtls?.LivingArea && <label>
                                <img
                                  src="../assets/images/icon-sqft.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                sq
                              </label>}
                              {item?.PropertyDtls?.ListBedRoom && <label>
                                <img
                                  src="../assets/images/icon-bed.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBedRoom}
                              </label>}
                              {item?.PropertyDtls?.ListBathRoom && <label>
                                <img
                                  src="../assets/images/icon-bath.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBathRoom}
                              </label>}
                            </div>
                          </div>
                          <ViewMore price={item?.PriceDataLead?.PriceDetails} index={index} />
                        </div>

                      </div>
                  ))}
                  {/* {!suggestedLoading && ( */}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={suggestedList?.leadActivityList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {/* )} */}
                </>}

                {view === "owned" && <>{ownedList?.leadActivityList?.data?.length > 0 &&
                  ownedList?.leadActivityList?.data?.map((item, index) => {
                     return( <div key={index} className="col-lg-4 col-md-6 pp-item-space">
                        <div className="pp-item">
                          {item.ImagesLead && (
                            <div className="pp-img">
                              <Link to={
                                  `/${item?.PropertyDtls?.PropertySlug}`
                                }
                                // target={"_blank"} 
                                onClick={()=> handleViewListing(item?.PropertyId)}
                                >
                              {userRole === "owner" && item.UserId === user?.data?._id ?
                                <img
                                  // onClick={(e) => { history.push("/property-detail/" + item?.PropertyId) }}
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                /> : <img
                                  src={
                                    item?.ImagesLead &&
                                    getFeaturedImage(item?.ImagesLead)
                                  }
                                  className="img-fluid"
                                  alt="Property"
                                />}</Link>
                              {/* {uType=== "renter"? (<Checkbox color ="primary"  onChange= {(e)=>{handleChecked(item,e.target.checked)}}/>):null} */}
                              {userRole === "owner" ||userRole === "renter"? item.UserId === user?.data?._id && <IconButton
                              color="primary"
                                onClick={(e) => { history.push(`/addProperty/${item.PropertyId}`) }}
                                className="pp-close"
                                style={{ right: "26px" }}
                              >
                                <EditIcon color="grey" />
                              </IconButton>: ""}
                              <IconButton
                                onClick={() =>{ 
                                setDelId(item.PropertyId)
                                setIsDeleted(true)}}
                                className="pp-close"
                              >
                                {/* <img
                              src="../assets/images/icon-close.svg"
                              className="img-fluid"
                              alt="Icon"
                            /> */}
                                <CancelIcon color="primary" />
                                </IconButton>
                                {item?.PropertyDtls?.Status&&<label className={item?.PropertyDtls?.Status==="approved"? "availablity-status is_available": "availablity-status not_available"}>{item?.PropertyDtls?.Status==="approved"? "Available": "Not Available"}</label>}
                            </div>
                          )}
                          <div className="pp-id">
                            <label>
                              PID#{item?.PropertyDtls && item?.PropertyDtls?.PID}
                            </label>
                            {/* {item?.LeadType === "Enquiry" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Enquiry.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "Suggest" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Suggest.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "Save" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/Save.png"
                            className="img-fluid"
                            alt="Icon"
                          /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {/* {item?.LeadType === "View" &&<span>
                          <img style={{width :"20px" }}
                            src="../assets/images/View.png"
                            className="img-fluid"
                            alt="Icon" */}
                            {/* /> */}
                            {/* <FavoriteIcon color="primary"/> */}
                            {/* {item?.LeadType}
                        </span>} */}
                            {(item?.LeadType === "Owned"||item?.LeadType === "Property") && <span>
                              <img style={{ width: "20px" }}
                                src="../assets/images/Ownericon.svg"
                                className="img-fluid"
                                alt="Icon"
                              />
                              {/* <FavoriteIcon color="primary"/> */}
                              {item?.LeadType === "Property" ? "Owned" : "Owned"}
                            </span>}
                          </div>
                          <div className="lp-detail">
                            <h3>
                              {item?.PropertyDtls && item?.PropertyDtls?.AdTitle}
                              {/* {item?.PropertyDtls && item?.PropertyDtls?.AdTitle.charAt(0).toUpperCase() + item?.PropertyDtls?.AdTitle.slice(1).toLowerCase()} */}
                            </h3>
                            {item?.PropertyDtls?.Area && <label>
                              <img
                                src="../assets/images/icon-location-outlined.svg"
                                className="img-fluid"
                                alt="Icon"
                              ></img>
                              {item?.PropertyDtls && item?.PropertyDtls?.Area.charAt(0).toUpperCase() + item?.PropertyDtls?.Area.slice(1).toLowerCase()}
                            </label>}
                            <div className="lp-rooms d-flex align-items-center">
                              {item?.PropertyDtls?.LivingArea && <label>
                                <img
                                  src="../assets/images/icon-sqft.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls && item?.PropertyDtls?.LivingArea}
                                sq
                              </label>}
                              {item?.PropertyDtls?.ListBedRoom && <label>
                                <img
                                  src="../assets/images/icon-bed.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBedRoom}
                              </label>}
                              {item?.PropertyDtls?.ListBathRoom && <label>
                                <img
                                  src="../assets/images/icon-bath.svg"
                                  className="img-fluid"
                                  alt="Icon"
                                ></img>
                                {item?.PropertyDtls &&
                                  item?.PropertyDtls?.ListBathRoom}
                              </label>}
                            </div>
                          </div>
                          <ViewMore price={item?.PriceDataLead?.PriceDetails} index={index} />
                        </div>

                      </div>
                  )})}
                  {/* {!ownedLoading && ( */}
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={ownedList?.leadActivityList?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  {/* )} */}
                </>}

                {/* {!portfolioLoading && (
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={portfolioCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )} */}
                {multiAgentPrint === true && (
                  <CustomizedModal
                    component={AgentPrint}
                    label="Multiple Agent Print"
                    open={multiAgentPrint}
                    data={formatData(checked)}
                    setOpenAgentPrint={setMultiAgentPrint}
                  />
                )}
                {multiCustomerPrint === true && (
                  <CustomizedModal
                    component={CustomerPrint}
                    label="Multiple Customer Print"
                    open={multiCustomerPrint}
                    data={formatData(checked)}
                    setOpenAgentPrint={setMultiCustomerPrint}
                  />
                )}
                {multiEmailOwner === true && (
                  <CustomizedModal
                    hideHeader={true}
                    component={EmailListing}
                    emailOwner={multiEmailOwner}
                    label="Email Owner's"
                    open={multiEmailOwner}
                    // data={formatData(checked)}
                    setOpenAgentPrint={setMultiEmailOwner}
                    propertyDetails={formatData(checked)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPortfolio;
