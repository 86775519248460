import React, { useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import Autocomplete, {
} from "@material-ui/lab/Autocomplete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_RENTER, LIST_ALL_USERS } from "../../Graghql/queries";
import { DELETE_RENTERS } from "../../Graghql/mutations";
import { DEACTIVATE_RENTER } from "../../Graghql/mutations";
import { toggleSnackbar } from "../../RactiveVariables/index";
import SideBar from "../Common/sidebar";
import AddRenter from "../Renter/AddRenter";
import LoggedInHeader from "../Common/loggedinHeader";
import { DialogBox } from "../Common";
import { useHistory } from "react-router";
import { userDeatils } from "../../RactiveVariables";
import { useReactiveVar } from "@apollo/client";

import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  menuPaper: {
    maxHeight: 200,
  },
}));
const options = [
  { label: "Edit", id: "EDIT" },
  { label: "Deactivate", id: "DEACTIVATE" },
  { label: "Delete", id: "DELETE" },
];

const Renter = () => {
  const user = useReactiveVar(userDeatils);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteVar, setDeleteVar] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [anchorElDelete, setAnchorElDelete] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isPaginating, setIsPaginating] = React.useState(false);
  const [openAddRenter, setOpenAddRenter] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState(null);
  const [agent, setAgent] = useState("All");
  const [renderdetails, setRenderdetails] = useState("");
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    setAgent(event.target.value);
  };
  const renterListVariables = {
    skip: page * rowsPerPage,
    limit: rowsPerPage,
    search: searchKeyword,
    agentId: renderdetails?._id,
  };
  const { data: agentData } = useQuery(LIST_ALL_USERS, {
    variables: { role: "agent", skip: 0, limit: 20, search: agent },
  });
  let agentName = agentData && [...agentData.listAllUsers];
  const {
    data,
    loading: renterLoading,
    fetchMore,
  } = useQuery(LIST_RENTER, {
    fetchPolicy: "network-only",
    variables: renterListVariables,
  });
  const handlePagination = () => {
    fetchMore({
      variables: renterListVariables,
    });
  };
  const [deactivateRenter] = useMutation(DEACTIVATE_RENTER, {
    refetchQueries: ["listRenter"],
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsPaginating(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setIsPaginating(true);
  };

  React.useEffect(() => {
    handlePagination();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, agent, searchKeyword]);

  const [deleteRenter] = useMutation(DELETE_RENTERS);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  function handleDelete() {
    try {
      deleteRenter({
        variables: {
          renterIds: deleteVar === null ? selectedItem : defaultValue._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: res?.data?.deleteRenters?.message,
            variant: "success",
          });
          handlePagination();
          setSelectedItem([]);
        })
        .catch((error) => {
          const message = error && error.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (error) {
      const message = error && error.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  const handleCheckboxChange = (item, value) => {
    let arr1 = JSON.stringify(selectedItem);
    let arr = JSON.parse(arr1);
    if (value === true) {
      arr.push(item._id);
    } else {
      let index = arr.indexOf(item._id);
      arr.splice(index, 1);
    }
    setSelectedItem(arr);
  };
  // const handleClick = (event, _itemToEdit) => {
  //     setItemToEdit(_itemToEdit);
  //     setAnchorEl(event.currentTarget);
  // };

  const handleMenuChange = (type, item, target, element) => {
    console.log("type..", type);
    item && setDefaultValue(item);
    setAnchorEl(true);
    // eslint-disable-next-line default-case
    switch (type) {
      case "EDIT":
        setAnchorEl(target);
        break;
      case "Edit":
        setAnchorEl(null);
        setOpenAddRenter(true);
        break;
      case "TOGGLE_ADD":
        setOpenAddRenter(true);
        setAnchorEl(false);
        break;
      case "DEACTIVATE":
        setAnchorEl(target);
        break;
      case "TOGGLE_DEACTIVATE_ALERT":
        setAnchorEl(null);
        setDeleteAlert(!deleteAlert);
        handleCloseDelete();
        break;
      case "CONFIRM_DEACTIVATE":
        setAnchorEl(null);
        handleDeactivate();
        setDeleteAlert(!deleteAlert);
        break;
      case "DELETE":
        setAnchorEl(target);
        break;
      case "Delete":
        setAnchorEl(null);
        setVisible(true);
        setDeleteVar(element);

        break;
    }
  };
  const handleDeactivate = () => {
    try {
      deactivateRenter({
        variables: { renterId: defaultValue._id, status: defaultValue.Status },
      })
        .then((res) => {
          const responseData = res.data && res.data.deactivateRenter;
          // eslint-disable-next-line no-unused-vars
          const changedStatus =
            responseData && responseData.status === "active"
              ? "inactive"
              : "active";
          //responseData && responseData.status=='active'?'inactive':'active'

          if (responseData && responseData.status)
            toggleSnackbar({
              status: true,
              message: responseData.message,
            });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  function handleAddBtnClick(params) {
    setOpenAddRenter(true);
    setDefaultValue(null);
  }

  let renterListCount = data && data.listRenter && data.listRenter.count;
  let renterList = data && data.listRenter && data.listRenter.data;
  //console.log("renterList", renterList, renterListCount);
  const changedStatus =
    defaultValue?.Status === "active" ? "inactive" : "active";
  function sortLabels(a, b) {
    var nameA = a.FirstName.toUpperCase();
    var nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  console.log("--------->",defaultValue)
  return (
    <div className="custom-layout">
      <AddRenter
        handlePagination={handlePagination}
        open={openAddRenter}
        setOpen={setOpenAddRenter}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
      <LoggedInHeader
        handleAddBtnClick={handleAddBtnClick}
        label="Renter"
        addBtnLabel="Add Renter"
        handleDelete={() => {
          if (selectedItem.length > 0 || deleteVar != null) setVisible(true);
          else {
            toggleSnackbar({
              status: true,
              message: "Please select Renter",
              variant: "error",
            });
          }
        }}
      />
      <div className="custom-layout-content">
        <div className="search-band">
          <DialogBox
            open={visible}
            setOpen={setVisible}
            setDeleteVar={setDeleteVar}
            handlePositiveBtn={handleDelete}
            title={"Delete?"}
            text={
              selectedItem.length === 1 || deleteVar == null
                ? "Are you sure you want to delete this renter?"
                : "Are you sure you want to delete this renters?"
            }
            // positiveText={"Delete"}
            // negativeText={"Cancel"}
          />
          <form>
            <FormControl>
              <TextField
                id="standard-full-width"
                className="fullWidthSearch"
                label="SEARCH"
                disableUnderline={false}
                placeholder="Name, Address, Email"
                type="search"
                onChange={(event) => setSearchKeyword(event.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  disableUnderline: false,
                }}
              />
            </FormControl>

            <FormControl className="autocompleteSearch">
              {/* <InputLabel id="demo-simple-select-outlined-label">
                Agent
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={agent}
                onChange={handleChange}
                label="Agent"
                defaultValue="All"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="All">All</MenuItem>
                {agentName?.sort(sortLabels).map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item?.FirstName.charAt(0).toUpperCase() + item?.FirstName.slice(1).toLowerCase() + " " + item?.LastName.charAt(0).toUpperCase() + item?.LastName.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </Select> */}
              <InputLabel>Agent</InputLabel>
              <Autocomplete
                id="demo-simple-select-outlined"
                options={agentName?.sort(sortLabels) ?? []}
                inputValue={agent}
                //freeSolo
                getOptionLabel={(option) =>
                  option?.FirstName.charAt(0).toUpperCase() +
                  option?.FirstName.slice(1).toLowerCase() +
                  " " +
                  option?.LastName.charAt(0).toUpperCase() +
                  option?.LastName.slice(1).toLowerCase()
                }
                className="w-100 fullWidthSearch"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="All"/>
                )}
                //value={renderdetails?.Email}
                //value={error.suggest}
                onChange={(e, value) => {
                  setRenderdetails(value);
                  // setEmailError()
                }}
                onInputChange={(e, value) => {
                  setAgent(value);
                }}
                // filterOptions={(options, params) => {
                //  // const filtered = filter(options, params);
                //   setSearchKeyword(params.inputValue)
                //   // Suggest the creation of a new value
                //   // if (params.inputValue !== "") {
                //   //   filtered.push(params.inputValue);
                //   // }
                //   // return filtered;
                // }}
              />
            </FormControl>
          </form>
        </div>
        <div className="content-wrap">
          <SideBar selected="renter" />
          <div className="row">
            <div className="col-md-12">
              {/* <h5>Renter Listing</h5> */}
              <div>
                <Table
                  className="common-table"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell className="text-left">Email</TableCell>
                      <TableCell className="text-left">
                        Associated Agent
                      </TableCell>
                      <TableCell>Phone</TableCell>
                      {/* <TableCell>LastActivity Date</TableCell> */}
                      <TableCell>LastActivity Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {renterLoading ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p> Loading Renters...</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : renterList && renterList.length ? (
                      renterList.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {user?.data?.Role !== "agent" && (
                              <FormControlLabel
                                className="table-checkbox"
                                control={
                                  <Checkbox
                                    checked={selectedItem.includes(item._id)}
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        item,
                                        event.target.checked
                                      )
                                    }
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                              />
                            )}
                            <label
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push({
                                  pathname:    "/lead-detail/" + item?.LeadDtls?._id,
                                  state: { title: 'renterPortfolios' }
                                }
                                 
                                )
                              }
                            >
                              {item?.FirstName?.charAt(0).toUpperCase() +
                                item?.FirstName?.slice(1).toLowerCase() +
                                " " +
                                item?.LastName?.charAt(0).toUpperCase() +
                                item?.LastName?.slice(1).toLowerCase()}
                            </label>
                          </TableCell>
                          <TableCell className="text-left">
                            {item.Email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="me-1"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  fontSize: "14px",
                                }}
                                src={item?.AgentDtls?.ProfileImage}
                              >
                                {item?.AgentDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.AgentDtls?.LastName.charAt(
                                    0
                                  ).toUpperCase()}
                              </Avatar>
                              <label>
                                {" "}
                                {item?.AgentDtls?.FirstName.charAt(
                                  0
                                ).toUpperCase() +
                                  item?.AgentDtls?.FirstName.slice(
                                    1
                                  ).toLowerCase() +
                                  " " +
                                  item?.AgentDtls?.LastName.charAt(
                                    0
                                  ).toUpperCase() +
                                  item?.AgentDtls?.LastName.slice(
                                    1
                                  ).toLowerCase()}
                              </label>
                            </div>
                          </TableCell>
                          <TableCell>{item.Phone}</TableCell>
                          {/* <TableCell>{}</TableCell> */}
                          <TableCell>
                            {moment(
                              new Date(parseInt(item.RenterActivityDate))
                            ).format("L")}
                          </TableCell>

                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(e) =>
                                handleMenuChange("EDIT", item, e.currentTarget)
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : agent || searchKeyword ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Renters Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p>No Renters Added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!renterLoading && renterListCount > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={renterListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </div>

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {options.map((option, index) => {
                  let statusLabel =
                    option.label === "Deactivate"
                      ? changedStatus === "inactive"
                        ? "Deactivate"
                        : "Activate"
                      : option.label;

                  return (
                    <MenuItem
                      key={index}
                      disabled={
                        user?.data?.Role === "agent" &&
                        (option.label === "Delete" ||
                          option.label === "Deactivate")
                      }
                      onClick={() =>
                        handleMenuChange(
                          option.label === "Deactivate" ||
                            option.label === "Activate"
                            ? "TOGGLE_DEACTIVATE_ALERT"
                            : option.label
                        )
                      }
                    >
                      {statusLabel}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Dialog
                open={deleteAlert}
                onClose={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {changedStatus === "inactive" ? "Deactivate ?" : "Activate ?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {changedStatus === "inactive"
                      ? "Are you sure you want to deactivate?"
                      : "Are you sure you want to activate?"}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleMenuChange("TOGGLE_DEACTIVATE_ALERT")}
                    color="primary"
                  >
                    No
                  </Button>
                  <Button
                    onClick={() => handleMenuChange("CONFIRM_DEACTIVATE")}
                    color="primary"
                    autoFocus
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Renter;
